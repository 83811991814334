<template>
    <div>
        <h1 class="display-1 mb-3 primary--text">
            <v-icon color="primary" class="titleIcon">
                account_balance
            </v-icon>
            {{ l("Exhibitions") }}
        </h1>
        <v-layout justify-end row class="pb-5 pr-3">
            <AddButton
                v-if="!usageLimitReached"
                :label="l('Create New Exhibition')"
                @click="add"
            />
            <v-alert v-if="usageLimitReached" type="error" dense>
                {{ limitReachedWarning }}
            </v-alert>
        </v-layout>
        <v-data-table
            :headers="headers"
            :items="list"
            :sort-by="sortBy"
            :items-per-page="itemsPerPage"
            :footer-props="pagination"
            :loading="loading"
            :no-data-text="l('No entries.')"
        >
            <template #item="row">
                <tr>
                    <td class="clickable" @click="edit(row.item)">
                        {{ row.item.title }}
                    </td>
                    <td class="clickable" @click="edit(row.item)">
                        <span v-if="hasSystemAccessRights" class="exhibition-id" @click="stopPropagation">
                            {{ row.item._id }}
                        </span>
                    </td>
                    <td class="text-right nobreak" style="width:248px">
                        <v-tooltip left>
                            <template #activator="{ on }">
                                <v-btn text icon color="primary" @click="openARTargetManager(row.item)" v-on="on">
                                    <v-icon>
                                        mdi-order-bool-ascending-variant
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">
                                {{ l("Manage AR Targets") }}
                            </span>
                        </v-tooltip>
                        <v-tooltip v-if="hasSystemAccessRights" left>
                            <template #activator="{ on }">
                                <v-btn text icon color="primary" :disabled="!row.item.outputJSONFile" @click="updateJsonInFirebase(row.item)" v-on="on">
                                    <v-icon>
                                        mdi-database-import
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">{{ l("Update JSON file in Firebase") }}</span>
                        </v-tooltip>
                        <!--<v-tooltip v-if="hasSystemAccessRights" left>
                            <template #activator="{ on }">
                                <v-btn text icon color="primary" @click="downloadMedia(row.item)" v-on="on">
                                    <v-icon>
                                        archive
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">{{ l("Download Exhibition's Media") }}</span>
                        </v-tooltip>-->
                        <v-tooltip v-if="hasSystemAccessRights" left>
                            <template #activator="{ on }">
                                <v-btn text icon color="primary" @click="openDownloadARTargetsDialog(row.item)" v-on="on">
                                    <v-icon>
                                        mdi-cube-scan
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">{{ l("Download targets for Vuforia") }}</span>
                        </v-tooltip>
                        <v-tooltip v-if="hasSystemAccessRights" left>
                            <template #activator="{ on }">
                                <v-btn text icon color="primary" target="_blank" :disabled="!row.item.outputJSONFile" @click="viewJsonFile(row.item)" v-on="on">
                                    <v-icon>
                                        mdi-code-json
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">{{ l("View JSON file") }}</span>
                        </v-tooltip>
                        <v-tooltip left>
                            <template #activator="{ on }">
                                <v-btn text icon color="primary" target="_blank" :disabled="usageLimitReached" @click="duplicateEntry(row.item)" v-on="on">
                                    <v-icon>
                                        mdi-content-duplicate
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">{{ l("Duplicate Exhibition") }}</span>
                        </v-tooltip>
                        <v-tooltip left>
                            <template #activator="{ on }">
                                <v-btn text icon color="primary" @click="deleteEntry(row.item)" v-on="on">
                                    <v-icon>
                                        delete
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">{{ l("Delete") }}</span>
                        </v-tooltip>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <v-dialog v-model="showDownloadARTargetsDialog" persistent max-width="480px">
            <v-card>
                <v-card-title>{{ l("Download targets for Vuforia") }}</v-card-title>
                <v-card-text>
                    <div v-if="selectedExhibitionForDownload">
                        {{ l("Exhibition:") }}
                        <strong> {{ selectedExhibitionForDownload.title }} </strong>
                    </div>
                    <v-checkbox
                        v-model="downloadARTargetSettings.missingOriginals"
                        :label="l('Original images of AR targets missing in Vuforia library')"
                        dense
                        hide-details
                    />
                    <v-checkbox
                        v-model="downloadARTargetSettings.missingTargets"
                        :label="l('Target images of AR targets missing in Vuforia library')"
                        dense
                        hide-details
                    />
                    <v-checkbox
                        v-model="downloadARTargetSettings.existingOriginals"
                        :label="l('Original images of AR targets currently in Vuforia library')"
                        dense
                        hide-details
                    />
                    <v-checkbox
                        v-model="downloadARTargetSettings.existingTargets"
                        :label="l('Target images of AR targets currently in Vuforia library')"
                        dense
                        hide-details
                    />
                    <v-checkbox
                        v-model="downloadARTargetSettings.allOriginals"
                        :label="l('Original images of all AR targets')"
                        dense
                        hide-details
                    />
                    <v-checkbox
                        v-model="downloadARTargetSettings.allTargets"
                        :label="l('Target images of all AR targets')"
                        dense
                        hide-details
                    />
                    <v-checkbox
                        v-model="downloadARTargetSettings.unusedTargets"
                        :label="l('Unused targets in Vuforia library')"
                        dense
                        hide-details
                    />
                    <v-checkbox
                        v-model="downloadARTargetSettings.wrongSizeTargets"
                        :label="l('Targets in Vuforia library with incorrect width value')"
                        dense
                        hide-details
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn text color="primary" @click="closeDownloadARTargetsDialog()">
                        {{ l("Cancel") }}
                    </v-btn>
                    <v-btn text color="primary" @click="startARTargetsDownload()">
                        {{ l("Download") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-container v-if="showUsageBar">
            <v-row align="center" justify="end" no-gutters>
                <v-spacer />
                <UsageBar metric-name="exhibitions" mini />
            </v-row>
        </v-container>
    </div>
</template>
<script>

    import { mapGetters } from "vuex";
    import AddButton from "../globalUiElements/AddButton.vue";
    import UsageBar from "../../usage/components/UsageBar.vue";
    import ExhibitionService from "../../services/ExhibitionService.js";
    import ListUtils from "../../utils/ListUtils.js";
    import { hasLimit, isLimitReached } from "../../usage/utils/usageUtils.js";
    import { l } from "../../utils/LocalizationUtils.js";
    import { updateLookups } from "../../utils/referenceUtils.js";


    export default ListUtils.setupList({
        components: {
            AddButton,
            UsageBar,
        },
        headers: [
            { text: "Title", value: "title" },
            { text: "", value: "_id" },
            { text: "", sortable: false }
        ],
        sortBy: "title",
        data() {
            return {
                showDownloadARTargetsDialog: false,
                selectedExhibitionForDownload: null,
                downloadARTargetSettings: {
                    missingOriginals: true,
                    missingTargets: true,
                    existingOriginals: false,
                    existingTargets: false,
                    allOriginals: false,
                    allTargets: false,
                    unusedTargets: true,
                    wrongSizeTargets: true,
                },
            };
        },
        computed: {
            showUsageBar: () => hasLimit("exhibitions"),
            usageLimitReached: () => isLimitReached("exhibitions"),
            limitReachedWarning: () => l("Limit of exhibitions reached"),
            ...mapGetters({
                hasSystemAccessRights: "user/hasSystemAccessRights"
            })
        },
        async update() {
            this.showLoading();
            await updateLookups(["usage"]);
            ExhibitionService.getExhibitions(list => {
                list.forEach(entry => {
                    entry.arTargetsDownloadLink = "/api/targets/" + entry._id + ".zip";
                });
                this.list = list;
                this.hideLoading();
            });
        },
        deleteSettings: {
            promptTitle: "Delete Exhibition?",
            promptQuestion: 'Are you sure you want to delete "${v.title}"?',
            action: ExhibitionService.deleteExhibition,
            success: 'Exhibition "${v.title}" deleted.'
        },
        methods: {
            updateJsonInFirebase(exhibition) {
                ExhibitionService.updateJsonInFirebase(exhibition, () => {
                    this.$root.$emit(
                        "success",
                        this.l('The JSON file of "${v.title}" was successfully updated.', exhibition)
                    );
                });
            },
            openARTargetManager(exhibition) {
                this.$emit("openARTargetManager", exhibition);
            },
            downloadMedia(exhibition) {
                ExhibitionService.downloadMedia(exhibition);
            },
            viewJsonFile(exhibition) {
                window.open("/api/app/" + exhibition._id, "_blank");
            },
            duplicateEntry(exhibition) {
                this.$emit("duplicate", exhibition);
            },
            openExhibitionManager(exhibition) {
                this.$emit("openExhibitionManager", exhibition);
            },
            openDownloadARTargetsDialog(exhibition) {
                this.selectedExhibitionForDownload = exhibition;
                this.downloadARTargetSettings = {
                    missingOriginals: true,
                    missingTargets: true,
                    existingOriginals: false,
                    existingTargets: false,
                    allOriginals: false,
                    allTargets: false,
                    unusedTargets: true,
                    wrongSizeTargets: true,
                };
                this.showDownloadARTargetsDialog = true;
            },
            closeDownloadARTargetsDialog() {
                this.showDownloadARTargetsDialog = false;
                this.selectedExhibitionForDownload = null;
            },
            startARTargetsDownload() {
                ExhibitionService.downloadArTargets(this.selectedExhibitionForDownload, this.downloadARTargetSettings);
                this.closeDownloadARTargetsDialog();
            },
            stopPropagation(event) {
                event.stopPropagation();
            }
        }
    });
</script>
<style scoped>
.clickable {
  cursor: pointer;
}

.exhibition-id {
  background: #ddd;
  font-family: "Lucida Console", Monaco, monospace;
  padding: 8px;
  width: 120px;
  cursor: text;
}

.nobreak {
  white-space: nowrap;
}
</style>