<template>
    <div>
        <h2 class="title primary--text">
            {{ l("Interaction Modules") }}
        </h2>
        <v-progress-linear v-if="loading" indeterminate class="mt-5" />
        <v-btn v-if="interactionsWithoutModule.length > 0" type="error" outlined tile color="error" class="body-1 mt-5 pa-5" style="text-transform:none; width:100%" @click="openInteractionsWithoutModuleList()">
            <v-icon left>
                mdi-alert
            </v-icon>
            {{ l("There are ${v} interactions with missing interaction modules.", interactionsWithoutModule.length) }}
        </v-btn>
        <v-card v-for="item in list" :key="item.id" class="mt-5">
            <v-card-title class="subtitle-2 pt-4 pb-0">
                <span class="element-id">
                    {{ item.id }}
                </span>
            </v-card-title>
            <v-data-table hide-default-footer :headers="headers" :items="item.versions" sort-by="versionNumber" :items-per-page="-1" :no-data-text="l('No entries.')">
                <template #item="row">
                    <tr>
                        <td class="text-right" style="width:40px">
                            {{ row.item.versionNumber }}
                        </td>
                        <td class="text-center" style="width:68px">
                            <v-tooltip v-if="row.item.isVisibleToCurator" left>
                                <template #activator="{ on }">
                                    <v-btn text icon color="primary" v-on="on" @click="toggleVisibility(row.item)">
                                        <v-icon>mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                                <span class="body-2">{{ l("Hide interaction module from curator") }}</span>
                            </v-tooltip>
                            <v-tooltip v-if="!row.item.isVisibleToCurator" left>
                                <template #activator="{ on }">
                                    <v-btn text icon color="primary" v-on="on" @click="toggleVisibility(row.item)">
                                        <v-icon>mdi-eye-off-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span class="body-2">{{ l("Show interaction module to curator") }}</span>
                            </v-tooltip>
                        </td>
                        <td style="width:20%">
                            {{ row.item.title }}
                        </td>
                        <td>
                            {{ row.item.versionDescription }}
                        </td>
                        <td class="text-right nobreak" style="width:100px">
                            <span class="pr-1" style="display:inline-flex; vertical-align:middle;">
                                {{ row.item.uses.length }}
                            </span>
                            <v-tooltip left>
                                <template #activator="{ on }">
                                    <v-btn text icon color="primary" v-on="on" @click="openUses(row.item)">
                                        <v-icon>mdi-view-list</v-icon>
                                    </v-btn>
                                </template>
                                <span class="body-2">{{ l("List all uses") }}</span>
                            </v-tooltip>
                        </td>
                        <td class="text-right nobreak" style="width:68px">
                            <v-tooltip left>
                                <template #activator="{ on }">
                                    <v-btn text icon color="primary" v-on="on" @click="openInformation(row.item)">
                                        <v-icon>mdi-information-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span class="body-2">{{ l("Information") }}</span>
                            </v-tooltip>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>
        <InteractionsWithoutModuleList v-if="showInteractionsWithoutModuleList" :interactions-without-module="interactionsWithoutModule" @close="closeInteractionsWithoutModuleList" @deletedInteraction="deletedInteractionWithoutModule" />
        <InteractionModuleUsesBox v-if="showUses" :interaction-module="showUsesOf" @close="closeUses" @deletedInteractionOfModule="deletedInteractionOfModule" />
        <InteractionModuleInformationBox v-if="showInformation" :interaction-module="showInformationOn" @close="closeInformation" />
    </div>
</template>
<script>

    import { mapActions } from "vuex";
    import InteractionModuleInformationBox from "./InteractionModuleInformationBox.vue";
    import InteractionsWithoutModuleList from "./InteractionsWithoutModuleList.vue";
    import InteractionModuleUsesBox from "./InteractionModuleUsesBox.vue";
    import InteractionModulesService from "../../services/InteractionModulesService.js";
    import ARTargetService from "../../services/ARTargetService.js";
    import InteractionService from "../../services/InteractionService.js";
    import ListUtils from "../../utils/ListUtils.js";

    export default {
        components: {
            InteractionModuleInformationBox,
            InteractionsWithoutModuleList,
            InteractionModuleUsesBox
        },
        props: {
            reloadTrigger: { type: Number, default: 0 },
        },
        data() {
            return {
                list: [],
                headers: ListUtils.addLocalizationToHeaders([
                    { text: "Version", sortable: false },
                    { text: "Visiblity", sortable: false },
                    { text: "Title", sortable: false },
                    { text: "Description", sortable: false },
                    { text: "Uses", sortable: false },
                    { text: "", sortable: false }
                ], this),
                loading: false,
                interactionsWithoutModule: [],
                showInteractionsWithoutModuleList: false,
                showUses: false,
                showUsesOf: null,
                waitingForUpdateAfterDelete: null,
                showInformation: false,
                showInformationOn: null
            };
        },
        watch: {
            reloadTrigger() {
                this.update();
            }
        },
        created() {
            this.update();
        },
        methods: {
            update() {
                this.updateSectionTitle(this.l("Interaction Modules"));
                this.loading = true;
                this.interactionsWithoutModule = [];

                InteractionModulesService.getInteractionModules(list => {
                    this.list = this.prepareModulesList(list);
                    const moduleIdLookUp = this.createModuleIdLookUp(list);

                    ARTargetService.getARTargets(arTargets => {
                        const arTargetLookUp = this.createARTargetLookUp(arTargets);

                        InteractionService.getAllInteractions(interactions => {
                            this.assignUses(interactions, moduleIdLookUp, arTargetLookUp);
                            this.loading = false;

                            this.reopenWindowsAfterUpdate(moduleIdLookUp);
                        });
                    });
                });

            },
            prepareModulesList(list) {
                list.forEach(module => {
                    module.versions.forEach(version => {
                        version.moduleId = module.id;
                        version.uses = [];
                    });
                });
                return list;
            },
            createModuleIdLookUp(list) {
                const lookUp = {};
                list.forEach(module => {
                    lookUp[module.id] = {};
                    module.versions.forEach(version => {
                        lookUp[module.id][version.versionNumber] = version;
                        if (version.oldMongoDBIds) {
                            version.oldMongoDBIds.forEach(oldMongoDBId => {
                                lookUp[oldMongoDBId] = {};
                                lookUp[oldMongoDBId][0] = version;
                            });
                        }
                    });
                });
                return lookUp;
            },
            createARTargetLookUp(list) {
                const lookUp = {};
                list.forEach(arTarget => {
                    arTarget.preview = "/assets/" + arTarget.target + ".jpg?quality=veryLow";
                    lookUp[arTarget._id] = arTarget;
                });
                return lookUp;
            },
            assignUses(interactions, moduleIdLookUp, arTargetLookup) {
                interactions.forEach(interaction => {
                    if (arTargetLookup.hasOwnProperty(interaction.arTargetId)) {
                        interaction.arTarget = arTargetLookup[interaction.arTargetId];
                    }
                    const moduleId = interaction.interactionModuleId;
                    const moduleVersion = interaction.interactionModuleVersion || 0;
                    if (moduleIdLookUp.hasOwnProperty(moduleId)
                        && moduleIdLookUp[moduleId].hasOwnProperty(moduleVersion)) {
                        moduleIdLookUp[moduleId][moduleVersion].uses.push(interaction);
                    } else {
                        this.interactionsWithoutModule.push(interaction);
                    }
                });
            },
            toggleVisibility(entry) {
                entry.isVisibleToCurator = !entry.isVisibleToCurator;
                InteractionModulesService.updateInteractionModules(this.list, list => {
                    this.list = list;
                    this.$root.$emit("success", this.l("Interaction module's visibility updated."));
                });
            },
            reopenWindowsAfterUpdate(moduleIdLookUp) {
                if (this.waitingForUpdateAfterDelete) {
                    const waiting = this.waitingForUpdateAfterDelete;
                    if (waiting.interactionsWithoutModuleList) {
                        this.openInteractionsWithoutModuleList();
                    } else if (moduleIdLookUp.hasOwnProperty(waiting.moduleId)
                        && moduleIdLookUp[waiting.moduleId].hasOwnProperty(waiting.versionNumber)) {
                        this.openUses(moduleIdLookUp[waiting.moduleId][waiting.versionNumber]);
                    }
                    this.waitingForUpdateAfterDelete = null;
                }
            },
            openInteractionsWithoutModuleList() {
                this.showInteractionsWithoutModuleList = true;
            },
            closeInteractionsWithoutModuleList() {
                this.showInteractionsWithoutModuleList = false;
            },
            deletedInteractionWithoutModule() {
                this.waitingForUpdateAfterDelete = {
                    interactionsWithoutModuleList: true
                };
                this.closeInteractionsWithoutModuleList();
                this.update();
            },
            openUses(entry) {
                this.showUses = true;
                this.showUsesOf = entry;
            },
            closeUses() {
                this.showUses = false;
                this.showUsesOf = null;
            },
            deletedInteractionOfModule(interactionModule) {
                this.waitingForUpdateAfterDelete = {
                    moduleId: interactionModule.moduleId,
                    versionNumber: interactionModule.versionNumber
                };
                this.closeUses();
                this.update();
            },
            openInformation(entry) {
                this.showInformation = true;
                this.showInformationOn = entry;
            },
            closeInformation() {
                this.showInformation = false;
                this.showInformationOn = null;
            },
            l(token, values) {
                return this.$root.l(token, values);
            },
            ...mapActions({
                updateSectionTitle: "platform/updateSectionTitle"
            })
        }
    };
</script>
<style scoped>
    .clickable {
        cursor: pointer;
    }

    .element-id {
        background: #ddd;
        font-family: "Lucida Console", Monaco, monospace;
        padding: 3px 5px;
    }
</style>
