<template>
    <div>
        <h2 class="mt-4 mb-2">
            {{ l("Museum Movement") }}
        </h2>
        <div v-if="loading">
            <v-progress-linear indeterminate />
        </div>
        <AnalyticsDataTable
            v-if="tableData.length > 0"
            :headers="tableHeaders"
            :data="tableData"
            :fold-out-labels="foldOutLabels"
            allow-search
            @highlight="highlight"
            @unhighlight="unhighlight"
            @select="select"
        />
    </div>
</template>
<script>

    import AnalyticsDataTable from "./AnalyticsDataTable.vue";
    import ARTargetService from "../../services/ARTargetService.js";
    import InformationPageService from "../../services/InformationPageService.js";
    import AnalyticsService from "../../services/AnalyticsService.js";
    import { createVisitTable, getShare } from "../../utils/analyticsUtils.js";
    import { shortDuration, getPercentage } from "../../utils/StringUtils.js";
    import { getArtworkPageDescription } from "../../utils/referencePreviewUtils.js";
    import { getParameterValue } from '../../utils/ParameterUtils';
    import { inContentLocale } from "../../locales/utils/localeUtils.js";
    import { l, getLocalizedParameterValue } from "../../utils/LocalizationUtils.js";


    export default {
        components: {
            AnalyticsDataTable,
        },
        props: {
            exhibitionAndDateRange: { type: Object, default: null },
        },
        data() {
            return {
                loading: true,
                infoPageLookUp: {},
                arTargetsLookUp: {},

                tableHeaders: [
                    { sortable: false },
                    { text: l("AR target"), value: "label" },
                    { text: l("Total interaction time"), value: "usage", align: "end" },
                    { text: l("AR visits"), value: "visits", align: "end" },
                    { text: l("Time per visit"), value: "timePerVisit", align: "end" },
                    { text: l("Scanned first"), value: "first", align: "end" },
                    { text: l("Scanned last"), value: "last", align: "end" },
                    { text: l("Most common previous target"), sortable: false, align: "end" },
                    { text: l("Most common next target"), sortable: false, align: "end" },
                ],
                tableData: [],

                foldOutLabels: {
                    usage: l("Total interaction time with this target:"),
                    usageShare: l("Share among all targets:"),
                    visits: l("Number of scans of this target:"),
                    timePerVisit: l("Average duration of interaction:"),
                    first: l("Was the first target scanned:"),
                    last: l("Was the last target scanned:"),
                },
            };
        },
        watch: {
            exhibitionAndDateRange: {
                handler() {
                    if(this.exhibitionAndDateRange && this.exhibitionAndDateRange.exhibitionId) {
                        this.loadARTargetUsageData();
                    }
                },
                deep: true,
                immediate: true,
            },
        },
        methods: {

            async loadARTargetUsageData() {
                this.loading = true;

                // create art pieces & artist look up
                const infoPages = await InformationPageService.getInformationPages();
                if(!Array.isArray(infoPages)) {
                    return;
                }
                this.infoPageLookUp = {};
                infoPages.forEach(infoPage => {
                    this.infoPageLookUp[infoPage._id] = infoPage;
                });

                // create ar targets look up
                const arTargets = await ARTargetService.getARTargets();
                if(!Array.isArray(arTargets)) {
                    return;
                }
                this.arTargetsLookUp = {};
                arTargets.forEach(arTarget => {

                    let name = arTarget.title;
                    let subtitle = "";
                    let image = arTarget.target;

                    if(arTarget.linkedContent && this.infoPageLookUp[arTarget.linkedContent]) {
                        const artPiece = this.infoPageLookUp[arTarget.linkedContent];
                        name = getLocalizedParameterValue(inContentLocale(), artPiece.attributes, "name");
                        subtitle = getArtworkPageDescription(artPiece);
                        image = getParameterValue(artPiece.attributes, "image");
                    }

                    this.arTargetsLookUp[arTarget._id] = {
                        name,
                        subtitle,
                        image: "/assets/" + image + ".jpg?quality=veryLow&update=" + image.substring(2, 8),
                    };
                });

                const data = await AnalyticsService.getArTrackingEvents(this.exhibitionAndDateRange);

                let tableData = [];

                if(data?.arTargets) {

                    let sumOfTotalDurations = 0;
                    let sumOfFirsts = 0;
                    let sumOfLasts = 0;
                    for(const { totalDuration, firstARTarget, lastARTarget } of Object.values(data.arTargets)) {
                        sumOfTotalDurations += totalDuration;
                        sumOfFirsts += firstARTarget;
                        sumOfLasts += lastARTarget;
                    }

                    for(const [ arTargetId, arTargetData ] of Object.entries(data.arTargets)) {

                        const label = this.getARTargetTitle(arTargetId);
                        const usageShare = getShare(arTargetData.totalDuration, sumOfTotalDurations);
                        const timePerVisit = arTargetData.totalVisits > 0 ? (arTargetData.totalDuration / arTargetData.totalVisits) : 0;

                        tableData.push({
                            label,
                            usage: arTargetData.totalDuration,
                            usageShare,
                            visits: arTargetData.totalVisits,
                            timePerVisit,
                            first: arTargetData.firstARTarget,
                            firstShare: getShare(arTargetData.firstARTarget, sumOfFirsts),
                            last: arTargetData.lastARTarget,
                            lastShare: getShare(arTargetData.lastARTarget, sumOfLasts),
                            previous: createVisitTable(arTargetData.previousARTargets, key => this.getARTargetTitle(key)),
                            next: createVisitTable(arTargetData.nextARTargets, key => this.getARTargetTitle(key)),
                            foldOutImage: this.getARTargetImage(arTargetId),
                            foldOutTitle: label,
                            foldOutSubtitle: this.getARTargetSubtitle(arTargetId),
                            showDetails: false,
                        });
                    }
                }
                this.tableData = tableData;

                this.loading = false;
            },

            getARTargetTitle(arTargetId) {
                if(this.arTargetsLookUp[arTargetId]) {
                    return this.arTargetsLookUp[arTargetId].name;
                }
                return arTargetId;
            },

            getARTargetSubtitle(arTargetId) {
                if(this.arTargetsLookUp[arTargetId]) {
                    return this.arTargetsLookUp[arTargetId].subtitle;
                }
                return "";
            },

            getARTargetImage(arTargetId) {
                if(this.arTargetsLookUp[arTargetId]) {
                    return this.arTargetsLookUp[arTargetId].image;
                }
                return "";
            },

            highlight(row) {
                // TODO do something
            },

            unhighlight(row) {
                // TODO do something
            },

            select(row) {
                this.tableData.forEach(otherRow => {
                    if(otherRow !== row) {
                        otherRow.showDetails = false;
                    }
                });
                row.showDetails = !row.showDetails;
            },

            shortDuration,

            getPercentage,

            l,
        },
    }
</script>