
import { l, tryGetTokenOverride, selectLanguage } from "./localization.js";


// Contains app-specific translations
export const scanner = {


    
    loadingAR(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "loadingAR");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Loading AR...";
            case "de": return "Lädt AR...";
            default: return `[loadingAR: ${language} missing]`;
        }
    
    },
            
};
