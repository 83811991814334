import InformationPageService from "../../services/InformationPageService.js";
import InformationPageTypesService from "../../services/InformationPageTypesService.js";
import { isString } from "../../utils/StringUtils.js";
import { localize, getLocalizedParameterValue } from "../../utils/LocalizationUtils.js";
import store from "../../store/index.js";


function isPageObject(page) {
    return page && typeof page === "object" && page.informationPageTypeId && page.informationPageTypeVersion && page.attributes;
}


export function isReference(reference) {
    return reference && typeof reference === "object" && reference.reference;
}


export function pageExists(page) {

    if (isPageObject(page)) {
        return true;

    } else if (isReference(page)) {
        return store.getters["pages/pageExists"](page.reference);

    } else if (isString(page)) {
        return store.getters["pages/pageExists"](page);

    } else {
        return false;
    }
}


export function getPage(page) {

    if (isPageObject(page)) {
        return page;

    } else if (isReference(page)) {
        return store.getters["pages/getPage"](page.reference);

    } else if (isString(page)) {
        return store.getters["pages/getPage"](page);

    } else {
        return null;
    }
}


export function getPageName(locale, pageIdOrObjectOrReference, showLifespan) {

    let pageName = "";
    let referenceLabel = "";

    const page = getPage(pageIdOrObjectOrReference);
    if (page) {
        pageName = getLocalizedParameterValue(locale, page.attributes, "name");
        if (showLifespan) {
            const lifespan = getLocalizedParameterValue(locale, page.attributes, "lifespan");
            if (lifespan) {
                pageName += " (" + lifespan + ")";
            }
        }
    }

    if (isReference(pageIdOrObjectOrReference) && pageIdOrObjectOrReference.label) {
        referenceLabel = localize(locale, pageIdOrObjectOrReference.label);
    }

    if (referenceLabel) {
        if (pageName) {
            return referenceLabel.replace("{0}", pageName);
        } else {
            return referenceLabel;
        }
    } else {
        return pageName;
    }
}


export function getAllPages() {
    return store.getters["pages/getAllPages"];
}


export function getPageType(pageTypeId, pageTypeVersion) {
    return store.getters["pages/getPageType"](pageTypeId, pageTypeVersion);
}


export function getPageTypeOfPage(page) {
    const pageTypeId = page.informationPageTypeId;
    const pageTypeVersion = page.informationPageTypeVersion;
    return getPageType(pageTypeId, pageTypeVersion);
}


export function assignMostRecentTitleAndIcon(pageType) {
    let highestVersion = -1;
    pageType.versions.forEach(version => {
        if (version.versionNumber > highestVersion) {
            highestVersion = version.versionNumber;
            pageType.title = version.title;
            pageType.titlePlural = version.titlePlural;
            pageType.mdiIcon = version.mdiIcon;
        }
    });
    return pageType;
}


export function assignMostRecentTitlesAndIcons(pageTypes) {
    pageTypes.forEach(type => {
        assignMostRecentTitleAndIcon(type);
    });
    return pageTypes;
}


export function addEntryCounts(types) {
    types.forEach(type => {
        type.entries = 0;
    });
    getAllPages().forEach(page => {
        const pageType = getPageTypeOfPage(page);
        if (pageType) {
            const type = types.find(type => type.id == pageType.type.id);
            if (type) {
                type.entries++;
            }
        }
    });
}


export function createPageLookup() {
    return new Promise(async (resolve, reject) => {
        try {
            const pages = await InformationPageService.getInformationPages();
            if (!Array.isArray(pages)) {
                resolve({});
            }
            const pageLookup = {};
            pages.forEach(page => {
                pageLookup[page._id] = page;
            });
            resolve(pageLookup);
        } catch (error) {
            reject(`Could not create page lookup: ${error}`);
        }
    });
}


export async function createPageTypeLookup() {
    return new Promise(async (resolve, reject) => {
        try {
            const pageTypes = await InformationPageTypesService.getInformationPageTypes();
            if (!Array.isArray(pageTypes)) {
                resolve({});
            }
            const pageTypeLookup = {};
            pageTypes.forEach(type => {
                pageTypeLookup[type.id] = {};
                type.versions.forEach(version => {
                    pageTypeLookup[type.id][version.versionNumber] = version;
                    version.type = type;
                });
            });
            resolve(pageTypeLookup);
        } catch (error) {
            reject(`Could not create page type lookup: ${error}`);
        }
    });
}