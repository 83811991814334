<template>
    <div :class="{ 'pa-3': label, 'my-4': label, referenceInputBox: label, referenceInputBoxContained: !label, required: label && field.required }">
        <v-subheader v-if="label && !field.error && !field.inSingleFieldArrayEntry" class="manualLabel label">
            {{ label }}
        </v-subheader>
        <v-subheader v-if="label && field.error && !field.inSingleFieldArrayEntry" style="color:red" class="manualLabel label">
            {{ label }}
        </v-subheader>
        <p v-if="field.errorMessages && field.errorMessages.length > 0" style="color:red; margin: 8px 0 0 0; font-size:smaller;">
            {{ field.errorMessages[0] }}
        </p>
        <v-autocomplete
            v-model="field.value"
            :items="items"
            :error="field.error"
            :error-messages="field.errorMessages"
            item-text="label"
            item-value="_id"
            hide-selected
            multiple
            chips
            deletable-chips
            hide-details="auto"
            class="pa-0"
        />
    </div>
</template>
<script>

    import { getAllTags, getLabel } from '../../../tags/utils/tagUtils.js';
    import { l } from "../../../utils/LocalizationUtils.js";


    export default {
        model: {
            prop: "propField",
            event: "input"
        },
        props: {
            propField: { type: Object, default: null }
        },
        data() {
            return {
                tagsValue: null,
            };
        },
        computed: {
            field() {
                return this.propField;
            },
            label() {
                return this.field.label ? l(this.field.label) : null;
            },
            items() {
                const items = getAllTags();
                items.forEach(item => item.label = getLabel(item));
                return items;
            },
        },
    }
</script>
<style scoped>

    .referenceInputBox {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
        background: #f3f3f3;
        padding: 12px;
    }

    .referenceInputBoxContained {
        margin-top: 3px !important;
        margin-bottom: 3px !important;
    }

    .manualLabel {
        font-size: 16px;
        font-weight: 400;
        height: 18px;
        padding: 0;
        margin: 0 0 8px;
    }

</style>