var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"previewContainer"},[_c('div',{ref:"viewport",staticClass:"viewport"},[_c('div',{class:{ 'worksheet': true, 'placing': _vm.locationBeingEdited && !_vm.locationBeingEdited.locked },style:(_vm.worksheetStyle),on:{"click":_vm.clickOnFloor,"mousemove":_vm.mouseMoveOnFloor,"mouseover":_vm.mouseOverFloor,"mouseleave":_vm.mouseOutOfFloor}},[(_vm.horizontalGuideVisible)?_c('div',{class:{ 'horizontalGuide': true, 'snapping': _vm.snappedToY, 'locked': _vm.lockedToHorizontal },style:(_vm.horizontalGuideStyle)}):_vm._e(),(_vm.verticalGuideVisible)?_c('div',{class:{ 'verticalGuide': true, 'snapping': _vm.snappedToX, 'locked': _vm.lockedToVertical },style:(_vm.verticalGuideStyle)}):_vm._e(),_c('div',{ref:"floor",staticClass:"floor",style:(_vm.floorStyle)},[_c('img',{ref:"svg",attrs:{"src":_vm.svgFilepath},on:{"load":_vm.extractSVGSize}}),_vm._l((_vm.locations),function(location){return _c('div',{key:location._id,class:{
                        'location': true,
                        'hovered': _vm.isLocationBeingHoveredOver(location),
                        'placing': _vm.isLocationBeingPlaced(location),
                        'locked': _vm.isLocationLocked(location),
                        'snapping': _vm.isLocationBeingSnappedTo(location),
                        'lockedTo': _vm.isLocationBeingLockedTo(location),
                    },style:({
                        'left': (location.positionX * _vm.floorSize.width) + 'px',
                        'top': (location.positionY * _vm.floorSize.height) + 'px'
                    })},[_c('div',{staticClass:"clickable",on:{"click":function($event){return _vm.clickOnLocation(location, $event)},"mousemove":_vm.mouseMoveOnFloor,"mouseover":function($event){return _vm.mouseOverLocation(location)},"mouseout":function($event){return _vm.mouseOutOfLocation(location)}}}),_c('div',{staticClass:"highlightMarker"}),_c('div',{staticClass:"marker"}),(_vm.saving)?_c('v-progress-circular',{staticClass:"savingIndicator",attrs:{"indeterminate":"","color":"primary","size":"24"}}):_vm._e()],1)}),(_vm.tooltipVisible)?_c('div',{staticClass:"locationTooltip elevation-3",style:(_vm.tooltipStyle)},[_c('ReferencePreview',{attrs:{"type":"page","preview-data":_vm.locationBeingHoveredOver.previewData,"append-icon":_vm.locationBeingHoveredOver.locked ? 'mdi-lock' : null,"max-width":"346"}})],1):_vm._e()],2)])]),(_vm.loading || _vm.loadingSVG)?_c('div',{staticClass:"loadingSVG"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }