
import { l, tryGetTokenOverride, selectLanguage } from "./localization.js";
import { buttons } from "./localizationAppButtons.js";
import { general } from "./localizationAppGeneral.js";
import { pages } from "./localizationAppPages.js";
import { scanner } from "./localizationAppScanner.js";
import { tours } from "./localizationAppTours.js";


export const app = {
    buttons,
    general,
    pages,
    scanner,
    tours,

};
