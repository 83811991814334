<template>
    <v-layout row justify-center>
        <v-dialog :value="true" persistent max-width="1080px" scrollable>
            <v-card>
                <v-card-title class="pb-4">
                    <span class="headline primary--text">
                        <v-icon large color="primary" class="title-icon">
                            mdi-information-outline
                        </v-icon>
                        {{ l("Uses of ${v.title}", interactionModule) + " (" + interactionModule.moduleId + " v." + interactionModule.versionNumber + ")" }}
                    </span>
                </v-card-title>
                <v-divider />
                <v-card-text ref="scrollArea" class="pt-4">
                    <v-data-table hide-default-footer :headers="headers" :items="interactionModule.uses" :sort-by="sortBy" :items-per-page="-1" :no-data-text="l('No entries.')">
                        <template #item="row">
                            <tr>
                                <td>
                                    <span class="id-box">
                                        {{ row.item._id }}
                                    </span>
                                </td>
                                <td>
                                    <v-alert v-if="!row.item.arTarget" type="error" dense outlined tile class="body-2 my-1 py-1 px-2">
                                        {{ l("AR target missing!") }}
                                        <br>
                                        {{ row.item.arTargetId }}
                                    </v-alert>
                                    <img v-if="row.item.arTarget" :src="row.item.arTarget.preview" class="preview-image">
                                    <span v-if="row.item.arTarget" class="preview-title subtitle-2">
                                        {{ row.item.arTarget.title }}
                                    </span>
                                </td>
                                <td>
                                    {{ row.item.name }}
                                </td>
                                <td>
                                    {{ "x: " + row.item.positionX.toFixed(3) }}
                                    <br>
                                    {{ "y: " + row.item.positionY.toFixed(3) }}
                                </td>
                                <td class="text-right nobreak" style="width:68px">
                                    <v-tooltip left>
                                        <template #activator="{ on }">
                                            <v-btn text icon color="primary" v-on="on" @click="deleteEntry(row.item)">
                                                <v-icon>delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span class="body-2">{{ l("Remove") }}</span>
                                    </v-tooltip>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" text min-width="80" @click="close">
                        {{ l("Close") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-layout>
</template>
<script>

    import InteractionService from "../../services/InteractionService.js";

    export default {
        props: {
            interactionModule: { type: Object, default: null },
        },
        data() {
            return {
                headers: [
                    { text: this.l("ID"), value: "_id" },
                    { text: this.l("AR Target"), value: "arTargetId" },
                    { text: this.l("Name"), value: "name" },
                    { text: this.l("Location"), value: "locationX" },
                    { text: "", sortable: false }
                ],
                sortBy: "name",
            };
        },
        methods: {
            deleteEntry(entry) {
                this.$root.$emit(
                    "confirm",
                    this.l("Remove Interaction?"),
                    this.l("Are you sure you want to remove the interaction \"${v.name}\"?", entry),
                    this.l("Delete"),
                    yes => {
                        if (yes) {
                            const deletingEntry = entry;
                            InteractionService.deleteInteraction(entry._id, () => {
                                this.$root.$emit("success", this.l("Interaction \"${v.name}\" removed.", deletingEntry));
                                this.$emit("deletedInteractionOfModule", this.interactionModule);
                            });
                        }
                    }
                );
            },
            close() {
                this.$emit("close");
            },
            l(token, values) {
                return this.$root.l(token, values);
            }
        }
    }
</script>
<style scoped>

    .title-icon {
        vertical-align: sub;
    }

    .id-box {
        background: #ddd;
        font-family: "Lucida Console", Monaco, monospace;
        padding: 5px;
        color: #111;
    }

    .preview-image {
        display: block;
        object-fit: contain;
        float: left;
        width: 42px;
        height: 42px;
        margin: 2px 8px 2px 0;
    }

    .preview-title {
        line-height: 42px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

</style>