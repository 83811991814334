<template>
    <div>
        <div style="display:inline-flex;">
            <v-tooltip top>
                <template #activator="{ on }">
                    <v-btn text icon color="primary" style="margin: 1px 10px 0 0;" v-on="on" @click="closeInteractionsList()">
                        <v-icon size="38px">
                            arrow_back
                        </v-icon>
                    </v-btn>
                </template>
                <span class="body-2">{{ l("Back to list of AR targets") }}</span>
            </v-tooltip>
            <h1 v-if="arTarget.label" class="display-1 mb-3 primary--text">
                {{ l("Interactions of \"${v}\"", arTarget.label) }}
            </h1>
            <h1 v-if="!arTarget.label" class="display-1 mb-3 primary--text">
                {{ l("Interactions") }}
            </h1>
        </div>
        <div class="text-center">
            <div id="poi-position-box">
                <img id="poi-position" ref="poiPosition" :src="'/assets/' + arTarget.image + '.jpg'" @load="reportImageLoaded">
                <div v-for="(poi, index) in list" :key="index" :ref="'poi_' + poi._id" class="poi">
                    <div class="poi-label">
                        {{ poi.name }}
                    </div>
                </div>
                <div id="poi-position-interactable" :class="poiPositionImgClass" @click="placePoi">
                    &nbsp;
                </div>
            </div>
        </div>
        <v-layout justify-end row class="pb-5 pr-3">
            <v-select
                v-model="activeInteractionModule"
                flat
                color="primary"
                :items="interactionModulesSelection"
                :disabled="loading"
                style="max-width:250px"
                :label="l('Select Interaction Type...')"
                :loading="loading"
                no-data-text="No Interaction Modules found"
            />
            <v-btn color="primary" class="pl-2" @click="addInteraction">
                <v-icon class="mr-2">
                    add
                </v-icon>
                {{ l("Add \"${v}\" interaction", activeInteractionModule ? l(activeInteractionModule.title) : "") }}
            </v-btn>
        </v-layout>
        <v-data-table :headers="headers" :items="list" :sort-by="sortBy" :items-per-page="itemsPerPage" :footer-props="pagination" :loading="loading" :no-data-text="l('No entries.')">
            <template #item="row">
                <tr>
                    <td class="clickable" @click="editInteraction(row.item)">
                        {{ row.item.name }}
                    </td>
                    <td v-if="row.item.interactionModule" class="clickable" @click="editInteraction(row.item)">
                        {{ l(row.item.interactionModule.title) }}
                    </td>
                    <td v-if="!row.item.interactionModule" class="clickable red--text font-weight-bold" @click="editInteraction(row.item)">
                        {{ l("Unknown:") + " " + row.item.interactionModuleId + " v." + (row.item.interactionModuleVersion || 0) }}
                    </td>
                    <td class="nobreak">
                        <v-tooltip v-if="row.item.isIntroduction" right>
                            <template #activator="{ on }">
                                <v-btn text :disabled="!row.item.interactionModule || (introductionChoiceLock && !row.item.showLoading)" :loading="row.item.showLoading" icon color="primary" v-on="on" @click="setIntroduction(row.item, false)">
                                    <v-icon>mdi-radiobox-marked</v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">{{ l("Dismiss as introduction") }}</span>
                        </v-tooltip>
                        <v-tooltip v-if="!row.item.isIntroduction" right>
                            <template #activator="{ on }">
                                <v-btn text :disabled="!row.item.interactionModule || (introductionChoiceLock && !row.item.showLoading)" :loading="row.item.showLoading" icon color="primary" v-on="on" @click="setIntroduction(row.item, true)">
                                    <v-icon>mdi-radiobox-blank</v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">{{ l("Choose as introduction") }}</span>
                        </v-tooltip>
                        <v-tooltip v-if="row.item.isIntroduction && !row.item.hidePoiIfIntroduction" right>
                            <template #activator="{ on }">
                                <v-btn text :disabled="!row.item.interactionModule || introductionChoiceLock" icon color="primary" v-on="on" @click="setIntroductionPoiVisibility(row.item, true)">
                                    <v-icon>mdi-map-marker-check</v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">{{ l("Visible as point of interest after introduction") }}</span>
                        </v-tooltip>
                        <v-tooltip v-if="row.item.isIntroduction && row.item.hidePoiIfIntroduction" right>
                            <template #activator="{ on }">
                                <v-btn text :disabled="!row.item.interactionModule || introductionChoiceLock" icon color="primary" v-on="on" @click="setIntroductionPoiVisibility(row.item, false)">
                                    <v-icon>mdi-map-marker-off-outline</v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">{{ l("Not visible as point of interest after introduction") }}</span>
                        </v-tooltip>
                    </td>
                    <td class="clickable" @click="editInteraction(row.item)">
                        {{ "x: " + row.item.positionX.toFixed(3) + " / y: " + row.item.positionY.toFixed(3) }}
                    </td>
                    <td class="text-right nobreak" style="width:176px">
                        <v-tooltip left>
                            <template #activator="{ on }">
                                <v-btn text :disabled="!row.item.interactionModule" icon color="primary" v-on="on" @click="startPoiPlacement(row.item)">
                                    <v-icon>location_on</v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">{{ l("Change interaction's position") }}</span>
                        </v-tooltip>
                        <v-tooltip v-if="!row.item.hideInBasicAR" left>
                            <template #activator="{ on }">
                                <v-btn text :disabled="!row.item.interactionModule" icon color="primary" v-on="on" @click="toggleVisibility(row.item)">
                                    <v-icon>mdi-eye</v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">{{ l("Hide in regular Art Scanner mode") }}</span>
                        </v-tooltip>
                        <v-tooltip v-if="row.item.hideInBasicAR" left>
                            <template #activator="{ on }">
                                <v-btn text :disabled="!row.item.interactionModule" icon color="primary" v-on="on" @click="toggleVisibility(row.item)">
                                    <v-icon>mdi-eye-off-outline</v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">{{ l("Show in regular Art Scanner mode") }}</span>
                        </v-tooltip>
                        <v-tooltip left>
                            <template #activator="{ on }">
                                <v-btn text :disabled="!row.item.interactionModule" icon color="primary" v-on="on" @click="editInteractionSettings(row.item)">
                                    <v-icon>settings</v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">{{ l("Edit Settings of Interaction") }}</span>
                        </v-tooltip>
                        <v-tooltip left>
                            <template #activator="{ on }">
                                <v-btn text :disabled="!row.item.interactionModule" icon color="primary" v-on="on" @click="deleteEntry(row.item)">
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">{{ l("Remove") }}</span>
                        </v-tooltip>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>
<script>

    import InteractionService from "../../services/InteractionService.js";
    import InteractionModulesService from "../../services/InteractionModulesService.js";
    import ListUtils from "../../utils/ListUtils.js";
    import { l } from "../../utils/LocalizationUtils.js";
    import { triggerError } from "../../utils/alerts.js";


    export default ListUtils.setupList({
        props: {
            arTarget: { type: Object, default: null },
        },
        data() {
            return {
                interactionModulesSelection: [],
                interactionModulesById: {},
                activeInteractionModule: null,
                placingPoi: null,
                poiPositionImgClass: "",
                imageIsLoaded: false,
                introductionChoiceLock: false
            };
        },
        headers: [
            { text: "Name", value: "name" },
            { text: "Interaction Type", value: "type" },
            { text: "Introduction", value: "isIntroduction" },
            { text: "Coordinates", value: "locationX" },
            { text: "", sortable: false }
        ],
        sortBy: "name",
        created() {
            this.showLoading();
            InteractionModulesService.getInteractionModules(interactionModules => {

                this.interactionModulesSelection = [];
                this.interactionModulesById = {};

                interactionModules.forEach(interactionModule => {

                    const availableVersions = [];

                    interactionModule.versions.forEach(version => {
                        if (version.isVisibleToCurator) {
                            availableVersions.push({
                                text: this.l(version.title),
                                value: {
                                    id: interactionModule.id,
                                    ...version
                                }
                            });
                        }
                        this.interactionModulesById[interactionModule.id + ":" + version.versionNumber] = version;
                        if (version.oldMongoDBIds) {
                            version.oldMongoDBIds.forEach(oldMongoDBId => {
                                this.interactionModulesById[oldMongoDBId + ":0"] = version;
                            });
                        }
                    });
                    availableVersions.forEach(version => {
                        if (availableVersions.length > 1) {
                            version.text += " (v" + version.value.versionNumber + ")";
                        }
                        this.interactionModulesSelection.push(version);
                    });
                });
                
                if (this.interactionModulesSelection.length > 0) {
                    this.activeInteractionModule = this.interactionModulesSelection[0].value;
                }
                this.update();
            });
        },
        update() {
            this.showLoading();
            InteractionService.getInteractionsByARTarget(this.arTarget._id, list => {
                list.forEach(interaction => {
                    const version = interaction.interactionModuleVersion || 0;
                    const versionLookupKey = interaction.interactionModuleId + ":" + version;
                    if (this.interactionModulesById.hasOwnProperty(versionLookupKey)) {
                        interaction.interactionModule = this.interactionModulesById[versionLookupKey];
                    } else {
                        interaction.interactionModule = null;
                    }
                });
                this.list = list;
                this.hideLoading();
                if (this.imageIsLoaded) {
                    this.$nextTick(() => {
                        this.updatePoiOnPicture();
                    });
                }
            });
        },
        deleteSettings: {
            promptTitle: "Remove Interaction?",
            promptQuestion: "Are you sure you want to remove the interaction \"${v.name}\"?",
            action: InteractionService.deleteInteraction,
            success: "Interaction \"${v.name}\" removed."
        },
        methods: {
            addInteraction() {
                if (typeof this.activeInteractionModule !== "undefined" && this.activeInteractionModule != null) {
                    this.$emit("add", this.activeInteractionModule);
                }
            },
            editInteraction(interaction) {
                if (interaction.interactionModule) {
                    this.$emit("edit", interaction);
                } else {
                    triggerError(l("Error: Cannot edit interaction with unknown interaction module."));
                }
            },
            editInteractionSettings(interaction) {
                if (interaction.interactionModule) {
                    this.$emit("editSettings", interaction);
                } else {
                    triggerError(l("Error: Cannot edit interaction with unknown interaction module."));
                }
            },
            closeInteractionsList() {
                this.$emit("close");
            },
            startPoiPlacement(interaction) {
                this.placingPoi = interaction;
                this.poiPositionImgClass = "crosshair-cursor";
            },
            placePoi(event) {
                if (this.placingPoi != null) {
                    const rect = event.target.getBoundingClientRect();
                    let x = (event.clientX - rect.left) / rect.width;
                    let y = (event.clientY - rect.top) / rect.height;
                    if (x >= 0.0 && x <= 1.0 && y >= 0.0 && y <= 1.0) {
                        this.placingPoi.positionX = x;
                        this.placingPoi.positionY = y;
                        this.saveInteraction(this.placingPoi, () => {
                            this.placingPoi = null;
                            this.poiPositionImgClass = "";
                            this.update();
                        });
                    }
                }
            },
            updatePoiOnPicture() {
                let foundAllPoi = true;
                let image = this.$refs["poiPosition"];
                this.list.forEach(elem => {
                    if (this.$refs.hasOwnProperty("poi_" + elem._id)) {
                        this.$refs["poi_" + elem._id][0].style.left = ((elem.positionX * image.width) - 4) + "px";
                        this.$refs["poi_" + elem._id][0].style.top = ((elem.positionY * image.height) - 4) + "px";
                    } else {
                        foundAllPoi = false;
                    }
                });
                if (!foundAllPoi) {
                    this.$nextTick(() => {
                        this.updatePoiOnPicture();
                    });
                }
            },
            setIntroduction(interaction, setAsIntroduction) {

                if (!this.introductionChoiceLock) {
                    interaction.showLoading = true;
                    this.introductionChoiceLock = true;
                    let waitingForOtherInteraction = false;

                    const successMessage = () => {
                        this.introductionChoiceLock = false;
                        interaction.showLoading = false;
                        this.$root.$emit("success", this.l("Introduction settings saved for \"${v}\"", this.arTarget.title));
                    };

                    this.list.forEach(otherInteraction => {
                        if (otherInteraction.isIntroduction) {
                            waitingForOtherInteraction = true;

                            otherInteraction.isIntroduction = false;
                            this.saveInteraction(otherInteraction, () => {

                                if (setAsIntroduction) {
                                    interaction.isIntroduction = true;
                                    this.saveInteraction(interaction, successMessage);

                                } else {
                                    successMessage();
                                }
                            });
                        }
                    });
                    if (setAsIntroduction && !waitingForOtherInteraction) {
                        interaction.isIntroduction = true;
                        this.saveInteraction(interaction, successMessage);
                    }
                }
            },
            setIntroductionPoiVisibility(interaction, hidden) {
                if (!this.introductionChoiceLock) {
                    interaction.showLoading = true;
                    this.introductionChoiceLock = true;

                    interaction.hidePoiIfIntroduction = hidden;
                    this.saveInteraction(interaction, () => {
                        this.introductionChoiceLock = false;
                        interaction.showLoading = false;
                        this.$root.$emit("success", this.l("Introduction settings saved for \"${v}\"", this.arTarget.title));
                    });
                }
            },
            toggleVisibility(interaction) {
                interaction.hideInBasicAR = !interaction.hideInBasicAR;
                this.saveInteraction(interaction, () => {
                    this.$root.$emit("success", this.l("Updated visibility of \"${v.name}\"", interaction));
                });
            },
            saveInteraction(interaction, callback) {
                let interactionObject = {
                    _id: interaction._id,
                    name: interaction.name,
                    arTargetId: interaction.arTargetId,
                    interactionModuleId: interaction.interactionModuleId,
                    interactionModuleVersion: interaction.interactionModuleVersion || 0,
                    isIntroduction: interaction.isIntroduction || false,
                    hidePoiIfIntroduction: interaction.hidePoiIfIntroduction || false,
                    poiLabel: interaction.poiLabel || {},
                    hidePoiLabel: interaction.hidePoiLabel || false,
                    poiIcon: interaction.poiIcon || "dot",
                    hideInBasicAR: interaction.hideInBasicAR || false,
                    openOnTrackingStart: interaction.openOnTrackingStart,
                    closeOnTrackingEnd: interaction.closeOnTrackingEnd,
                    cannotBeClosed: interaction.cannotBeClosed,
                    showInfoBoxDuringInteraction: interaction.showInfoBoxDuringInteraction,
                    isBackgroundOfPOISelection: interaction.isBackgroundOfPOISelection || false,
                    transform: interaction.transform,
                    tags: interaction.tags || [],
                    positionX: interaction.positionX,
                    positionY: interaction.positionY
                };
                interaction.parameters.forEach(param => {
                    if(param.key) {
                        interactionObject[param.key] = param.value;
                    } else {
                        interactionObject[param.name] = param.value; // backward compatability
                    }
                });
                InteractionService.updateInteraction(interactionObject, callback);
            },
            reportImageLoaded() {
                if (this.list != null && this.list.length > 0) {
                    this.updatePoiOnPicture()
                }
                this.imageIsLoaded = true;
            },
            l
        }
    });
</script>
<style>
    .clickable {
        cursor: pointer;
    }

    #poi-position-box {
        position: relative;
        border: 1px solid #333;
        margin: 20px;
        display: inline-block;
    }

    #poi-position {
        max-width: 500px;
        max-height: 500px;
        vertical-align: bottom;
    }

    #poi-position-interactable {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .crosshair-cursor {
        cursor: crosshair;
    }

    .poi {
        position: absolute;
        width: 8px;
        height: 8px;
        border: solid 2px #fff;
        background: #000;
        border-radius: 4px;
    }

    .poi-label {
        position: absolute;
        left: 8px;
        top: 8px;
        background: #fff;
        opacity: 0.4;
        font-size: 12px;
        padding: 1px 2px;
        border-radius: 3px;
    }

</style>