<template>
    <div>
        <h2 class="title primary--text mb-5">
            {{ l("Server Settings") }}
        </h2>
        <InpageForm v-model="form" />
        <div class="upgradeScriptBox subtitle-2 mb-3">
            {{ l("Upgrade Script") }}
            <v-btn to="/upgrade-script/" color="primary" text class="ml-5">
                {{ l("Upgrade") }}
            </v-btn>
        </div>
    </div>
</template>
<script>

    import { mapActions } from "vuex";
    import ServerSettingsService from "../../services/ServerSettingsService.js";
    import InpageForm from "../globalUiElements/InpageForm.vue";
    import { createForm } from "../../utils/FormUtils.js";
    import { l } from "../../utils/LocalizationUtils.js";


    export default {
        components: {
            InpageForm
        },
        props: {
            reloadTrigger: { type: Number, default: 0 },
        },
        data() {
            return {
                form: {}
            };
        },
        watch: {
            reloadTrigger() {
                this.update();
            }
        },
        mounted() {
            this.update();
        },
        methods: {
            update() {
                this.updateSectionTitle(this.l("Server Settings"));
                ServerSettingsService.get(doc => {  
                    this.form = createForm({

                        action: ServerSettingsService.update,

                        fieldDefs: [
                            {
                                key: "curationLock",
                                label: "Curation lock",
                                type: "Switch",
                                value: doc.curationLock || false
                            },
                            {
                                key: "googleDataStudioIFrameURL",
                                label: "Google Data Studio iFrame URL",
                                type: "Text",
                                value: doc.googleDataStudioIFrameURL || ""
                            },
                        ],

                        submitLabel: "Save",

                        submitted: serverSettings => {
                            this.$root.$emit("success", l("Server settings updated.", serverSettings));
                        }
                    })
                });
            },
            ...mapActions({
                updateSectionTitle: "platform/updateSectionTitle"
            }),
            l
        }
    }
</script>
<style scoped>

    .upgradeScriptBox {
        margin-top: 220px;
        padding: 20px;
        background: #eee;
        border-radius: 5px;
        width: fit-content;
    }

</style>