class FrontendEnvService {

    static getEnvFile(callback) {
        let content = "";
        for (let key in process.env) {
            content += key + "=" + process.env[key] + "\n";
        }
        callback(content);
    }

}

export default FrontendEnvService;