<template>
    <div id="alerts">
        <div v-for="(alert, index) in alerts" :key="index">
            <v-alert :type="alert.type" :class="alert.class" dismissible>
                {{ alert.message }}
            </v-alert>
        </div>
    </div>   
</template>
<script>

    import { mapActions, mapGetters } from "vuex";
    import { triggerError, triggerWarning, triggerSuccess, triggerInfo } from "../../utils/alerts.js";


    export default {
        computed: {
            ...mapGetters({
                alerts: "alerts/getAlerts"
            })
        },
        mounted() {
            this.$root.$on("error", message => { // TODO This should be replaced by utils/alert.js on the long term
                triggerError(message);
            });
            this.$root.$on("warning", message => { // TODO This should be replaced by utils/alert.js on the long term
                triggerWarning(message);
            });
            this.$root.$on("success", message => { // TODO This should be replaced by utils/alert.js on the long term
                triggerSuccess(message);
            });
            this.$root.$on("info", message => { // TODO This should be replaced by utils/alert.js on the long term
                triggerInfo(message);
            });
            setInterval(this.update, 250);
        },
        methods: {
            ...mapActions({
                update: "alerts/updateAlerts"
            })
        }
    }
</script>
<style scoped>
    #alerts {
        position: fixed;
        bottom: 20px;
        right: 20px;
    }

    @keyframes fade-out {
        from { opacity: 1; }
        to { opacity: 0; }
    }

    .fade-out {
        animation-name: fade-out;
        animation-duration: 2s;
    }
    
</style>