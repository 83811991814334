<template>
    <div>
        <h2 class="title primary--text">
            {{ l("App Settings") }}
        </h2>
        <AppSettingsList :reload-trigger="listReloadTrigger" @add="openCreateForm" @edit="openEditForm" />
        <CreateAppSetting v-if="showCreateForm" @cancel="closeCreateForm" @createdEntry="createdEntry" />
        <EditAppSetting v-if="showEditForm" :entry="editingEntry" @cancel="closeEditForm" @editedEntry="editedEntry" />
    </div>
</template>
<script>
    import AppSettingsList from "./AppSettingsList.vue";
    import CreateAppSetting from "./CreateAppSetting.vue";
    import EditAppSetting from "./EditAppSetting.vue";

    export default {
        components: {
            AppSettingsList,
            CreateAppSetting,
            EditAppSetting
        },
        props: {
            reloadTrigger: { type: Number, default: 0 },
        },
        data() {
            return {
                listReloadTrigger: 0,
                showCreateForm: false,
                showEditForm: false,
                editingEntry: null
            };
        },
        watch: {
            reloadTrigger() {
                this.listReloadTrigger++;
            }
        },
        methods: {
            openCreateForm() {
                this.showCreateForm = true;
            },
            closeCreateForm() {
                this.showCreateForm = false;
            },
            createdEntry() {
                this.closeCreateForm();
                this.listReloadTrigger++;
            },
            openEditForm(entry) {
                this.editingEntry = entry;
                this.showEditForm = true;
            },
            closeEditForm() {
                this.showEditForm = false;
                this.editingEntry = null;
            },
            editedEntry() {
                this.closeEditForm();
                this.listReloadTrigger++;
            },
            l(token, values) {
                return this.$root.l(token, values);
            }
        }
    }
</script>
