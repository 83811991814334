
import { l, tryGetTokenOverride, selectLanguage } from "./localization.js";


// Contains general translations
export const other = {


    
    languageName(languageTag, locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "languageName");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        switch(languageTag) {
            case "en": return "English";
            case "en-US": return "English (US)";
            case "de": return "Deutsch";
            case "de-CH": return "Deutsch (DE)";
            case "de-DE": return "Deutsch (CH)";
            case "fr": return "Français";
            case "fr-CH": return "Français (CH)";
            default: return `[${languageTag} not supported]`;
        }
            
    },
            

    
    language(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "language");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return "Language";
            case "de": return "Sprache";
            case "fr": return "Langue";
            default: return `[language: ${language} missing]`;
        }
    
    },
            

    
    fontSize(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "fontSize");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Font Size";
            case "de": return "Schriftgröße";
            default: return `[fontSize: ${language} missing]`;
        }
    
    },
            

    
    skip(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "skip");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Skip";
            case "de": return "Überspringen";
            default: return `[skip: ${language} missing]`;
        }
    
    },
            

    
    close(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "close");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Close";
            case "de": return "Schliessen";
            default: return `[close: ${language} missing]`;
        }
    
    },
            

    
    settings(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "settings");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Settings";
            case "de": return "Einstellungen";
            default: return `[settings: ${language} missing]`;
        }
    
    },
            

    
    step(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "step");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Step";
            case "de": return "Schritt";
            default: return `[step: ${language} missing]`;
        }
    
    },
            

    
    slide(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "slide");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Slide";
            case "de": return "Seite";
            default: return `[slide: ${language} missing]`;
        }
    
    },
            

    
    pageIndicator(label, curPage, numPages, locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "pageIndicator");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        return label + " " + curPage + "/" + numPages;
    },
            

    
    pageIndicatorNoLabel(curPage, numPages, locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "pageIndicatorNoLabel");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        return curPage + "/" + numPages;
    },
            
};
