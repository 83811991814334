<template>
    <div class="defaultContentView">
        <h1 class="display-1 mb-3 primary--text">
            Welcome!
        </h1>
    </div>
</template>
<script>
    export default {
    }
</script>
