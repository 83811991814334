<template>
    <div class="defaultContentView">
        <ViewTitle
            :title="pageTitle"
            icon="mdi-gauge-full"
        />
        <div class="report">
            <div v-for="(element, index) in usage.report" :key="index" class="elementContainer">
                <UsageReportElement
                    :element="element"
                    :limits="usage.limits"
                />
            </div>
        </div>
    </div>
</template>
<script>

    import ViewTitle from "../../components/globalUiElements/ViewTitle.vue";
    import UsageReportElement from "../components/UsageReportElement.vue";
    import { updateLookups } from "../../utils/referenceUtils";
    import { getUsage } from "../utils/usageUtils.js";
    import { updateDocumentTitle } from "../../platform/utils/platformUtils.js";
    import { l } from "../../localization/localization.js";


    export default {
        components: {
            ViewTitle,
            UsageReportElement,
        },
        computed: {
            pageTitle: () => l.cms.usage.pageTitle(),
            usage: () => getUsage(),
        },
        async mounted() {
            updateDocumentTitle(this.pageTitle);
            await updateLookups(["usage"]);
        },
    }

</script>
<style scoped>

    .report {
        max-width: 800px;
    }

    .report .elementContainer {
        display: flex;
        align-items: center;
    }

</style>