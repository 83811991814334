import axios from "axios";
import router from "../router/router.js";
import store from "../store/index.js";


export function routeAuthorizer(to, from, next) {
    if (store.getters["platform/isLoading"]) {
        setTimeout(() => {
            routeAuthorizer(to, from, next);
        }, 50);
    } else {
        if (doesUserHaveAccessToRoute(to)) {
            next();
        } else {
            next("/403");
        }
    }
}


export function setupAutoLogout() {

    axios.interceptors.response.use(response => {
        return response;

    }, error => {
        // logout if user tries to access forbidden API (probably their session has timed-out)
        if (error.response.status == 403) {
            store.dispatch("logout");
        } else {
            return Promise.reject(error);
        }
    });
}


export function checkAccessRightsToCurrentRoute() {
    if (!doesUserHaveAccessToRoute(router.currentRoute)) {
        router.push("/403");
    }
}


function doesUserHaveAccessToRoute(route) {
    if (route && route.meta.hasOwnProperty("accessZone")) {
        return store.getters["user/hasAccessRights"](route.meta.accessZone);
    } else {
        return true;
    }
}