<template>
    <v-dialog
        ref="dialog"
        v-model="timeModal"
        :return-value.sync="field.value"
        persistent
        width="290px"
    >
        <template #activator="{ on, attrs }">
            <v-text-field
                v-model="field.value"
                :label="label"
                :error="field.error"
                :error-messages="field.errorMessages"
                prepend-icon="mdi-clock-time-four-outline"
                readonly
                v-bind="attrs"
                v-on="on"
            />
        </template>
        <v-time-picker
            v-if="timeModal"
            v-model="time"
            full-width
            format="24hr"
            use-seconds
        >
            <v-spacer />
            <v-btn text color="primary" @click="timeModal = false">
                Cancel
            </v-btn>
            <v-btn text color="primary" @click="$refs.dialog.save(time)">
                OK
            </v-btn>
        </v-time-picker>
    </v-dialog>
</template>

<script>

    import {l} from "../../../utils/LocalizationUtils.js";

    export default {

        model: {
            prop: "propField",
            event: "input"
        },
        props: {
            propField: {type: Object, default: null},
            waitingForResponse: {type: Boolean, default: false}
        },
        data() {
            return {
                time: null,
                timeModal: false,
            }
        },
        computed: {
            field() {
                return this.propField;
            },
            label() {
                return this.field.label ? l(this.field.label) : null;
            },

        },
        methods: {
            l
        }
    }
</script>

<style scoped>

</style>