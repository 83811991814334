<template>
    <div class="mt-3">
        <v-data-table hide-default-footer :custom-sort="customSort" :headers="headers" :items="list" :items-per-page="itemsPerPage" :footer-props="pagination" :loading="loading" :no-data-text="l('No entries.')">
            <template #item="row">
                <tr>
                    <td class="clickable pr-0" style="width:40px" @click="open(row.item)">
                        <v-icon>
                            {{ row.item.mdiIcon }}
                        </v-icon>
                    </td>
                    <td class="clickable" style="width:320px" @click="open(row.item)">
                        <strong>
                            {{ l(row.item.titlePlural) }}
                        </strong>
                    </td>
                    <td class="clickable" @click="open(row.item)">
                        {{ row.item.entries }}
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>
<script>

    import InformationPageTypesService from "../../services/InformationPageTypesService.js";
    import ListUtils from "../../utils/ListUtils.js";
    import { updateLookups } from "../../utils/referenceUtils.js";
    import { assignMostRecentTitlesAndIcons, addEntryCounts } from "../../pages/utils/pageUtils.js";
    import { l } from "../../utils/LocalizationUtils.js";


    export default ListUtils.setupList({
        headers: [
            { text: "", sortable: false },
            { text: "Category", sortable: false },
            { text: "Entries", sortable: false }
        ],
        async update() {
            this.showLoading();

            await updateLookups("pages");

            InformationPageTypesService.getInformationPageTypesForEncyclopedia(list => {
                addEntryCounts(list);
                this.list = assignMostRecentTitlesAndIcons(list);
                this.hideLoading();
            });
        },
        itemsPerPage: -1,
        sortBy: "titlePlural",
        methods: {
            open(type) {
                this.$router.push("/encyclopedia/" + type.id);
            },
            customSort(items) {
                items.sort((a, b) => {
                    return l(a.titlePlural).localeCompare(l(b.titlePlural));
                });
                return items;
            },
            l
        }
    });
</script>
<style scoped>

    .clickable {
        cursor: pointer;
    }

</style>