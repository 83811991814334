<template>
    <v-switch
        :value="visibility"
        :value-comparator="v => v"
        :label="toggleLabel"
        :loading="loading"
        @change="toggleVisibility"
    />
</template>
<script>
    // :value-comparator="v => v" is a necessary hack to get v-switch to update

    import { l } from "../../localization/localization.js";


    export default {
        props: {
            visibility: { type: Boolean, default: false },
            loading: { type: Boolean, default: false },
        },
        computed: {
            toggleLabel: () => l.cms.usage.visibilityToggleLabel(),
        },
        methods: {
            toggleVisibility() {
                this.$emit("saveVisibility", !this.visibility);
            },
        },
    }
</script>