<template>
    <div>
        <ViewTitle
            :title="l('Floorplan Navigation')"
            icon="mdi-view-list"
        />
    </div>
</template>
<script>

    import { mapActions } from "vuex";
    import ViewTitle from "../../components/globalUiElements/ViewTitle.vue";
    import { l } from "../../utils/LocalizationUtils.js";


    export default {
        components: {
            ViewTitle,
        },
        mounted() {
            this.updateSectionTitle(this.l("Floorplan Navigation"));
        },
        methods: {
            l,
            ...mapActions({
                updateSectionTitle: "platform/updateSectionTitle"
            })
        }
    }
</script>
