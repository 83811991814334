
import { l, tryGetTokenOverride, selectLanguage } from "./localization.js";


// Translations for global curation lock overlays
export const curationLock = {


    
    popupTitle(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "popupTitle");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Maintenance";
            case "de": return "Wartungsarbeiten";
            default: return `[popupTitle: ${language} missing]`;
        }
    
    },
            

    
    popupDescription(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "popupDescription");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "The curation system is currently undergoing maintenance. This should only last a few minutes.";
            case "de": return "Das Kurations-System wird gerade gewartet. Die Arbeiten sollten nur wenige Minuten dauern.";
            default: return `[popupDescription: ${language} missing]`;
        }
    
    },
            

    
    bypassButtonLabel(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "bypassButtonLabel");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Bypass";
            case "de": return "Sperre umgehen";
            default: return `[bypassButtonLabel: ${language} missing]`;
        }
    
    },
            

    
    engagedMessage(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "engagedMessage");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Curation lock engaged!";
            case "de": return "Kurationssperre aktiv!";
            default: return `[engagedMessage: ${language} missing]`;
        }
    
    },
            
};
