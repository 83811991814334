
import { l, tryGetTokenOverride, selectLanguage } from "./localization.js";
import { datetime } from "./localizationGeneralDatetime.js";
import { other } from "./localizationGeneralOther.js";


export const general = {
    datetime,
    other,

};
