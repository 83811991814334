<template>
    <div class="defaultContentView">
        <h1 class="display-1 mb-3 primary--text">
            <v-icon color="primary" class="titleIcon">
                mdi-sign-direction
            </v-icon>
            {{ l("Tours") }}
        </h1>
        <ToursList
            @add="add"
            @editInfo="editInfo"
            @editStops="editStops"
        />
    </div>
</template>
<script>

    import { mapActions } from "vuex";
    import ToursList from "../../components/tours/ToursList.vue";
    import { l } from "../../utils/LocalizationUtils.js";

    export default {
        components: {
            ToursList
        },
        created() {
            this.updateSectionTitle(this.l("Tours"));
        },
        methods: {
            add() {
                this.$router.push("/tours/new");
            },
            editInfo(entry) {
                this.$router.push("/tours/" + entry._id + "/editInfo");
            },
            editStops(entry) {
                this.$router.push("/tours/" + entry._id + "/editStops");
            },
            l,
            ...mapActions({
                updateSectionTitle: "platform/updateSectionTitle"
            })
        }
    }

</script>
