import axios from "axios";


export default {


    namespaced: true,


    state: {
        userToken: {}
    },


    mutations: {

        setUserToken(state, userToken) {
            state.userToken = userToken;
        }

    },


    actions: {

        setUserToken({ commit }, userToken) {
            localStorage.userToken = userToken;
            if (userToken === null) {
                axios.defaults.headers.common["Authorization"] = null;
            } else {
                axios.defaults.headers.common["Authorization"] = "Bearer " + userToken;
            }
            commit("setUserToken", userToken);
        },

        loadSessionFromLocalStorage({ dispatch }) {
            if (localStorage.userToken) {
                let userToken = localStorage.userToken;
                if (userToken === "null") {
                    userToken = null;
                }
                dispatch("setUserToken", userToken);
            }
        }

    },


    getters: {

        getUserToken(state) {
            return state.userToken;
        }

    }

}