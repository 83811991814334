import { l } from "../../localization/localization.js";
import * as usageService from "../services/usageService.js";
import store from "../../store/index.js";


export const metrics = [

    {
        name: "storage",
        used: () => store.getters["usage/getCurrentUse"]?.storageSize / (1000 * 1000 * 1000), // GB
        label: () => l.cms.files.fileStorageSize(),
        unit: () => l.cms.files.fileSizeGB(),
    },

    {
        name: "exhibitions",
        used: () => store.getters["usage/getCurrentUse"]?.exhibitionCount,
        label: () => l.cms.exhibitions.plural(),
        unit: () => "",
    },

    {
        name: "arAnchors",
        used: () => store.getters["usage/getCurrentUse"]?.arAnchorCount,
        label: () => l.cms.arAnchors.plural(),
        unit: () => "",
    },

    {
        name: "pages",
        used: () => store.getters["usage/getCurrentUse"]?.pageCount,
        label: () => l.app.pages.general.plural(),
        unit: () => "",
    },

    {
        name: "tours",
        used: () => store.getters["usage/getCurrentUse"]?.tourCount,
        label: () => l.app.tours.general.plural(),
        unit: () => "",
    },

];


export function getUsage() {
    return store.getters["usage/getUsage"];
}


export function updateUsage(usage) {
    return new Promise(async resolve => {
        await usageService.update(usage);
        await store.dispatch("usage/load");
        resolve();
    });
}


export function isUsageVisible() {
    const usage = getUsage();
    return usage ? usage.visible : false;
}


export function getMetric(metricName) {
    return metrics.find(metric => metric.name === metricName); 
}


export function getLimit(metricName) {
    const limits = getUsage()?.limits;
    if (limits && limits.hasOwnProperty(metricName) && !isNaN(limits[metricName])) {
        return limits[metricName];
    } else {
        return -1;
    }
}


export function hasLimit(metricName) {
    return getLimit(metricName) >= 0;
}


export function isLimitReached(metricName) {
    const metric = getMetric(metricName);
    const limit = getLimit(metricName);
    if (metric && !isNaN(limit)) {
        const used = metric.used();
        return limit >= 0 && !isNaN(used) && used >= limit;
    } else {
        return false;
    }
}


export function getMetricOptions() {
    const options = [];
    metrics.forEach(metric => {
        options.push({
            text: metric.label(),
            value: metric.name,
        });
    });
    options.push({
        text: l.cms.usage.customLimitType(),
        value: "custom",
    });
    return options;
}