<template>
    <v-layout row justify-center>
        <v-dialog :value="true" persistent max-width="720px" scrollable>
            <v-card>
                <v-card-title class="pb-4">
                    <span class="headline primary--text">
                        <v-icon large color="primary" class="title-icon">
                            mdi-information-outline
                        </v-icon>
                        {{ l("Information") }}
                    </span>
                </v-card-title>
                <v-divider />
                <v-card-text ref="scrollArea" class="pt-4">
                    <v-container>
                        <v-row v-if="hasSystemAccessRights" no-gutters class="py-3 body-2">
                            <v-col cols="4">
                                {{ l("AR Target ID") }}
                            </v-col>
                            <v-col>
                                <span class="target-id">
                                    {{ arTarget._id }}
                                </span>
                            </v-col>
                        </v-row>
                        <v-row v-if="hasSystemAccessRights" no-gutters class="py-3 body-2">
                            <v-col cols="4">
                                {{ l("Media ID of Target Image") }}
                            </v-col>
                            <v-col>
                                <span class="target-id">
                                    {{ arTarget.target }}
                                </span>
                            </v-col>
                        </v-row>
                        <v-row v-if="hasSystemAccessRights" no-gutters class="py-3 body-2">
                            <v-col cols="4">
                                {{ l("Vuforia Target Name") }}
                            </v-col>
                            <v-col>
                                <span class="target-id">
                                    {{ arTarget.identifier }}
                                </span>
                            </v-col>
                        </v-row>
                        <v-row v-if="hasSystemAccessRights && arTarget.overlay" no-gutters class="py-3 body-2">
                            <v-col cols="4">
                                {{ l("Media ID of Overlay Image") }}
                            </v-col>
                            <v-col>
                                <span class="target-id">
                                    {{ arTarget.overlay }}
                                </span>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" text min-width="80" @click="close">
                        {{ l("Close") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-layout>
</template>
<script>

    import { mapGetters } from "vuex";
    import { l } from "../../utils/LocalizationUtils.js";


    export default {
        props: {
            arTarget: { type: Object, default: null },
        },
        computed: {
            ...mapGetters({
                hasSystemAccessRights: "user/hasSystemAccessRights"
            })
        },
        methods: {
            close() {
                this.$emit("close");
            },
            l
        }
    }
</script>
<style scoped>

    .title-icon {
        vertical-align: sub;
    }

    .target-id {
        background: #ddd;
        font-family: "Lucida Console", Monaco, monospace;
        padding: 5px;
        color: #111;
    }

</style>