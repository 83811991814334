
import { l, tryGetTokenOverride, selectLanguage } from "./localization.js";
import { arAnchors } from "./localizationCmsAranchors.js";
import { curationLock } from "./localizationCmsCurationlock.js";
import { exhibitions } from "./localizationCmsExhibitions.js";
import { files } from "./localizationCmsFiles.js";
import { general } from "./localizationCmsGeneral.js";
import { media } from "./localizationCmsMedia.js";
import { releaseNotes } from "./localizationCmsReleasenotes.js";
import { tags } from "./localizationCmsTags.js";
import { usage } from "./localizationCmsUsage.js";
import { userSettings } from "./localizationCmsUsersettings.js";


export const cms = {
    arAnchors,
    curationLock,
    exhibitions,
    files,
    general,
    media,
    releaseNotes,
    tags,
    usage,
    userSettings,

};
