import axios from "axios";
import { triggerError } from "../utils/alerts.js";


const url = "/api/analytics";


class AnalyticsService {


    static getArTrackingEvents({ exhibitionId, startDate, endDate }) {
        return new Promise(async resolve => {
            try {
                const response = await axios.get(`${url}/arTrackingEvents/${exhibitionId}/`, {
                    params: {
                        startDate: startDate.toISOString(),
                        endDate: endDate.toISOString(),
                    }
                });
                resolve(response.data);
            } catch (error) {
                triggerError(error);
                resolve([]);
            }
        });
    }


    static getArInteractionEvents({ exhibitionId, arTargetId, startDate, endDate }) {
        return new Promise(async resolve => {
            try {
                const response = await axios.get(`${url}/arInteractionEvents/${exhibitionId}/${arTargetId}/`, {
                    params: {
                        startDate: startDate.toISOString(),
                        endDate: endDate.toISOString(),
                    }
                });
                resolve(response.data);
            } catch (error) {
                triggerError(error);
                resolve([]);
            }
        });
    }


    static getModeEvents({ exhibitionId, startDate, endDate }) {
        return new Promise(async resolve => {
            try {
                const response = await axios.get(`${url}/modeEvents/${exhibitionId}/`, {
                    params: {
                        startDate: startDate.toISOString(),
                        endDate: endDate.toISOString(),
                    }
                });
                resolve(response.data);
            } catch (error) {
                triggerError(error);
                resolve([]);
            }
        });
    }

}

export default AnalyticsService;