<template>
    <ButtonWithIcon
        icon="mdi-plus"
        :label="label"
        :disabled="disabled"
        @click="click"
    />
</template>
<script>

    import ButtonWithIcon from "./ButtonWithIcon.vue";


    export default {
        components: {
            ButtonWithIcon,
        },
        props: {
            label: { type: String, default: "" },
            disabled: { type: Boolean, default: false },
        },
        methods: {
            click() {
                this.$emit("click");
            },
        },
    }

</script>