<template>
    <v-tooltip :left="!tooltipRight" :right="tooltipRight">
        <template #activator="{ on }">
            <v-btn class="listRowButton" text icon color="primary" :disabled="disabled" v-on="on" @click="click">
                <v-icon :size="iconSize">
                    {{ icon }}
                </v-icon>
            </v-btn>
        </template>
        <span class="body-2">
            {{ label }}
        </span>
    </v-tooltip>
</template>
<script>

    export default {
        props: {
            icon: { type: String, default: "mdi-help-rhombus-outline" },
            label: { type: String, default: "" },
            tooltipRight: { type: Boolean },
            disabled: { type: Boolean },
            iconSize: { type: String, default: undefined },
        },
        methods: {
            click() {
                this.$emit("click");
            },
        },
    }

</script>
<style scoped>

    .listRowButton {
        flex-grow: 0;
        flex-shrink: 0;
    }

</style>