import axios from "axios";


const url = "/ping/";


class PingService {

    static getStatus(callback) {
        axios.get(url).then(response => {
            callback(response.data);
        }).catch(error => {
            callback(null);
        });
    }

}

export default PingService;