<template>
    <div>
        <InpageForm v-model="form" />
    </div>
</template>
<script>

    import LocalizationService from "../../services/LocalizationService.js";
    import InpageForm from "../globalUiElements/InpageForm.vue";
    import { createForm } from "../../utils/FormUtils.js";
    import { l } from "../../utils/LocalizationUtils.js";


    export default {
        components: {
            InpageForm
        },
        props: {
            reloadTrigger: { type: Number, default: 0 },
        },
        data() {
            return {
                form: {}
            };
        },
        watch: {
            reloadTrigger() {
                this.update();
            }
        },
        mounted() {
            this.update();
        },
        methods: {
            update() {
                LocalizationService.get(doc => {
                    this.form = createForm({

                        action: LocalizationService.update,

                        fieldDefs: [
                            {
                                key: "file",
                                label: "Localization JSON file",
                                type: "Reference",
                                referenceOf: "media",
                                referenceType: "json",
                                value: doc.file || null
                            }
                        ],

                        submitLabel: "Save",

                        submitted: entry => {
                            this.$root.$emit("success", l("Localization JSON file assigned.", entry));
                        }
                    });
                });
            }
        }
    }
</script>
