import Vue from "vue";
import Vuex from "vuex";
import { checkAccessRightsToCurrentRoute } from "../router/authorization.js";
import platform from "../platform/state/platformState.js";
import alerts from "./modules/alerts.js";
import user from "./modules/user.js";
import session from "./modules/session.js";
import locales from "../locales/store/locales.js";
import pages from "../pages/store/pages.js";
import tags from "../tags/store/tags.js";
import usage from "../usage/store/usage.js";
import lightbox from "../lightbox/store/lightbox.js";
import PingService from "../services/PingService.js";


// Load Vuex
Vue.use(Vuex);


export default new Vuex.Store({


    modules: {
        platform,
        alerts,
        user,
        session,
        locales,
        pages,
        tags,
        usage,
        lightbox,
    },


    actions: {

        login({ dispatch }, { user, token }) {
            dispatch("session/setUserToken", token);
            dispatch("user/setUser", user);
            dispatch("usage/load");
            checkAccessRightsToCurrentRoute();
        },

        logout({ dispatch }) {
            dispatch("session/setUserToken", null);
            dispatch("user/setUser", {});
        },

        loadDataFromLocalStorage({ commit, dispatch }) {
            dispatch("session/loadSessionFromLocalStorage"); // has to be before loading of user
            dispatch("user/loadUserFromLocalStorage");
            commit("platform/reportFinishedLoading");
        },

        loadPingStatus({ commit, getters, dispatch }) {
            PingService.getStatus(status => {
                if (status !== null) {
                    commit("platform/setStatus", status);
                    dispatch("platform/updateDocumentTitle");

                    // logout if server session has timed out
                    if (!status.userLoggedIn && getters("isLoggedIn")) {
                        dispatch("logout");
                    }
                }
            });
        },

    },


    getters: {

        isLoggedIn(state) {
            return state.user.user
                && state.user.user.hasOwnProperty("email")
                && state.session.userToken !== null;
        }

    }

});
