<template>
    <v-select
        filled
        hide-details
        :value="previewLocale"
        :items="appLocales"
        item-text="name"
        item-value="ietfLanguageTag"
        :label="selectionLabel"
        class="languageSelection mb-5"
        @input="onInput"
    />
</template>
<script>

    import { getAppLocales, inPreviewLocale, setPreviewLocale } from '../../locales/utils/localeUtils.js';
    import { l } from "../../localization/localization.js";


    export default {
        computed: {
            selectionLabel: () => l.cms.general.previewLocaleSelectionLabel(),
            previewLocale: () => inPreviewLocale(),
            appLocales: () => getAppLocales(),
        },
        methods: {
            onInput(ietfLanguageTag) {
                const locale = this.appLocales.find(locale => locale.ietfLanguageTag === ietfLanguageTag);
                if(locale) {
                    setPreviewLocale(locale);
                }
            },
        }
    };

</script>
<style scoped>

    .languageSelection {
        width: 480px;
    }

</style>