
import { l, tryGetTokenOverride, selectLanguage } from "./localization.js";


// Tags Management in CMS
export const tags = {


    
    pageTitle(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "pageTitle");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Tags";
            case "de": return "Tags";
            default: return `[pageTitle: ${language} missing]`;
        }
    
    },
            

    
    name(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "name");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Name";
            case "de": return "Name";
            default: return `[name: ${language} missing]`;
        }
    
    },
            

    
    addButtonLabel(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "addButtonLabel");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Create New Tag";
            case "de": return "Neuer Tag hinzufügen";
            default: return `[addButtonLabel: ${language} missing]`;
        }
    
    },
            

    
    deleteButtonLabel(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "deleteButtonLabel");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        return l.cms.general.delete(locale);
    },
            

    
    deletePromptTitle(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "deletePromptTitle");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Delete Tag?";
            case "de": return "Tag löschen?";
            default: return `[deletePromptTitle: ${language} missing]`;
        }
    
    },
            

    
    deletePromptQuestion(name, locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "deletePromptQuestion");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        return l.cms.general.areYouSureYouWantToDelete(name, locale);
    },
            

    
    deletedMessage(name, locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "deletedMessage");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Tag \"" + name + "\" deleted.";
            case "de": return "Tag \"" + name + "\" gelöscht.";
            default: return `[deletedMessage: ${language} missing]`;
        }
    
    },
            

    
    addFormTitle(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "addFormTitle");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Create New Tag";
            case "de": return "Neuer Tag hinzufügen";
            default: return `[addFormTitle: ${language} missing]`;
        }
    
    },
            

    
    addFormSubmitButtonLabel(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "addFormSubmitButtonLabel");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        return l.cms.general.create(locale);
    },
            

    
    addedMessage(name, locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "addedMessage");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Tag \"" + name + "\" created.";
            case "de": return "Tag \"" + name + "\" hinzugefügt.";
            default: return `[addedMessage: ${language} missing]`;
        }
    
    },
            

    
    editFormTitle(name, locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "editFormTitle");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Editing Tag \"" + name + "\"";
            case "de": return "Tag \"" + name + "\" bearbeiten";
            default: return `[editFormTitle: ${language} missing]`;
        }
    
    },
            

    
    editFormSubmitButtonLabel(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "editFormSubmitButtonLabel");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        return l.cms.general.save(locale);
    },
            

    
    editedMessage(name, locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "editedMessage");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Tag \"" + name + "\" saved.";
            case "de": return "Tag \"" + name + "\" gespeichert.";
            default: return `[editedMessage: ${language} missing]`;
        }
    
    },
            
};
