<template>
    <div class="defaultContentView">
        <h1 class="display-1 mb-5 primary--text">
            <v-icon color="primary" class="titleIcon">
                settings
            </v-icon>
            {{ title }}
        </h1>
        <v-tabs v-model="showTab" vertical slider-color="primary" background-color="transparent">
            <v-tab v-for="tab in tabs" :key="tab.id" :href="'#' + tab.id" class="tab">
                <v-icon left>
                    {{ tab.icon }}
                </v-icon>
                {{ tab.label }}
            </v-tab>
            <v-tabs-items v-model="showTab" class="tab-content-container">
                <v-tab-item v-for="tab in tabs" :key="tab.id" :value="tab.id">
                    <v-card flat tile class="tab-content">
                        <v-card-text>
                            <component :is="tab.component" :reload-trigger="tab.trigger" :visible="showTab === tab.id" />
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-tabs>
    </div>
</template>
<script>

    import LanguagesComponent from "../components/languages/LanguagesComponent.vue";
    import InteractionModulesComponent from "../components/interactionModules/InteractionModulesComponent.vue"
    import TourStopTypesComponent from "../components/tourStopTypes/TourStopTypesComponent.vue" 
    import InformationPageBlocksComponent from "../components/informationPageBlocks/InformationPageBlocksComponent.vue"
    import InformationPageTypesComponent from "../components/informationPageTypes/InformationPageTypesComponent.vue"
    import TagsComponent from "../tags/components/TagsComponent.vue";
    import UsageSettingsComponent from "../usage/components/UsageSettingsComponent.vue";
    import MinAppVersionComponent from "../components/minAppVersion/MinAppVersionComponent.vue";
    import AppSettingsComponent from "../components/appSettings/AppSettingsComponent.vue";
    import ServerSettingsComponent from "../components/serverSettings/ServerSettingsComponent.vue";
    import EnvironmentVariables from "../components/environmentVariables/EnvironmentVariables.vue";
    import SystemLogBox from "../components/systemLog/SystemLogBox.vue";
    import BashScripts from "../components/bashScripts/BashScripts.vue";
    import { l } from "../localization/localization.js";
    import { backwardCompatibleL } from "../utils/LocalizationUtils.js";


    export default {
        components: {
            LanguagesComponent,
            InteractionModulesComponent,
            TourStopTypesComponent,
            InformationPageBlocksComponent,
            InformationPageTypesComponent,
            TagsComponent,
            UsageSettingsComponent,
            MinAppVersionComponent,
            AppSettingsComponent,
            ServerSettingsComponent,
            EnvironmentVariables,
            SystemLogBox,
            BashScripts
        },
        data() {
            const tabs = [
                {
                    id: "Languages",
                    component: LanguagesComponent,
                    icon: "mdi-translate",
                    label: backwardCompatibleL("Languages"),
                },
                {
                    id: "InteractionModules",
                    component: InteractionModulesComponent,
                    icon: "mdi-gesture-tap",
                    label: backwardCompatibleL("Interaction Modules"),
                },
                {
                    id: "TourStopTypes",
                    component: TourStopTypesComponent,
                    icon: "mdi-map-marker-multiple-outline",
                    label: backwardCompatibleL("Tour Stop Types"),
                },
                {
                    id: "InformationPageTypes",
                    component: InformationPageTypesComponent,
                    icon: "mdi-file-multiple-outline",
                    label: backwardCompatibleL("Information Page Types"),
                },
                {
                    id: "InformationPageBlocks",
                    component: InformationPageBlocksComponent,
                    icon: "mdi-file-table-outline",
                    label: backwardCompatibleL("Information Page Blocks"),
                },
                {
                    id: "Tags",
                    component: TagsComponent,
                    icon: "mdi-tag-outline",
                    label: l.cms.tags.pageTitle(),
                },
                {
                    id: "UsageReport",
                    component: UsageSettingsComponent,
                    icon: "mdi-gauge-full",
                    label: l.cms.usage.settingsPageTitle(),
                },
                {
                    id: "MinAppVersion",
                    component: MinAppVersionComponent,
                    icon: "mdi-cellphone-arrow-down",
                    label: backwardCompatibleL("Minimal App Version"),
                },
                {
                    id: "AppSettings",
                    component: AppSettingsComponent,
                    icon: "phonelink_setup",
                    label: backwardCompatibleL("App Settings"),
                },
                {
                    id: "ServerSettings",
                    component: ServerSettingsComponent,
                    icon: "mdi-server-network",
                    label: backwardCompatibleL("Server Settings"),
                },
                {
                    id: "EnvironmentVariables",
                    component: EnvironmentVariables,
                    icon: "mdi-package-variant-closed",
                    label: backwardCompatibleL("Environment Variables"),
                },
                {
                    id: "SystemLog",
                    component: SystemLogBox,
                    icon: "mdi-file-alert-outline",
                    label: backwardCompatibleL("Server Log"),
                },
                {
                    id: "BashScripts",
                    component: BashScripts,
                    icon: "mdi-script-text-outline",
                    label: backwardCompatibleL("Bash Scripts"),
                }
            ];
            tabs.forEach(tab => {
                tab.trigger = 0;
            });
            return {
                showTab: tabs[0].id,
                tabs
            };
        },
        computed: {
            title: () => backwardCompatibleL("System"),
        },
        watch: {
            showTab(value) {
                const tab = this.tabs.find(tab => tab.id === value);
                if (tab) {
                    tab.trigger++;
                }
            }
        },
    }
</script>
<style scoped>

    .tab {
        justify-content: flex-start !important;
        text-transform: none !important;
        background: #fff;
    }

    .tab-content-container {
        background: transparent;
    }

    .tab-content,
    .tab-content div {
        background: transparent;
        padding-top: 0 !important;
    }

</style>