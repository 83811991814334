<template>
    <WindowedForm v-model="form" />
</template>
<script>

    import SettingService from "../../services/AppSettingService.js";
    import WindowedForm from "../globalUiElements/WindowedForm.vue";
    import { createForm } from "../../utils/FormUtils.js";
    import { l } from "../../utils/LocalizationUtils.js";

    export default {
        components: {
            WindowedForm
        },
        props: {
            entry : { type: Object, default: null }
        },
        data() {
            return {
                form: createForm({

                    title: {
                        label: "Editing ${v.key}",
                        entry: this.entry
                    },

                    action: SettingService.updateSetting,

                    fieldDefs: [
                        {
                            key: "_id",
                            type: "Hidden",
                            value: this.entry._id
                        },
                        {
                            key: "key",
                            type: "Text",
                            label: "Key",
                            value: this.entry.key,
                            required: true
                        },
                        {
                            key: "value",
                            type: "Textarea",
                            label: "Value",
                            value: this.entry.value
                        }
                    ],

                    submitLabel: "Save",

                    submitted: entry => {
                        this.$root.$emit("success", l("Changes for app setting \"${v.key}\" saved.", entry));
                        this.$emit("editedEntry");
                    },

                    cancelled: () => {
                        this.$emit("cancel");
                    }
                })
            }
        }
    }
</script>