<template>
    <div>
        <WindowedForm v-model="form" />
    </div>
</template>
<script>

    import MediaService from "../../services/MediaService.js";
    import WindowedForm from "../globalUiElements/WindowedForm.vue";
    import { createForm } from "../../utils/FormUtils.js";
    import { backwardCompatibleL } from "../../utils/LocalizationUtils.js";
    import { l } from "../../localization/localization.js";



    export default {
        components: {
            WindowedForm,
        },
        props: {
            acceptedFileFormats: { type: Object, default: () => {} },
        },
        data() {
            return {
                form: createForm({

                    title: "Add Media",

                    action: MediaService.insertMedia,

                    fieldDefs: [
                        {
                            key: "name",
                            type: "Text",
                            label: "Name",
                            required: true
                        },
                        {
                            key: "file",
                            type: "FileUpload",
                            label: "Select a File...",
                            acceptedFileFormats: this.acceptedFileFormats,
                            required: true
                        },
                        {
                            uiElement: "InfoList",
                            items: [
                                l.cms.media.acceptedImageFormats(),
                                l.cms.media.maxImageResolution(),
                                l.cms.media.acceptedAudioFormats(),
                                l.cms.media.acceptedVideoFormats(),
                                l.cms.media.maxFileSize(),
                            ],
                        },
                    ],

                    submitLabel: "Upload",

                    submitted: entry => {
                        this.$root.$emit("success", backwardCompatibleL("Media \"${v.name}\" added.", entry));
                        this.$emit("createdEntry");
                    },

                    cancelled: () => {
                        this.$emit("cancel");
                    }
                })
            }
        }
    }
</script>