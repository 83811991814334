<template>
    <div>
        <h2 class="title primary--text">
            {{ pageTitle }}
        </h2>
        <TagList :reload-trigger="listReloadTrigger" @add="openAddForm" @edit="openEditForm" />
        <AddTag v-if="showAddForm" @cancel="closeAddForm" @addedEntry="addedEntry" />
        <EditTag v-if="showEditForm" :entry="editingEntry" @cancel="closeEditForm" @editedEntry="editedEntry" />
    </div>
</template>
<script>

    import TagList from "./TagList.vue";
    import AddTag from "./AddTag.vue";
    import EditTag from "./EditTag.vue";
    import { l } from "../../localization/localization.js";


    export default {
        components: {
            TagList,
            AddTag,
            EditTag
        },
        props: {
            reloadTrigger: { type: Number, default: 0 },
        },
        data() {
            return {
                listReloadTrigger: 0,
                showAddForm: false,
                showEditForm: false,
                editingEntry: null
            };
        },
        computed: {
            pageTitle: () => l.cms.tags.pageTitle(),
        },
        watch: {
            reloadTrigger() {
                this.listReloadTrigger++;
            }
        },
        methods: {
            openAddForm() {
                this.showAddForm = true;
            },
            closeAddForm() {
                this.showAddForm = false;
            },
            addedEntry() {
                this.closeAddForm();
                this.listReloadTrigger++;
            },
            openEditForm(entry) {
                this.editingEntry = entry;
                this.showEditForm = true;
            },
            closeEditForm() {
                this.showEditForm = false;
                this.editingEntry = null;
            },
            editedEntry() {
                this.closeEditForm();
                this.listReloadTrigger++;
            }
        }
    }
</script>
