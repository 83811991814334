<template>
    <v-dialog v-model="show" persistent max-width="400">
        <v-card>
            <v-card-title class="headline">
                {{ title }}
            </v-card-title>
            <v-card-text>
                {{ text }}
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="primary" text @click="close(true)">
                    {{ button }}
                </v-btn>
                <v-btn color="primary" text @click="close(false)">
                    {{ l("Cancel") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        data() {
            return {
                show: false,
                title: "",
                text: "",
                button: "",
                callback: null
            }
        },
        mounted() {
            this.$root.$on("confirm", (title, text, button, callback) => {
                this.show = true;
                this.title = title;
                this.text = text;
                this.button = button;
                this.callback = callback;
            });
        },
        methods: {
            close(yes) {
                this.show = false;
                this.callback(yes);
            },
            l(token, values) {
                return this.$root.l(token, values);
            }
        }
    }
</script>