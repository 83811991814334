
import { l, tryGetTokenOverride, selectLanguage } from "./localization.js";


// Person pages in encyclopedia
export const persons = {


    
    entry(number, locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "entry");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        switch(number) {
            case 1: return l.app.pages.persons.singular(locale);
            default: return l.app.pages.persons.plural(locale);
        }
            
    },
            

    
    singular(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "singular");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Person";
            case "de": return "Person";
            default: return `[singular: ${language} missing]`;
        }
    
    },
            

    
    plural(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "plural");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Persons";
            case "de": return "Personen";
            default: return `[plural: ${language} missing]`;
        }
    
    },
            

    
    born(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "born");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return "Born";
            case "de": return "Geboren";
            case "fr": return "Né(e)";
            default: return `[born: ${language} missing]`;
        }
    
    },
            

    
    died(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "died");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return "Died";
            case "de": return "Gestorben";
            case "fr": return "Mort";
            default: return `[died: ${language} missing]`;
        }
    
    },
            
};
