import store from "../store/index.js";


export function showWaitingAnimation(message) {
    store.dispatch("platform/showWaitingAnimation", message);
}


export function hideWaitingAnimation() {
    store.dispatch("platform/hideWaitingAnimation");
}