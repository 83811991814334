<template>
    <WindowedForm v-model="form" />
</template>
<script>

    import FloorService from "../../services/FloorService.js";
    import WindowedForm from "../../components/globalUiElements/WindowedForm.vue";
    import { createForm } from "../../utils/FormUtils.js";
    import { l } from "../../utils/LocalizationUtils.js";

    export default {
        components: {
            WindowedForm
        },
        props: {
            entry : { type: Object, default: null }
        },
        data() {
            return {
                form: createForm({

                    title: {
                        label: "Editing ${v.key}",
                        entry: this.entry
                    },

                    action: FloorService.updateFloor,

                    fieldDefs: [
                        {
                            key: "_id",
                            type: "Hidden",
                            value: this.entry._id,
                        },
                        {
                            key: "exhibitionId",
                            type: "Hidden",
                            value: this.entry.exhibitionId,
                        },
                        {
                            key: "name",
                            type: "Text",
                            label: "Name",
                            localized: true,
                            required: true,
                            value: this.entry.name,
                        },
                        {
                            key: "level",
                            type: "Number",
                            label: "Level",
                            required: true,
                            value: this.entry.level,
                        },
                        {
                            key: "floorPlanSvgId",
                            type: "Reference",
                            referenceOf: "media",
                            label: "SVG file of floorplan",
                            value: this.entry.floorPlanSvgId,
                        },
                        {
                            key: "floorPlanWidthInMeters",
                            type: "Hidden",
                            value: this.entry.floorPlanWidthInMeters,
                        },
                    ],

                    submitLabel: "Save",

                    submitted: entry => {
                        this.$root.$emit("success", l("Floor saved.", entry));
                        this.$emit("editedEntry");
                    },

                    cancelled: () => {
                        this.$emit("cancel");
                    }
                })
            }
        }
    }
</script>