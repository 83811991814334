<template>
    <div>
        <h2 class="title primary--text">
            {{ pageTitle }}
        </h2>
        <UsageVisibilityToggle
            :visibility="usage.visible"
            :loading="loadingVisibility"
            @saveVisibility="saveVisibility"
        />
        <UsageLimitsList 
            :limits="usage.limits"
            :loading="loadingLimits"
            @saveLimits="saveLimits"
        />
        <UsageReportEditor 
            :report="usage.report"
            :limits="usage.limits"
            :loading="loadingReport"
            @saveReport="saveReport"
        />
    </div>
</template>
<script>

    import UsageVisibilityToggle from "./UsageVisibilityToggle.vue";
    import UsageLimitsList from "./UsageLimitsList.vue";
    import UsageReportEditor from "./UsageReportEditor.vue";
    import { updateLookups } from "../../utils/referenceUtils";
    import { getUsage, updateUsage } from "../utils/usageUtils.js";
    import { l } from "../../localization/localization.js";
    import { deepClone } from '../../utils/objectUtils';


    export default {
        components: {
            UsageVisibilityToggle,
            UsageLimitsList,
            UsageReportEditor,
        },
        props: {
            reloadTrigger: { type: Number, default: 0 },
        },
        data() {
            return {
                loadingVisibility: false,
                loadingLimits: false,
                loadingReport: false,
            };
        },
        computed: {
            pageTitle: () => l.cms.usage.settingsPageTitle(),
            usage: () => getUsage(),
        },
        async mounted() {
            await updateLookups(["usage"]);
        },
        methods: {
            async saveVisibility(visibility) {
                this.loadingVisibility = true;
                const usage = deepClone(this.usage);
                usage.visible = visibility;
                await updateUsage(usage);
                this.loadingVisibility = false;
            },
            async saveLimits(limits) {
                this.loadingLimits = true;
                const usage = deepClone(this.usage);
                usage.limits = limits;
                await updateUsage(usage);
                this.loadingLimits = false;
            },
            async saveReport(report) {
                this.loadingReport = true;
                const usage = deepClone(this.usage);
                usage.report = report;
                await updateUsage(usage);
                this.loadingReport = false;
            },
        },
    }

</script>
