
import { l, tryGetTokenOverride, selectLanguage } from "./localization.js";



export const timeline = {


    
    name(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "name");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        return "Timeline";
    },
            

    
    description(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "description");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        return "Explore the art history through a visual chronology of artists and events.";
    },
            
};
