
import { l, tryGetTokenOverride, selectLanguage } from "./localization.js";
import { acquirers } from "./localizationAppPagesAcquirers.js";
import { artists } from "./localizationAppPagesArtists.js";
import { artMovements } from "./localizationAppPagesArtmovements.js";
import { artworks } from "./localizationAppPagesArtworks.js";
import { categories } from "./localizationAppPagesCategories.js";
import { general } from "./localizationAppPagesGeneral.js";
import { persons } from "./localizationAppPagesPersons.js";
import { places } from "./localizationAppPagesPlaces.js";


export const pages = {
    acquirers,
    artists,
    artMovements,
    artworks,
    categories,
    general,
    persons,
    places,

};
