<template>
    <div>
        <h2 class="title primary--text mt-15 mb-3">
            {{ editorTitle }}
        </h2>
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
        />
        <div class="editor">
            <div v-for="(element, index) in report" :key="index" class="elementContainer">
                <UsageReportElement
                    :element="element"
                />
                <div class="editorButtons text-right">
                    <ListRowButton
                        v-if="['title', 'text', 'usageBar'].includes(element.type)"
                        :label="editElementButtonLabel"
                        icon="mdi-cog"
                        @click="openEditForm(index)"
                    />
                    <ListRowButton
                        :label="moveUpElementButtonLabel"
                        icon="mdi-arrow-up"
                        :disabled="index === 0"
                        @click="moveUpElement(index)"
                    />
                    <ListRowButton
                        :label="moveDownElementButtonLabel"
                        icon="mdi-arrow-down"
                        :disabled="index === report.length - 1"
                        @click="moveDownElement(index)"
                    />
                    <ListRowButton
                        :label="deleteElementButtonLabel"
                        icon="mdi-delete"
                        @click="deleteElement(index)"
                    />
                </div>
            </div>
        </div>
        <ButtonWithIcon
            icon="mdi-format-title"
            :label="addTitleElementButtonLabel"
            class="mt-2 mr-2"
            @click="addTitleElement"
        />
        <ButtonWithIcon
            icon="mdi-text"
            :label="addTextElementButtonLabel"
            class="mt-2 mr-2"
            @click="addTextElement"
        />
        <ButtonWithIcon
            icon="mdi-arrow-expand-vertical"
            :label="addSpacerElementButtonLabel"
            class="mt-2 mr-2"
            @click="addSpacerElement"
        />
        <ButtonWithIcon
            icon="mdi-label-percent-outline"
            :label="addUsageBarElementButtonLabel"
            class="mt-2 mr-2"
            @click="addUsageBarElement"
        />
        <EditUsageReportElement
            v-if="editingIndex !== -1"
            :element="report[editingIndex]"
            @cancel="closeEditForm"
            @saveElement="saveElement"
        />
    </div>
</template>
<script>

    import UsageReportElement from "./UsageReportElement.vue";
    import ListRowButton from "../../components/globalUiElements/ListRowButton.vue";
    import ButtonWithIcon from "../../components/globalUiElements/ButtonWithIcon.vue";
    import EditUsageReportElement from "./EditUsageReportElement.vue";
    import { metrics } from "../utils/usageUtils.js";
    import { deepClone } from "../../utils/objectUtils";
    import { l } from "../../localization/localization.js";


    export default {
        components: {
            UsageReportElement,
            ListRowButton,
            ButtonWithIcon,
            EditUsageReportElement,
        },
        props: {
            report: { type: Array, default: null },
            limits: { type: Object, default: null },
            loading: { type: Boolean, default: false },
        },
        data() {
            return {
                editingIndex: -1,
            };
        },
        computed: {
            editorTitle: () => l.cms.usage.structureOfUsageReportTitle(),
            editElementButtonLabel: () => l.cms.usage.editElementButtonLabel(),
            moveUpElementButtonLabel: () => l.cms.usage.moveUpElementButtonLabel(),
            moveDownElementButtonLabel: () => l.cms.usage.moveDownElementButtonLabel(),
            deleteElementButtonLabel: () => l.cms.usage.deleteElementButtonLabel(),
            addTitleElementButtonLabel: () => l.cms.usage.addTitleElementButtonLabel(),
            addTextElementButtonLabel: () => l.cms.usage.addTextElementButtonLabel(),
            addSpacerElementButtonLabel: () => l.cms.usage.addSpacerElementButtonLabel(),
            addUsageBarElementButtonLabel: () => l.cms.usage.addUsageBarElementButtonLabel(),
        },
        methods: {
            openEditForm(index) {
                this.editingIndex = index;
            },
            closeEditForm() {
                this.editingIndex = -1;
            },
            saveElement(element) {
                const report = deepClone(this.report);
                report[this.editingIndex] = element;
                this.save(report);
            },
            moveUpElement(index) {
                if(index > 0 && index < this.report.length) {
                    const report = deepClone(this.report);
                    [ report[index - 1], report[index] ] = [ report[index], report[index - 1] ];
                    this.save(report);
                }
            },
            moveDownElement(index) {
                this.moveUpElement(index + 1);
            },
            deleteElement(index) {
                this.$root.$emit(
                    "confirm",
                    l.cms.usage.deleteElementPromptTitle(),
                    l.cms.usage.deleteElementPromptQuestion(),
                    l.cms.usage.deleteElementPromptButtonLabel(),
                    yes => {
                        if (yes) {
                            const report = deepClone(this.report);
                            report.splice(index, 1);
                            this.save(report);
                        }
                    }
                );
            },
            addTitleElement() {
                const report = deepClone(this.report);
                report.push({
                    type: "title",
                    text: l.cms.usage.newTitleElementContent(),
                });
                this.save(report);
            },
            addTextElement() {
                const report = deepClone(this.report);
                report.push({
                    type: "text",
                    text: l.cms.usage.newTextElementContent(),
                });
                this.save(report);
            },
            addSpacerElement() {
                const report = deepClone(this.report);
                report.push({
                    type: "spacer",
                });
                this.save(report);
            },
            addUsageBarElement() {
                const report = deepClone(this.report);
                report.push({
                    type: "usageBar",
                    metric: metrics[0].name,
                });
                this.save(report);
            },
            save(report) {
                this.closeEditForm();
                this.$emit("saveReport", report);
            },
        },
    }
</script>
<style scoped>

    .editor {
        border: 1px solid #ccc;
    }

    .editor .elementContainer {
        display: flex;
        border-top: 1px dashed #999;
        padding-left: 20px;
        align-items: center;
    }

    .editor .elementContainer:first-of-type {
        border-top: 0 !important;
    }

    .editor .elementContainer:hover {
        background: #e0e0e0 !important;
    }

    .editor .elementContainer .editorButtons {
        width: 160px;
        flex-grow: 0;
        flex-shrink: 0
    }

</style>