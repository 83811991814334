export default {
    translations: {
	
        "$Language": "English (United States)",

        "Logged in as": "Logged in as",
        "User Settings": "User Settings",
        "Logout": "Logout",
        "Error 403: You don't have permission to access this content.": "Error 403: You don't have permission to access this content.",

        "Maintenance": "Maintenance",
        "The curation system is currently undergoing maintenance. This should only last a few minutes.": "The curation system is currently undergoing maintenance. This should only last a few minutes.",
        "Bypass": "Bypass",
        "Curation lock engaged!": "Curation lock engaged!",

	    "Exhibitions": "Exhibitions",
        "AR Content": "AR Content",
	    "Information Database": "Information Database",
	    "Media": "Media",
        "Analytics": "Analytics",
	    "Users": "Users",
	    "System": "System",
        "Help": "Help",

        "Cancel": "Cancel",
        "Create": "Create",
        "Close": "Close",
        "Add": "Add",
        "Delete": "Delete",
        "Remove": "Remove",
        "Save": "Save",
        "No entries.": "No entries.",
        "Never": "Never",
        "All": "All",
        "{0}-{1} of {2}": "{0}-{1} of {2}",
        "Rows per page": "Rows per page",
        "Editing ${v.name}": "Editing ${v.name}",
        "Editing ${v.title}": "Editing ${v.title}",
        "Are you sure you want to delete \"${v.name}\"?": "Are you sure you want to delete \"${v.name}\"?",
        "Are you sure you want to delete \"${v.title}\"?": "Are you sure you want to delete \"${v.title}\"?",
        "Are you sure you want to delete \"${v.key}\"?": "Are you sure you want to delete \"${v.key}\"?",
        "Search": "Search",
        "Information": "Information",
        "loading...": "loading...",
        "Version": "Version",
        "Description": "Description",
        "Uses": "Uses",
        "Visiblity": "Visiblity",
        "Yes": "Yes",
        "No": "No",
        "Not found": "Not found",

        "$Date": "${v.month} ${v.day}, ${v.year}",
        "$Year and month": "${v.month} ${v.year}",
        "$Add era to date": "${v.dateString} ${v.era}",
        "Circa ${v.dateString}": "c. ${v.dateString}",
        "Before ${v.dateString}": "before ${v.dateString}",
        "After ${v.dateString}": "after ${v.dateString}",
        "Single date": "Single date",
        "Time period": "Time period",
        "Circa": "Circa",
        "Before": "Before",
        "After": "After",
        "Day": "Day",
        "Month": "Month",
        "AD Date": "AD",
        "BC Date": "BC",
        "From... Date": "From...",
        "To... Date": "To...",
        "January": "January",
        "February": "February",
        "March": "March",
        "April": "April",
        "May": "May",
        "June": "June",
        "July": "July",
        "August": "August",
        "September": "September",
        "October": "October",
        "November": "November",
        "December": "December",
        "Invalid date": "Invalid date",
        "Edit label": "Edit label",
        "The start date is after the end date.": "The start date is after the end date.",
        "The start and end date are identical.": "The start and end date are identical.",
        "${v} seconds": "${v} seconds",
        "${v} minutes": "${v} minutes",
        "${v} hours": "${v} hours",
        "${v} days": "${v} days",
        "${v} seconds.": "${v} sec",
        "${v} minutes.": "${v} min",
        "${v} hours.": "${v} hrs",
        "${v} days.": "${v} days",

        "Fill": "Fill",
        "Image transform mode fill explanation": "The image fills the frame while still being fully contained within the frame. The aspect ratio of the image in not enforced.",
        "Contain": "Contain",
        "Image transform mode contain explanation": "The image is fully contained within the frame and the aspect ratio of the image is retained. The image may not fully cover the frame.",
        "Cover": "Cover",
        "Image transform mode cover explanation": "The image fully covers the frame and the aspect ratio of the image is retained. The image may extend beyond the frame.",
        "Custom": "Custom",
        "Image transform mode custom explanation": "The position, size, and rotation of the image in respect to the frame is defined manually.",
        "Horizontal offset": "Horizontal offset",
        "Vertical offset": "Vertical offset",
        "Scale": "Scale",
        "Rotation": "Rotation",

        "Create New User": "Create New User",
        "User \"${v.name}\" created.": "User \"${v.name}\" created.",
        "Changes for user \"${v.name}\" saved.": "Changes for user \"${v.name}\" saved.",
        "Name": "Name",
        "Email Address": "Email Address",
        "Last Login": "Last Login",
        "Password": "Password",
        "Change password": "Change password",
        "Access Rights": "Access Rights",
        "Language": "Language",
        "Delete User?": "Delete User?",
        "User \"${v.name}\" deleted.": "User \"${v.name}\" deleted.",
        "User Settings for ${v.name}": "User Settings for ${v.name}",

        "Create New Exhibition": "Create New Exhibition",
        "Exhibition \"${v.title}\" created.": "Exhibition \"${v.title}\" created.",
        "Update JSON file in Firebase": "Update JSON file in Firebase",
        "Updating JSON In Firebase": "Updating JSON In Firebase",
        "The JSON file of \"${v.title}\" was successfully updated.": "The JSON file of \"${v.title}\" was successfully updated.",
        "Download Exhibition's Media": "Download Exhibition's Media",
        "Creating Media Package": "Creating Media Package",
        "Download targets for Vuforia": "Download targets for Vuforia",
        "Bundling Targets for Vuforia": "Bundling Targets for Vuforia",
        "View JSON file": "View JSON file",
        "Changes for exhibition \"${v.title}\" saved.": "Changes for exhibition \"${v.title}\" saved.",
        "Delete Exhibition?": "Delete Exhibition?",
        "Exhibition \"${v.title}\" deleted.": "Exhibition \"${v.title}\" deleted.",
        "Vuforia .xml AR target file": "Vuforia .xml AR target file",
        "Vuforia .dat AR target file": "Vuforia .dat AR target file",
        "Edit Exhibition": "Edit Exhibition",

        "All": "All",
        "Search Art Piece...": "Search Art Piece...",
        "Not Exhibited": "Not Exhibited",
        "Exhibited": "Exhibited",
        "placing...": "placing...",
        "Not yet placed": "Not yet placed",
        "Add to Exhibition": "Add to Exhibition",
        "Remove from Exhibition": "Remove from Exhibition",
        "Set Position": "Set Position",
        "Additional Settings": "Additional Settings",
        "Art Pieces": "Art Pieces",
        "Manage the Exhibition \"${v}\"": "Manage the Exhibition \"${v}\"",
        "Back to Exhibitions": "Back to Exhibitions",

        "Floors": "Floors",
        "Remove Floor?": "Remove Floor?",
        "Are you sure you want to remove \"${v.name}\"?": "Are you sure you want to remove \"${v.name}\"?",
        "Floor \"${v.name}\" removed.": "Floor \"${v.name}\" removed.",

        "Add Art Piece": "Add Art Piece",
        "Art Piece Details": "Art Piece Details",
        "Display Settings": "Display Settings",
        "Image": "Image",
        "Number": "Number",
        "Title": "Title",
        "Artist": "Artist",
        "Year": "Year",
        "Medium": "Medium",
        "Width in cm": "Width in cm",
        "Art Piece ID": "Art Piece ID",
        "Media ID of Art Piece Image": "Media ID of Art Piece Image",
        "Vuforia Target Name": "Vuforia Target Name",
        "Media ID of Target Image": "Media ID of Target Image",
        "Delete Art Piece?": "Delete Art Piece?",
        "Art piece \"${v.title}\" deleted.": "Art piece \"${v.title}\" deleted.",
        "Show overlay on art piece when it's being tracked in AR": "Show overlay on art piece when it's being tracked in AR",
        "Offset of interactions from AR target in cm": "Offset of interactions from AR target in cm",
        "Show information box when AR target is tracked": "Show information box when AR target is tracked",

        "Back to list of art pieces": "Back to list of art pieces",
        "Interactions of \"${v}\"": "Interaction of \"${v}\"",
        "No Interaction Modules found": "No Interaction Modules found",
        "Add ${v.name} Interaction": "Add ${v.name} Interaction",
        "Remove Interaction?": "Remove Interaction?",
        "Are you sure you want to remove the interaction \"${v.name}\"?": "Are you sure you want to remove the interaction \"${v.name}\"?",
        "Interaction \"${v.name}\" removed.": "Interaction \"${v.name}\" removed.",
        "Edit Interaction ${v}": "Edit Interaction ${v}",
        "Edit interactions": "Edit interactions",
        "Change interaction's position": "Change interaction's position",
        "Select Interaction Type...": "Select Interaction Type...",
        "Add ${v} Interaction": "Add ${v} Interaction",
        "Add \"${v}\" interaction": "Add \"${v}\" interaction",
        "Add ${v}": "Add ${v}",
        "Interaction Type": "Interaction Type",
        "Coordinates": "Coordinates",
        "Changes to interaction \"${v.name}\" saved.": "Changes to interaction \"${v.name}\" saved.",
        "Edit Settings of Interaction": "Edit Settings of Interaction",
        "Edit Settings of Interaction ${v}": "Edit Settings for Interaction ${v}",
        "Interaction Module:": "Interaction Module:",
        "Name in CMS (not shown in the app)": "Name in CMS (not shown in the app)",
        "Point of interest (as seen in App)": "Point of interest (as seen in App)",
        "Hide Label": "Hide Label",
        "Icon of point of interest": "Icon of point of interest",
        "Hide point of interest in regular Art Scanner mode": "Hide point of interest in regular Art Scanner mode",
        "Content of interaction": "Content of interaction",
        "Position x axis": "Position x axis",
        "Position y axis": "Position y axis",
        "Behavior": "Behavior",
        "Is the art piece's introduction:": "Is the art piece's introduction:",
        "Open interaction when tracking of art piece starts": "Open interaction when tracking of art piece starts",
        "Close interaction when tracking of art piece stops": "Close interaction when tracking of art piece stops",
        "User cannot close interaction manually": "User cannot close interaction manually",
        "Art piece information box is visible during interaction": "Art piece information box is visible during interaction",
        "Transform (9 comma-separated values: 3 translate, 3 rotation, 3 scale)": "Transform (9 comma-separated values: 3 translate, 3 rotation, 3 scale)",
        "Dismiss as introduction": "Dismiss as introduction",
        "Choose as introduction": "Choose as introduction",
        "Hide in regular Art Scanner mode": "Hide in regular Art Scanner mode",
        "Show in regular Art Scanner mode": "Show in regular Art Scanner mode",
        "Introduction": "Introduction",
        "Introduction settings saved for \"${v}\"": "Introduction settings saved for \"${v}\"",
        "Updated visibility of \"${v.name}\"": "Updated visibility of \"${v.name}\"",

        "Text": "Text",
        "Audio": "Audio",
        "Video": "Video",
        "Multiple Images": "Multiple Images",
        "Perspective": "Perspective",

        "File name": "File name",
        "Display orthogonal to art piece": "Display orthogonal to art piece",
        "Audio file": "Audio file",
        "Face descriptors as JSON": "Face descriptors as JSON",
        "Custom Tutorial Text": "Custom Tutorial Text",
        "Label": "Label",
        "Show as AR overlay on art piece": "Show as AR overlay on art piece",
        "Layers": "Layers",
        "Layer parameters": "Layer parameters",
        "Perpendicular layers": "Perpendicular layers",
        "Perpendicular layer parameters": "Perpendicular layer parameters",
        "Global parameters": "Global parameters",
        "Images": "Images",
        "Titles": "Titles",
        "Texts": "Texts",
        "Audio files": "Audio files",
        "Text pages": "Text pages",        
        "Video file": "Video file",
        "Caption": "Caption",
        "Caption page": "Caption page",
        "AR image scaling mode": "AR image scaling mode",
        "AR image scaling modes": "AR image scaling modes",
        "Image scaling mode": "Image scaling mode",

        "Face descriptor": "Face descriptor",
        "Layer": "Layer",
        "Layer parameter": "Layer parameter",
        "Perpendicular layer": "Perpendicular layer",
        "Perpendicular layer parameter": "Perpendicular layer parameter",
        "Page": "Page",

        "Enter author's name...": "Enter author's name...",
        "Enter title...": "Enter title...",
        "Enter text...": "Enter text...",
        "Enter teaser...": "Enter teaser...",
        "Enter link label...": "Enter link label...",

        "Encyclopedia": "Encyclopedia",
        "Category": "Category",
        "Entries": "Entries",

        "Topic": "Topic",
        "Topics": "Topics",
        "Acquirer": "Acquirer",
        "Acquirers": "Acquirers",
        "Article": "Article",
        "Articles": "Articles",
        "Artist": "Artist",
        "Artists": "Artists",
        "Art Piece": "Art Piece",
        "Art Pieces": "Art Pieces",
        "Art Movement": "Art Movement",
        "Art Movements": "Art Movements",
        "Categories": "Categories",
        "Focus": "Focus",
        "Focuses": "Focuses",
        "Location": "Location",
        "Locations": "Locations",

        "By ${v}": "By ${v}",
        "1 minute": "1 minute",

        "Create Information Page": "Create Information Page",
        "Information page \"${v.name}\" created.": "Information page \"${v.name}\" created.",
        "Type": "Type",
        "Edit Settings of Information Page": "Edit Settings of Information Page",
        "Edit Settings of Information Page ${v.name}": "Edit Settings of Information Page ${v.name}",
        "Edit Information Page ${v.name}": "Edit Information Page ${v.name}",
        "Changes to information page \"${v.name}\" saved.": "Changes to information page \"${v.name}\" saved.",
        "Add Information Page": "Add Information Page",
        "Delete Information Page?": "Delete Information Page?",
        "Information page \"${v.name}\" deleted.": "Information page \"${v.name}\" deleted.",
        "Edit Content": "Edit Content",
 
        "Add Media" : "Add Media",
        "Upload": "Upload",
        "Delete Media?": "Delete Media?",
        "Media \"${v.name}\" added.": "Media \"${v.name}\" added.",
        "Changes for media \"${v.name}\" saved.": "Changes for media \"${v.name}\" saved.",
        "Upload Date": "Upload Date",
        "File Origin Date": "File Origin Date",
        "File Size": "File Size",
        "File Type": "File Type",
        "File Revision": "File Revision",
        "Select a File...": "Select a File...",
        "Update File...": "Update File...",
        "The file \"${v}\" will be overwritten when clicking UPDATE.": "The file \"${v}\" will be overwritten when clicking UPDATE.",
        "Sorry, this file type is not supported.": "Sorry, this file type is not supported.",
        "Undo File Change": "Undo File Change",
        "Open Asset": "Open Asset",
        "Download": "Download",
        "bytes": "bytes",
        "Edit Media Settings": "Edit Media Settings",
        "Edit Media Settings of ${v.name}": "Edit Media Settings of ${v.name}",
        "Prevent the app from caching this file": "Prevent the app from caching this file",
        "Original File Name": "Original File Name",
        "Size": "Size",
        "Caching": "Caching",
        "Allowed": "Allowed",
        "Forbidden": "Forbidden",
        "Original file ID": "Original file ID",
        "Very low quality file ID": "Very low quality file ID",
        "Low quality file ID": "Low quality file ID",
        "Medium quality file ID": "Medium quality file ID",
        "High quality file ID": "High quality file ID",
        "Very high quality file ID": "Very high quality file ID",
        "JSON media entry": "JSON media entry",

        "Advanced Search": "Advanced Search",
        "File type": "File type",
        "File size": "File size",
        "Date uploaded": "Date uploaded",
        "Image files": "Image files",
        "Audio files": "Audio files",
        "Video files": "Video files",
        "Text files": "Text files",
        "System files": "System files",
        "< 100 KB": " < 100 KB",
        "100 KB - 1 MB": "100 KB - 1 MB",
        "1 MB - 10 MB": "1 MB - 10 MB",
        "> 10 MB": "> 10 MB",
        "Today": "Today",
        "Last 3 days": "Last 3 days",
        "This week": "This week",
        "This month": "This month",
        "This year": "This year",

        "Languages": "Languages",
        "Add Language": "Add Language",
        "Priority": "Priority",
        "IETF language tag": "IETF language tag",
        "Increase priority": "Increase priority",
        "Decrease priority": "Decrease priority",
        "Language \"${v.name}\" added.": "Language \"${v.name}\" added.",
        "Edit Language ${v.ietfLanguageTag}": "Edit Language ${v.ietfLanguageTag}",
        "Changes to language \"${v.name}\" saved.": "Changes to language \"${v.name}\" saved.",
        "Delete Language?": "Delete Language?",
        "Language \"${v.name}\" deleted.": "Language \"${v.name}\" deleted.",
        "Language priorities updated.": "Language priorities updated.",
        "Localization JSON file": "Localization JSON file",
        "Localization JSON file assigned.": "Localization JSON file assigned.",

        "Minimal App Version": "Minimal App Version",
        "Version number": "Version number",
        "The version number may only consist of numbers seperated by dots.": "The version number may only consist of numbers seperated by dots.",
        "Minimum app version updated to ${v}.": "Minimum app version updated to ${v}.",

        "App Settings": "App Settings",
        "Create New App Setting": "Create New App Setting",
        "Value": "Value",
        "Setting \"${v.key}\" created.": "Setting \"${v.key}\" created.",
        "Changes for app setting \"${v.key}\" saved.": "Changes for app setting \"${v.key}\" saved.",
        "Delete App Setting?": "Delete App Setting?",
        "App setting \"${v.key}\" deleted.": "App setting \"${v.key}\" deleted.",

        "Server Settings": "Server Settings",
        "Curation lock": "Curation lock",
        "Server settings updated.": "Server settings updated.",

        "Interaction Modules": "Interaction Modules",
        "Hide interaction module from curator": "Hide interaction module from curator",
        "Show interaction module to curator": "Show interaction module to curator",
        "Interaction module's visibility updated.": "Interaction module's visibility updated.",
        "ID": "ID",
        "Version Number": "Version Number",
        "Parameters": "Parameters",
        "Parameter Name": "Parameter Name",
        "Uses of ${v.title}": "Uses of ${v.title}",
        "Art piece missing!": "Art piece missing!",
        "Required": "Required",
        "Localized": "Localized",
        "There are ${v} interactions with missing interaction modules.": "There are ${v} interactions with missing interaction modules.",
        "Interactions with Missing Interaction Modules": "Interactions with Missing Interaction Modules",
        "Interaction Module ID": "Interaction Module ID",

        "Information Page Types": "Information Page Types",
        "Hide information page type from curator": "Hide information page type from curator",
        "Show information page type to curator": "Show information page type to curator",
        "Information page type's visibility updated.": "Information page type's visibility updated.",

        "Information Page Blocks": "Information Page Blocks",
        "Hide information page block from curator": "Hide information page block from curator",
        "Show information page block to curator": "Show information page block to curator",
        "Information page block's visibility updated.": "Information page block's visibility updated.",

        "Environment Variables": "Environment Variables",
        "Server Log": "Server Log",
        "Automatically update every ${v} seconds": "Automatically update every ${v} seconds",
        "Bash Scripts": "Bash Scripts",
        "Call": "Call",
        "No bash scripts configured.": "No bash scripts configured.",
        "Bash script list must be configured in these files:": "Bash script list must be configured in these files:",

        "Validation error: any.required": "This field cannot be left empty.",
        "Validation error: any.empty": "This field cannot be left empty.",
        "Validation error: string.empty": "This field cannot be left empty.",
        "Validation error: string.email": "This is not a valid email address.",
        "Validation error: string.max": "This input contains too many characters.",
        "Validation error: string.min": "This input contains too few characters.",
        "Validation error: string.base": "This input is invalid.",
        "Validation error: string.alphanum": "This input may only contain uppercase and lowercase letters and numbers.",
        "Validation error: number.base": "This input must be a number",
        "Validation error: userEmail.alreadyExists": "There is already a user with this email address.",
        "Validation error: mediaName.alreadyExists": "There is already a media file with this name.",
        "Validation error: target.alreadyInUse": "This target is already being used by another art piece.",
        "Validation error: interactionModuleName.alreadyExists": "There is already an interaction module with this name.",
        "Validation error: informationPageTypeName.alreadyExists": "There is already an information page type with this name.",
        "Validation error: informationPageModuleName.alreadyExists": "There is already an information page module with this name.",
        "Validation error: paramName.alreadyExists": "There is already a parameter with this name.",
        "Validation error: file.required": "This field cannot be left empty.",
        "Validation error: paramName.notAllowed": "This parameter name is invalid.",
        "Validation error: interactionModule.inUse": "This interaction module is still being used.",
        "Validation error: artPieceId.alreadyInExhibition": "This art piece already exists in this exhibition.",
        "Validation error for language ${v.language}: any.empty": "The translation for \"${v.language}\" cannot be left empty.",
        "Validation error for language ${v.language}: string.max": "The translation for \"${v.language}\" contains too many characters.",
        "Validation error for language ${v.language}: string.base": "The translation for \"${v.language}\" cannot be left empty.",

        "Exhibit.": "Exhibitions",
        "AR Content.": "AR Content",
        "Info. Dat.": "Info. Database",
        "Analytics.": "Analytics",
        "Users.": "Users",
        "System.": "System",

        "Analytics": "Analytics",
        "App Statistics": "App Statistics",
        "Museum Transition Map": "Museum Transition Map",
        "Art Piece Transition Map": "Art Piece Transition Map",
        "Update": "Update",
        "App Usage": "App Usage",
        "Feature Usage": "Feature Usage",
        "Art Pieces Tracked": "Art Pieces Tracked",
        "All": "All",
        "Start Date": "Start Date",
        "End Date": "End Date",
        "Exhibition": "Exhibition",
        "Day": "Day",
        "Unique Users": "Unique Users",
        "App Launches": "App Launches",
        "Feature": "Feature",
        "Hours used": "Hours used",
        "Seconds used": "Seconds used",
        "Artwork": "Artwork",
        "Tracking events": "Tracking events",

        "AnalyticsFeature:Home": "Home",
        "AnalyticsFeature:InfoPage": "Encyclopedia",
        "AnalyticsFeature:Trail": "Tour",
        "AnalyticsFeature:AR": "Art scanner",
        "AnalyticsFeature:Map": "Map",
        "AnalyticsFeature:BurgerMenu": "Main menu",
        "AnalyticsFeature:HistoryTimeline": "Historical timeline",
        "AnalyticsFeature:WorldMap": "World map",
        "AnalyticsFeature:Search": "Search",
        "AnalyticsFeature:None": "Other",
        "${v} of usage": "${v} of usage",
        "${v} of total usage": "${v} of total usage",
        "No data": "No data",

        "Art-Piece Visit Statistics": "Art-Piece Visit Statistics",
        "Art Piece": "Art Piece",
        "Start of App Usage": "Start of App Usage",
        "Popularity": "Popularity",
        "Visits": "Visits",
        "Time Spent": "Time Spent",
        "Average Time Spent": "Average Time Spent",
        "Time Spent in AR": "Time Spent in AR",
        "End of App Usage": "End of App Usage",
        "Art Pieces visited next": "Art Pieces visited next",
        "Count": "Count",
        "Probability": "Probability",

        "Interaction Visit Statistics": "Interaction Visit Statistics",
        "Interactions visited next": "Interactions visited next",
        "spent on average": "spent on average",
        "first visited interaction of this art piece": "first visited interaction of this art piece",
        "last visited interaction of this art piece": "last visited interaction of this art piece",
        "Select Art Piece": "Select Art Piece",
        "Select Art Piece...": "Select Art Piece...",
        "Interaction": "Interaction",
        "First Interaction": "First Interaction",
        "Last Interaction": "Last Interaction",

        "times visited": "times visited",
        "spent here on average": "spent here on average",
        "spent here on average in AR mode": "spent here on average in AR mode",
        "first visited painting of the exhibition": "first visited painting of the exhibition",
        "last visited painting of the exhibition": "last visited painting of the exhibition",

        "In case you need help or support, please contact:": "In case you need help or support, please contact:",
        "Credits": "Credits",

        "Error 403": "Error 403",
        "You are not permitted to access this page.": "You are not permitted to access this page.",
        "Error 404": "Error 404",
        "Page not found.": "Page not found."
    }
}