import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/src/styles/main.sass'
import colors from 'vuetify/es5/util/colors'
import '@mdi/font/css/materialdesignicons.css'


Vue.use(Vuetify);


export default new Vuetify({
    icons: {
        iconfont: "mdi"
    },
    theme: {
        themes: {
            light: {
                primary: colors.deepPurple.darken2,
                secondary: colors.deepPurple.lighten4
            }
        },
        options: { customProperties: true },
    }
});