<template>
    <div class="inputFieldContainer">
        <img v-if="previewImage" :src="previewImage" class="previewImage">
        <div v-if="icon" class="previewIconContainer">
            <v-icon size="70px" class="previewIcon">
                {{ icon }}
            </v-icon>
        </div>
        <grid-loader :loading="showSpinner" color="grey" size="20px" class="spinner" />
        <div class="inputFieldLine">
            <v-text-field
                v-model="fileName"
                :label="label"
                :error="field.error"
                :error-messages="field.errorMessages"
                prepend-icon="mdi-paperclip"
                :class="field.required ? ' required' : ''"
                @keyup="onKeyup"
                @click="pickFile"
            />
            <input
                ref="input"
                type="file"
                class="hiddenInput"
                :accept="acceptedFileFormatsString"
                @change="onFilePicked"
            >
            <v-tooltip v-if="showFileChangedWarning || isFileRejected" bottom>
                <template #activator="{ on }">
                    <v-btn text icon color="primary" style="margin-top:10px" v-on="on" @click="reset">
                        <v-icon>mdi-restore</v-icon>
                    </v-btn>
                </template>
                <span class="body-2">{{ l("Undo File Change") }}</span>
            </v-tooltip>
        </div>
        <div v-if="isFileRejected" style="display:inline-flex; color:red;">
            <v-icon large color="red">
                warning
            </v-icon>
            <span style="width:100%; text-align:left; padding-left:10px; padding-top: 6px">
                {{ l("Sorry, this file type is not supported.") }}
            </span>
        </div>
        <div v-if="showFileChangedWarning" style="display:inline-flex; color:grey;">
            <v-icon large color="grey">
                warning
            </v-icon>
            <span style="width:100%; text-align:left; padding-left:10px; padding-top: 6px">
                {{ l("The file \"${v}\" will be overwritten when clicking UPDATE.", field.existingFile.fileName) }}
            </span>
        </div>
    </div>
</template>
<script>

    import GridLoader from 'vue-spinner/src/GridLoader.vue';
    import { l } from "../../../utils/LocalizationUtils.js";


    export default {
        components: {
            GridLoader,
        },
        model: {
            prop: "propField",
            event: "input"
        },
        props: {
            propField: { type: Object, default: null },
            waitingForResponse: { type: Boolean, default: false }
        },
        data() {
            return {
                fileName: "",
                fileType: "",
                localFileURI: "",
                isLoading: false,
                isLoaded: false,
                isFileRejected: false
            };
        },
        computed: {
            field() {
                return this.propField;
            },
            label() {
                return this.field.label ? l(this.field.label) : null;
            },
            showSpinner() {
                return this.isLoading || this.waitingForResponse;
            },
            previewImage() {
                if (!this.showSpinner && (this.isLoaded || this.localFileURI !== "") && this.fileType.includes("image")) {
                    return this.localFileURI;
                }
                return null;
            },
            icon() {
                if (!this.showSpinner && (this.isLoaded || this.fileName !== "")) {
                    if (this.fileType.includes("image") && this.previewImage === null) {
                        return "mdi-file-image";
                    } else if (this.fileType.includes("video") ) {
                        return "mdi-movie-open";
                    } else if (this.fileType.includes("audio")) {
                        return "mdi-music-box-outline";
                    } else if (this.fileType.includes("text")) {
                        return "mdi-file-document-outline";
                    } else if (this.fileType.includes("pdf")) {
                        return "mdi-file-pdf";
                    } else if (this.fileType.includes("octet-stream")) {
                        return "mdi-file-code-outline";
                    }
                }
                return null;
            },
            showFileChangedWarning() {
                return this.isLoaded && this.field.existingFile && !this.waitingForResponse;
            },
            acceptedFileFormatsString() {
                const formats = [];
                Object.values(this.field.acceptedFileFormats).forEach(format => {
                    formats.push(...Object.values(format));
                });
                return formats.join(",");
            }
        },
        mounted() { 
            this.reset();
        },
        methods: {
            pickFile() {
                this.$refs.input.click(); // Click onto the hidden <input /> to start file selection
            },
            dataURItoMimeType(dataURI) {
                return dataURI.split(",")[0].split(":")[1].split(";")[0]; // separates out the mime component
            },
            isSupportedFileType(fileType) {
                for (let fileFormat of Object.values(this.field.acceptedFileFormats)) {
                    if (fileFormat.hasOwnProperty(fileType)) {
                        return true;
                    }
                }
                return false;
            },
            onFilePicked(event) {

                this.field.value = null;

                const files = event.target.files;
                if (typeof files[0] !== "undefined") {

                    const file = files[0];

                    this.fileName = file.name;
                    this.isLoading = true;
                    this.isFileRejected = false;
                    this.isLoaded = false;
                    
                    const fr = new FileReader ();
                    fr.readAsDataURL(file);
                    fr.addEventListener('load', () => {

                        this.isLoading = false;
                        this.localFileURI = fr.result;
                        this.fileType = this.dataURItoMimeType(this.localFileURI);

                        const metaData = { 
                            name: file.name, 
                            type: file.type ? file.type : this.fileType,
                            size: file.size,
                            lastModifiedDate: file.lastModifiedDate,
                        };

                        if (this.isSupportedFileType(metaData.type)) {
                            this.isLoaded = true;
                            this.field.value = { metaData, url: this.localFileURI };

                        } else {
                            this.isFileRejected = true;
                            this.isLoaded = false;
                            this.field.error = true;
                        }

                    });
                } else {
                    // Reset if no file is selected (when cancel is pressed in file dialog)
                    this.reset();
                }
            },
            onKeyup(event) {
                if (event.key === "Enter") {
                    if (this.fileName === "") {
                        this.pickFile();
                    } else {
                        this.$emit("submitOnReturn", event);
                    }
                }
            },
            reset() {

                this.fileName = "";
                this.fileType = "";
                this.localFileURI = "";

                if (this.field.existingFile) {
                    this.fileName = this.field.existingFile.fileName;
                    this.fileType = this.field.existingFile.fileType;
                    this.localFileURI = "/assets/" + this.field.existingFile._id + ".jpg?quality=medium&update=" + this.field.existingFile.fileId.substring(2, 8); // '&update' is needed such that the thumbnail is updated when the referenced image changes;
                }

                this.isLoading = false;
                this.isLoaded = false;
                this.isFileRejected = false;

                this.field.value = null;
                this.field.error = false;

            },
            l
        }}
</script>
<style scoped>

    .inputFieldContainer {
        display: flex;
        flex-direction: column;
    }

    .previewIconContainer {
        display: flex;
        justify-content: center;
    }

    .previewImage {
        height: 100px;
        margin: 0 auto;
    }

    .previewIcon {
        height: 90px;
    }

    .spinner {
        margin: 0 auto;
    }

    .inputFieldLine {
        display: flex;
    }

    .hiddenInput {
        display: none;
    }

</style>
