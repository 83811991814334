import axios from "axios";
import { triggerError } from "../utils/alerts.js";


const url = "/api/languages/";


class LanguageService {

    // Get
    static getLanguage(id, callback) {
        axios.get(`${url}${id}`).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }

    // Get all
    static getLanguages(callback) {
        axios.get(url).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }

    // Post
    static insertLanguage(language, callback) {
        axios.post(url, language).then(response => {
            callback(response.data);
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.isJoi) {
                callback({ errors: error.response.data.details });
            } else {
                triggerError(error);
            }
        });
    }

    // Put
    static updateLanguage(language, callback) {
        axios.put(`${url}${language._id}`, language).then(response => {
            response.data._id = language._id;
            callback(response.data);
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.isJoi) {
                callback({ errors: error.response.data.details });
            } else {
                triggerError(error);
            }
        });
    }

    // Delete
    static deleteLanguage(languageId, callback) {
        axios.delete(`${url}${languageId}`).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }

}

export default LanguageService;