import * as networkService from "../../services/networkService.js";


const url = "/api/tags/";


export const get = id => networkService.get({
    url: `${url}${id}`,
});


export const getAll = () => networkService.get({
    url,
    defaultResponse: [],
});


export const insert = tag => networkService.insert({
    url,
    entry: tag,
});


export const update = tag => networkService.update({
    url: `${url}${tag._id}`,
    entry: tag,
});


export const remove = id => networkService.remove({
    url: `${url}${id}`,
});