import axios from "axios";
import { triggerError } from "../utils/alerts.js";


const url = "/api/informationPageTypes/";


class InformationPageTypesService {

    // Get
    static getInformationPageType(id, callback) {
        axios.get(`${url}${id}`).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }

    // Get all
    static getInformationPageTypes(callback) {
        if (callback) {
            axios.get(url).then(response => {
                callback(response.data);
            }).catch(error => {
                triggerError(error);
            });
        } else {
            return new Promise(async resolve => {
                try {
                    const response = await axios.get(url);
                    resolve(response.data);
                } catch (error) {
                    triggerError(error);
                    resolve([]);
                }
            });
        }
    }

    // Get all types for encyclopedia
    static getInformationPageTypesForEncyclopedia(callback) {
        axios.get(url + "forEncyclopedia/").then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }

    // Put
    static updateInformationPageTypes(informationPageTypes, callback) {
        axios.put(url, informationPageTypes).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }

}

export default InformationPageTypesService;