<template>
    <div class="element">
        <h2
            v-if="element.type == 'title'"
            class="title primary--text pt-5"
        >
            {{ element.text }}
        </h2>
        <div
            v-if="element.type == 'text'"
            class="text-body-2 py-2"
        >
            {{ element.text }}
        </div>
        <div
            v-if="element.type == 'spacer'"
            class="spacer"
        >
            {{ element.text }}
        </div>
        <UsageBar
            v-if="element.type == 'usageBar'"
            :element="element"
        />
    </div>
</template>
<script>

    import UsageBar from "./UsageBar.vue";


    export default {
        components: {
            UsageBar,
        },
        props: {
            element: { type: Object, default: null },
        },
    }

</script>
<style scoped>

    .element {
        flex-grow: 1;
        flex-shrink: 1;
    }

    .spacer {
        height: 50px;
    }

</style>
