
import { l, tryGetTokenOverride, selectLanguage } from "./localization.js";


// Contains app-specific translations
export const general = {


    
    themeName(theme, locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "themeName");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        switch(theme) {
            case "scanner": return l.modules.scanner.name(locale);
            case "floorplan": return l.modules.floorplan.name(locale);
            case "timeline": return l.modules.timeline.name(locale);
            case "tours": return l.modules.tours.name(locale);
            case "encyclopedia": return l.modules.encyclopedia.name(locale);
            case "exhibition": return l.modules.exhibition.name(locale);
            default: return `[${theme} not supported]`;
        }
            
    },
            

    
    themeDescription(theme, locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "themeDescription");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        switch(theme) {
            case "scanner": return l.modules.scanner.description(locale);
            case "floorplan": return l.modules.floorplan.description(locale);
            case "timeline": return l.modules.timeline.description(locale);
            case "tours": return l.modules.tours.description(locale);
            case "encyclopedia": return l.modules.encyclopedia.description(locale);
            case "exhibition": return l.modules.exhibition.description(locale);
            default: return `[${theme} not supported]`;
        }
            
    },
            
};
