<template>
    <WindowedForm v-model="form" />
</template>
<script>

    import ARTargetService from "../../services/ARTargetService.js";
    import WindowedForm from "../globalUiElements/WindowedForm.vue";
    import { createForm } from "../../utils/FormUtils.js";
    import { l } from "../../utils/LocalizationUtils.js";


    export default {
        components: {
            WindowedForm
        },
        data() {
            return {
                form: createForm({

                    title: "Add AR Target",

                    action: ARTargetService.insertARTarget,

                    fieldDefs: [
                        {
                            uiElement: "Title",
                            icon: "mdi-playlist-edit",
                            text: "Details"
                        },
                        {
                            key: "linkedContent",
                            type: "LinkedContentSelector",
                            label: "Linked art piece"
                        },
                        {
                            key: "title",
                            type: "Text",
                            label: "Title in CMS (not shown in the app)",
                            required: true,
                            passOtherParameters: ["linkedContent"],
                            visibility: field => !field.otherParameters?.linkedContent?.value
                        },
                        {
                            uiElement: "Divider"
                        },
                        {
                            uiElement: "Title",
                            icon: "mdi-cube-scan",
                            text: "Target"
                        },
                        {
                            key: "target",
                            type: "Reference",
                            referenceOf: "media",
                            referenceType: "image",
                            label: "Target image",
                            required: true
                        },
                        {
                            key: "width",
                            type: "Text",
                            label: "Width of target in cm",
                            required: true
                        },
                        {
                            uiElement: "Divider"
                        },
                        {
                            uiElement: "Title",
                            icon: "mdi-ruler-square",
                            text: "Display Settings"
                        },
                        {
                            key: "showOverlay",
                            type: "Checkbox",
                            label: "Show overlay on target in AR when it's being tracked",
                            value: true
                        },
                        {
                            key: "overlay",
                            type: "Reference",
                            referenceOf: "media",
                            referenceType: "image",
                            label: "AR overlay",
                        },
                        {
                            key: "poiDisplayMode",
                            type: "Select",
                            label: "POI selection display mode",
                            options: [
                                { text: "Default (as set for entire exhibition)", value: "Default" },
                                { text: "Only in AR", value: "AR" },
                                { text: "Only in screenspace", value: "Screenspace" },
                                { text: "Both in AR and screenspace", value: "Both" },
                            ],
                            value: "Default",
                            passOtherParameters: ["overlay"],
                            visibility: field => field.otherParameters?.overlay?.value
                        },
                        {
                            key: "screenspaceOpeningMode",
                            type: "Select",
                            label: "How can screenspace POI selection be opened",
                            options: [
                                { text: "Default (as set for entire exhibition)", value: "Default" },
                                { text: "Button in information box", value: "InfoBoxButton" },
                                { text: "Button on AR target", value: "ARButton" },
                                { text: "Buttons both in information box and on AR target", value: "Both" },
                            ],
                            value: "Default",
                            passOtherParameters: ["overlay", "poiDisplayMode"],
                            visibility: field => {
                                const poiDisplayMode = field.otherParameters?.poiDisplayMode?.value;
                                return poiDisplayMode !== "AR" && field.otherParameters?.overlay?.value;
                            },
                        },
                        {
                            key: "showInfoBox",
                            type: "Checkbox",
                            label: "Show information box when AR target is tracked",
                            value: true,
                            passOtherParameters: ["linkedContent"],
                            visibility: field => field.otherParameters?.linkedContent?.value
                        },
                        {
                            key: "overrideCopyrightLine",
                            type: "Checkbox",
                            label: "Override exhibition's copyright line in information box",
                            value: false,
                            passOtherParameters: ["linkedContent", "showInfoBox"],
                            visibility: field => field.otherParameters?.linkedContent?.value && field.otherParameters?.showInfoBox?.value
                        },
                        {
                            key: "copyrightLine",
                            type: "Text",
                            label: "Copyright line in information box",
                            localized: true,
                            passOtherParameters: ["linkedContent", "showInfoBox", "overrideCopyrightLine"],
                            visibility: field => field.otherParameters?.linkedContent?.value && field.otherParameters?.showInfoBox?.value && field.otherParameters?.overrideCopyrightLine?.value
                        },
                        {
                            key: "offsetInteractions",
                            type: "Number",
                            label: "Offset of interactions from AR target in cm",
                            value: 0.0
                        },
                        {
                            key: "uses",
                            type: "Hidden",
                            value: []
                        },
                    ],

                    submitLabel: "Add",

                    submitted: entry => {
                        this.$root.$emit("success", l("AR Target added.", entry));
                        this.$emit("createdEntry");
                    },

                    cancelled: () => {
                        this.$emit("cancel");
                    }
                })
            }
        }
    }
</script>