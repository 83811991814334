<template>
    <div>
        <div v-for="(field, index) in fields" :key="index">
            <FormUiElement
                v-if="field.uiElement"
                v-model="fields[index]"
            />
            <InputField
                v-if="field.key"
                v-model="fields[index]"
                :waiting-for-response="waitingForResponse"
                @scrollTo="scrollTo"
                @submitOnReturn="submitOnReturn"
            />
        </div>
    </div>
</template>
<script>

    import FormUiElement from "./FormUiElement.vue";
    import InputField from "./InputField.vue";


    export default {
        components: {
            FormUiElement,
            InputField
        },
        model: {
            prop: "propFields",
            event: "input"
        },
        props: {
            propFields: { type: Array, default: () => [] },
            waitingForResponse: { type: Boolean, default: false },
        },
        computed: {
            fields() {
                return this.propFields;
            }
        },
        methods: {
            scrollTo(scrollTarget) {
                this.$emit("scrollTo", scrollTarget);
            },
            submitOnReturn(event) {
                this.$emit("submit", event);
            }
        }
    }
</script>