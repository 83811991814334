<template>
    <div :class="'array-input pt-1 px-3 pb-2' + (field.inMultipleFieldArrayEntry ? ' my-4' : ' mt-3') + (field.required ? ' required' : '')">
        <v-subheader v-if="!field.error" class="pt-0 pl-0 pb-0 manual-label label">
            {{ fieldLabel }}
        </v-subheader>
        <v-subheader v-if="field.error" style="color:red" class="pt-0 pl-0 pb-0 manual-label label">
            {{ fieldLabel }}
        </v-subheader>
        <p v-if="field.error" style="color:red; margin: -15px 0 10px 0; font-size:smaller;">
            {{ errorMessage }}
        </p>
        <div v-for="(entry, index) in field.entries" :key="entry.uid">
            <ArrayEntry 
                v-model="field.entries[index]"
                :index="index"
                :can-move-up="index !== 0"
                :can-move-down="index !== field.entries.length - 1"
                :waiting-for-response="waitingForResponse"
                @remove="removeEntry(index)"
                @moveUp="moveUpEntry(index)"
                @moveDown="moveDownEntry(index)"
                @scrollTo="scrollTo"
                @submitOnReturn="submitOnReturn"
            />
        </div>
        <v-layout justify-center>
            <v-btn :disabled="!canAddEntry" color="primary" :small="field.inMultipleFieldArrayEntry" @click="addEntry">
                <v-icon :small="field.inMultipleFieldArrayEntry" style="margin: 0 10px 2px 0;">
                    add_box
                </v-icon>
                {{ addEntryLabel }}
            </v-btn>
        </v-layout>
    </div>
</template>
<script>

    import ArrayEntry from "./ArrayEntry.vue";
    import { l } from "../../../utils/LocalizationUtils.js";


    export default {
        components: {
            ArrayEntry,
        },
        model: {
            prop: "propField",
            event: "input"
        },
        props: {
            propField: { type: Object, default: null },
            waitingForResponse: { type: Boolean, default: false }
        },
        data() {
            return {
                canAddEntry: true // TODO maybe set a limit to maxNumOfParameters
            };
        },
        computed: {
            field() {
                return this.propField;
            },
            fieldLabel() {
                return l(this.field.label) + " (" + this.field.entries.length + ")";
            },
            addEntryLabel() {
                return l("Add ${v}", l(this.field.entryLabel));
            },
            errorMessage() {
                if (this.field.errorMessages && this.field.errorMessages.length > 0) {
                    return this.field.errorMessages[0];
                }
                return "";
            }
        },
        methods: {
            addEntry() {
                if (this.canAddEntry) {
                    this.field.addEntry();
                }
            },
            removeEntry(index) {
                this.field.removeEntry(index);
            },
            moveUpEntry(index) {
                this.field.moveUpEntry(index);
            },
            moveDownEntry(index) {
                this.field.moveDownEntry(index);
            },
            scrollTo(scrollTarget) {
                this.$emit("scrollTo", scrollTarget);
            },
            submitOnReturn(event) {
                this.$emit("submitOnReturn", event);
            }
        }
    }    
</script>
<style>

    .array-input {
        background: #f3f3f3;
    }

    .manual-label {
        font-size: 16px;
        font-weight: 400;
    }

</style>