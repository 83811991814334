<template>
    <v-expand-transition>
        <div
            v-if="locationBeingEdited"
            class="locationEditingPanel"
        >
            <v-progress-linear v-if="saving" class="loadingBar" indeterminate />
            <div class="closeButton">
                <ListRowButton
                    :label="l('Close')"
                    icon="mdi-close"
                    icon-size="32"
                    @click="close()"
                />
            </div>
            <div class="panelHeader">
                <div class="image">
                    <v-icon v-if="!previewData.mediumImage" size="60">
                        {{ previewData.icon }}
                    </v-icon>
                    <img :src="previewData.mediumImage" @click="openImageInLightbox(previewData.veryLargeImage)">
                </div>
                <div class="rightColumn">
                    <div class="typeLabel text-body-2 font-italic">
                        {{ previewData.typeLabel }}
                    </div>
                    <div class="titleBox text-subtitle-2">
                        {{ previewData.label }}
                    </div>
                    <div class="detailsBox text-body-2">
                        {{ previewData.details }}
                    </div>
                </div>
            </div>
            <div class="panelFooter">
                <v-icon class="positionIcon">
                    mdi-map-marker
                </v-icon>
                <div class="positionData">
                    <div class="positionLine">
                        <v-icon size="16" class="icon">
                            mdi-arrow-expand-right
                        </v-icon>
                        <div class="axis text-body-2">
                            x:
                        </div>
                        <EditableValueField
                            :value="(locationBeingEdited.positionX * 100).toFixed(2)"
                            width="56"
                            :disabled="saving || locationBeingEdited.locked"
                            @change="applyPositionChangeX"
                        />
                    </div>
                    <div class="positionLine">
                        <v-icon size="16" class="icon">
                            mdi-arrow-expand-down
                        </v-icon>
                        <div class="axis text-body-2">
                            y:
                        </div>
                        <EditableValueField
                            :value="(locationBeingEdited.positionY * 100).toFixed(2)"
                            width="56"
                            :disabled="saving || locationBeingEdited.locked"
                            @change="applyPositionChangeY"
                        />
                    </div>
                </div>
                <ListRowButton
                    :label="locationBeingEdited.locked ? l('Locked (Click to unlock)') : l('Unlocked (Click to lock)')"
                    :icon="locationBeingEdited.locked ? 'mdi-lock' : 'mdi-lock-open-outline'"
                    @click="toggleLock"
                />
                <ListRowButton
                    :label="l('Delete Location')"
                    icon="mdi-delete"
                    :disabled="locationBeingEdited.locked"
                    @click="deleteItem"
                />
            </div>
        </div>
    </v-expand-transition>
</template>
<script>

    import { mapActions } from "vuex";
    import ListRowButton from "../../components/globalUiElements/ListRowButton.vue";
    import EditableValueField from "../../components/globalUiElements/EditableValueField.vue";
    import { l } from "../../utils/LocalizationUtils.js";


    export default {
        components: {
            ListRowButton,
            EditableValueField,
        },
        props: {
            locationBeingEdited: { type: Object, default: null },
            saving: { type: Boolean, default: false },
        },
        computed: {
            previewData() {
                if(this.locationBeingEdited) {
                    return this.locationBeingEdited.previewData;
                }
                return {};
            },
        },
        methods: {

            close() {
                this.$emit("close");
            },

            applyPositionChangeX(newPositionX) {
                this.$emit("save", {
                    positionX: Math.min(Math.max(newPositionX / 100.0, 0.0), 1.0),
                });
            },

            applyPositionChangeY(newPositionY) {
                this.$emit("save", {
                    positionY: Math.min(Math.max(newPositionY / 100.0, 0.0), 1.0),
                });
            },

            toggleLock() {
                this.$emit("save", {
                    locked: !this.locationBeingEdited.locked,
                });
            },

            deleteItem() {
                this.$emit("deleteItem");
            },

            l,

            ...mapActions({
                openImageInLightbox: "lightbox/openImage",
            }),

        },
    }

</script>
<style scoped>

    .locationEditingPanel {
        position: relative;
        background: #fff;
        border-top: 1px solid #e0e0e0;
        padding: 20px;
        flex-shrink: 0;
        flex-grow: 0;
    }

    .loadingBar {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
    }

    .closeButton {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .panelHeader {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .panelHeader .image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        height: 150px;
        background: #d0d0d0;
        flex-grow: 0;
    }

    .panelHeader .image img {
        max-width: 142px;
        max-height: 142px;
        box-shadow: rgba(0, 0, 0, 0.6) 0 1px 5px;
        cursor: pointer;
    }

    .panelHeader .rightColumn {
        width: 180px;
        align-self: flex-end;
        flex-grow: 0;
    }

    .panelHeader .rightColumn .typeLabel {
        width: 120px;
        max-height: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 5px 0;
    }

    .panelHeader .rightColumn .titleBox {
        max-height: 120px;
        line-height: 20px !important;
        overflow: hidden;
    }

    .panelHeader .rightColumn .detailsBox {
        margin: 5px 0;
        max-height: 120px;
        line-height: 20px !important;
        overflow: hidden;
    }

    .panelFooter {
        display: flex;
        height: 52px;
        align-items: flex-end;
        justify-content: space-between;
    }

    .panelFooter .positionIcon {
        width: 32px;
        align-self: center;
        flex-shrink: 0;
        flex-grow: 0;
    }

    .panelFooter .positionData {
        align-self: center;
        height: 52px;
        flex-shrink: 1;
        flex-grow: 1;
    }

    .panelFooter .positionData .positionLine {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 26px;
        padding-left: 10px;
    }

    .panelFooter .positionData .positionLine .icon {
        height: 24px;
        align-self: flex-end;
    }

    .panelFooter .positionData .positionLine .axis {
        width: 16px;
        text-align: right;
    }

</style>
