import axios from "axios";
import { triggerError } from "../utils/alerts.js";


const url = "/api/env/";


class BackendEnvService {

    static getEnvFile(callback) {
        axios.get(url).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }

}

export default BackendEnvService;