
import { l, tryGetTokenOverride, selectLanguage } from "./localization.js";


// Contains app-specific translations
export const userSettings = {


    
    userSettings(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "userSettings");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return "User Settings";
            case "de": return "Benutzereinstellungen";
            case "fr": return "Paramètres de l'utilisateur";
            default: return `[userSettings: ${language} missing]`;
        }
    
    },
            
};
