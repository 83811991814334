<template>
    <div>
        <h2 class="title mb-3 primary--text">
            {{ l("Environment Variables") }}
        </h2>
        <div class="subtitle-1">
            {{ l("Backend") }}
        </div>
        <div class="envBox">
            <!-- eslint-disable -->
            <div class="env">{{ backendEnv }}</div>
            <!-- eslint-enable -->
        </div>
        <div class="subtitle-1 mt-4">
            {{ l("Frontend") }}
        </div>
        <div class="envBox">
            <!-- eslint-disable -->
            <div class="env">{{ frontendEnv }}</div>
            <!-- eslint-enable -->
        </div>
    </div>
</template>
<script>

    import { mapActions } from "vuex";
    import BackendEnvService from "../../services/BackendEnvService.js";
    import FrontendEnvService from "../../services/FrontendEnvService.js";
    import { l } from "../../utils/LocalizationUtils.js";


    export default {
        props: {
            reloadTrigger: { type: Number, default: 0 },
        },
        data() {
            return {
                backendEnv: "",
                frontendEnv: "",
            };
        },
        watch: {
            reloadTrigger() {
                this.update();
            }
        },
        mounted() {
            this.update();
        },
        methods: {

            update() {
                this.updateSectionTitle(this.l("Environment Variables"));
                BackendEnvService.getEnvFile(content => {
                    this.backendEnv = content.trim();
                });
                FrontendEnvService.getEnvFile(content => {
                    this.frontendEnv = content.trim();
                });
            },

            l,

            ...mapActions({
                updateSectionTitle: "platform/updateSectionTitle"
            }),
        }
    }
</script>
<style scoped>
    .envBox {
        border: 1px solid #bbb;
        height: 320px;
        background: #ddd;
        overflow: auto;
    }

    .env {
        padding: 20px;
        color: #333;
        font-size: 14px;
        font-family: "Lucida Console", Monaco, monospace;
        line-height: 18px;
        white-space: pre-wrap;
        overflow-wrap: break-word;
    }
</style>
