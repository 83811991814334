<template>
    <div class="addBeforeContainer">
        <div :class="'addBeforeElement' + (firstElement && lastElement ? ' onlyElement' : '')">
            <a class="addBeforeElementButton" @click="openSelectionBox">
                <div class="addBeforeElementLine elevation-1 primary" />
                <div class="addBeforeElementIcon elevation-3 primary">
                    <v-icon color="#fff">
                        mdi-plus
                    </v-icon>
                </div>
            </a>
            <v-overlay :value="showSelectionBox" @click.native="closeSelectionBox" />
            <transition name="slide-x-transition">
                <div v-if="showSelectionBox" class="selectionBox elevation-5" :style="{ top: selectionBoxTop, height: selectionBoxHeight }">
                    <v-icon x-large class="selectionBoxArrow" :style="{ top: selectionBoxArrowTop }">
                        mdi-chevron-left
                    </v-icon>
                    <div class="selectionBoxButtonsContainer">
                        <v-btn
                            v-for="elementType in availableElementTypes"
                            :key="elementType.title"
                            :disabled="saving !== null && saving !== elementType"
                            :loading="saving === elementType"
                            class="selectionBoxButton subtitle-1"
                            text
                            color="primary"
                            @click="addElement(elementType)"
                        >
                            <v-icon left>
                                {{ elementType.mdiIcon }}
                            </v-icon>
                            {{ elementType.title }}
                        </v-btn>
                        <v-btn
                            :disabled="saving !== null"
                            class="selectionBoxButton subtitle-1"
                            text
                            color="error"
                            @click="closeSelectionBox"
                        >
                            <v-icon left>
                                mdi-close
                            </v-icon>
                            {{ l("Cancel") }}
                        </v-btn>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>
<script>

    import { l } from "../../utils/LocalizationUtils.js";

    export default {
        props: {
            index: { type: Number, default: 0 },
            firstElement: { type: Boolean, default: false },
            lastElement: { type: Boolean, default: false },
            availableElementTypes: { type: Array, default: () => [] }
        },
        data() {
            return {
                showSelectionBox: false,
                saving: null,
                selectionBoxTop: "-8px",
                selectionBoxHeight: "40px",
                selectionBoxArrowTop: "0px",
            }
        },
        watch: {
            availableElementTypes() {
                this.updateSize();
            }
        },
        methods: {
            openSelectionBox() {
                this.showSelectionBox = true;
                this.saving = null;
                this.updateSize();
            },
            closeSelectionBox() {
                this.showSelectionBox = false;
                this.saving = false;
            },
            addElement(elementType) {
                this.saving = elementType;
                this.$emit("addElement", {
                    index: this.index,
                    elementType,
                    onSuccess: () => {
                        this.closeSelectionBox();
                    }
                });
            },
            updateSize() {
                const numberOfButtons = this.availableElementTypes.length + 1; // + cancel button
                const height = (2 + (38 * numberOfButtons)); // 36 per button + 2 margin
                this.selectionBoxTop = "-" + ((height / 2) - 12) + "px";
                this.selectionBoxHeight = height + "px";
                this.selectionBoxArrowTop = ((height / 2) - 20) + "px";
            },
            l
        }
    }
</script>
<style scoped>

    .addBeforeContainer {
        position: relative;
        width: 480px;
        height: 0px;
    }

    .addBeforeElement {
        position: absolute;
        top: -12px;
        left: 0px;
        width: 480px;
        height: 24px;
        z-index: 4;
    }

    .addBeforeElementButton {
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 3;
    }

    .addBeforeElementLine {
        display: none;
    }

    .addBeforeElement:hover .addBeforeElementLine,
    .addBeforeElement.onlyElement .addBeforeElementLine {
        display: block !important;
        position: absolute;
        left: 0px;
        right: 0px;
        top: 11px;
        height: 2px;
    }

    .addBeforeElementIcon {
        display: none;
    }

    .addBeforeElement:hover .addBeforeElementIcon,
    .addBeforeElement.onlyElement .addBeforeElementIcon {
        display: flex !important;
        position: absolute;
        left: 228px;
        top: 0px;
        width: 24px;
        height: 24px;
        border-radius: 12px;
    }

    .selectionBox {
        position: absolute;
        left: 470px;
        width: 274px;
        background: #fff;
        z-index: 6;
        border-radius: 5px;
    }

    .selectionBoxArrow {
        position: absolute;
        left: -5px;
    }

    .selectionBoxButtonsContainer {
        position: absolute;
        left: 32px;
        top: 0;
        right: 0;
        bottom: 0;
    }

    .selectionBoxButton {
        width: 240px;
        height: 36px;
        margin: 2px 2px 0 0;
        text-transform: none !important;
        justify-content: flex-start;
    }

</style>