<template>
    <div class="blockContent">
        <TopicHeaderPreview
            v-if="previewType === 'topicHeader'"
            :values="values"
        />
        <BaseEncyclopediaHeaderPreview
            v-if="previewType === 'baseEncyclopediaHeader'"
            :values="values"
        />
        <AcquirerHeaderPreview
            v-if="previewType === 'acquirerHeader'"
            :values="values"
        />
        <ArtistHeaderPreview
            v-if="previewType === 'artistHeader'"
            :values="values"
        />
        <ArtworkHeaderPreview
            v-if="previewType === 'artworkHeader'"
            :values="values"
        />
        <ArtMovementHeaderPreview
            v-if="previewType === 'artMovementHeader'"
            :values="values"
        />
        <PlaceHeaderPreview
            v-if="previewType === 'placeHeader'"
            :values="values"
        />
        <TourInfoHeaderPreview
            v-if="previewType === 'tourInfoHeader'"
            :values="values"
        />
        <ParagraphPreview
            v-if="previewType === 'paragraph'"
            :values="values"
        />
        <SectionTitlePreview
            v-if="previewType === 'sectionTitle'"
            :values="values"
        />
        <LinkBoxPreview
            v-if="previewType === 'linkBox'"
            :values="values"
        />
        <CroppedImagePreview
            v-if="previewType === 'croppedImage'"
            :values="values"
        />
        <SlideshowPreview
            v-if="previewType === 'slideshow'"
            :values="values"
        />
        <HomeContentTourPreview
            v-if="previewType === 'homeContentTour'"
            :values="values"
        />
        <HomeContentTopicPreview
            v-if="previewType === 'homeContentTopic'"
            :values="values"
        />
        <div v-if="!previewType" class="defaultPreview">
            <div v-if="informationPageType" class="text-h4">
                <strong>{{ l(informationPageType.title) }}</strong>
            </div>
            <div v-if="blockType">
                <strong>{{ blockType.title }}</strong>
            </div>
            <div v-if="tour" class="text-h4">
                <strong>Tour</strong>
            </div>
            <div>
                {{ l("No preview available") }}
            </div>
            <div v-for="(value, key) in values" :key="key" class="caption">
                <em>{{ key }}: </em>
                <span v-if="value === null">null</span>
                <span v-else-if="Array.isArray(value)">
                    <span v-for="(arrayEntry, index) in value" :key="index">{{ arrayEntry }};</span>
                </span>
                <span v-else>{{ value }}</span>
            </div>
        </div>
    </div>
</template>
<script>

    import TopicHeaderPreview from "./TopicHeaderPreview.vue";
    import BaseEncyclopediaHeaderPreview from "./BaseEncyclopediaHeaderPreview.vue";
    import AcquirerHeaderPreview from "./AcquirerHeaderPreview.vue";
    import ArtistHeaderPreview from "./ArtistHeaderPreview.vue";
    import ArtworkHeaderPreview from "./ArtworkHeaderPreview.vue";
    import ArtMovementHeaderPreview from "./ArtMovementHeaderPreview.vue";
    import PlaceHeaderPreview from "./PlaceHeaderPreview.vue";
    import TourInfoHeaderPreview from "../../tours/blockPreviews/TourInfoHeaderPreview.vue";
    import ParagraphPreview from "./ParagraphPreview.vue";
    import SectionTitlePreview from "./SectionTitlePreview.vue";
    import LinkBoxPreview from "./LinkBoxPreview.vue";
    import CroppedImagePreview from "./CroppedImagePreview.vue";
    import SlideshowPreview from "./SlideshowPreview.vue";
    import HomeContentTourPreview from "../../homeContent/blockPreviews/HomeContentTourPreview.vue";
    import HomeContentTopicPreview from "../../homeContent/blockPreviews/HomeContentTopicPreview.vue";
    import { l } from "../../../utils/LocalizationUtils.js";

    export default {
        components: {
            TopicHeaderPreview,
            BaseEncyclopediaHeaderPreview,
            AcquirerHeaderPreview,
            ArtistHeaderPreview,
            ArtworkHeaderPreview,
            ArtMovementHeaderPreview,
            PlaceHeaderPreview,
            TourInfoHeaderPreview,
            ParagraphPreview,
            SectionTitlePreview,
            LinkBoxPreview,
            CroppedImagePreview,
            SlideshowPreview,
            HomeContentTourPreview,
            HomeContentTopicPreview
        },
        props: {
            informationPageType: { type: Object, default: () => null },
            blockType: { type: Object, default: () => null },
            tour: { type: Boolean, default: false },
            homeContentElement: { type: Object, default: () => null },
            values: { type: Object, default: () => { } }
        },
        data() {
            return {
                previewType: ""
            }
        },
        watch: {
            blockType() {
                this.determinePreviewType();
            }
        },
        created() {
            this.determinePreviewType();
        },
        methods: {
            determinePreviewType() {

                if (this.informationPageType) {

                    if (this.informationPageType.id === "topic") {
                        this.previewType = "topicHeader";

                    } else if (this.informationPageType.id === "acquirer") {
                        this.previewType = "acquirerHeader";

                    } else if (this.informationPageType.id === "artist") {
                        this.previewType = "artistHeader";

                    } else if (this.informationPageType.id === "artPiece") {
                        this.previewType = "artworkHeader";

                    } else if (this.informationPageType.id === "artMovement") {
                        this.previewType = "artMovementHeader";

                    } else if (this.informationPageType.id === "location") {
                        this.previewType = "placeHeader";

                    } else {
                        this.previewType = "baseEncyclopediaHeader";
                    }

                } else if (this.tour) {

                    this.previewType = "tourInfoHeader";

                } else if (this.blockType) {

                    if (this.blockType.id === "paragraph") {
                        this.previewType = "paragraph";

                    } else if (this.blockType.id === "sectionTitle") {
                        this.previewType = "sectionTitle";

                    } else if (this.blockType.id === "linkBox") {
                        this.previewType = "linkBox";

                    } else if (this.blockType.id === "croppedImage") {
                        this.previewType = "croppedImage";

                    } else if (this.blockType.id === "slideshow") {
                        this.previewType = "slideshow";

                    }

                } else if (this.homeContentElement) {

                    if (this.homeContentElement.type === "tour") {
                        this.previewType = "homeContentTour";

                    } else if (this.homeContentElement.type === "topic") {
                        this.previewType = "homeContentTopic";

                    }

                }
            },
            l
        }
    }
</script>
<style scoped>

    .blockContent {
        position: relative;
        width: 480px;
    }

    .defaultPreview {
        padding: 20px;
        background: #ccc;
    }

</style>