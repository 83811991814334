<template>
    <div class="defaultContentView">
        <div>
            <router-link to="/encyclopedia/" class="pt-2 pb-4 text-decoration-none" style="display:inline-block">
                <v-icon color="primary">
                    arrow_back
                </v-icon>
                {{ l("Encyclopedia") }}
            </router-link>
        </div>
        <h1 v-if="!informationPageTypeNotFound" class="display-1 mb-3 primary--text">
            <v-icon color="primary" class="titleIcon">
                {{ informationPageTypeIcon }}
            </v-icon>
            {{ l(informationPageTypeTitle) }}
        </h1>
        <h1 v-if="informationPageTypeNotFound" class="display-1 mb-3 primary--text">
            <v-icon color="primary" class="titleIcon">
                mdi-file-question
            </v-icon>
            {{ l("Not found") }}
        </h1>
        <InformationPagesList
            v-if="informationPageType"
            :information-page-type="informationPageType"
            :reload-trigger="listReloadTrigger"
            @add="add"
            @edit="edit"
            @editSettings="openEditSettingsForm"
        />
        <EditInformationPageSettings
            v-if="showEditSettingsForm"
            :entry="editingEntry"
            @cancel="closeEditSettingsForm"
            @editedEntry="editedEntry"
        />
    </div>
</template>
<script>

    import { mapActions } from "vuex";
    import InformationPagesList from "../../components/informationPages/InformationPagesList.vue";
    import EditInformationPageSettings from "../../components/informationPages/EditInformationPageSettings.vue";
    import InformationPageTypesService from "../../services/InformationPageTypesService.js";
    import { assignMostRecentTitleAndIcon } from "../../pages/utils/pageUtils.js";
    import { l } from "../../utils/LocalizationUtils.js";

    export default {
        components: {
            InformationPagesList,
            EditInformationPageSettings
        },
        data() {
            return {
                informationPageTypeTitle: "",
                informationPageType: null,
                informationPageTypeNotFound: false,
                listReloadTrigger: 0,
                showEditSettingsForm: false,
                editingEntry: null
            }
        },
        computed: {
            informationPageTypeIcon() {
                return this.informationPageType?.mdiIcon;
            }
        },
        created() {
            const informationPageTypeId = this.$route.params.type;
            InformationPageTypesService.getInformationPageType(informationPageTypeId, type => {
                if (type) {
                    this.informationPageType = assignMostRecentTitleAndIcon(type);
                    this.informationPageTypeTitle = this.informationPageType.titlePlural;
                    this.updateSectionTitle(this.l(this.informationPageTypeTitle));
                } else {
                    this.informationPageTypeNotFound = true;
                    this.updateSectionTitle(this.l("Not found"));
                }
            });
        },
        methods: {
            add(versionNumber) {
                this.$router.push("/encyclopedia/" + this.informationPageType.id + "/" + versionNumber + "/new");
            },
            edit(entry) {
                this.$router.push("/encyclopedia/" + this.informationPageType.id + "/" + entry._id);
            },
            openEditSettingsForm(entry) {
                this.editingEntry = entry;
                this.showEditSettingsForm = true;
            },
            closeEditSettingsForm() {
                this.showEditSettingsForm = false;
                this.editingEntry = null;
            },
            editedEntry() {
                this.closeEditSettingsForm();
                this.listReloadTrigger++;
            },
            l,
            ...mapActions({
                updateSectionTitle: "platform/updateSectionTitle"
            })
        }
    }
</script>
