<template>
    <div>
        <div style="display:inline-flex;">
            <v-tooltip top>
                <template #activator="{ on }">
                    <v-btn text icon color="primary" style="margin: 1px 10px 0 0;" v-on="on" @click="close()">
                        <v-icon size="38px">
                            arrow_back
                        </v-icon>
                    </v-btn>
                </template>
                <span class="body-2">{{ l("Back to list of exhibitions") }}</span>
            </v-tooltip>
            <h1 v-if="exhibition" class="display-1 mb-3 primary--text">
                {{ l("Manage AR Targets of \"${v}\"", exhibition.title) }}
            </h1>
        </div>
        <v-container fluid class="pb-5 px-0 mx-0">
            <v-row align="center" no-gutters>
                <v-col cols="4">
                    <v-text-field v-model="search" append-icon="mdi-magnify" :label="l('Search')" single-line clearable hide-details />
                </v-col>
                <v-spacer />
            </v-row>
        </v-container>
        <v-data-table
            hide-default-footer
            :headers="headers"
            :items="list"
            :sort-by="sortBy"
            :items-per-page="itemsPerPage"
            :loading="loading"
            :search="search"
            :custom-filter="arTargetSearch"
            :no-data-text="l('No entries.')"
        >
            <template #item="row">
                <tr>
                    <td style="width:68px">
                        <v-tooltip v-if="row.item.visible" right>
                            <template #activator="{ on }">
                                <v-btn text icon color="primary" :disabled="loading && !row.item.loading" :loading="row.item.loading" v-on="on" @click="toggleVisibility(row.item)">
                                    <v-icon>mdi-eye</v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">{{ l("Hide in App") }}</span>
                        </v-tooltip>
                        <v-tooltip v-if="!row.item.visible" right>
                            <template #activator="{ on }">
                                <v-btn text icon color="primary" :disabled="loading && !row.item.loading" :loading="row.item.loading" v-on="on" @click="toggleVisibility(row.item)">
                                    <v-icon>mdi-eye-off-outline</v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">{{ l("Show in App") }}</span>
                        </v-tooltip>
                    </td>
                    <td class="clickable fixed-row-height" style="width:76px" @click="toggleVisibility(row.item)">
                        <img :src="row.item.preview" class="preview-image">
                    </td>
                    <td class="clickable fixed-row-height truncate" @click="toggleVisibility(row.item)">
                        <span v-if="!row.item.previewData">
                            {{ row.item.label }}
                        </span>
                        <ReferencePreview
                            v-if="row.item.previewData"
                            type="page"
                            :preview-data="row.item.previewData"
                            only-icon
                            max-width="auto"
                        />
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>
<script>

    import ExhibitionService from "../../services/ExhibitionService.js";
    import ARTargetService from "../../services/ARTargetService.js";
    import ReferencePreview from "../globalUiElements/ReferencePreview.vue";
    import { triggerSuccess } from "../../utils/alerts.js";
    import { updateLookups } from "../../utils/referenceUtils.js";
    import { getPreviewDataFromId } from "../../utils/referencePreviewUtils.js";
    import { l } from "../../utils/LocalizationUtils.js";


    export default {
        components: {
            ReferencePreview,
        },
        props: {
            exhibitionId: { type: String, default: "" },
        },
        data() {
            return {
                loading: false,
                exhibition: null,
                list: [],
                search: "",
                sortBy: "label",
                itemsPerPage: -1,
                headers: [
                    { text: "", sortable: false },
                    { text: "", sortable: false },
                    { text: "Target", value: "label" },
                ],
            }
        },
        mounted() {
            this.update();
        },
        methods: {

            async update() {
                this.loading = true;

                this.exhibition = await ExhibitionService.getExhibition(this.exhibitionId);
                if(!Array.isArray(this.exhibition.arTargets)) {
                    this.exhibition.arTargets = [];
                }

                await updateLookups(["pages"]);

                const arTargets = await ARTargetService.getARTargets();
                arTargets.forEach(entry => {

                    entry.visible = this.exhibition.arTargets.includes(entry._id);
                    entry.loading = false;

                    entry.image = entry.overlay ? entry.overlay : entry.target;
                    entry.preview = "/assets/" + entry.image + ".jpg?quality=veryLow";

                    if (entry.linkedContent) {
                        entry.previewData = getPreviewDataFromId("page", entry.linkedContent);
                        if (entry.previewData) {
                            entry.label = entry.previewData.label;
                        } else {
                            entry.label = l("Cannot find linked content!");
                        }
                    } else {
                        entry.label = entry.title;
                    }
                });

                this.list = arTargets;
                this.loading = false;
            },

            arTargetSearch(value, search, item) {
                const searchString = search.trim().toLocaleLowerCase();
                return item.previewData
                    && item.previewData.searchableString
                    && item.previewData.searchableString.includes(searchString);
            },

            async toggleVisibility(arTarget) {
                if(!this.loading) {
                    arTarget.loading = true;
                    this.loading = true;
                    await ExhibitionService.linkARTarget(this.exhibition._id, arTarget._id, !arTarget.visible);
                    triggerSuccess(l("Successfully saved AR Target's visibility"));
                    arTarget.loading = false;
                    this.update();
                }
            },
            
            close() {
                this.$emit("close");
            },

            l,

        },
    }
</script>
<style scoped>

    .clickable {
        cursor: pointer;
    }

    .preview-image {
        display: block;
        max-width: 48px;
        max-height: 48px;
        margin: 0 auto;
    }

    .fixed-row-height {
        height: 61px !important;
    }

</style>
