<template>
    <v-app-bar app dark dense clipped-left class="deep-purple darken-2">
        <v-app-bar-nav-icon @click="toggleSideBar()" />
        <v-toolbar-title v-if="curationLockBypassed" class="pl-1 pr-3">
            <span class="curation-lock-bypassed body-2 text-uppercase">{{ curationLockEngagedMessage }}</span>
        </v-toolbar-title>
        <v-toolbar-title>
            <span class="headline text-uppercase">{{ title }}</span>
        </v-toolbar-title>
        <v-toolbar-title v-if="hasSystemAccessRights" class="versionBox caption">
            <span class="versionLabel">Backend</span> {{ backendVersion }}<br>
            <span class="versionLabel">Frontend</span> {{ frontendVersion }}<br>
            <span class="versionLabel">Build</span> {{ buildNumber }}
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-title class="body-2 pr-3">
            {{ loggedInAsLabel }}
            <span class="font-weight-bold">
                {{ userName }}
            </span>
        </v-toolbar-title>
        <v-toolbar-items>
            <v-tooltip bottom>
                <template #activator="{ on }">
                    <v-btn icon to="/user-settings/" v-on="on">
                        <v-icon>
                            account_circle
                        </v-icon>
                    </v-btn>
                </template>
                <span class="body-2">{{ userSettingsLabel }}</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template #activator="{ on }">
                    <v-btn icon @click="logout()" v-on="on">
                        <v-icon>
                            exit_to_app
                        </v-icon>
                    </v-btn>
                </template>
                <span class="body-2">{{ logoutButtonLabel }}</span>
            </v-tooltip>
        </v-toolbar-items>
    </v-app-bar>
</template>
<script>

    import { mapActions, mapGetters } from "vuex";
    import { l } from "../../localization/localization.js";


    export default {
        data() {
            return {
                curationLockBypassed: false,
            }
        },
        computed: {
            curationLockEngagedMessage: () => l.cms.curationLock.engagedMessage(),
            loggedInAsLabel: () => l.cms.general.loggedInAs(),
            userSettingsLabel: () => l.cms.userSettings.userSettings(),
            logoutButtonLabel: () => l.cms.general.logout(),
            ...mapGetters({
                title: "platform/getTitle",
                userName: "user/getUserName",
                hasSystemAccessRights: "user/hasSystemAccessRights",
                backendVersion: "platform/getBackendVersion",
                frontendVersion: "platform/getFrontendVersion",
                buildNumber: "platform/getBuildNumber"
            })
        },
        mounted() {

            this.$root.$on("curationLockBypassed", () => {
                this.curationLockBypassed = true;
            });
            this.$root.$on("curationLockOff", () => {
                this.curationLockBypassed = false;
            });
        },
        methods: {

            toggleSideBar() {
                this.$root.$emit("toggleSideBar");
            },

            ...mapActions({
                logout: "logout"
            }),

        }
    }
</script>
<style scoped>

    .curation-lock-bypassed {
        display: block;
        background: #d00;
        border-radius: 3px;
        padding: 2px 8px;
    }

    .versionBox {
        padding-left: 30px;
        line-height: 13px !important;
        opacity: 0.45;
    }

    .versionLabel {
        display: inline-block;
        width: 60px;
    }

</style>