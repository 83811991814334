<template>
    <div :class="'image-transform-container' + (field.required ? ' required' : '')">
        <!-- eslint-disable -->
        <div v-if="label" class="image-transform-label label">{{ label }}</div>
        <!-- eslint-enable -->
        <v-btn-toggle v-model="mode" exclusive mandatory color="primary" :class="mode === 'custom' ? 'hide-bottom-corners' : ''" @change="onInput">
            <v-tooltip bottom content-class="image-transform-tooltip elevation-3">
                <template #activator="{ on, attrs }">
                    <v-btn value="fill" v-bind="attrs" v-on="on">
                        <div class="transform-example example-fill">
                            <div class="example-content" />
                            <div class="example-container" />
                            <v-icon class="icon">
                                mdi-arrow-expand-all
                            </v-icon>
                        </div>
                        <span class="pl-2">
                            {{ l('Fill') }}
                        </span>
                    </v-btn>
                </template>
                <span>
                    {{ l('Image transform mode fill explanation') }}
                </span>
            </v-tooltip>
            <v-tooltip bottom content-class="image-transform-tooltip elevation-3">
                <template #activator="{ on, attrs }">
                    <v-btn value="contain" v-bind="attrs" v-on="on">
                        <div class="transform-example example-contain">
                            <div class="example-content" />
                            <div class="example-container" />
                            <v-icon class="icon">
                                mdi-arrow-left-right
                            </v-icon>
                        </div>
                        <span class="pl-2">
                            {{ l('Contain') }}
                        </span>
                    </v-btn>
                </template>
                <span>
                    {{ l('Image transform mode contain explanation') }}
                </span>
            </v-tooltip>
            <v-tooltip bottom content-class="image-transform-tooltip elevation-3">
                <template #activator="{ on, attrs }">
                    <v-btn value="cover" v-bind="attrs" v-on="on">
                        <div class="transform-example example-cover">
                            <div class="example-content" />
                            <div class="example-container" />
                            <v-icon class="icon">
                                mdi-arrow-up-down
                            </v-icon>
                        </div>
                        <span class="pl-2">
                            {{ l('Cover') }}
                        </span>
                    </v-btn>
                </template>
                <span>
                    {{ l('Image transform mode cover explanation') }}
                </span>
            </v-tooltip>
            <v-tooltip bottom content-class="image-transform-tooltip elevation-3">
                <template #activator="{ on, attrs }">
                    <v-btn value="custom" v-bind="attrs" v-on="on">
                        <v-icon>tune</v-icon>
                        <span class="pl-2">
                            {{ l('Custom') }}
                        </span>
                    </v-btn>
                </template>
                <span>
                    {{ l('Image transform mode custom explanation') }}
                </span>
            </v-tooltip>
        </v-btn-toggle>
        <v-expand-transition>
            <div v-if="mode === 'custom'" class="image-transform-settings">
                <v-text-field
                    v-model="x"
                    :label="l('Horizontal offset')"
                    hide-details="auto"
                    @blur="customNumberInput"
                />
                <v-text-field
                    v-model="y"
                    :label="l('Vertical offset')"
                    hide-details="auto"
                    @blur="customNumberInput"
                />
                <v-text-field
                    v-model="scale"
                    :label="l('Scale')"
                    hide-details="auto"
                    @blur="customNumberInput"
                />
                <v-text-field
                    v-model="rotation"
                    :label="l('Rotation')"
                    hide-details="auto"
                    @blur="customNumberInput"
                />
            </div>
        </v-expand-transition>
    </div>
</template>
<script>

    import { l } from "../../../utils/LocalizationUtils.js";


    export default {
        model: {
            prop: "propField",
            event: "input"
        },
        props: {
            propField: { type: Object, default: null }
        },
        data() {
            return {
                mode: "fill",
                x: 0,
                y: 0,
                scale: 1,
                rotation: 0
            }
        },
        computed: {
            field() {
                return this.propField;
            },
            label() {
                return this.field.label ? l(this.field.label) : null;
            }
        },
        created() {
            this.loadValues();
            this.formatCustomNumbers();
            this.onInput();
        },
        methods: {
            loadValues() {
                if (this.field.value) {
                    this.mode = this.field.value.mode ? this.field.value.mode : "fill";
                    this.x = this.field.value.x ? this.field.value.x : 0;
                    this.y = this.field.value.y ? this.field.value.y : 0;
                    this.scale = this.field.value.scale ? this.field.value.scale : 1;
                    this.rotation = this.field.value.rotation ? this.field.value.rotation : 0;
                }
            },
            customNumberInput() {
                this.formatCustomNumbers();
                this.onInput();
            },
            formatCustomNumbers() {
                this.x = this.toMinFixed(this.x, 2);
                this.y = this.toMinFixed(this.y, 2);
                this.scale = this.toMinFixed(this.scale, 2);
                this.rotation = this.toMinFixed(this.rotation, 1) + "°";
            },
            toMinFixed(input, decimalPoints) {
                let number = parseFloat(input);
                if (!number) {
                    number = 0;
                }
                return number.toFixed(Math.max(decimalPoints, (number.toString().split('.')[1] || []).length));
            },
            onInput() {
                this.field.value = {
                    mode: this.mode,
                    x: parseFloat(this.x),
                    y: parseFloat(this.y),
                    scale: parseFloat(this.scale),
                    rotation: parseFloat(this.rotation)
                };
            },
            l
        }
    }
</script>
<style scoped>

    .image-transform-container {
        width: fit-content;
        margin: 8px 0;
    }

    .image-transform-label {
        line-height: 20px;
        letter-spacing: normal;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.6);
    }

    .hide-bottom-corners {
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }

    .transform-example {
        display: inline-block;
        position: relative;
        width: 32px;
        height: 32px;
    }

    .transform-example.example-cover {
        width: 40px !important;
    }

    .transform-example .example-content {
        background: #999;
    }

    .transform-example.example-fill .example-content {
        position: absolute;
        left: 6px;
        top: 2px;
        width: 28px;
        height: 28px;
    }

    .transform-example.example-contain .example-content {
        position: absolute;
        left: 6px;
        top: 7px;
        width: 28px;
        height: 18px;
    }

    .transform-example.example-cover .example-content {
        position: absolute;
        left: 0px;
        top: 2px;
        width: 40px;
        height: 28px;
    }

    .transform-example .example-container {
        position: absolute;
        left: 6px;
        top: 2px;
        width: 28px;
        height: 28px;
        border: 2px solid #333 !important;
        border-radius: 2px;
    }

    .transform-example .icon {
        display: block;
        position: absolute;
        left: 6px;
        top: 2px;
        width: 28px;
        height: 28px;
    }

    .image-transform-settings {
        border-left: 1px solid rgba(0, 0, 0, 0.12);
        border-right: 1px solid rgba(0, 0, 0, 0.12);
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        padding: 10px;
    }

    .image-transform-tooltip {
        background: #fff;
        color: rgba(0, 0, 0, 0.6) !important;
        opacity: 1 !important;
        max-width: 280px;
        padding-top: 12px !important;
        padding-bottom: 12px !important;
    }

</style>