<template>
    <div>
        <WindowedForm v-model="form" />
    </div>
</template>
<script>

    // THIS COMPONENT IS NOT USED YET, BUT WILL LIKELY BE USED IN THE FUTURE

    import InformationPageService from "../../services/InformationPageService.js";
    import WindowedForm from "../globalUiElements/WindowedForm.vue";
    import { createForm } from "../../utils/FormUtils.js";
    import { l } from "../../utils/LocalizationUtils.js";


    export default {
        components: {
            WindowedForm,
        },
        props: {
            entry : { type: Object, default: null }
        },
        data() {
            return {
                form: createForm({

                    title: {
                        label: "Edit Settings of Information Page ${v.name}",
                        entry: this.entry
                    },

                    action: null, // TODO

                    fieldDefs: [
                        {
                            key: "_id",
                            type: "Hidden",
                            value: this.entry._id
                        },
                        {
                            uiElement: "Info",
                            label: "Info",
                            value: "This information page has no settings." // TODO
                        }
                    ],

                    submitLabel: "Save",

                    submitted: entry => {
                        this.$root.$emit("success", l("Changes to information page \"${v.name}\" saved.", entry));
                        this.$emit("editedEntry");
                    },

                    cancelled: () => {
                        this.$emit("cancel");
                    }
                })
            }
        }
    }
</script>