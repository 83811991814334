<template>
    <WindowedForm v-model="form" />
</template>
<script>

    import UserService from "../../services/UserService.js";
    import WindowedForm from "../globalUiElements/WindowedForm.vue";
    import { getCMSLocaleOptions, getAppLocaleOptions } from "../../locales/utils/localeUtils.js";
    import { createForm } from "../../utils/FormUtils.js";
    import { l } from "../../utils/LocalizationUtils.js";


    export default {
        components: {
            WindowedForm
        },
        data() {
            return {
                form: createForm({

                    title: "Create New User",

                    action: UserService.insertUser,

                    fieldDefs: [
                        {
                            key: "name",
                            type: "Text",
                            label: "Name",
                            required: true,
                        },
                        {
                            key: "email",
                            type: "Text",
                            label: "Email Address",
                            required: true,
                        },
                        {
                            key: "password",
                            type: "Password",
                            label: "Password",
                            required: true,
                        },
                        {
                            key: "language",
                            type: "Select",
                            label: "Interface language in CMS",
                            options: getCMSLocaleOptions(),
                            required: true,
                        },
                        {
                            key: "contentLocale",
                            type: "Select",
                            label: "Content language in CMS",
                            options: getAppLocaleOptions(),
                            required: true,
                        },
                        {
                            uiElement: "Header",
                            text: "Access Rights",
                        },
                        {
                            key: "exhibitions",
                            type: "Checkbox",
                            label: "Exhibitions",
                        },
                        {
                            key: "arContent",
                            type: "Checkbox",
                            label: "AR Content",
                        },
                        {
                            key: "informationDatabase",
                            type: "Checkbox",
                            label: "Information Database",
                        },
                        {
                            key: "analytics",
                            type: "Checkbox",
                            label: "Analytics",
                        },
                        {
                            key: "users",
                            type: "Checkbox",
                            label: "Users",
                        },
                        {
                            key: "system",
                            type: "Checkbox",
                            label: "System",
                        }
                    ],

                    submitLabel: "Create",

                    submitted: entry => {
                        this.$root.$emit("success", l("User \"${v.name}\" created.", entry));
                        this.$emit("createdEntry");
                    },

                    cancelled: () => {
                        this.$emit("cancel");
                    }
                })
            }
        }
    }
</script>