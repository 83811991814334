<template>
    <EncyclopediaHeader 
        :image="image"
        :name="name"
        :data-table="dataTable"
    />
</template>
<script>

    import EncyclopediaHeader from "./EncyclopediaHeader.vue";
    import { niceCoordinates } from "../../../utils/geographyUtils.js";
    import { generateCategories, generateDataTableRowFromSinglePage } from "../../../utils/headerPreviewUtils.js";
    import { l } from "../../../localization/localization.js";


    export default {
        components: {
            EncyclopediaHeader
        },
        props: {
            values: { type: Object, default: () => { } }
        },
        computed: {
            image() {
                return this.values.image;
            },
            name() {
                return this.values.name;
            },
            dataTable() {
                return [
                    {
                        label: l.app.pages.places.country(),
                        value: this.values.country
                    },
                    {
                        label: l.app.pages.places.coordinates(),
                        value: "<u>" + niceCoordinates(this.values.coordinates) + "</u>",
                    },
                    ...generateDataTableRowFromSinglePage(l.app.pages.places.parentPlace(), this.values.parent),
                    ...generateCategories(this.values.categories),
                ];
            },
        },
    }
</script>