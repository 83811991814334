<template>
    <div>
        <h2 class="title primary--text mb-5">
            {{ l("Minimal App Version") }}
        </h2>
        <InpageForm v-model="form" />
    </div>
</template>
<script>

    import { mapActions } from "vuex";
    import MinAppVersionService from "../../services/MinAppVersionService.js";
    import InpageForm from "../globalUiElements/InpageForm.vue";
    import { createForm } from "../../utils/FormUtils.js";
    import { l } from "../../utils/LocalizationUtils.js";

    export default {
        components: {
            InpageForm
        },
        props: {
            reloadTrigger: { type: Number, default: 0 },
        },
        data() {
            return {
                form: {}
            };
        },
        watch: {
            reloadTrigger() {
                this.update();
            }
        },
        mounted() {
            this.update();
        },
        methods: {
            update() {
                this.updateSectionTitle(this.l("Minimal App Version"));

                MinAppVersionService.get(content => {

                    const contentLines = content.trim().split(/\r?\n/);


                    this.form = createForm({

                        action: MinAppVersionService.update,

                        fieldDefs: [
                            {
                                key: "minAppVersion",
                                label: this.l("Version number"),
                                type: "Text",
                                value: contentLines[0].trim(),
                                required: true
                            },
                            {
                                uiElement: "Spacer"
                            },
                            {
                                uiElement: "Title",
                                text: "Update Links"
                            },
                            {
                                key: "editorAppLink",
                                label: this.l("Editor app link"),
                                type: "Text",
                                value: contentLines.length > 1 ? contentLines[1].trim() : "",
                                required: false
                            },
                            {
                                key: "androidAppLink",
                                label: this.l("Android app link"),
                                type: "Text",
                                value: contentLines.length > 2 ? contentLines[2].trim() : "",
                                required: false
                            },
                            {
                                key: "iOSAppLink",
                                label: this.l("iOS app link"),
                                type: "Text",
                                value: contentLines.length > 3 ? contentLines[3].trim() : "",
                                required: false
                            }
                        ],

                        submitPreprocessor: form => {
                            const minAppVersionField = form.fields.find(field => field.key === "minAppVersion");
                            if (minAppVersionField) {
                                const newVersion = minAppVersionField.value;
                                if (newVersion.trim() === "" || !newVersion.match(/^[0-9]*(\.[0-9]*)*$/)) {
                                    minAppVersionField.error = true;
                                    minAppVersionField.errorMessages = this.l("The version number may only consist of numbers seperated by dots.");
                                    return false;
                                }
                            }
                            return true;
                        },

                        submitLabel: "Save",

                        submitted: response => {
                            this.$root.$emit("success", this.l("Minimum app version updated to ${v}.", response));
                        }
                    });
                });
            },
            ...mapActions({
                updateSectionTitle: "platform/updateSectionTitle"
            }),
            l
        }
    }
</script>
