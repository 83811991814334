<template>
    <div class="defaultContentView">
        <InpageForm v-model="form" />
    </div>
</template>
<script>

    import { mapActions, mapGetters } from "vuex";
    import UserService from "../services/UserService.js";
    import InpageForm from "../components/globalUiElements/InpageForm.vue";
    import { getCMSLocaleOptions, getAppLocaleOptions } from "../locales/utils/localeUtils.js";
    import { createForm } from "../utils/FormUtils.js";
    import { l } from "../utils/LocalizationUtils.js";

    export default {
        components: {
            InpageForm
        },
        data() {
            return {
                form: {}
            };
        },
        computed: {
            ...mapGetters({
                user: "user/getUser"
            })
        },
        mounted() {

            this.updateSectionTitle(l("User Settings") + ": " + this.user.name);

            this.form = createForm({

                title: {
                    label: "User Settings for ${v.name}",
                    entry: this.user
                },

                action: UserService.updateMe,

                fieldDefs: [
                    {
                        key: "_id",
                        type: "Hidden",
                        value: this.user._id,
                    },
                    {
                        key: "name",
                        type: "Text",
                        label: "Name",
                        value: this.user.name,
                        required: true,
                    },
                    {
                        key: "email",
                        type: "Text",
                        label: "Email Address",
                        value: this.user.email,
                        required: true,
                    },
                    {
                        key: "password",
                        type: "Password",
                        label: "Change password",
                    },
                    {
                        key: "language",
                        type: "Select",
                        label: "Interface language in CMS",
                        value: this.user.language,
                        options: getCMSLocaleOptions(),
                        required: true,
                    },
                    {
                        key: "contentLocale",
                        type: "Select",
                        label: "Content language in CMS",
                        value: this.user.contentLocale,
                        options: getAppLocaleOptions(),
                        required: true,
                    },
                ],

                submitLabel: "Save",

                submitted: user => {
                    this.$root.$emit("success", l("Changes for user \"${v.name}\" saved.", user));
                    this.reloadUser();
                }
            });
        },
        methods: {
            ...mapActions({
                reloadUser: "user/reloadUser",
                updateSectionTitle: "platform/updateSectionTitle"
            })
        }
    }
</script>
