import axios from "axios";
import { triggerError } from "../utils/alerts.js";


const url = "/api/users/";
const userSettingsUrl = "/api/userSettings/";


class UserService {

    // Get
    static getUser(id, callback) {
        axios.get(`${url}${id}`).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }

    // Get all
    static getUsers(callback) {
        axios.get(url).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }

    // Post
    static insertUser(user, callback) {
        axios.post(url, user).then(response => {
            callback(response.data);
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.isJoi) {
                callback({ errors: error.response.data.details });
            } else {
                triggerError(error);
            }
        });
    }

    // Put
    static updateUser(user, callback) {
        axios.put(`${url}${user._id}`, user).then(response => {
            response.data._id = user._id;
            callback(response.data);
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.isJoi) {
                callback({ errors: error.response.data.details });
            } else {
                triggerError(error);
            }
        });
    }

    // Delete
    static deleteUser(userId, callback) {
        axios.delete(`${url}${userId}`).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }


    // Get the current user
    static getMe(userId, callback) {
        axios.get(`${userSettingsUrl}${userId}`).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }

    // Put UserSettings
    static updateMe(user, callback) {
        axios.put(`${userSettingsUrl}${user._id}`, user).then(response => {
            response.data._id = user._id;
            callback(response.data);
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.isJoi) {
                callback({ errors: error.response.data.details });
            } else {
                triggerError(error);
            }
        });
    }

}

export default UserService;