import store from "../store/index.js";


export function triggerError(message) {
    store.dispatch("alerts/triggerError", message);
}


export function triggerWarning(message) {
    store.dispatch("alerts/triggerWarning", message);
}


export function triggerSuccess(message) {
    store.dispatch("alerts/triggerSuccess", message);
}


export function triggerInfo(message) {
    store.dispatch("alerts/triggerInfo", message);
}