var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:'image-transform-container' + (_vm.field.required ? ' required' : '')},[(_vm.label)?_c('div',{staticClass:"image-transform-label label"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('v-btn-toggle',{class:_vm.mode === 'custom' ? 'hide-bottom-corners' : '',attrs:{"exclusive":"","mandatory":"","color":"primary"},on:{"change":_vm.onInput},model:{value:(_vm.mode),callback:function ($$v) {_vm.mode=$$v},expression:"mode"}},[_c('v-tooltip',{attrs:{"bottom":"","content-class":"image-transform-tooltip elevation-3"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"value":"fill"}},'v-btn',attrs,false),on),[_c('div',{staticClass:"transform-example example-fill"},[_c('div',{staticClass:"example-content"}),_c('div',{staticClass:"example-container"}),_c('v-icon',{staticClass:"icon"},[_vm._v(" mdi-arrow-expand-all ")])],1),_c('span',{staticClass:"pl-2"},[_vm._v(" "+_vm._s(_vm.l('Fill'))+" ")])])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.l('Image transform mode fill explanation'))+" ")])]),_c('v-tooltip',{attrs:{"bottom":"","content-class":"image-transform-tooltip elevation-3"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"value":"contain"}},'v-btn',attrs,false),on),[_c('div',{staticClass:"transform-example example-contain"},[_c('div',{staticClass:"example-content"}),_c('div',{staticClass:"example-container"}),_c('v-icon',{staticClass:"icon"},[_vm._v(" mdi-arrow-left-right ")])],1),_c('span',{staticClass:"pl-2"},[_vm._v(" "+_vm._s(_vm.l('Contain'))+" ")])])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.l('Image transform mode contain explanation'))+" ")])]),_c('v-tooltip',{attrs:{"bottom":"","content-class":"image-transform-tooltip elevation-3"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"value":"cover"}},'v-btn',attrs,false),on),[_c('div',{staticClass:"transform-example example-cover"},[_c('div',{staticClass:"example-content"}),_c('div',{staticClass:"example-container"}),_c('v-icon',{staticClass:"icon"},[_vm._v(" mdi-arrow-up-down ")])],1),_c('span',{staticClass:"pl-2"},[_vm._v(" "+_vm._s(_vm.l('Cover'))+" ")])])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.l('Image transform mode cover explanation'))+" ")])]),_c('v-tooltip',{attrs:{"bottom":"","content-class":"image-transform-tooltip elevation-3"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"value":"custom"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("tune")]),_c('span',{staticClass:"pl-2"},[_vm._v(" "+_vm._s(_vm.l('Custom'))+" ")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.l('Image transform mode custom explanation'))+" ")])])],1),_c('v-expand-transition',[(_vm.mode === 'custom')?_c('div',{staticClass:"image-transform-settings"},[_c('v-text-field',{attrs:{"label":_vm.l('Horizontal offset'),"hide-details":"auto"},on:{"blur":_vm.customNumberInput},model:{value:(_vm.x),callback:function ($$v) {_vm.x=$$v},expression:"x"}}),_c('v-text-field',{attrs:{"label":_vm.l('Vertical offset'),"hide-details":"auto"},on:{"blur":_vm.customNumberInput},model:{value:(_vm.y),callback:function ($$v) {_vm.y=$$v},expression:"y"}}),_c('v-text-field',{attrs:{"label":_vm.l('Scale'),"hide-details":"auto"},on:{"blur":_vm.customNumberInput},model:{value:(_vm.scale),callback:function ($$v) {_vm.scale=$$v},expression:"scale"}}),_c('v-text-field',{attrs:{"label":_vm.l('Rotation'),"hide-details":"auto"},on:{"blur":_vm.customNumberInput},model:{value:(_vm.rotation),callback:function ($$v) {_vm.rotation=$$v},expression:"rotation"}})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }