<template>
    <!-- eslint-disable -->
    <div v-if="values.text" class="paragraph" v-html="values.text" />
    <div v-else-if="!values.text" class="paragraph"><p>{{ l("Enter text...") }}</p></div>
    <!-- eslint-enable -->
</template>
<script>

    import { l } from "../../../utils/LocalizationUtils.js";


    export default {
        props: {
            values: { type: Object, default: () => { } }
        },
        methods: {
            l
        }
    }

</script>
<style scoped>

    .paragraph {
        position: relative;
        padding: 0 20px;
        font-size: 18px;
        min-height: 50px;
        color: #fff;
        background: #212121;
        white-space: pre-wrap;
    }

    .paragraph ::v-deep p {
        padding: 8px 0 !important;
        margin: 0;
    }

    .paragraph ::v-deep a {
        color: #fff !important;
        text-decoration: underline !important;
    }

    .paragraph ::v-deep strong {
        font-weight: 900;
    }

</style>