<template>
    <div>
        <h1 class="display-1 primary--text">
            <v-icon color="primary" class="titleIcon">
                filter
            </v-icon>
            {{ l("AR Content") }}
        </h1>
        <v-container fluid class="pb-5 px-0 mx-0">
            <v-row align="center" no-gutters>
                <v-col cols="4">
                    <v-text-field v-model="search" append-icon="mdi-magnify" :label="l('Search')" single-line clearable hide-details />
                </v-col>
                <v-spacer />
                <v-col class="text-right">
                    <AddButton
                        v-if="!usageLimitReached"
                        :label="l('Add AR Target')"
                        @click="add"
                    />
                    <v-alert v-if="usageLimitReached" type="error" dense>
                        {{ limitReachedWarning }}
                    </v-alert>
                </v-col>
            </v-row>
        </v-container>
        <v-data-table :headers="headers" :items="list" :sort-by="sortBy" :items-per-page="itemsPerPage" :footer-props="pagination" :loading="loading" :search="search" :custom-filter="arTargetSearch" :no-data-text="l('No entries.')">
            <template #item="row">
                <tr>
                    <td class="clickable fixed-row-height" style="width:76px" @click="editInteractions(row.item)">
                        <img :src="row.item.preview" class="preview-image">
                    </td>
                    <td class="clickable fixed-row-height truncate" @click="editInteractions(row.item)">
                        <span v-if="!row.item.linkedPreview">
                            {{ row.item.label }}
                        </span>
                        <div v-if="row.item.linkedPreview" class="art-piece-box">
                            <v-icon class="art-piece-icon">
                                {{ row.item.linkedPreview.previewIcon }}
                            </v-icon>
                            <div class="art-piece-info">
                                <div class="subtitle-2">
                                    {{ row.item.linkedPreview.optionTitle }}
                                </div>
                                <div class="body-2">
                                    {{ row.item.linkedPreview.optionDescription }}
                                </div>
                            </div>
                            <v-tooltip left>
                                <template #activator="{ on }">
                                    <v-btn class="mx-1 art-piece-button" text icon color="primary" :to="row.item.linkedLink" v-on="on">
                                        <v-icon>mdi-arrow-right-thick</v-icon>
                                    </v-btn>
                                </template>
                                <span class="body-2">{{ l("Go to art piece") }}</span>
                            </v-tooltip>
                        </div>
                    </td>
                    <td class="clickable fixed-row-height nobreak" @click="editInteractions(row.item)">
                        {{ row.item.numOfInteractions }}
                    </td>
                    <td class="text-right nobreak" style="width:176px">
                        <v-tooltip left>
                            <template #activator="{ on }">
                                <v-btn text icon color="primary" v-on="on" @click="edit(row.item)">
                                    <v-icon>settings</v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">{{ l("Edit AR Target") }}</span>
                        </v-tooltip>
                        <v-tooltip left>
                            <template #activator="{ on }">
                                <v-btn text icon color="primary" v-on="on" @click="openVisibilityManager(row.item)">
                                    <v-icon v-if="row.item.useLinks.length == 0">
                                        mdi-eye-off-outline
                                    </v-icon>
                                    <v-icon v-if="row.item.useLinks.length > 0">
                                        mdi-eye-settings
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span v-if="row.item.useLinks.length == 0" class="body-2">
                                {{ l("Manage Visibility") }}
                            </span>
                            <span v-if="row.item.useLinks.length > 0" class="subtitle-2">
                                {{ l("Visibility:") }}
                            </span>
                            <ul v-if="Array.isArray(row.item.useLinks)">
                                <li v-for="(useLink, index) in row.item.useLinks" :key="index">
                                    <span v-if="useLink.exhibition">
                                        {{ useLink.exhibition.title }} ({{ l("Exhibition") }})
                                    </span>
                                </li>
                            </ul>
                        </v-tooltip>
                        <v-tooltip v-if="hasSystemAccessRights" left>
                            <template #activator="{ on }">
                                <v-btn text icon color="primary" v-on="on" @click="openInformation(row.item)">
                                    <v-icon>mdi-information-outline</v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">{{ l("Information") }}</span>
                        </v-tooltip>
                        <v-tooltip left>
                            <template #activator="{ on }">
                                <v-btn text icon color="primary" v-on="on" @click="deleteEntry(row.item)">
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">{{ l("Delete") }}</span>
                        </v-tooltip>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <ARTargetVisibilityManager v-if="showVisibilityManager" :ar-target="selectedARTarget" @close="closeVisibilityManager" />
        <ARTargetInformationBox v-if="showInformation" :ar-target="selectedARTarget" @close="closeInformation" />
        <v-container v-if="showUsageBar">
            <v-row align="center" justify="end" no-gutters>
                <v-spacer />
                <UsageBar metric-name="arAnchors" mini />
            </v-row>
        </v-container>
    </div>
</template>
<script>

    import { mapGetters } from "vuex";
    import AddButton from "../globalUiElements/AddButton.vue";
    import UsageBar from "../../usage/components/UsageBar.vue";
    import ARTargetService from "../../services/ARTargetService.js";
    import ExhibitionService from "../../services/ExhibitionService.js";
    import InteractionService from "../../services/InteractionService.js";
    import ARTargetVisibilityManager from "./ARTargetVisibilityManager.vue";
    import ARTargetInformationBox from "./ARTargetInformationBox.vue";
    import ListUtils from "../../utils/ListUtils.js";
    import { createPageItem } from "../../utils/referenceFieldUtils.js";
    import { updateLookups } from "../../utils/referenceUtils.js";
    import { getPage } from "../../pages/utils/pageUtils.js";
    import { hasLimit, isLimitReached } from "../../usage/utils/usageUtils.js";
    import { l } from "../../utils/LocalizationUtils.js";


    export default ListUtils.setupList({
        components: {
            ARTargetVisibilityManager,
            ARTargetInformationBox,
            AddButton,
            UsageBar,
        },
        headers: [
            { text: "", sortable: false },
            { text: "Target", value: "label" },
            { text: "Interactions", value: "numOfInteractions" },
            { text: "", sortable: false },
        ],
        data() {
            return {
                showVisibilityManager: false,
                showInformation: false,
                selectedARTarget: null
            };
        },
        computed: {
            showUsageBar: () => hasLimit("arAnchors"),
            usageLimitReached: () => isLimitReached("arAnchors"),
            limitReachedWarning: () => l("Limit of AR targets reached"),
            ...mapGetters({
                hasSystemAccessRights: "user/hasSystemAccessRights"
            })
        },
        sortBy: "label",
        async update() {
            this.showLoading();

            await updateLookups(["pages", "usage"]);

            const exhibitions = await ExhibitionService.getExhibitions();
            const exhibitionLookup = {};
            exhibitions.forEach(exhibition => {
                exhibitionLookup[exhibition._id] = exhibition;
            });

            const arTargets = await ARTargetService.getARTargets();
            arTargets.forEach(entry => {

                entry.image = entry.overlay ? entry.overlay : entry.target;
                entry.preview = "/assets/" + entry.image + ".jpg?quality=veryLow";
                entry.numOfInteractions = 0;

                if (entry.linkedContent) {

                    const page = getPage(entry.linkedContent);

                    if (page) {
                        entry.linkedPreview = createPageItem(page);
                        entry.label = entry.linkedPreview.optionTitle;
                        if(page.informationPageType === "topic") {
                            entry.linkedLink = "/topics/" + page._id;
                        } else {
                            entry.linkedLink = "/encyclopedia/" + page.informationPageType + "/" + page._id;
                        }

                    } else {
                        entry.label = l("Cannot find linked content!");
                    }
                } else {
                    entry.label = entry.title;
                }

                entry.useLinks = [];
                if(Array.isArray(entry.uses)) {
                    entry.uses.forEach(use => {
                        if(exhibitionLookup[use]) {
                            entry.useLinks.push({
                                exhibition: exhibitionLookup[use],
                            });
                        }
                    });
                }
            });

            this.list = arTargets;
            InteractionService.getAllInteractions(interactions => {
                interactions.forEach(interaction => {
                    const arTarget = this.list.find(arTarget => arTarget._id === interaction.arTargetId);
                    if (arTarget) {
                        arTarget.numOfInteractions++;
                    }
                });
            });

            this.hideLoading();
        },
        deleteSettings: {
            promptTitle: "Delete Art Piece?",
            promptQuestion: "Are you sure you want to delete \"${v.title}\"?",
            action: ARTargetService.deleteARTarget,
            success: "Art piece \"${v.title}\" deleted."
        },
        methods: {

            editInteractions(entry) {
                this.$emit("editInteractions", entry);
            },

            edit(entry) {
                this.$emit("edit", entry);
            },

            openVisibilityManager(entry) {
                this.showVisibilityManager = true;
                this.selectedARTarget = entry;
            },

            closeVisibilityManager(hasChanges) {
                this.showVisibilityManager = false;
                this.selectedARTarget = null;
                if(hasChanges) {
                    this.update();
                }
            },

            openInformation(entry) {
                this.showInformation = true;
                this.selectedARTarget = entry;
            },
            
            closeInformation() {
                this.showInformation = false;
                this.selectedARTarget = null;
            },

            arTargetSearch(value, search, item) {
                return Object.values(item).some(v => v && v.toString().toLocaleLowerCase().includes(search.toLocaleLowerCase()))
                    || (item.linkedPreview && Object.values(item.linkedPreview).some(v => v && v.toString().toLocaleLowerCase().includes(search.toLocaleLowerCase())));
            },

            l
        }
    });
</script>
<style scoped>

    .clickable {
        cursor: pointer;
    }

    .preview-image {
        display: block;
        max-width: 48px;
        max-height: 48px;
        margin: 0 auto;
    }

    .fixed-row-height {
        height: 60px !important;
    }

    .art-piece-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 48px;
        max-width: 400px;
        border-radius: 3px;
        background: #e0e0e0;
    }

    .art-piece-icon {
        width: 48px;
        flex-grow: 0;
        flex-shrink: 0;
    }

    .art-piece-info {
        flex-grow: 1;
        overflow: hidden;
    }

    .art-piece-info div {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .art-piece-button {
        flex-grow: 0;
        flex-shrink: 0;
    }

    .nobreak {
        white-space: nowrap;
    }

</style>