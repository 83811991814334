import { createPageLookup, createPageTypeLookup } from "../../pages/utils/pageUtils.js";


export default {


    namespaced: true,


    state: {
        pageLookup: {},
        pageTypeLookup: {},
    },


    mutations: {

        setPageLookup(state, pageLookup) {
            state.pageLookup = pageLookup;
        },

        setPageTypeLookup(state, pageTypeLookup) {
            state.pageTypeLookup = pageTypeLookup;
        },

    },


    actions: {

        update({ commit }) {
            return new Promise(async (resolve, reject) => {
                try {

                    const pageLookup = await createPageLookup();
                    commit("setPageLookup", pageLookup);

                    const pageTypeLookup = await createPageTypeLookup();
                    commit("setPageTypeLookup", pageTypeLookup);

                    resolve();
                } catch (error) {
                    reject(`Could not update page lookup: ${error}`);
                }
            });
        },

    },


    getters: {

        pageExists(state) {
            return (pageId) => {
                return state.pageLookup && state.pageLookup[pageId];
            }
        },


        getPage(state) {
            return (pageId) => {
                if (state.pageLookup && state.pageLookup[pageId]) {
                    return state.pageLookup[pageId];
                } else {
                    return null;
                }
            }
        },

        getAllPages(state) {
            if (state.pageLookup) {
                return Object.values(state.pageLookup);
            } else {
                return [];
            }
        },

        getPageType(state) {
            return (pageTypeId, pageTypeVersion) => {
                if (state.pageTypeLookup && state.pageTypeLookup[pageTypeId] && state.pageTypeLookup[pageTypeId][pageTypeVersion]) {
                    return state.pageTypeLookup[pageTypeId][pageTypeVersion];
                } else {
                    return null;
                }
            }
        },

    },

}