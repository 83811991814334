<template>
    <div>
        <v-data-table hide-default-headers hide-default-footer :headers="headers" :items="list" :sort-by="sortBy" :items-per-page="itemsPerPage" :loading="loading" :no-data-text="l('No entries.')">
            <template #item="row">
                <tr>
                    <td class="clickable" @click="edit(row.item)">
                        {{ row.item.label }}
                    </td>
                    <td class="text-right">
                        <ListRowButton
                            :label="deleteButtonLabel"
                            icon="mdi-delete"
                            @click="deleteEntry(row.item)"
                        />
                    </td>
                </tr>
            </template>
        </v-data-table>
        <v-layout justify-center class="mt-5 mb-5">
            <v-btn color="primary" class="pl-3" @click="add">
                <v-icon class="mr-2">
                    add
                </v-icon>
                {{ addButtonLabel }}
            </v-btn>
        </v-layout>
    </div>
</template>
<script>

    import ListRowButton from "../../components/globalUiElements/ListRowButton.vue";
    import { mapActions } from "vuex";
    import * as tagService from "../services/tagService.js";
    import ListUtils from "../../utils/ListUtils.js";
    import { getLabel } from "../utils/tagUtils.js";
    import { l } from "../../localization/localization.js";


    export default ListUtils.setupList({
        components: {
            ListRowButton
        },
        headers: [
            { text: () => l.cms.tags.name(), value: "label" },
            { text: "", sortable: false }
        ],
        sortBy: "label",
        async update() {
            this.updateSectionTitle(l.cms.tags.pageTitle());
            this.showLoading();
            this.list = await tagService.getAll();
            this.list.forEach(tag => {
                tag.label = getLabel(tag);
            });
            this.hideLoading();
        },
        deleteSettings : {
            promptTitle: () => l.cms.tags.deletePromptTitle(),
            promptQuestion: entry => l.cms.tags.deletePromptQuestion(getLabel(entry)),
            action: tagService.remove,
            success: entry => l.cms.tags.deletedMessage(getLabel(entry)),
        },
        itemsPerPage: -1,
        computed: {
            addButtonLabel: () => l.cms.tags.addButtonLabel(),
            deleteButtonLabel: () => l.cms.tags.deleteButtonLabel(),
        },
        methods: {
            ...mapActions({
                updateSectionTitle: "platform/updateSectionTitle"
            }),
        }
    });
</script>
<style scoped>

    .clickable {
        cursor: pointer;
    }

</style>