<template>
    <div :class="'linked-art-piece-input-container pa-3 my-4' + (field.required ? ' required' : '')">
        <v-subheader v-if="(!field.error && !field.inSingleFieldArrayEntry)" class="manual-label label">
            {{ label }}
        </v-subheader>
        <v-subheader v-if="(field.error && !field.inSingleFieldArrayEntry)" style="color:red" class="manual-label label">
            {{ label }}
        </v-subheader>
        <p v-if="field.errorMessages && field.errorMessages.length > 0" style="color:red; margin: 8px 0 0 0; font-size:smaller;">
            {{ field.errorMessages[0] }}
        </p>
        <v-btn-toggle v-model="linkType" exclusive mandatory color="primary" :class="linkType !== 'none' ? 'hide-bottom-corners' : ''" @change="onInput">
            <v-btn value="none" class="link-type-button">
                <v-icon>
                    mdi-image-off-outline
                </v-icon>
                <span class="pl-2">
                    {{ l('Not linked') }}
                </span>
            </v-btn>
            <v-btn value="artPiece" class="link-type-button">
                <v-icon>
                    mdi-image-frame
                </v-icon>
                <span class="pl-2">
                    {{ l('Art piece') }}
                </span>
            </v-btn>
            <v-btn value="infoPage" class="link-type-button">
                <v-icon>
                    mdi-newspaper-variant-outline
                </v-icon>
                <span class="pl-2">
                    {{ l('Encyclopedia page') }}
                </span>
            </v-btn>
        </v-btn-toggle>
        <v-expand-transition>
            <div v-if="linkType !== 'none'" class="linked-content-settings">
                <ReferenceField
                    v-if="linkType === 'artPiece'"
                    key="artPiece"
                    v-model="artPieceReferenceField"
                />
                <div v-if="linkType === 'artPiece' && !artPieceReferenceField.value" class="px-3">
                    {{ l("Has the art piece not yet been added to the database?") }}
                    <router-link to="/encyclopedia/artPiece/1/new">
                        {{ l("Add new art piece.") }}
                    </router-link>
                </div>
                <ReferenceField
                    v-if="linkType === 'infoPage'"
                    key="infoPage"
                    v-model="infoPageReferenceField"
                />
            </div>
        </v-expand-transition>
    </div>
</template>
<script>

    import InformationPageService from '../../../services/InformationPageService.js';
    import { l } from "../../../utils/LocalizationUtils.js";
    import ReferenceField from "./ReferenceField.vue";


    export default {
        components: {
            ReferenceField
        },
        model: {
            prop: "propField",
            event: "input"
        },
        props: {
            propField: { type: Object, default: null }
        },
        data() {
            return {
                linkType: "none",
                artPieceReferenceField: {
                    type: "Reference",
                    value: null,
                    referenceOf: "informationPage",
                    pageTypes: [ "artPiece" ],
                    error: false,
                    errorMessages: []
                },
                infoPageReferenceField: {
                    type: "Reference",
                    value: null,
                    referenceOf: "informationPage",
                    pageTypes: [ "encyclopedia", "topic"],
                    error: false,
                    errorMessages: []
                }
            }
        },
        computed: {
            field() {
                return this.propField;
            },
            label() {
                return this.field.label ? l(this.field.label) : null;
            }
        },
        watch: {
            field: {
                async handler() {
                    if (this.field.value) {
                        const infoPage = await InformationPageService.getInformationPage(this.field.value);
                        if(infoPage) {
                            if(infoPage.informationPageTypeId === 'artPiece') {
                                this.linkType = "artPiece";
                                this.artPieceReferenceField.value = this.field.value;
                            } else {
                                this.linkType = "infoPage";
                                this.infoPageReferenceField.value = this.field.value;
                            }
                        } else {
                            this.linkType = "none";
                        }
                       
                    } else {
                        this.linkType = "none";
                    }
                },
                immediate: true
            },
            artPieceReferenceField: {
                handler() {
                    this.onInput();
                },
                deep: true
            },
            infoPageReferenceField: {
                handler() {
                    this.onInput();
                },
                deep: true
            }
        },
        methods: {
            onInput() {
                if (this.linkType === "artPiece" && this.artPieceReferenceField && this.artPieceReferenceField.value) {
                    this.field.value = this.artPieceReferenceField.value;

                } else if (this.linkType === "infoPage" && this.infoPageReferenceField && this.infoPageReferenceField.value) {
                    this.field.value = this.infoPageReferenceField.value;

                } else {
                    this.field.value = null;
                }
            },
            l
        }
    }
</script>
<style scoped>

    .linked-art-piece-input-container {
        background: #f3f3f3;
        max-width: 800px;
    }

    .manual-label {
        font-size: 16px;
        font-weight: 400;
        height: 18px;
        padding: 0;
        margin: 0 0 12px;
    }

    .link-type-button {
        height: 48px !important;
    }

    .hide-bottom-corners .link-type-button {
        border-bottom: 0 !important;
        height: 47px !important;
    }

    .hide-bottom-corners {
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }

    .linked-content-settings {
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        padding: 10px;
        background: #fff;
    }

</style>