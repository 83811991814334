export function createVisitTable(visitList, labelGenerator) {
    const table = [];
    if (visitList && typeof visitList === "object") {
        let sumOfVisits = 0;
        for (const visits of Object.values(visitList)) {
            sumOfVisits += visits;
        }
        for (const [key, visits] of Object.entries(visitList)) {
            table.push({
                label: labelGenerator(key),
                visits,
                share: getShare(visits, sumOfVisits),
            });
        }
        table.sort((a, b) => a.visits < b.visits);
    }
    return table;
}


export function getShare(value, total) {
    if (total === 0) {
        return 0;
    }
    return value / total;
}


export function toISODateString(date) {
    if (isValidDate(date)) {
        return date.toISOString().substr(0, 10);
    }
    return "";
}


export function isValidDate(date) {
    return date && date instanceof Date && !isNaN(date);
}


export function getTodayAt2359() {
    return setTime2359(new Date());
}


export function getDateAMonthAgoAt0000() {
    const aMonthAgo = new Date();
    aMonthAgo.setMonth(new Date().getMonth() - 1);
    aMonthAgo.setDate(new Date().getDate() + 1);
    return setTime0000(aMonthAgo);
}


export function setTime0000(date) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    return date;
}


export function setTime2359(date) {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    return date;
}