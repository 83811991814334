import { createTagLookup } from "../utils/tagUtils.js";


export default {


    namespaced: true,


    state: {
        tagLookup: {},
    },


    mutations: {

        setTagLookup(state, tagLookup) {
            state.tagLookup = tagLookup;
        },

    },


    actions: {

        update({ commit }) {
            return new Promise(async (resolve, reject) => {
                try {

                    const tagLookup = await createTagLookup();
                    commit("setTagLookup", tagLookup);

                    resolve();
                } catch (error) {
                    reject(`Could not update tag lookup: ${error}`);
                }
            });
        },

    },


    getters: {

        tagExists(state) {
            return (tagId) => {
                return state.tagLookup && state.tagLookup[tagId];
            }
        },

        getTag(state) {
            return (tagId) => {
                if (state.tagLookup && state.tagLookup[tagId]) {
                    return state.tagLookup[tagId];
                } else {
                    return null;
                }
            }
        },

        getAllTags(state) {
            if (state.tagLookup) {
                return Object.values(state.tagLookup);
            } else {
                return [];
            }
        },

    },

}