<template>
    <div :class="{ 'pa-3': label, 'my-4': label, referenceInputBox: label, referenceInputBoxContained: !label, required: label && field.required }">
        <v-subheader v-if="label && !field.error && !field.inSingleFieldArrayEntry" class="manualLabel label">
            {{ label }}
        </v-subheader>
        <v-subheader v-if="label && field.error && !field.inSingleFieldArrayEntry" style="color:red" class="manualLabel label">
            {{ label }}
        </v-subheader>
        <p v-if="field.errorMessages && field.errorMessages.length > 0" style="color:red; margin: 8px 0 0 0; font-size:smaller;">
            {{ field.errorMessages[0] }}
        </p>
        <v-autocomplete
            v-model="referenceValue"
            :items="items"
            :filter="filter"
            :error="field.error"
            :error-messages="field.errorMessages"
            :loading="loading"
            item-value="_id"
            hide-details="auto"
            clearable
            :solo="!label"
            :flat="!label"
            :class="{ referenceSelectionAutocomplete: true, hasValue: referenceValue }"
        >
            <template #selection="data">
                <div class="selectionBox">
                    <div class="selectionImage">
                        <v-icon v-if="data.item.showPreview == 'icon'" class="previewIcon">
                            {{ data.item.previewIcon }}
                        </v-icon>
                        <img v-if="data.item.showPreview == 'image'" :src="data.item.previewImage" class="previewImage">
                    </div>
                    <div class="selectionContent mt-1 mb-1 mr-1">
                        <v-list-item-title> {{ data.item.optionTitle }}</v-list-item-title>
                        <v-list-item-subtitle v-if="data.item.optionDescription" class="selectionDescription">
                            {{ data.item.optionDescription }}
                        </v-list-item-subtitle>
                    </div>
                </div>
            </template>
            <template #item="data">
                <v-list-item-action class="text-center selectionItemImage">
                    <v-icon v-if="data.item.showPreview == 'icon'" class="previewIcon">
                        {{ data.item.previewIcon }}
                    </v-icon>
                    <img v-if="data.item.showPreview == 'image'" :src="data.item.previewImage" class="previewImage">
                </v-list-item-action>
                <v-list-item-content class="selectionItemContent">
                    <v-list-item-title> {{ data.item.optionTitle }}</v-list-item-title>
                    <v-list-item-subtitle v-if="data.item.optionDescription">
                        {{ data.item.optionDescription }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </template>
        </v-autocomplete>
        <div v-if="field.allowCustomLabel" class="customLabelBox">
            <v-icon>
                mdi-label-outline
            </v-icon>
            <div v-if="defaultLabelDisplay && !customLabelField" class="text-body-2 defaultLabelDisplay">
                {{ defaultLabelDisplay }}
            </div>
            <div v-if="customLabelField" class="customLabelField">
                <InputField v-model="customLabelField" />
            </div>
            <v-tooltip v-if="!customLabelField" right>
                <template #activator="{ on }">
                    <v-btn icon v-on="on" @click="openCustomLabelField">
                        <v-icon>
                            mdi-pencil
                        </v-icon>
                    </v-btn>
                </template>
                <span class="body-2">{{ l("Edit label") }}</span>
            </v-tooltip>
            <v-tooltip v-if="customLabelField" right>
                <template #activator="{ on }">
                    <v-btn icon class="useDefaultLabelButton" v-on="on" @click="closeCustomLabelField">
                        <v-icon>
                            mdi-pencil-off
                        </v-icon>
                    </v-btn>
                </template>
                <span class="body-2">{{ l("Use default label") }}</span>
            </v-tooltip>
        </div>
        <div v-if="field.allowCustomImage" class="customImageBox">
            <v-icon>
                mdi-image
            </v-icon>
            <img v-if="!customImageField && defaultImageDisplay" :src="defaultImageDisplay" class="defaultImageDisplay">
            <div v-if="customImageField" class="customImageField">
                <InputField v-model="customImageField" />
            </div>
            <v-tooltip v-if="!customImageField" right>
                <template #activator="{ on }">
                    <v-btn icon v-on="on" @click="openCustomImageField">
                        <v-icon>
                            mdi-pencil
                        </v-icon>
                    </v-btn>
                </template>
                <span class="body-2">{{ l("Edit image") }}</span>
            </v-tooltip>
            <v-tooltip v-if="customImageField" right>
                <template #activator="{ on }">
                    <v-btn icon v-on="on" @click="closeCustomImageField">
                        <v-icon>
                            mdi-pencil-off
                        </v-icon>
                    </v-btn>
                </template>
                <span class="body-2">{{ l("Use default image") }}</span>
            </v-tooltip>
        </div>
    </div>
</template>
<script>

    import { getItemsForReferenceField } from "../../../utils/referenceFieldUtils.js";
    import { setupField } from "../../../utils/FormUtils.js";
    import { inContentLocale } from "../../../locales/utils/localeUtils.js";
    import { l, localize } from "../../../utils/LocalizationUtils.js";


    export default {
        model: {
            prop: "propField",
            event: "input"
        },
        props: {
            propField: { type: Object, default: null }
        },
        data() {
            return {
                referenceValue: null,
                customLabelField: null,
                customImageField: null,
                items: [],
                loading: false
            }
        },
        computed: {
            field() {
                return this.propField;
            },
            label() {
                return this.field.label ? l(this.field.label) : null;
            },
            defaultLabelDisplay() {
                if(this.referenceValue) {
                    const item = this.items.find(item => item._id === this.referenceValue);
                    if(item) {
                        return localize(inContentLocale(), item.defaultLinkLabel);
                    }
                }
                return "";
            },
            defaultImageDisplay() {
                if(this.referenceValue) {
                    const item = this.items.find(item => item._id === this.referenceValue);
                    if(item && item.defaultLinkImage) {
                        return "/assets/" + item.defaultLinkImage + ".jpg?quality=veryLow&update=" + item.defaultLinkImage.substring(2, 8);
                    }
                }
                return null;
            }
        },
        watch: {
            referenceValue() {
                this.setFieldValue();
            },
            customLabelField: {
                handler(form) {
                    this.setFieldValue();
                },
                deep: true
            },
            customImageField: {
                handler(form) {
                    this.setFieldValue();
                },
                deep: true
            },
        },
        beforeCreate() { // Delayed InputField import, to prevent recursion of imports
            this.$options.components.InputField = require('./InputField.vue').default;
        },
        mounted() {
            if(this.field.value && typeof this.field.value === "object") {
                this.referenceValue = this.field.value.reference ? this.field.value.reference : null;
                if(this.field.value.label) {
                    this.setCustomLabelField(this.field.value.label);
                }
                if(this.field.value.image) {
                    this.setCustomImageField(this.field.value.image);
                }
            } else {
                this.referenceValue = this.field.value;
            }
            this.loadItems();
        },
        methods: {
            async loadItems() {
                this.loading = true;
                this.items = await getItemsForReferenceField(this.field);
                this.loading = false;
            },
            setFieldValue() {
                if(this.field.allowCustomLabel || this.field.allowCustomImage) {
                    this.field.value = {
                        reference: this.referenceValue,
                        label: this.customLabelField ? this.customLabelField.getValue() : null,
                        image: this.customImageField ? this.customImageField.getValue() : null
                    };
                } else {
                    this.field.value = this.referenceValue;
                }
            },
            filter(item, queryText) {
                if (item.optionDescription) {
                    return (item.optionTitle.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1) ||
                        (item.optionDescription.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
                } else {
                    return (item.optionTitle.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
                }
            },
            openCustomLabelField() {
                const item = this.items.find(item => item._id === this.referenceValue);
                this.setCustomLabelField(item ? item.defaultLinkLabel : null);
            },
            setCustomLabelField(value) {
                this.customLabelField = setupField({
                    type: "Text",
                    localized: true,
                }, value);
            },
            closeCustomLabelField() {
                this.customLabelField = null;
                this.setFieldValue();
            },
            openCustomImageField() {
                const item = this.items.find(item => item._id === this.referenceValue);
                this.setCustomImageField(item ? item.defaultLinkImage : null);
            },
            setCustomImageField(value) {
                this.customImageField = setupField({
                    type: "Reference",
                    referenceOf: "media",
                    referenceType: "image",
                }, value);
            },
            closeCustomImageField() {
                this.customImageField = null;
                this.setFieldValue();
            },

            l
        }
    }
</script>
<style scoped>

    .referenceInputBox {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
        background: #f3f3f3;
        padding: 12px;
    }

    .referenceInputBoxContained {
        margin-top: 3px !important;
        margin-bottom: 3px !important;
    }

    .manualLabel {
        font-size: 16px;
        font-weight: 400;
        height: 18px;
        padding: 0;
        margin: 0 0 8px;
    }

    .referenceSelectionAutocomplete {
        background: #fff;
        height: 60px;
        overflow: hidden;
        padding: 0px;
    }

    .referenceSelectionAutocomplete.v-select--is-menu-active.hasValue {
        height: 92px !important;
    }

    .referenceSelectionAutocomplete ::v-deep .v-input__append-inner {
        align-self: flex-start;
        margin-top: 18px;
    }

    .referenceSelectionAutocomplete ::v-deep .v-select__selections {
        min-height: 60px;
        flex-direction: column;
    }

    .previewIcon {
        margin: 12px auto !important;
    }

    .previewImage {
        width: 48px;
        height: 48px;
        object-fit: contain;
        margin: 0 auto;
    }

    .selectionBox {
        display: flex;
        width: 100% !important;
    }

    .selectionImage {
        height: 60px !important;
        width: 64px !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-right: 4px;
        text-align: center;
    }

    .selectionContent {
        height: 52px !important;
        padding-top: 8px !important;
        padding-bottom: 8px !important;
        padding-left: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .selectionDescription {
        color: rgba(0, 0, 0, 0.6);
    }

    .selectionItemImage {
        width: 48px !important;
        height: 48px !important;
        margin: 2px 8px 2px 0!important;
    }

    .selectionItemContent {
        height: 52px;
        padding-top: 8px !important;
        padding-bottom: 8px !important;
        padding-left: 4px;
    }

    .customLabelBox {
        display: flex;
        margin: 8px 0 0;
        align-items: center;
        justify-items: flex-start;
        flex-wrap: nowrap;
    }

    .defaultLabelDisplay {
        padding-left: 8px;
        color: rgba(0, 0, 0, 0.6);
    }

    .customLabelField,
    .customImageField {
        padding-left: 8px;
        flex-grow: 1;
    }

    .useDefaultLabelButton {
        align-self: flex-end;
    }

    .customImageBox {
        display: flex;
        margin: 4px 0 0;
        align-items: center;
        justify-items: flex-start;
        flex-wrap: nowrap;
    }

    .defaultImageDisplay {
        margin-left: 8px;
        width: 32px;
        height: 32px;
        object-fit: contain;
    }

</style>