<template>
    <v-app>
        <HeaderBar />
        <SideBar />
        <v-main v-if="isLoggedIn">
            <router-view />
        </v-main>
        <Lightbox />
        <Alerts />
        <ConfirmBox />
        <SavePromptBox />
        <WaitingAnimation />
        <CurationLock />
        <Login />
    </v-app>
</template>
<script>
    import { mapGetters } from "vuex";
    import HeaderBar from "./components/navigation/HeaderBar.vue";
    import SideBar from "./components/navigation/SideBar.vue";
    import Lightbox from "./lightbox/components/Lightbox.vue";
    import Alerts from "./components/globalUiElements/Alerts.vue";
    import ConfirmBox from "./components/globalUiElements/ConfirmBox.vue";
    import SavePromptBox from "./components/globalUiElements/SavePromptBox.vue";
    import WaitingAnimation from "./components/globalUiElements/WaitingAnimation.vue";
    import CurationLock from "./components/curationLock/CurationLock.vue";
    import Login from "./components/authentication/Login.vue";
    

    export default {
        name: "App",
        components: {
            HeaderBar,
            SideBar,
            Lightbox,
            Alerts,
            ConfirmBox,
            SavePromptBox,
            WaitingAnimation,
            CurationLock,
            Login
        },
        computed: {
            ...mapGetters({
                isLoggedIn: "isLoggedIn"
            })
        }
    }
</script>
<style>

    .defaultContentView {
        margin-left: 50px;
        margin-right: max(calc(100vw - 1600px), 50px);
        margin-top: 50px;
        margin-bottom: 50px;
        min-height: calc(100% - 100px); /* 2 * 50px margin */
    }

    .titleIcon {
        font-size: 32px !important;
        padding-bottom: 6px !important;
        padding-right: 10px !important;
    }

    .required label::after,
    .required .label::after {
        color: #e00 !important;
        font-weight: bold;
        content: "*";
        padding-left: 0.2em;
    }

</style>
