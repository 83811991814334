<template>
    <WindowedForm v-model="form" />
</template>
<script>

    import InteractionsService from "../../services/InteractionService.js";
    import WindowedForm from "../globalUiElements/WindowedForm.vue";
    import { createParameterFields } from "../../utils/ParameterUtils.js";
    import { createForm } from "../../utils/FormUtils.js";
    import { l } from "../../utils/LocalizationUtils.js";


    export default {
        components: {
            WindowedForm
        },
        props: {
            entry: { type: Object, default: null }
        },
        data() {
            return {
                form: {}
            }
        },
        mounted() {
            this.form = createForm({

                title: {
                    label: "Edit Interaction ${v}",
                    entry: this.entry.name
                },

                action: InteractionsService.updateInteraction,

                fieldDefs: [
                    {
                        key: "_id",
                        type: "Hidden",
                        value: this.entry._id
                    },
                    {
                        key: "arTargetId",
                        type: "Hidden",
                        value: this.entry.arTargetId
                    },
                    {
                        key: "interactionModuleId",
                        type: "Hidden",
                        value: this.entry.interactionModuleId
                    },
                    {
                        key: "interactionModuleVersion",
                        type: "Hidden",
                        value: this.entry.interactionModuleVersion || 0
                    },
                    {
                        key: "name",
                        type: "Text",
                        label: "Name in CMS (not shown in the app)",
                        value: this.entry.name,
                        required: true
                    },
                    {
                        key: "positionX",
                        type: "Hidden",
                        value: this.entry.positionX
                    },
                    {
                        key: "positionY",
                        type: "Hidden",
                        value: this.entry.positionY
                    },
                    {
                        key: "isIntroduction",
                        type: "Hidden",
                        value: this.entry.isIntroduction || false
                    },
                    {
                        key: "hidePoiIfIntroduction",
                        type: "Hidden",
                        value: this.entry.hidePoiIfIntroduction || false
                    },
                    {
                        uiElement: "Spacer"
                    },
                    {
                        uiElement: "Title",
                        icon: "mdi-record-circle-outline",
                        text: "Point of interest (as seen in App)"
                    },
                    {
                        key: "poiLabel",
                        type: "Text",
                        label: "Label",
                        localized: true,
                        value: this.entry.poiLabel || {}
                    },
                    {
                        key: "hidePoiLabel",
                        type: "Checkbox",
                        label: "Hide Label",
                        value: this.entry.hidePoiLabel || false
                    },
                    {
                        key: "poiIcon",
                        type: "Select",
                        label: "Icon of point of interest",
                        options: [
                            { value: "Dot", text: "Default" },
                            { value: "Detail", text: "Detail" },
                            { value: "Fun", text: "Special" },
                            { value: "Identity", text: "Identity" },
                            { value: "Links", text: "Link" },
                            { value: "Reveal", text: "Reveal" }
                        ],
                        value: this.entry.poiIcon || "Dot",
                        showPoiIconHelp: true
                    },
                    {
                        key: "hideInBasicAR",
                        type: "Checkbox",
                        label: "Hide point of interest in regular Art Scanner mode",
                        value: this.entry.hideInBasicAR || false
                    },
                    {
                        key: "openOnTrackingStart",
                        type: "Hidden",
                        value: this.entry.openOnTrackingStart
                    },
                    {
                        key: "closeOnTrackingEnd",
                        type: "Hidden",
                        value: this.entry.closeOnTrackingEnd
                    },
                    {
                        key: "cannotBeClosed",
                        type: "Hidden",
                        value: this.entry.cannotBeClosed
                    },
                    {
                        key: "showInfoBoxDuringInteraction",
                        type: "Hidden",
                        value: this.entry.showInfoBoxDuringInteraction
                    },
                    {
                        key: "isBackgroundOfPOISelection",
                        type: "Hidden",
                        value: this.entry.isBackgroundOfPOISelection || false
                    },
                    {
                        key: "transform",
                        type: "Hidden",
                        value: this.entry.transform
                    },
                    {
                        key: "tags",
                        type: "Hidden",
                        value: this.entry.tags || []
                    },
                    {
                        uiElement: "Spacer"
                    },
                    {
                        uiElement: "Title",
                        icon: "mdi-tooltip-text-outline",
                        text: "Content of interaction"
                    },
                    ...createParameterFields(this.entry.interactionModule.parameters, this.entry.parameters, false)
                ],

                submitLabel: "Save",

                submitted: entry => {
                    this.$root.$emit("success", l("Changes to interaction \"${v.name}\" saved.", entry));
                    this.$emit("editedEntry");
                },

                cancelled: () => {
                    this.$emit("cancel");
                }
            });
        }
    }
</script>