
import { l, tryGetTokenOverride, selectLanguage } from "./localization.js";
import { encyclopedia } from "./localizationModulesEncyclopedia.js";
import { exhibition } from "./localizationModulesExhibition.js";
import { floorplan } from "./localizationModulesFloorplan.js";
import { scanner } from "./localizationModulesScanner.js";
import { timeline } from "./localizationModulesTimeline.js";
import { tours } from "./localizationModulesTours.js";


export const modules = {
    encyclopedia,
    exhibition,
    floorplan,
    scanner,
    timeline,
    tours,

};
