<template>
    <div>
        <div v-if="editor" class="toolbar">
            <div
                v-for="(tool, index) in toolbar"
                :key="index"
            >
                <v-tooltip v-if="tool.action" bottom>
                    <template #activator="{ on, attrs }">
                        <v-btn
                            icon
                            tile
                            :disabled="!tool.enabled()"
                            :class="{ activeFormatButton: tool.isActive() }"
                            v-bind="attrs"
                            v-on="on"
                            @click="tool.action()"
                        >
                            <v-icon>
                                {{ tool.icon }}
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>{{ l(tool.tooltip) }}</span>
                </v-tooltip>
                <div v-if="tool.spacer" class="toolbarSpacer" />
            </div>
        </div>
        <v-expand-transition>
            <div v-if="showLinkEditor" class="linkEditor px-3 py-2">
                <v-tabs v-model="linkType" height="32" class="mx-3" @change="onLinkTypeChange">
                    <v-tab key="app">
                        Link within app
                    </v-tab>
                    <v-tab key="web">
                        External web link
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="linkType">
                    <v-tab-item key="app" transition="false" class="linkEditorTabContent">
                        <div class="linkFieldBox">
                            <ReferenceField
                                v-model="appLinkField"
                                @input="insertAppLink"
                            />
                        </div>
                        <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                                <v-btn
                                    color="primary"
                                    icon
                                    class="ml-2"
                                    :disabled="!appLinkField.value"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="closeLinkEditor"
                                >
                                    <v-icon>
                                        mdi-check
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ saveLinkLabel }}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                                <v-btn
                                    color="primary"
                                    icon
                                    class="ml-2"
                                    :disabled="!canUnsetLink"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="unsetLink"
                                >
                                    <v-icon>
                                        mdi-link-off
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ removeLinkLabel }}</span>
                        </v-tooltip>
                    </v-tab-item>
                    <v-tab-item key="web" transition="false" class="linkEditorTabContent">
                        <v-text-field
                            ref="webLinkField"
                            v-model="webLinkValue"
                            hide-details="auto"
                            outlined
                            required
                            class="linkFieldInput"
                            @change="validateWebLink"
                        />
                        <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                                <v-btn
                                    color="primary"
                                    icon
                                    class="ml-2"
                                    :disabled="webLinkValue === 'http://'"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="saveWebLink"
                                >
                                    <v-icon>
                                        mdi-check
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ saveLinkLabel }}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                                <v-btn
                                    color="primary"
                                    icon
                                    class="ml-2"
                                    :disabled="!canUnsetLink"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="unsetLink"
                                >
                                    <v-icon>
                                        mdi-link-off
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ removeLinkLabel }}</span>
                        </v-tooltip>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </v-expand-transition>
        <div v-if="editor" :class="{ textareaContainer: true, withScrollbar: hasScrollbar }">
            <div ref="textarea" class="textarea subtitle-1" @mouseup="openLinkEditorIfLinkSelected">
                <EditorContent :editor="editor" />
            </div>
            <v-icon class="resize">
                mdi-resize-bottom-right
            </v-icon>
            <div class="resizeButton" @mousedown="startResize" />
        </div>
    </div>
</template>
<script>

    import { Editor, EditorContent } from "@tiptap/vue-2";
    import StarterKit from "@tiptap/starter-kit";
    import Link from "@tiptap/extension-link";
    import Superscript from "@tiptap/extension-superscript";
    import Subscript from "@tiptap/extension-subscript";
    import ReferenceField from "./ReferenceField.vue";
    import { setupField } from "../../../utils/FormUtils.js";
    import { l } from "../../../utils/LocalizationUtils.js";


    export default {
        components: {
            EditorContent,
            ReferenceField
        },
        model: {
            prop: "propField",
            event: "input"
        },
        props: {
            propField: { type: Object, default: null }
        },
        data() {
            return {
                editor: null,
                toolbar: [
                    {
                        icon: "mdi-undo",
                        tooltip: "Undo",
                        action: () => {
                            this.editor?.chain().focus().undo().run();
                        },
                        isActive: () => {
                            return false;
                        },
                        enabled: () => {
                            return this.editor?.can().undo();
                        }
                    },
                    {
                        icon: "mdi-redo",
                        tooltip: "Redo",
                        action: () => {
                            this.editor?.chain().focus().redo().run();
                        },
                        isActive: () => {
                            return false;
                        },
                        enabled: () => {
                            return this.editor?.can().redo();
                        }
                    },
                    {
                        spacer: true
                    },
                    {
                        icon: "mdi-format-bold",
                        tooltip: "Bold",
                        action: () => {
                            this.editor?.chain().focus().toggleBold().run();
                        },
                        isActive: () => {
                            return this.editor?.isActive("bold");
                        },
                        enabled: () => {
                            return true;
                        }
                    },
                    {
                        icon: "mdi-format-italic",
                        tooltip: "Italic",
                        action: () => {
                            this.editor?.chain().focus().toggleItalic().run();
                        },
                        isActive: () => {
                            return this.editor?.isActive("italic");
                        },
                        enabled: () => {
                            return true;
                        }
                    },
                    {
                        icon: "mdi-format-strikethrough",
                        tooltip: "Strike-through",
                        action: () => {
                            this.editor.chain().focus().toggleStrike().run();
                        },
                        isActive: () => {
                            return this.editor?.isActive("strike");
                        },
                        enabled: () => {
                            return true;
                        }
                    },
                    {
                        spacer: true
                    },
                    {
                        icon: "mdi-format-superscript",
                        tooltip: "Superscript",
                        action: () => {
                            this.editor?.chain().focus().toggleSuperscript().run();
                        },
                        isActive: () => {
                            return this.editor?.isActive("superscript");
                        },
                        enabled: () => {
                            return true;
                        }
                    },
                    {
                        icon: "mdi-format-subscript",
                        tooltip: "Subscript",
                        action: () => {
                            this.editor?.chain().focus().toggleSubscript().run();
                        },
                        isActive: () => {
                            return this.editor?.isActive("subscript");
                        },
                        enabled: () => {
                            return true;
                        }
                    },
                    {
                        spacer: true
                    },
                    {
                        icon: "mdi-link",
                        tooltip: "Link",
                        action: () => {
                            this.openLinkEditor();
                        },
                        isActive: () => {
                            return this.editor?.isActive("link");
                        },
                        enabled: () => {
                            return true;
                        }
                    }
                ],
                hasScrollbar: false,
                showLinkEditor: false,
                appLinkField: setupField({
                    type: "Reference",
                    referenceOf: "informationPage"
                }),
                webLinkValue: "http://",
                linkType: 0,
                canUnsetLink: false
            }
        },
        computed: {
            field() {
                return this.propField;
            },
            label() {
                return this.field.label ? l(this.field.label) : null;
            },
            saveLinkLabel() {
                return l("Save Link");
            },
            removeLinkLabel() {
                return l("Remove Link");
            }
        },
        watch: {
            appLinkField: {
                handler(field) {
                    if(field && field.value) {
                        this.insertAppLink(field.value);
                    }
                },
                deep: true
            }
        },
        mounted() {
            this.editor = new Editor({
                extensions: [
                    StarterKit.configure({
                        horizontalRule: false
                    }),
                    Superscript,
                    Subscript,
                    Link.configure({
                        HTMLAttributes: { target: undefined, rel: undefined },
                        openOnClick: false
                    })
                ],
                content: this.field.value,
                onCreate: () => {
                    this.checkScrollbar();
                },
                onUpdate: () => {
                    this.checkScrollbar();
                    this.field.value = this.stripUnwantedTags(this.editor?.getHTML());
                },
                onSelectionUpdate: (e) => {
                    if(this.showLinkEditor && !this.editor?.isActive("link")) {
                        this.closeLinkEditor();
                    }
                }
            });
        },
        beforeDestroy() {
            this.editor.destroy();
        },
        methods: {

            stripUnwantedTags(html) {
                const allowedTags = ["p", "br", "strong", "em", "s", "sup", "sub", "a"];
                return html.replace(/<(\/?)(\w+)[^>]*\/?>/gi, (match, endMark, tag) => {
                    return allowedTags.includes(tag) ? match : "";
                }).replace(/<!--.*?-->/g, "");
            },

            checkScrollbar() {
                const textarea = this.$refs.textarea;
                this.hasScrollbar = textarea.scrollHeight > textarea.clientHeight;
            },
            
            startResize(e) {
                const startHeight = this.$refs.textarea.clientHeight;
                const startY = e.clientY;
                document.body.style.cursor = "ns-resize";
                document.body.style.userSelect = "none";

                const resizer = (e) => {
                    const newHeight = Math.max(startHeight + (e.clientY - startY), 50);
                    this.$refs.textarea.style.height = newHeight + "px";
                    this.checkScrollbar();
                }

                document.addEventListener("mousemove", resizer, false);
                document.addEventListener(
                    "mouseup",
                    () => {
                        document.body.style.cursor = "";
                        document.body.style.userSelect = "auto";
                        document.removeEventListener("mousemove", resizer, false);
                    },
                    false
                );
            },

            openLinkEditor() {
                const linkAttributes = this.editor?.getAttributes("link");

                if(linkAttributes.hasOwnProperty("href")) {
                    const href = linkAttributes.href;
                    if(href.substring(0, 11) === "infopage://") {
                        const infoPageId = href.split("/")[2];
                        this.appLinkField.value = infoPageId;
                        this.webLinkValue = "http://";
                        this.linkType = 0;
                    } else {
                        this.appLinkField.value = null;
                        this.webLinkValue = href;
                        this.linkType = 1;
                    }
                    this.canUnsetLink = true;
                } else {
                    this.appLinkField.value = null;
                    this.webLinkValue = "http://";
                    this.linkType = 0;
                    this.canUnsetLink = false;
                }

                this.showLinkEditor = true;
            },

            insertAppLink(pageId) {
                const url = "infopage://" + pageId + "/";
                this.editor?.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
                this.canUnsetLink = true;
            },

            validateWebLink() {
                if(this.webLinkValue.substring(0, 7) !== "http://" && this.webLinkValue.substring(0, 8) !== "https://") {
                    this.webLinkValue = "http://" + this.webLinkValue;
                }
            },

            saveWebLink() {
                this.validateWebLink();
                let url = this.webLinkValue;
                this.editor?.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
                this.canUnsetLink = true;
                this.closeLinkEditor();
            },

            unsetLink() {
                this.editor?.chain().focus().unsetLink().run();
                this.closeLinkEditor();
            },

            onLinkTypeChange() {
                if(this.linkType === 0) {
                    setTimeout(() => {
                        this.webLinkValue = "http://";
                    }, 100);
                } else if(this.linkType === 1) {
                    setTimeout(() => {
                        this.$refs.webLinkField?.focus();
                        this.appLinkField.value = null;
                    }, 100);
                }
            },

            openLinkEditorIfLinkSelected() {
                if(this.editor?.isActive("link")) {
                    this.openLinkEditor();
                }
            },
            
            closeLinkEditor() {
                this.showLinkEditor = false;
            },

            l
        }
    }
</script>
<style scoped>

    .toolbar {
        display: flex;
        border-bottom: 1px solid #ddd;
    }

    .toolbarSpacer {
        align-self: stretch;
        margin: 8px;
        width: 1px;
        background: #ddd;
        height: 20px;
    }

    .activeFormatButton {
        background: #ddd !important;
    }

    .linkEditor {
        border-bottom: 1px solid #ddd;
    }

    .linkEditorTabContent {
        display: flex;
        align-items: center;
    }

    .linkFieldBox {
        border: 1px solid #9e9e9e;
        flex-grow: 1;
        border-radius: 4px;
    }

    .linkFieldBox:hover {
        border: 1px solid rgba(0, 0, 0, 0.87) !important;
    }

    .textareaContainer {
        position: relative;
    }

    .textarea {
        letter-spacing: normal !important;
        height: 200px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .resize {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    .resizeButton {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 16px;
        height: 16px;
        cursor: ns-resize;
    }

    .withScrollbar .resize,
    .withScrollbar .resizeButton {
        right: 20px !important;
    }

    ::v-deep .ProseMirror {
        min-height: 200px;
    }

    ::v-deep .ProseMirror:focus {
        outline: none;
    }

    ::v-deep .ProseMirror p {
        margin: 0 !important;
        padding: 10px !important;
    } 

    ::v-deep .ProseMirror a {
        color: #333;
        text-decoration: underline;
        cursor: text !important;
    } 

</style>