<template>
    <div>
        <h2 class="title primary--text">
            {{ l("Tour Stop Types") }}
        </h2>
        <v-progress-linear v-if="loading" indeterminate class="mt-5" />
        <v-btn v-if="stopsWithoutType.length > 0" type="error" outlined tile color="error" class="body-1 mt-5 pa-5" style="text-transform:none; width:100%" @click="openStopsWithoutTypeList()">
            <v-icon left>
                mdi-alert
            </v-icon>
            {{ l("There are ${v} tour stops with missing stop type.", stopsWithoutType.length) }}
        </v-btn>
        <v-card v-for="item in list" :key="item.id" class="mt-5">
            <v-card-title class="subtitle-2 pt-4 pb-0">
                <span class="element-id">
                    {{ item.id }}
                </span>
            </v-card-title>
            <v-data-table hide-default-footer :headers="headers" :items="item.versions" sort-by="versionNumber" :items-per-page="-1" :no-data-text="l('No entries.')">
                <template #item="row">
                    <tr>
                        <td class="text-right" style="width:40px">
                            {{ row.item.versionNumber }}
                        </td>
                        <td class="text-center" style="width:68px">
                            <v-tooltip v-if="row.item.isVisibleToCurator" left>
                                <template #activator="{ on }">
                                    <v-btn text icon color="primary" v-on="on" @click="toggleVisibility(row.item)">
                                        <v-icon>mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                                <span class="body-2">{{ l("Hide tour stop type from curator") }}</span>
                            </v-tooltip>
                            <v-tooltip v-if="!row.item.isVisibleToCurator" left>
                                <template #activator="{ on }">
                                    <v-btn text icon color="primary" v-on="on" @click="toggleVisibility(row.item)">
                                        <v-icon>mdi-eye-off-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span class="body-2">{{ l("Show tour stop type to curator") }}</span>
                            </v-tooltip>
                        </td>
                        <td style="width:20%">
                            {{ row.item.title }}
                        </td>
                        <td>
                            {{ row.item.versionDescription }}
                        </td>
                        <td class="text-right nobreak" style="width:100px">
                            <span class="pr-1" style="display:inline-flex; vertical-align:middle;">
                                {{ row.item.uses.length }}
                            </span>
                            <v-tooltip left>
                                <template #activator="{ on }">
                                    <v-btn text icon color="primary" v-on="on" @click="openUses(row.item)">
                                        <v-icon>mdi-view-list</v-icon>
                                    </v-btn>
                                </template>
                                <span class="body-2">{{ l("List all uses") }}</span>
                            </v-tooltip>
                        </td>
                        <td class="text-right nobreak" style="width:68px">
                            <v-tooltip left>
                                <template #activator="{ on }">
                                    <v-btn text icon color="primary" v-on="on" @click="openInformation(row.item)">
                                        <v-icon>mdi-information-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span class="body-2">{{ l("Information") }}</span>
                            </v-tooltip>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>
        <StopsWithoutTypeList v-if="showStopsWithoutTypeList" :stops-without-type="stopsWithoutType" @close="closeStopsWithoutTypeList" />
        <TourStopTypeUsesBox v-if="showUses" :stop-type="showUsesOf" @close="closeUses" />
        <TourStopTypeInformationBox v-if="showInformation" :tour-stop-type="showInformationOn" @close="closeInformation" />
    </div>
</template>
<script>

    import { mapActions } from "vuex";
    import TourStopTypeInformationBox from "./TourStopTypeInformationBox.vue";
    import StopsWithoutTypeList from "./StopsWithoutTypeList.vue";
    import TourStopTypeUsesBox from "./TourStopTypeUsesBox.vue";
    import TourStopTypesService from "../../services/TourStopTypesService.js";
    import TourService from "../../services/TourService.js";
    import ListUtils from "../../utils/ListUtils.js";
    import { l } from "../../utils/LocalizationUtils.js";

    export default {
        components: {
            TourStopTypeInformationBox,
            StopsWithoutTypeList,
            TourStopTypeUsesBox
        },
        props: {
            reloadTrigger: { type: Number, default: 0 },
        },
        data() {
            return {
                list: [],
                headers: ListUtils.addLocalizationToHeaders([
                    { text: "Version", sortable: false },
                    { text: "Visiblity", sortable: false },
                    { text: "Title", sortable: false },
                    { text: "Description", sortable: false },
                    { text: "Uses", sortable: false },
                    { text: "", sortable: false }
                ], this),
                loading: false,
                stopsWithoutType: [],
                showStopsWithoutTypeList: false,
                showUses: false,
                showUsesOf: null,
                showInformation: false,
                showInformationOn: null
            };
        },
        watch: {
            reloadTrigger() {
                this.update();
            }
        },
        created() {
            this.update();
        },
        methods: {
            update() {
                this.updateSectionTitle(this.l("Tour Stop Types"));
                this.loading = true;
                this.stopsWithoutType = [];

                TourStopTypesService.getTourStopTypes(list => {
                    this.list = this.prepareTypesList(list);
                    const typeIdLookUp = this.createTypeIdLookUp(list);

                    TourService.getTours(tours => {
                        this.assignUses(tours, typeIdLookUp);
                        this.loading = false;
                    });
                });

            },
            prepareTypesList(list) {
                list.forEach(type => {
                    type.versions.forEach(version => {
                        version.typeId = type.id;
                        version.uses = [];
                    });
                });
                return list;
            },
            createTypeIdLookUp(list) {
                const lookUp = {};
                list.forEach(type => {
                    lookUp[type.id] = {};
                    type.versions.forEach(version => {
                        lookUp[type.id][version.versionNumber] = version;
                    });
                });
                return lookUp;
            },
            assignUses(tours, typeIdLookUp) {
                tours.forEach(tour => {
                    let index = 0;
                    tour.stops.forEach(stop => {
                        stop.tourId = tour._id;
                        stop.tourTitle = tour.title;
                        stop.index = index;
                        const typeId = stop.stopTypeId;
                        const typeVersion = stop.stopTypeVersion || 0;
                        if (typeIdLookUp.hasOwnProperty(typeId)
                            && typeIdLookUp[typeId].hasOwnProperty(typeVersion)) {
                            typeIdLookUp[typeId][typeVersion].uses.push(stop);
                        } else {
                            this.stopsWithoutType.push(stop);
                        }
                        index++;
                    });
                });
            },
            toggleVisibility(entry) {
                entry.isVisibleToCurator = !entry.isVisibleToCurator;
                TourStopTypesService.updateTourStopTypes(this.list, list => {
                    this.list = list;
                    this.$root.$emit("success", this.l("Tour stop type's visibility updated."));
                });
            },
            openStopsWithoutTypeList() {
                this.showStopsWithoutTypeList = true;
            },
            closeStopsWithoutTypeList() {
                this.showStopsWithoutTypeList = false;
            },
            openUses(entry) {
                this.showUses = true;
                this.showUsesOf = entry;
            },
            closeUses() {
                this.showUses = false;
                this.showUsesOf = null;
            },
            openInformation(entry) {
                this.showInformation = true;
                this.showInformationOn = entry;
            },
            closeInformation() {
                this.showInformation = false;
                this.showInformationOn = null;
            },
            l,
            ...mapActions({
                updateSectionTitle: "platform/updateSectionTitle"
            })
        }
    };
</script>
<style scoped>
    .clickable {
        cursor: pointer;
    }

    .element-id {
        background: #ddd;
        font-family: "Lucida Console", Monaco, monospace;
        padding: 3px 5px;
    }
</style>
