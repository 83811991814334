export default {
    translations: {

        "$Language": "Deutsch (Schweiz)",

        "Logged in as": "Angemeldet als",
        "User Settings": "Benutzereinstellungen",
        "Logout": "Abmelden",
        "Error 403: You don't have permission to access this content.": "Fehler 403: Sie haben keine Zugriffsberechtigung auf diesen Inhalt.",

        "Maintenance": "Wartungsarbeiten",
        "The curation system is currently undergoing maintenance. This should only last a few minutes.": "Das Kurations-System wird gerade gewartet. Die Arbeiten sollten nur wenige Minuten dauern.",
        "Bypass": "Sperre umgehen",
        "Curation lock engaged!": "Kuration-Sperre aktiv!",

        "Exhibitions": "Ausstellungen",
        "AR Content": "AR-Inhalte",
        "Information Database": "Info-Datenbank",
        "Media": "Mediathek",
        "Analytics": "Analytics",
        "Users": "Benutzer",
        "System": "System",
        "Help": "Hilfe",

        "Cancel": "Abbrechen",
        "Create": "Erstellen",
        "Close": "Schliessen",
        "Add": "Hinzufügen",
        "Delete": "Löschen",
        "Remove": "Entfernen",
        "Save": "Speichern",
        "No entries.": "Keine Einträge.",
        "Never": "Nie",
        "All": "Alle",
        "{0}-{1} of {2}": "{0}-{1} von {2}",
        "Rows per page": "Einträge pro Seite",
        "Editing ${v.name}": "${v.name} bearbeiten",
        "Editing ${v.title}": "${v.title} bearbeiten",
        "Are you sure you want to delete \"${v.name}\"?": "Wollen Sie \"${v.name}\" wirklich löschen?",
        "Are you sure you want to delete \"${v.title}\"?": "Wollen Sie \"${v.title}\" wirklich löschen?",
        "Are you sure you want to delete \"${v.key}\"?": "Wollen Sie \"${v.key}\" wirklich löschen?",
        "Search": "Suche",
        "Information": "Informationen",
        "loading...": "lädt...",
        "Version": "Version",
        "Description": "Beschreibung",
        "Uses": "Nutzungen",
        "Visiblity": "Sichtbarkeit",
        "Yes": "Ja",
        "No": "Nein",
        "Not found": "Nicht gefunden",

        "$Date": "${v.day}. ${v.month} ${v.year}",
        "$Year and month": "${v.month} ${v.year}",
        "$Add era to date": "${v.dateString} ${v.era}",
        "Circa ${v.dateString}": "ca. ${v.dateString}",
        "Before ${v.dateString}": "vor ${v.dateString}",
        "After ${v.dateString}": "nach ${v.dateString}",
        "Single date": "Einzeldatum",
        "Time period": "Zeitspanne",
        "Circa": "Circa",
        "Before": "Vor",
        "After": "Nach",
        "Day": "Tag",
        "Month": "Monat",
        "AD Date": "n. Chr.",
        "BC Date": "v. Chr.",
        "From... Date": "Von...",
        "To... Date": "Bis...",
        "January": "Januar",
        "February": "Februar",
        "March": "März",
        "April": "April",
        "May": "Mai",
        "June": "Juni",
        "July": "Juli",
        "August": "August",
        "September": "September",
        "October": "Oktober",
        "November": "November",
        "December": "Dezember",
        "Invalid date": "Ungültiges Datum",
        "Edit label": "Beschriftung ändern",
        "The start date is after the end date.": "Das Anfangsdatum liegt nach dem Enddatum",
        "The start and end date are identical.": "Das Anfangsdatum und das Enddatum sind identisch.",
        "${v} seconds": "${v} Sekunden",
        "${v} minutes": "${v} Minuten",
        "${v} hours": "${v} Stunden",
        "${v} days": "${v} Tage",
        "${v} seconds.": "${v} s",
        "${v} minutes.": "${v} min",
        "${v} hours.": "${v} h",
        "${v} days.": "${v} d",

        "Fill": "Füllen",
        "Image transform mode fill explanation": "Das Bild füllt den Rahmen aus und ist gleichzeitig vollständig im Rahmen enthalten. Das Seitenverhältnis des Bilds wird nicht gewährleistet.",
        "Contain": "Enthalten",
        "Image transform mode contain explanation": "Das Bild ist vollständig im Rahmen enthalten und das Seitenverhältnis des Bilds wird gewährleistet. Das Bild füllt möglicherweise nicht den gesamten Rahmen aus.",
        "Cover": "Abdecken",
        "Image transform mode cover explanation": "Das Bild füllt den Rahmen aus und das Seitenverhältnis des Bilds wird gewährleistet. Möglicherweise geht das Bild über den Rahmen hinaus.",
        "Custom": "Benutzerdefiniert",
        "Image transform mode custom explanation": "Die Position, Grösse und Rotation des Bilds im Verhältnis zum Rahmen kann von Hand eingestellt werden.",
        "Horizontal offset": "Horizontaler Versatz",
        "Vertical offset": "Vertikaler Versatz",
        "Scale": "Skalierung",
        "Rotation": "Rotation",

        "Create New User": "Neuen Benutzer erstellen",
        "User \"${v.name}\" created.": "Benutzer \"${v.name}\" erstellt.",
        "Changes for user \"${v.name}\" saved.": "Änderungen am Benutzer \"${v.name}\" gespeichert.",
        "Name": "Name",
        "Email Address": "E-Mail-Adresse",
        "Last Login": "Letzte Anmeldung",
        "Password": "Passwort",
        "Change password": "Passwort ändern",
        "Access Rights": "Zugriffsrechte",
        "Language": "Sprache",
        "Delete User?": "Benutzer löschen?",
        "User \"${v.name}\" deleted.": "Benutzer \"${v.name}\" gelöscht.",
        "User Settings for ${v.name}": "Benutzereinstellungen for ${v.name}",

        "Create New Exhibition": "Neue Ausstellung erstellen",
        "Exhibition \"${v.title}\" created.": "Ausstellung \"${v.title}\" erstellt.",
        "Update JSON file in Firebase": "JSON-Datei auf Firebase aktualisieren",
        "Updating JSON In Firebase": "Aktualisiere JSON-Datei auf Firebase",
        "The JSON file of \"${v.title}\" was successfully updated.": "Die JSON-Datei von \"${v.title}\" wurde erfolgreich aktualisiert.",
        "Download Exhibition's Media": "Medien der Ausstellung herunterladen",
        "Creating Media Package": "Stellt Medien-Paket zusammen",
        "Download targets for Vuforia": "Vuforia-Targets herunterladen",
        "Bundling Targets for Vuforia": "Vuforia-Targets werden als ZIP-Datei verpackt",
        "View JSON file": "JSON-Datei anzeigen",
        "Changes for exhibition \"${v.title}\" saved.": "Änderungen an der Ausstellung \"${v.title}\" gespeichert.",
        "Delete Exhibition?": "Ausstellung löschen?",
        "Exhibition \"${v.title}\" deleted.": "Ausstellung \"${v.title}\" gelöscht.",
        "Vuforia .xml AR target file": ".xml AR-Target-Datei für Vuforia",
        "Vuforia .dat AR target file": ".dat AR-Target-Datei für Vuforia",
        "Edit Exhibition": "Ausstellung editieren",

        "All": "Alle",
        "Search Art Piece...": "Suche Kunstwerk...",
        "Not Exhibited": "Nicht Ausgestellte",
        "Exhibited": "Ausgestellte",
        "placing...": "platzieren...",
        "Not yet placed": "Noch nicht platziert",
        "Add to Exhibition": "Zur Ausstellung hinzufügen",
        "Remove from Exhibition": "Von der Ausstellung entfernen",
        "Set Position": "Position auswählen",
        "Additional Settings": "Weitere Einstellungen",
        "Art Pieces": "Kunstwerke",
        "Manage the Exhibition \"${v}\"": "Editiere die Ausstellung \"${v}\"",
        "Back to Exhibitions": "Zurück zu allen Ausstellungen",

        "Floors": "Ausstellungs Ebenen",
        "Remove Floor?": "Ausstellungs Ebene entfernen?",
        "Are you sure you want to remove \"${v.name}\"?": "Wollen Sie die Ausstellungs Ebene \"{v.name}\" wirklich entfernen?",
        "Floor \"${v.name}\" removed.": "Ausstellungs Ebene \"${v.name}\" entfernt.",

        "Add Art Piece": "Neues Kunstwerk hinzufügen",
        "Art Piece Details": "Angaben zum Kunstwerk",
        "Display Settings": "Anzeige-Einstellungen",
        "Image": "Bild",
        "Number": "Nummer",
        "Title": "Titel",
        "Artist": "Künstler",
        "Year": "Jahr",
        "Medium": "Technik",
        "Width in cm": "Breite in cm",
        "Art Piece ID": "Kunstwerk-ID",
        "Media ID of Art Piece Image": "Medien-ID des Kunstwerk-Bilds",
        "Vuforia Target Name": "Name des Vuforia-Targets",
        "Media ID of Target Image": "Medien-ID des Target-Bilds",
        "Delete Art Piece?": "Kunstwerk löschen?",
        "Art piece \"${v.title}\" deleted.": "Kunstwerk \"${v.title}\" gelöscht.",
        "Show overlay on art piece when it's being tracked in AR": "Overlay über Kunstwerk in AR anzeigen",
        "Offset of interactions from AR target in cm": "Abstand der Interaktionen vom AR-Target in cm",
        "Show information box when AR target is tracked": "Informations-Box zum AR-Target anzeigen",

        "Back to list of art pieces": "Zurück zur Kunstwerk-Liste",
        "Interactions of \"${v}\"": "Interaktionen für \"${v}\"",
        "No Interaction Modules found": "Keine Interaktions-Module gefunden",
        "Add ${v.name} Interaction": "\"${v.name}\"-Interaktion hinzufügen",
        "Remove Interaction?": "Interaktion entfernen",
        "Are you sure you want to remove the interaction \"${v.name}\"?": "Wollen Sie die Interaktion \"${v.name}\" wirklich entfernen?",
        "Interaction \"${v.name}\" removed.": "Interaktion \"${v.name}\" wurde entfernt.",
        "Edit Interaction ${v}": "Interaktion ${v} bearbeiten",
        "Edit interactions": "Interaktionen ändern",
        "Change interaction's position": "Koordinaten der Interaktion ändern.",
        "Select Interaction Type...": "Interaktionstyp wählen...",
        "Add \"${v}\" interaction": "\"${v}\"-Interaktion hinzufügen",
        "Add ${v} Interaction": "${v}-Interaktion hinzufügen",
        "Add ${v}": "${v} hinzufügen",
        "Interaction Type": "Interaktionstyp",
        "Coordinates": "Koordinaten",
        "Changes to interaction \"${v.name}\" saved.": "Änderungen an der Interaktion \"${v.name}\" gespeichert.",
        "Edit Settings of Interaction": "Einstellungen der Interaktion bearbeiten",
        "Edit Settings of Interaction ${v}": "Einstellungen für ${v}-Interaction bearbeiten",
        "Interaction Module:": "Interaktions-Modul:",
        "Name in CMS (not shown in the app)": "Name im CMS (wird nicht in der App angezeigt)",
        "Point of interest (as seen in App)": "Point-Of-Interest (in der App)",
        "Hide Label": "Beschriftung ausblenden",
        "Icon of point of interest": "Symbol des Point-Of-Interests",
        "Hide point of interest in regular Art Scanner mode": "Point-Of-Interest im normalen Kunstscanner ausblenden",
        "Content of interaction": "Interaktions-Inhalt",
        "Position x axis": "Position: X-Koordinate",
        "Position y axis": "Position: Y-Koordinate",
        "Behavior": "Verhalten",
        "Is the art piece's introduction:": "Ist die Einleitung des Kunstwerks:",
        "Open interaction when tracking of art piece starts": "Interaktion öffnet automatisch wenn das Kunstwerk getrackt wird",
        "Close interaction when tracking of art piece stops": "Interaktion schliesst automatisch wenn das Tracking des Kunstwerk verloren geht",
        "User cannot close interaction manually": "Benutzer kann die Interaktion nicht manuell schliessen",
        "Art piece information box is visible during interaction": "Informations-Box des Kunstwerks während der Interaktion anzeigen",
        "Transform (9 comma-separated values: 3 translate, 3 rotation, 3 scale)": "Transformation (9 kommagetrennte Werte: 3 Verschieben, 3 Rotatieren, 3 Skalieren)",
        "Dismiss as introduction": "Einleitung aufheben",
        "Choose as introduction": "Als Einleitung auswählen",
        "Hide in regular Art Scanner mode": "Im normalen Kunstscanner ausblenden",
        "Show in regular Art Scanner mode": "Im normalen Kunstscanner anzeigen",
        "Introduction": "Einleitung",
        "Introduction settings saved for \"${v}\"": "Einleitungs-Einstellung für \"${v}\" gespeichert.",
        "Updated visibility of \"${v.name}\"": "Sichtbarkeit von \"${v.name}\" gespeichert",

        "Text": "Text",
        "Audio": "Audio",
        "Video": "Video",
        "Multiple Images": "Bilderreihe",
        "Perspective": "Perspektive",

        "File name": "Dateinamen",
        "Display orthogonal to art piece": "Rechtwinklig zum Kunstwerk anzeigen",
        "Audio file": "Audio-Datei",
        "Face descriptors as JSON": "Gesichts-Parameter als JSON",
        "Custom Tutorial Text": "Benutzerdefinierter Tutorialtext",
        "Label": "Beschriftung",
        "Show as AR overlay on art piece": "Als AR-Overlay auf Kunstwerk anzeigen",
        "Layers": "Ebenen",
        "Layer parameters": "Parameter der Ebenen",
        "Perpendicular layers": "Senkrechte Ebenen",
        "Perpendicular layer parameters": "Parameter der senkrechten Ebenen",
        "Global parameters": "Globale Parameter",
        "Images": "Bilder",
        "Titles": "Titel",
        "Texts": "Texte",
        "Audio files": "Audio-Dateien",
        "Text pages": "Textseiten",
        "Video file": "Video-Datei",
        "Caption": "Bildlegende",
        "Caption page": "Seite in Bildlegende",
        "AR image scaling mode": "Skalierung-Modus des Bilds in AR",
        "AR image scaling modes": "Skalierung-Modi der Bilder in AR",
        "Image scaling mode": "Skalierung-Modus",

        "Face descriptor": "Gesichts-Parameter",
        "Layer": "Ebene",
        "Layer parameter": "Parameter für Ebene",
        "Perpendicular layer": "Senkrechte Ebene",
        "Perpendicular layer parameter": "Parameter für senkrechte Ebene",
        "Page": "Seite",

        "Enter author's name...": "Name des Autors eingeben...",
        "Enter title...": "Titel eingeben...",
        "Enter text...": "Text eingeben...",
        "Enter teaser...": "Teaser eingeben...",
        "Enter link label...": "Link-Beschriftung eingeben...",

        "Encyclopedia": "Enzyklopädie",
        "Category": "Kategorie",
        "Entries": "Einträge",

        "Topic": "Thema",
        "Topics": "Themen",
        "Acquirer": "Erwerber",
        "Acquirers": "Erwerber",
        "Article": "Artikel",
        "Articles": "Artikel",
        "Artist": "Künstler",
        "Artists": "Künstler",
        "Art Piece": "Kunstwerk",
        "Art Pieces": "Kunstwerke",
        "Art Movement": "Kunstrichtung",
        "Art Movements": "Kunstrichtungen",
        "Categories": "Kategorien",
        "Focus": "Schwerpunkt",
        "Focuses": "Schwerpunkte",
        "Location": "Ort",
        "Locations": "Orte",

        "By ${v}": "Von ${v}",
        "1 minute": "1 Minute",

        "Create Information Page": "Informations-Seite hinzufügen",
        "Information page \"${v.name}\" created.": "Informations-Seite \"${v.name}\" hinzugefügt.",
        "Type": "Typ",
        "Edit Settings of Information Page": "Einstellungen der Informations-Seite bearbeiten",
        "Edit Settings of Information Page ${v.name}": "Einstellungen der Informations-Seite ${v.name} bearbeiten",
        "Edit Information Page ${v.name}": "Informations-Seite ${v.name} bearbeiten",
        "Changes to information page \"${v.name}\" saved.": "Änderungen an Informations-Seite \"${v.name}\" gespeichert.",
        "Add Information Page": "Informations-Seite hinzufügen",
        "Delete Information Page?": "Informations-Seite löschen?",
        "Information page \"${v.name}\" deleted.": "Informations-Seite \"${v.name}\" wurde gelöscht.",
        "Edit Content": "Inhalt editieren",

        "Add Media": "Neue Media-Datei hinzufügen",
        "Upload": "Hochladen",
        "Delete Media?": "Media-Datei löschen?",
        "Media \"${v.name}\" added.": "Media-Datei \"${v.name}\" hinzugefügt.",
        "Changes for media \"${v.name}\" saved.": "Änderungen für Media-Datei \"${v.name}\" gespeichert.",
        "Upload Date": "Hochgeladen am",
        "File Origin Date": "Datei vom",
        "File Size": "Grösse",
        "File Type": "Dateityp",
        "File Revision": "Datei-Version",
        "Select a File...": "Datei auswählen...",
        "Update File...": "Datei ändern...",
        "The file \"${v}\" will be overwritten when clicking UPDATE.": "Die Datei \"${v}\" wird beim Klick auf AKTUALISIEREN überschrieben.",
        "Sorry, this file type is not supported.": "Leider wird dieses Dateiformat nicht unterstützt.",
        "Undo File Change": "Änderung an Datei verwerfen",
        "Open Asset": "Medien-Datei als Asset öffnen",
        "Download": "Herunterladen",
        "bytes": "Bytes",
        "Edit Media Settings": "Media-Einstellungen bearbeiten",
        "Edit Media Settings of ${v.name}": "Media-Einstellungen von ${v.name} bearbeiten",
        "Prevent the app from caching this file": "Caching dieser Datei in der App verhindern",
        "Original File Name": "Original-Dateiname",
        "Size": "Grösse",
        "Caching": "Caching",
        "Allowed": "Erlaubt",
        "Forbidden": "Verboten",
        "Original file ID": "Datei-ID des Originals",
        "Very low quality file ID": "Datei-ID sehr niedrige Qualität",
        "Low quality file ID": "Datei-ID niedrige Qualität",
        "Medium quality file ID": "Datei-ID mittlere Qualität",
        "High quality file ID": "Datei-ID hohe Qualität",
        "Very high quality file ID": "Datei-ID sehr hohe Qualität",
        "JSON media entry": "JSON-Media-Eintrag",
        
        "Advanced Search": "Erweiterte Suche",
        "File type": "Dateityp",
        "File size": "Dateigrösse",
        "Date uploaded": "Hochgeladen am",
        "Image files": "Bild-Dateien",
        "Audio files": "Audio-Dateien",
        "Video files": "Video-Dateien",
        "Text files": "Text-Dateien",
        "System files": "System-Dateien",
        "< 100 KB": " < 100 KB",
        "100 KB - 1 MB": "100 KB - 1 MB",
        "1 MB - 10 MB": "1 MB - 10 MB",
        "> 10 MB": "> 10 MB",
        "Today": "Heute",
        "Last 3 days": "In den letzten 3 Tagen",
        "This week": "Diese Woche",
        "This month": "Diesen Monat",
        "This year": "Dieses Jahr",

        "Languages": "Sprachen",
        "Add Language": "Sprache hinzufügen",
        "Priority": "Priorität",
        "IETF language tag": "IETF Sprach-Tag",
        "Increase priority": "Priorität erhöhen",
        "Decrease priority": "Priorität verringern",
        "Language \"${v.name}\" added.": "Sprache \"${v.name}\" hinzugefügt.",
        "Edit Language ${v.ietfLanguageTag}": "Sprache ${v.ietfLanguageTag} bearbeiten",
        "Changes to language \"${v.name}\" saved.": "Änderungen an der Sprache \"${v.name}\" gespeichert.",
        "Delete Language?": "Sprache löschen?",
        "Language \"${v.name}\" deleted.": "Sprache \"${v.name}\" gelöscht.",
        "Language priorities updated.": "Prioritäten-Reihenfolge der Sprachen aktualisiert.",
        "Localization JSON file": "Localization-JSON-Datei",
        "Localization JSON file assigned.": "Localization-JSON-Datei zugewiesen.",

        "Minimal App Version": "Minimale App-Version",
        "Version number": "Versionsnummer",
        "The version number may only consist of numbers seperated by dots.": "Die Versionsnummer darf nur aus punkt-getrennten Zahlen bestehen.",
        "Minimum app version updated to ${v}.": "Minimale App-Version auf ${v} aktualisiert.",

        "App Settings": "App-Einstellungen",
        "Create New App Setting": "Neue App-Einstellung erstellen",
        "Value": "Wert",
        "App setting \"${v.key}\" created.": "App-Einstellung \"${v.key}\" erstellt.",
        "Changes for app setting \"${v.key}\" saved.": "Änderungen an der App-Einstellung \"${v.key}\" gespeichert.",
        "Delete App Setting?": "App-Einstellung löschen?",
        "App setting \"${v.key}\" deleted.": "App-Einstellung \"${v.key}\" gelöscht.",

        "Server Settings": "Server-Einstellungen",
        "Curation lock": "Kurations-Sperre",
        "Server settings updated.": "Server-Einstellungen aktualisiert.",

        "Interaction Modules": "Interaktions-Module",
        "Hide interaction module from curator": "Interaktions-Modul für Kurator ausblenden",
        "Show interaction module to curator": "Interaktions-Modul für Kurator einblenden",
        "Interaction module's visibility updated.": "Sichtbarkeit des Interaktions-Moduls aktualisiert.",
        "ID": "ID",
        "Version Number": "Versions-Nummer",
        "Parameters": "Parameter",
        "Parameter Name": "Parametername",
        "Uses of ${v.title}": "Verwendungen von ${v.title}",
        "Art piece missing!": "Kunstwerk nicht vorhanden!",
        "Required": "Erforderlich",
        "Localized": "Lokalisiert",
        "There are ${v} interactions with missing interaction modules.": "Für ${v} Interaktionen konnte das dazugehörige Interaktions-Modul nicht gefunden werden.",
        "Interactions with Missing Interaction Modules": "Interaktionen mit fehlenden Interaktions-Modulen",
        "Interaction Module ID": "Interaktions-Modul-ID",

        "Information Page Types": "Informations-Seiten-Typen",
        "Hide information page type from curator": "Informations-Seiten-Typ für Kurator ausblenden",
        "Show information page type to curator": "Informations-Seiten-Typ für Kurator einblenden",
        "Information page type's visibility updated.": "Sichtbarkeit des Informations-Seiten-Typs aktualisiert.",
 
        "Information Page Blocks": "Informations-Seiten-Bausteine",
        "Hide information page block from curator": "Informations-Seiten-Baustein für Kurator ausblenden",
        "Show information page block to curator": " Informations-Seiten-Baustein für Kurator einblenden",
        "Information page block's visibility updated.": "Sichtbarkeit des Informations-Seiten-Bausteins aktualisiert.",
 
        "Environment Variables": "Environment-Variablen",
        "Server Log": "Server-Log",
        "Automatically update every ${v} seconds": "Automatisch alle ${v} Sekunden aktualisieren",
        "Bash Scripts": "Bash-Scripts",
        "Call": "Ausführen",
        "No bash scripts configured.": "Keine Bash-Scripts konfiguriert.",
        "Bash script list must be configured in these files:": "Die Liste der Bash-Scripts muss in den folgenden Dateien konfiguriert werden:",

        "Validation error: any.required": "Dieses Eingabefeld darf nicht leer gelassen werden.",
        "Validation error: any.empty": "Dieses Eingabefeld darf nicht leer gelassen werden.",
        "Validation error: string.empty": "Dieses Eingabefeld darf nicht leer gelassen werden.",
        "Validation error: string.email": "Dies ist keine gültige E-Mail-Adresse.",
        "Validation error: string.max": "Diese Eingabe enthält zu viele Zeichen.",
        "Validation error: string.min": "Diese Eingabe enthält zu wenig Zeichen.",
        "Validation error: string.base": "Ungültige Eingabe.",
        "Validation error: string.alphanum": "Diese Eingabe darf nur aus Gross- und Kleinbuchstaben sowie Zahlen bestehen.",
        "Validation error: number.base": "Die Eingabe muss aus einer Zahl bestehen.",
        "Validation error: userEmail.alreadyExists": "Es gibt bereits einen Benutzer mit dieser E-Mail-Adresse.",
        "Validation error: mediaName.alreadyExists": "Es gibt bereits eine Media-Datei mit diesem Namen.",
        "Validation error: target.alreadyInUse": "Dieses Target wird bereits von einem anderen Kunstwerk verwendet.",
        "Validation error: interactionModuleName.alreadyExists": "Es gibt bereits ein Interaktions-Modul mit diesem Namen.",
        "Validation error: informationPageTypeName.alreadyExists": "Es gibt bereits ein Informationsseiten-Typ mit diesem Namen.",
        "Validation error: informationPageModuleName.alreadyExists": "Es gibt bereits ein Informationsseiten-Modul mit diesem Namen.",
        "Validation error: paramName.alreadyExists": "Es gibt bereits einen Parameter mit diesem Namen.",
        "Validation error: file.required": "Dieses Eingabefeld darf nicht leer gelassen werden.",
        "Validation error: paramName.notAllowed": "Dieser Name ist ungültig.",
        "Validation error: interactionModule.inUse": "Dieses Interaktions-Modul ist noch in Verwendung.",
        "Validation error: artPieceId.alreadyInExhibition": "Dieses Kunstwerk existiert bereits in dieser Ausstellung.",
        "Validation error for language ${v.language}: any.empty": "Die Übersetzung für \"${v.language}\" darf nicht leer gelassen werden.",
        "Validation error for language ${v.language}: string.max": "Die Übersetzung für \"${v.language}\" enthält zu viele Zeichen.",
        "Validation error for language ${v.language}: string.base": "Die Übersetzung für \"${v.language}\" darf nicht leer gelassen werden.",

        "Exhibit.": "Ausstell.",
        "AR Content": "AR-Inhalte",
        "Info. Dat.": "Info.-Datenb.",
        "Analytics.": "Analytics",
        "Users.": "Benutzer",
        "System.": "System",

        "Analytics": "Analytics",
        "App Statistics": "App Statistik",
        "Museum Transition Map": "Museum Transitions Karte",
        "Art Piece Transition Map": "Kunstwerk Transitions Karte",
        "Update": "Aktualisieren",
        "App Usage": "Nutzung der App",
        "Feature Usage": "Nutzung der Features",
        "Art Pieces Tracked": "Tracking der Kunstwerke",
        "All": "Alle",
        "Start Date": "Von",
        "End Date": "Bis",
        "Exhibition": "Ausstellung",
        "Day": "Tag",
        "Unique Users": "Einzelne Nutzer",
        "App Launches": "Starts der App",
        "Feature": "Feature",
        "Hours used": "Stunden verwendet",
        "Seconds used": "Sekunden verwendet",
        "Artwork": "Kunstwerk",
        "Tracking events": "Tracking-Events",

        "AnalyticsFeature:Home": "Home",
        "AnalyticsFeature:InfoPage": "Enzyklopädie",
        "AnalyticsFeature:Trail": "Rundgang",
        "AnalyticsFeature:AR": "Kunstscanner",
        "AnalyticsFeature:Map": "Plan",
        "AnalyticsFeature:BurgerMenu": "Hauptmenü",
        "AnalyticsFeature:HistoryTimeline": "Historische Zeitleiste",
        "AnalyticsFeature:WorldMap": "Weltkarte",
        "AnalyticsFeature:Search": "Suche",
        "AnalyticsFeature:None": "Sonstige",
        "${v} of usage": "${v} Nutzung",
        "${v} of total usage": "${v} der gesamten Nutzung",
        "No data": "Keine Daten",

        "Art-Piece Visit Statistics": "Kunstwerk Besucher Statistiken",
        "Art Piece": "Kunstwerk",
        "Start of App Usage": "Start der App Nutzung",
        "Popularity": "Popularität",
        "Visits": "Besuche",
        "Time Spent": "Verweil Dauer",
        "Average Time Spent": "Durchschn. Verweil Dauer",
        "Time Spent in AR": "AR Nutzungsdauer",
        "End of App Usage": "Ende der App Nutzung",
        "Art Pieces visited next": "Als nächstes besuchte Kunstwerke",
        "Count": "Anzahl",
        "Probability": "Wahrscheinlichkeit",

        "Interaction Visit Statistics": "AR Interaktionen Statistik",
        "Interactions visited next": "Als nächstes besuchte Interaktionen",
        "spent on average": "wird hier im Schnitt verbracht",
        "first visited interaction of this art piece": "als erstes besuchte Interaktion dieses Kunstwerks",
        "last visited interaction of this art piece": "als letztes besuchte Interaktion dieses Kunstwerks",
        
        "times visited": "mal besucht",
        "spent here on average": "werden hier im Schnitt verbracht",
        "spent here on average in AR mode": "werden hier im Schnitt im AR Modus verbracht",
        "first visited painting of the exhibition": "erstes besuchtes Kunstwerk der Ausstellung",
        "last visited painting of the exhibition": "letztes besuchtes Kunstwerk der Ausstellung",
        "Select Art Piece": "Kunstwerk auswählen",
        "Select Art Piece...": "Kunstwerk auswählen...",
        "Interaction": "Interaktion",
        "First Interaction": "Erste Interaktion",
        "Last Interaction": "Letzte Interaktion",

        "In case you need help or support, please contact:": "Falls Sie Hilfe benötigen, bitte kontaktieren Sie:",
        "Credits": "Credits",

        "Error 403": "Fehler 403",
        "You are not permitted to access this page.": "Sie sind nicht dazu berechtigt, auf diese Seite zuzugreifen.",
        "Error 404": "Fehler 404",
        "Page not found.": "Seite nicht gefunden."
    }
}