<template>
    <div>
        <h1 class="display-1 mb-3 primary--text">
            <v-icon color="primary" class="titleIcon">
                supervisor_account
            </v-icon>
            {{ l("Users") }}
        </h1>
        <v-layout justify-end row class="pb-5 pr-3">
            <v-btn color="primary" class="pl-3" @click="add">
                <v-icon class="mr-2">
                    add
                </v-icon>
                {{ l("Create New User") }}
            </v-btn>
        </v-layout>
        <v-data-table :headers="headers" :items="list" :sort-by="sortBy" :items-per-page="itemsPerPage" :footer-props="pagination" :loading="loading" :no-data-text="l('No entries.')">
            <template #item="row">
                <tr>
                    <td class="clickable" @click="edit(row.item)">
                        {{ row.item.name }}
                    </td>
                    <td class="clickable" @click="edit(row.item)">
                        {{ row.item.email }}
                    </td>
                    <td class="clickable" @click="edit(row.item)">
                        {{ row.item.lastLogin }}
                    </td>
                    <td class="clickable" @click="edit(row.item)">
                        <v-icon v-if="row.item.rights.exhibitions">
                            done
                        </v-icon>
                    </td>
                    <td class="clickable" @click="edit(row.item)">
                        <v-icon v-if="row.item.rights.arContent">
                            done
                        </v-icon>
                    </td>
                    <td class="clickable" @click="edit(row.item)">
                        <v-icon v-if="row.item.rights.informationDatabase">
                            done
                        </v-icon>
                    </td>
                    <td class="clickable" @click="edit(row.item)">
                        <v-icon v-if="row.item.rights.analytics">
                            done
                        </v-icon>
                    </td>
                    <td class="clickable" @click="edit(row.item)">
                        <v-icon v-if="row.item.rights.users">
                            done
                        </v-icon>
                    </td>
                    <td class="clickable" @click="edit(row.item)">
                        <v-icon v-if="row.item.rights.system">
                            done
                        </v-icon>
                    </td>
                    <td class="text-right">
                        <v-tooltip v-if="isNotSelf(row.item)" left>
                            <template #activator="{ on }">
                                <v-btn text icon color="primary" v-on="on" @click="deleteEntry(row.item)">
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">{{ l("Delete") }}</span>
                        </v-tooltip>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>
<script>

    import { mapGetters } from "vuex";
    import UserService from "../../services/UserService.js";
    import ListUtils from "../../utils/ListUtils.js";
    import StringUtils from "../../utils/StringUtils.js";

    export default ListUtils.setupList({
        headers: [
            { text: "Name", value: "name" },
            { text: "Email Address", value: "email" },
            { text: "Last Login", value: "lastLogin" },
            { text: "Exhibit.", value: "rights.exhibition" },
            { text: "AR Content.", value: "rights.arContent" },
            { text: "Info. Dat.", value: "rights.informationDatabase" },
            { text: "Analytics.", value: "rights.analytics" },
            { text: "Users.", value: "rights.users" },
            { text: "System.", value: "rights.system" },
            { text: "", sortable: false }
        ],
        sortBy: "name",
        update() {
            this.showLoading();
            UserService.getUsers(list => {
                list.forEach(entry => {
                    entry.lastLogin = StringUtils.niceDate(entry.lastLogin, this.l("Never"));
                });
                this.list = list;
                this.hideLoading();
            });
        },
        deleteSettings : {
            promptTitle: "Delete User?",
            promptQuestion: "Are you sure you want to delete \"${v.name}\"?",
            action: UserService.deleteUser,
            success: "User \"${v.name}\" deleted."
        },
        computed: {
            ...mapGetters({
                user: "user/getUser"
            })
        },
        methods: {
            isNotSelf(user) {
                return this.user._id !== user._id;
            }
        }
    });
</script>
<style>
    .clickable {
        cursor: pointer;
    }
</style>