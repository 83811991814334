
import { l, tryGetTokenOverride, selectLanguage } from "./localization.js";


// Artwork pages in encyclopedia
export const artworks = {


    
    entry(number, locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "entry");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        switch(number) {
            case 1: return l.app.pages.artworks.singular(locale);
            default: return l.app.pages.artworks.plural(locale);
        }
            
    },
            

    
    singular(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "singular");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return "Artwork";
            case "de": return "Kunstwerk";
            case "fr": return "Œuvre d'art";
            default: return `[singular: ${language} missing]`;
        }
    
    },
            

    
    plural(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "plural");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return "Artworks";
            case "de": return "Kunstwerke";
            case "fr": return "Œuvres d'art";
            default: return `[plural: ${language} missing]`;
        }
    
    },
            

    
    title(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "title");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Title";
            case "de": return "Titel";
            default: return `[title: ${language} missing]`;
        }
    
    },
            

    
    date(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "date");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Date";
            case "de": return "Datierung";
            default: return `[date: ${language} missing]`;
        }
    
    },
            

    
    medium(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "medium");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Material/technique";
            case "de": return "Material/Technik";
            default: return `[medium: ${language} missing]`;
        }
    
    },
            

    
    dimensions(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "dimensions");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "de-CH"]);

        switch (language) {
            case "en": return "Dimensions";
            case "de": return "Maße";
            case "de-CH": return "Masse";
            default: return `[dimensions: ${language} missing]`;
        }
    
    },
            

    
    credits(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "credits");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Credits";
            case "de": return "Besitz";
            default: return `[credits: ${language} missing]`;
        }
    
    },
            

    
    catalogueNumber(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "catalogueNumber");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Inventory number";
            case "de": return "Inventar-Nummer";
            default: return `[catalogueNumber: ${language} missing]`;
        }
    
    },
            

    
    catalogueNumberAbr(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "catalogueNumberAbr");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Inv. No.";
            case "de": return "Inv.-Nr.";
            default: return `[catalogueNumberAbr: ${language} missing]`;
        }
    
    },
            

    
    acquirer(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "acquirer");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Acquisition";
            case "de": return "Erwerb";
            default: return `[acquirer: ${language} missing]`;
        }
    
    },
            

    
    creation(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "creation");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Place of production";
            case "de": return "Entstehungsort";
            default: return `[creation: ${language} missing]`;
        }
    
    },
            

    
    depiction(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "depiction");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Place depicted";
            case "de": return "Dargestellter Ort";
            default: return `[depiction: ${language} missing]`;
        }
    
    },
            
};
