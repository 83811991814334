import cloneDeep from "lodash";


export function deepClone(original) {
    return _.cloneDeep(original);
}


export function deepCloneAndReplace(original, needle, replacer) {
    const clone = deepClone(original);
    replaceNestedChildInClone(original, clone, needle, replacer);
    return clone;
}


export function replaceNestedChildInClone(original, clone, needle, replacer) {

    if (original && typeof original === "object") {
        for (const [key, value] of Object.entries(original)) {
            if (value === needle) {
                clone[key] = replacer;
            } else {
                replaceNestedChildInClone(value, clone[key], needle, replacer);
            }
        }

    } else if (Array.isArray(original)) {
        for (const [index, value] of original.entries()) {
            if (value === needle) {
                clone[index] = replacer;
            } else {
                replaceNestedChildInClone(value, clone[index], needle, replacer);
            }
        }
    }
    
}


export function objectHash(value) {

    let hashString = "";

    if (value == null) {
        hashString = "null";

    } else {

        hashString = typeof value;
        if (Array.isArray(value)) {
            value.forEach(element => {
                hashString += ObjectToHash.hash(element);
            });

        } else if (typeof value === "object") {
            for (let key in value) {
                hashString += key;
                hashString += ObjectToHash.hash(value[key]);
            }

        } else if (typeof value === "boolean") {
            hashString += value ? "true" : "false";

        } else if (typeof value === "number") {
            hashString += value.toString(16);

        } else if (typeof value === "string") {
            hashString += value;
        }
    }
        
    let hash = 0;
    for (var i = 0; i < hashString.length && i < 256; i++) {
        let char = hashString.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash;
    }

    return hash.toString(16);
}