<template>
    <div>
        <h2 class="title mb-3 primary--text">
            {{ l("Server Log") }}
        </h2>
        <v-container class="pr-3 py-0">
            <v-row align="center" justify="end">
                <v-checkbox v-model="autoReload" :label="l('Automatically update every ${v} seconds', 3)" color="primary" />
                <v-btn color="primary" class="pl-3 ml-5" :loading="loading" @click="update">
                    <v-icon class="mr-2">
                        mdi-update
                    </v-icon>
                    {{ l("Update") }}
                </v-btn>
            </v-row>
        </v-container>
        <div ref="logBox" class="logBox">
            <!-- eslint-disable -->
            <div class="log">{{ log }}</div>
            <!-- eslint-enable -->
        </div>
    </div>
</template>
<script>

    import { mapActions } from "vuex";
    import LogService from "../../services/LogService.js";
    import { l } from "../../utils/LocalizationUtils.js";


    export default {
        props: {
            visible: { type: Boolean, default: false },
            reloadTrigger: { type: Number, default: 0 },
        },
        data() {
            return {
                loading: false,
                interval: null,
                autoReload: true,
                log: "",
            };
        },
        watch: {
            reloadTrigger() {
                this.update();
            },
            visible(isVisible) {
                if (isVisible) {
                    this.setupAutoReload();
                } else {
                    if (this.interval !== null) {
                        clearInterval(this.interval);
                        this.interval = false;
                    }
                }
            },
        },
        mounted() {
            this.update();
            this.setupAutoReload();
        },
        methods: {

            setupAutoReload() {
                this.interval = setInterval(() => {
                    if (this.autoReload) {
                        this.update();
                    }
                }, 3000);
            },

            update() {
                this.updateSectionTitle(this.l("Server Log"));
                this.loading = true;
                LogService.getLogLines(2000, lines => {
                    if (this.$refs.logBox) {
                        this.log = lines.trim();
                        this.$refs.logBox.scrollTop = this.$refs.logBox.scrollHeight;
                        this.loading = false;
                    }
                });
            },

            l,

            ...mapActions({
                updateSectionTitle: "platform/updateSectionTitle"
            }),
        }
    }
</script>
<style scoped>

    .logBox {
        border: 1px solid #bbb;
        height: 640px;
        background: #ddd;
        overflow: auto;
    }

    .log {
        padding: 20px;
        color: #333;
        font-size: 12px;
        font-family: "Lucida Console", Monaco, monospace;
        line-height: 16px;
        white-space: pre-wrap;
        overflow-wrap: break-word;
    }

</style>
