<template>
    <v-card elevation="2" class="tourInfoPreview mb-5">
        <v-card-text class="font-weight-medium pb-0">
            {{ name }}
        </v-card-text>
        <v-card-text class="font-italic pt-0 pb-2">
            {{ authorLine }}
        </v-card-text>
        <v-card-text class="py-0">
            {{ teaser }}
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn small color="primary" @click="editInfo()">
                <v-icon>mdi-playlist-edit</v-icon>
                {{ l("Edit Information") }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>

    import { inPreviewLocale } from "../../locales/utils/localeUtils.js";
    import { l, lInPreviewLocale, localize } from "../../utils/LocalizationUtils.js";


    export default {
        props: {
            tour: { type: Object, default: null }
        },
        computed: {
            name() {
                if (this.tour && this.tour.name) {
                    return localize(inPreviewLocale(), this.tour.name);
                }
                return "";
            },
            authorLine() {
                let author = "";
                if (this.tour && this.tour.author) {
                    author = localize(inPreviewLocale(), this.tour.author);
                }
                return lInPreviewLocale("By ${v}", author);
            },
            teaser() {
                if (this.tour && this.tour.teaser) {
                    const localizedTeaser = localize(inPreviewLocale(), this.tour.teaser);
                    return localizedTeaser.length > 100 ? localizedTeaser.substring(0, 100) + "..." : localizedTeaser;
                }
                return "";
            }
        },
        methods: {

            editInfo() {
                this.$router.push("/tours/" + this.tour._id + "/editInfo");
            },

            l

        }
    }
</script>
<style scoped>

    .tourInfoPreview {
        width: 360px;
    }

</style>
