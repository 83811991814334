<template>
    <div class="headerBox">
        <div class="headerImage">
            <img v-if="values.image" class="headerImage" :src="'/assets/' + values.image + '.jpg'">
            <div class="headerImageContent">
                <div class="headerGradient">
                    <div v-if="readDuration" class="headerDuration">
                        {{ readDuration }}
                    </div>
                </div>
                <div class="headerLine">
                    <div />
                </div>
                <div class="headerTitle">
                    {{ values.name }}
                </div>
                <!-- eslint-disable -->
                <div class="headerIntro">{{ values.intro }}</div>
                <!-- eslint-enable -->
            </div>
        </div>
    </div>
</template>
<script>

    import { lInPreviewLocale } from "../../../utils/LocalizationUtils.js";


    export default {
        props: {
            values: { type: Object, default: () => { } }
        },
        computed: {
            readDuration() {
                const readDuration = this.values.readDuration;
                if (typeof readDuration === "undefined" || readDuration === null || readDuration === "" || readDuration <= 0) {
                    return "";
                } else if (readDuration == 1) { // do not ===
                    return lInPreviewLocale("1 minute");
                } else {
                    return lInPreviewLocale("${v} minutes", readDuration);
                }
            }
        }
    }
</script>
<style scoped>

    .headerBox {
        position: relative;
        min-height: 100px;
        background: #212121;
    }

    .headerImage {
        position: relative;
        height: 440px;
        width: 100%;
        padding: 0;
    }

    .headerImage img {
        display: block;
        height: 440px;
        width: 100%;
        object-fit: cover;
    }

    .headerImageContent {
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
    }

    .headerGradient {
        position: relative;
        height: 50px;
        background-image: linear-gradient(to bottom, rgba(33, 33, 33, 0.0), rgba(33, 33, 33, 0.8));
    }

    .headerDuration {
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
        padding: 0 20px 8px;
        font-size: 15px;
        font-style: italic;
        color: #fff;
    }

    .headerLine {
        width: 100%;
        height: 1px;
        padding: 0 10px;
        background: rgba(33, 33, 33, 0.8);
    }

    .headerLine div {
        width: 100%;
        height: 1px;
        background: #fff;
    }

    .headerTitle {
        padding: 16px 20px 0px;
        font-size: 30px;
        font-weight: 500;
        color: #fff;
        background: rgba(33, 33, 33, 0.8);
    }

    .headerIntro {
        padding: 0px 20px 32px;
        font-size: 18px;
        color: #fff;
        background: rgba(33, 33, 33, 0.8);
        white-space: pre-wrap;
    }

</style>