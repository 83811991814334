<template>
    <div v-if="field.visibility(field)" ref="field">
        <v-textarea
            v-if="field.type === 'Textarea' && !field.richText"
            ref="textArea"
            v-model="field.value"
            :label="label"
            :error="field.error"
            :error-messages="field.errorMessages"
            hide-details="auto"
            required
            :class="'textarea' + (field.required ? ' required' : '') + (field.isLanguageField ? ' isLanguageField' : '')"
        />
        <RichTextField
            v-if="field.type === 'Textarea' && field.richText"
            v-model="field"
        />
        <v-text-field
            v-if="field.type === 'Text'"
            ref="textField"
            v-model="field.value"
            :label="label"
            :error="field.error"
            :error-messages="field.errorMessages"
            hide-details="auto"
            required
            :class="'text-input' + (field.required ? ' required' : '') + (field.isLanguageField ? ' isLanguageField' : '')"
            @keyup="submitOnReturn"
        />
        <v-text-field
            v-if="field.type === 'Number'"
            ref="numberField"
            v-model="field.value"
            :label="label"
            :error="field.error"
            :error-messages="field.errorMessages"
            hide-details="auto"
            type="number"
            required
            :class="'text-input' + (field.required ? ' required' : '') + (field.isLanguageField ? ' isLanguageField' : '')"
            @keyup="submitOnReturn"
        />
        <v-text-field
            v-if="field.type === 'Password'"
            v-model="field.value"
            :label="label"
            :error="field.error"
            :error-messages="field.errorMessages"
            hide-details="auto"
            required
            type="password"
            :class="'text-input' + (field.required ? ' required' : '') + (field.isLanguageField ? ' isLanguageField' : '')"
            @keyup="submitOnReturn"
        />
        <v-checkbox
            v-if="field.type === 'Checkbox'"
            v-model="field.value"
            :label="label"
            :error="field.error"
            :error-messages="field.errorMessages"
            :class="'checkbox' + (field.isLanguageField ? ' isLanguageField' : '')"
            color="primary"
            hide-details="auto"
        />
        <v-switch
            v-if="field.type === 'Switch'"
            v-model="field.value"
            :label="label"
            :error="field.error"
            :error-messages="field.errorMessages"
            :class="'switch' + (field.isLanguageField ? ' isLanguageField' : '')"
            color="primary"
        />
        <v-select
            v-if="field.type === 'Select'"
            v-model="field.value"
            :items="field.options"
            :label="label"
            :error="field.error"
            :error-messages="field.errorMessages"
            :class="'text-input' + (field.required ? ' required' : '') + (field.isLanguageField ? ' isLanguageField' : '')"
            hide-details="auto"
        />
        <TimeField
            v-if="field.type === 'Time'"
            v-model="field"
        />
        <MediaUploadField
            v-if="field.type === 'FileUpload'"
            v-model="field"
            :waiting-for-response="waitingForResponse"
            @submitOnReturn="submitOnReturn"
        />
        <ReferenceField
            v-if="field.type === 'Reference'"
            v-model="field"
        />
        <DateField
            v-if="field.type === 'Date'"
            v-model="field"
        />
        <EventField
            v-if="field.type === 'Event'"
            v-model="field"
        />
        <LinkedContentSelector
            v-if="field.type === 'LinkedContentSelector'"
            v-model="field"
        />
        <ImageAlignmentField
            v-if="field.type === 'ImageAlignment'"
            v-model="field"
        />
        <ImageTransformField
            v-if="field.type === 'ImageTransform'"
            v-model="field"
        />
        <ArtPieceInteractionSelection
            v-if="field.type === 'ArtPieceInteractionSelection'"
            v-model="field"
        />
        <TagsField
            v-if="field.type === 'Tags'"
            v-model="field"
        />
        <ArrayInput
            v-if="field.type === 'Array'"
            v-model="field"
            :waiting-for-response="waitingForResponse"
            @scrollTo="scrollTo"
            @submitOnReturn="submitOnReturn"
        />
        <LocalizedField
            v-if="field.type === 'Localized'"
            v-model="field"
            :waiting-for-response="waitingForResponse"
            @scrollTo="scrollTo"
            @submitOnReturn="submitOnReturn"
        />
        <PoiIconHelp
            v-if="field.showPoiIconHelp"
        />
    </div>
</template>
<script>

    import RichTextField from "./RichTextField.vue";
    import TimeField from "./TimeField.vue";
    import MediaUploadField from "./MediaUploadField.vue";
    import ReferenceField from "./ReferenceField.vue";
    import DateField from "./DateField.vue";
    import EventField from "./EventField.vue";
    import LinkedContentSelector from "./LinkedContentSelector.vue";
    import ImageAlignmentField from "./ImageAlignmentField.vue";
    import ImageTransformField from "./ImageTransformField.vue";
    import ArtPieceInteractionSelection from "./ArtPieceInteractionSelection.vue";
    import TagsField from "./TagsField.vue";
    import ArrayInput from "./ArrayInput.vue";
    import LocalizedField from "./LocalizedField.vue";
    import PoiIconHelp from "./PoiIconHelp.vue";
    import { backwardCompatibleL } from "../../../utils/LocalizationUtils.js";


    export default {
        components: {
            RichTextField,
            TimeField,
            MediaUploadField,
            ReferenceField,
            DateField,
            EventField,
            LinkedContentSelector,
            ImageAlignmentField,
            ImageTransformField,
            ArtPieceInteractionSelection,
            TagsField,
            ArrayInput,
            LocalizedField,
            PoiIconHelp
        },
        model: {
            prop: "propField",
            event: "input"
        },
        props: {
            propField: { type: Object, default: null },
            waitingForResponse: { type: Boolean, default: false }
        },
        computed: {
            field: {
                get() {
                    return this.propField;
                },
                set(field) {
                    this.$emit("input", field);
                }
            },
            label() {
                return this.field.label ? backwardCompatibleL(this.field.label) : "";
            }
        },
        watch: {
            "field.value": function () {
                this.field.clearErrors();
                if (this.field.isLanguageField) {
                    this.$emit("clearErrors");
                }
            },
            "field.scrollToMe": function (scrollToMe) {
                if (scrollToMe) {
                    this.scrollTo(this.$refs.field);
                }
            }
        },
        created() {
            Object.defineProperty(this.field, "focusCallback", { // prevents triggering reactivity
                value: () => {
                    this.$nextTick(() => {
                        let element = null;
                        if (this.field.type === "Textarea" && this.$refs.textArea) {
                            element = this.$refs.textArea;
                        } else if (this.field.type === "Text" && this.$refs.textField) {
                            element = this.$refs.textField;
                        } else if (this.field.type === "Number" && this.$refs.numberField) {
                            element = this.$refs.numberField;
                        }
                        if (element) {
                            element.focus();
                        }
                    });
                },
                writeable: false
            });
        },
        methods: {
            submitOnReturn(event) {
                if (event.key === "Enter") {
                    this.$emit("submitOnReturn", event);
                }
            },
            scrollTo(scrollTarget) {
                this.$emit("scrollTo", scrollTarget);
            }
        }
    }

</script>
<style scoped>

    .textarea.isLanguageField {
        margin: 0 !important;
    }

    .text-input {
        max-width: 400px;
        margin: 16px 0;
    }

    .text-input.isLanguageField {
        width: 100%;
        max-width: none !important;
        margin: 0 0 12px !important;
    }

    .checkbox,
    .switch {
        padding: 0;
        margin: 20px 0;
    }

    .checkbox.isLanguageField,
    .switch.isLanguageField {
        height: 36px;
        margin: 12px 12px 0 !important;
    }

</style>