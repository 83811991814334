import store from "../store/index.js";


export function updateLookups(typesToUpdate) {
    return new Promise(async (resolve, reject) => {
        try {

            if (typesToUpdate.includes("pages")) {
                await store.dispatch("pages/update");
            }

            if (typesToUpdate.includes("tags")) {
                await store.dispatch("tags/update");
            }

            if (typesToUpdate.includes("usage")) {
                await store.dispatch("usage/load");
            }
            
            resolve();
        } catch (error) {
            reject(`Could not update lookups: ${error}`);
        }
    });
}