<template>
    <div class="defaultContentView">
        <div>
            <router-link to="/tours/" class="pt-2 pb-4 text-decoration-none" style="display:inline-block">
                <v-icon color="primary">
                    arrow_back
                </v-icon>
                {{ l("Tours") }}
            </router-link>
        </div>
        <h1 v-if="!tourNotFound" class="display-1 mb-5 primary--text">
            <v-icon color="primary" class="titleIcon">
                mdi-sign-direction
            </v-icon>
            {{ tourName }}
        </h1>
        <h1 v-if="tourNotFound" class="display-1 mb-5 primary--text">
            <v-icon color="primary" class="titleIcon">
                mdi-file-question
            </v-icon>
            {{ l("Not found") }}
        </h1>
        <v-progress-linear v-if="loading" indeterminate class="loading mt-5" />
        <PreviewLanguageSelection v-if="!tourNotFound" />
        <div v-if="tour" class="blockContainer">
            <div class="blockContainerBox elevation-3" />
            <TourInfoProperties
                :tour="tour"
                @save="save"
            />
            <InformationPageBlock
                v-for="block in tour.blocks"
                :key="block.uid"
                :block="block"
                :block-type-look-up="blockTypeLookUp"
                :available-block-types="availableBlockTypes"
                @save="save"
                @moveUpBlock="moveUpBlock(block, $event)"
                @moveDownBlock="moveDownBlock(block, $event)"
                @deleteBlock="deleteBlock(block, $event)"
                @addBlock="addBlock"
            />
            <div class="virtualClosingBlock">
                <AddBlockBeforeBox :index="tour.blocks.length" :available-block-types="availableBlockTypes" @addBlock="addBlock" />
            </div>
        </div>
    </div>
</template>
<script>

    import { mapActions } from "vuex";
    import PreviewLanguageSelection from "../../components/globalUiElements/PreviewLanguageSelection.vue";
    import TourInfoProperties from "../../components/tours/TourInfoProperties.vue";
    import InformationPageBlock from "../../components/informationPages/InformationPageBlock.vue";
    import AddBlockBeforeBox from "../../components/informationPages/AddBlockBeforeBox.vue";
    import TourService from "../../services/TourService.js";
    import InformationPageBlocksService from "../../services/InformationPageBlocksService.js";
    import { giveUniqueId, giveUniqueIdsToAll, updateIndicesAndMovability } from "../../utils/ListUtils.js";
    import { deepClone, deepCloneAndReplace } from "../../utils/objectUtils.js";
    import { inContentLocale } from "../../locales/utils/localeUtils.js";
    import { l, localize, setLocalizedValues } from "../../utils/LocalizationUtils.js";

    export default {
        components: {
            PreviewLanguageSelection,
            TourInfoProperties,
            InformationPageBlock,
            AddBlockBeforeBox
        },
        data() {
            return {
                loading: false,
                tour: null,
                tourNotFound: false,
                blockTypeLookUp: {},
                availableBlockTypes: []
            }
        },
        computed: {
            tourName() {
                let tourName = "";
                if (this.tour && this.tour.name) {
                    tourName = localize(inContentLocale(), this.tour.name);
                }
                this.updateSectionTitle(tourName);
                return tourName;
            }
        },
        created() {

            this.loading = true;

            this.updateInformationBlockTypes(() => {

                const action = this.$route.meta.tourInfoEditorAction;
                if (action === "new") {

                    this.tour = {
                        author: {},
                        authorImage: "",
                        authorBiography: "",
                        name: {},
                        teaser: {},
                        image: "",
                        duration: 0,
                        blocks: [],
                        stops: [],
                        hidden: false
                    };

                    setLocalizedValues(this.tour, {
                        name: "Enter title...",
                        teaser: "Enter teaser..."
                    });
                    this.loading = false;
            
                } else if (action === "edit") {

                    const tourId = this.$route.params.tourId;

                    TourService.getTour(tourId, tour => {
                        this.tour = tour;

                        giveUniqueIdsToAll(this.tour.blocks);
                        updateIndicesAndMovability(this.tour.blocks, this);

                        this.loading = false;
                    }, error => {
                        this.tourNotFound = true;
                        this.loading = false;
                    });
                }

            });
             
        },
        methods: {

            updateInformationBlockTypes(callback) {

                InformationPageBlocksService.getInformationPageBlocks(blocks => {

                    this.blockTypeLookUp = {};
                    this.availableBlockTypes = [];

                    blocks.forEach(block => {

                        this.blockTypeLookUp[block.id] = {};
                        const hasSeveralVisibleVersions = block.versions.filter(version => version.isVisibleToCurator).length > 1;

                        block.versions.forEach(version => {
                            version.id = block.id;
                            this.blockTypeLookUp[block.id][version.versionNumber] = version;

                            if (version.isVisibleToCurator) {
                                this.availableBlockTypes.push({
                                    blockTypeId: block.id,
                                    blockTypeVersion: version.versionNumber,
                                    title: this.l(version.title) + (hasSeveralVisibleVersions ? " (v" + version.versionNumber + ")" : ""),
                                    mdiIcon: version.mdiIcon,
                                    parameters: version.parameters
                                });
                            }
                        })
                    });

                    callback();
                });
            },            
            
            save({ editedTour, originalBlock, editedBlock, caller, onSuccess }) {

                if (originalBlock && editedBlock) {
                    editedTour = deepCloneAndReplace(this.tour, originalBlock, editedBlock);
                }

                if (this.tour._id) {
                    // existing tour
                    TourService.updateTour(editedTour, response => {
                        if (response.errors && response.errors.length > 0) {
                            if (caller) {
                                caller.assignErrors(response.errors);
                            } else {
                                this.$root.$emit("error", "Validation error: " + response.errors[0].message);
                            }
                            
                        } else {
                            if (onSuccess) {
                                onSuccess();
                            }
                            this.tour = editedTour;
                            updateIndicesAndMovability(this.tour.blocks, this);
                            this.$root.$emit("success", this.l("Changes to tour \"${v}\" saved.", this.tourName));
                        }
                    });

                } else {
                    // new tour
                    TourService.insertTour(editedTour, response => {
                        if (response.errors && response.errors.length > 0) {
                            if (caller) {
                                caller.assignErrors(response.errors);
                            } else {
                                this.$root.$emit("error", "Validation error: " + response.errors[0].message);
                            }

                        } else if (response._id) {
                            editedTour._id = response._id;
                            if (onSuccess) {
                                onSuccess();
                            }
                            this.tour = editedTour;
                            updateIndicesAndMovability(this.tour.blocks, this);
                            this.$root.$emit("success", this.l("Changes to tour \"${v}\" saved.", this.tourName));

                        } else {
                            this.$root.$emit("error", "Error: Did not generate entry ID");
                        }
                    });
                }
            },

            moveUpBlock(block, onSuccess) {
                if (block.index > 0) {
                    const editedTour = deepClone(this.tour); 
                    editedTour.blocks.splice(block.index, 1);
                    editedTour.blocks.splice(block.index - 1, 0, block);
                    this.save({ editedTour, onSuccess });
                }
            },

            moveDownBlock(block, onSuccess) {
                if (block.index < this.tour.blocks.length - 1) {
                    const editedTour = deepClone(this.tour); 
                    editedTour.blocks.splice(block.index, 1);
                    editedTour.blocks.splice(block.index + 1, 0, block);
                    this.save({ editedTour, onSuccess });
                }
            },
            
            deleteBlock(block, onSuccess) {
                this.$root.$emit(
                    "confirm",
                    this.l("Delete block"),
                    this.l("Do you really want to delete this block?"),
                    this.l("Delete"),
                    yes => {
                        if (yes) {
                            const editedTour = deepClone(this.tour); 
                            editedTour.blocks.splice(block.index, 1);
                            this.save({ editedTour, onSuccess });
                        } else {
                            onSuccess();
                        }
                    }
                );
            },

            addBlock({ blockType, index, onSuccess }) {

                const newBlock = {
                    blockTypeId: blockType.blockTypeId,
                    blockTypeVersion: blockType.blockTypeVersion,
                    parameters: []
                };
                blockType.parameters.forEach(parameter => {
                    newBlock.parameters.push({
                        key: parameter.key,
                        value: parameter.defaultValue || null,
                    })
                });
                giveUniqueId(newBlock);

                const editedTour = deepClone(this.tour); 
                editedTour.blocks.splice(index, 0, newBlock);
                this.save({ editedTour, onSuccess });
            },

            l,

            ...mapActions({
                updateSectionTitle: "platform/updateSectionTitle"
            })
        }
    }
</script>
<style scoped>

    .loading {
        width: 480px;
    }

    .blockContainer {
        position: relative;
        margin-bottom: 400px;
    }

    .blockContainerBox {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 480px;
        border: 1px solid #666;
        border-radius: 2px;
        z-index: 2;
    }

    .virtualClosingBlock {
        position: relative;
        height: 0px;
    }

</style>
