<template>
    <WindowedForm v-model="form" />
</template>
<script>

    import { mapActions, mapGetters } from "vuex";
    import UserService from "../../services/UserService.js";
    import WindowedForm from "../globalUiElements/WindowedForm.vue";
    import { getCMSLocaleOptions, getAppLocaleOptions } from "../../locales/utils/localeUtils.js";
    import { createForm } from "../../utils/FormUtils.js";
    import { l } from "../../utils/LocalizationUtils.js";


    export default {
        components: {
            WindowedForm
        },
        props: {
            entry : { type: Object, default: null }
        },
        data() {
            return {
                form: createForm({

                    title: {
                        label: "Editing ${ v.name }",
                        entry: this.entry
                    },

                    action: UserService.updateUser,

                    fieldDefs: [
                        {
                            key: "_id",
                            type: "Hidden",
                            value: this.entry._id
                        },
                        {
                            key: "name",
                            type: "Text",
                            label: "Name",
                            value: this.entry.name,
                            required: true
                        },
                        {
                            key: "email",
                            type: "Text",
                            label: "Email Address",
                            value: this.entry.email,
                            required: true
                        },
                        {
                            key: "password",
                            type: "Password",
                            label: "Change password"
                        },
                        {
                            key: "language",
                            type: "Select",
                            label: "Interface language in CMS",
                            value: this.entry.language,
                            options: getCMSLocaleOptions(),
                            required: true
                        },
                        {
                            key: "contentLocale",
                            type: "Select",
                            label: "Content language in CMS",
                            value: this.entry.contentLocale,
                            options: getAppLocaleOptions(),
                            required: true,
                        },
                        {
                            uiElement: "Header",
                            text: "Access Rights"
                        },
                        {
                            key: "exhibitions",
                            type: "Checkbox",
                            label: "Exhibitions",
                            value: this.entry.rights.exhibitions
                        },
                        {
                            key: "arContent",
                            type: "Checkbox",
                            label: "AR Content",
                            value: this.entry.rights.arContent
                        },
                        {
                            key: "informationDatabase",
                            type: "Checkbox",
                            label: "Information Database",
                            value: this.entry.rights.informationDatabase
                        },
                        {
                            key: "analytics",
                            type: "Checkbox",
                            label: "Analytics",
                            value: this.entry.rights.analytics
                        },
                        {
                            key: "users",
                            type: "Checkbox",
                            label: "Users",
                            value: this.entry.rights.users
                        },
                        {
                            key: "system",
                            type: "Checkbox",
                            label: "System",
                            value: this.entry.rights.system
                        }
                    ],

                    submitLabel: "Save",

                    submitted: entry => {
                        this.$root.$emit("success", l("Changes for user \"${v.name}\" saved.", entry));
                        this.$emit("editedEntry");
                        if (this.user._id === entry._id) {
                            this.reloadUser();
                        }
                    },

                    cancelled: () => {
                        this.$emit("cancel");
                    }
                })
            }
        },
        computed: {
            ...mapGetters({
                user: "user/getUser"
            })
        },
        methods: {
            ...mapActions({
                reloadUser: "user/reloadUser"
            })
        }
    }
</script>