<template>
    <v-container>
        <v-row v-if="!!exhibitionAndDateRange" align="center" justify="start">
            <v-col class="pl-0">
                <v-select
                    v-if="exhibitions.length > 0"
                    v-model="exhibitionId"
                    :items="exhibitions"
                    :label="l('Exhibition')"
                />
            </v-col>
            <v-col>
                <v-menu
                    v-model="showStartDatePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                >
                    <template #activator="{ on }">
                        <v-text-field
                            :value="formattedStartDate"
                            :label="l('Start Date')"
                            prepend-icon="event"
                            readonly
                            :error="!!(startDateError || bothDatesError)"
                            :error-messages="startDateError ? [ l(startDateError.message) ] : (bothDatesError ? [ l(bothDatesError.message) ] : [])"
                            v-on="on"
                        />
                    </template>
                    <v-date-picker
                        :value="startISODateString"
                        no-title
                        scrollable
                        width="320"
                        @input="setStartDate"
                    />
                </v-menu>
            </v-col>
            <v-col>
                <v-menu
                    v-model="showEndDatePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                >
                    <template #activator="{ on }">
                        <v-text-field
                            :value="formattedEndDate"
                            :label="l('End Date')"
                            prepend-icon="event"
                            readonly
                            :error="!!(endDateError || bothDatesError)"
                            :error-messages="endDateError ? [ l(endDateError.message) ] : []"
                            v-on="on"
                        />
                    </template>
                    <v-date-picker
                        :value="endISODateString"
                        no-title
                        scrollable
                        width="320"
                        @input="setEndDate"
                    />
                </v-menu>
            </v-col>
            <v-col>
                <v-btn color="primary" :loading="loading" @click="apply()">
                    {{ l("Update") }}
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

    import ExhibitionService from "../../services/ExhibitionService.js";
    import { dateStringFromDateObject } from "../../utils/StringUtils.js";
    import { isValidDate, toISODateString, setTime0000, setTime2359 } from "../../utils/analyticsUtils.js";
    import { inCMSLocale } from "../../locales/utils/localeUtils.js";
    import { l } from "../../utils/LocalizationUtils.js";

    export default {
        model: {
            prop: "exhibitionAndDateRange",
            event: "apply",
        },
        props: {
            exhibitionAndDateRange: { type: Object, default: null },
        },
        data () {
            return {
                loading: false,

                exhibitions: [],
                exhibitionId: "",

                tentativeStartDate: null,
                showStartDatePicker: false,
                startDateError: null,

                tentativeEndDate: null,
                showEndDatePicker: false,
                endDateError: null,

                bothDatesError: null,
            }
        },
        computed: {
            startISODateString() {
                return toISODateString(this.tentativeStartDate);           
            },
            formattedStartDate() {
                return dateStringFromDateObject(inCMSLocale(), this.tentativeStartDate);          
            },
            endISODateString() {
                return toISODateString(this.tentativeEndDate);   
            },
            formattedEndDate() {
                return dateStringFromDateObject(inCMSLocale(), this.tentativeEndDate);
            },
        },
        watch: {
            exhibitionAndDateRange() {
                if(this.exhibitionAndDateRange) {
                    if(this.exhibitionAndDateRange.exhibitionId) {
                        this.exhibitionId = this.exhibitionAndDateRange.exhibitionId;
                    }
                    if(isValidDate(this.exhibitionAndDateRange.startDate)) {
                        this.tentativeStartDate = this.exhibitionAndDateRange.startDate;
                    }
                    if(isValidDate(this.exhibitionAndDateRange.endDate)) {
                        this.tentativeEndDate = this.exhibitionAndDateRange.endDate;
                    }
                }
            },
            tentativeStartDate() {
                this.bothDatesError = null;
                this.startDateError = null;
            },
            tentativeEndDate() {
                this.bothDatesError = null;
                this.endDateError = null;
            },
        },
        mounted() {

            this.loading = true;

            ExhibitionService.getExhibitions(list => {

                list.forEach(exhibition => {
                    this.exhibitions.push({
                        text: exhibition.title,
                        value: exhibition._id
                    });
                });
                this.loading = false;

                if(!this.exhibitionAndDateRange.exhibitionId && list.length > 0) {
                    this.exhibitionId = list[0]._id;
                    this.apply();
                }
            });
        },
        methods: {

            setStartDate(startISODateString) {
                const startDateParsed = new Date(startISODateString);
                if(isNaN(startDateParsed)) {
                    this.startDateError = { message: "Invalid date" };
                } else {
                    this.tentativeStartDate = setTime0000(startDateParsed);
                }
            },

            setEndDate(endISODateString) {
                const endDateParsed = new Date(endISODateString);
                if(isNaN(endDateParsed)) {
                    this.endDateError = { message: "Invalid date" };
                } else {
                    this.tentativeEndDate = setTime2359(endDateParsed);
                }
            },

            apply() {

                if(this.loading) {
                    return;
                }

                if(this.tentativeStartDate > this.tentativeEndDate) {
                    this.bothDatesError = { message: "The start date is after the end date." };
                    return;
                }

                if(this.tentativeStartDate.getTime() == this.tentativeEndDate.getTime()) {
                    this.bothDatesError = { message: "The start and end date are identical." };
                    return;
                }

                this.$emit("apply", {
                    exhibitionId: this.exhibitionId,
                    startDate: this.tentativeStartDate,
                    endDate: this.tentativeEndDate
                });
            },

            l,

        }
    }
</script>
