import axios from "axios";
import { triggerError } from "../utils/alerts.js";


const url = "/api/log/";


class LogService {

    static getLogLines(numberOfLines, callback) {
        axios.get(`${url}${numberOfLines}`).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }

}

export default LogService;