<template>
    <v-dialog v-model="show" persistent max-width="220">
        <v-card class="loadingCard">
            <div class="loadingIconBox">
                <v-progress-circular indeterminate color="primary" size="60" />
            </div>
            <v-card-text class="loadingLabel">
                {{ label }}
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>

    import { mapGetters } from "vuex";


    export default {
        computed: {
            ...mapGetters({
                show: "platform/getShowWaitingAnimation",
                label: "platform/getWaitingAnimationLabel"
            })
        }
    }

</script>
<style scoped>

    .loadingCard {
        width: 220px;
        height: 220px;
        overflow: hidden;
    }

    .loadingIconBox {
        width: 60px;
        padding: 70px 80px 20px;
    }

    .loadingLabel {
        width: 220px;
        text-align: center;
    }
</style>