<template>
    <div>
        <div class="text-subtitle-1 mb-3">
            {{ l("Select an exhibition:") }}
        </div>
        <v-data-table
            hide-default-footer
            :items="list"
            :sort-by="sortBy"
            :items-per-page="itemsPerPage"
            :footer-props="pagination"
            :loading="loading"
            :no-data-text="l('No entries.')"
        >
            <template #item="row">
                <tr>
                    <td class="clickable pr-0" style="width:40px" @click="edit(row.item)">
                        <v-icon>
                            account_balance
                        </v-icon>
                    </td>
                    <td class="clickable" @click="openHomeContentEditor(row.item)">
                        {{ row.item.title }}
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>
<script>

    import ExhibitionService from "../../services/ExhibitionService.js";
    import ListUtils from "../../utils/ListUtils.js";


    export default ListUtils.setupList({
        headers: [
            { text: "", sortable: false },
            { text: "Title", value: "title" }
        ],
        sortBy: "title",
        itemsPerPage: -1,
        update() {
            this.showLoading();
            ExhibitionService.getExhibitions(list => {
                this.list = list;
                this.hideLoading();
            });
        },
        methods: {
            openHomeContentEditor(exhibition) {
                this.$router.push("/home-content/" + exhibition._id);
            }
        }
    });
</script>
<style scoped>

    .clickable {
        cursor: pointer;
    }

</style>