<template>
    <div>
        <h1 v-if="title" class="display-1 mb-3 primary--text">
            {{ title }}
        </h1>
        <div class="formFields">
            <FormFields
                v-model="form.fields"
                :waiting-for-response="form.waitingForResponse"
                @scrollTo="scrollTo"
                @submit="submit"
            />
        </div>
        <v-btn
            :disabled="!form.submitButtonEnabled || form.waitingForResponse"
            :loading="form.waitingForResponse"
            color="primary"
            @click="submit"
        >
            {{ submitLabel }}
        </v-btn>
    </div>
</template>
<script>

    import FormFields from "./inputFields/FormFields.vue";
    import { backwardCompatibleL } from "../../utils/LocalizationUtils.js";

    export default {
        components: {
            FormFields
        },
        model: {
            prop: "propForm",
            event: "input"
        },
        props: {
            propForm: { type: Object, default: null },
        },
        computed: {
            form() {
                return this.propForm;
            },
            title() {
                if (this.form.title) {
                    if (this.form.title.label) {
                        return backwardCompatibleL(this.form.title.label, this.form.title.entry);
                    } else {
                        return backwardCompatibleL(this.form.title);
                    }
                }
                return "";
            },
            submitLabel() {
                return this.form.submitLabel ? backwardCompatibleL(this.form.submitLabel) : "";
            }
        },
        watch: {
            form: {
                handler(form) {
                    form.onChange();
                    this.$emit("input", form);
                },
                deep: true
            }
        },
        methods: {
            submit() {
                this.form.submit();
            },
            scrollTo(scrollTarget) {
                if (scrollTarget) {
                    this.$vuetify.goTo(scrollTarget, {
                        duration: 300,
                        easing: "easeInOutCubic"
                    });
                }
            }
        }
    }
</script>
<style scoped>

    .formFields {
        max-width: 800px
    }

</style>
