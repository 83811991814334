<template>
    <div>
        <h2 class="title primary--text">
            {{ l("Languages") }}
        </h2>
        <LanguageList :reload-trigger="listReloadTrigger" @add="openCreateForm" @edit="openEditForm" @delete="deletedEntry" @editedEntry="editedEntry" />
        <LocalizationFileForm />
        <AddLanguage v-if="showCreateForm" @cancel="closeCreateForm" @createdEntry="createdEntry" />
        <EditLanguage v-if="showEditForm" :entry="editingEntry" @cancel="closeEditForm" @editedEntry="editedEntry" />
    </div>
</template>
<script>
    import { mapActions } from "vuex";


    import LanguageList from "./LanguageList.vue";
    import LocalizationFileForm from "./LocalizationFileForm.vue";
    import AddLanguage from "./AddLanguage.vue";
    import EditLanguage from "./EditLanguage.vue";

    export default {
        components: {
            LanguageList,
            LocalizationFileForm,
            AddLanguage,
            EditLanguage
        },
        props: {
            reloadTrigger: { type: Number, default: 0 },
        },
        data() {
            return {
                listReloadTrigger: 0,
                showCreateForm: false,
                showEditForm: false,
                editingEntry: null
            };
        },
        watch: {
            reloadTrigger() {
                this.listReloadTrigger++;
            }
        },
        methods: {
            openCreateForm() {
                this.showCreateForm = true;
            },
            closeCreateForm() {
                this.showCreateForm = false;
            },
            createdEntry() {
                this.closeCreateForm();
                this.listReloadTrigger++;
                this.reloadAppLanguages();
            },
            openEditForm(entry) {
                this.editingEntry = entry;
                this.showEditForm = true;
            },
            closeEditForm() {
                this.showEditForm = false;
                this.editingEntry = null;
            },
            editedEntry() {
                this.closeEditForm();
                this.listReloadTrigger++;
                this.reloadAppLanguages();
            },
            deletedEntry() {
                this.reloadAppLanguages();
            },
            l(token, values) {
                return this.$root.l(token, values);
            },
            ...mapActions({
                reloadAppLanguages: "locales/loadAppLanguages"
            })
        }
    }
</script>