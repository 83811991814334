<template>
    <div class="defaultContentView">
        <div>
            <router-link to="/home-content/" class="pt-2 pb-4 text-decoration-none" style="display:inline-block">
                <v-icon color="primary">
                    arrow_back
                </v-icon>
                {{ l("Home Content") }}
            </router-link>
        </div>
        <h1 v-if="!exhibitionNotFound" class="display-1 mb-5 primary--text">
            <v-icon color="primary" class="titleIcon">
                mdi-bank
            </v-icon>
            {{ exhibitionTitle }}
        </h1>
        <h1 v-if="exhibitionNotFound" class="display-1 mb-5 primary--text">
            <v-icon color="primary" class="titleIcon">
                mdi-file-question
            </v-icon>
            {{ l("Not found") }}
        </h1>
        <v-progress-linear v-if="loading" indeterminate class="loading mt-5" />
        <PreviewLanguageSelection v-if="!exhibitionNotFound" />
        <div v-if="homeContent" class="homeContentContainer mt-10">
            <div class="homeContentContainerBox elevation-3" />
            <div v-for="element in homeContent" :key="element.uid">
                <AddHomeContentElementBeforeBox
                    :index="element.index"
                    :first-element="element.index === 0"
                    :last-element="false"
                    :available-element-types="availableElementTypes"
                    @addElement="addElement"
                />
                <HomeContentElement               
                    :element="element"
                    :element-type-look-up="elementTypeLookUp"
                    @save="save"
                    @moveUpElement="moveUpElement(element, $event)"
                    @moveDownElement="moveDownElement(element, $event)"
                    @deleteElement="deleteElement(element, $event)"
                    @addElement="addElement"
                />
            </div>
            <AddHomeContentElementBeforeBox
                :index="homeContent.length"
                :first-element="homeContent.length === 0"
                :last-element="true"
                :available-element-types="availableElementTypes"
                @addElement="addElement"
            />
        </div>
    </div>
</template>
<script>

    import { mapActions } from "vuex";
    import PreviewLanguageSelection from "../../components/globalUiElements/PreviewLanguageSelection.vue";
    import HomeContentElement from "../../components/homeContent/HomeContentElement.vue";
    import AddHomeContentElementBeforeBox from "../../components/homeContent/AddHomeContentElementBeforeBox.vue";
    import ExhibitionService from "../../services/ExhibitionService.js";
    import HomeContentService from "../../services/HomeContentService.js";
    import { giveUniqueId, giveUniqueIdsToAll, updateIndicesAndMovability } from "../../utils/ListUtils.js";
    import { deepClone, deepCloneAndReplace } from "../../utils/objectUtils.js";
    import { l } from "../../utils/LocalizationUtils.js";

    export default {
        components: {
            PreviewLanguageSelection,
            HomeContentElement,
            AddHomeContentElementBeforeBox
        },
        data() {
            return {
                loading: false,
                exhibition: null,
                homeContent: [],
                exhibitionNotFound: false
            }
        },
        computed: {
            exhibitionTitle() {
                let exhibitionTitle = "";
                if (this.exhibition) {
                    exhibitionTitle = this.exhibition.title;
                } else if (this.exhibitionNotFound) {
                    exhibitionTitle = l("Not found");
                }
                this.updateSectionTitle(l("Home Content") + " - " + exhibitionTitle);
                return exhibitionTitle;
            },
            availableElementTypes() {
                return [
                    {
                        type: "tour",
                        title: "Tour",
                        mdiIcon: "mdi-sign-direction",
                        parameters: [
                            {
                                key: "id",
                                type: "Tour",
                                label: "Tour",
                                required: false,
                                localized: false
                            }
                        ]
                    },
                    {
                        type: "topic",
                        title: "Topic",
                        mdiIcon: "mdi-post-outline",
                        parameters: [
                            {
                                key: "id",
                                type: "Topic",
                                label: "Topic",
                                required: false,
                                localized: false
                            }
                        ]
                    }
                ];
            },
            elementTypeLookUp() {
                const elementTypeLookUp = {};
                if (Array.isArray(this.availableElementTypes)) {
                    this.availableElementTypes.forEach(elementType => {
                        elementTypeLookUp[elementType.type] = elementType;
                    });
                }
                return elementTypeLookUp;
            }
        },
        created() {

            this.loading = true;

            const exhibitionId = this.$route.params.exhibitionId;

            ExhibitionService.getExhibition(exhibitionId, exhibition => {
                this.exhibition = exhibition;

                HomeContentService.getHomeContent(exhibition._id, homeContent => {

                    if (Array.isArray(homeContent)) {
                        this.homeContent = homeContent;
                        giveUniqueIdsToAll(this.homeContent);
                        updateIndicesAndMovability(this.homeContent, this);
                    } else {
                        this.exhibitionNotFound = true;
                    }
                    this.loading = false;
                });
            }, error => {
                this.exhibitionNotFound = true;
                this.loading = false;
            });
        },
        methods: {       

            save({ editedHomeContent, originalElement, editedElement, caller, onSuccess }) {

                if (originalElement && editedElement) {
                    editedHomeContent = deepCloneAndReplace(this.homeContent, originalElement, editedElement);
                }

                if (this.exhibition._id) {
                    
                    HomeContentService.updateHomeContent(this.exhibition._id, editedHomeContent, response => {
                        if (response.errors && response.errors.length > 0) {
                            if (caller) {
                                caller.assignErrors(response.errors);
                            } else {
                                this.$root.$emit("error", "Validation error: " + response.errors[0].message);
                            }

                        } else {
                            if (onSuccess) {
                                onSuccess();
                            }
                            this.homeContent = editedHomeContent;
                            updateIndicesAndMovability(this.homeContent, this);
                            this.$root.$emit("success", this.l("Changes to home content for \"${v}\" saved.", this.exhibitionTitle));
                        }
                    });

                }
            },

            moveUpElement(element, onSuccess) {
                if (element.index > 0) {
                    const editedHomeContent = deepClone(this.homeContent);
                    editedHomeContent.splice(element.index, 1);
                    editedHomeContent.splice(element.index - 1, 0, element);
                    this.save({ editedHomeContent, onSuccess });
                }
            },

            moveDownElement(element, onSuccess) {
                if (element.index < this.homeContent.length - 1) {
                    const editedHomeContent = deepClone(this.homeContent);
                    editedHomeContent.splice(element.index, 1);
                    editedHomeContent.splice(element.index + 1, 0, element);
                    this.save({ editedHomeContent, onSuccess });
                }
            },

            deleteElement(element, onSuccess) {
                this.$root.$emit(
                    "confirm",
                    this.l("Delete Element"),
                    this.l("Do you really want to delete this element?"),
                    this.l("Delete"),
                    yes => {
                        if (yes) {
                            const editedHomeContent = deepClone(this.homeContent);
                            editedHomeContent.splice(element.index, 1);
                            this.save({ editedHomeContent, onSuccess });
                        } else {
                            onSuccess();
                        }
                    }
                );
            },

            addElement({ elementType, index, onSuccess }) {
                const newElement = {
                    type: elementType.type,
                    parameters: [],
                };
                elementType.parameters.forEach(parameter => {
                    newElement.parameters.push({
                        key: parameter.key,
                        value: parameter.defaultValue || null,
                    })
                });
                giveUniqueId(newElement);

                const editedHomeContent = deepClone(this.homeContent);
                editedHomeContent.splice(index, 0, newElement);
                this.save({ editedHomeContent, onSuccess });
            },

            l,

            ...mapActions({
                updateSectionTitle: "platform/updateSectionTitle"
            })
        }
    }
</script>
<style scoped>

    .loading {
        width: 480px;
    }

    .languageSelection {
        width: 480px;
    }

    .homeContentContainer {
        position: relative;
        margin-bottom: 400px;
    }

    .homeContentContainerBox {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 480px;
        border: 1px solid #666;
        border-radius: 2px;
        z-index: 2;
    }

    .virtualClosingHomeElement {
        position: relative;
        height: 0px;
    }

</style>
