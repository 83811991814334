<template>
    <h1 class="display-1 primary--text mb-5">
        <v-icon color="primary" class="titleIcon">
            {{ icon }}
        </v-icon>
        {{ title }}
    </h1>
</template>
<script>

    export default {
        props: {
            title: { type: String, default: "" },
            icon: { type: String, default: "" },
        },
    }

</script>
