<template>
    <div :class="'addBeforeStop' + (firstStop && lastStop ? ' onlyStop' : '')">
        <a class="addBeforeStopButton" @click="openSelectionBox">
            <div class="addBeforeStopDot primary" />
            <div v-if="lastStop && !firstStop" class="addBeforeStopLastEdge primary" />
            <div v-if="firstStop && !lastStop" class="addBeforeStopFirstEdge primary" />
            <div v-if="!showSelectionBox" class="addBeforeStopArrow">
                <v-icon class="addBeforeStopArrowIcon primary--text">
                    mdi-chevron-left
                </v-icon>
            </div>
            <div v-if="!showSelectionBox" class="addBeforeStopBox primary">
                <v-icon class="addBeforeStopBoxIcon">
                    mdi-plus
                </v-icon>
                <div class="addBeforeStopLabel text-button">
                    {{ l("Add Stop") }}
                </div>
                <div class="addBeforeStopBoxCover" />
            </div>
        </a>
        <v-overlay z-index="9" :value="showSelectionBox" @click.native="closeSelectionBox" />
        <transition name="slide-x-transition">
            <div v-if="showSelectionBox" class="selectionBox elevation-5" :style="{ top: selectionBoxTop, height: selectionBoxHeight }">
                <v-icon x-large class="selectionBoxArrow" :style="{ top: selectionBoxArrowTop }">
                    mdi-chevron-left
                </v-icon>
                <div class="selectionBoxButtonsContainer">
                    <v-btn
                        v-for="stopType in availableStopTypes"
                        :key="stopType.title"
                        :disabled="saving !== null && saving !== stopType"
                        :loading="saving === stopType"
                        class="selectionBoxButton subtitle-1"
                        text
                        color="primary"
                        @click="addStop(stopType)"
                    >
                        <v-icon left>
                            {{ stopType.mdiIcon }}
                        </v-icon>
                        {{ stopType.title }}
                    </v-btn>
                    <v-btn
                        :disabled="saving !== null"
                        class="selectionBoxButton subtitle-1"
                        text
                        color="error"
                        @click="closeSelectionBox"
                    >
                        <v-icon left>
                            mdi-close
                        </v-icon>
                        {{ l("Cancel") }}
                    </v-btn>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>

    import { l } from "../../utils/LocalizationUtils.js";

    export default {
        props: {
            index: { type: Number, default: 0 },
            firstStop: { type: Boolean, default: false },
            lastStop: { type: Boolean, default: false },
            availableStopTypes: { type: Array, default: () => [] }
        },
        data() {
            return {
                showSelectionBox: false,
                saving: null,
                selectionBoxTop: "-8px",
                selectionBoxHeight: "40px",
                selectionBoxArrowTop: "0px",
            }
        },
        watch: {
            availableStopTypes() {
                this.updateSize();
            }
        },
        methods: {
            openSelectionBox() {
                this.showSelectionBox = true;
                this.saving = null;
                this.updateSize();
            },
            closeSelectionBox() {
                this.showSelectionBox = false;
                this.saving = null;
            },
            addStop(stopType) {
                this.saving = stopType;
                this.$emit("addStop", {
                    index: this.index,
                    stopType,
                    onSuccess: () => {
                        this.closeSelectionBox();
                    }
                });
            },
            updateSize() {
                const numberOfButtons = this.availableStopTypes.length + 1; // + cancel button
                const height = (2 + (38 * numberOfButtons)); // 36 per button + 2 margin
                this.selectionBoxTop = "-" + ((height / 2) - 16) + "px";
                this.selectionBoxHeight = height + "px";
                this.selectionBoxArrowTop = ((height / 2) - 20) + "px";
            },
            l
        }
    }
</script>
<style scoped>

    .addBeforeStop {
        position: absolute;
        top: -16px;
        left: 0px;
        width: fit-content;
        height: 32px;
    }

    .addBeforeStopButton {
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 3;
    }

    .addBeforeStopDot {
        display: none;
    }

    .addBeforeStop:hover .addBeforeStopDot {
        display: block !important;
        position: absolute;
        left: 29px;
        top: 10px;
        width: 12px;
        height: 12px;
        border-radius: 6px;
    }

    .addBeforeStopLastEdge {
        display: none;
    }

    .addBeforeStop:hover .addBeforeStopLastEdge {
        display: block !important;
        position: absolute;
        top: -5px;
        left: 33px;
        width: 4px;
        height: 22px;
    }

    .addBeforeStopFirstEdge {
        display: none;
    }

    .addBeforeStop:hover .addBeforeStopFirstEdge {
        display: block !important;
        position: absolute;
        top: 16px;
        left: 33px;
        width: 4px;
        height: 22px;
    }

    .addBeforeStopArrow {
        display: none;
    }

    .addBeforeStop:hover .addBeforeStopArrow {
        display: block !important;
        position: absolute;
        left: 42px;
        top: 3px;
        height: 24px;
        width: 24px;
    }

    .addBeforeStopArrowIcon {
        width: 24px;
        height: 24px;
    }

    .addBeforeStopBox {
        display: block;
        position: absolute;
        left: 68px;
        top: 0px;
        height: 32px;
        border-radius: 2px;
        padding: 2px 8px 2px 28px;
    }

    .addBeforeStop:hover .addBeforeStopBox,
    .addBeforeStop.onlyStop .addBeforeStopBox {
        box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12) !important;
    }

    .addBeforeStopBoxIcon {
        display: block !important;
        position: absolute;
        width: 24px;
        height: 24px;
        top: 2px;
        left: 5px;
        color: #fff;
    }

    .addBeforeStopLabel {
        display: inline-block !important;
        line-height: 30px !important;
        height: 28px;
        white-space: nowrap;
        color: #fff;
        padding-left: 4px;
    }

    .addBeforeStopBoxCover {
        display: block;
        position: absolute;
        left: -24px;
        right: -3px;
        top: -3px;
        bottom: -3px;
        background: #fff;
        z-index: 4;
    }

    .addBeforeStop:hover .addBeforeStopBoxCover,
    .addBeforeStop.onlyStop .addBeforeStopBoxCover {
        display: none !important;
    }

    .selectionBox {
        position: absolute;
        left: 68px;
        width: 334px;
        background: #fff;
        z-index: 10;
        border-radius: 5px;
    }

    .selectionBoxArrow {
        position: absolute;
        left: -5px;
    }

    .selectionBoxButtonsContainer {
        position: absolute;
        left: 32px;
        top: 0;
        right: 0;
        bottom: 0;
    }

    .selectionBoxButton {
        width: 300px;
        height: 36px;
        margin: 2px 2px 0 0;
        text-transform: none !important;
        justify-content: flex-start;
    }

</style>