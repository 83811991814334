
import { l, tryGetTokenOverride, selectLanguage } from "./localization.js";


// File management translations
export const files = {


    
    fileStorageSize(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "fileStorageSize");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "File storage size";
            case "de": return "Dateispeichergrösse";
            default: return `[fileStorageSize: ${language} missing]`;
        }
    
    },
            

    
    fileSizeKB(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "fileSizeKB");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return "KB";
            case "de": return "KB";
            case "fr": return "Ko";
            default: return `[fileSizeKB: ${language} missing]`;
        }
    
    },
            

    
    fileSizeMB(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "fileSizeMB");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return "MB";
            case "de": return "MB";
            case "fr": return "Mo";
            default: return `[fileSizeMB: ${language} missing]`;
        }
    
    },
            

    
    fileSizeGB(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "fileSizeGB");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return "GB";
            case "de": return "GB";
            case "fr": return "Go";
            default: return `[fileSizeGB: ${language} missing]`;
        }
    
    },
            
};
