<template>
    <WindowedForm v-model="form" />
</template>
<script>

    import LanguageService from "../../services/LanguageService.js";
    import WindowedForm from "../globalUiElements/WindowedForm.vue";
    import { createForm } from "../../utils/FormUtils.js";
    import { l } from "../../utils/LocalizationUtils.js";
    import { getAppLocaleOptions } from "../../locales/utils/localeUtils.js";


    export default {
        components: {
            WindowedForm
        },
        data() {
            return {
                form: createForm({

                    title: "Add Language",

                    action: LanguageService.insertLanguage,

                    fieldDefs: [
                        {
                            key: "ietfLanguageTag",
                            type: "Text",
                            label: "IETF language tag",
                            required: true,
                        },
                        {
                            key: "name",
                            type: "Text",
                            label: "Name",
                            required: true,
                        },
                        {
                            key: "showInCMS",
                            type: "Checkbox",
                            label: "Show in CMS",
                        },
                        {
                            key: "showInApp",
                            type: "Checkbox",
                            label: "Show in App",
                        },
                        {
                            key: "translationsMandatory",
                            type: "Checkbox",
                            label: "Content has to be translated into this locale",
                        },
                        {
                            key: "fallbackLocale",
                            type: "Select",
                            label: "Fallback locale if translations are missing",
                            options: [
                                { value: null, text: "No fallback (non-translated elements are hidden)" },
                                ...getAppLocaleOptions(),
                            ],
                            value: null,
                        },
                    ],

                    submitLabel: "Add",

                    submitted: entry => {
                        this.$root.$emit("success", l("Language \"${v.name}\" added.", entry));
                        this.$emit("createdEntry");
                    },

                    cancelled: () => {
                        this.$emit("cancel");
                    }
                })
            }
        }
    }
</script>