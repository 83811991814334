<template>
    <v-fade-transition>
        <v-overlay
            v-if="show"
            z-index="200"
            opacity="0.8"
        >
            <div
                v-if="show"
                class="lightboxContainer"
            >
                <img
                    v-if="image"
                    :src="image"
                    class="image elevation-15"
                    @click="close"
                >
                <div class="closeButton elevation-7" @click="close">
                    <v-icon size="24">
                        mdi-close
                    </v-icon>
                </div>
            </div>
        </v-overlay>
    </v-fade-transition>
</template>
<script>

    import { mapActions, mapGetters } from "vuex";


    export default {
        computed: {
            ...mapGetters({
                show: "lightbox/getVisibility",
                image: "lightbox/getImage",
            }),
        },
        methods: {
            ...mapActions({
                close: "lightbox/close"
            }),
        }
    }

</script>
<style scoped>

    .lightboxContainer {
        position: relative;
    }

    .image {
        max-width: calc(100vw - 100px);
        max-height: calc(100vh - 100px);
    }

    .closeButton {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -20px;
        right: -20px;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        border: 2px solid #fff !important;
        background: var(--v-primary-base);
        cursor: pointer;
    }

    .closeButton:hover {
        background: var(--v-primary-lighten1);
    }

    .closeButton:active {
        top: -19px;
    }
    
</style>