import { v4 as uuidv4 } from 'uuid';
import { backwardCompatibleL } from "./LocalizationUtils.js";


class ListUtils {


    static setupList(settings) {

        const data = {
            components: {},
            props: {
                reloadTrigger: { type: Number, default: 0 },
            },
            data() {
                let data = {
                    list: [],
                    itemsPerPage: settings.hasOwnProperty("itemsPerPage") ? settings.itemsPerPage : 25,
                    pagination: {
                        showFirstLastPage: true,
                        showCurrentPage: true,
                        pageText: backwardCompatibleL("{0}-{1} of {2}"),
                        itemsPerPageText: backwardCompatibleL("Rows per page"),
                        itemsPerPageOptions: settings.itemsPerPageOptions || [10, 25, 50, 100, -1],
                        itemsPerPageAllText: backwardCompatibleL("All")
                    },
                    loading: false,
                    search: "",
                };
                if (settings.headers) {
                    data.headers = ListUtils.addLocalizationToHeaders(settings.headers);
                }
                if (settings.data) {
                    let additionalData = settings.data();
                    data = { ...data, ...additionalData };
                }
                if (settings.sortBy) {
                    data.sortBy = settings.sortBy;
                }
                if (settings.sortDesc) {
                    data.sortDesc = settings.sortDesc;
                }
                return data;
            },
            watch: {
                reloadTrigger() {
                    this.update();
                }
            },
            created() {
                this.update();
            },
            computed: {},
            methods: {
                update : settings.update,
                add() {
                    this.$emit("add");
                },
                edit(entry) {
                    this.$emit("edit", entry);
                },
                deleteEntry(entry) {
                    this.$root.$emit(
                        "confirm",
                        backwardCompatibleL(settings.deleteSettings.promptTitle),
                        backwardCompatibleL(settings.deleteSettings.promptQuestion, entry),
                        backwardCompatibleL("Delete"),
                        async yes => {
                            if (yes) {
                                const deletingEntry = entry;

                                const responseHandler = () => {
                                    this.$root.$emit("success", backwardCompatibleL(settings.deleteSettings.success, deletingEntry));
                                    this.update();
                                    this.$emit("delete", deletingEntry._id);
                                };

                                const action = settings.deleteSettings.action(entry._id, responseHandler);

                                if (action && action.then) { // is Promise
                                    const response = await action;
                                    responseHandler(response);
                                }
                            }
                        }
                    );
                },
                showLoading() {
                    this.loading = true;
                },
                hideLoading() {
                    this.loading = false;
                },
                l(token, values) {
                    return this.$root.l(token, values);
                }
            }
        }
        if (settings.hasOwnProperty("components")) {
            data.components = settings.components;
        }
        if (settings.hasOwnProperty("update")) {
            data.update = settings.update;
        }
        if (settings.hasOwnProperty("created")) {
            data.created = settings.created;
        }
        if (settings.hasOwnProperty("mounted")) {
            data.mounted = settings.mounted;
        }
        if (settings.hasOwnProperty("name")) {
            data.name = settings.name;
        }
        if (settings.hasOwnProperty("methods")) {
            for (let methodName in settings.methods) {
                data.methods[methodName] = settings.methods[methodName];
            }
        }
        if (settings.hasOwnProperty("props")) {
            for (let propsName in settings.props) {
                data.props[propsName] = settings.props[propsName];
            }
        }
        if (settings.hasOwnProperty("computed")) {
            for (let computedName in settings.computed) {
                data.computed[computedName] = settings.computed[computedName];
            }
        }
        if (settings.hasOwnProperty("watch")) {
            for (let watchName in settings.watch) {
                data.watch[watchName] = settings.watch[watchName];
            }
        }
        if (settings.hasOwnProperty("mixins")) {
            data.mixins = settings.mixins;
        }
        if (settings.hasOwnProperty("model")) {
            data.model = settings.model;
        }
        return data;
    }


    static addLocalizationToHeaders(headers) {
        headers.forEach(header => {
            header.text = backwardCompatibleL(header.text);
        });
        return headers;
    };


}

export default ListUtils;


export function giveUniqueId(entry) {
    if (entry) {
        entry.uid = uuidv4();
    }
}


export function giveUniqueIdsToAll(entries) {
    if (Array.isArray(entries)) {
        entries.forEach(entry => {
            giveUniqueId(entry);
        });
    }
}


export function updateIndicesAndMovability(entries, vueContext) {
    if (Array.isArray(entries)) {
        for (let i = 0; i < entries.length; i++) {
            vueContext.$set(entries[i], "index", i);
            vueContext.$set(entries[i], "canMoveUp", i != 0);
            vueContext.$set(entries[i], "canMoveDown", i != entries.length - 1);
        }
    }
}