import store from "../../store/index.js";


export function getCMSLocales() {
    return store.getters["locales/getCMSLocales"];
}


export function getAppLocales() {
    return store.getters["locales/getAppLocales"];
}


export function inCMSLocale() {
    return store.getters["locales/getCMSLocale"];
}


export function inContentLocale() {
    return store.getters["locales/getContentLocale"];
}


export function inPreviewLocale() {
    return store.getters["locales/getPreviewLocale"];
}


export function inFallbackLocale() {
    return store.getters["locales/getFallbackLocale"];
}


export function setPreviewLocale(locale) {
    store.commit("locales/setPreviewLocale", locale);
}


export function getCMSLocaleOptions() {
    const locales = [];
    getCMSLocales().forEach(locale => {
        locales.push({
            text: locale.name,
            value: locale.ietfLanguageTag,
        });
    });
    return locales;
}


export function getAppLocaleOptions() {
    const locales = [];
    getAppLocales().forEach(locale => {
        locales.push({
            text: locale.name,
            value: locale.ietfLanguageTag,
        });
    });
    return locales;
}