<template>
    <WindowedForm v-model="form" />
</template>
<script>

    import WindowedForm from "../../components/globalUiElements/WindowedForm.vue";
    import { getMetricOptions } from "../utils/usageUtils.js";
    import { createForm } from "../../utils/FormUtils.js";
    import { l } from "../../localization/localization.js";


    export default {
        components: {
            WindowedForm
        },
        props: {
            element : { type: Object, default: null }
        },
        data() {
            const fieldDefs = [
                {
                    key: "type",
                    type: "Hidden",
                    value: this.element.type,
                },
            ];

            if(this.element.type === "title") {
                fieldDefs.push({
                    key: "text",
                    type: "Text",
                    label: () => l.cms.usage.contentFieldLabel(),
                    required: true,
                    value: this.element.text,
                });

            } else if(this.element.type === "text") {
                fieldDefs.push({
                    key: "text",
                    type: "Textarea",
                    label: () => l.cms.usage.contentFieldLabel(),
                    required: true,
                    value: this.element.text,
                });

            } else if(this.element.type === "usageBar") {
                fieldDefs.push({
                    key: "metric",
                    type: "Select",
                    options: getMetricOptions(),
                    label: () => l.cms.usage.usageBarMetricFieldLabel(),
                    required: true,
                    value: this.element.metric,
                });
                fieldDefs.push({
                    key: "label",
                    type: "Text",
                    label: () => l.cms.usage.usageBarLabelFieldLabel(),
                    required: true,
                    value: this.element.label,
                    passOtherParameters: ["metric"],
                    visibility: field => field.otherParameters.metric.value === "custom",
                });
                fieldDefs.push({
                    key: "value",
                    type: "Number",
                    label: () => l.cms.usage.usageBarCurrentValueFieldLabel(),
                    required: true,
                    value: this.element.value,
                    passOtherParameters: ["metric"],
                    visibility: field => field.otherParameters.metric.value === "custom",
                });
                fieldDefs.push({
                    key: "max",
                    type: "Number",
                    label: () => l.cms.usage.usageBarMaxValueFieldLabel() + " " + l.cms.usage.limitFieldExplanation(),
                    required: true,
                    value: this.element.max,
                    passOtherParameters: ["metric"],
                    visibility: field => field.otherParameters.metric.value === "custom",
                });
                fieldDefs.push({
                    key: "unit",
                    type: "Text",
                    label: () => l.cms.usage.usageBarUnitFieldLabel(),
                    value: this.element.unit,
                    passOtherParameters: ["metric"],
                    visibility: field => field.otherParameters.metric.value === "custom",
                });

            }

            return {
                form: createForm({

                    title: {
                        label: () => l.cms.usage.editElementFormTitle(),
                    },

                    action: (entry, callback) => callback(entry),

                    fieldDefs,

                    submitLabel: () => l.cms.usage.editElementFormSubmitButtonLabel(),

                    submitted: entry => {
                        this.$emit("saveElement", entry);
                    },

                    cancelled: () => {
                        this.$emit("cancel");
                    }
                }),
            }
        },
    }
</script>