import axios from "axios";
import { triggerError } from "../utils/alerts.js";


const url = "/api/media/";
const settingsUrl = "/api/media/settings/";


class MediaService {

    // Download
    static downloadMedia(id, root) {
        axios.get(`${url}download/${id}`, { responseType: "blob" }).then(response => {
            const blob = new Blob([response.data], { type: response.data.type });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            const filename = response.headers["content-disposition"].split('=')[1].replace(/"/g, "");
            link.download = filename;
            document.body.appendChild(link);
            link.click();
        }).catch(error => {
            triggerError(error);
        });
    }

    // Download but keep data only within script
    static downloadMediaInternally(id, callback) {
        axios.get(`${url}download/${id}`, { responseType: "stream" }).then(response => {
            callback(response.data, null);
        }).catch(error => {
            triggerError(error);
            callback(null, error);
        });
    }

    // Get supported files formats
    static getSupportedFileFormats(callback) {
        axios.get(`${url}supportedFileFormats/`).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }

    // Get
    static getMedia(id, callback) {
        axios.get(`${url}${id}`).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }

    // Get all
    static getMedias(callback) {
        if (callback) {
            axios.get(url).then(response => {
                callback(response.data);
            }).catch(error => {
                triggerError(error);
            });
        } else {
            return new Promise(async resolve => {
                try {
                    const response = await axios.get(url);
                    resolve(response.data);
                } catch (error) {
                    triggerError(error);
                    resolve([]);
                }
            });
        }
    }

    // Post
    static insertMedia(formData, callback) {
        axios.post(url, formData).then(response => {
            callback(response.data);
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.isJoi) {
                callback({ errors: error.response.data.details });
            } else {
                triggerError(error);
            }
        });
    }

    // Put
    static updateMedia(media, callback) {
        axios.put(`${url}${media._id}`, media).then(response => {
            response.data._id = media._id;
            callback(response.data);
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.isJoi) {
                callback({ errors: error.response.data.details });
            } else {
                triggerError(error);
            }
        });
    }

    // Put settings
    static updateMediaSettings(media, callback) {
        axios.put(`${settingsUrl}${media._id}`, media).then(response => {
            response.data._id = media._id;
            callback(response.data);
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.isJoi) {
                callback({ errors: error.response.data.details });
            } else {
                triggerError(error);
            }
        });
    }

    // Delete
    static deleteMedia(mediaId, callback) {
        axios.delete(`${url}${mediaId}`).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }

}

export default MediaService;