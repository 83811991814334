<template>
    <div :class="'stop' + (showEditBox ? ' activeStop' : '')">
        <div class="stopDotBorder primary">
            <div class="stopDot">
                <v-icon color="primary" class="stopIcon">
                    {{ stopType.mdiIcon }}
                </v-icon>
            </div>
        </div>
        <div v-if="stop.canMoveDown" class="stopEdge primary" />
        <div class="stopType caption primary--text">
            {{ stopType.title }}
        </div>
        <div class="stopTitle font-weight-medium primary--text">
            {{ stopTitleLabel }}
        </div>
        <a class="stopSelector" @click="openEditBox()" />
        <AddStopBeforeBox
            :index="stop.index"
            :first-stop="stop.index === 0"
            :last-stop="false"
            :available-stop-types="availableStopTypes"
            @addStop="addStop"
        />
        <EditStopBox
            ref="editBox"
            :stop="stop"
            :stop-type="stopType"
            :show-edit-box="showEditBox"
            :saving="saving"
            @close="closeEditBox"
            @saveStop="saveStop"
            @moveUpStop="moveUpStop"
            @moveDownStop="moveDownStop"
            @deleteStop="deleteStop"
        />
    </div>
</template>
<script>

    import AddStopBeforeBox from "./AddStopBeforeBox.vue";
    import EditStopBox from "./EditStopBox.vue";
    import { deepClone } from "../../utils/objectUtils";
    import { inPreviewLocale } from "../../locales/utils/localeUtils.js";
    import { l, getLocalizedParameterValue } from "../../utils/LocalizationUtils.js";

    export default {
        components: {
            AddStopBeforeBox,
            EditStopBox
        },
        props: {
            stop: { type: Object, default: () => { } },
            stopTypeLookUp: { type: Object, default: () => {} },
            availableStopTypes: { type: Array, default: () => [] },
        },
        data() {
            return {
                showEditBox: false,
                stopType: {},
                saving: false
            }
        },
        computed: {
            stopTitleLabel() {
                return getLocalizedParameterValue(inPreviewLocale(), this.stop.parameters, this.stopType.stopTitleKey);
            }
        },
        watch: {
            stopTypeLookUp() {
                this.setStopType();
            },
        },
        mounted() {
            this.setStopType();
        },
        methods: {

            setStopType() {
                if (this.stopTypeLookUp.hasOwnProperty(this.stop.stopTypeId)
                    && this.stopTypeLookUp[this.stop.stopTypeId].hasOwnProperty(this.stop.stopTypeVersion)) {
                    this.stopType = this.stopTypeLookUp[this.stop.stopTypeId][this.stop.stopTypeVersion];
                }
            },

            openEditBox() {
                this.showEditBox = true;
                this.saving = false;
            },

            closeEditBox() {
                this.showEditBox = false;
                this.saving = false;
            },

            assignErrors(errors) {
                this.$refs.editBox.assignErrors(errors);
                this.saving = false;
            },

            saveStop(values) {
                const editedStop = deepClone(this.stop);
                editedStop.parameters.splice(0, editedStop.parameters.length);
                this.stopType.parameters.forEach(parameter => {
                    editedStop.parameters.push({
                        key: parameter.key,
                        value: values[parameter.key]
                    });
                });

                this.saving = true;
                this.$emit("save", {
                    originalStop: this.stop,
                    editedStop,
                    caller: this,
                    onSuccess: () => {
                        this.closeEditBox();
                    }
                });       
            },

            moveUpStop(onSuccess) {
                this.$emit("moveUpStop", onSuccess);
            },

            moveDownStop(onSuccess) {
                this.$emit("moveDownStop", onSuccess);
            },

            deleteStop(onSuccess) {
                this.$emit("deleteStop", onSuccess);
            },

            addStop(newStopInfo) {
                this.$emit("addStop", newStopInfo);
            },

            l
        }
    }
</script>
<style scoped>

    .stop {
        position: relative;
        height: 90px;
    }

    .stopDotBorder {
        position: absolute;
        top: 20px;
        left: 10px;
        width: 50px;
        height: 50px;
        border-radius: 25px;
    }

    .stopDot {
        position: absolute;
        top: 3px;
        left: 3px;
        width: 44px;
        height: 44px;
        border-radius: 22px;
        background: #fff;
    }

    .stopEdge {
        position: absolute;
        top: 68px;
        left: 33px;
        width: 4px;
        height: 44px;
    }

    .stopIcon {
        position: absolute;
        top: 6px;
        left: 6px;
        width: 32px;
        height: 32px;
    }

    .stopType {
        position: absolute;
        left: 70px;
        top: 25px;
        width: 280px;
        height: 20px;
        line-height: 20px;
        white-space: nowrap;
        overflow: hidden;
    }

    .stopTitle {
        position: absolute;
        left: 70px;
        top: 45px;
        width: 280px;
        height: 20px;
        line-height: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .activeStop .stopDotBorder,
    .activeStop .stopType,
    .activeStop .stopTitle {
        z-index: 9;
    }

    .stopSelector {
        display: block;
        position: absolute;
        top: 9px;
        left: -1px;
        width: 362px;
        bottom: 9px;
        user-select: none;
        cursor: pointer;
        z-index: 6;
    }

    .stopSelector:hover {
        border: 2px solid;
        border-radius: 3px;
        background: rgba(204, 204, 204, 0.12);
        z-index: 6;
    }

    .activeStop .stopSelector {
        top: 8px !important;
        left: -2px !important;
        width: 364px !important;
        bottom: 8px !important;
        border: 3px solid !important;
        border-radius: 4px !important;
        z-index: 8 !important;
        background: #fff !important;
    }

</style>