<template>
    <WindowedForm v-model="form" />
</template>
<script>
    
    import ArtPiecePlacementService from "../../services/ArtPiecePlacementService.js";
    import WindowedForm from "../../components/globalUiElements/WindowedForm.vue";
    import { createForm } from "../../utils/FormUtils.js";


    export default {
        components: {
            WindowedForm
        },
        props: {
            exhibition: { type: Object, default: null },
            floor: { type: Object, default: null },
            saving: { type: Boolean, default: false },
        },
        data() {
            return {
                form: createForm({

                    title: "Add New Location",

                    action: ArtPiecePlacementService.insertArtPiecePlacement,

                    fieldDefs: [
                        {
                            key: "artPieceId",
                            type: "Reference",
                            referenceOf: "informationPage",
                            pageTypes: [ "artPiece" ],
                            label: "Select art piece",
                        },
                        {
                            key: "exhibitionId",
                            type: "Hidden",
                            value: this.exhibition?._id,
                        },
                        {
                            key: "floorId",
                            type: "Hidden",
                            value: this.floor?._id,
                        },
                        {
                            key: "positionX",
                            type: "Hidden",
                            value: 0.5,
                        },
                        {
                            key: "positionY",
                            type: "Hidden",
                            value: 0.5,
                        },
                        {
                            key: "heightAboveFloorInMeters",
                            type: "Hidden",
                            value: 0,
                        },
                        {
                            key: "rotation",
                            type: "Hidden",
                            value: 0,
                        },
                        {
                            key: "room",
                            type: "Hidden",
                            value: "",
                        },
                        {
                            key: "wall",
                            type: "Hidden",
                            value: "",
                        },
                        {
                            key: "locked",
                            type: "Hidden",
                            value: false,
                        },
                    ],

                    submitLabel: "Add",

                    submitted: entry => {
                        this.$emit("addedLocation", entry._id);
                    },

                    cancelled: () => {
                        this.$emit("cancel");
                    },

                })
            }
        },
        watch: {
            saving: {
                handler(value) {
                    this.form.waitingForResponse = value;
                },
                immediate: true,
            },
        },
    }
</script>