<template>
    <div :class="'block' + (showEditBox ? ' activeBlock' : '')">
        <BlockPreview
            :information-page-type-attributes="null"
            :block-type="blockType"
            :tour="false"
            :values="valuesForPreview"
        />
        <a class="blockSelector" @click="openEditBox()" />
        <AddBlockBeforeBox
            :index="block.index"
            :available-block-types="availableBlockTypes"
            @addBlock="addBlock"
        />
        <EditBlockBox
            ref="editBox"
            :block="block"
            :block-type="blockType"
            :show-edit-box="showEditBox"
            :saving="saving"
            @close="closeEditBox"
            @saveBlock="saveBlock"
            @moveUpBlock="moveUpBlock"
            @moveDownBlock="moveDownBlock"
            @deleteBlock="deleteBlock"
        />
    </div>
</template>
<script>

    import BlockPreview from "./blockPreviews/BlockPreview.vue";
    import AddBlockBeforeBox from "./AddBlockBeforeBox.vue";
    import EditBlockBox from "./EditBlockBox.vue";
    import { deepClone } from "../../utils/objectUtils";
    import { inPreviewLocale } from "../../locales/utils/localeUtils.js";
    import { l, getLocalizedParameterList } from "../../utils/LocalizationUtils.js";


    export default {
        components: {
            BlockPreview,
            AddBlockBeforeBox,
            EditBlockBox
        },
        props: {
            block: { type: Object, default: () => { } },
            blockTypeLookUp: { type: Object, default: () => {} },
            availableBlockTypes: { type: Array, default: () => [] }
        },
        data() {
            return {
                showEditBox: false,
                blockType: {},
                saving: false
            }
        },
        computed: {
            valuesForPreview() {
                if (this.block
                    && this.block.parameters
                    && this.blockType
                    && this.blockType.parameters) {
                    return getLocalizedParameterList(inPreviewLocale(), this.block.parameters, this.blockType.parameters);
                } else {
                    return {};
                }
            }
        },
        watch: {
            blockTypeLookUp() {
                this.setBlockType();
            },
        },
        mounted() {
            this.setBlockType();
        },
        methods: {

            setBlockType() {
                if (this.blockTypeLookUp.hasOwnProperty(this.block.blockTypeId)
                    && this.blockTypeLookUp[this.block.blockTypeId].hasOwnProperty(this.block.blockTypeVersion)) {
                    this.blockType = this.blockTypeLookUp[this.block.blockTypeId][this.block.blockTypeVersion];
                }
            },

            openEditBox() {
                this.showEditBox = true;
                this.saving = false;
            },

            closeEditBox() {
                this.showEditBox = false;
                this.saving = false;
            },

            assignErrors(errors) {
                this.$refs.editBox.assignErrors(errors);
                this.saving = false;
            },

            saveBlock(values) {
                const editedBlock = deepClone(this.block);
                editedBlock.parameters.splice(0, editedBlock.parameters.length);
                this.blockType.parameters.forEach(parameter => {
                    editedBlock.parameters.push({
                        key: parameter.key,
                        value: values[parameter.key]
                    });
                });

                this.saving = true;
                this.$emit("save", {
                    originalBlock: this.block,
                    editedBlock,
                    caller: this,
                    onSuccess: () => {
                        this.closeEditBox();
                    }
                });
            },

            moveUpBlock(onSuccess) {
                this.$emit("moveUpBlock", onSuccess);
            },

            moveDownBlock(onSuccess) {
                this.$emit("moveDownBlock", onSuccess);
            },

            deleteBlock(onSuccess) {
                this.$emit("deleteBlock", onSuccess);
            },

            addBlock(newBlockInfo) {
                this.$emit("addBlock", newBlockInfo);
            },

            l
        }
    }
</script>
<style scoped>

    .block {
        position: relative;
        min-height: 40px;
    }

    .activeBlock .blockContent {
        z-index: 6;
    }

    .blockSelector {
        display: block;
        position: absolute;
        top: -1px;
        left: -1px;
        width: 482px;
        bottom: -1px;
        user-select: none;
        cursor: pointer;
        z-index: 3;
    }

    .blockSelector:hover {
        border: 2px solid;
        border-radius: 3px;
        background: rgba(204, 204, 204, 0.12);
    }

    .activeBlock .blockSelector {
        top: -2px !important;
        left: -2px !important;
        width: 484px !important;
        bottom: -2px !important;
        border: 3px solid !important;
        border-radius: 4px !important;
        z-index: 7 !important;
        background: none !important;
    }

</style>