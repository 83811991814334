<template>
    <div class="defaultContentView">
        <h1 class="display-1 primary--text mb-5">
            <v-icon color="primary" class="titleIcon">
                mdi-database-arrow-up
            </v-icon>
            {{ l("Upgrade Script") }}
            <v-btn class="ml-5" color="primary" :loading="loading" @click="run">
                <v-icon class="mr-2">
                    {{ runButtonIcon }}
                </v-icon>
                {{ runButtonLabel }}
            </v-btn>
        </h1>
        <!-- eslint-disable -->
        <div v-if="output" class="output" v-html="output" />
        <!-- eslint-enable -->
    </div>
</template>
<script>

    import UpgradeScriptService from "../../services/UpgradeScriptService.js";
    import { l } from "../../utils/LocalizationUtils.js";

    export default {
        data() {
            return {
                output: "",
                alreadyRun: false,
                loading: false
            };
        },
        computed: {
            runButtonLabel() {
                if (this.alreadyRun) {
                    return l("Rerun");
                } else {
                    return l("Run now");
                }
            },
            runButtonIcon() {
                if (this.alreadyRun) {
                    return "mdi-reload";
                } else {
                    return "mdi-play";
                }
            }
        },
        methods: {
            run() {
                this.$root.$emit(
                    "confirm",
                    l("Upgrade Script"),
                    l("Are you sure you want to run the upgrade script? This could seriously corrupt your data!"),
                    l("Yes"),
                    yes => {
                        if (yes) {
                            this.loading = true;
                            UpgradeScriptService.performUpgrade(output => {
                                this.output = output.replace(/(error)/gi, "<span style=\"color:#fff;background:#f00;font-weight:bold\">$1</span>");
                                this.alreadyRun = true;
                                this.loading = false;
                            });
                        }
                    }
                );
            },
            l
        }
    }
</script>
<style scoped>

    .output {
        border: 1px solid #bbb;
        background: #ddd;
        min-height: 500px;
        padding: 20px;
        color: #333;
        font-size: 12px;
        font-family: "Lucida Console", Monaco, monospace;
        line-height: 16px;
        white-space: pre-wrap;
        overflow-wrap: break-word;
    }

</style>
