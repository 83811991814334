<template>
    <v-select
        filled
        hide-details
        :value="currentExhibition ? currentExhibition._id : null"
        :items="exhibitions"
        :loading="loading"
        item-text="title"
        item-value="_id"
        :label="l('Exhibition:')"
        class="exhibitionSelection mb-5"
        @input="setExhibition"
    />
</template>
<script>

    import ExhibitionService from '../../services/ExhibitionService.js';
    import { l } from "../../utils/LocalizationUtils.js";


    export default {
        data() {
            return {
                currentExhibition: null,
                loading: false,
                exhibitions: [],
            };
        },
        async mounted() {
            this.loading = true;
            this.exhibitions = await ExhibitionService.getExhibitions();
            this.exhibitions.sort((a, b) => a.title.localeCompare(b.title));
            this.currentExhibition = this.exhibitions[0];
            this.loading = false;
            this.$emit("change", this.currentExhibition);
        },
        methods: {
            setExhibition(newId) {
                this.currentExhibition = this.exhibitions.find(exhibition => exhibition._id === newId);
                this.$emit("change", this.currentExhibition);
            },
            l,
        }
    };

</script>
<style scoped>

    .exhibitionSelection {
        max-width: 300px;
    }

</style>