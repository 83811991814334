import { inPreviewLocale } from "../locales/utils/localeUtils.js";
import { l, dateString } from "../localization/localization.js";
import { localize } from "./LocalizationUtils.js";
import { getPageName } from "../pages/utils/pageUtils.js";


function generateDataTableRowFromPageList({ singleLabel, multipleLabel, pageReferences }) {
    const dataTable = [];
    if (Array.isArray(pageReferences) && pageReferences.length > 0) {
        
        const entries = [];
        pageReferences.forEach(reference => {
            const pageName = getPageName(inPreviewLocale(), reference, true);
            if (pageName) {
                entries.push("<u>" + pageName + "</u>");
            }
        });

        const label = entries.length > 1 ? multipleLabel : singleLabel;
        const value = entries.join(", ");
        dataTable.push({ label, value });
    }
    return dataTable;
}


export function generateArtists(artists) {
    return generateDataTableRowFromPageList({
        singleLabel: l.app.pages.artists.singular(inPreviewLocale()),
        multipleLabel: l.app.pages.artists.plural(inPreviewLocale()),
        pageReferences: artists,
    });
}


export function generateCategories(categories) {
    return generateDataTableRowFromPageList({
        singleLabel: l.app.pages.categories.singular(inPreviewLocale()),
        multipleLabel: l.app.pages.categories.plural(inPreviewLocale()),
        pageReferences: categories,
    });
}


export function generateImportantLocations(importantLocations) {
    return generateDataTableRowFromPageList({
        singleLabel: l.app.pages.artMovements.importantLocations(inPreviewLocale()), // no singular
        multipleLabel: l.app.pages.artMovements.importantLocations(inPreviewLocale()),
        pageReferences: importantLocations,
    });
}


export function generateArtMovements(artMovements) {
    return generateDataTableRowFromPageList({
        singleLabel: l.app.pages.artMovements.singular(inPreviewLocale()),
        multipleLabel: l.app.pages.artMovements.plural(inPreviewLocale()),
        pageReferences: artMovements,
    });
}


export function generateDataTableRowFromSinglePage(label, reference) {
    const dataTable = [];
    const pageName = getPageName(inPreviewLocale(), reference, true);
    if (pageName) {
        dataTable.push({ label, value: "<u>" + pageName + "</u>" });
    }
    return dataTable;
}


export function generateEvents(events) {

    const dataTable = [];

    if (Array.isArray(events)) {
        events.forEach(event => {

            let label = "";

            if (event.typeString === "Custom") {
                label = localize(inPreviewLocale(), event.customLabel);

            } else if (event.typeString === "Born") {
                label = l.app.pages.persons.born(inPreviewLocale());

            } else if (event.typeString === "Died") {
                label = l.app.pages.persons.died(inPreviewLocale());

            } else if (event.typeString === "Creation") {
                label = l.app.pages.artworks.creation(inPreviewLocale());

            } else if (event.typeString === "Depiction") {
                label = l.app.pages.artworks.depiction(inPreviewLocale());

            } else if (event.typeString === "Onset") {
                label = l.app.pages.artMovements.onset(inPreviewLocale());

            } else if (event.typeString === "Closure") {
                label = l.app.pages.artMovements.closure(inPreviewLocale());

            } else {
                label = event.typeString;
            }

            const valueParts = [];

            if (event.date) {

                if (event.date.label) {
                    valueParts.push("<u>" + localize(inPreviewLocale(), event.date.label) + "</u>");
                
                } else if (event.date.typeString === "Single") {
                    valueParts.push("<u>" + dateString(
                        inPreviewLocale(),
                        event.date.date?.year,
                        event.date.date?.month,
                        event.date.date?.day,
                        event.date.date?.eraString,
                        event.date.date?.tagString,
                    ) + "</u>");

                } else if (event.date.typeString === "Period") {
                    valueParts.push("<u>" + dateString(
                        inPreviewLocale(),
                        event.date.start?.year,
                        event.date.start?.month,
                        event.date.start?.day,
                        event.date.start?.eraString,
                        event.date.start?.tagString,
                    ) + " – " + dateString(
                        inPreviewLocale(),
                        event.date.end?.year,
                        event.date.end?.month,
                        event.date.end?.day,
                        event.date.end?.eraString,
                        event.date.end?.tagString,
                    ) + "</u>");
                }
            }

            if (event.location) {
                if (event.location.reference) {
                    valueParts.push("<u>" + getPageName(inPreviewLocale(), event.location, false) + "</u>");

                } else if (event.location.label) {
                    valueParts.push(localize(inPreviewLocale(), event.location.label, false));
                }
            }
            
            const value = valueParts.join(", ");

            dataTable.push({ label, value });
        });
    }
    return dataTable;
}