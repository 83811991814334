<template>
    <WindowedForm v-model="form" />
</template>
<script>
    
    import InteractionsService from "../../services/InteractionService.js";
    import WindowedForm from "../globalUiElements/WindowedForm.vue";
    import { createForm } from "../../utils/FormUtils.js";
    import { createParameterFields } from "../../utils/ParameterUtils.js";
    import { l } from "../../utils/LocalizationUtils.js";


    export default {
        components: {
            WindowedForm
        },
        props: {
            arTarget: { type: Object, default: null },
            interactionModule: { type: Object, default: null }
        },
        data() {
            return {
                form: createForm({

                    title: {
                        label: "Add ${v} Interaction",
                        entry: l(this.interactionModule.title)
                    },

                    action: InteractionsService.insertInteraction,

                    fieldDefs: [
                        {
                            key: "arTargetId",
                            type: "Hidden",
                            value: this.arTarget._id
                        },
                        {
                            key: "interactionModuleId",
                            type: "Hidden",
                            value: this.interactionModule.id
                        },
                        {
                            key: "interactionModuleVersion",
                            type: "Hidden",
                            value: this.interactionModule.versionNumber
                        },
                        {
                            key: "name",
                            type: "Text",
                            label: "Name in CMS (not shown in the app)",
                            required: true
                        },
                        {
                            key: "isIntroduction",
                            type: "Hidden",
                            value: false
                        },
                        {
                            key: "hidePoiIfIntroduction",
                            type: "Hidden",
                            value: false
                        },
                        {
                            uiElement: "Spacer"
                        },
                        {
                            uiElement: "Title",
                            icon: "mdi-record-circle-outline",
                            text: "Point of interest (as seen in App)"
                        },
                        {
                            key: "poiLabel",
                            type: "Text",
                            label: "Label",
                            localized: true
                        },
                        {
                            key: "hidePoiLabel",
                            type: "Checkbox",
                            label: "Hide Label",
                            value: false
                        },
                        {
                            key: "poiIcon",
                            type: "Select",
                            label: "Icon of point of interest",
                            options: [
                                { value: "Dot", text: "Default" },
                                { value: "Detail", text: "Detail" },
                                { value: "Fun", text: "Special" },
                                { value: "Identity", text: "Identity" },
                                { value: "Links", text: "Link" },
                                { value: "Reveal", text: "Reveal" }
                            ],
                            value: "Dot",
                            showPoiIconHelp: true
                        },
                        {
                            key: "hideInBasicAR",
                            type: "Checkbox",
                            label: "Hide point of interest in regular Art Scanner mode",
                            value: false
                        },
                        {
                            uiElement: "Spacer"
                        },
                        {
                            uiElement: "Title",
                            icon: "mdi-tooltip-text-outline",
                            text: "Content of interaction"
                        },
                        ...createParameterFields(this.interactionModule.parameters, [], false)
                    ],

                    submitLabel: "Add",

                    submitted: entry => {
                        this.$root.$emit("success", l("Interaction \"${v.name}\" added.", entry));
                        this.$emit("createdEntry");
                    },

                    cancelled: () => {
                        this.$emit("cancel");
                    }
                })
            }
        }
    }
</script>