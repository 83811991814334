
import { l, tryGetTokenOverride, selectLanguage } from "./localization.js";



export const floorplan = {


    
    name(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "name");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Floor Plan";
            case "de": return "Plan";
            default: return `[name: ${language} missing]`;
        }
    
    },
            

    
    description(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "description");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        return "Navigate the exhibition space to find artworks and utilities.";
    },
            
};
