
import { l, tryGetTokenOverride, selectLanguage } from "./localization.js";



export const encyclopedia = {


    
    name(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "name");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Encyclopedia";
            case "de": return "Enzyklopädie";
            default: return `[name: ${language} missing]`;
        }
    
    },
            

    
    description(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "description");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        return "Search through artists, artworks and their historical and geographical context.";
    },
            
};
