<template>
    <div>
        <v-layout justify-end row class="pb-5 pr-3">
            <v-btn color="primary" class="pl-3 ml-5" @click="add">
                <v-icon class="mr-2">
                    add
                </v-icon>
                {{ l("Add Floor") }}
            </v-btn>
        </v-layout>
        <v-data-table :headers="headers" :items="list" :custom-sort="sortByLevel" :items-per-page="-1" hide-default-footer :loading="loading" :no-data-text="l('No entries.')">
            <template #item="row">
                <tr>
                    <td class="clickable text-right font-weight-bold large-fixed-row-height" style="width: 80px;" @click="edit(row.item)">
                        {{ row.item.level }}
                    </td>
                    <td class="clickable large-fixed-row-height" style="width:100px" @click="edit(row.item)">
                        <img :src="row.item.image" class="large-preview-image">
                    </td>
                    <td class="clickable large-fixed-row-height" @click="edit(row.item)">
                        {{ row.item.label }}
                    </td>
                    <td class="text-right large-fixed-row-height" style="width:68px">
                        <ListRowButton
                            :label="l('Delete')"
                            icon="mdi-delete"
                            @click="deleteEntry(row.item)"
                        />
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>
<script>

    import FloorService from "../../services/FloorService.js";
    import ListRowButton from "../../components/globalUiElements/ListRowButton.vue";
    import ListUtils from "../../utils/ListUtils.js";
    import { inContentLocale } from "../../locales/utils/localeUtils.js";
    import { l, localize } from "../../utils/LocalizationUtils.js";


    export default ListUtils.setupList({
        components: {
            ListRowButton
        },
        props: {
            exhibition: { type: Object, default: () => null },
        },
        /*data() {
            return {
                columns: [
                    {
                        header: {
                            text: "Level",
                            sortable: false,
                            align: "right"
                        },

                    },
                    

                ]
            };
        },*/
        headers: [
            { text: "Level", sortable: false, align: "right" },
            { text: "", sortable: false },
            { text: "Name", sortable: false },
            { text: "", sortable: false },
        ],
        sortBy: "level",
        watch: {
            exhibition() {
                this.update();
            }
        },
        created() {
            this.update();
        },
        update() {
            if(this.exhibition) {
                this.showLoading();
                FloorService.getFloors(this.exhibition._id, list => {
                    list.forEach(item => {
                        item.label = localize(inContentLocale(), item.name);
                        item.image = "/assets/" + item.floorPlanSvgId + ".svg";
                    });
                    this.list = list;
                    this.hideLoading();
                });
            }
        },
        deleteSettings : {
            promptTitle: "Delete Floor?",
            promptQuestion: "Are you sure you want to delete \"${v.label}\"?",
            action: FloorService.deleteFloor,
            success: "Floor \"${v.label}\" deleted."
        },
        methods: {
            edit(entry) {
                this.$emit("edit", entry);
            },
            sortByLevel(items, index, isDesc) {
                items?.sort((a, b) => a.level < b.level);
                return items;
            },
            l
        }
    });
</script>
<style scoped>

    .clickable {
        cursor: pointer;
    }

    .large-preview-image {
        display: block;
        max-width: 72px;
        max-height: 72px;
        margin: 0 auto;
    }

    .large-fixed-row-height {
        height: 84px !important;
    }

</style>