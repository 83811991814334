<template>
    <div>
        <h2 class="title primary--text">
            {{ l("Information Page Types") }}
        </h2>
        <v-progress-linear v-if="loading" indeterminate class="mt-5" />
        <v-card v-for="item in list" :key="item.id" class="mt-5">
            <v-card-title class="subtitle-2 pt-4 pb-0">
                <span class="element-id">
                    {{ item.id }}
                </span>
            </v-card-title>
            <v-data-table hide-default-footer :headers="headers" :items="item.versions" sort-by="versionNumber" :items-per-page="-1" :no-data-text="l('No entries.')">
                <template #item="row">
                    <tr>
                        <td class="text-right" style="width:40px">
                            {{ row.item.versionNumber }}
                        </td>
                        <td style="width:30%">
                            {{ row.item.title }}
                        </td>
                        <td>
                            {{ row.item.versionDescription }}
                        </td>
                        <td class="text-right" style="width:40px">
                            ToDo
                        </td>
                        <td class="text-right" style="width:40px">
                            <v-tooltip v-if="row.item.isVisibleToCurator" left>
                                <template #activator="{ on }">
                                    <v-btn text icon color="primary" v-on="on" @click="toggleVisibility(row.item)">
                                        <v-icon>mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                                <span class="body-2">{{ l("Hide information page type from curator") }}</span>
                            </v-tooltip>
                            <v-tooltip v-if="!row.item.isVisibleToCurator" left>
                                <template #activator="{ on }">
                                    <v-btn text icon color="primary" v-on="on" @click="toggleVisibility(row.item)">
                                        <v-icon>mdi-eye-off-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span class="body-2">{{ l("Show information page type to curator") }}</span>
                            </v-tooltip>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>

    import { mapActions } from "vuex";
    import InformationPageTypesService from "../../services/InformationPageTypesService.js";
    import ListUtils from "../../utils/ListUtils.js";

    export default {
        props: {
            reloadTrigger: { type: Number, default: 0 },
        },
        data() {
            return {
                list: [],
                headers: ListUtils.addLocalizationToHeaders([
                    { text: "Version", sortable: false },
                    { text: "Title", sortable: false },
                    { text: "Description", sortable: false },
                    { text: "Uses", sortable: false },
                    { text: "Visiblity", sortable: false }
                ], this),
                loading: false,
            };
        },
        watch: {
            reloadTrigger() {
                this.update();
            }
        },
        created() {
            this.update();
        },
        methods: {
            update() {
                this.updateSectionTitle(this.l("Information Page Types"));
                this.loading = true;
                InformationPageTypesService.getInformationPageTypes(list => {
                    this.list = list;
                    this.loading = false;
                });
            },
            toggleVisibility(entry) {
                entry.isVisibleToCurator = !entry.isVisibleToCurator;
                InformationPageTypesService.updateInformationPageTypes(this.list, list => {
                    this.list = list;
                    this.$root.$emit("success", this.l("Information page type's visibility updated."));
                });
            },
            l(token, values) {
                return this.$root.l(token, values);
            },
            ...mapActions({
                updateSectionTitle: "platform/updateSectionTitle"
            })
        }
    };
</script>
<style scoped>
    .element-id {
        background: #ddd;
        font-family: "Lucida Console", Monaco, monospace;
        padding: 3px 5px;
    }
</style>
