<template>
    <div class="defaultContentView">
        <div>
            <router-link to="/tours/" class="pt-2 pb-4 text-decoration-none" style="display:inline-block">
                <v-icon color="primary">
                    arrow_back
                </v-icon>
                {{ l("Tours") }}
            </router-link>
        </div>
        <h1 v-if="!tourNotFound" class="display-1 mb-5 primary--text">
            <v-icon color="primary" class="titleIcon">
                mdi-sign-direction
            </v-icon>
            {{ l(tourName) }}
        </h1>
        <h1 v-if="tourNotFound" class="display-1 mb-5 primary--text">
            <v-icon color="primary" class="titleIcon">
                mdi-file-question
            </v-icon>
            {{ l("Not found") }}
        </h1>
        <v-progress-linear v-if="loading" indeterminate class="loading mt-5" />
        <PreviewLanguageSelection v-if="!tourNotFound" />
        <EditTourInfoPreviewBox v-if="tour" :tour="tour" />
        <div v-if="tour" class="stopContainer">
            <TourStop
                v-for="stop in tour.stops"
                :key="stop.uid"
                :stop="stop"
                :stop-type-look-up="stopTypeLookUp"
                :available-stop-types="availableStopTypes"
                @save="save"
                @moveUpStop="moveUpStop(stop, $event)"
                @moveDownStop="moveDownStop(stop, $event)"
                @deleteStop="deleteStop(stop, $event)"
                @addStop="addStop"
            />
            <div class="virtualClosingStop">
                <AddStopBeforeBox
                    :index="tour.stops.length"
                    :first-stop="tour.stops.length === 0"
                    :last-stop="true"
                    :available-stop-types="availableStopTypes"
                    @addStop="addStop"
                />
            </div>
        </div>
    </div>
</template>
<script>

    import { mapActions } from "vuex";
    import PreviewLanguageSelection from "../../components/globalUiElements/PreviewLanguageSelection.vue";
    import EditTourInfoPreviewBox from "../../components/tours/EditTourInfoPreviewBox.vue";
    import TourStop from "../../components/tours/TourStop.vue";
    import AddStopBeforeBox from "../../components/tours/AddStopBeforeBox.vue";
    import TourService from "../../services/TourService.js";
    import TourStopTypesService from "../../services/TourStopTypesService.js";
    import { giveUniqueId, giveUniqueIdsToAll, updateIndicesAndMovability } from "../../utils/ListUtils.js";
    import { deepClone, deepCloneAndReplace } from "../../utils/objectUtils.js";
    import { inContentLocale } from "../../locales/utils/localeUtils.js";
    import { l, localize } from "../../utils/LocalizationUtils.js";


    export default {
        components: {
            PreviewLanguageSelection,
            EditTourInfoPreviewBox,
            TourStop,
            AddStopBeforeBox
        },
        data() {
            return {
                loading: false,
                tour: null,
                tourNotFound: false,
                stopTypeLookUp: {},
                availableStopTypes: []
            }
        },
        computed: {
            tourName() {
                let tourName = "";
                if (this.tour && this.tour.name) {
                    tourName = localize(inContentLocale(), this.tour.name);
                }
                this.updateSectionTitle(tourName);
                return tourName;
            }
        },
        created() {

            this.loading = true;

            this.updateTourStopTypes(() => {

                const tourId = this.$route.params.tourId;

                TourService.getTour(tourId, tour => {
                    this.tour = tour;
                    try {
                        giveUniqueIdsToAll(this.tour.stops);
                        updateIndicesAndMovability(this.tour.stops, this);
                    } catch (e) {
                        console.log(e);
                    }

                    this.loading = false;

                }, error => {
                    this.tourNotFound = true;
                    this.loading = false;
                });

            });
             
        },
        methods: {

            updateTourStopTypes(callback) {

                TourStopTypesService.getTourStopTypes(stopTypes => {

                    this.stopTypeLookUp = {};
                    this.availableStopTypes = [];

                    stopTypes.forEach(stopType => {

                        this.stopTypeLookUp[stopType.id] = {};
                        const hasSeveralVisibleVersions = stopType.versions.filter(version => version.isVisibleToCurator).length > 1;

                        stopType.versions.forEach(version => {
                            version.id = stopType.id;
                            this.stopTypeLookUp[stopType.id][version.versionNumber] = version;

                            if (version.isVisibleToCurator) {
                                this.availableStopTypes.push({
                                    stopTypeId: stopType.id,
                                    stopTypeVersion: version.versionNumber,
                                    title: this.l(version.title) + (hasSeveralVisibleVersions ? " (v" + version.versionNumber + ")" : ""),
                                    mdiIcon: version.mdiIcon,
                                    parameters: version.parameters
                                });
                            }
                        })
                    });

                    callback();
                });
            },            

            save({ editedTour, originalStop, editedStop, caller, onSuccess }) {

                if (originalStop && editedStop) {
                    editedTour = deepCloneAndReplace(this.tour, originalStop, editedStop);
                }

                TourService.updateTour(editedTour, response => {
                    if (response.errors && response.errors.length > 0) {
                        if (caller) {
                            caller.assignErrors(response.errors);
                        } else {
                            this.$root.$emit("error", "Validation error: " + response.errors[0].message);
                        }

                    } else {
                        if (onSuccess) {
                            onSuccess();
                        }
                        this.tour = editedTour;
                        updateIndicesAndMovability(this.tour.stops, this);
                        this.$root.$emit("success", this.l("Changes to tour \"${v}\" saved.", this.tourName));
                    }
                });
            },

            moveUpStop(stop, onSuccess) {
                if (stop.index > 0) {
                    const editedTour = deepClone(this.tour); 
                    editedTour.stops.splice(stop.index, 1);
                    editedTour.stops.splice(stop.index - 1, 0, stop);
                    this.save({ editedTour, onSuccess });
                }
            },

            moveDownStop(stop, onSuccess) {
                if (stop.index < this.tour.stops.length - 1) {
                    const editedTour = deepClone(this.tour); 
                    editedTour.stops.splice(stop.index, 1);
                    editedTour.stops.splice(stop.index + 1, 0, stop);
                    this.save({ editedTour, onSuccess });
                }
            },

            deleteStop(stop, onSuccess) {
                this.$root.$emit(
                    "confirm",
                    this.l("Delete stop"),
                    this.l("Do you really want to delete this stop?"),
                    this.l("Delete"),
                    yes => {
                        if (yes) {
                            const editedTour = deepClone(this.tour); 
                            editedTour.stops.splice(stop.index, 1);
                            this.save({ editedTour, onSuccess });
                        } else {
                            onSuccess();
                        }
                    }
                );
            },
            
            addStop({ stopType, index, onSuccess }) {

                const newStop = {
                    stopTypeId: stopType.stopTypeId,
                    stopTypeVersion: stopType.stopTypeVersion,
                    parameters: [],
                };
                stopType.parameters.forEach(parameter => {
                    newStop.parameters.push({
                        key: parameter.key,
                        value: parameter.defaultValue || null,
                    });
                });
                giveUniqueId(newStop);

                const editedTour = deepClone(this.tour); 
                editedTour.stops.splice(index, 0, newStop);
                this.save({ editedTour, onSuccess });
            },

            l,

            ...mapActions({
                updateSectionTitle: "platform/updateSectionTitle"
            })
        }
    }
</script>
<style scoped>

    .loading {
        width: 360px;
    }

    .languageSelection {
        width: 360px;
    }

    .tourInfoPreview {
        width: 360px;
    }

    .stopContainer {
        position: relative;
        margin-top: 40px;
        margin-bottom: 400px;
    }

    .virtualClosingStop {
        position: relative;
        height: 0px;
    }

</style>
