<template>
    <div class="defaultContentView">
        <h1 class="display-1 primary--text">
            <v-icon color="primary" class="titleIcon">
                perm_media
            </v-icon>
            {{ l("Media") }}
        </h1>
        <div>
            <MediaList :reload-trigger="listReloadTrigger" @add="openCreateForm" @edit="openEditForm" @editSettings="openEditSettingsForm" />
            <AddMedia v-if="showCreateForm" :accepted-file-formats="supportedFileFormats" @cancel="closeCreateForm" @createdEntry="createdEntry" />
            <EditMedia v-if="showEditForm" :accepted-file-formats="supportedFileFormats" :entry="editingEntry" @cancel="closeEditForm" @editedEntry="editedEntry" />
            <EditMediaSettings v-if="showEditSettingsForm" :entry="editingEntry" @cancel="closeEditSettingsForm" @editedEntry="editedEntry" />
        </div>
    </div>
</template>
<script>

    import { mapActions } from "vuex";
    import MediaList from "../components/media/MediaList.vue";
    import AddMedia from "../components/media/AddMedia.vue";
    import EditMedia from "../components/media/EditMedia.vue";
    import EditMediaSettings from "../components/media/EditMediaSettings.vue";
    import MediaService from "../services/MediaService.js";

    export default {
        components: {
            MediaList,
            AddMedia,
            EditMedia,
            EditMediaSettings
        },
        data() {
            return {
                listReloadTrigger: 0,
                showCreateForm: false,
                showEditForm: false,
                showEditSettingsForm: false,
                editingEntry: null,
                supportedFileFormats: {},
            };
        },
        mounted() {
            MediaService.getSupportedFileFormats(formats => {
                this.supportedFileFormats = { ...formats };
            });
            this.updateSectionTitle(this.l("Media"));
        },
        methods: {
            openCreateForm() {
                this.showCreateForm = true;
            },
            closeCreateForm() {
                this.showCreateForm = false;
            },
            createdEntry() {
                this.closeCreateForm();
                this.listReloadTrigger++;
            },
            openEditForm(entry) {
                this.editingEntry = entry;
                this.showEditForm = true;
            },
            closeEditForm() {
                this.showEditForm = false;
                this.editingEntry = null;
            },
            openEditSettingsForm(entry) {
                this.editingEntry = entry;
                this.showEditSettingsForm = true;
            },
            closeEditSettingsForm() {
                this.showEditSettingsForm = false;
                this.editingEntry = null;
            },
            editedEntry() {
                this.closeEditForm();
                this.closeEditSettingsForm();
                this.listReloadTrigger++;
            },
            l(token, values) {
                return this.$root.l(token, values);
            },
            ...mapActions({
                updateSectionTitle: "platform/updateSectionTitle"
            })
        }
    }
</script>
