<template>
    <div :class="values.size === 'subsection' ? 'subsectionTitle' : 'sectionTitle'">
        {{ values.title }}
        <span v-if="!values.title">
            {{ l("Enter title...") }}
        </span>
        <div v-if="values.size === 'section'" class="sectionTitleLine" />
    </div>
</template>
<script>

    import { l } from "../../../utils/LocalizationUtils.js";


    export default {
        props: {
            values: { type: Object, default: () => { } }
        },
        methods: {
            l
        }
    }

</script>
<style scoped>

    .sectionTitle {
        position: relative;
        padding: 24px 20px 8px;
        min-height: 65px;
        font-size: 22px;
        font-weight: 600;
        color: #fff;
        background: #212121;
    }

    .sectionTitleLine {
        position: absolute;
        left: 10px;
        bottom: 1px;
        height: 1px;
        width: 33%;
        background: #616161;
    }

    .subsectionTitle {
        position: relative;
        padding: 16px 20px 8px;
        min-height: 50px;
        font-size: 18px;
        font-weight: 600;
        color: #fff;
        background: #212121;
    }

</style>