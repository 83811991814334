var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"title primary--text"},[_vm._v(" "+_vm._s(_vm.l("Tour Stop Types"))+" ")]),(_vm.loading)?_c('v-progress-linear',{staticClass:"mt-5",attrs:{"indeterminate":""}}):_vm._e(),(_vm.stopsWithoutType.length > 0)?_c('v-btn',{staticClass:"body-1 mt-5 pa-5",staticStyle:{"text-transform":"none","width":"100%"},attrs:{"type":"error","outlined":"","tile":"","color":"error"},on:{"click":function($event){return _vm.openStopsWithoutTypeList()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-alert ")]),_vm._v(" "+_vm._s(_vm.l("There are ${v} tour stops with missing stop type.", _vm.stopsWithoutType.length))+" ")],1):_vm._e(),_vm._l((_vm.list),function(item){return _c('v-card',{key:item.id,staticClass:"mt-5"},[_c('v-card-title',{staticClass:"subtitle-2 pt-4 pb-0"},[_c('span',{staticClass:"element-id"},[_vm._v(" "+_vm._s(item.id)+" ")])]),_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.headers,"items":item.versions,"sort-by":"versionNumber","items-per-page":-1,"no-data-text":_vm.l('No entries.')},scopedSlots:_vm._u([{key:"item",fn:function(row){return [_c('tr',[_c('td',{staticClass:"text-right",staticStyle:{"width":"40px"}},[_vm._v(" "+_vm._s(row.item.versionNumber)+" ")]),_c('td',{staticClass:"text-center",staticStyle:{"width":"68px"}},[(row.item.isVisibleToCurator)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.toggleVisibility(row.item)}}},on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',{staticClass:"body-2"},[_vm._v(_vm._s(_vm.l("Hide tour stop type from curator")))])]):_vm._e(),(!row.item.isVisibleToCurator)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.toggleVisibility(row.item)}}},on),[_c('v-icon',[_vm._v("mdi-eye-off-outline")])],1)]}}],null,true)},[_c('span',{staticClass:"body-2"},[_vm._v(_vm._s(_vm.l("Show tour stop type to curator")))])]):_vm._e()],1),_c('td',{staticStyle:{"width":"20%"}},[_vm._v(" "+_vm._s(row.item.title)+" ")]),_c('td',[_vm._v(" "+_vm._s(row.item.versionDescription)+" ")]),_c('td',{staticClass:"text-right nobreak",staticStyle:{"width":"100px"}},[_c('span',{staticClass:"pr-1",staticStyle:{"display":"inline-flex","vertical-align":"middle"}},[_vm._v(" "+_vm._s(row.item.uses.length)+" ")]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.openUses(row.item)}}},on),[_c('v-icon',[_vm._v("mdi-view-list")])],1)]}}],null,true)},[_c('span',{staticClass:"body-2"},[_vm._v(_vm._s(_vm.l("List all uses")))])])],1),_c('td',{staticClass:"text-right nobreak",staticStyle:{"width":"68px"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.openInformation(row.item)}}},on),[_c('v-icon',[_vm._v("mdi-information-outline")])],1)]}}],null,true)},[_c('span',{staticClass:"body-2"},[_vm._v(_vm._s(_vm.l("Information")))])])],1)])]}}],null,true)})],1)}),(_vm.showStopsWithoutTypeList)?_c('StopsWithoutTypeList',{attrs:{"stops-without-type":_vm.stopsWithoutType},on:{"close":_vm.closeStopsWithoutTypeList}}):_vm._e(),(_vm.showUses)?_c('TourStopTypeUsesBox',{attrs:{"stop-type":_vm.showUsesOf},on:{"close":_vm.closeUses}}):_vm._e(),(_vm.showInformation)?_c('TourStopTypeInformationBox',{attrs:{"tour-stop-type":_vm.showInformationOn},on:{"close":_vm.closeInformation}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }