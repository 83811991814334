<template>
    <EncyclopediaHeader 
        :image="image"
        :name="name"
        :data-table="dataTable"
    />
</template>
<script>

    import EncyclopediaHeader from "./EncyclopediaHeader.vue";
    import { generateCategories, generateEvents, generateArtMovements } from "../../../utils/headerPreviewUtils.js";


    export default {
        components: {
            EncyclopediaHeader
        },
        props: {
            values: { type: Object, default: () => { } }
        },
        computed: {
            image() {
                return this.values.image;
            },
            name() {
                return this.values.name;
            },
            dataTable() {
                return [
                    ...generateEvents(this.values.events),
                    ...generateArtMovements(this.values.artMovements),
                    ...generateCategories(this.values.categories),
                ];
            },
        },
    }
</script>