<template>
    <v-card flat class="mb-2" style="border: 1px solid #ccc">
        <div class="arrayEntryRow">
            <div class="arrayEntryIndex text-right font-weight-bold mr-5">
                {{ indexLabel }}
            </div>
            <div class="arrayEntryFields">
                <div v-for="(field, name) in entry.fields" :key="name">
                    <InputField
                        v-model="entry.fields[name]"
                        :waiting-for-response="waitingForResponse"
                        @scrollTo="scrollTo"
                        @submitOnReturn="submitOnReturn"
                    />
                </div>
            </div>
            <div class="arrayEntryButtons ml-3 mr-1">
                <v-tooltip left>
                    <template #activator="{ on }">
                        <v-btn :disabled="!canMoveUp" text icon x-small color="primary" v-on="on" @click="moveUpEntry()">
                            <v-icon>
                                mdi-chevron-up
                            </v-icon>
                        </v-btn>
                    </template>
                    <span class="body-2">{{ l("Move up") }}</span>
                </v-tooltip>
                <v-tooltip left>
                    <template #activator="{ on }">
                        <v-btn text icon x-small color="primary" v-on="on" @click="removeEntry()">
                            <v-icon>
                                delete
                            </v-icon>
                        </v-btn>
                    </template>
                    <span class="body-2">{{ l("Remove") }}</span>
                </v-tooltip>
                <v-tooltip left>
                    <template #activator="{ on }">
                        <v-btn :disabled="!canMoveDown" text icon x-small color="primary" v-on="on" @click="moveDownEntry()">
                            <v-icon>
                                mdi-chevron-down
                            </v-icon>
                        </v-btn>
                    </template>
                    <span class="body-2">{{ l("Move down") }}</span>
                </v-tooltip>
            </div>
        </div>
    </v-card>
</template>
<script>

    import { l } from "../../../utils/LocalizationUtils.js";


    export default {
        model: {
            prop: "propEntry",
            event: "input"
        },
        props: {
            propEntry: { type: Object, default: null },
            index: { type: Number, default: 0 },
            canMoveUp: { type: Boolean, default: true },
            canMoveDown: { type: Boolean, default: true },
            waitingForResponse: { type: Boolean, default: false }
        },
        computed: {
            entry() {
                return this.propEntry;
            },
            indexLabel() {
                return this.index + 1;
            }
        },
        beforeCreate() { // Delayed InputField import, to prevent recursion of imports
            this.$options.components.InputField = require('./InputField.vue').default;
        },
        methods: {
            removeEntry() {
                this.$emit("remove");
            },
            moveUpEntry() {
                this.$emit("moveUp");
            },
            moveDownEntry() {
                this.$emit("moveDown");
            },
            scrollTo(scrollTarget) {
                this.$emit("scrollTo", scrollTarget);
            },
            submitOnReturn(event) {
                this.$emit("submitOnReturn", event);
            },
            l
        }
    }    
</script>
<style scoped>

    .arrayEntryRow {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .arrayEntryIndex {
        width: 32px;
        height: 64px;
        line-height: 64px;
        flex-shrink: 0;
        flex-grow: 0;
    }

    .arrayEntryFields {
        min-height: 64px;
        flex-grow: 1;
        flex-shrink: 1;
    }

    .arrayEntryButtons {
        width: 20px;
        height: 64px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

</style>