export default {
    translations: {
	
        "$Language": "Français (Suisse)",

        "Logged in as": "Connecté comme",
        "User Settings": "Paramètres de l'utilisateur",
        "Logout": "Déconnexion",
        "Error 403: You don't have permission to access this content.": "Erreur 403 : Vous n'avez pas la permission d'accéder à ce contenu.",

        "Maintenance": "Maintenance",
        "The curation system is currently undergoing maintenance. This should only last a few minutes.": "The curation system is currently undergoing maintenance. This should only last a few minutes.",
        "Bypass": "Bypass",
        "Curation lock engaged!": "Curation lock engaged!",

	    "Exhibitions": "Exposition",
        "AR Content": "Contenu RA",
	    "Information Database": "Information Database",
	    "Media": "Médias",
        "Analytics": "Analytics",
	    "Users": "Utilisateurs",
	    "System": "Système",
        "Help": "Aide",

        "Cancel": "Annuler",
        "Create": "Créer",
        "Close": "Fermer",
        "Add": "Ajouter",
        "Delete": "Supprimer",
        "Remove": "Enlever",
        "Save": "Enregistrer",
        "No entries.": "Aucune donnée.",
        "Never": "Jamais",
        "All": "Tout",
        "{0}-{1} of {2}": "{0}-{1} de {2}",
        "Rows per page": "Lignes par page",
        "Editing ${v.name}": "Édition de ${v.name}",
        "Editing ${v.title}": "Édition de ${v.title}",
        "Are you sure you want to delete \"${v.name}\"?": "Êtes-vous sûr de vouloir supprimer \"${v.name}\"?",
        "Are you sure you want to delete \"${v.title}\"?": "Êtes-vous sûr de vouloir supprimer \"${v.title}\"?",
        "Are you sure you want to delete \"${v.key}\"?": "Êtes-vous sûr de vouloir supprimer \"${v.key}\"?",
        "Search": "Rechercher",
        "Information": "Informations",
        "loading...": "Chargement...",
        "Version": "Version",
        "Description": "Description",
        "Uses": "Usages",
        "Visiblity": "Visiblité",
        "Yes": "Oui",
        "No": "Non",
        "Not found": "Inaccessible",

        "$Date": "${v.day} ${v.month} ${v.year}",
        "$Year and month": "${v.month} ${v.year}",
        "$Add era to date": "${v.dateString} ${v.era}",
        "Circa ${v.dateString}": "c. ${v.dateString}",
        "Before ${v.dateString}": "before ${v.dateString}",
        "After ${v.dateString}": "after ${v.dateString}",
        "Single date": "Single date",
        "Time period": "Time period",
        "Circa": "Circa",
        "Before": "Before",
        "After": "After",
        "Day": "Day",
        "Month": "Month",
        "AD Date": "AD",
        "BC Date": "BC",
        "From... Date": "De...",
        "To... Date": "À...",
        "January": "January",
        "February": "February",
        "March": "March",
        "April": "April",
        "May": "May",
        "June": "June",
        "July": "July",
        "August": "August",
        "September": "September",
        "October": "October",
        "November": "November",
        "December": "December",
        "Invalid date": "Date non valide",
        "Edit label": "Edit label",
        "The start date is after the end date.": "The start date is after the end date.",
        "The start and end date are identical.": "The start and end date are identical.",
        "${v} seconds": "${v} seconds",
        "${v} minutes": "${v} minutes",
        "${v} hours": "${v} heures",
        "${v} days": "${v} jours",
        "${v} seconds.": "${v} s",
        "${v} minutes.": "${v} min",
        "${v} hours.": "${v} h",
        "${v} days.": "${v} j",

        "Fill": "Remplir",
        "Image transform mode fill explanation": "L'image remplit le cadre tout en étant entièrement contenue dedans. Le rapport hauteur/largeur de l'image n'est pas appliqué.",
        "Contain": "Contient",
        "Image transform mode contain explanation": "L'image est entièrement contenue dans le cadre et le rapport hauteur/largeur de l'image est conservé. L'image peut ne pas recouvrir entièrement le cadre.",
        "Cover": "Masqué",
        "Image transform mode cover explanation": "L'image recouvre entièrement le cadre et le rapport hauteur/largeur de l'image est conservé. L'image peut s'étendre au-delà du cadre.",
        "Custom": "Personnalisé",
        "Image transform mode custom explanation": "La position, la taille et la rotation de l'image par rapport au cadre sont définies manuellement.",
        "Horizontal offset": "Décalage horizontal",
        "Vertical offset": "Décalage vertical",
        "Scale": "Scale",
        "Rotation": "Rotation",

        "Create New User": "Créer un nouvel utilisateur",
        "User \"${v.name}\" created.": "Utilisateur \"${v.name}\" créé.",
        "Changes for user \"${v.name}\" saved.": "Changements pour l'utilisateur \"${v.name}\" enregistré.",
        "Name": "Nom",
        "Email Address": "Adresse e-mail ",
        "Last Login": "Dernier Login",
        "Password": "Mot de passe",
        "Change password": "Changer le mot de passe",
        "Access Rights": "Droits d'accès",
        "Language": "Langue",
        "Delete User?": "Supprimer l'utilisateur?",
        "User \"${v.name}\" deleted.": "Utilisateur \"${v.name}\" supprimé.",
        "User Settings for ${v.name}": "Paramètres de l'utilisateur ${v.name}",

        "Create New Exhibition": "Créer une nouvelle exposition",
        "Exhibition \"${v.title}\" created.": "Exposition \"${v.title}\" créée.",
        "Update JSON file in Firebase": "Mettre à jour du fichier JSON dans Firebase",
        "Updating JSON In Firebase": "Mise à jour du JSON dans Firebase",
        "The JSON file of \"${v.title}\" was successfully updated.": "Le fichier JSON de \"${v.title}\" a été mis à jour.",
        "Download Exhibition's Media": "Télécharger Le média de l'Exposition",
        "Creating Media Package": "Création du Media Package",
        "Download targets for Vuforia": "Télécharger des targets pour Vuforia",
        "Bundling Targets for Vuforia": "Regroupement des targets pour Vuforia",
        "View JSON file": "Voir le fichier JSON",
        "Changes for exhibition \"${v.title}\" saved.": "Changements pour l'exposition \"${v.title}\" sauvegardés.",
        "Delete Exhibition?": "Supprimer l'exposition?",
        "Exhibition \"${v.title}\" deleted.": "Exposition \"${v.title}\" supprimée.",
        "Vuforia .xml AR target file": "Fichier .xml AR target Vuforia",
        "Vuforia .dat AR target file": "Fichier .dat AR target Vuforia",
        "Edit Exhibition": "Modifier l'exposition",

        "All": "Tous",
        "Search Art Piece...": "Rechercher une oeuvre...",
        "Not Exhibited": "Non exposée",
        "Exhibited": "Exposée",
        "placing...": "placement...",
        "Not yet placed": "Pas encore placée",
        "Add to Exhibition": "Ajouter à l'exposition",
        "Remove from Exhibition": "Retirer de l'exposition",
        "Set Position": "Définir la position",
        "Additional Settings": "Paramètres additionnels",
        "Art Pieces": "Oeuvre d'art",
        "Manage the Exhibition \"${v}\"": "Gérer l'exposition \"${v}\"",
        "Back to Exhibitions": "Retour à l'exposition",

        "Floors": "Floors",
        "Remove Floor?": "Remove Floor?",
        "Are you sure you want to remove \"${v.name}\"?": "Are you sure you want to remove \"${v.name}\"?",
        "Floor \"${v.name}\" removed.": "Floor \"${v.name}\" removed.",

        "Add Art Piece": "Ajouter une oeuvre",
        "Art Piece Details": "Details de l'oeuvre",
        "Display Settings": "Options d'affichage",
        "Image": "Image",
        "Number": "Numéro",
        "Title": "Titre",
        "Artist": "Artiste",
        "Year": "Année",
        "Medium": "Medium",
        "Width in cm": "Largeur en cm",
        "Art Piece ID": "ID de l'oeuvre d'art",
        "Media ID of Art Piece Image": "ID du média de l'image de l'oeuvre",
        "Vuforia Target Name": "Nom du target Vuforia",
        "Media ID of Target Image": "ID du média de l'image du target",
        "Delete Art Piece?": "Supprimer l'oeuvre?",
        "Art piece \"${v.title}\" deleted.": "L'oeuvre \"${v.title}\" a été supprimée.",
        "Show overlay on art piece when it's being tracked in AR": "Afficher la superposition sur l'oeuvre lorsqu'elle est détécté en AR",
        "Offset of interactions from AR target in cm": "Décalage des interactions du target AR en cm",
        "Show information box when AR target is tracked": "Afficher la boîte d'informations lorsque le target AR est détécté",

        "Back to list of art pieces": "Retour vers la liste d'oeuvre",
        "Interactions of \"${v}\"": "Interactions de \"${v}\"",
        "No Interaction Modules found": "Aucune interaction trouvé",
        "Add ${v.name} Interaction": "Ajouter une interaction ${v.name}",
        "Remove Interaction?": "Supprimer l'interaction?",
        "Are you sure you want to remove the interaction \"${v.name}\"?": "Voulez-vous vraiment supprimer l'interaction \"${v.name}\"?",
        "Interaction \"${v.name}\" removed.": "Interaction \"${v.name}\" supprimée.",
        "Edit Interaction ${v}": "Modifier l'interaction ${v}",
        "Edit interactions": "Modifier les interactions",
        "Change interaction's position": "Changer la position de l'interaction",
        "Select Interaction Type...": "Selectioner un type d'interaction...",
        "Add ${v} Interaction": "Ajouter une interaction ${v}",
        "Add \"${v}\" interaction": "Ajouter une interaction \"${v}\"",
        "Add ${v}": "Ajouter ${v}",
        "Interaction Type": "Type d'interaction",
        "Coordinates": "Coordonnées",
        "Changes to interaction \"${v.name}\" saved.": "Changements de l'interaction \"${v.name}\" enregistrés.",
        "Edit Settings of Interaction": "Modifier les paramètres d'interaction",
        "Edit Settings of Interaction ${v}": "Modifier les paramètres de l'interaction ${v}",
        "Interaction Module:": "Module d'interaction:",
        "Name in CMS (not shown in the app)": "Nom dans le CMS (n'apparaît pas dans l'application)",
        "Point of interest (as seen in App)": "Point d'intérêt (vu comme dans l'application)",
        "Hide Label": "Masquer l'étiquette",
        "Icon of point of interest": "Icône de point d'intérêt",
        "Hide point of interest in regular Art Scanner mode": "Masquer le point d'intérêt en mode Art Scanner normal",
        "Content of interaction": "Contenu de l'interaction",
        "Position x axis": "Position sur l'axe x",
        "Position y axis": "Position sur l'axe y",
        "Behavior": "Comportement",
        "Is the art piece's introduction:": "L'introduction de l'œuvre d'art est-elle:",
        "Open interaction when tracking of art piece starts": "Ouvrir l'Interaction lorsque l'œuvre est détectée",
        "Close interaction when tracking of art piece stops": "Arrêter l'Interaction lorsque de l'œuvre n'est plus détectée",
        "User cannot close interaction manually": "L'utilisateur ne peut pas fermer l'interaction manuellement",
        "Art piece information box is visible during interaction": "La boîte d'information sur l'œuvre est visible pendant l'interaction",
        "Transform (9 comma-separated values: 3 translate, 3 rotation, 3 scale)": "Transformer (9 valeurs séparées par des virgules: 3 translation, 3 rotation, 3 échelle)",
        "Dismiss as introduction": "Ignorer comme introduction",
        "Choose as introduction": "Choisir comme introduction",
        "Hide in regular Art Scanner mode": "Masquer en mode Art Scanner normal",
        "Show in regular Art Scanner mode": "Afficher en mode Art Scanner normal",
        "Introduction": "Introduction",
        "Introduction settings saved for \"${v}\"": "Paramètres d'introduction enregistrés pour \"${v}\"",
        "Updated visibility of \"${v.name}\"": "Visibilité mise à jour de \"${v.name}\"",

        "Text": "Texte",
        "Audio": "Audio",
        "Video": "Vidéo",
        "Multiple Images": "Plusieurs images",
        "Perspective": "Perspective",

        "File name": "Nom du fichier",
        "Display orthogonal to art piece": "Affichage orthogonal à l'œuvre d'art",
        "Audio file": "Fichier Audio",
        "Face descriptors as JSON": "Descripteurs de visage au format JSON",
        "Custom Tutorial Text": "Texte du didacticiel personnalisé",
        "Label": "Etiquette",
        "Show as AR overlay on art piece": "Afficher en superposition AR sur une œuvre d'art",
        "Layers": "Calques",
        "Layer parameters": "paramètres de Calque",
        "Perpendicular layers": "Calques perpendiculaires",
        "Perpendicular layer parameters": "Paramètres de Calques perpendiculaire",
        "Global parameters": "Paramètres globaux",
        "Images": "Images",
        "Titles": "Titres",
        "Texts": "Textes",
        "Audio files": "Fichiers Audio",
        "Text pages": "Pasges de Texte",        
        "Video file": "Fichiers Video",
        "Caption": "Sous-titre",
        "Caption page": "Page de Sous-titre",
        "AR image scaling mode": "Mode de mise à l'échelle de l'image AR",
        "AR image scaling modes": "Modes de mise à l'échelle de l'image AR",
        "Image scaling mode": "Mode de mise à l'échelle de l'image",

        "Face descriptor": "Descripteur de visage",
        "Layer": "Calque",
        "Layer parameter": "paramètres de Calque",
        "Perpendicular layer": "Calques perpendiculaires",
        "Perpendicular layer parameter": "Paramètres de Calques perpendiculaire",
        "Page": "Page",

        "Enter author's name...": "Enter author's name...",
        "Enter title...": "Saisir le titre...",
        "Enter text...": "Saisir le text...",
        "Enter teaser...": "Enter teaser...",
        "Enter link label...": "Saisir l'étiquette du lien...",

        "Encyclopedia": "Encyclopédie",
        "Category": "Catégorie",
        "Entries": "Entrées",

        "Topic": "Topic",
        "Topics": "Topics",
        "Acquirer": "Acquirer",
        "Acquirers": "Acquirers",
        "Article": "Article",
        "Articles": "Articles",
        "Artist": "Artist",
        "Artists": "Artists",
        "Art Piece": "Art Piece",
        "Art Pieces": "Art Pieces",
        "Art Movement": "Art Movement",
        "Art Movements": "Art Movements",
        "Categories": "Categories",
        "Focus": "Focus",
        "Focuses": "Focuses",
        "Location": "Location",
        "Locations": "Locations",

        "By ${v}": "Par ${v}",
        "1 minute": "1 minute",

        "Create Information Page": "Create Information Page",
        "Information page \"${v.name}\" created.": "Information page \"${v.name}\" created.",
        "Type": "Type",
        "Edit Settings of Information Page": "Edit Settings of Information Page",
        "Edit Settings of Information Page ${v.name}": "Edit Settings of Information Page ${v.name}",
        "Edit Information Page ${v.name}": "Edit Information Page ${v.name}",
        "Changes to information page \"${v.name}\" saved.": "Changes to information page \"${v.name}\" saved.",
        "Add Information Page": "Add Information Page",
        "Delete Information Page?": "Delete Information Page?",
        "Information page \"${v.name}\" deleted.": "Information page \"${v.name}\" deleted.",
        "Edit Content": "Edit Content",
 
        "Add Media": "Ajouter un média",
        "Upload": "Télécharger",
        "Delete Media?": "Supprimer le média?",
        "Media \"${v.name}\" added.": "Média \"${v.name}\" ajouté.",
        "Changes for media \"${v.name}\" saved.": "Changements pour le media \"${v.name}\" enregistrés.",
        "Upload Date": "Date de téléchargement",
        "File Origin Date": "Date d'origine du fichier",
        "File Size": "Taille du fichier",
        "File Type": "Type du fichier",
        "File Revision": "Révision du fichier",
        "Select a File...": "Sélectionner un fichier...",
        "Update File...": "Mise à jour du fichier...",
        "The file \"${v}\" will be overwritten when clicking UPDATE.": "Le fichier \"${v}\" sera écrasé en cliquant sur METTRE À JOUR.",
        "Sorry, this file type is not supported.": "Désolé, ce type de fichier n'est pas supporté.",
        "Undo File Change": "Annuler la modification du fichier",
        "Open Asset": "Ouvrir l'élément",
        "Download": "Télécharger",
        "bytes": "octets",
        "Edit Media Settings": "Modifier les paramètres de média",
        "Edit Media Settings of ${v.name}": "Modifier les paramètres du média ${v.name}",
        "Prevent the app from caching this file": "Empêcher l'application de mettre en cache ce fichier",
        "Original File Name": "Nom du fichier d'origine",
        "Size": "Taille",
        "Caching": "Mise en cache",
        "Allowed": "Autorisé",
        "Forbidden": "Interdit",
        "Original file ID": "ID du fichier d'origine",
        "Very low quality file ID": "ID de fichier de très mauvaise qualité",
        "Low quality file ID": "ID de fichier de mauvaise qualité",
        "Medium quality file ID": "ID de fichier de qualité moyenne",
        "High quality file ID": "ID de fichier de haute qualité",
        "Very high quality file ID": "ID de fichier de très haute qualité",
        "JSON media entry": "Entrée du média JSON",

        "Advanced Search": "Recherche avancée",
        "File type": "Type de fichier",
        "File size": "Taille du fichier",
        "Date uploaded": "Date de téléchargement",
        "Image files": "Fichiers image",
        "Audio files": "Fichiers audio",
        "Video files": "Fichiers vidéo",
        "Text files": "Fichiers texte",
        "System files": "Fichiers système",
        "< 100 KB": " < 100 KB",
        "100 KB - 1 MB": "100 KB - 1 MB",
        "1 MB - 10 MB": "1 MB - 10 MB",
        "> 10 MB": "> 10 MB",
        "Today": "Aujourd'hui",
        "Last 3 days": "3 derniers jours",
        "This week": "Cette semaine",
        "This month": "Ce mois-ci",
        "This year": "Cette année",

        "Languages": "Langues",
        "Add Language": "Ajouter une langue",
        "Priority": "Priorité",
        "IETF language tag": "IETF language tag",
        "Increase priority": "Increase priority",
        "Decrease priority": "Decrease priority",
        "Language \"${v.name}\" added.": "Language \"${v.name}\" added.",
        "Edit Language ${v.ietfLanguageTag}": "Edit Language ${v.ietfLanguageTag}",
        "Changes to language \"${v.name}\" saved.": "Changes to language \"${v.name}\" saved.",
        "Delete Language?": "Delete Language?",
        "Language \"${v.name}\" deleted.": "Language \"${v.name}\" deleted.",
        "Language priorities updated.": "Language priorities updated.",
        "Localization JSON file": "Localization JSON file",
        "Localization JSON file assigned.": "Localization JSON file assigned.",

        "Minimal App Version": "Minimal App Version",
        "Version number": "Version number",
        "The version number may only consist of numbers seperated by dots.": "The version number may only consist of numbers seperated by dots.",
        "Minimum app version updated to ${v}.": "Minimum app version updated to ${v}.",

        "App Settings": "App Settings",
        "Create New App Setting": "Create New App Setting",
        "Value": "Value",
        "Setting \"${v.key}\" created.": "Setting \"${v.key}\" created.",
        "Changes for app setting \"${v.key}\" saved.": "Changes for app setting \"${v.key}\" saved.",
        "Delete App Setting?": "Delete App Setting?",
        "App setting \"${v.key}\" deleted.": "App setting \"${v.key}\" deleted.",

        "Server Settings": "Server Settings",
        "Curation lock": "Curation lock",
        "Server settings updated.": "Server settings updated.",

        "Interaction Modules": "Interaction Modules",
        "Hide interaction module from curator": "Hide interaction module from curator",
        "Show interaction module to curator": "Show interaction module to curator",
        "Interaction module's visibility updated.": "Interaction module's visibility updated.",
        "ID": "ID",
        "Version Number": "Version Number",
        "Parameters": "Parameters",
        "Parameter Name": "Parameter Name",
        "Uses of ${v.title}": "Uses of ${v.title}",
        "Art piece missing!": "Art piece missing!",
        "Required": "Required",
        "Localized": "Localized",
        "There are ${v} interactions with missing interaction modules.": "There are ${v} interactions with missing interaction modules.",
        "Interactions with Missing Interaction Modules": "Interactions with Missing Interaction Modules",
        "Interaction Module ID": "Interaction Module ID",

        "Information Page Types": "Information Page Types",
        "Hide information page type from curator": "Hide information page type from curator",
        "Show information page type to curator": "Show information page type to curator",
        "Information page type's visibility updated.": "Information page type's visibility updated.",

        "Information Page Blocks": "Information Page Blocks",
        "Hide information page block from curator": "Hide information page block from curator",
        "Show information page block to curator": "Show information page block to curator",
        "Information page block's visibility updated.": "Information page block's visibility updated.",

        "Environment Variables": "Environment Variables",
        "Server Log": "Server Log",
        "Automatically update every ${v} seconds": "Automatically update every ${v} seconds",
        "Bash Scripts": "Bash Scripts",
        "Call": "Call",
        "No bash scripts configured.": "No bash scripts configured.",
        "Bash script list must be configured in these files:": "Bash script list must be configured in these files:",

        "Validation error: any.required": "This field cannot be left empty.",
        "Validation error: any.empty": "This field cannot be left empty.",
        "Validation error: string.empty": "This field cannot be left empty.",
        "Validation error: string.email": "This is not a valid email address.",
        "Validation error: string.max": "This input contains too many characters.",
        "Validation error: string.min": "This input contains too few characters.",
        "Validation error: string.base": "This input is invalid.",
        "Validation error: string.alphanum": "This input may only contain uppercase and lowercase letters and numbers.",
        "Validation error: number.base": "This input must be a number",
        "Validation error: userEmail.alreadyExists": "There is already a user with this email address.",
        "Validation error: mediaName.alreadyExists": "There is already a media file with this name.",
        "Validation error: target.alreadyInUse": "This target is already being used by another art piece.",
        "Validation error: interactionModuleName.alreadyExists": "There is already an interaction module with this name.",
        "Validation error: informationPageTypeName.alreadyExists": "There is already an information page type with this name.",
        "Validation error: informationPageModuleName.alreadyExists": "There is already an information page module with this name.",
        "Validation error: paramName.alreadyExists": "There is already a parameter with this name.",
        "Validation error: file.required": "This field cannot be left empty.",
        "Validation error: paramName.notAllowed": "This parameter name is invalid.",
        "Validation error: interactionModule.inUse": "This interaction module is still being used.",
        "Validation error: artPieceId.alreadyInExhibition": "This art piece already exists in this exhibition.",
        "Validation error for language ${v.language}: any.empty": "The translation for \"${v.language}\" cannot be left empty.",
        "Validation error for language ${v.language}: string.max": "The translation for \"${v.language}\" contains too many characters.",
        "Validation error for language ${v.language}: string.base": "The translation for \"${v.language}\" cannot be left empty.",

        "Exhibit.": "Exhibitions",
        "AR Content.": "Contenu RA",
        "Info. Dat.": "Info. Database",
        "Analytics.": "Analytics",
        "Users.": "Users",
        "System.": "System",

        "Analytics": "Analytics",
        "App Statistics": "App Statistics",
        "Museum Transition Map": "Museum Transition Map",
        "Art Piece Transition Map": "Art Piece Transition Map",
        "Update": "Update",
        "App Usage": "App Usage",
        "Feature Usage": "Feature Usage",
        "Art Pieces Tracked": "Art Pieces Tracked",
        "All": "All",
        "Start Date": "Start Date",
        "End Date": "End Date",
        "Exhibition": "Exhibition",
        "Day": "Day",
        "Unique Users": "Unique Users",
        "App Launches": "App Launches",
        "Feature": "Feature",
        "Hours used": "Hours used",
        "Seconds used": "Seconds used",
        "Artwork": "Artwork",
        "Tracking events": "Tracking events",

        "AnalyticsFeature:Home": "Home",
        "AnalyticsFeature:InfoPage": "Encyclopedia",
        "AnalyticsFeature:Trail": "Tour",
        "AnalyticsFeature:AR": "Art scanner",
        "AnalyticsFeature:Map": "Map",
        "AnalyticsFeature:BurgerMenu": "Main menu",
        "AnalyticsFeature:HistoryTimeline": "Historical timeline",
        "AnalyticsFeature:WorldMap": "World map",
        "AnalyticsFeature:Search": "Search",
        "AnalyticsFeature:None": "Other",
        "${v} of usage": "${v} of usage",
        "${v} of total usage": "${v} of total usage",
        "No data": "No data",

        "Art-Piece Visit Statistics": "Art-Piece Visit Statistics",
        "Art Piece": "Art Piece",
        "Start of App Usage": "Start of App Usage",
        "Popularity": "Popularity",
        "Visits": "Visits",
        "Time Spent": "Time Spent",
        "Average Time Spent": "Average Time Spent",
        "Time Spent in AR": "Time Spent in AR",
        "End of App Usage": "End of App Usage",
        "Art Pieces visited next": "Art Pieces visited next",
        "Count": "Count",
        "Probability": "Probability",

        "Interaction Visit Statistics": "Interaction Visit Statistics",
        "Interactions visited next": "Interactions visited next",
        "spent on average": "spent on average",
        "first visited interaction of this art piece": "first visited interaction of this art piece",
        "last visited interaction of this art piece": "last visited interaction of this art piece",
        "Select Art Piece": "Select Art Piece",
        "Select Art Piece...": "Select Art Piece...",
        "Interaction": "Interaction",
        "First Interaction": "First Interaction",
        "Last Interaction": "Last Interaction",

        "times visited": "times visited",
        "spent here on average": "spent here on average",
        "spent here on average in AR mode": "spent here on average in AR mode",
        "first visited painting of the exhibition": "first visited painting of the exhibition",
        "last visited painting of the exhibition": "last visited painting of the exhibition",

        "In case you need help or support, please contact:": "In case you need help or support, please contact:",
        "Credits": "Credits",

        "Error 403": "Error 403",
        "You are not permitted to access this page.": "You are not permitted to access this page.",
        "Error 404": "Error 404",
        "Page not found.": "Page not found."
    }
}