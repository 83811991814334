<template>
    <div>
        <v-container fluid class="pb-5 px-0 mx-0">
            <v-row align="center" no-gutters>
                <v-col cols="4">
                    <v-text-field v-model="search" append-icon="mdi-magnify" :label="l('Search')" single-line clearable hide-details />
                </v-col>
                <v-spacer />
                <v-col class="text-right">
                    <v-btn v-for="version in visibleVersions" :key="version.versionNumber" color="primary" class="pl-3 ml-5" @click="addInformationPage(version.versionNumber)">
                        <v-icon class="mr-2">
                            add
                        </v-icon>
                        {{ l("Add ${v}", l(version.title) + (visibleVersions.length > 1 ? ' (v' + version.versionNumber + ')' : '')) }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
        <v-data-table :headers="headers" :items="list" :sort-by="sortBy" :items-per-page="itemsPerPage" :footer-props="pagination" :loading="loading" :search="search" :no-data-text="l('No entries.')">
            <template #item="row">
                <tr>
                    <td v-if="!row.item.image" class="clickable pr-0 centerIcon" style="width:40px" @click="edit(row.item)">
                        <v-icon>
                            {{ row.item.version.mdiIcon }}
                        </v-icon>
                    </td>
                    <td v-if="row.item.image" class="clickable pr-0" style="width:40px" @click="edit(row.item)">
                        <img :src="row.item.image" class="preview-image">
                    </td>
                    <td v-for="(value, key) of row.item.columns" :key="key" class="clickable" @click="edit(row.item)">
                        <div class="overflowEllipses">
                            {{ value }}
                        </div>
                    </td>
                    <td class="text-right" style="width: 104px">
                        <v-tooltip v-if="!row.item.hidden" left>
                            <template #activator="{ on }">
                                <v-btn text icon color="primary" v-on="on" @click="toggleVisibility(row.item)">
                                    <v-icon>mdi-eye</v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">{{ l("Hide in App") }}</span>
                        </v-tooltip>
                        <v-tooltip v-if="row.item.hidden" left>
                            <template #activator="{ on }">
                                <v-btn text icon color="primary" v-on="on" @click="toggleVisibility(row.item)">
                                    <v-icon>mdi-eye-off-outline</v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">{{ l("Show in App") }}</span>
                        </v-tooltip>
                        <!--<v-tooltip left>
                        <template #activator="{ on }">
                            <v-btn text icon color="primary" v-on="on" @click="editInformationPageSettings(row.item)">
                                <v-icon>settings</v-icon>
                            </v-btn>
                        </template>
                        <span class="body-2">{{ l("Edit Settings of Information Page") }}</span>
                        </v-tooltip>-->
                        <v-tooltip left>
                            <template #activator="{ on }">
                                <v-btn text icon color="primary" v-on="on" @click="deleteEntry(row.item)">
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">{{ l("Delete") }}</span>
                        </v-tooltip>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>
<script>

    import InformationPageService from "../../services/InformationPageService.js";
    import ListUtils from "../../utils/ListUtils.js";
    import { deepClone } from "../../utils/objectUtils.js";
    import { inContentLocale } from "../../locales/utils/localeUtils.js";
    import { l, getLocalizedParameterValue } from "../../utils/LocalizationUtils.js";
    import { getParameterValue } from "../../utils/ParameterUtils.js";


    export default ListUtils.setupList({
        props: {
            informationPageType: { type: Object, default: null }
        },
        data() {
            return {
                visibleVersions: [],
                versionLookup: {}
            };
        },
        computed: {
            headers() {
                const columnHeaders = [];
                if(this.informationPageType && this.informationPageType.listColumns) {
                    this.informationPageType.listColumns.forEach(column => {
                        if(column.sortable) {
                            columnHeaders.push({ text: column.header, value: "columns." + column.value });
                        } else {
                            columnHeaders.push({ text: column.header, value: "columns." + column.value, sortable: false });
                        }
                    });
                }
                return [
                    { text: "", sortable: false },
                    ...columnHeaders,
                    { text: "", sortable: false }
                ];
            }
        },
        sortBy: "name",
        update() {

            this.showLoading();

            this.visibleVersions = [];
            this.informationPageType.versions.forEach(version => {
                if (version.isVisibleToCurator) {
                    this.visibleVersions.push(version);
                }
            });

            this.versionLookup = {};
            this.informationPageType.versions.forEach(version => {
                this.versionLookup[version.versionNumber] = version;
            });

            const infoPageNameLookup = {};
            InformationPageService.getInformationPages(all => {
                all.forEach(page => {
                    infoPageNameLookup[page._id] = getLocalizedParameterValue(inContentLocale(), page.attributes, "name");
                });

                InformationPageService.getInformationPagesByType(this.informationPageType.id, list => {
                    list.forEach(page => {

                        page.version = this.versionLookup[page.informationPageTypeVersion];
                        page.name = getLocalizedParameterValue(inContentLocale(), page.attributes, "name");
                        const image = getParameterValue(page.attributes, "image");
                        page.image = image ? "/assets/" + image + ".jpg?quality=veryLow" : false;

                        page.columns = {};
                        this.informationPageType.listColumns.forEach(column => {
                            
                            let columnValue = "";
                            const attribute = page.version.attributes.find(attribute => attribute.key === column.value);
                            if(attribute) {
                                if(attribute.localized) {
                                    columnValue = getLocalizedParameterValue(inContentLocale(), page.attributes, column.value);
                                } else {
                                    columnValue = getParameterValue(page.attributes, column.value);
                                }
                                if(attribute.type === "Array of Pages" && Array.isArray(columnValue)) {
                                    const pageNames = [];
                                    columnValue.forEach(referencedPage => {
                                        if(referencedPage) {
                                            if (typeof referencedPage === "object" && referencedPage.reference) {
                                                if (infoPageNameLookup.hasOwnProperty(referencedPage.reference)) {
                                                    pageNames.push(infoPageNameLookup[referencedPage.reference]);
                                                }
                                            } else if (infoPageNameLookup.hasOwnProperty(referencedPage)) {
                                                pageNames.push(infoPageNameLookup[referencedPage]);
                                            }
                                        } 
                                    });
                                    columnValue = pageNames.join(", ");
                                }
                            }
                            page.columns[column.value] = columnValue ? columnValue : "";
                        });
                    });
                    this.list = list;
                    this.hideLoading();
                });
            });
        },
        deleteSettings : {
            promptTitle: "Delete Information Page?",
            promptQuestion: "Are you sure you want to delete \"${v.name}\"?",
            action: InformationPageService.deleteInformationPage,
            success: "Information page \"${v.name}\" deleted."
        },
        methods: {
            addInformationPage(versionNumber) {
                this.$emit("add", versionNumber);
            },
            toggleVisibility(informationPage) {
                informationPage.hidden = !informationPage.hidden;
                const editedInformationPage = deepClone(informationPage);
                InformationPageService.updateInformationPage(editedInformationPage, () => {
                    this.$root.$emit("success", this.l("Updated visibility of \"${v.name}\"", editedInformationPage));
                });
            },
            editInformationPageSettings(entry) {
                this.$emit("editSettings", entry);
            },
            l
        }
    });
</script>
<style scoped>

    .clickable {
        cursor: pointer;
    }

    .centerIcon {
        text-align: center;
    }

    .preview-image {
        display: block;
        max-width: 40px;
        max-height: 40px;
        margin: 0 auto;
    }

    .overflowEllipses {
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 320px;
        overflow: hidden;
    }

</style>