<template>
    <WindowedForm v-model="form" />
</template>
<script>

    import MediaService from "../../services/MediaService.js";
    import WindowedForm from "../globalUiElements/WindowedForm.vue";
    import { createForm } from "../../utils/FormUtils.js";
    import { backwardCompatibleL } from "../../utils/LocalizationUtils.js";
    import { l } from "../../localization/localization.js";


    export default {
        components: {
            WindowedForm
        },
        props: {
            entry : { type: Object, default: null },
            acceptedFileFormats: { type: Object, default: () => {} },
        },
        data() {
            return {
                form: createForm({

                    title: {
                        label: "Editing ${v.name}",
                        entry: this.entry
                    },

                    action: MediaService.updateMedia,

                    fieldDefs: [
                        {
                            key: "_id",
                            type: "Hidden",
                            value: this.entry._id
                        },
                        {
                            key: "name",
                            type: "Text",
                            label: "Name",
                            value: this.entry.name,
                            required: true
                        },
                        {
                            key: "file",
                            type: "FileUpload",
                            label: "Update File...",
                            acceptedFileFormats: this.acceptedFileFormats,
                            existingFile: this.entry,
                            required: true
                        },
                        {
                            uiElement: "InfoList",
                            items: [
                                l.cms.media.acceptedImageFormats(),
                                l.cms.media.maxImageResolution(),
                                l.cms.media.acceptedAudioFormats(),
                                l.cms.media.acceptedVideoFormats(),
                                l.cms.media.maxFileSize(),
                            ],
                        },
                    ],

                    submitLabel: "Update",

                    submitted: entry => {
                        this.$root.$emit("success", backwardCompatibleL("Changes for media \"${v.name}\" saved.", entry));
                        this.$emit("editedEntry");
                    },

                    cancelled: () => {
                        this.$emit("cancel");
                    }
                })
            }
        }
    }
</script>