<template>
    <div class="manager">
        <div class="toolbarContainer">
            <LocationPlacementToolbar
                :viewport-zoom="viewportZoom"
                :snapping="snapping"
                :show-guides="showGuides"
                @changeZoom="changeZoom"
                @toggleSnapping="toggleSnapping"
                @toggleGuides="toggleGuides"
                @addLocation="openAddLocation"
            />
        </div>
        <div class="viewerContainer">
            <LocationPlacementViewer
                :floor="floor"
                :viewport-zoom="viewportZoom"
                :locations="locations"
                :location-being-hovered-over="locationBeingHoveredOver"
                :location-being-edited="locationBeingEdited"
                :snapping="snapping"
                :show-guides="showGuides"
                :lock-axis="lockAxis"
                :loading="loading"
                :saving="saving"
                @hover="onHover"
                @startPlacing="onStartPlacing"
                @place="saveLocation"
                @changeZoom="changeZoom"
            />
        </div>
        <div class="sidebarContainer">
            <LocationPlacementSidebar
                :locations="locations"
                :location-being-hovered-over="locationBeingHoveredOver"
                :location-being-edited="locationBeingEdited"
                :loading="loading"
                :saving="saving"
                @hover="onHover"
                @startPlacing="onStartPlacing"
                @save="saveLocation"
                @deleteItem="deleteLocation"
                @reportFocusOnSearchField="reportFocusOnSearchField"
            />
        </div>
        <AddLocation
            v-if="showAddLocation"
            :exhibition="exhibition"
            :floor="floor"
            :saving="savingNewLocation"
            @cancel="closeAddLocation"
            @addedLocation="onAddedLocation"
        />
    </div>
</template>
<script>

    import ArtPiecePlacementService from "../../services/ArtPiecePlacementService.js";
    import LocationPlacementToolbar from "./LocationPlacementToolbar.vue";
    import LocationPlacementViewer from "./LocationPlacementViewer.vue";
    import LocationPlacementSidebar from "./LocationPlacementSidebar.vue";
    import AddLocation from "./AddLocation.vue";
    import { updateLookups } from "../../utils/referenceUtils.js";
    import { getPreviewDataFromId } from "../../utils/referencePreviewUtils.js";
    import { l } from "../../utils/LocalizationUtils.js";


    const KEY = {
        ESC: 27,
        DEL: 46,
        A: 65,
        X: 88,
    };


    export default {
        components: {
            LocationPlacementToolbar,
            LocationPlacementViewer,
            LocationPlacementSidebar,
            AddLocation
        },
        props: {
            exhibition: { type: Object, default: null },
            floor: { type: Object, default: null },
        },
        data() {
            return {

                loading: false,
                saving: false,
                savingNewLocation: false,
                locations: [],

                viewportZoom: 1.0,
                minZoom: 0.16777216, // 0.8^8
                maxZoom: 35.527136788, // 1.25^16

                locationBeingHoveredOver: null,
                locationBeingEdited: null,

                snapping: true,
                snappingPause: false,
                showGuides: true,
                lockAxis: false,

                showAddLocation: false,

                focusOnSearchField: false,
            };
        },
        watch: {
            floor: {
                async handler() {

                    this.loading = true;
                    this.saving = false;
                    this.savingNewLocation = false;
                    this.locations = [];

                    this.viewportZoom = 1.0;

                    this.locationBeingHoveredOver = null;
                    this.locationBeingEdited = null;

                    await this.update();

                    this.loading = false;
                },
                immediate: true,
            },
        },
        mounted() {
            window.addEventListener("keydown", this.keyDownHandler);
            window.addEventListener("keyup", this.keyUpHandler);
        },
        beforeDestroy() {
            window.removeEventListener("keydown", this.keyDownHandler);
            window.removeEventListener("keyup", this.keyUpHandler);
        },
        methods: {

            update() {
                return new Promise(async resolve => {
                    if(this.floor) {
                        await updateLookups(["pages"]);

                        const locations = await ArtPiecePlacementService.getArtPiecePlacementsOfFloor(this.floor._id);
                        locations.forEach(location => {
                            location.previewData = getPreviewDataFromId("page", location.artPieceId);
                        });
                        this.locations = locations.sort((a, b) => a.previewData.label.localeCompare(b.previewData.label));
                    }
                    resolve();
                });
            },

            keyDownHandler(event) {

                if(!this.focusOnSearchField) {
                    switch(event.keyCode) {

                    case KEY.A: 
                        if(this.locationBeingEdited) {
                            this.lockAxis = true;
                        }
                        break;

                    case KEY.X:
                        if(this.locationBeingEdited && !this.snappingPause) {
                            this.snapping = !this.snapping;
                            this.snappingPause = true;
                        }
                        break;
                    }
                }
            },

            keyUpHandler(event) {

                if(!this.focusOnSearchField) {
                    switch(event.keyCode) {

                    case KEY.ESC:
                        if(this.locationBeingEdited) {
                            this.locationBeingEdited = null;
                            event.preventDefault();
                        }

                    case KEY.DEL:
                        if(this.locationBeingEdited) {
                            this.deleteLocation();
                        }

                    case KEY.A: 
                        if(this.lockAxis) {
                            this.lockAxis = false;
                        }
                        break;

                    case KEY.X:
                        if(this.snappingPause) {
                            this.snapping = !this.snapping;
                            this.snappingPause = false;
                        }
                        break;
                    }

                    if(event.key === "+") {
                        this.changeZoom(this.viewportZoom * 1.25);
                    } else if(event.key === "-") {
                        this.changeZoom(this.viewportZoom * 0.8);
                    }
                }
            },

            changeZoom(newZoom) {
                this.viewportZoom = Math.min(Math.max(newZoom, this.minZoom), this.maxZoom);
            },

            toggleSnapping() {
                this.snapping = !this.snapping;
            },

            toggleGuides() {
                this.showGuides = !this.showGuides;
            },

            openAddLocation() {
                this.locationBeingEdited = null;
                this.showAddLocation = true;
            },

            closeAddLocation() {
                this.showAddLocation = false;
            },

            async onAddedLocation(newLocationId) {
                this.savingNewLocation = true;
                await this.update();
                this.locationBeingEdited = this.locations.find(location => location._id == newLocationId);
                this.closeAddLocation();
                this.savingNewLocation = false;
            },

            onHover(location) {
                this.locationBeingHoveredOver = location;
            },

            onStartPlacing(location) {
                this.locationBeingHoveredOver = null;
                this.locationBeingEdited = location;
            },

            async saveLocation(newProperties) {
                this.saving = true;
                if(typeof newProperties.positionX !== "undefined") {
                    this.locationBeingEdited.positionX = newProperties.positionX;
                }
                if(typeof newProperties.positionY !== "undefined") {
                    this.locationBeingEdited.positionY = newProperties.positionY;
                }
                if(typeof newProperties.locked !== "undefined") {
                    this.locationBeingEdited.locked = newProperties.locked;
                }
                await ArtPiecePlacementService.updateArtPiecePlacement({
                    _id: this.locationBeingEdited._id,
                    artPieceId: this.locationBeingEdited.artPieceId,
                    exhibitionId: this.locationBeingEdited.exhibitionId,
                    floorId: this.locationBeingEdited.floorId,
                    positionX: this.locationBeingEdited.positionX,
                    positionY: this.locationBeingEdited.positionY,
                    heightAboveFloorInMeters: this.locationBeingEdited.heightAboveFloorInMeters,
                    rotation: this.locationBeingEdited.rotation,
                    room: this.locationBeingEdited.room || "",
                    wall: this.locationBeingEdited.wall || "",
                    locked: this.locationBeingEdited.locked || false,
                });

                await this.update();

                this.locationBeingEdited = this.locations.find(location => location._id == this.locationBeingEdited._id);

                this.saving = false;
            },

            deleteLocation() {
                this.$root.$emit(
                    "confirm",
                    l("Delete location"),
                    l("Do you really want to delete this location?"),
                    l("Delete"),
                    async yes => {
                        if (yes) {
                            this.saving = true;
                            await ArtPiecePlacementService.deleteArtPiecePlacement(this.locationBeingEdited._id);
                            await this.update();
                            this.locationBeingEdited = null;
                            this.saving = false;
                        }
                    }
                );
            },

            reportFocusOnSearchField(value) {
                this.focusOnSearchField = value;
            },

        },
    }

</script>
<style scoped>

    .manager {
        position: absolute;
        left: 0;
        right: 0;
        top: 140px; /* height and top padding of topBar in Locations.vue */
        bottom: 0;
        border-top: 1px solid #e0e0e0;
    }

    .toolbarContainer {
        position: absolute;
        left: 0;
        right: 380px;
        top: 0;
        height: 51px;
        border-bottom: 1px solid #e0e0e0;
        overflow: hidden;
    }

    .addingOrDeletingProgressBar {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        z-index: 7;
    }

    .viewerContainer {
        position: absolute;
        left: 0;
        right: 380px;
        top: 51px;
        bottom: 0;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 12px inset;
        z-index: 6;
    }

    .sidebarContainer {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 380px;
        border-left: 1px solid #e0e0e0;
    }

</style>
