<template>
    <div class="defaultContentView">
        <h1 class="display-1 mb-3 primary--text">
            <v-icon color="primary" class="titleIcon">
                mdi-bookshelf
            </v-icon>
            {{ l("Topics") }}
        </h1>
        <InformationPagesList
            v-if="informationPageType"
            :information-page-type="informationPageType"
            :reload-trigger="listReloadTrigger"
            @add="add"
            @edit="edit"
            @editSettings="openEditSettingsForm"
        />
        <EditInformationPageSettings
            v-if="showEditSettingsForm"
            :entry="editingEntry"
            @cancel="closeEditSettingsForm"
            @editedEntry="editedEntry"
        />
    </div>
</template>
<script>

    import { mapActions } from "vuex";
    import InformationPagesList from "../../components/informationPages/InformationPagesList.vue";
    import EditInformationPageSettings from "../../components/informationPages/EditInformationPageSettings.vue";
    import InformationPageTypesService from "../../services/InformationPageTypesService.js";
    import { assignMostRecentTitleAndIcon } from "../../pages/utils/pageUtils.js";
    import { l } from "../../utils/LocalizationUtils.js";

    export default {
        components: {
            InformationPagesList,
            EditInformationPageSettings
        },
        data() {
            return {
                informationPageType: null,
                listReloadTrigger: 0,
                showEditSettingsForm: false,
                editingEntry: null
            }
        },
        created() {
            this.updateSectionTitle(this.l("Topics"));
            InformationPageTypesService.getInformationPageType("topic", type => {
                this.informationPageType = assignMostRecentTitleAndIcon(type);
            });
        },
        methods: {
            add(versionNumber) {
                this.$router.push("/topics/" + versionNumber + "/new");
            },
            edit(entry) {
                this.$router.push("/topics/" + entry._id);
            },
            openEditSettingsForm(entry) {
                this.editingEntry = entry;
                this.showEditSettingsForm = true;
            },
            closeEditSettingsForm() {
                this.showEditSettingsForm = false;
                this.editingEntry = null;
            },
            editedEntry() {
                this.closeEditSettingsForm();
                this.listReloadTrigger++;
            },
            l,
            ...mapActions({
                updateSectionTitle: "platform/updateSectionTitle"
            })
        }
    }

</script>
