<template>
    <div class="topicBlockContainer">
        <div v-if="topic" class="topicBlock">
            <div class="topicSidebar" />
            <img v-if="topicImage" :style="'object-position:' + values.horizontalAlignment + ' ' + values.verticalAlignment" :src="'/assets/' + topicImage + '.jpg?quality=high'">
            <div class="topicGradientBox">
                <div v-if="topicTitle" class="topicTitle">
                    {{ topicTitle }}
                </div>
                <div v-if="topicDescription" class="topicDescription">
                    {{ topicDescription }}
                </div>
                <div v-if="topicDuration" class="topicDuration">
                    {{ topicDuration }}
                </div>
            </div>
            <div class="topLeftBadge">
                <v-icon class="topLeftIcon" size="18">
                    mdi-message-alert-outline
                </v-icon>
                <div class="topLeftLabel">
                    {{ topLeftLabel }}
                </div>
            </div>
        </div>
        <div v-if="topicNotFound" class="topicNotFound">
            <v-icon x-large>
                mdi-help
            </v-icon>
        </div>
    </div>
</template>
<script>

    import InformationPageService from "../../../services/InformationPageService.js";
    import { getParameterValue } from "../../../utils/ParameterUtils.js";
    import { inPreviewLocale } from "../../../locales/utils/localeUtils.js";
    import { l, lInPreviewLocale, getLocalizedParameterValue } from "../../../utils/LocalizationUtils.js";


    export default {
        props: {
            values: { type: Object, default: () => { } }
        },
        data() {
            return {
                topic: null,
                topicNotFound: false
            }
        },
        computed: {
            topicTitle() {
                if (this.topic) {
                    return getLocalizedParameterValue(inPreviewLocale(), this.topic.attributes, "name");
                }
                return "";
            },
            topicImage() {
                if (this.topic) {
                    return getParameterValue(this.topic.attributes, "image");
                }
                return null;
            },
            topicDescription() {
                if (this.topic) {
                    const description = getLocalizedParameterValue(inPreviewLocale(), this.topic.attributes, "intro");
                    return description.length > 70 ? description.substring(0, 70) + "…" : description;
                }
                return "";
            },
            topicDuration() {
                const readDuration = getParameterValue(this.topic.attributes, "readDuration");
                if (typeof readDuration === "undefined" || readDuration === null || readDuration === "" || readDuration <= 0) {
                    return "";
                } else if (readDuration == 1) { // do not ===
                    return lInPreviewLocale("1 minute");
                } else {
                    return lInPreviewLocale("${v} minutes", readDuration);
                }
            },
            topLeftLabel() {
                return lInPreviewLocale("Topic");
            }
        },
        watch: {
            values() {
                this.updateTopic();
            }
        },
        mounted() {
            this.updateTopic();
        },
        methods: {
            updateTopic() {
                this.topic = null;
                this.topicNotFound = false;
                if (this.values.id) {
                    InformationPageService.getInformationPage(this.values.id, topic => {
                        if (topic) {
                            this.topic = topic;
                        } else {
                            this.topicNotFound = true;
                        }
                    }, error => {
                        this.topicNotFound = true;
                    });
                } else {
                    this.topicNotFound = true;
                }
            },
            l
        }
    }

</script>
<style scoped>

    .topicBlockContainer {
        position: relative;
        width: 480px;
        height: 323px;
        background: #212121;
    }

    .topicBlock {
        position: absolute;
        left: 20px;
        top: 10px;
        width: 440px;
        height: 303px;
    }

    .topicSidebar {
        position: absolute;
        left: 0;
        top: 0;
        width: 4px;
        height: 303px;
        background: #651FFF;
    }

    .topicBlock img {
        position: absolute;
        display: block;
        left: 4px;
        top: 0;
        width: 436px;
        height: 303px;
        object-fit: cover;
    }


    .topicGradientBox {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 16px 16px 12px;
        background-image: linear-gradient(to bottom, rgba(33, 33, 33, 0.0), rgba(33, 33, 33, 0.8));
    }

    .topicTitle {
        padding-bottom: 4px;
        font-size: 30px;
        font-weight: 500;
        color: #fff;
    }

    .topicDescription {
        padding-bottom: 4px;
        font-size: 18px;
        color: #fff;
    }

    .topicDuration {
        font-size: 18px;
        font-style: italic;
        color: #fff;
    }

    .topLeftBadge {
        display: flex;
        align-items: center;
        position: absolute;
        left: 4px;
        top: 0;
        height: 24px;
        background: #424242;
    }

    .topLeftIcon {
        color: #fff;
        margin: 0 4px 0 6px;
    }

    .topLeftLabel {
        font-size: 15px;
        color: #fff;
        margin-right: 6px;
    }

    .topicNotFound {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: center;
        left: 20px;
        top: 10px;
        width: 440px;
        height: 303px;
        background: #999;
    }
    

</style>