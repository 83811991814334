<template>
    <WindowedForm v-model="form" />
</template>
<script>

    import MediaService from "../../services/MediaService.js";
    import WindowedForm from "../globalUiElements/WindowedForm.vue";
    import { createForm } from "../../utils/FormUtils.js";
    import { l } from "../../utils/LocalizationUtils.js";

    export default {
        components: {
            WindowedForm
        },
        props: {
            entry : { type: Object, default: null }
        },
        data() {
            return {
                form: createForm({

                    title: {
                        label: "Edit Media Settings of ${v.name}",
                        entry: this.entry
                    },

                    action: MediaService.updateMediaSettings,

                    fieldDefs: [
                        {
                            key: "_id",
                            type: "Hidden",
                            value: this.entry._id
                        },
                        {
                            key: "license",
                            type: "Textarea",
                            label: "License",
                            localized: false,
                            value: this.entry.license ? this.entry.license : ""
                        },
                        {
                            key: "copyright",
                            type: "Text",
                            label: "Copyright",
                            localized: true,
                            value: this.entry.copyright ? this.entry.copyright : ""
                        },
                        {
                            key: "doNotCache",
                            type: "Checkbox",
                            label: "Prevent the app from caching this file",
                            value: this.entry.doNotCache
                        },
                        {
                            key: "doNotLoadOnIOS",
                            type: "Checkbox",
                            label: "Prevent the app from loading this file on iOS",
                            value: this.entry.doNotLoadOnIOS
                        },
                        {
                            key: "doNotLoadOnAndroid",
                            type: "Checkbox",
                            label: "Prevent the app from loading this file on Android",
                            value: this.entry.doNotLoadOnAndroid
                        }
                    ],

                    submitLabel: "Update",

                    submitted: entry => {
                        this.$root.$emit("success", l("Changes for media \"${v.name}\" saved.", entry));
                        this.$emit("editedEntry");
                    },

                    cancelled: () => {
                        this.$emit("cancel");
                    }
                })
            }
        }
    }
</script>