
import * as tagService from "../services/tagService.js";
import { inContentLocale } from "../../locales/utils/localeUtils.js";
import { localize } from "../../utils/LocalizationUtils.js";
import store from "../../store/index.js";


export function getTag(tagId) {
    return store.getters["tags/getTag"](tagId);
}


export function getAllTags() {
    return store.getters["tags/getAllTags"];
}


export function getLabel(tag) {
    return localize(inContentLocale(), tag.name);
}


export function createTagLookup() {
    return new Promise(async (resolve, reject) => {
        try {
            const tags = await tagService.getAll();
            if (!Array.isArray(tags)) {
                resolve({});
            }
            const tagLookup = {};
            tags.forEach(tag => {
                tagLookup[tag._id] = tag;
            });
            resolve(tagLookup);
        } catch (error) {
            reject(`Could not create tag lookup: ${error}`);
        }
    });
}