import axios from "axios";
import { triggerError } from "../utils/alerts.js";


const url = "/api/interactions/";
const byARTargetUrl = "/api/interactions/byARTarget/";


class InteractionService {

    // Get
    static getInteraction(id, callback) {
        axios.get(`${url}${id}`).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }

    // Get all interactions by AR Target
    static getInteractionsByARTarget(arTargetId, callback) {
        if (callback) {
            axios.get(`${byARTargetUrl}${arTargetId}`).then(response => {
                callback(response.data);
            }).catch(error => {
                triggerError(error);
            });
        } else {
            return new Promise(async resolve => {
                try {
                    const response = await axios.get(`${byARTargetUrl}${arTargetId}`);
                    resolve(response.data);
                } catch (error) {
                    triggerError(error);
                    resolve([]);
                }
            });
        }
    }


    // Get all
    static getAllInteractions(callback) {
        axios.get(url).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }

    // Post
    static insertInteraction(interaction, callback) {
        axios.post(url, interaction).then(response => {
            callback(response.data);
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.isJoi) {
                callback({ errors: error.response.data.details });
            } else {
                triggerError(error);
            }
        });
    }

    // Put
    static updateInteraction(interaction, callback) {
        axios.put(`${url}${interaction._id}`, interaction).then(response => {
            response.data._id = interaction._id;
            callback(response.data);
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.isJoi) {
                callback({ errors: error.response.data.details });
            } else {
                triggerError(error);
            }
        });
    }

    // Delete
    static deleteInteraction(interactionId, callback) {
        axios.delete(`${url}${interactionId}`).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }

}

export default InteractionService;