
import { l, tryGetTokenOverride, selectLanguage } from "./localization.js";


// Release notes translations
export const releaseNotes = {


    
    pageTitle(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "pageTitle");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Release Notes";
            case "de": return "Versionshinweise";
            default: return `[pageTitle: ${language} missing]`;
        }
    
    },
            

    
    releaseNotesLabel(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "releaseNotesLabel");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Release Notes";
            case "de": return "Versionshinweise";
            default: return `[releaseNotesLabel: ${language} missing]`;
        }
    
    },
            

    
    versionNumberTitle(versionNumber, locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "versionNumberTitle");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Version " + versionNumber;
            case "de": return "Version " + versionNumber;
            default: return `[versionNumberTitle: ${language} missing]`;
        }
    
    },
            

    
    releaseDateLabel(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "releaseDateLabel");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Released:";
            case "de": return "Veröffentlicht am:";
            default: return `[releaseDateLabel: ${language} missing]`;
        }
    
    },
            

    
    newFeaturesLabel(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "newFeaturesLabel");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "New Features";
            case "de": return "Neue Funktionen";
            default: return `[newFeaturesLabel: ${language} missing]`;
        }
    
    },
            

    
    changesLabel(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "changesLabel");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Changes";
            case "de": return "Änderungen";
            default: return `[changesLabel: ${language} missing]`;
        }
    
    },
            
};
