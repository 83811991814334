<template>
    <div class="defaultContentView">
        <ViewTitle
            :title="pageTitle"
            icon="mdi-clipboard-text-outline"
        />
        <v-card
            v-for="(version, index) in versions"
            :key="index"
            elevation="5"
            class="pa-8 mb-10"
        >
            <div class="text-h5 font-weight-bold">
                {{ versionNumberTitle(version.number) }}
            </div>
            <div class="text-subtitle-1 grey--text text--darken-2">
                {{ releaseDateLabel }}
                {{ dateString(version.date.year, version.date.month, version.date.day) }}
            </div>
            <div v-if="version.newFeatures && version.newFeatures.length > 0">
                <div class="font-weight-bold mt-8 mb-2">
                    {{ newFeaturesLabel }}
                </div>
                <ul class="my-0">
                    <li v-for="(item, index2) in version.newFeatures" :key="index2" class="my-2 ml-3 pl-1">
                        {{ localize(item) }}
                    </li>
                </ul>
            </div>
            <div v-if="version.changes && version.changes.length > 0">
                <div class="font-weight-bold mt-8 mb-2">
                    {{ changesLabel }}
                </div>
                <ul class="my-0">
                    <li v-for="(item, index2) in version.changes" :key="index2" class="my-2 ml-3 pl-1">
                        {{ localize(item) }}
                    </li>
                </ul>
            </div>
        </v-card>
    </div>
</template>
<script>

    import ViewTitle from "../../components/globalUiElements/ViewTitle.vue";
    import { updateDocumentTitle } from "../../platform/utils/platformUtils.js";
    import { l, dateString } from "../../localization/localization.js";
    import { inCMSLocale } from "../../locales/utils/localeUtils.js";
    import { localize } from "../../utils/LocalizationUtils.js";
    import { versions } from "../providers/releaseNotes.js";


    export default {
        components: {
            ViewTitle,
        },
        data() {
            return {
                
            };
        },
        computed: {
            pageTitle: () => l.cms.releaseNotes.pageTitle(),
            releaseDateLabel: () => l.cms.releaseNotes.releaseDateLabel(),
            newFeaturesLabel: () => l.cms.releaseNotes.newFeaturesLabel(),
            changesLabel: () => l.cms.releaseNotes.changesLabel(),
            versions: () => versions,
        },
        mounted() {
            updateDocumentTitle(this.pageTitle);
        },
        methods: {
            versionNumberTitle: versionNumber => l.cms.releaseNotes.versionNumberTitle(versionNumber),
            dateString(year, month, day) {
                return dateString(inCMSLocale(), year, month, day);
            },
            localize: content => localize(inCMSLocale(), content),
        },
    }
</script>