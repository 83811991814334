<template>
    <v-btn
        color="primary"
        :disabled="disabled"
        class="pl-3 mr-0"
        @click="click"
    >
        <v-icon class="mr-2">
            {{ icon }}
        </v-icon>
        {{ label }}
    </v-btn>
</template>
<script>

    export default {
        props: {
            icon: { type: String, default: "mdi-help-rhombus-outline" },
            label: { type: String, default: "" },
            disabled: { type: Boolean, default: false },
        },
        methods: {
            click() {
                this.$emit("click");
            },
        },
    }

</script>