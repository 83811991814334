<template>
    <div class="defaultContentView">
        <UserList :reload-trigger="listReloadTrigger" @add="openCreateForm" @edit="openEditForm" />
        <CreateUser v-if="showCreateForm" @cancel="closeCreateForm" @createdEntry="createdEntry" />
        <EditUser v-if="showEditForm" :entry="editingEntry" @cancel="closeEditForm" @editedEntry="editedEntry" />
    </div>
</template>
<script>

    import { mapActions } from "vuex";
    import UserList from "../components/users/UserList.vue";
    import CreateUser from "../components/users/CreateUser.vue";
    import EditUser from "../components/users/EditUser.vue";

    export default {
        components: {
            UserList,
            CreateUser,
            EditUser
        },
        data() {
            return {
                listReloadTrigger: 0,
                showCreateForm: false,
                showEditForm: false,
                editingEntry: null
            };
        },
        mounted() {
            this.updateSectionTitle(this.l("Users"));
        },
        methods: {
            openCreateForm() {
                this.showCreateForm = true;
            },
            closeCreateForm() {
                this.showCreateForm = false;
            },
            createdEntry() {
                this.closeCreateForm();
                this.listReloadTrigger++;
            },
            openEditForm(entry) {
                this.editingEntry = entry;
                this.showEditForm = true;
            },
            closeEditForm() {
                this.showEditForm = false;
                this.editingEntry = null;
            },
            editedEntry() {
                this.closeEditForm();
                this.listReloadTrigger++;
            },
            l(token, values) {
                return this.$root.l(token, values);
            },
            ...mapActions({
                updateSectionTitle: "platform/updateSectionTitle"
            })
        }
    }
</script>
