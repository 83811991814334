import Vue from "vue";
import Router from "vue-router";
import { routeAuthorizer } from "./authorization.js";
import Home from "../views/Home.vue";
import Exhibitions from "../views/Exhibitions.vue";
import Buildings from "../buildings/views/Buildings.vue";
import Locations from "../locations/views/Locations.vue";
import FloorplanNavigation from "../floorplanNavigation/views/FloorplanNavigation.vue";
import ARContent from "../views/ARContent.vue";
import Tours from "../views/tours/Tours.vue";
import TourInfoEditor from "../views/tours/TourInfoEditor.vue";
import TourStopsEditor from "../views/tours/TourStopsEditor.vue";
import Topics from "../views/informationDatabase/Topics.vue";
import HomeContent from "../views/homeContent/HomeContent.vue";
import HomeContentEditor from "../views/homeContent/HomeContentEditor.vue";
import Encyclopedia from "../views/informationDatabase/Encyclopedia.vue";
import InformationPageListByType from "../views/informationDatabase/InformationPageListByType.vue";
import InformationPageEditor from "../views/informationDatabase/InformationPageEditor.vue";
import Media from "../views/Media.vue";
import Analytics from "../views/Analytics.vue";
import UsageReport from "../usage/views/UsageReport.vue";
import Users from "../views/Users.vue";
import System from "../views/System.vue";
import UpgradeScript from "../views/upgradeScript/UpgradeScript.vue";
import Help from "../views/Help.vue";
import ReleaseNotes from "../releaseNotes/views/ReleaseNotes.vue";
import UserSettings from "../views/UserSettings.vue";
import Error403 from "../views/Error403.vue";
import Error404 from "../views/Error404.vue";


Vue.use(Router);

const router = new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            component: Home
        },
        {
            path: "/exhibitions",
            component: Exhibitions,
            meta: { accessZone: "exhibitions" }
        },
        {
            path: "/floorplan/buildings",
            component: Buildings,
            meta: { accessZone: "exhibitions" }
        },
        {
            path: "/floorplan/locations",
            component: Locations,
            meta: { accessZone: "exhibitions" }
        },
        {
            path: "/floorplan/navigation",
            component: FloorplanNavigation,
            meta: { accessZone: "exhibitions" }
        },
        {
            path: "/arContent",
            component: ARContent,
            meta: { accessZone: "arContent" }
        },
        {
            path: "/tours",
            component: Tours,
            meta: { accessZone: "informationDatabase" }
        },
        {
            path: "/tours/new",
            component: TourInfoEditor,
            meta: { accessZone: "informationDatabase", tourInfoEditorAction: "new" }
        },
        {
            path: "/tours/:tourId/editInfo",
            component: TourInfoEditor,
            meta: { accessZone: "informationDatabase", tourInfoEditorAction: "edit" }
        },
        {
            path: "/tours/:tourId/editStops",
            component: TourStopsEditor,
            meta: { accessZone: "informationDatabase" }
        },
        {
            path: "/topics",
            component: Topics,
            meta: { accessZone: "informationDatabase" }
        },
        {
            path: "/topics/:version/new",
            component: InformationPageEditor,
            meta: { accessZone: "informationDatabase", informationPageEditorAction: "new", informationPageEditorParent: "topics" }
        },
        {
            path: "/topics/:pageId",
            component: InformationPageEditor,
            meta: { accessZone: "informationDatabase", informationPageEditorAction: "edit", informationPageEditorParent: "topics" }
        },
        {
            path: "/home-content",
            component: HomeContent,
            meta: { accessZone: "informationDatabase" }
        },
        {
            path: "/home-content/:exhibitionId",
            component: HomeContentEditor,
            meta: { accessZone: "informationDatabase" }
        },
        {
            path: "/encyclopedia",
            component: Encyclopedia,
            meta: { accessZone: "informationDatabase" }
        },
        {
            path: "/encyclopedia/:type",
            component: InformationPageListByType,
            meta: { accessZone: "informationDatabase" }
        },
        {
            path: "/encyclopedia/:type/:version/new/",
            component: InformationPageEditor,
            meta: { accessZone: "informationDatabase", informationPageEditorAction: "new", informationPageEditorParent: "encyclopedia" }
        },
        {
            path: "/encyclopedia/:type/:pageId",
            component: InformationPageEditor,
            meta: { accessZone: "informationDatabase", informationPageEditorAction: "edit", informationPageEditorParent: "encyclopedia" }
        },
        {
            path: "/media",
            component: Media,
            meta: { accessZone: "media" }
        },
        {
            path: "/analytics",
            component: Analytics,
            meta: { accessZone: "analytics" }
        },
        {
            path: "/usage",
            component: UsageReport,
        },
        {
            path: "/users",
            component: Users,
            meta: { accessZone: "users" }
        },
        {
            path: "/system",
            component: System,
            meta: { accessZone: "system" }
        },
        {
            path: "/upgrade-script",
            component: UpgradeScript,
            meta: { accessZone: "system" }
        },
        {
            path: "/help",
            component: Help
        },
        {
            path: "/release-notes",
            component: ReleaseNotes,
        },
        {
            path: "/user-settings",
            component: UserSettings
        },
        {
            path: "/403",
            component: Error403
        },
        {
            path: "/404",
            component: Error404
        },
        {
            path: "*",
            redirect: "/404"
        }
    ]
});

// add authorization for router, so that user access rights are checked for each route
router.beforeEach(routeAuthorizer);

export default router;