<template>
    <div :class="{ 'pa-3': label, 'my-4': label, imageAlignmentBox: label, imageAlignmentBoxContained: !label, required: label && field.required }">
        <v-subheader v-if="label && !field.error && !field.inSingleFieldArrayEntry" class="manualLabel label noBottomMargin">
            {{ label }}
        </v-subheader>
        <v-subheader v-if="label && field.error && !field.inSingleFieldArrayEntry" style="color:red" class="manualLabel label noBottomMargin">
            {{ label }}
        </v-subheader>
        <p v-if="field.errorMessages && field.errorMessages.length > 0" style="color:red; margin: 8px 0 0 0; font-size:smaller;">
            {{ field.errorMessages[0] }}
        </p>
        <div class="fieldRow">
            <div class="dimensionLabel">
                {{ l("Horizontal") }}
            </div>
            <div v-if="xSelectField" :class="{ selectField: xSelectField.value !== -1, selectFieldCustom: xSelectField.value === -1 }">
                <InputField v-model="xSelectField" />
            </div>
            <div v-if="xCustomField && xSelectField && xSelectField.value === -1" class="customField">
                <InputField v-model="xCustomField" />
            </div>
            <div v-if="xCustomField && xSelectField && xSelectField.value === -1" class="percentageLabel">
                %
            </div>
            <div class="dimensionLabel rightLabel">
                {{ l("Vertical") }}
            </div>
            <div v-if="ySelectField" :class="{ selectField: ySelectField.value !== -1, selectFieldCustom: ySelectField.value === -1 }">
                <InputField v-model="ySelectField" />
            </div>
            <div v-if="yCustomField && ySelectField && ySelectField.value === -1" class="customField">
                <InputField v-model="yCustomField" />
            </div>
            <div v-if="yCustomField && ySelectField && ySelectField.value === -1" class="percentageLabel">
                %
            </div>
        </div>
    </div>
</template>
<script>

    import { setupField } from "../../../utils/FormUtils.js";
    import { l } from "../../../utils/LocalizationUtils.js";


    export default {
        model: {
            prop: "propField",
            event: "input"
        },
        props: {
            propField: { type: Object, default: null }
        },
        data() {
            return {
                xSelectField: null,
                xCustomField: null,
                ySelectField: null,
                yCustomField: null
            }
        },
        computed: {
            field() {
                return this.propField;
            },
            label() {
                return this.field.label ? l(this.field.label) : null;
            }
        },
        watch: {
            xSelectField: {
                handler(form) {
                    this.setFieldValue();
                },
                deep: true
            },
            xCustomField: {
                handler(form) {
                    this.setFieldValue();
                },
                deep: true
            },
            ySelectField: {
                handler(form) {
                    this.setFieldValue();
                },
                deep: true
            },
            yCustomField: {
                handler(form) {
                    this.setFieldValue();
                },
                deep: true
            }
        },
        beforeCreate() { // Delayed InputField import, to prevent recursion of imports
            this.$options.components.InputField = require('./InputField.vue').default;
        },
        mounted() {
            const xSelectOptions = [
                { text: "Left", value: 0 },
                { text: "Center", value: 50 },
                { text: "Right", value: 100 },
                { text: "Custom", value: -1 }
            ];
            const ySelectOptions = [
                { text: "Top", value: 0 },
                { text: "Center", value: 50 },
                { text: "Bottom", value: 100 },
                { text: "Custom", value: -1 }
            ];

            this.xSelectField = setupField({
                type: "Select",
                options: xSelectOptions
            }, (typeof this.field.value?.x !== "undefined") ? ((this.field.value.x === 0 || this.field.value.x === 50 || this.field.value.x === 100) ? this.clampPercentage(this.field.value.x) : -1) : 50);

            this.xCustomField = setupField({
                type: "Number",
            }, this.field.value?.x ? this.clampPercentage(this.field.value.x) : 0);

            this.ySelectField = setupField({
                type: "Select",
                options: ySelectOptions
            }, (typeof this.field.value?.y !== "undefined") ? ((this.field.value.y === 0 || this.field.value.y === 50 || this.field.value.y === 100) ? this.clampPercentage(this.field.value.y) : -1) : 50);

            this.yCustomField = setupField({
                type: "Number",
                required: true
            }, this.field.value?.y ? this.clampPercentage(this.field.value.y) : 0);
        },
        methods: {
            setFieldValue() {
                this.field.value = {
                    x: this.xSelectField.getValue() !== -1 ? this.xSelectField.getValue() : this.clampPercentage(this.xCustomField.getValue()),
                    y: this.ySelectField.getValue() !== -1 ? this.ySelectField.getValue() : this.clampPercentage(this.yCustomField.getValue()),
                };
            },
            clampPercentage(value) {
                return Math.min(Math.max(parseFloat(value, 10), 0), 100);
            },
            l
        }
    }
</script>
<style scoped>

    .imageAlignmentBox {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
        background: #f3f3f3;
        padding: 12px;
    }

    .imageAlignmentBoxContained {
        margin-top: 3px !important;
        margin-bottom: 3px !important;
    }

    .manualLabel {
        font-size: 16px;
        font-weight: 400;
        height: 18px;
        padding: 0;
        margin: 0 0 8px;
    }

    .noBottomMargin {
        margin-bottom: 0 !important;
    }

    .fieldRow {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        margin: 0 0 8px;
    }

    .dimensionLabel {
        width: 12%;
        min-width: 40px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 2%;
        text-align: right;
    }

    .rightLabel {
        margin-left: 10%;
    }

    .selectField {
        width: 26%;
        min-width: 60px;
    }

    .selectFieldCustom {
        width: 16%;
        min-width: 60px;
        margin-right: 2%;
    }

    .customField {
        width: 6%;
        min-width: 20px;
    }

    .percentageLabel {
        width: 2%;
        margin-bottom: 2px;
    }

    .fieldRow ::v-deep .text-input {
        margin: 0 !important;
    }

</style>