import { inCMSLocale, inFallbackLocale } from "../locales/utils/localeUtils.js";
import { app } from "./localizationApp.js";
import { cms } from "./localizationCms.js";
import { general } from "./localizationGeneral.js";
import { modules } from "./localizationModules.js";


export const l = {
    app,
    cms,
    general,
    modules,
};


export function tryGetTokenOverride(locale, token) {
    // TODO
    return null;
}


export function selectLanguage(locale, offeredLanguages) {

    if (!locale) {
        locale = inCMSLocale();
    }

    if (locale) {
        for (const acceptedLanguage of locale.acceptedLanguages) {
            if (offeredLanguages.includes(acceptedLanguage)) {
                return acceptedLanguage;
            }
        }
    }

    const fallbackLocale = inFallbackLocale();
    if (fallbackLocale) {
        for (const acceptedLanguage of fallbackLocale.acceptedLanguages) {
            if (offeredLanguages.includes(acceptedLanguage)) {
                return acceptedLanguage;
            }
        }
    }
    
    return offeredLanguages[0];
}


export function dateString(locale, year, month, day, era, qualifier) {

    const dayString = day && day >= 1 && day <= 31 ? day.toString() : "";
    const monthString = month && month >= 1 && month <= 12 ? l.general.datetime.month(month, locale) : "";
    let yearString = typeof year !== "undefined" && year !== null && year !== "" ? year.toString() : "";

    let dateString = "";

    // year known
    if (yearString !== "") {
        
        if (era === "BC") {
            yearString = l.general.datetime.yearWithEra(year, era, locale);
        }

        // day & month & year
        if (dayString !== "" && monthString !== "") {
            dateString = l.general.datetime.dateString(yearString, monthString, dayString, locale);

        // month & year
        } else if (monthString !== "") {
            dateString = l.general.datetime.monthAndYear(yearString, monthString, locale);

        // year
        } else {
            dateString = yearString;
        }

    // year unknown
    } else {
        if (era === "BC") {
            dateString = l.general.datetime.yearWithEra("?", era, locale);
        } else {
            dateString = "?";
        }
    }

    if (dateString !== "" && qualifier) {
        if (qualifier === "Circa") {
            dateString = l.general.datetime.circaDate(dateString, locale);
        } else if (qualifier === "Before") {
            dateString = l.general.datetime.beforeDate(dateString, locale);
        } else if (qualifier === "After") {
            dateString = l.general.datetime.afterDate(dateString, locale);
        }
    }

    return dateString;
}


