<template>
    <div>
        <v-container v-if="allowSearch" fluid class="pb-5 px-0 mx-0">
            <v-row align="center" no-gutters>
                <v-col cols="4">
                    <v-text-field v-model="search" append-icon="mdi-magnify" :label="l('Search')" single-line clearable hide-details />
                </v-col>
                <v-spacer />
            </v-row>
        </v-container>
        <v-data-table
            :headers="headers"
            :items="data"
            :no-data-text="l('No data')"
            :items-per-page="10"
            :footer-props="{ 'items-per-page-options': [ 5, 10, 20, 50, -1 ] }"
            :search="allowSearch ? search : undefined"
            :custom-filter="searchFilter"
            sort-by="usage"
            sort-desc
            must-sort
            dense
        >
            <template #item="row">
                <tr
                    :style="'cursor:pointer;' + (row.item.showDetails ? 'background:#eee;' : '')"
                    @mouseover="highlight(row.item)" 
                    @mouseleave="unhighlight(row.item)" 
                    @click="select(row.item)"
                >
                    <td style="padding:0;width:24px;">
                        <v-icon v-if="!row.item.showDetails">
                            mdi-chevron-right
                        </v-icon>
                        <v-icon v-if="row.item.showDetails">
                            mdi-chevron-down
                        </v-icon>
                    </td>
                    <td class="font-weight-medium labelColumn" style="width: 100px;">
                        {{ row.item.label }}
                    </td>
                    <td class="text-no-wrap text-right">
                        {{ shortDuration(row.item.usage, true) }}
                        <span class="tablePercentage">
                            {{ "(" + getPercentage(row.item.usageShare, 0) + ")" }}
                        </span>
                    </td>
                    <td class="text-no-wrap text-right">
                        {{ row.item.visits }}
                    </td>
                    <td class="text-no-wrap text-right">
                        {{ shortDuration(row.item.timePerVisit, true) }}
                    </td>
                    <td class="text-no-wrap text-right">
                        {{ row.item.first }}
                        <span class="tablePercentage">
                            {{ "(" + getPercentage(row.item.firstShare, 0) + ")" }}
                        </span>
                    </td>
                    <td class="text-no-wrap text-right">
                        {{ row.item.last }}
                        <span class="tablePercentage">
                            {{ "(" + getPercentage(row.item.lastShare, 0) + ")" }}
                        </span>
                    </td>
                    <td v-if="row.item.previous.length > 0" class="text-no-wrap text-right">
                        {{ clip(row.item.previous[0].label, 10) }}
                        <span class="tablePercentage">
                            {{ "(" + getPercentage(row.item.previous[0].share, 0) + ")" }}
                        </span>
                    </td>
                    <td v-if="row.item.previous.length === 0" />
                    <td v-if="row.item.next.length > 0" class="text-no-wrap text-right">
                        {{ clip(row.item.next[0].label, 10) }}
                        <span class="tablePercentage">
                            {{ "(" + getPercentage(row.item.next[0].share, 0) + ")" }}
                        </span>
                    </td>
                    <td v-if="row.item.next.length === 0" />
                </tr>
                <tr v-if="row.item.showDetails" :style="row.item.showDetails ? 'background:#eee;' : ''">
                    <td />
                    <td colspan="8">
                        <v-container>
                            <v-row>
                                <v-col cols="6" class="pl-0">
                                    <div v-if="row.item.foldOutImage" class="foldOutImage">
                                        <img :src="row.item.foldOutImage">
                                    </div>
                                    <div v-if="row.item.foldOutTitle" class="text-h6">
                                        {{ row.item.foldOutTitle }}
                                    </div>
                                    <div v-if="row.item.foldOutSubtitle" class="text-subtitle-2 pb-2">
                                        {{ row.item.foldOutSubtitle }}
                                    </div>
                                    <div class="foldOutInfoBlock pt-2">
                                        <span class="foldOutInfoLabel">
                                            {{ foldOutLabels.usage }}
                                        </span>
                                        <span class="text-subtitle-2 foldOutInfoValue">
                                            {{ preciseDuration(row.item.usage, row.item.usage >= 3600) }}
                                        </span>
                                        <br>
                                        <span class="foldOutInfoLabel">
                                            {{ foldOutLabels.usageShare }}
                                        </span>
                                        <span class="text-subtitle-2 foldOutInfoValue">
                                            {{ getPercentage(row.item.usageShare, 1) }}
                                        </span>
                                        <br>
                                        <br>
                                        <span class="foldOutInfoLabel">
                                            {{ foldOutLabels.visits }}
                                        </span>
                                        <span class="text-subtitle-2 foldOutInfoValue">
                                            {{ row.item.visits }}
                                        </span>
                                        <br>
                                        <span class="foldOutInfoLabel">
                                            {{ foldOutLabels.timePerVisit }}
                                        </span>
                                        <span class="text-subtitle-2 foldOutInfoValue">
                                            {{ preciseDuration(row.item.timePerVisit, row.item.timePerVisit >= 3600) }}
                                        </span>
                                        <br>
                                        <br>
                                        <span class="foldOutInfoLabel">
                                            {{ foldOutLabels.first }}
                                        </span>
                                        <span class="text-subtitle-2 foldOutInfoValue">
                                            {{ row.item.first }}
                                            ({{ l("${v} of all app launches", getPercentage(row.item.firstShare, 1)) }})
                                        </span>
                                        <br>
                                        <span class="foldOutInfoLabel">
                                            {{ foldOutLabels.last }}
                                        </span>
                                        <span class="text-subtitle-2 foldOutInfoValue">
                                            {{ row.item.last }}
                                            ({{ l("${v} of all app shutdowns", getPercentage(row.item.lastShare, 1)) }})
                                        </span>
                                    </div>
                                </v-col>
                                <v-col cols="3">
                                    <div class="text-subtitle-2">
                                        {{ l("Users came from:") }}
                                    </div>
                                    <v-data-table
                                        :items="row.item.previous"
                                        :no-data-text="l('No data')"
                                        :items-per-page="10"
                                        :footer-props="{ 'items-per-page-options': [] }"
                                        hide-default-header
                                        sort-by="visits"
                                        sort-desc
                                        dense
                                    >
                                        <template #item="previousRow">
                                            <tr>
                                                <td class="text-no-wrap">
                                                    {{ clip(previousRow.item.label, 16) }}
                                                </td>
                                                <td class="text-no-wrap text-right">
                                                    {{ previousRow.item.visits }}
                                                    <span class="tablePercentage">
                                                        {{ "(" + getPercentage(previousRow.item.share, 0) + ")" }}
                                                    </span>
                                                </td>
                                            </tr>
                                        </template>
                                    </v-data-table>
                                </v-col>
                                <v-col cols="3">
                                    <div class="text-subtitle-2">
                                        {{ l("Afterwards users went to:") }}
                                    </div>
                                    <v-data-table
                                        :items="row.item.next"
                                        :no-data-text="l('No data')"
                                        :items-per-page="10"
                                        :footer-props="{ 'items-per-page-options': [] }"
                                        sort-by="visits"
                                        hide-default-header
                                        sort-desc
                                        dense
                                    >
                                        <template #item="nextRow">
                                            <tr>
                                                <td class="text-no-wrap">
                                                    {{ clip(nextRow.item.label, 16) }}
                                                </td>
                                                <td class="text-no-wrap text-right">
                                                    {{ nextRow.item.visits }}
                                                    <span class="tablePercentage">
                                                        {{ "(" + getPercentage(nextRow.item.share, 0) + ")" }}
                                                    </span>
                                                </td>
                                            </tr>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>
<script>

    import { preciseDuration, shortDuration, getPercentage, clip } from "../../utils/StringUtils.js";
    import { l } from "../../utils/LocalizationUtils.js";


    export default {
        props: {
            headers: { type: Array, default: () => [] },
            data: { type: Array, default: () => [] },
            foldOutLabels: { type: Object, default: () => {} },
            allowSearch: { type: Boolean, default: false },
        },
        data() {
            return {
                search: "",
            };
        },
        methods: {

            highlight(row) {
                this.$emit("highlight", row);
            },

            unhighlight(row) {
                this.$emit("unhighlight", row);
            },

            select(row) {
                this.$emit("select", row);
            },

            searchFilter(value, search, item) {
                return Object.values(item).some(v => v && v.toString().toLowerCase().includes(search.toLowerCase()));
            },

            preciseDuration,

            shortDuration,

            getPercentage,

            clip,

            l,
        },
    }
</script>
<style scoped>

    .labelColumn {
        max-width: 240px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .tablePercentage {
        display: inline-block;
        min-width: 42px;
    }

    .foldOutImage {
        width: 56px;
        height: 56px;
        margin-right: 8px;
        float: left;
    }

    .foldOutImage img {
        width: 56px;
        height: 56px;
        object-fit: contain;
    }

    .foldOutInfoBlock {
        clear: both;
    }

    .foldOutInfoLabel {
        display: inline-block;
        min-width: 260px;
    }

    .foldOutInfoValue {
        display: inline-block;
    }

</style>