<template>
    <div class="linkBoxContainer">
        <div v-if="values.size !== 'Large'" class="linkBox">
            <div v-if="image" class="linkBoxImage">
                <img :src="'/assets/' + image + '.jpg?quality=low'">
            </div>
            <div class="linkBoxText">
                {{ label }}
            </div>
            <v-icon v-if="!values.linkedPage" class="linkBoxLinkMissingIcon">
                mdi-link-variant
            </v-icon>
            <v-icon v-if="!values.linkedPage" class="linkBoxLinkMissingIcon">
                mdi-help
            </v-icon>
            <v-icon size="24" color="#424242" class="linkBoxIcon">
                mdi-chevron-right
            </v-icon>
        </div>
        <div v-if="values.size === 'Large'" class="largeLinkBox">
            <div v-if="image" class="largeLinkBoxImage">
                <img :src="'/assets/' + image + '.jpg?quality=low'">
            </div>
            <div class="largeLinkInfoBox">
                <div class="largeLinkBoxLabel">
                    {{ label }}
                </div>
                <!-- eslint-disable -->
                <div class="largeLinkBoxDescription" v-html="description" />
                <!-- eslint-enable -->
                <div v-if="!values.linkedPage" class="largeLinkBoxLinkMissingIcon">
                    <v-icon class="linkBoxLinkMissingIcon">
                        mdi-link-variant
                    </v-icon>
                    <v-icon class="linkBoxLinkMissingIcon">
                        mdi-help
                    </v-icon>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import InformationPageService from "../../../services/InformationPageService.js";
    import TourService from "../../../services/TourService.js";
    import { getParameterValue } from '../../../utils/ParameterUtils.js';
    import { inPreviewLocale } from "../../../locales/utils/localeUtils.js";
    import { l, localize, getLocalizedParameterValue } from "../../../utils/LocalizationUtils.js";


    export default {
        props: {
            values: { type: Object, default: () => { } }
        },
        data() {
            return {
                linkedPage: null
            };
        },
        computed: {
            label() {
                if(this.values?.linkedPage?.label && localize(inPreviewLocale(), this.values.linkedPage.label)) {
                    return localize(inPreviewLocale(), this.values.linkedPage.label);
                } else if(this.linkedPage?.name) {
                    return localize(inPreviewLocale(), this.linkedPage.name);
                }
                return l("Enter link label...");
            },
            description() {
                if(this.linkedPage?.description) {
                    return localize(inPreviewLocale(), this.linkedPage.description);
                }
                return "";
            },
            image() {
                if(this.values?.linkedPage?.image) {
                    return localize(inPreviewLocale(), this.values.linkedPage.image);
                } else if(this.linkedPage?.image) {
                    return localize(inPreviewLocale(), this.linkedPage.image);
                }
                return null;
            }
        },
        watch: {
            values: {
                handler() {
                    if(this.values?.linkedPage?.reference) {

                        InformationPageService.getInformationPage(this.values.linkedPage.reference, infoPage => {
                            if(infoPage) {
                                this.linkedPage = {
                                    name: getParameterValue(infoPage.attributes, "name"),
                                    image: getParameterValue(infoPage.attributes, "image"),
                                    description: ""
                                };
                                let type = infoPage.informationPageTypeId;
                                if(type === "artPiece") {
                                    const year = getLocalizedParameterValue(inPreviewLocale(), infoPage.attributes, "year");
                                    const medium = getLocalizedParameterValue(inPreviewLocale(), infoPage.attributes, "medium");
                                    this.linkedPage.description = year + ((year && medium) ? "<br />" : "") + medium;
                                } else if(type === "topic") {
                                    this.linkedPage.description = getParameterValue(infoPage.attributes, "intro");
                                } else if(type === "acquirer") {
                                    this.linkedPage.description = l("Acquirer");
                                } else if(type === "article") {
                                    this.linkedPage.description = l("Article");
                                } else if(type === "artist") {
                                    this.linkedPage.description = l("Artist");
                                } else if(type === "artPiece") {
                                    this.linkedPage.description = l("Art Piece");
                                } else if(type === "artMovement") {
                                    this.linkedPage.description = l("Art Movement");
                                } else if(type === "category") {
                                    this.linkedPage.description = l("Category");
                                } else if(type === "focus") {
                                    this.linkedPage.description = l("Focus");
                                } else if(type === "location") {
                                    this.linkedPage.description = l("Location");
                                }
                                
                            } else {
                                TourService.getTour(this.values.linkedPage.reference, tour => {
                                    if(tour) {
                                        this.linkedPage = {
                                            name: tour.name,
                                            image: tour.image,
                                            description: tour.teaser
                                        };
                                    } else {
                                        this.linkedPage = {
                                            name: l("Cannot find linked content!"),
                                            image: null,
                                            description: ""
                                        };
                                    }
                                });
                            }
                        });
                    }
                },
                deep: true,
                immediate: true
            }
        }
    }

</script>
<style scoped>

    .linkBoxContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        position: relative;
        background: #212121;
        padding: 8px 20px;
    }

    .linkBox {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        min-height: 40px;
        background: #fff;
    }

    .linkBoxImage {
        flex-shrink: 0;
        position: relative;
        width: 64px;
        height: 100%;
    }

    .linkBoxImage img {
        position: absolute;
        display: block;
        width: 64px;
        height: 100%;
        object-fit: cover;
    }

    .linkBoxText {
        padding: 8px;
        line-height: 24px;
        font-size: 18px;
        color: #424242;
    }

    .linkBoxLinkMissingIcon {
        color: #e00;
        align-self: center;
    }

    .linkBoxLinkMissing {
        padding: 8px 8px 8px 4px;
        line-height: 24px;
        font-size: 18px;
        color: #e00;
    }

    .linkBoxIcon {
        flex-shrink: 0;
        align-self: center;
        padding-right: 8px;
        width: 24px;
        color: #424242;
    }

    .largeLinkBox {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-start;
        min-height: 116px;
    }

    .largeLinkBoxImage {
        position: relative;
        flex-shrink: 0;
        width: 116px;
        height: 116px;
        border: 8px solid #4d4d4d;
    }

    .largeLinkBoxImage img {
        position: absolute;
        display: block;
        width: 100px;
        height: 100px;
        object-fit: cover;
    }

    .largeLinkInfoBox {
        display: flex;
        flex-direction: column;
        margin-left: 16px;
        width: 308px;
    }

    .largeLinkBoxLabel {
        font-size: 18px;
        color: #fff;
        margin-top: 4px;
        text-decoration: underline;
        font-style: italic;
    }

    .largeLinkBoxDescription {
        font-size: 18px;
        color: #fff;
        margin-top: 4px;
    }

</style>