import axios from "axios";
import { triggerError } from "../utils/alerts.js";


const url = "/api/informationPageBlocks/";


class InformationPageBlocksService {

    // Get all
    static getInformationPageBlocks(callback) {
        axios.get(url).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }

    // Put
    static updateInformationPageBlocks(informationPageBlocks, callback) {
        axios.put(url, informationPageBlocks).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }

}

export default InformationPageBlocksService;