<template>
    <div class="blockFormBox">
        <v-overlay :value="showEditBox" @click.native="closeWithOverlay" />
        <transition name="slide-x-transition">
            <div v-if="showEditBox" class="blockForm elevation-5">
                <a class="blockFormArrow" @click="closeWithArrow">
                    <v-icon x-large>
                        mdi-chevron-left
                    </v-icon>
                </a>
                <v-icon class="blockFormIcon" color="primary">
                    {{ blockType.mdiIcon }}
                </v-icon>
                <div class="blockFormTitle subtitle-1">
                    <strong class="primary--text">{{ blockType.title }}</strong>
                    <span v-if="hasSystemAccessRights && blockType.versionNumber" class="text-caption text--disabled pl-2">(v{{ blockType.versionNumber }})</span>
                </div>
                <div class="blockFormButton1">
                    <v-tooltip left>
                        <template #activator="{ on }">
                            <v-btn :loading="savingMoveUp" :disabled="!block.canMoveUp || savingMoveDown || savingDelete" text icon color="primary" v-on="on" @click="moveUpBlock()">
                                <v-icon>mdi-arrow-up</v-icon>
                            </v-btn>
                        </template>
                        <span class="body-2">{{ l("Move up") }}</span>
                    </v-tooltip>
                </div>
                <div class="blockFormButton2">
                    <v-tooltip left>
                        <template #activator="{ on }">
                            <v-btn :loading="savingMoveDown" :disabled="!block.canMoveDown || savingMoveUp || savingDelete" text icon color="primary" v-on="on" @click="moveDownBlock()">
                                <v-icon>mdi-arrow-down</v-icon>
                            </v-btn>
                        </template>
                        <span class="body-2">{{ l("Move down") }}</span>
                    </v-tooltip>
                </div>
                <div class="blockFormButton3">
                    <v-tooltip left>
                        <template #activator="{ on }">
                            <v-btn :loading="savingDelete" :disabled="savingMoveUp || savingMoveDown" text icon color="primary" v-on="on" @click="deleteBlock()">
                                <v-icon>delete</v-icon>
                            </v-btn>
                        </template>
                        <span class="body-2">{{ l("Delete") }}</span>
                    </v-tooltip>
                </div>
                <EditBlockForm v-model="form" />
            </div>
        </transition>
    </div>
</template>
<script>

    import { mapGetters } from "vuex";
    import EditBlockForm from "./EditBlockForm.vue";
    import { createParameterFields } from "../../utils/ParameterUtils.js";
    import { createForm } from "../../utils/FormUtils.js";
    import { l } from "../../utils/LocalizationUtils.js";


    export default {
        components: {
            EditBlockForm
        },
        props: {
            block: { type: Object, default: () => { } },
            blockType: { type: Object, default: () => { } },
            showEditBox: { type: Boolean, default: false },
            saving: { type: Boolean, default: false }
        },
        data() {
            return {
                form: {},
                savingMoveUp: false,
                savingMoveDown: false,
                savingDelete: false,
                waitingForClosingPromptResolution: false
            }
        },
        computed: {
            ...mapGetters({
                hasSystemAccessRights: "user/hasSystemAccessRights"
            })
        },
        watch: {
            blockType() {
                this.setupForm();
            },
            showEditBox() {
                if (this.showEditBox) {
                    this.setupForm();
                }
            },
            saving() {
                this.form.waitingForResponse = this.saving;
            }
        },
        methods: {
            setupForm() {

                this.waitingForClosingPromptResolution = false;

                this.form = createForm({

                    fieldDefs: createParameterFields(this.blockType.parameters, this.block.parameters, false),

                    onSubmit: values => {
                        this.$emit("saveBlock", values);
                    },

                    cancelled: () => {
                        this.closeEditBox();
                    }
                });
            },
            moveUpBlock() {
                this.savingMoveUp = true;
                this.$emit("moveUpBlock", () => {
                    this.savingMoveUp = false;
                });
            },
            moveDownBlock() {
                this.savingMoveDown = true;
                this.$emit("moveDownBlock", () => {
                    this.savingMoveDown = false;
                });
            },
            deleteBlock() {
                this.savingDelete = true;
                this.$emit("deleteBlock", () => {
                    this.savingDelete = false;
                });
            },
            closeEditBox() {
                this.$emit("close");
            },
            assignErrors(errors) {
                this.form.assignErrors(errors);
            },
            closeWithOverlay() {
                if (!this.waitingForClosingPromptResolution) {
                    this.closeWithArrow();
                }
            },
            closeWithArrow() {
                this.waitingForClosingPromptResolution = true;
                if (this.form.hasChanges) {
                    this.$root.$emit(
                        "savePrompt",
                        this.l("Save changes"),
                        this.l("Do you want to save your changes to this block?"),
                        (save, close) => {
                            if (close) {
                                if (save) {
                                    this.form.submit();
                                } else {
                                    this.closeEditBox();
                                }
                            }
                            setTimeout(() => {
                                this.waitingForClosingPromptResolution = false;
                            }, 500);
                        }
                    );
                } else {
                    this.closeEditBox();
                }
            },
            l
        }
    }
</script>
<style scoped>

    .blockFormBox {
        position: absolute;
        left: 470px;
        top: 6px;
        right: 0;
        min-width: 400px;
        max-width: 800px;
    }

    .blockForm {
        position: relative;
        left: 0px;
        top: 0px;
        right: 0px;
        min-height: 50px;
        padding: 40px 10px 10px 10px;
        background: #fff;
        z-index: 8;
    }

    .blockFormArrow {
        display: block;
        position: absolute;
        left: -5px;
        top: 5px;
        width: 40px;
        height: 40px;
    }

    .blockFormIcon {
        position: absolute;
        top: 9px;
        left: 32px;
        width: 32px;
        height: 32px;
    }

    .blockFormTitle {
        position: absolute;
        top: 5px;
        left: 64px;
        right: 118px;
        height: 40px;
        line-height: 40px;
    }

    .blockFormButton1 {
        position: absolute;
        top: 7px;
        width: 36px;
        right: 82px;
    }

    .blockFormButton2 {
        position: absolute;
        top: 7px;
        width: 36px;
        right: 46px;
    }

    .blockFormButton3 {
        position: absolute;
        top: 7px;
        width: 36px;
        right: 10px;
    }

</style>