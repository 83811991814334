<template>
    <v-select
        filled
        hide-details
        :value="currentFloor ? currentFloor._id : null"
        :items="floors"
        :loading="loading"
        item-text="label"
        item-value="_id"
        :label="l('Floor:')"
        :no-data-text="l('This exhibition has no floors')"
        class="floorSelection mb-5"
        @input="setFloor"
    />
</template>
<script>

    import FloorService from '../../services/FloorService.js';
    import { inContentLocale } from "../../locales/utils/localeUtils.js";
    import { l, localize } from "../../utils/LocalizationUtils.js";


    export default {
        props: {
            exhibition: { type: Object, default: null },
        },
        data() {
            return {
                currentFloor: null,
                loading: false,
                floors: [],
            };
        },
        watch: {
            exhibition: {
                handler() {
                    this.update();
                },
                immediate: true,
            }
        },
        mounted() {
            this.update();
        },
        methods: {

            async update() {
                if(this.exhibition) {

                    this.loading = true;

                    this.currentFloor = null;
                    this.floors = await FloorService.getFloors(this.exhibition._id);
                    this.floors.sort((a, b) => a.level < b.level);

                    this.floors.forEach(floor => {
                        if(floor.level === 0 || (floor.level === 1 && this.currentFloor === null)) {
                            this.currentFloor = floor;
                        }
                        floor.label = floor.level + " - " + localize(inContentLocale(), floor.name);
                    });

                    if(this.currentFloor === null) {
                        this.currentFloor = this.floors[0];
                    }
                    
                    this.loading = false;
                    this.$emit("change", this.currentFloor);
                }
            },

            setFloor(newId) {
                this.currentFloor = this.floors.find(floor => floor._id === newId);
                this.$emit("change", this.currentFloor);
            },

            l,
        }
    };

</script>
<style scoped>

    .floorSelection {
        max-width: 300px;
    }

</style>