import axios from "axios";
import { triggerError } from "../utils/alerts.js";


const url = "/api/artPiecePlacements/";
const urlByExhibition = "/api/artPiecePlacements/byExhibition/";
const urlByFloor = "/api/artPiecePlacements/byFloor/";


class ArtPiecePlacementService {

    // Get
    static getArtPiecePlacements(id, callback) {
        axios.get(`${url}${id}`).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }


    // Get all
    static getArtPiecePlacements(callback) {
        axios.get(url).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }


    // Get all of exhibition
    static getArtPiecePlacementsOfExhibition(exhibitionId, callback) {
        axios.get(`${urlByExhibition}${exhibitionId}`).then(response => {
            callback(response.data);
        }).catch(error => {
            console.log(error);
            triggerError(error);
        });
    }


    // Get all of exhibition
    static getArtPiecePlacementsOfFloor(floorId, callback) {
        if (callback) {
            axios.get(`${urlByFloor}${floorId}`).then(response => {
                callback(response.data);
            }).catch(error => {
                triggerError(error);
            });
        } else {
            return new Promise(async resolve => {
                try {
                    const response = await axios.get(`${urlByFloor}${floorId}`);
                    resolve(response.data);
                } catch (error) {
                    triggerError(error);
                    resolve([]);
                }
            });
        }
    }
    

    // Post
    static insertArtPiecePlacement(artPiecePosition, callback) {
        if (callback) {
            axios.post(url, artPiecePosition).then(response => {
                callback(response.data);
            }).catch(error => {
                if (error.response && error.response.data && error.response.data.isJoi) {
                    callback({ errors: error.response.data.details });
                } else {
                    triggerError(error);
                }
            });
        } else {
            return new Promise(async resolve => {
                try {
                    const response = await axios.post(url, artPiecePosition);
                    resolve(response.data);
                } catch (error) {
                    if (error.response && error.response.data && error.response.data.isJoi) {
                        resolve({ errors: error.response.data.details });
                    } else {
                        triggerError(error);
                        resolve(null);
                    }
                }
            });
        }
    }


    // Put
    static updateArtPiecePlacement(artPiecePosition, callback) {
        if (callback) {
            axios.put(`${url}${artPiecePosition._id}`, artPiecePosition).then(response => {
                response.data._id = artPiecePosition._id;
                callback(response.data, null);
            }).catch(error => {
                if (error.response && error.response.data && error.response.data.isJoi) {
                    callback({ errors: error.response.data.details }, null);
                } else {
                    triggerError(error);
                }
            });
        } else {
            return new Promise(async resolve => {
                try {
                    const response = await axios.put(`${url}${artPiecePosition._id}`, artPiecePosition);
                    resolve(response.data);
                } catch (error) {
                    if (error.response && error.response.data && error.response.data.isJoi) {
                        resolve({ errors: error.response.data.details });
                    } else {
                        triggerError(error);
                        resolve(null);
                    }
                }
            });
        }
    }

    // Delete
    static deleteArtPiecePlacement(artPiecePositionId, callback) {
        if (callback) {
            axios.delete(`${url}${artPiecePositionId}`).then(response => {
                callback(response.data);
            }).catch(error => {
                triggerError(error);
            });
        } else {
            return new Promise(async resolve => {
                try {
                    const response = await axios.delete(`${url}${artPiecePositionId}`);
                    resolve(response.data);
                } catch (error) {
                    triggerError(error);
                    resolve(null);
                }
            });
        }
    }

}

export default ArtPiecePlacementService;