import { isString, pad } from "./StringUtils.js";


export function niceCoordinates(coordinates) {
    if (!coordinates || !isString(coordinates)) {
        return "";
    }

    /*
    List<float> coordinateDecimalArray = this.coordinates.Split(',').Select(elem => float.Parse(elem.Trim())).ToList();
        Assert.AreEqual(coordinateDecimalArray.Count(), 2);
        List<string> coordinatesDMSArray = new List<string>();
        for (int i = 0; i < 2; i++)
        {
            float coordinateDecimal = coordinateDecimalArray[i];
            Assert.IsTrue(Mathf.Abs(coordinateDecimal) <= ((i == 0) ? 90f : 180f));

            float degreeFloat = Mathf.Abs(coordinateDecimal);
            int degree = Mathf.FloorToInt(degreeFloat);
            float minutesFloat = Mathf.Abs(degreeFloat - degree) * 60f;
            int minutes = Mathf.FloorToInt(minutesFloat);
            float secondsFloat = Mathf.Abs(minutesFloat - minutes) * 60f;
            int seconds = Mathf.FloorToInt(secondsFloat);

            string direction = (coordinateDecimal > 0) ? ((i == 0) ? "N" : "E") : ((i == 0) ? "S" : "W");

            string coordinateDMS = $"{ degree }° { minutes:D2}' { seconds:D2}'' { direction }";
            coordinatesDMSArray.Add(coordinateDMS);
        }
        this.coordinatesDegree = string.Join(", ", coordinatesDMSArray);

        this.coordinatesDecimal = new Vector2(coordinateDecimalArray[0], coordinateDecimalArray[1]);
        */
    

    const coordinateDecimalArray = [];
    coordinates.split(",").forEach(elem => {
        const parsedFloat = Number.parseFloat(elem);
        if (!Number.isNaN(parsedFloat)) {
            coordinateDecimalArray.push(parsedFloat);
        }
    });

    if (coordinateDecimalArray.length !== 2) {
        return "";
    }

    const coordinatesDMSArray = [];

    for (let i = 0; i < 2; i++) {

        const coordinateDecimal = coordinateDecimalArray[i];
        if (Math.abs(coordinateDecimal) > ((i == 0) ? 90.0 : 180.0)) {
            return "";
        }

        const degreeFloat = Math.abs(coordinateDecimal);
        const degree = Math.floor(degreeFloat);
        const minutesFloat = Math.abs(degreeFloat - degree) * 60.0;
        const minutes = Math.floor(minutesFloat);
        const secondsFloat = Math.abs(minutesFloat - minutes) * 60.0;
        const seconds = Math.floor(secondsFloat);

        const direction = (coordinateDecimal > 0) ? ((i == 0) ? "N" : "E") : ((i == 0) ? "S" : "W");

        const coordinateDMS = `${ degree }° ${ pad(minutes, 2) }' ${ pad(seconds, 2) }'' ${ direction }`;
        coordinatesDMSArray.push(coordinateDMS);
    }

    return coordinatesDMSArray.join(", ");
}