<template>
    <div>
        <v-layout justify-end row class="pb-5 pr-3">
            <AddButton
                v-if="!usageLimitReached"
                :label="l('New Tour')"
                @click="add"
            />
            <v-alert v-if="usageLimitReached" type="error" dense>
                {{ limitReachedWarning }}
            </v-alert>
        </v-layout>
        <v-data-table :headers="headers" :items="list" :sort-by="sortBy" :items-per-page="itemsPerPage" :footer-props="pagination" :loading="loading" :no-data-text="l('No entries.')">
            <template #item="row">
                <tr>
                    <td class="clickable fixed-row-height" style="width:76px" @click="editStops(row.item)">
                        <img :src="row.item.preview" class="preview-image">
                    </td>
                    <td class="clickable fixed-row-height" @click="editStops(row.item)">
                        {{ row.item.label }}
                    </td>
                    <td class="clickable fixed-row-height" @click="editStops(row.item)">
                        {{ row.item.authorLabel }}
                    </td>
                    <td class="fixed-row-height" style="width:200px">
                        <v-btn small color="primary" @click="editInfo(row.item)">
                            <v-icon>mdi-playlist-edit</v-icon>
                            {{ l("Edit Information") }}
                        </v-btn>  
                    </td>
                    <td class="text-right fixed-row-height" style="width:104px">
                        <v-tooltip v-if="!row.item.hidden" left>
                            <template #activator="{ on }">
                                <v-btn text icon color="primary" v-on="on" @click="toggleVisibility(row.item)">
                                    <v-icon>mdi-eye</v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">{{ l("Hide in App") }}</span>
                        </v-tooltip>
                        <v-tooltip v-if="row.item.hidden" left>
                            <template #activator="{ on }">
                                <v-btn text icon color="primary" v-on="on" @click="toggleVisibility(row.item)">
                                    <v-icon>mdi-eye-off-outline</v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">{{ l("Show in App") }}</span>
                        </v-tooltip>
                        <v-tooltip left>
                            <template #activator="{ on }">
                                <v-btn text icon color="primary" v-on="on" @click="deleteEntry(row.item)">
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">{{ l("Delete") }}</span>
                        </v-tooltip>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <v-container v-if="showUsageBar">
            <v-row align="center" justify="end" no-gutters>
                <v-spacer />
                <UsageBar metric-name="tours" mini />
            </v-row>
        </v-container>
    </div>
</template>
<script>

    import TourService from "../../services/TourService.js";
    import AddButton from "../globalUiElements/AddButton.vue";
    import UsageBar from "../../usage/components/UsageBar.vue";
    import ListUtils from "../../utils/ListUtils.js";
    import { deepClone } from "../../utils/objectUtils.js";
    import { hasLimit, isLimitReached } from "../../usage/utils/usageUtils.js";
    import { inContentLocale } from "../../locales/utils/localeUtils.js";
    import { updateLookups } from "../../utils/referenceUtils.js";
    import { l, localize } from "../../utils/LocalizationUtils.js";


    export default ListUtils.setupList({
        components: {
            AddButton,
            UsageBar,
        },
        data() {
            return {
                visibleVersions: [],
                versionLookup: {}
            };
        },
        headers: [
            { text: "", sortable: false },
            { text: "Name", value: "label" },
            { text: "Author", value: "authorLabel" },
            { text: "", sortable: false },
            { text: "", sortable: false }
        ],
        sortBy: "label",
        computed: {
            showUsageBar: () => hasLimit("tours"),
            usageLimitReached: () => isLimitReached("tours"),
            limitReachedWarning: () => l("Limit of tours reached"),
        },
        created() {
            this.update();
        },
        async update() {
            this.showLoading();
            await updateLookups(["usage"]);
            TourService.getTours(list => {
                list.forEach(tour => {
                    tour.preview = "/assets/" + tour.image + ".jpg?quality=veryLow";
                    tour.label = localize(inContentLocale(), tour.name);
                    tour.authorLabel = localize(inContentLocale(), tour.author);
                });
                this.list = list;
                this.hideLoading();
            });
        },
        deleteSettings : {
            promptTitle: "Delete Tour?",
            promptQuestion: "Are you sure you want to delete \"${v.label}\"?",
            action: TourService.deleteTour,
            success: "Tour \"${v.label}\" deleted."
        },
        methods: {
            editStops(entry) {
                this.$emit("editStops", entry);
            },
            editInfo(entry) {
                this.$emit("editInfo", entry);
            },
            toggleVisibility(tour) {
                tour.hidden = !tour.hidden;
                const editedTour = deepClone(tour);
                TourService.updateTour(editedTour, () => {
                    this.$root.$emit("success", this.l("Updated visibility of \"${v.label}\"", editedTour));
                });
            },
            l
        }
    });
</script>
<style>

    .clickable {
        cursor: pointer;
    }

    .preview-image {
        display: block;
        max-width: 48px;
        max-height: 48px;
        margin: 0 auto;
    }

    .fixed-row-height {
        height: 60px !important;
    }

</style>