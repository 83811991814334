export default {


    namespaced: true,


    state: {
        title: "Artifact",
        sectionTitle: "",

        loading: true,

        curationLock: false,
        curationLockBypassed: false,

        backendVersion: "loading...",
        frontendVersion: "loading...",
        buildNumber: "Development Build",

        backendStatus: 0,
        backendLabel: "Backend offline",
        databaseStatus: 0,
        databaseLabel: "Database offline",
        cloudStorageStatus: 0,
        cloudStorageLabel: "Cloud storage disconnected",
        initialUserStatus: 0,
        initialUserLabel: "Initial user missing",

        showWaitingAnimation: false,
        waitingAnimationMessage: ""
    },


    mutations: {

        setTitle(state, title) {
            state.title = title;
        },

        setSectionTitle(state, sectionTitle) {
            state.sectionTitle = sectionTitle;
        },

        setFrontendVersion(state, frontendVersion) {
            state.frontendVersion = frontendVersion;
        },

        setStatus(state, status) {
            if (status.backendStatus !== "offline") {
                state.backendStatus = 1;
                state.backendLabel = "Backend " + status.backendStatus;
            }
            if (status.databaseStatus !== "offline") {
                if (status.databaseStatus === "connected") {
                    state.databaseStatus = 1;
                } else if (status.databaseStatus === "connecting") {
                    state.databaseStatus = 2;
                }
                state.databaseLabel = "Database " + status.databaseStatus;
            }
            if (status.cloudStorageStatus === "connected") {
                state.cloudStorageStatus = 1;
                state.cloudStorageLabel = "Cloud storage connected";
            }
            if (status.hasInitialUser) {
                state.initialUserStatus = 1;
                state.initialUserLabel = "Login active";
            }
            if (status.backendVersion) {
                state.backendVersion = status.backendVersion;
            }
            if (status.buildNumber) {
                state.buildNumber = status.buildNumber;
            }
            if (status.frontendTitle) {
                state.title = status.frontendTitle;
            }
        },

        setWaitingAnimation(state, { show, message }) {
            state.showWaitingAnimation = show;
            state.waitingAnimationMessage = message;
        },

        reportFinishedLoading(state) {
            if (state.loading) {
                state.loading = false;
            }
        }

    },


    actions: {

        updateTitle({ commit, dispatch }, title) {
            commit("setTitle", title);
            dispatch("updateDocumentTitle");
        },

        updateSectionTitle({ commit, dispatch }, sectionTitle) {
            if (typeof sectionTitle !== "undefined" && sectionTitle !== null && sectionTitle !== "") {
                commit("setSectionTitle", sectionTitle);
            } else {
                commit("setSectionTitle", "");
            }
            dispatch("updateDocumentTitle");
        },

        updateDocumentTitle({ state }) {
            if (typeof state.sectionTitle !== "undefined" && state.sectionTitle !== null && state.sectionTitle !== "") {
                document.title = state.title + " > " + state.sectionTitle;
            } else {
                document.title = state.title;
            }
        },

        showWaitingAnimation({ commit }, message) {
            commit("setWaitingAnimation", { show: true, message });
        },

        hideWaitingAnimation({ commit }) {
            commit("setWaitingAnimation", { show: false, message: "" });
        }

    },


    getters: {

        getTitle(state) {
            return state.title;
        },


        isLoading(state) {
            return state.loading;
        },


        getCurationLock(state) {
            return state.curationLock;
        },

        getCurationLockBypassed(state) {
            return state.curationLockBypassed;
        },


        getBackendVersion(state) {
            return state.backendVersion;
        },

        getFrontendVersion(state) {
            return state.frontendVersion;
        },

        getBuildNumber(state) {
            return state.buildNumber;
        },


        getBackendStatus(state) {
            return state.backendStatus;
        },

        getBackendLabel(state) {
            return state.backendLabel;
        },

        getDatabaseStatus(state) {
            return state.databaseStatus;
        },

        getDatabaseLabel(state) {
            return state.databaseLabel;
        },

        getCloudStorageStatus(state) {
            return state.cloudStorageStatus;
        },

        getCloudStorageLabel(state) {
            return state.cloudStorageLabel;
        },

        getInitialUserStatus(state) {
            return state.initialUserStatus;
        },

        getInitialUserLabel(state) {
            return state.initialUserLabel;
        },

        getShowWaitingAnimation(state) {
            return state.showWaitingAnimation;
        },

        getWaitingAnimationLabel(state) {
            return state.waitingAnimationMessage;
        }

    }

}