
import { l, tryGetTokenOverride, selectLanguage } from "./localization.js";


// Art movement pages in encyclopedia
export const artMovements = {


    
    entry(number, locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "entry");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        switch(number) {
            case 1: return l.app.pages.artMovements.singular(locale);
            default: return l.app.pages.artMovements.plural(locale);
        }
            
    },
            

    
    singular(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "singular");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return "Art Movement";
            case "de": return "Kunstrichtung";
            case "fr": return "Mouvement artistique";
            default: return `[singular: ${language} missing]`;
        }
    
    },
            

    
    plural(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "plural");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return "Art Movements";
            case "de": return "Kunstrichtungen";
            case "fr": return "Mouvements artistique";
            default: return `[plural: ${language} missing]`;
        }
    
    },
            

    
    onset(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "onset");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return "Onset";
            case "de": return "Beginn";
            case "fr": return "Début";
            default: return `[onset: ${language} missing]`;
        }
    
    },
            

    
    closure(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "closure");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return "Closure";
            case "de": return "Ende";
            case "fr": return "Fin";
            default: return `[closure: ${language} missing]`;
        }
    
    },
            

    
    importantLocations(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "importantLocations");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return "Important locations";
            case "de": return "Wichtige Orte";
            case "fr": return "Lieux importants";
            default: return `[importantLocations: ${language} missing]`;
        }
    
    },
            
};
