import axios from "axios";


const url = "/api/login/";


class LoginService {

    static login(email, password, errorCallback, callback) {
        axios.post(url, {
            email: email,
            password: password
        })
        .then(response => {
            if (response.data.hasOwnProperty("user") && response.data.hasOwnProperty("token")) {
                callback(response.data);
            } else {
                errorCallback(error);
            }
        })
        .catch(error => {
            errorCallback(error);
        });
    }

}

export default LoginService;