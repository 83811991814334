import axios from "axios";
import { triggerError } from "../utils/alerts.js";


const url = "/api/upgradeScript/";


class LogService {

    static performUpgrade(callback) {
        axios.get(`${url}`).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }

}

export default LogService;