<template>
    <div class="blockFormBox">
        <v-overlay :value="showEditBox" @click.native="closeWithOverlay" />
        <transition name="slide-x-transition">
            <div v-if="showEditBox" class="blockForm elevation-5">
                <a class="blockFormArrow" @click="closeWithArrow">
                    <v-icon x-large>
                        mdi-chevron-left
                    </v-icon>
                </a>
                <v-icon class="blockFormIcon" color="primary">
                    mdi-file-document-outline
                </v-icon>
                <div class="blockFormTitle subtitle-1">
                    <strong class="primary--text">{{ l("Tour") }}</strong>
                </div>
                <EditBlockForm v-model="form" />
            </div>
        </transition>
    </div>
</template>
<script>

    import EditBlockForm from "../informationPages/EditBlockForm.vue";
    import { createForm } from "../../utils/FormUtils.js";
    import { l } from "../../utils/LocalizationUtils.js";

    export default {
        components: {
            EditBlockForm
        },
        props: {
            tour: { type: Object, default: () => {} },
            showEditBox: { type: Boolean, default: false },
            saving: { type: Boolean, default: false }
        },
        data() {
            return {
                form: {},
                waitingForClosingPromptResolution: false
            }
        },
        watch: {
            showEditBox() {
                if (this.showEditBox) {
                    this.setupForm();
                }
            },
            saving() {
                this.form.waitingForResponse = this.saving;
            }
        },

        methods: {
            setupForm() {

                this.waitingForClosingPromptResolution = false;

                this.form = createForm({

                    fieldDefs: [
                        {
                            key: "name",
                            type: "Text",
                            label: "Title (max. 32 characters)",
                            localized: true,
                            required: true,
                            value: this.tour.name
                        },
                        {
                            key: "teaser",
                            type: "Textarea",
                            label: "Teaser (max. 70 characters)",
                            localized: true,
                            required: true,
                            value: this.tour.teaser
                        },
                        {
                            key: "image",
                            type: "Reference",
                            referenceOf: "media",
                            referenceType: "image",
                            label: "Image",
                            required: true,
                            value: this.tour.image
                        },
                        {
                            key: "duration",
                            type: "Number",
                            label: "Duration",
                            required: true,
                            value: this.tour.duration
                        },
                        {
                            uiElement: "Spacer"
                        },
                        {
                            uiElement: "Title",
                            icon: "mdi-account",
                            text: "Author"
                        },
                        {
                            key: "author",
                            type: "Text",
                            label: "Author Name",
                            localized: true,
                            required: false,
                            value: this.tour.author
                        },
                        {
                            key: "authorImage",
                            type: "Reference",
                            referenceOf: "media",
                            referenceType: "image",
                            label: "Author image",
                            required: false,
                            value: this.tour.authorImage
                        },
                        {
                            key: "authorBiography",
                            type: "Textarea",
                            label: "Author's Biography (max. 140 characters)",
                            localized: true,
                            required: false,
                            value: this.tour.authorBiography
                        },
                    ],

                    onSubmit: values => {
                        this.$emit("save", values);
                    },

                    cancelled: () => {
                        this.closeEditBox();
                    }
                });
            },
            closeEditBox() {
                if (this.tourNotYetCreated()) {
                    this.$router.go(-1);
                } else {
                    this.$emit("close");
                }
            },
            assignErrors(errors) {
                this.form.assignErrors(errors);
            },
            closeWithOverlay() {
                if (!this.waitingForClosingPromptResolution && !this.tourNotYetCreated()) {
                    this.closeWithArrow();
                }
            },
            closeWithArrow() {
                this.waitingForClosingPromptResolution = true;
                if (this.form.hasChanges) {
                    this.$root.$emit(
                        "savePrompt",
                        this.l("Save changes"),
                        this.l("Do you want to save your changes to this tour?"),
                        (save, close) => {
                            if (close) {
                                if (save) {
                                    this.form.submit();
                                } else {
                                    this.closeEditBox();
                                }
                            }
                            setTimeout(() => {
                                this.waitingForClosingPromptResolution = false;
                            }, 500);
                        }
                    );
                } else {
                    this.closeEditBox();
                }
            },
            tourNotYetCreated() {
                return !this.tour._id;
            },
            l
        }
    }
</script>
<style scoped>

    .blockFormBox {
        position: absolute;
        left: 470px;
        top: 6px;
        right: 0;
        min-width: 400px;
        max-width: 800px;
    }

    .blockForm {
        position: relative;
        left: 0px;
        top: 0px;
        right: 0px;
        min-height: 50px;
        padding: 40px 10px 10px 10px;
        background: #fff;
        z-index: 8;
        margin-bottom: 20px;
    }

    .blockFormArrow {
        display: block;
        position: absolute;
        left: -5px;
        top: 5px;
        width: 40px;
        height: 40px;
    }

    .blockFormIcon {
        position: absolute;
        top: 9px;
        left: 32px;
        width: 32px;
        height: 32px;
    }

    .blockFormTitle {
        position: absolute;
        top: 5px;
        left: 64px;
        right: 118px;
        height: 40px;
        line-height: 40px;
    }

</style>