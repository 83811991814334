const parameterToFieldDef = {

    "Short Text": parameter => {
        return {
            ...parameter,
            type: "Text"
        };
    },

    "Long Text": parameter => {
        return {
            ...parameter,
            type: "Textarea"
        };
    },

    "Number": parameter => {
        return {
            ...parameter,
            type: "Number"
        };
    },

    "Boolean": parameter => {
        return {
            ...parameter,
            type: "Switch"
        };
    },

    "Time": parameter => {
        return {
            ...parameter,
            type: "Time"
        };
    },

    "Date": parameter => {
        return {
            ...parameter,
            type: "Date"
        };
    },

    "Event": parameter => {
        return {
            ...parameter,
            type: "Event"
        };
    },

    "File": parameter => {
        return {
            ...parameter,
            type: "Reference",
            referenceOf: "media"
        };
    },

    "Image": parameter => {
        return {
            ...parameter,
            type: "Reference",
            referenceOf: "media",
            referenceType: "image"
        };
    },

    "Audio": parameter => {
        return {
            ...parameter,
            type: "Reference",
            referenceOf: "media",
            referenceType: "audio"
        };
    },

    "Video": parameter => {
        return {
            ...parameter,
            type: "Reference",
            referenceOf: "media",
            referenceType: "video"
        };
    },

    "AssetBundle": parameter => {
        return {
            ...parameter,
            type: "Reference",
            referenceOf: "media",
            referenceType: "assetBundle"
        };
    },

    "Page": parameter => {
        return {
            ...parameter,
            type: "Reference",
            referenceOf: "informationPage"
        };
    },

    "Topic": parameter => {
        return {
            ...parameter,
            type: "Reference",
            referenceOf: "topic"
        };
    },

    "ARTarget": parameter => {
        return {
            ...parameter,
            type: "Reference",
            referenceOf: "arTarget"
        };
    },

    "Tour": parameter => {
        return {
            ...parameter,
            type: "Reference",
            referenceOf: "tour"
        };
    },

    "ImageAlignment": parameter => {
        return {
            ...parameter,
            type: "ImageAlignment"
        };
    },

    "ImageTransform": parameter => {
        return {
            ...parameter,
            type: "ImageTransform"
        };
    },

    "Choice": parameter => {
        return {
            ...parameter,
            type: "Select"
        };
    },

    "Array": parameter => {
        const entryFieldDefs = [];
        parameter.entryParameters.forEach(entryParameter => {
            entryFieldDefs.push(convertParameterToFieldDef(entryParameter));
        });
        return {
            ...parameter,
            type: "Array",
            entryFieldDefs
        };
    },

    "Array of Short Texts": parameter => {
        return createSingleParameterArrayField(parameter, {
            type: "Short Text"
        });
    },

    "Array of Long Texts": parameter => {
        return createSingleParameterArrayField(parameter, {
            type: "Long Text"
        });
    },

    "Array of Numbers": parameter => {
        return createSingleParameterArrayField(parameter, {
            type: "Number"
        });
    },

    "Array of Booleans": parameter => {
        return createSingleParameterArrayField(parameter, {
            type: "Boolean"
        });
    },

    "Array of Times": parameter => {
        return createSingleParameterArrayField(parameter, {
            type: "Time"
        });
    },

    "Array of Dates": parameter => {
        return createSingleParameterArrayField(parameter, {
            type: "Date"
        });
    },

    "Array of Events": parameter => {
        return createSingleParameterArrayField(parameter, {
            type: "Event",
            labelOptions: parameter.labelOptions
        });
    },

    "Array of Files": parameter => {
        return createSingleParameterArrayField(parameter, {
            type: "File"
        });
    },

    "Array of Images": parameter => {
        return createSingleParameterArrayField(parameter, {
            type: "Image"
        });
    },

    "Array of Audio": parameter => {
        return createSingleParameterArrayField(parameter, {
            type: "Audio"
        });
    },

    "Array of Videos": parameter => {
        return createSingleParameterArrayField(parameter, {
            type: "Video"
        });
    },

    "Array of AssetBundles": parameter => {
        return createSingleParameterArrayField(parameter, {
            type: "AssetBundles"
        });
    },

    "Array of Pages": parameter => {
        return createSingleParameterArrayField(parameter, {
            type: "Page",
            pageTypes: parameter.pageTypes,
            allowCustomLabel: parameter.allowCustomLabel,
            allowCustomImage: parameter.allowCustomImage
        });
    },

    "Array of Topics": parameter => {
        return createSingleParameterArrayField(parameter, {
            type: "Topic",
            allowCustomLabel: parameter.allowCustomLabel,
            allowCustomImage: parameter.allowCustomImage
        });
    },

    "Array of ARTargets": parameter => {
        return createSingleParameterArrayField(parameter, {
            type: "ARTarget",
            allowCustomLabel: parameter.allowCustomLabel,
            allowCustomImage: parameter.allowCustomImage
        });
    },

    "Array of Tours": parameter => {
        return createSingleParameterArrayField(parameter, {
            type: "Tour",
            allowCustomLabel: parameter.allowCustomLabel,
            allowCustomImage: parameter.allowCustomImage
        });
    },

    "Array of ImageAlignments": parameter => {
        return createSingleParameterArrayField(parameter, {
            type: "ImageAlignment"
        });
    },

    "Array of ImageTransforms": parameter => {
        return createSingleParameterArrayField(parameter, {
            type: "ImageTransform"
        });
    },

    "Array of Choices": parameter => {
        return createSingleParameterArrayField(parameter, {
            type: "Choice",
            options: parameter.options
        });
    }

};


// used for all "Array of..." parameter types
function createSingleParameterArrayField(parameter, childParameter) {
    return {
        ...parameter,
        type: "Array",
        required: false, // "required", "localized", and "defaultValue" applies to the individual entryParameters and not to the root field
        localized: false,
        defaultValue: null,
        richText: false,
        entryFieldDefs: [
            convertParameterToFieldDef({
                ...childParameter,
                required: parameter.required,
                localized: parameter.localized,
                defaultValue: parameter.defaultValue,
                richText: parameter.richText
            })
        ]
    };
}


function convertParameterToFieldDef(parameter) {
    const convert = parameterToFieldDef[parameter.type];
    return convert(parameter);
}


export function createParameterFields(parameters, values, makeFieldsHidden) {

    const fieldDefs = [];

    if (Array.isArray(parameters)) {

        parameters.forEach(parameter => {

            parameter.value = getParameterValue(values, parameter.key);

            const fieldDef = convertParameterToFieldDef(parameter);

            if (parameter.customInputField) {
                fieldDef.type = parameter.customInputField.type;
            }

            if (makeFieldsHidden) {
                fieldDef.type = "Hidden";
            }

            fieldDefs.push(fieldDef);
        });
    }

    return fieldDefs;
}


export function getParameterValue(values, parameterKey) {
    if (Array.isArray(values)) {
        const value = values.find(value => {
            return value.key == parameterKey || value.name == parameterKey;
        });
        if (value && value.value) {
            return value.value;
        }
    }
    return undefined;
}


export function getFieldTypeFromParameter(parameterType) {
    return parameterToFieldDef[parameterType]({}).type;
}
