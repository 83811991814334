
import { l, tryGetTokenOverride, selectLanguage } from "./localization.js";



export const scanner = {


    
    name(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "name");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        return "Scanner";
    },
            

    
    description(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "description");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        return "Use the Augmented Reality Scanner to explore artworks in the exhibition.";
    },
            
};
