import axios from "axios";
import { triggerError } from "../utils/alerts.js";


const url = "/api/tours/";


class TourService {

    // Get
    static getTour(id, callback, errorCallback) {
        axios.get(`${url}${id}`).then(response => {
            callback(response.data);
        }).catch(error => {
            if (errorCallback) {
                errorCallback(error);
            } else {
                triggerError(error);
            }
        });
    }

    // Get all
    static getTours(callback) {
        if (callback) {
            axios.get(url).then(response => {
                callback(response.data);
            }).catch(error => {
                triggerError(error);
            });
        } else {
            return new Promise(async resolve => {
                try {
                    const response = await axios.get(url);
                    resolve(response.data);
                } catch (error) {
                    triggerError(error);
                    resolve([]);
                }
            });
        }
    }

    // Post
    static insertTour(tour, callback) {
        axios.post(url, tour).then(response => {
            callback(response.data);
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.isJoi) {
                callback({ errors: error.response.data.details });
            } else {
                triggerError(error);
            }
        });
    }

    // Put
    static updateTour(tour, callback) {
        axios.put(`${url}${tour._id}`, tour).then(response => {
            response.data._id = tour._id;
            callback(response.data);
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.isJoi) {
                callback({ errors: error.response.data.details });
            } else {
                triggerError(error);
            }
        });
    }

    // Delete
    static deleteTour(tourId, callback) {
        axios.delete(`${url}${tourId}`).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }

}

export default TourService;
