<template>
    <div>
        <div v-if="field.uiElement === 'Spacer'" class="spacer" />
        <div v-if="field.uiElement === 'Divider'" class="divider my-10" />
        <h2 v-if="field.uiElement === 'Title'" class="mt-3 pl-0 primary--text subtitle-1 text-uppercase font-weight-medium">
            <v-icon v-if="field.icon" color="primary" class="title-icon">
                {{ field.icon }}
            </v-icon>
            {{ text }}
        </h2>
        <v-subheader v-if="field.uiElement === 'Header'" class="pt-4 pl-0 pb-2 body-1 text-uppercase font-weight-medium">
            {{ text }}
        </v-subheader>
        <v-container v-if="field.uiElement === 'Info'" class="pa-0">
            <v-row no-gutters class="pl-0 py-2 body-2">
                <v-col cols="4">
                    {{ label }}
                </v-col>
                <v-col>
                    {{ field.value }}
                </v-col>
            </v-row>
        </v-container>
        <ul v-if="field.uiElement === 'InfoList'">
            <li v-for="(item, index) in field.items" :key="index">
                {{ item }}
            </li>
        </ul>
    </div>
</template>
<script>

    import { l } from "../../../utils/LocalizationUtils.js";
    

    export default {
        model: {
            prop: "propField",
            event: "input"
        },
        props: {
            propField: { type: Object, default: null }
        },
        computed: {
            field() {
                return this.propField;
            },
            text() {
                return this.field.text ? l(this.field.text) : "";
            },
            label() {
                return this.field.label ? l(this.field.label) : "";
            },
        }
    }
    
</script>
<style scoped>

    .spacer {
        height: 32px;
    }

    .divider {
        border-bottom: 1px solid #ddd;
        height: 1px;
    }

    .title-icon {
        vertical-align: sub;
    }

</style>