import { render, staticRenderFns } from "./EditBlockBox.vue?vue&type=template&id=29f5f1eb&scoped=true&"
import script from "./EditBlockBox.vue?vue&type=script&lang=js&"
export * from "./EditBlockBox.vue?vue&type=script&lang=js&"
import style0 from "./EditBlockBox.vue?vue&type=style&index=0&id=29f5f1eb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29f5f1eb",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VIcon,VOverlay,VTooltip})
