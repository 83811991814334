import axios from "axios";


const url = "/api/serverStatus/";


class ServerStatusService {

    // Get
    static getCurationLockStatus(callback, errorCallback) {
        axios.get(url).then(response => {
            callback(response.data.curationLock);
        }).catch(error => {
            errorCallback(error);
        });
    }

}

export default ServerStatusService;