<template>
    <div class="defaultContentView">
        <h1 class="display-1 mb-3 primary--text">
            <v-icon color="primary" class="titleIcon">
                help_outline
            </v-icon>
            {{ l("Help") }}
        </h1>
        <h2 class="title mt-10 mb-2 primary--text">
            {{ l("Documentation") }}
        </h2>
        <p>
            {{ l("A full documentation of the Artifact platform can be found here:") }} <br>
            ➔ <a href="https://doc.artifact-ar.ch" target="_blank">doc.artifact-ar.ch</a>
        </p>
        <h2 class="title mt-10 mb-2 primary--text">
            {{ l("Support") }}
        </h2>
        <p>
            {{ l("In case you need help or support, please contact:") }} <br>
            ➔ <a href="mailto:support@aperionxr.com">support@aperionxr.com</a>
        </p>
        <h2 class="title mt-10 mb-2 primary--text">
            {{ l("Credits") }}
        </h2>
        <p class="mt-3">
            <strong>Game Technology Center &ndash; ETH Zurich</strong> <br>
            Dhruv Agrawal <br>
            Edouard Angebault <br>
            Manuel Braunschweiler <br>
            Violaine Fayolle <br>
            Dirk Hüttig <br>
            Henry Raymond <br>
            Fraser Rothnie <br>
            Börge Scheel <br>
            Fabio Zünd
        </p>
        <h2 class="title mt-10 mb-2 primary--text">
            {{ l("Version") }}
        </h2>
        <p class="mt-3">
            <strong class="versionLabel">Backend Version</strong> {{ backendVersion }}<br>
            <strong class="versionLabel">Frontend Version</strong> {{ frontendVersion }}<br>
            <strong class="versionLabel">Build Number</strong> {{ buildNumber }}
        </p>
        <p>
            ➔ 
            <router-link to="/release-notes">
                {{ releaseNotesLabel }}
            </router-link>
        </p>
        <h2 class="title mt-10 mb-2 primary--text">
            {{ l("Legal") }}
        </h2>
        <p>
            ➔ 
            <a href="https://aperionxr.com/privacypolicy.html" target="_blank">
                {{ l("Privacy Policy") }}
            </a>
        </p>
    </div>
</template>
<script>

    import { mapActions, mapGetters } from "vuex";
    import { l } from "../localization/localization.js";


    export default {
        computed: {
            releaseNotesLabel: () => l.cms.releaseNotes.releaseNotesLabel(),
            ...mapGetters({
                backendVersion: "platform/getBackendVersion",
                frontendVersion: "platform/getFrontendVersion",
                buildNumber: "platform/getBuildNumber"
            })
        },
        mounted() {
            this.updateSectionTitle(this.l("Help"));
        },
        methods: {
            l(token, values) {
                return this.$root.l(token, values);
            },
            ...mapActions({
                updateSectionTitle: "platform/updateSectionTitle"
            })
        }
    }
</script>
<style scoped>
    .versionLabel {
        display: inline-block;
        width: 150px;
    }
</style>