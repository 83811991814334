<template>
    <v-layout row justify-center>
        <v-dialog :value="true" persistent max-width="660px" scrollable>
            <v-card>
                <v-card-title class="pb-4">
                    <span class="headline primary--text">
                        <v-icon large color="primary" class="title-icon">
                            mdi-information-outline
                        </v-icon>
                        {{ l("Information") }}
                    </span>
                </v-card-title>
                <v-divider />
                <v-card-text ref="scrollArea" class="pt-4">
                    <v-container>
                        <v-row no-gutters class="py-3 body-2">
                            <v-col cols="4">
                                {{ l("Name") }}
                            </v-col>
                            <v-col>
                                {{ media.name }}
                            </v-col>
                        </v-row>
                        <v-row v-if="hasSystemAccessRights" no-gutters class="py-3 body-2">
                            <v-col cols="4">
                                {{ l("Id") }}
                            </v-col>
                            <v-col>
                                <span class="media-id">
                                    {{ media._id }}
                                </span>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="py-3 body-2">
                            <v-col cols="4">
                                {{ l("File Type") }}
                            </v-col>
                            <v-col>
                                {{ media.fileType }}
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="py-3 body-2">
                            <v-col cols="4">
                                {{ l("Original File Name") }}
                            </v-col>
                            <v-col>
                                {{ media.fileName }}
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="py-3 body-2">
                            <v-col cols="4">
                                {{ l("Size") }}
                            </v-col>
                            <v-col>
                                {{ media.fancyFileSize }} ({{ media.fileSize }} {{ l("bytes") }})
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="py-3 body-2">
                            <v-col cols="4">
                                {{ l("File Origin Date") }}
                            </v-col>
                            <v-col>
                                {{ media.fileOriginDate }}
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="py-3 body-2">
                            <v-col cols="4">
                                {{ l("Upload Date") }}
                            </v-col>
                            <v-col>
                                {{ media.uploadDate }}
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="py-3 body-2">
                            <v-col cols="4">
                                {{ l("File Revision") }}
                            </v-col>
                            <v-col>
                                {{ media.fileRevision }}
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="py-3 body-2">
                            <v-col cols="4">
                                {{ l("Caching") }}
                            </v-col>
                            <v-col>
                                {{ media.doNotCache ? l("Forbidden") : l("Allowed") }}
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="py-3 body-2">
                            <v-col cols="4">
                                {{ l("Original file ID") }}
                            </v-col>
                            <v-col cols="7">
                                <span class="file-id">
                                    {{ makeNiceNull(media.fileId) }}
                                </span>
                            </v-col>
                            <v-col cols="1">
                                <v-tooltip left>
                                    <template #activator="{ on }">
                                        <v-btn icon x-small color="primary" v-on="on" @click="openAsset(media)">
                                            <v-icon>mdi-open-in-new</v-icon>
                                        </v-btn>
                                    </template>
                                    <span class="body-2">{{ l("Open Asset") }}</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="py-3 body-2">
                            <v-col cols="4">
                                {{ l("Very low quality file ID") }}
                            </v-col>
                            <v-col cols="7">
                                <span class="file-id">
                                    {{ makeNiceNull(media.fileId_veryLow) }}
                                </span>
                            </v-col>
                            <v-col cols="1">
                                <v-tooltip left>
                                    <template #activator="{ on }">
                                        <v-btn icon x-small color="primary" v-on="on" @click="openAsset(media, 'veryLow')">
                                            <v-icon>mdi-open-in-new</v-icon>
                                        </v-btn>
                                    </template>
                                    <span class="body-2">{{ l("Open Asset") }}</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="py-3 body-2">
                            <v-col cols="4">
                                {{ l("Low quality file ID") }}
                            </v-col>
                            <v-col cols="7">
                                <span class="file-id">
                                    {{ makeNiceNull(media.fileId_low) }}
                                </span>
                            </v-col>
                            <v-col cols="1">
                                <v-tooltip left>
                                    <template #activator="{ on }">
                                        <v-btn icon x-small color="primary" v-on="on" @click="openAsset(media, 'low')">
                                            <v-icon>mdi-open-in-new</v-icon>
                                        </v-btn>
                                    </template>
                                    <span class="body-2">{{ l("Open Asset") }}</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="py-3 body-2">
                            <v-col cols="4">
                                {{ l("Medium quality file ID") }}
                            </v-col>
                            <v-col cols="7">
                                <span class="file-id">
                                    {{ makeNiceNull(media.fileId_medium) }}
                                </span>
                            </v-col>
                            <v-col cols="1">
                                <v-tooltip left>
                                    <template #activator="{ on }">
                                        <v-btn icon x-small color="primary" v-on="on" @click="openAsset(media, 'medium')">
                                            <v-icon>mdi-open-in-new</v-icon>
                                        </v-btn>
                                    </template>
                                    <span class="body-2">{{ l("Open Asset") }}</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="py-3 body-2">
                            <v-col cols="4">
                                {{ l("High quality file ID") }}
                            </v-col>
                            <v-col cols="7">
                                <span class="file-id">
                                    {{ makeNiceNull(media.fileId_high) }}
                                </span>
                            </v-col>
                            <v-col cols="1">
                                <v-tooltip left>
                                    <template #activator="{ on }">
                                        <v-btn icon x-small color="primary" v-on="on" @click="openAsset(media, 'high')">
                                            <v-icon>mdi-open-in-new</v-icon>
                                        </v-btn>
                                    </template>
                                    <span class="body-2">{{ l("Open Asset") }}</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="py-3 body-2">
                            <v-col cols="4">
                                {{ l("Very high quality file ID") }}
                            </v-col>
                            <v-col cols="7">
                                <span class="file-id">
                                    {{ makeNiceNull(media.fileId_veryHigh) }}
                                </span>
                            </v-col>
                            <v-col cols="1">
                                <v-tooltip left>
                                    <template #activator="{ on }">
                                        <v-btn icon x-small color="primary" v-on="on" @click="openAsset(media, 'veryHigh')">
                                            <v-icon>mdi-open-in-new</v-icon>
                                        </v-btn>
                                    </template>
                                    <span class="body-2">{{ l("Open Asset") }}</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                        <v-row v-if="hasSystemAccessRights" no-gutters class="py-3 body-2">
                            <v-col cols="4">
                                {{ l("JSON media entry") }}
                            </v-col>
                            <v-col cols="8">
                                <span class="json-box">
                                    {{ generateMediaEntry(media) }}
                                </span>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" text min-width="80" @click="close">
                        {{ l("Close") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-layout>
</template>
<script>

    import { mapGetters } from "vuex";


    export default {
        props: {
            media: { type: Object, default: null },
        },
        computed: {
            ...mapGetters({
                hasSystemAccessRights: "user/hasSystemAccessRights"
            })
        },
        methods: {
            close() {
                this.$emit("close");
            },
            makeNiceNull(value) {
                if (typeof value === "undefined" || value === null) {
                    return "null";
                } else {
                    return value;
                }
            },
            generateMediaEntry(mediaElement) {
                return JSON.stringify({
                    _id: mediaElement._id,
                    fileId: mediaElement.fileId,
                    name: mediaElement.name,
                    fileType: mediaElement.fileType,
                    fileTypeGroup: mediaElement.fileTypeGroup,
                    fileRevision: mediaElement.fileRevision,
                    fileSize: mediaElement.fileSize,
                    fileEnding: mediaElement.fileEnding,
                    doNotCache: mediaElement.doNotCache ? true : false,
                });
            },
            openAsset(media, quality) {
                let assetPath = "/assets/" + media._id;
                assetPath += media.fileEnding;
                assetPath += "?";
                if (typeof quality !== "undefined") {
                    assetPath += "quality=" + quality + "&";
                }
                assetPath += "revision=" + media.fileRevision;
                window.open(assetPath, "_blank");
            },
            l(token, values) {
                return this.$root.l(token, values);
            }
        }
    }
</script>
<style scoped>

    .title-icon {
        vertical-align: sub;
    }

    .media-id {
        background: #ddd;
        font-family: "Lucida Console", Monaco, monospace;
        padding: 5px;
        color: #111;
    }

    .file-id {
        background: #ddd;
        font-family: "Lucida Console", Monaco, monospace;
        padding: 5px;
        color: #111;
        font-size: 11px;
    }

    .json-box {
        display: block;
        background: #ddd;
        font-family: "Lucida Console", Monaco, monospace;
        padding: 5px;
        color: #111;
        font-size: 11px;
        height: 66px;
        overflow-y: scroll;
    }

</style>