import { inContentLocale } from "../locales/utils/localeUtils.js";
import { l, getLocalizedParameterValue } from "./LocalizationUtils.js";
import { getParameterValue } from "./ParameterUtils.js";
import { getPage, getPageName, getPageTypeOfPage } from "../pages/utils/pageUtils.js";


export function getEntryById(type, id) {
    if (type === "page") {
        return getPage(id);
    }
    return null;
}


export function getPreviewDataFromId(type, id) {
    const entry = getEntryById(type, id);
    return getPreviewData(type, entry);
}


export function getPreviewData(type, entry) {

    const previewData = {
        _id: entry?._id,
        type: "unknown",
        typeLabel: l("Unknown"),
        label: l("Content missing"),
        icon: "mdi-file-question-outline",
        details: "",
        searchableString: "",
        appLinkPreviewLabel: l("Content missing"),
        appLinkPreviewImage: null,
    };

    if (entry) {
        if (type === "page") {

            previewData.type = "page";
            previewData.typeLabel = l("Page");
            previewData.label = getLocalizedParameterValue(inContentLocale(), entry.attributes, "name");
            previewData.appLinkPreviewLabel = getParameterValue(entry.attributes, "name");

            const pageType = getPageTypeOfPage(entry);
            if (pageType) {

                const pageTypeId = entry.informationPageTypeId;

                previewData.icon = pageType.mdiIcon;
                previewData.typeLabel = l(pageType.title);

                const image = getParameterValue(entry.attributes, "image");
                if (image) {
                    previewData.image = "/assets/" + image + ".jpg?quality=veryLow&update=" + image.substring(2, 8);     // '&update' is needed such that the thumbnail is updated when the referenced image changes 
                    previewData.mediumImage = "/assets/" + image + ".jpg?quality=low&update=" + image.substring(2, 8);
                    previewData.largeImage = "/assets/" + image + ".jpg?quality=high&update=" + image.substring(2, 8);
                    previewData.veryLargeImage = "/assets/" + image + ".jpg?quality=veryHigh&update=" + image.substring(2, 8);
                    previewData.appLinkPreviewImage = image;
                }

                if (pageTypeId === "artPiece") {
                    const artPieceDetails = getArtworkPageDescription(entry);
                    if (artPieceDetails !== "") {
                        previewData.details = artPieceDetails;
                    }

                } else if (pageTypeId === "artist" || pageTypeId === "acquirer") {
                    const lifespan = getParameterValue(entry.attributes, "lifespan");
                    if (lifespan) {
                        for (const [ietfLanguageTag, label] of Object.entries(previewData.appLinkPreviewLabel)) {
                            if (lifespan[ietfLanguageTag]) {
                                previewData.appLinkPreviewLabel[ietfLanguageTag] = label + " (" + lifespan[ietfLanguageTag] + ")";
                            }
                        }
                    }
                    const lifespanForDetails = getLocalizedParameterValue(inContentLocale(), entry.attributes, "lifespan");
                    if (lifespanForDetails) {
                        previewData.details = lifespanForDetails;
                    }

                } else if (pageTypeId === "location") {
                    const countryForDetails = getLocalizedParameterValue(inContentLocale(), entry.attributes, "country");
                    if (countryForDetails) {
                        previewData.details = countryForDetails;
                    }
                }
            }

            previewData.description = previewData.typeLabel + (previewData.details ? " – " + previewData.details : "")
            previewData.searchableString = (previewData.label + " " + previewData.typeLabel + " " + previewData.details).toLocaleLowerCase();
            
        }
    }
    return previewData;
}


export function getArtworkPageDescription(page) {
    const artistNames = [];
    const artistPages = getParameterValue(page.attributes, "artists");
    if (Array.isArray(artistPages)) {
        artistPages.forEach(artistPage => {
            const artistName = getPageName(inContentLocale(), artistPage, false);
            if (artistName) {
                artistNames.push(artistName);
            }
        });
    }
    const artPieceNumber = getParameterValue(page.attributes, "number");
    return (artPieceNumber ? artPieceNumber + " – " : "") + artistNames.join("; ");
}
