<template>
    <div :class="'block' + (showEditBox ? ' activeBlock' : '')">
        <BlockPreview
            :information-page-type="informationPageType"
            :block-type="null"
            :tour="false"
            :values="valuesForPreview"
        />
        <a class="blockSelector" @click="openEditBox()" />
        <EditAttributesBox
            ref="editBox"
            :information-page-type="informationPageTypeVersion"
            :version-attributes="informationPageTypeVersion.attributes"
            :information-page="informationPage"
            :show-edit-box="showEditBox"
            :saving="saving"
            @close="closeEditBox"
            @save="save"
        />
    </div>
</template>
<script>

    import BlockPreview from "./blockPreviews/BlockPreview.vue";
    import EditAttributesBox from "./EditAttributesBox.vue";
    import { deepClone } from "../../utils/objectUtils";
    import { inPreviewLocale } from "../../locales/utils/localeUtils.js";
    import { l, getLocalizedParameterList } from "../../utils/LocalizationUtils.js";


    export default {
        components: {
            BlockPreview,
            EditAttributesBox
        },
        props: {
            informationPageType: { type: Object, default: () => { } },
            informationPageTypeVersion: { type: Object, default: () => { } },
            informationPage: { type: Object, default: () => { } },
        },
        data() {
            return {
                showEditBox: false,
                saving: false
            }
        },
        computed: {
            valuesForPreview() {
                if (this.informationPage
                    && this.informationPage.attributes
                    && this.informationPageTypeVersion
                    && this.informationPageTypeVersion.attributes) {
                    return getLocalizedParameterList(inPreviewLocale(), this.informationPage.attributes, this.informationPageTypeVersion.attributes);
                } else {
                    return {};
                } 
            }
        },
        mounted() {
            if (this.pageNotYetCreated()) {
                this.openEditBox();
            }
        },
        methods: {

            openEditBox() {
                this.showEditBox = true;
                this.saving = false;
            },

            closeEditBox() {
                this.showEditBox = false;
                this.saving = false;
            },

            assignErrors(errors) {
                this.$refs.editBox.assignErrors(errors);
                this.saving = false;
            },

            save(values) {

                const editedInformationPage = deepClone(this.informationPage);

                editedInformationPage.attributes.splice(0, editedInformationPage.attributes.length)
                this.informationPageTypeVersion.attributes.forEach(attribute => {
                    editedInformationPage.attributes.push({
                        key: attribute.key,
                        value: values[attribute.key]
                    });
                });

                this.saving = true;
                this.$emit("save", {
                    editedInformationPage,
                    caller: this,
                    onSuccess: () => {
                        this.closeEditBox();
                    }
                });
            },

            pageNotYetCreated() {
                return !this.informationPage._id;
            },

            l
        }
    }
</script>
<style scoped>

    .block {
        position: relative;
    }

    .activeBlock .blockContent {
        z-index: 6;
    }

    .blockSelector {
        display: block;
        position: absolute;
        top: -1px;
        left: -1px;
        width: 482px;
        bottom: -1px;
        user-select: none;
        cursor: pointer;
        z-index: 2;
    }

    .blockSelector:hover {
        border: 2px solid;
        border-radius: 3px;
        background: rgba(204, 204, 204, 0.12);
    }

    .activeBlock .blockSelector {
        top: -2px !important;
        left: -2px !important;
        width: 484px !important;
        bottom: -2px !important;
        border: 3px solid !important;
        border-radius: 4px !important;
        z-index: 7 !important;
        background: none !important;
    }

</style>