<template>
    <div class="slideshow">
        <div class="slideshowImage">
            <img v-if="firstImage" :style="'object-position:' + firstHorizontalAlignment + ' ' + firstVerticalAlignment" :src="'/assets/' + firstImage + '.jpg?quality=high'">
            <v-icon class="slideshowMaximizeIcon" size="32">
                mdi-arrow-expand-all 
            </v-icon>
            <div v-if="!firstImage" class="slideshowImageMissing">
                <v-icon x-large>
                    mdi-image-outline
                </v-icon>
                <v-icon x-large>
                    mdi-help
                </v-icon>
            </div>
            <div class="slideshowNavigation">
                <v-icon class="slideshowArrow">
                    mdi-chevron-left
                </v-icon>
                <div class="slideshowDots">
                    <div v-for="(image, index) in values.slides" :key="index">
                        <span />
                    </div>
                </div>
                <v-icon class="slideshowArrow">
                    mdi-chevron-right
                </v-icon>
            </div>
        </div>
        <!-- eslint-disable -->
        <div v-if="firstCaption" class="slideshowCaption">{{ firstCaption }}</div>
        <!-- eslint-enable -->
    </div>
</template>
<script>

    import { getParameterValue } from "../../../utils/ParameterUtils.js";
    import { inPreviewLocale } from "../../../locales/utils/localeUtils.js";
    import { getLocalizedParameterValue } from "../../../utils/LocalizationUtils.js";


    export default {
        props: {
            values: { type: Object, default: () => { } }
        },
        computed: {
            firstImage() {
                if (Array.isArray(this.values.slides) && this.values.slides.length > 0) {
                    return getParameterValue(this.values.slides[0], "image");
                }
                return null;
            },
            firstHorizontalAlignment() {
                if (Array.isArray(this.values.slides) && this.values.slides.length > 0) {
                    return getParameterValue(this.values.slides[0], "horizontalAlignment");
                }
                return "center";
            },
            firstVerticalAlignment() {
                if (Array.isArray(this.values.slides) && this.values.slides.length > 0) {
                    return getParameterValue(this.values.slides[0], "verticalAlignment");
                }
                return "center";
            },
            firstCaption() {
                if (Array.isArray(this.values.slides) && this.values.slides.length > 0) {
                    return getLocalizedParameterValue(inPreviewLocale(), this.values.slides[0], "caption");
                }
                return null;
            }
        }
    }

</script>
<style scoped>

    .slideshow {
        position: relative;
        background: #212121;
        padding: 24px 20px;
    }

    .slideshowImage {
        position: relative;
        height: 240px;
    }    

    .slideshowImage img {
        position: absolute;
        display: block;
        width: 100%;
        height: 240px;
        object-fit: cover;
    }

    .slideshowMaximizeIcon {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 24px;
        height: 24px;
        color: #fff;
        text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.7);
    }

    .slideshowImageMissing {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        height: 204px;
        background: #999;
        text-align: center;
    }

    .slideshowNavigation {
        display: flex;
        align-items: center;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 36px;
        background: rgba(0.82, 0.82, 0.82, 0.8);
    }

    .slideshowArrow {
        width: 40px;
        color: #fff;
    }

    .slideshowDots {
        display: flex;
        flex-grow: 1;
        flex-flow: nowrap;
        justify-content: center;
        overflow: hidden;
    }

    .slideshowDots div {
        padding: 14px;
        width: 36px;
        height: 36px;
    }

    .slideshowDots div span {
        display: block;
        width: 8px;
        height: 8px;
        border: 1px solid #fff;
        border-radius: 4px;
    }

    .slideshowDots div:first-child span {
        background: #fff;
    }

    .slideshowCaption {
        padding: 6px 10px;
        background: #212121;
        font-size: 15px;
        color: #fafafa;
        white-space: pre-wrap;
    }

</style>