<template>
    <div :class="{ 'usageBar': true, 'normal': !mini, 'mini': mini }">
        <div v-if="!mini" class="label text-body-2 font-weight-bold">
            {{ usageBarLabel }}
        </div>
        <div v-if="!mini" class="ratio text-body-2">
            {{ usageBarRatio }}
        </div>
        <div v-if="!usageBarUnlimited" class="bar">
            <div
                :class="{
                    filler: true,
                    fillerYellow: usageBarPercentage >= 80 && usageBarPercentage < 100,
                    fillerRed: usageBarPercentage >= 100
                }"
                :style="{ width: Math.min(usageBarPercentage, 100) + '%'}"
            />
        </div>
        <div v-if="!usageBarUnlimited && !mini" class="percentage text-body-2 text-right">
            {{ usageBarPercentage }}%
        </div>
        <div v-if="mini" class="miniLabel text-body-2">
            {{ usageBarRatio }} ({{ usageBarPercentage }}%)
        </div>
    </div>
</template>
<script>

    import { getMetric, getLimit } from "../utils/usageUtils.js";
    import { l } from "../../localization/localization.js";


    export default {
        props: {
            metricName: { type: String, default: null },
            element: { type: Object, default: null },
            mini: { type: Boolean, default: false },
        },
        computed: {
            metric() {
                if(this.metricName) {
                    return getMetric(this.metricName);
                } else if(this.element.metric && this.element.metric !== "custom") {
                    return getMetric(this.element.metric);
                }
                return null;
            },
            isCustomMetric() {
                return this.element && this.element.metric === "custom";
            },
            usageBarLabel() {
                if(this.metric) {
                    return this.metric.label();
                } else if(this.isCustomMetric) {
                    return this.element.label;
                }
                return "";
            },
            usageBarValue() {
                if(this.metric) {
                    let used = this.metric.used(); 
                    return !isNaN(used) ? +used.toFixed(3) : used;
                } else if(this.isCustomMetric) {
                    return this.element.value;
                }
                return 0;
            },
            usageBarUnlimited() {
                if(this.metric) {
                    const limit = getLimit(this.metric.name);
                    return !isNaN(limit) && limit === -1;
                } else if(this.isCustomMetric) {
                    return this.element.max == -1;
                }
                return false;
            },
            usageBarMax() {
                if(this.metric) {
                    const limit = getLimit(this.metric.name);
                    return !isNaN(limit) && limit >= 0 ? limit : 0;
                } else if(this.isCustomMetric) {
                    return this.element.max;
                }
                return 0;
            },
            usageBarPercentage() {
                if(this.usageBarMax && !isNaN(this.usageBarMax) && this.usageBarMax > 0) {
                    return Math.round(100 * this.usageBarValue / this.usageBarMax);
                } else {
                    return 100;
                }
            },
            usageBarRatio() {
                let units = "";
                if(this.metric) {
                    units = " " + this.metric.unit();
                } else if(this.isCustomMetric && this.element.unit) {
                    units = " " + this.element.unit;
                }
                if(this.usageBarUnlimited) {
                    return this.usageBarValue + units + " / " + l.cms.usage.unlimited() + units;
                } else {
                    return this.usageBarValue + units + " / " + this.usageBarMax + units;
                }
            },
        }
    }

</script>
<style scoped>

    .usageBar {
        height: 60px;
        display: flex;
        align-items: center;
    }

    .usageBar.normal {
        width: 100%;
    }

    .usageBar.mini {
        width: 320px;
    }

    .usageBar .label {
        width: 200px;
        margin-right: 20px;
    }

    .usageBar .ratio {
        width: 180px;
        margin-left: 20px;
    }

    .usageBar .bar {
        min-width: 50px;
        height: 16px;
        flex-grow: 1;
        flex-shrink: 1;
        background: #c6c6c6;
        border-radius: 8px;
        overflow: hidden;
    }

    .usageBar .bar .filler {
        width: 350px;
        height: 16px;
        background: #090;
    }

    .usageBar .bar .fillerYellow {
        background: #eb0 !important;
    }

    .usageBar .bar .fillerRed {
        background: #d00 !important;
    }

    .usageBar .percentage {
        width: 50px;
    }

    .usageBar .miniLabel {
        margin-left: 20px;
    }

</style>
