<template>
    <div>
        <v-container class="titleBar">
            <v-row justify="start" class="titleBarRow">
                <v-col cols="auto">
                    <ViewTitle
                        :title="l('Floorplan Locations')"
                        icon="mdi-map-marker-multiple"
                    />
                </v-col>
                <v-spacer />
                <v-col cols="auto">
                    <ExhibitionSelection
                        @change="setExhibition"
                    />
                </v-col>
                <v-col cols="auto">
                    <FloorSelection
                        :exhibition="exhibition"
                        @change="setFloor"
                    />
                </v-col>
            </v-row>
        </v-container>
        <LocationPlacementManager
            v-if="exhibition && floor"
            :exhibition="exhibition"
            :floor="floor"
        />
    </div>
</template>
<script>

    import { mapActions } from "vuex";
    import ViewTitle from "../../components/globalUiElements/ViewTitle.vue";
    import ExhibitionSelection from "../../components/globalUiElements/ExhibitionSelection.vue";
    import FloorSelection from "../components/FloorSelection.vue";
    import LocationPlacementManager from "../components/LocationPlacementManager.vue";
    import { l } from "../../utils/LocalizationUtils.js";


    export default {
        components: {
            ViewTitle,
            ExhibitionSelection,
            FloorSelection,
            LocationPlacementManager,
        },
        data() {
            return {
                exhibition: null,
                floor: null,
            };
        },
        mounted() {
            this.updateSectionTitle(this.l("Floorplan Locations"));
        },
        methods: {

            setExhibition(exhibition) {
                this.exhibition = exhibition;
            },

            setFloor(floor) {
                this.floor = floor;
            },

            l,

            ...mapActions({
                updateSectionTitle: "platform/updateSectionTitle"
            }),
        }
    }
</script>
<style scoped>

    .titleBar {
        padding: 50px 50px 0;
        height: 90px;
        margin: 0;
        max-width: none;
    }

    .titleBarRow {
        flex-wrap: nowrap;
    }

</style>
