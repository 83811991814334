import axios from "axios";
import { triggerError } from "../utils/alerts.js";


const url = "/api/localization/";


class LocalizationService {

    // Get
    static get(callback) {
        axios.get(url).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }

    // Put
    static update(localization, callback) {
        axios.put(url, localization).then(response => {
            callback(response.data);
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.isJoi) {
                callback({ errors: error.response.data.details });
            } else {
                triggerError(error);
            }
        });
    }

}

export default LocalizationService;