<template>
    <WindowedForm v-model="form" />
</template>
<script>
    
    import * as tagService from "../services/tagService.js";
    import WindowedForm from "../../components/globalUiElements/WindowedForm.vue";
    import { createForm } from "../../utils/FormUtils.js";
    import { getLabel } from "../utils/tagUtils.js";
    import { l } from "../../localization/localization.js";


    export default {
        components: {
            WindowedForm
        },
        data() {
            return {
                form: createForm({

                    title: () => l.cms.tags.addFormTitle(),

                    action: tagService.insert,

                    fieldDefs: [
                        {
                            key: "name",
                            type: "Text",
                            label: () => l.cms.tags.name(),
                            localized: true,
                            required: true,
                        },
                    ],

                    submitLabel: () => l.cms.tags.addFormSubmitButtonLabel(),

                    submitted: entry => {
                        this.$root.$emit("success", l.cms.tags.addedMessage(getLabel(entry.name)));
                        this.$emit("addedEntry");
                    },

                    cancelled: () => {
                        this.$emit("cancel");
                    }
                })
            }
        }
    }
</script>