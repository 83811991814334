<template>
    <WindowedForm v-model="form" />
</template>
<script>

    import store from "../../store/index.js";
    import ExhibitionService from "../../services/ExhibitionService.js";
    import WindowedForm from "../globalUiElements/WindowedForm.vue";
    import { createForm } from "../../utils/FormUtils.js";
    import { l } from "../../utils/LocalizationUtils.js";


    export default {
        components: {
            WindowedForm
        },
        props: {
            entry : { type: Object, default: null }
        },
        data() {
            return {
                form: createForm({

                    title: {
                        label: "Create duplicate of \"${v.title}\"",
                        entry: this.entry
                    },

                    action: ExhibitionService.duplicateExhibition,

                    fieldDefs: [
                        {
                            key: "originalId",
                            type: "Hidden",
                            value: this.entry._id
                        },
                        {
                            key: "title",
                            type: "Text",
                            label: "New Title",
                            value: this.entry.title,
                            required: true
                        },
                        {
                            key: "arTargetXmlFile",
                            type: this.hasSystemAccessRights() ? "Reference" : "Hidden",
                            referenceOf: "media",
                            referenceType: "xml",
                            label: "Vuforia .xml AR target file",
                            value: this.entry.arTargetXmlFile
                        },
                        {
                            key: "arTargetDatFile",
                            type: this.hasSystemAccessRights() ? "Reference" : "Hidden",
                            referenceOf: "media",
                            referenceType: "binary",
                            label: "Vuforia .dat AR target file",
                            value: this.entry.arTargetDatFile
                        },
                        {
                            key: "arTargetCopyrightLine",
                            type: "Text",
                            label: "Copyright line for art pieces",
                            localized: true,
                            value: this.entry.arTargetCopyrightLine
                        },
                        {
                            key: "textTokens",
                            type: this.hasSystemAccessRights() ? "Array" : "Hidden",
                            label: "Exhibition-specific Text Tokens",
                            entryLabel: "Text token",
                            entryFieldDefs: [
                                {
                                    key: "key",
                                    type: "Text",
                                    label: "Key"
                                },
                                {
                                    key: "translations",
                                    type: "Textarea",
                                    label: "Translations",
                                    localized: true,
                                    richText: true
                                },
                            ],
                            saveEntriesAsObjects: true,
                            value: this.entry.textTokens ? this.entry.textTokens : [],
                        },
                        {
                            key: "appSettings",
                            type: this.hasSystemAccessRights() ? "Array" : "Hidden",
                            label: "Exhibition-specific settings (overrides global App settings)",
                            entryLabel: "Setting",
                            entryFieldDefs: [
                                {
                                    key: "key",
                                    type: "Text",
                                    label: "Key",
                                    value: "",
                                    required: true
                                },
                                {
                                    key: "value",
                                    type: "Textarea",
                                    label: "Value",
                                    value: ""
                                },
                            ],
                            saveEntriesAsObjects: true,
                            value: this.entry.appSettings ? this.entry.appSettings : [],
                        },
                        {
                            key: "defaultPoiDisplayMode",
                            type: "Select",
                            label: "Default POI selection display mode",
                            options: [
                                { text: "Only in AR", value: "AR" },
                                { text: "Only in screenspace", value: "Screenspace" },
                                { text: "Both in AR and screenspace", value: "Both" },
                            ],
                            value: this.entry.defaultPoiDisplayMode || "AR",
                        },
                        {
                            key: "defaultScreenspaceOpeningMode",
                            type: "Select",
                            label: "Default screenspace POI selection opening behavior",
                            options: [
                                { text: "Button in information box", value: "InfoBoxButton" },
                                { text: "Button on AR target", value: "ARButton" },
                                { text: "Buttons both in information box and on AR target", value: "Both" },
                            ],
                            value: this.entry.defaultScreenspaceOpeningMode || "Both",
                        },
                        {
                            key: "outputJSONFile",
                            type: this.hasSystemAccessRights() ? "Checkbox" : "Hidden",
                            label: "Allow generation of App's JSON file",
                            value: this.entry.outputJSONFile || false,
                        },
                        {
                            key: "arTargets",
                            type: "Hidden",
                            value: this.entry.arTargets || []
                        },
                    ],

                    submitLabel: "Duplicate",

                    submitted: entry => {
                        this.$root.$emit("success", l("Duplicate exhibition \"${v.title}\" created.", entry));
                        this.$emit("duplicatedEntry");
                    },

                    cancelled: () => {
                        this.$emit("cancel");
                    }
                })
            }
        },
        methods: {
            hasSystemAccessRights() {
                return store.getters["user/hasSystemAccessRights"];
            },
        },
    }
</script>