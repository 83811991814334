<template>
    <div class="toolbar">
        <EditableValueField
            :value="Math.round(viewportZoom * 100)"
            suffix="%"
            @change="applyZoomInput"
        />
        <ListRowButton
            :label="l('Zoom in')"
            icon="mdi-magnify-plus-outline"
            tooltip-right
            @click="zoomIn()"
        />
        <ListRowButton
            :label="l('Zoom out')"
            icon="mdi-magnify-minus-outline"
            tooltip-right
            @click="zoomOut()"
        />
        <div class="toolbarDivider" />
        <ListRowButton
            :label="snapping ? l('Snapping ON (click to turn off)') : l('Snapping OFF (click to turn on)')"
            :icon="snapping ? 'mdi-magnet-on' : 'mdi-magnet'"
            tooltip-right
            @click="toggleSnapping()"
        />
        <ListRowButton
            :label="showGuides ? l('Guides ON (click to turn off)') : l('Guides OFF (click to turn on)')"
            :icon="showGuides ? 'mdi-crosshairs-gps' : 'mdi-crosshairs-off'"
            tooltip-right
            @click="toggleGuides()"
        />
        <div class="toolbarDivider" />
        <div class="spacer" />
        <AddButton
            :label="l('Add location')"
            @click="addLocation"
        />
    </div>
</template>
<script>

    import ListRowButton from "../../components/globalUiElements/ListRowButton.vue";
    import EditableValueField from "../../components/globalUiElements/EditableValueField.vue";
    import AddButton from "../../components/globalUiElements/AddButton.vue";
    import { l } from "../../utils/LocalizationUtils.js";


    export default {
        components: {
            ListRowButton,
            EditableValueField,
            AddButton,
        },
        props: {
            viewportZoom: { type: Number, default: 1 },
            snapping: { type: Boolean, default: false },
            showGuides: { type: Boolean, default: false },
        },
        methods: {

            zoomIn() {
                this.$emit("changeZoom", this.viewportZoom * 1.25);
            },

            zoomOut() {
                this.$emit("changeZoom", this.viewportZoom * 0.8);
            },

            applyZoomInput(newZoom)  {
                this.$emit("changeZoom", newZoom / 100);
            },

            toggleSnapping() {
                this.$emit("toggleSnapping");
            },

            toggleGuides() {
                this.$emit("toggleGuides");
            },

            addLocation() {
                this.$emit("addLocation");
            },

            l,

        }
    }

</script>
<style scoped>

    .toolbar {
        display: flex;
        padding: 0 10px;
        height: 50px;
        align-items: center;
        justify-content: space-between;
    }

    .toolbarDivider {
        width: 1px;
        height: 50px;
        flex-grow: 0;
        margin: 0 10px;
        background: #e0e0e0;
    }

    .spacer {
        flex-grow: 100;
    }

</style>
