import axios from "axios";
import { triggerError } from "../utils/alerts.js";


const url = "/api/arTargets/";
const interactionsUrl = "/api/arTargets/interactions/"


class ARTargetService {

    // Get
    static getARTarget(id, callback) {
        if (callback) {
            axios.get(`${url}${id}`).then(response => {
                callback(response.data);
            }).catch(error => {
                if (errorCallback) {
                    errorCallback(error);
                } else {
                    triggerError(error);
                }
            });
        } else {
            return new Promise(async resolve => {
                try {
                    const response = await axios.get(`${url}${id}`);
                    resolve(response.data);
                } catch (error) {
                    triggerError(error);
                    resolve(null);
                }
            });
        }
    }

    // Get all
    static getARTargets(callback) {
        if (callback) {
            axios.get(url).then(response => {
                callback(response.data);
            }).catch(error => {
                triggerError(error);
            });
        } else {
            return new Promise(async resolve => {
                try {
                    const response = await axios.get(url);
                    resolve(response.data);
                } catch (error) {
                    triggerError(error);
                    resolve([]);
                }
            });
        }
    }

    // Post
    static insertARTarget(arTarget, callback) {
        axios.post(url, arTarget).then(response => {
            callback(response.data);
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.isJoi) {
                callback({ errors: error.response.data.details });
            } else {
                triggerError(error);
            }
        });
    }

    // Put
    static updateARTarget(arTarget, callback) {
        axios.put(`${url}${arTarget._id}`, arTarget).then(response => {
            response.data._id = arTarget._id;
            callback(response.data);
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.isJoi) {
                callback({ errors: error.response.data.details });
            } else {
                triggerError(error);
            }
        });
    }

    // Delete
    static deleteARTarget(arTargetId, callback) {
        axios.delete(`${url}${arTargetId}`).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }

}

export default ARTargetService;