<template>
    <div id="login">
        <div v-if="!isLoggedIn" id="loginOverlay" />
        <v-layout row justify-center>
            <v-dialog :value="!isLoggedIn" persistent hide-overlay elevation="0" max-width="720px">
                <v-card>
                    <v-toolbar dark dense flat color="primary" class="text-uppercase">
                        <v-toolbar-title>
                            {{ title }}
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-form ref="Login" v-model="valid" lazy-validation class="loginForm" @submit="login">
                        <v-card-title class="primary--text pb-2">
                            Login
                        </v-card-title>
                        <v-card-text class="py-6">
                            <v-alert dense :value="showError" type="error" class="mb-8">
                                Email address or password incorrect.
                            </v-alert>
                            <v-text-field v-model="email" label="Email Address" required />
                            <v-text-field v-model="password" label="Password" type="password" required />
                        </v-card-text>
                        <v-card-actions class="pt-2">
                            <v-spacer />
                            <v-btn color="primary" text type="submit" min-width="80" @click="submit">
                                Login
                            </v-btn>
                        </v-card-actions>
                        <v-navigation-drawer :value="!isLoggedIn" light absolute stateless right width="280">
                            <v-list dense nav class="py-0">
                                <v-card-title class="primary--text pl-2 pb-2">
                                    Status
                                </v-card-title>
                                <PingStatus />
                                <v-divider class="mt-5 mb-3" />
                                <v-card-title class="primary--text pl-2 pb-2 pt-0">
                                    Version
                                </v-card-title>
                                <div class="caption grey--text pl-2 versionBox">
                                    <div class="versionLine">
                                        <span class="versionLabel">Backend</span>
                                        <span class="versionNumber">
                                            {{ backendVersion }}
                                        </span>
                                    </div>
                                    <div class="versionLine">
                                        <span class="versionLabel">Frontend</span>
                                        <span class="versionNumber">
                                            {{ frontendVersion }}
                                        </span>
                                    </div>
                                    <div class="versionLine">
                                        <span class="versionLabel">Build</span>
                                        <span class="versionNumber">
                                            {{ buildNumber }}
                                        </span>
                                    </div>
                                </div>
                            </v-list>
                        </v-navigation-drawer>
                    </v-form>
                </v-card>
            </v-dialog>
        </v-layout>
    </div>
</template>
<script>

    import { mapActions, mapGetters } from "vuex";
    import PingStatus from "./PingStatus.vue";
    import LoginService from "../../services/LoginService.js";
    

    export default {
        components: {
            PingStatus,
        },
        data() {
            return {
                showError: false,
                email: "",
                password: "",
                valid: false,
            };
        },
        computed: {
            ...mapGetters({
                isLoggedIn: "isLoggedIn",
                title: "platform/getTitle",
                backendVersion: "platform/getBackendVersion",
                frontendVersion: "platform/getFrontendVersion",
                buildNumber: "platform/getBuildNumber"
            })
        },
        methods: {
            submit(e) {
                e.preventDefault();
                this.showError = false;
                LoginService.login(this.email, this.password, () => {
                    this.showError = true;
                }, (userAndToken) => {
                    this.login(userAndToken);
                });
            },
            l(token, values) {
                return this.$root.l(token, values);
            },
            ...mapActions({
                login : "login"
            })
        }
    };
</script>
<style scoped>
    #loginOverlay {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: #ddd;
        z-index: 100;
    }
    .loginForm {
        position: relative;
        display: block;
        max-width: 720px;
        padding: 0px 290px 10px 10px;
    }
    .versionBox {
        width: 270px;
        line-height: 14px !important;
    }
    .versionLine {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding-bottom: 6px;
    }
    .versionLabel {
        display: block;
        width: 60px;
        font-weight: bold;
    }
    .versionNumber {
        display: block;
        width: 200px;
        overflow-wrap: break-word;
    }
</style>