import axios from "axios";
import { triggerError } from "../utils/alerts.js";


const url = "/api/minAppVersion/";


class MinAppVersionService {

    // Get
    static get(callback) {
        axios.get(`${url}`).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }

    // Put
    static update(minAppVersion, callback) {
        axios.put(`${url}`, minAppVersion).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }

}

export default MinAppVersionService;