<template>
    <div class="stopFormBox">
        <v-overlay :value="showEditBox" z-index="7" @click.native="closeWithOverlay" />
        <transition name="slide-x-transition">
            <div v-if="showEditBox" class="stopForm elevation-5">
                <a class="stopFormArrow" @click="closeWithArrow">
                    <v-icon x-large>
                        mdi-chevron-left
                    </v-icon>
                </a>
                <v-icon class="stopFormIcon" color="primary">
                    {{ stopType.mdiIcon }}
                </v-icon>
                <div class="stopFormTitle subtitle-1">
                    <strong class="primary--text">{{ stopType.title }}</strong>
                    <span v-if="hasSystemAccessRights" class="text-caption text--disabled pl-2">(v{{ stopType.versionNumber }})</span>
                </div>
                <div class="stopFormButton1">
                    <v-tooltip left>
                        <template #activator="{ on }">
                            <v-btn :loading="savingMoveUp" :disabled="!stop.canMoveUp || savingMoveDown || savingDelete" text icon color="primary" v-on="on" @click="moveUpStop()">
                                <v-icon>mdi-arrow-up</v-icon>
                            </v-btn>
                        </template>
                        <span class="body-2">{{ l("Move up") }}</span>
                    </v-tooltip>
                </div>
                <div class="stopFormButton2">
                    <v-tooltip left>
                        <template #activator="{ on }">
                            <v-btn :loading="savingMoveDown" :disabled="!stop.canMoveDown || savingMoveUp || savingDelete" text icon color="primary" v-on="on" @click="moveDownStop()">
                                <v-icon>mdi-arrow-down</v-icon>
                            </v-btn>
                        </template>
                        <span class="body-2">{{ l("Move down") }}</span>
                    </v-tooltip>
                </div>
                <div class="stopFormButton3">
                    <v-tooltip left>
                        <template #activator="{ on }">
                            <v-btn :loading="savingDelete" :disabled="savingMoveUp || savingMoveDown" text icon color="primary" v-on="on" @click="deleteStop()">
                                <v-icon>delete</v-icon>
                            </v-btn>
                        </template>
                        <span class="body-2">{{ l("Delete") }}</span>
                    </v-tooltip>
                </div>
                <EditBlockForm v-model="form" />
            </div>
        </transition>
    </div>
</template>
<script>

    import { mapGetters } from "vuex";
    import EditBlockForm from "../informationPages/EditBlockForm.vue";
    import { createParameterFields } from "../../utils/ParameterUtils.js";
    import { createForm } from "../../utils/FormUtils.js";
    import { l } from "../../utils/LocalizationUtils.js";


    export default {
        components: {
            EditBlockForm
        },
        props: {
            stop: { type: Object, default: () => { } },
            stopType: { type: Object, default: () => { } },
            showEditBox: { type: Boolean, default: false },
            saving: { type: Boolean, default: false }
        },
        data() {
            return {
                form: {},
                savingMoveUp: false,
                savingMoveDown: false,
                savingDelete: false,
                waitingForClosingPromptResolution: false
            }
        },
        computed: {
            ...mapGetters({
                hasSystemAccessRights: "user/hasSystemAccessRights"
            })
        },
        watch: {
            stopType() {
                this.setupForm();
            },
            showEditBox() {
                if (this.showEditBox) {
                    this.setupForm();
                }
            },
            saving() {
                this.form.waitingForResponse = this.saving;
            }
        },
        methods: {
            setupForm() {

                this.waitingForClosingPromptResolution = false;

                this.form = createForm({

                    fieldDefs: createParameterFields(this.stopType.parameters, this.stop.parameters, false),

                    onSubmit: values => {
                        this.$emit("saveStop", values);
                    },

                    cancelled: () => {
                        this.closeEditBox();
                    }
                });
            },
            moveUpStop() {
                this.savingMoveUp = true;
                this.$emit("moveUpStop", () => {
                    this.savingMoveUp = false;
                });
            },
            moveDownStop() {
                this.savingMoveDown = true;
                this.$emit("moveDownStop", () => {
                    this.savingMoveDown = false;
                });
            },
            deleteStop() {
                this.savingDelete = true;
                this.$emit("deleteStop", () => {
                    this.savingDelete = false;
                });
            },
            closeEditBox() {
                this.$emit("close");
            },
            assignErrors(errors) {
                this.form.assignErrors(errors);
            },
            closeWithOverlay() {
                if (!this.waitingForClosingPromptResolution) {
                    this.closeWithArrow();
                }
            },
            closeWithArrow() {
                this.waitingForClosingPromptResolution = true;
                if (this.form.hasChanges) {
                    this.$root.$emit(
                        "savePrompt",
                        this.l("Save changes"),
                        this.l("Do you want to save your changes to this stop?"),
                        (save, close) => {
                            if (close) {
                                if (save) {
                                    this.form.submit();
                                } else {
                                    this.closeEditBox();
                                }
                            }
                            setTimeout(() => {
                                this.waitingForClosingPromptResolution = false;
                            }, 500);
                        }
                    );
                } else {
                    this.closeEditBox();
                }
            },
            l
        }
    }
</script>
<style scoped>

    .stopFormBox {
        position: absolute;
        left: 350px;
        top: 6px;
        right: 0;
        min-width: 520px;
        max-width: 9200px;
    }

    .stopForm {
        position: relative;
        left: 0px;
        top: 0px;
        right: 0px;
        min-height: 50px;
        padding: 40px 10px 10px 10px;
        background: #fff;
        z-index: 9;
    }

    .stopFormArrow {
        display: block;
        position: absolute;
        left: -5px;
        top: 5px;
        width: 40px;
        height: 40px;
    }

    .stopFormIcon {
        position: absolute;
        top: 9px;
        left: 32px;
        width: 32px;
        height: 32px;
    }

    .stopFormTitle {
        position: absolute;
        top: 5px;
        left: 64px;
        right: 118px;
        height: 40px;
        line-height: 40px;
    }

    .stopFormButton1 {
        position: absolute;
        top: 7px;
        width: 36px;
        right: 82px;
    }

    .stopFormButton2 {
        position: absolute;
        top: 7px;
        width: 36px;
        right: 46px;
    }

    .stopFormButton3 {
        position: absolute;
        top: 7px;
        width: 36px;
        right: 10px;
    }

</style>