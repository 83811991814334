import MediaService from "../services/MediaService.js";
import TourService from "../services/TourService.js";
import ARTargetService from "../services/ARTargetService.js";
import StringUtils from "./StringUtils.js";
import { inContentLocale } from "../locales/utils/localeUtils.js";
import { l, localize, getObjectWithValueForAllAppLocales } from "./LocalizationUtils.js";
import { getPreviewData } from "./referencePreviewUtils.js";
import { updateLookups } from "./referenceUtils.js";
import { getPage, getAllPages, getPageName } from "../pages/utils/pageUtils.js";


function createMediaItem(media) {
    
    const fileSize = StringUtils.niceFileSize(media.fileSize);
    
    const item = {
        _id: media._id,
        optionTitle: media.name,
        optionDescription: media.fileType + " - " + fileSize,
        showPreview: "icon",
        previewIcon: "mdi-file-outline",
        defaultLinkLabel: getObjectWithValueForAllAppLocales(media.name),
        defaultLinkImage: null
    };

    if (media.fileTypeGroup === "image" || media.fileTypeGroup === "svg") {
        item.showPreview = "image";
        item.previewImage = "/assets/" + media._id + ".jpg?quality=veryLow&update=" + media.fileId.substring(2, 8);    // '&update' is needed such that the thumbnail is updated when the referenced image changes

    } else if (media.fileTypeGroup === "audio") {
        item.previewIcon = "music_video";
    } else if (media.fileTypeGroup === "video") {
        item.previewIcon = "movie";
    } else if (media.fileTypeGroup === "json" || media.fileTypeGroup === "xml") {
        item.previewIcon = "description";
    }

    return item;
}


export function createPageItem(page) {

    const previewData = getPreviewData("page", page);

    previewData.optionTitle = previewData.label;
    previewData.optionDescription = previewData.description;

    previewData.previewIcon = previewData.icon;
    previewData.previewImage = previewData.image;
    previewData.showPreview = previewData.image ? "image" : "icon";

    previewData.defaultLinkLabel = previewData.appLinkPreviewLabel;
    previewData.defaultLinkImage = previewData.appLinkPreviewImage;

    return previewData;
}


function createTourItem(tour) {
    return {
        _id: tour._id,
        optionTitle: localize(inContentLocale(), tour.name),
        optionDescription: l("Tour") + " – " + l("By ${v}", localize(inContentLocale(), tour.author)),
        showPreview: "image",
        previewImage: "/assets/" + tour.image + ".jpg?quality=veryLow&update=" + tour.image.substring(2, 8),     // '&update' is needed such that the thumbnail is updated when the referenced image changes
        defaultLinkLabel: tour.name,
        defaultLinkImage: tour.image
    };
}


function createARTargetItem(arTarget) {

    const image = arTarget.overlay ? arTarget.overlay : arTarget.target;

    const item = {
        _id: arTarget._id,
        optionTitle: arTarget.title,
        optionDescription: "",
        showPreview: "image",
        previewImage: "/assets/" + image + ".jpg?quality=veryLow&update=" + image.substring(2, 8),     // '&update' is needed such that the thumbnail is updated when the referenced image changes 
        defaultLinkLabel: arTarget.title,
        defaultLinkImage: image
    };

    if (arTarget.linkedContent) {
        const page = getPage(arTarget.linkedContent);
        if (page) {
            const previewData = getPreviewData("page", page);
            item.optionTitle = previewData.label;
            item.optionDescription = previewData.description;
            item.defaultLinkLabel = previewData.appLinkPreviewLabel;
        } else {
            item.optionTitle = "???";
            item.optionDescription = l("Cannot find linked content!");
            item.defaultLinkLabel = getObjectWithValueForAllAppLocales("???");
        }
        
    }

    return item;
}


function isAllowedPageType(allowedPageTypes, checkPageType) {
    if (!allowedPageTypes || !Array.isArray(allowedPageTypes)) {
        return true;
    }
    if (allowedPageTypes.includes("encyclopedia")) {
        return (checkPageType !== "topic" || allowedPageTypes.includes("topic"))
            && (checkPageType !== "tour" || allowedPageTypes.includes("tour"));
    }
    return allowedPageTypes.includes(checkPageType);
}


export async function getItemsForReferenceField(field) {
    return new Promise(async resolve => {

        const referenceOf = field.referenceOf;

        const items = [];

        // Media reference
        if (referenceOf === "media") {
            const allMedia = await MediaService.getMedias();
            allMedia.forEach(media => {
                if (!field.referenceType || field.referenceType === media.fileTypeGroup) {
                    items.push(createMediaItem(media));
                }
            });


            // Page reference
        } else if (referenceOf === "informationPage") {
            await updateLookups(["pages"]);
            getAllPages().forEach(page => {
                if (!page.hidden && isAllowedPageType(field.pageTypes, page.informationPageTypeId)) {
                    items.push(createPageItem(page));
                }
            });
            if (isAllowedPageType(field.pageTypes, "tour")) {
                const tours = await TourService.getTours();
                tours.forEach(tour => {
                    if (!tour.hidden) {
                        items.push(createTourItem(tour));
                    }
                });
            }


            // Topic reference
        } else if (referenceOf === "topic") {
            await updateLookups(["pages"]);
            getAllPages().forEach(page => {
                if (!page.hidden && isAllowedPageType(["topic"], page.informationPageTypeId)) {
                    items.push(createPageItem(page));
                }
            });


            // Tour reference
        } else if (referenceOf === "tour") {
            const tours = await TourService.getTours();
            tours.forEach(tour => {
                if (!tour.hidden) {
                    items.push(createTourItem(tour));
                }
            });

    
            // AR target reference
        } else if (referenceOf === "arTarget") {
            await updateLookups(["pages"]);
            const arTargets = await ARTargetService.getARTargets();
            arTargets.forEach(arTarget => {
                items.push(createARTargetItem(arTarget));
            });
        }

        // Sort items alphabetically by title
        items.sort((a, b) => {
            return a.optionTitle.localeCompare(b.optionTitle);
        });

        resolve(items);
    });
};


