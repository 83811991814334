import LanguageService from "../../services/LanguageService.js";


export default {


    namespaced: true,


    state: {

        cmsLocales: [
            {
                ietfLanguageTag: "en-US",
                name: "English (United States)",
                acceptedLanguages: ["en-US", "en"],
            },
            {
                ietfLanguageTag: "de-CH",
                name: "Deutsch (Schweiz)",
                acceptedLanguages: ["de-CH", "de"],
            },
            {
                ietfLanguageTag: "fr-CH",
                name: "Français (Suisse)",
                acceptedLanguages: ["fr-CH", "fr"],
            },
        ],
        appLocales: [],

        cmsLocale: null,
        contentLocale: null,
        previewLocale: null,

        fallbackLocale: {
            ietfLanguageTag: "en-US",
            name: "English (United States) [fallback]",
            acceptedLanguages: ["en-US", "en"],
        },
    },


    mutations: {

        setAppLocales(state, appLocales) {
            state.appLocales = appLocales;
        },

        setCMSLocale(state, cmsLocale) {
            state.cmsLocale = cmsLocale;
            if (cmsLocale.ietfLanguageTag) {
                localStorage.setItem("cmsLocale", cmsLocale.ietfLanguageTag); // set in-browser storage
            }
        },

        setContentLocale(state, contentLocale) {
            state.contentLocale = contentLocale;
        },

        setPreviewLocale(state, previewLocale) {
            state.previewLocale = previewLocale;
        },

    },


    actions: {

        loadAppLanguages({ commit, dispatch, rootGetters }) {
            
            LanguageService.getLanguages(locales => {

                const sortedLocales = [...locales].sort((a, b) => {
                    return a.priority - b.priority;
                });

                const appLocales = [];

                sortedLocales.forEach(locale => {

                    const acceptedLanguages = [];
                    const ietfLanguageTagParts = locale.ietfLanguageTag.split("-");
                    for (let i = ietfLanguageTagParts.length; i >= 1; i--) {
                        acceptedLanguages.push(ietfLanguageTagParts.slice(0, i).join("-"));
                    }

                    appLocales.push({
                        ...locale,
                        acceptedLanguages,
                    });
                });

                commit("setAppLocales", appLocales);
                dispatch("selectUserLocales", rootGetters["user/getUser"]);
            });
            
        },

        selectUserLocales({ state, commit }, user) {

            if (state.appLocales && state.appLocales.length > 0) {
                
                let cmsLocale = null;

                if (user && user.language) {
                    cmsLocale = state.cmsLocales.find(locale => locale.ietfLanguageTag === user.language);

                } else if (localStorage.getItem("cmsLocale")) { // in-browser storage
                    cmsLocale = state.cmsLocales.find(locale => locale.ietfLanguageTag === localStorage.getItem("cmsLocale"));
                }

                if (cmsLocale) {
                    commit("setCMSLocale", cmsLocale);

                } else {
                    commit("setCMSLocale", state.cmsLocales[0]);
                }


                const contentLocale = state.appLocales.find(locale => locale.ietfLanguageTag === user.contentLocale);

                if (contentLocale) {
                    commit("setContentLocale", contentLocale);
                    if (!state.previewLocale) {
                        commit("setPreviewLocale", contentLocale);
                    }

                } else if(state.appLocales.length > 0) {
                    commit("setContentLocale", state.appLocales[0]);
                    if (!state.previewLocale) {
                        commit("setPreviewLocale", state.appLocales[0]);
                    }
                }
            }
        },

    },


    getters: {

        getCMSLocales(state) {
            return state.cmsLocales;
        },

        getAppLocales(state) {
            return state.appLocales;
        },

        getCMSLocale(state) {
            return state.cmsLocale;
        },

        getContentLocale(state) {
            return state.contentLocale;
        },

        getPreviewLocale(state) {
            return state.previewLocale;
        },

        getFallbackLocale(state) {
            return state.fallbackLocale;
        },
    },

}