<template>
    <div class="defaultContentView">
        <ExhibitionList
            v-if="!showARTargetManager"
            :reload-trigger="listReloadTrigger"
            @add="openCreateForm"
            @edit="openEditForm"
            @openARTargetManager="openARTargetManager"
            @duplicate="openDuplicateForm"
        />
        <CreateExhibition
            v-if="showCreateForm"
            @cancel="closeCreateForm"
            @createdEntry="createdEntry"
        />
        <EditExhibition
            v-if="showEditForm"
            :entry="editingEntry"
            @cancel="closeEditForm"
            @editedEntry="editedEntry"
        />
        <DuplicateExhibition
            v-if="showDuplicateForm"
            :entry="editingEntry"
            @cancel="closeDuplicateForm"
            @duplicatedEntry="duplicatedEntry"
        />
        <ARTargetManager
            v-if="showARTargetManager"
            :exhibition-id="editingEntry._id"
            @close="closeARTargetManager"
        />
    </div>
</template>
<script>

    import { mapActions } from "vuex";
    import ExhibitionList from "../components/exhibitions/ExhibitionList.vue";
    import CreateExhibition from "../components/exhibitions/CreateExhibition.vue";
    import EditExhibition from "../components/exhibitions/EditExhibition.vue";
    import DuplicateExhibition from "../components/exhibitions/DuplicateExhibition.vue";
    import ARTargetManager from "../components/exhibitions/ARTargetManager.vue";

    export default {
        components: {
            ExhibitionList,
            CreateExhibition,
            EditExhibition,
            DuplicateExhibition,
            ARTargetManager,
        },
        data() {
            return {
                listReloadTrigger: 0,
                showCreateForm: false,
                showEditForm: false,
                showDuplicateForm: false,
                showARTargetManager: false,
                editingEntry: null,
            };
        },
        mounted() {
            this.updateSectionTitle(this.l("Exhibitions"));
        },
        methods: {
            openCreateForm() {
                this.showCreateForm = true;
            },
            closeCreateForm() {
                this.showCreateForm = false;
            },
            createdEntry() {
                this.closeCreateForm();
                this.listReloadTrigger++;
            },
            openEditForm(entry) {
                this.editingEntry = entry;
                this.showEditForm = true;
            },
            closeEditForm() {
                this.showEditForm = false;
                this.editingEntry = null;
            },
            editedEntry() {
                this.closeEditForm();
                this.listReloadTrigger++;
            },
            openDuplicateForm(entry) {
                this.editingEntry = entry;
                this.showDuplicateForm = true;
            },
            closeDuplicateForm() {
                this.showDuplicateForm = false;
                this.editingEntry = null;
            },
            duplicatedEntry() {
                this.closeDuplicateForm();
                this.listReloadTrigger++;
            },
            openARTargetManager(entry) {
                this.editingEntry = entry;
                this.showARTargetManager = true;
            },
            closeARTargetManager() {
                this.showARTargetManager = false;
                this.editingEntry = null;
                this.listReloadTrigger++;
            },
            l(token, values) {
                return this.$root.l(token, values);
            },
            ...mapActions({
                updateSectionTitle: "platform/updateSectionTitle"
            })
        }
    }
</script>
