import { inFallbackLocale } from "../locales/utils/localeUtils.js";
import enUs from "../locales/en-us.js";
import deCh from "../locales/de-ch.js";
import frCh from "../locales/fr-ch.js";


class Localization {
    
    static l(language, token, values) {
        const v = values;

        if (typeof token === "undefined" || token === null || token === "") {
            return token;
        }

        let currentLangauge = Localization.languages[language.ietfLanguageTag]?.translations;
        let fallbackLanguage = Localization.languages[inFallbackLocale().ietfLanguageTag]?.translations;

        if (currentLangauge && currentLangauge[token]) {
            return eval("`" + currentLangauge[token] + "`");
        }
        if (fallbackLanguage && fallbackLanguage[token]) {
            return eval("`" + fallbackLanguage[token] + "`");
        }
        return eval("`" + token + "`");
    }

}


Localization.languages = {
    "en-US": enUs,
    "de-CH": deCh,
    "fr-CH": frCh
}


export default Localization;