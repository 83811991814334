<template>
    <div :class="'art-piece-interaction-selection-container' + (field.required ? ' required' : '')">
        <v-progress-linear v-if="loading" indeterminate />
        <v-subheader v-if="(!field.error && !field.inSingleFieldArrayEntry)" class="manual-label label">
            {{ label }}
        </v-subheader>
        <v-subheader v-if="(field.error && !field.inSingleFieldArrayEntry)" style="color:red" class="manual-label label">
            {{ label }}
        </v-subheader>
        <p v-if="field.errorMessages && field.errorMessages.length > 0" style="color:red; margin: 8px 0 0 0; font-size:smaller;">
            {{ field.errorMessages[0] }}
        </p>
        <p v-if="statusMessage" class="body-2 font-italic mx-4 pb-4">
            {{ statusMessage }}
        </p>
        <div v-if="interactionList.length > 0" class="px-4 pb-4">
            <v-btn-toggle v-model="mode" exclusive mandatory color="primary" @change="onInput">
                <v-btn value="single">
                    {{ l("Single interaction") }}
                </v-btn>
                <v-btn value="multiple">
                    {{ l("Multiple interactions") }}
                </v-btn>
            </v-btn-toggle>
            <div v-if="mode === 'single'" class="art-piece-interaction-selection-list">
                <v-radio-group v-model="singleSelection" hide-details class="mt-4 pt-0" @change="onInput">
                    <v-radio
                        v-for="interaction in interactionList"
                        :key="interaction._id"
                        :label="interaction.name"
                        :value="interaction._id"
                        class="my-1"
                    />
                </v-radio-group>
            </div>
            <div v-if="mode === 'multiple'" class="art-piece-interaction-selection-list">
                <div class="mt-4">
                    <v-checkbox
                        v-for="interaction in interactionList"
                        :key="interaction._id"
                        v-model="multipleSelection[interaction._id]"
                        :label="interaction.name"
                        hide-details
                        class="my-1"
                        @change="onInput"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import InteractionService from "../../../services/InteractionService.js";
    import { l } from "../../../utils/LocalizationUtils.js";


    export default {
        model: {
            prop: "propField",
            event: "input"
        },
        props: {
            propField: { type: Object, default: null }
        },
        data() {
            return {
                mode: "single",
                interactionList: [],
                singleSelection: null,
                multipleSelection: {},
                statusMessage: "",
                loading: false
            }
        },
        computed: {
            field() {
                return this.propField;
            },
            label() {
                return this.field.label ? l(this.field.label) : null;
            }
        },
        watch: {
            "field.otherParameters.arTargetId.value": function () {
                this.setupInteractionList();
            }
        },
        mounted() {
            this.setupInteractionList(() => {
                this.loadValues();
            });
        },
        methods: {
            loadValues() {
                if (this.field.value && Array.isArray(this.field.value)) {
                    this.mode = this.field.value.length <= 1 ? "single" : "multiple";
                    if (this.mode === "single" && this.field.value.length === 1) {
                        this.singleSelection = this.field.value[0];
                    } else if (this.mode === "multiple") {
                        this.field.value.forEach(selection => {
                            this.multipleSelection[selection] = true;
                        });
                    }
                }
            },
            setupInteractionList(callback) {

                this.mode = "single";
                this.interactionList = [];
                this.singleSelection = null;
                this.multipleSelection = {};
                this.statusMessage = "";

                if (this.field
                    && this.field.otherParameters
                    && this.field.otherParameters.arTargetId) {

                    const arTargetId = this.field.otherParameters.arTargetId.value;

                    if (arTargetId) {
                        this.loading = true;

                        InteractionService.getInteractionsByARTarget(arTargetId, interactionList => {

                            if (interactionList.length > 0) {

                                this.interactionList = interactionList;
                                interactionList.forEach(interaction => {
                                    this.multipleSelection[interaction._id] = false;
                                });

                                if (callback) {
                                    callback();
                                }

                            } else {
                                this.statusMessage = this.l("The selected art piece does not have any interactions.")
                            }


                            
                            /*if (this.imageIsLoaded) { TODO
                                this.$nextTick(() => {
                                    this.updatePoiOnPicture();
                                });
                            }*/

                            this.loading = false;
                        });

                    } else {
                        this.statusMessage = this.l("Please select an art piece above.");
                    }
                }
            },
            onInput() {
                const selectedInteractions = [];
                if (this.mode === "single" && this.singleSelection) {
                    selectedInteractions.push(this.singleSelection);

                } else if (this.mode === "multiple") {
                    this.interactionList.forEach(interaction => {
                        if (this.multipleSelection[interaction._id]) {
                            selectedInteractions.push(interaction._id);
                        }
                    });
                }
                this.field.value = selectedInteractions;
            },
            l
        }
    }
</script>
<style>
    /* should not be scoped */

    .art-piece-interaction-selection-container {
        background: #f3f3f3;
        max-width: 800px;
    }

    .art-piece-interaction-selection-list label::after {
        content: none !important;
    }

</style>