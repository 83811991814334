<template>
    <div class="tourBlockContainer">
        <div v-if="tour" class="tourBlock">
            <div class="tourSidebar" />
            <img v-if="tourImage" :style="'object-position:' + values.horizontalAlignment + ' ' + values.verticalAlignment" :src="'/assets/' + tourImage + '.jpg?quality=high'">
            <div class="tourGradientBox">
                <div v-if="tourTitle" class="tourTitle">
                    {{ tourTitle }}
                </div>
                <div v-if="tourDescription" class="tourDescription">
                    {{ tourDescription }}
                </div>
                <div v-if="tourDuration" class="tourDuration">
                    {{ tourDuration }}
                </div>
            </div>
            <div class="topLeftBadge">
                <v-icon class="topLeftIcon" size="18">
                    mdi-walk
                </v-icon>
                <div class="topLeftLabel">
                    {{ topLeftLabel }}
                </div>
            </div>
        </div>
        <div v-if="tourNotFound" class="tourNotFound">
            <v-icon x-large>
                mdi-help
            </v-icon>
        </div>
    </div>
</template>
<script>

    import TourService from "../../../services/TourService.js";
    import { inPreviewLocale } from "../../../locales/utils/localeUtils.js";
    import { l, lInPreviewLocale, localize } from "../../../utils/LocalizationUtils.js";


    export default {
        props: {
            values: { type: Object, default: () => { } }
        },
        data() {
            return {
                tour: null,
                tourNotFound: false
            }
        },
        computed: {
            tourTitle() {
                if (this.tour) {
                    return localize(inPreviewLocale(), this.tour.name);
                }
                return "";
            },
            tourImage() {
                if (this.tour) {
                    return this.tour.image;
                }
                return null;
            },
            tourDescription() {
                if (this.tour) {
                    const description = localize(inPreviewLocale(), this.tour.teaser);
                    return description.length > 70 ? description.substring(0, 70) + "…" : description;
                }
                return "";
            },
            tourDuration() {
                const duration = this.tour.duration;
                if (typeof duration === "undefined" || duration === null || duration === "" || duration <= 0) {
                    return "";
                } else if (duration == 1) { // do not ===
                    return lInPreviewLocale("1 minute");
                } else {
                    return lInPreviewLocale("${v} minutes", duration);
                }
            },
            topLeftLabel() {
                return lInPreviewLocale("Tour");
            }
        },
        watch: {
            values() {
                this.updateTour();
            }
        },
        mounted() {
            this.updateTour();
        },
        methods: {
            updateTour() {
                this.tour = null;
                this.tourNotFound = false;
                if (this.values.id) {
                    TourService.getTour(this.values.id, tour => {
                        if (tour) {
                            this.tour = tour;
                        } else {
                            this.tourNotFound = true;
                        }
                    }, error => {
                        this.tourNotFound = true;
                    });
                } else {
                    this.tourNotFound = true;
                }
            },
            l,
        }
    }

</script>
<style scoped>

    .sectionTitle {
        position: relative;
        padding: 24px 20px 8px;
        min-height: 65px;
        font-size: 22px;
        font-weight: 600;
        color: #fff;
        background: #212121;
    }

</style>
<style scoped>

    .tourBlockContainer {
        position: relative;
        width: 480px;
        height: 323px;
        background: #212121;
    }

    .tourBlock {
        position: absolute;
        left: 20px;
        top: 10px;
        width: 440px;
        height: 303px;
    }

    .tourSidebar {
        position: absolute;
        left: 0;
        top: 0;
        width: 4px;
        height: 303px;
        background: #FF6F00;
    }

    .tourBlock img {
        position: absolute;
        display: block;
        left: 4px;
        top: 0;
        width: 436px;
        height: 303px;
        object-fit: cover;
    }


    .tourGradientBox {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 16px 16px 12px;
        background-image: linear-gradient(to bottom, rgba(33, 33, 33, 0.0), rgba(33, 33, 33, 0.8));
    }

    .tourTitle {
        padding-bottom: 4px;
        font-size: 30px;
        font-weight: 500;
        color: #fff;
    }

    .tourDescription {
        padding-bottom: 4px;
        font-size: 18px;
        color: #fff;
    }

    .tourDuration {
        font-size: 18px;
        font-style: italic;
        color: #fff;
    }


    .topLeftBadge {
        display: flex;
        align-items: center;
        position: absolute;
        left: 4px;
        top: 0;
        height: 24px;
        background: #424242;
    }

    .topLeftIcon {
        color: #fff;
        margin: 0 4px 0 6px;
    }

    .topLeftLabel {
        font-size: 15px;
        color: #fff;
        margin-right: 6px;
    }

    .tourNotFound {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: center;
        left: 20px;
        top: 10px;
        width: 440px;
        height: 303px;
        background: #999;
    }
</style>