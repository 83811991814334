<template>
    <v-tooltip bottom color="#fff" content-class="tooltip image-transform-tooltip elevation-3">
        <template #activator="{ on, attrs }">
            <div class="helpButton" v-bind="attrs" v-on="on">
                <v-icon small>
                    mdi-help-circle-outline
                </v-icon>
                <div class="ml-2 text-caption">
                    {{ l("Available icons") }}
                </div>
            </div>
        </template>
        <div class="tableContainer">
            <table>
                <tbody>
                    <tr>
                        <td>
                            <div class="examplePoi">
                                <v-icon>
                                    mdi-circle-medium
                                </v-icon>
                            </div>
                        </td>
                        <td class="text-body-2 iconLabel pl-1">
                            Default
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="examplePoi">
                                <v-icon>
                                    mdi-magnify
                                </v-icon>
                            </div>
                        </td>
                        <td class="text-body-2 iconLabel pl-1">
                            Detail
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="examplePoi">
                                <v-icon>
                                    mdi-star
                                </v-icon>
                            </div>
                        </td>
                        <td class="text-body-2 iconLabel pl-1">
                            Special
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="examplePoi">
                                <v-icon>
                                    mdi-account
                                </v-icon>
                            </div>
                        </td>
                        <td class="text-body-2 iconLabel pl-1">
                            Identity
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="examplePoi">
                                <v-icon>
                                    mdi-open-in-new
                                </v-icon>
                            </div>
                        </td>
                        <td class="text-body-2 iconLabel pl-1">
                            Link
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="examplePoi">
                                <v-icon>
                                    mdi-flip-horizontal
                                </v-icon>
                            </div>
                        </td>
                        <td class="text-body-2 iconLabel pl-1">
                            Reveal
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </v-tooltip>
</template>
<script>

    import { l } from "../../../utils/LocalizationUtils.js";


    export default {
        methods: {
            l
        }
    };

</script>

<style scoped>
    
    .helpButton {
        display: flex;
        width: fit-content;
        cursor: pointer;
    }

    .tooltip {
        opacity: 1 !important;
        padding: 5px !important;
    }

    .examplePoi {
        display: flex;
        border: 2px solid rgba(0, 0, 0, 0.6);
        width: 34px;
        height: 34px;
        border-radius: 17px;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .iconLabel {
        color: rgba(0, 0, 0, 0.6);
    }

</style>