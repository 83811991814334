import axios from "axios";
import { triggerError } from "../utils/alerts.js";


const url = "/api/homeContent/";


class HomeContentService {

    // Get
    static getHomeContent(exhibitionId, callback, errorCallback) {
        axios.get(`${url}${exhibitionId}`).then(response => {
            callback(response.data);
        }).catch(error => {
            if (errorCallback) {
                errorCallback(error);
            } else {
                triggerError(error);
            }
        });
    }

    // Put
    static updateHomeContent(exhibitionId, homeContent, callback) {
        axios.put(`${url}${exhibitionId}`, homeContent).then(response => {
            callback(response.data);
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.isJoi) {
                callback({ errors: error.response.data.details });
            } else {
                triggerError(error);
            }
        });
    }

}

export default HomeContentService;