import { backwardCompatibleL } from "./LocalizationUtils.js";
import { l, dateString } from "../localization/localization.js";


class StringUtils {


    static niceDate(date, nullText) {
        if (date === null) {
            return nullText;
        } else {
            let dateObj = new Date(date);
            let dateText = dateObj.getFullYear() + "-";
            dateText += pad(dateObj.getMonth() + 1, 2) + "-";
            dateText += pad(dateObj.getDate(), 2) + " ";
            dateText += pad(dateObj.getHours(), 2) + ":";
            dateText += pad(dateObj.getMinutes(), 2);
            return dateText;
        }
    }

    // Converts the 'fileSize' (given in bytes) into a string, representing 'fileSize' in KB, MB or GB (depending on the size)
    static niceFileSize(fileSize) {
        let extension = "bytes";
        if (fileSize > 1024) {
            fileSize /= 1024;
            extension = "KB";
        }
        if (fileSize > 1024) {
            fileSize /= 1024;
            extension = "MB";
        }
        if (fileSize > 1024) {
            fileSize /= 1024;
            extension = "GB";
        }
        return fileSize.toFixed(extension === "bytes" ? 0 : 1) + " " + extension;
    }

}

export default StringUtils;


export function isString(string) {
    return typeof string === "string" || string instanceof String;
}


export function pad(number, length) {
    var numberString = number.toString();
    var pad = "00000000";
    return pad.substring(0, length - numberString.length) + numberString;
}


export function dateStringFromDateObject(locale, date, qualifier) {
    if (!date || !(date instanceof Date) || isNaN(date)) {
        return l.general.datetime.invalidDate(locale);
    }
    return dateString(locale, date.getFullYear(), date.getMonth() + 1, date.getDate(), qualifier);
}


export function preciseDuration(seconds, abbreviate) {
    if (isNaN(seconds)) {
        return "0 s";
    }
    let string = "";
    const days = Math.floor(seconds / 86400);
    if (days > 0) {
        string += abbreviate ? (days + "d ") : (backwardCompatibleL("${v} days", days) + ", ");
        seconds -= days * 86400;
    }
    const hours = Math.floor(seconds / 3600);
    if (hours > 0 || days > 0) {
        string += abbreviate ? (hours + "h ") : (backwardCompatibleL("${v} hours", hours) + ", ");
        seconds -= hours * 3600;
    }
    const minutes = Math.floor(seconds / 60);
    if (minutes > 0 || hours > 0 || days > 0) {
        string += abbreviate ? (minutes + "m ") : (backwardCompatibleL("${v} minutes", minutes) + ", ");
        seconds -= minutes * 60;
    }
    seconds = Math.round(seconds);
    string += abbreviate ? (seconds + "s") : (backwardCompatibleL("${v} seconds", seconds));
    return string;
}


export function shortDuration(seconds, abbreviate) {
    const short = abbreviate ? "." : "";
    if (isNaN(seconds)) {
        return backwardCompatibleL("${v} seconds" + short, "0");
    }
    if (seconds < 60) {
        return backwardCompatibleL("${v} seconds" + short, Math.round(seconds));
    }
    const minutes = seconds / 60;
    if (minutes < 60) {
        return backwardCompatibleL("${v} minutes" + short, minutes.toFixed(1));
    }
    const hours = minutes / 60;
    if (hours < 24) {
        return backwardCompatibleL("${v} hours" + short, hours.toFixed(1));
    }
    const days = hours / 24;
    return backwardCompatibleL("${v} days" + short, days.toFixed(1));
}


export function getPercentage(value, digits) {
    return (100 * value).toFixed(digits) + "%";
}


export function clip(string, maxLength) {
    if (!isString(string)) {
        return "";
    }
    if (string.length > maxLength) {
        return string.substring(0, maxLength - 1) + "…";
    }
    return string;
}
