<template>
    <v-layout row justify-center>
        <v-dialog :value="true" persistent max-width="720px" scrollable>
            <v-card>
                <v-card-title class="pb-4">
                    <span class="headline primary--text">
                        <v-icon large color="primary" class="title-icon">
                            mdi-eye
                        </v-icon>
                        {{ l("Manage Visibility for \"${v}\"", arTarget.label) }}
                    </span>
                </v-card-title>
                <v-divider />
                <v-card-text ref="scrollArea">
                    <v-container fluid class="pt-0 pb-2 px-0 mx-0">
                        <v-row align="center" no-gutters>
                            <v-col cols="6">
                                <v-text-field v-model="search" append-icon="mdi-magnify" :label="l('Search')" single-line clearable hide-details />
                            </v-col>
                            <v-spacer />
                        </v-row>
                    </v-container>
                    <v-data-table
                        hide-default-footer
                        :headers="headers"
                        :items="list"
                        :sort-by="sortBy"
                        :items-per-page="itemsPerPage"
                        :loading="loading"
                        :search="search"
                        :no-data-text="l('No entries.')"
                    >
                        <template #item="row">
                            <tr>
                                <td style="width:68px">
                                    <v-tooltip v-if="row.item.visible" right>
                                        <template #activator="{ on }">
                                            <v-btn text icon color="primary" :disabled="loading && !row.item.loading" :loading="row.item.loading" v-on="on" @click="toggleVisibility(row.item)">
                                                <v-icon>mdi-eye</v-icon>
                                            </v-btn>
                                        </template>
                                        <span class="body-2">{{ l("Hide in Exhibition") }}</span>
                                    </v-tooltip>
                                    <v-tooltip v-if="!row.item.visible" right>
                                        <template #activator="{ on }">
                                            <v-btn text icon color="primary" :disabled="loading && !row.item.loading" :loading="row.item.loading" v-on="on" @click="toggleVisibility(row.item)">
                                                <v-icon>mdi-eye-off-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span class="body-2">{{ l("Show in Exhibition") }}</span>
                                    </v-tooltip>
                                </td>
                                <td class="clickable truncate" @click="toggleVisibility(row.item)">
                                    <span>
                                        {{ row.item.title }}
                                    </span>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" text min-width="80" @click="close">
                        {{ l("Close") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-layout>
</template>
<script>

    import ExhibitionService from "../../services/ExhibitionService.js";
    import ARTargetService from "../../services/ARTargetService.js";
    import { triggerSuccess } from "../../utils/alerts.js";
    import { l } from "../../utils/LocalizationUtils.js";


    export default {
        props: {
            arTarget: { type: Object, default: null },
        },
        data() {
            return {
                hasChanges: false,
                loading: false,
                updatedARTarget: null,
                list: [],
                search: "",
                sortBy: "label",
                itemsPerPage: -1,
                headers: [
                    { text: "", sortable: false },
                    { text: "Exhibition", value: "title" },
                ],
            }
        },
        mounted() {
            this.hasChanges = false;
            this.update();
        },
        methods: {

            async update() {
                this.loading = true;

                this.updatedARTarget = await ARTargetService.getARTarget(this.arTarget._id);
                if(!Array.isArray(this.updatedARTarget.uses)) {
                    this.updatedARTarget.uses = [];
                }

                const exhibitions = await ExhibitionService.getExhibitions();
                exhibitions.forEach(entry => {
                    entry.visible = this.updatedARTarget.uses.includes(entry._id);
                    entry.loading = false;
                });

                this.list = exhibitions;
                this.loading = false;
            },

            async toggleVisibility(exhibition) {
                if(!this.loading) {
                    exhibition.loading = true;
                    this.loading = true;
                    await ExhibitionService.linkARTarget(exhibition._id, this.arTarget._id, !exhibition.visible);
                    triggerSuccess(l("Successfully saved AR Target's visibility"));
                    exhibition.loading = false;
                    this.hasChanges = true;
                    this.update();
                }
            },

            close() {
                this.$emit("close", this.hasChanges);
            },

            l
        }
    }
</script>
<style scoped>

    .title-icon {
        vertical-align: sub;
    }

    .clickable {
        cursor: pointer;
    }

</style>