<template>
    <div class="defaultContentView">
        <ARTargetList
            v-if="showARTargetList"
            :reload-trigger="arTargetListReloadTrigger"
            @add="openAddARTargetForm"
            @edit="openEditARTargetForm"
            @editInteractions="openInteractionsList"
        />
        <AddARTarget
            v-if="showAddARTargetForm"
            @cancel="closeAddARTargetForm"
            @createdEntry="onARTargetCreated"
        />
        <EditARTarget
            v-if="showEditARTargetForm"
            :entry="editingARTarget"
            @cancel="closeEditARTargetForm"
            @editedEntry="onARTargetEdited"
        />
        <InteractionsList
            v-if="showInteractionsList"
            :reload-trigger="interactionListReloadTrigger"
            :ar-target="activeARTarget"
            @close="closeInteractionsList"
            @edit="openEditInteractionForm"
            @editSettings="openEditInteractionSettingsForm"
            @add="openAddInteractionForm"
        />
        <AddInteraction
            v-if="showAddInteractionForm"
            :ar-target="activeARTarget"
            :interaction-module="activeInteractionModule"
            @cancel="closeAddInteractionForm"
            @createdEntry="onInteractionCreated"
        />
        <EditInteraction
            v-if="showEditInteractionForm"
            :entry="editingInteraction"
            @cancel="closeEditInteractionForm"
            @editedEntry="onInteractionEdited"
        />
        <EditInteractionSettings
            v-if="showEditInteractionSettingsForm"
            :entry="editingInteraction"
            @cancel="closeEditInteractionSettingsForm"
            @editedEntry="onInteractionSettingsEdited"
        />
    </div>
</template>
<script>

    import { mapActions } from "vuex";
    import ARTargetList from "../components/arTargets/ARTargetList.vue";
    import AddARTarget from "../components/arTargets/AddARTarget.vue";
    import EditARTarget from "../components/arTargets/EditARTarget.vue";
    import InteractionsList from "../components/interactions/InteractionsList.vue";
    import AddInteraction from "../components/interactions/AddInteraction.vue";
    import EditInteraction from "../components/interactions/EditInteraction.vue";
    import EditInteractionSettings from "../components/interactions/EditInteractionSettings.vue";

    export default {
        components: {
            ARTargetList,
            AddARTarget,
            EditARTarget,
            InteractionsList,
            AddInteraction,
            EditInteraction,
            EditInteractionSettings
        },
        data() {
            return {
                arTargetListReloadTrigger: 0,
                showAddARTargetForm: false,
                showEditARTargetForm: false,
                showARTargetList: true,
                editingARTarget: null,
                activeARTarget: null,

                interactionListReloadTrigger: 0,
                showInteractionsList: false,
                showAddInteractionForm: false,
                showEditInteractionForm: false,
                showEditInteractionSettingsForm: false,
                activeInteractionModule: null,
                editingInteraction: null,
            };
        },
        mounted() {
            this.updateSectionTitle(this.l("AR Content"));
            this.$root.$on("arContentSideBarButtonClicked", () => {
                this.resetARContentPage();
            });
        },
        methods: {
            openAddARTargetForm() {
                this.showAddARTargetForm = true;
            },
            closeAddARTargetForm() {
                this.showAddARTargetForm = false;
            },
            onARTargetCreated() {
                this.closeAddARTargetForm();
                this.arTargetListReloadTrigger++;
            },
            openEditARTargetForm(entry) {
                this.editingARTarget = entry;
                this.showEditARTargetForm = true;
            },
            closeEditARTargetForm() {
                this.showEditARTargetForm = false;
                this.editingARTarget = null;
            },
            onARTargetEdited() {
                this.closeEditARTargetForm();
                this.arTargetListReloadTrigger++;
            },
            openInteractionsList(entry) {
                this.activeARTarget = entry;
                this.showARTargetList = false;
                this.showInteractionsList = true;
            },
            closeInteractionsList() {
                this.showARTargetList = true;
                this.showInteractionsList = false;
                this.activeARTarget = null;
            },
            openAddInteractionForm(interactionModule) {
                this.activeInteractionModule = interactionModule;
                this.showAddInteractionForm = true;
            },
            closeAddInteractionForm() {
                this.showAddInteractionForm = false;
            },
            onInteractionCreated() {
                this.closeAddInteractionForm();
                this.interactionListReloadTrigger++;
            },
            openEditInteractionForm(interaction) {
                this.editingInteraction = interaction;
                this.showEditInteractionForm = true;
            },
            closeEditInteractionForm() {
                this.showEditInteractionForm = false;
                this.editingInteraction = null;
            },
            onInteractionEdited() {
                this.closeEditInteractionForm();
                this.interactionListReloadTrigger++;
            },
            openEditInteractionSettingsForm(interaction) {
                this.editingInteraction = interaction;
                this.showEditInteractionSettingsForm = true;
            },
            closeEditInteractionSettingsForm() {
                this.showEditInteractionSettingsForm = false;
                this.editingInteraction = null;
            },
            onInteractionSettingsEdited() {
                this.closeEditInteractionSettingsForm();
                this.interactionListReloadTrigger++;
            },
            resetARContentPage() {
                this.closeAddARTargetForm();
                this.closeEditARTargetForm();
                this.closeAddInteractionForm();
                this.closeEditInteractionForm();
                this.closeEditInteractionSettingsForm();
                this.closeInteractionsList();
            },
            l(token, values) {
                return this.$root.l(token, values);
            },
            ...mapActions({
                updateSectionTitle: "platform/updateSectionTitle"
            })
        }
    }
</script>
