import axios from "axios";
import { triggerError } from "../utils/alerts.js";


const url = "/api/interactionModules/";


class InteractionModulesService {

    // Get all
    static getInteractionModules(callback) {
        if (callback) {
            axios.get(url).then(response => {
                callback(response.data);
            }).catch(error => {
                triggerError(error);
            });
        } else {
            return new Promise(async resolve => {
                try {
                    const response = await axios.get(url);
                    resolve(response.data);
                } catch (error) {
                    triggerError(error);
                    resolve([]);
                }
            });
        }
    }

    // Put
    static updateInteractionModules(interactionModules, callback) {
        axios.put(url, interactionModules).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }

}

export default InteractionModulesService;