import axios from "axios";
import { triggerError } from "../utils/alerts.js";
import { showWaitingAnimation, hideWaitingAnimation } from "../utils/waitingAnimation.js";
import { l } from "../utils/LocalizationUtils.js";


const url = "/api/exhibitions/";
const duplicateUrl = "/api/exhibitions/duplicate/";
const firebaseUpdateUrl = "/api/firebaseJsonUpdate/";
const targetsUrl = "/api/targets/";
const mediaUrl = "/api/exhibitionMedia/";
const linkARTargetUrl = "/api/exhibitions/linkARTarget/"


class ExhibitionService {

    // Get
    static getExhibition(id, callback, errorCallback) {
        if (callback) {
            axios.get(`${url}${id}`).then(response => {
                callback(response.data);
            }).catch(error => {
                if (errorCallback) {
                    errorCallback(error);
                } else {
                    triggerError(error);
                }
            });
        } else {
            return new Promise(async resolve => {
                try {
                    const response = await axios.get(`${url}${id}`);
                    resolve(response.data);
                } catch (error) {
                    triggerError(error);
                    resolve(null);
                }
            });
        }
    }

    // Get all
    static getExhibitions(callback) {
        if (callback) {
            axios.get(url).then(response => {
                callback(response.data);
            }).catch(error => {
                triggerError(error);
            });
        } else {
            return new Promise(async resolve => {
                try {
                    const response = await axios.get(url);
                    resolve(response.data);
                } catch (error) {
                    triggerError(error);
                    resolve([]);
                }
            });
        }
    }

    // Post
    static insertExhibition(exhibition, callback) {
        axios.post(url, exhibition).then(response => {
            callback(response.data);
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.isJoi) {
                callback({ errors: error.response.data.details });
            } else {
                triggerError(error);
            }
        });
    }

    // Post - duplicate existing exhibition
    static duplicateExhibition(exhibition, callback) {
        axios.post(duplicateUrl, exhibition).then(response => {
            callback(response.data);
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.isJoi) {
                callback({ errors: error.response.data.details });
            } else {
                triggerError(error);
            }
        });
    }

    // Put - Link AR Target
    static linkARTarget(exhibitionId, arTargetId, link) {
        return new Promise(async resolve => {
            try {
                await axios.put(linkARTargetUrl, {
                    exhibitionId,
                    arTargetId,
                    link
                });
                resolve();
            } catch (error) {
                triggerError(error);
                resolve(null);
            }
        });
    }

    // Put
    static updateExhibition(exhibition, callback) {
        axios.put(`${url}${exhibition._id}`, exhibition).then(response => {
            response.data._id = exhibition._id;
            callback(response.data);
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.isJoi) {
                callback({ errors: error.response.data.details });
            } else {
                triggerError(error);
            }
        });
    }

    // Delete
    static deleteExhibition(exhibitionId, callback) {
        axios.delete(`${url}${exhibitionId}`).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }


    // Update Json in Firebase
    static updateJsonInFirebase(exhibition, callback) {
        showWaitingAnimation(l("Updating JSON In Firebase"));
        axios.post(`${firebaseUpdateUrl}${exhibition._id}`).then(response => {
            hideWaitingAnimation();
            callback(response.data);
        }).catch(error => {
            hideWaitingAnimation();
            triggerError(error);
        });
    }


    // Download Exhibition's Media
    static downloadMedia(exhibition) {
        showWaitingAnimation(l("Creating Media Package"));
        axios.get(`${mediaUrl}/${exhibition._id}/media.zip`, { responseType: "blob" }).then(response => {
            hideWaitingAnimation();
            let blob = new Blob([response.data], { type: response.data.type });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = exhibition.title + " Media.zip";
            document.body.appendChild(link);
            link.click();
        }).catch(error => {
            hideWaitingAnimation();
            triggerError(error);
        });
    }


    // Download AR Targets
    static downloadArTargets(exhibition, params) {
        showWaitingAnimation(l("Bundling Targets for Vuforia"));
        axios.get(`${targetsUrl}${exhibition._id}.zip`, { responseType: "blob", params }).then(response => {
            hideWaitingAnimation();
            let blob = new Blob([response.data], { type: response.data.type });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = exhibition.title + " AR Targets.zip";
            document.body.appendChild(link);
            link.click();
        }).catch(error => {
            hideWaitingAnimation();
            triggerError(error);
        });
    }

}

export default ExhibitionService;