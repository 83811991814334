<template>
    <div :class="'element' + (showEditBox ? ' activeElement' : '')">
        <BlockPreview
            :information-page-type-attributes="null"
            :home-content-element="element"
            :values="valuesForPreview"
        />
        <a class="elementSelector" @click="openEditBox()" />
        <EditBlockBox
            ref="editBox"
            :block="element"
            :block-type="elementType"
            :show-edit-box="showEditBox"
            :saving="saving"
            @close="closeEditBox"
            @saveBlock="saveElement"
            @moveUpBlock="moveUpElement"
            @moveDownBlock="moveDownElement"
            @deleteBlock="deleteElement"
        />
    </div>
</template>
<script>

    import BlockPreview from "../informationPages/blockPreviews/BlockPreview.vue";
    import EditBlockBox from "../informationPages/EditBlockBox.vue";
    import { deepClone } from "../../utils/objectUtils";
    import { inPreviewLocale } from "../../locales/utils/localeUtils.js";
    import { l, getLocalizedParameterList } from "../../utils/LocalizationUtils.js";


    export default {
        components: {
            BlockPreview,
            EditBlockBox
        },
        props: {
            element: { type: Object, default: () => { } },
            elementTypeLookUp: { type: Object, default: () => {} },
        },
        data() {
            return {
                showEditBox: false,
                elementType: {},
                saving: false
            }
        },
        computed: {
            valuesForPreview() {
                if (this.element
                    && this.element.parameters
                    && this.elementType
                    && this.elementType.parameters) {
                    return getLocalizedParameterList(inPreviewLocale(), this.element.parameters, this.elementType.parameters);
                } else {
                    return {};
                }
            }
        },
        watch: {
            elementTypeLookUp() {
                this.setElementType();
            },
        },
        mounted() {
            this.setElementType();
        },
        methods: {

            setElementType() {
                if (this.elementTypeLookUp.hasOwnProperty(this.element.type)) {
                    this.elementType = this.elementTypeLookUp[this.element.type];
                }
            },

            openEditBox() {
                this.showEditBox = true;
                this.saving = false;
            },

            closeEditBox() {
                this.showEditBox = false;
                this.saving = false;
            },

            assignErrors(errors) {
                this.$refs.editBox.assignErrors(errors);
                this.saving = false;
            },

            saveElement(values) {
                const editedElement = deepClone(this.element);
                editedElement.parameters.splice(0, editedElement.parameters.length);
                this.elementType.parameters.forEach(parameter => {
                    editedElement.parameters.push({
                        key: parameter.key,
                        value: values[parameter.key]
                    });
                });

                this.saving = true;
                this.$emit("save", {
                    originalElement: this.element,
                    editedElement,
                    caller: this,
                    onSuccess: () => {
                        this.closeEditBox();
                    }
                });
            },

            moveUpElement(onSuccess) {
                this.$emit("moveUpElement", onSuccess);
            },

            moveDownElement(onSuccess) {
                this.$emit("moveDownElement", onSuccess);
            },

            deleteElement(onSuccess) {
                this.$emit("deleteElement", onSuccess);
            },

            addElement(newElementInfo) {
                this.$emit("addElement", newElementInfo);
            },

            l
        }
    }
</script>
<style scoped>

    .element {
        position: relative;
        min-height: 40px;
    }

    .activeElement .elementContent {
        z-index: 6;
    }

    .elementSelector {
        display: block;
        position: absolute;
        top: -1px;
        left: -1px;
        width: 482px;
        bottom: -1px;
        user-select: none;
        cursor: pointer;
        z-index: 3;
    }

    .elementSelector:hover {
        border: 2px solid;
        border-radius: 3px;
        background: rgba(204, 204, 204, 0.12);
    }

    .activeElement .elementSelector {
        top: -2px !important;
        left: -2px !important;
        width: 484px !important;
        bottom: -2px !important;
        border: 3px solid !important;
        border-radius: 4px !important;
        z-index: 7 !important;
        background: none !important;
    }

</style>