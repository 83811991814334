<template>
    <div class="croppedImage">
        <div class="croppedImageImage">
            <img v-if="values.image" :style="'object-position:' + values.horizontalAlignment + ' ' + values.verticalAlignment" :src="'/assets/' + values.image + '.jpg?quality=high'">
            <v-icon class="croppedImageMaximizeIcon" size="32">
                mdi-arrow-expand-all 
            </v-icon>
            <div v-if="!values.image" class="croppedImageImageMissing">
                <v-icon x-large>
                    mdi-image-outline
                </v-icon>
                <v-icon x-large>
                    mdi-help
                </v-icon>
            </div>
        </div>
        <!-- eslint-disable -->
        <div v-if="values.caption" class="croppedImageCaption">{{ values.caption }}</div>
        <!-- eslint-enable -->
    </div>
</template>
<script>

    export default {
        props: {
            values: { type: Object, default: () => { } }
        }
    }

</script>
<style scoped>

    .croppedImage {
        position: relative;
        background: #212121;
        padding: 24px 20px;
    }

    .croppedImageImage {
        position: relative;
        height: 240px;
    }    

    .croppedImageImage img {
        position: absolute;
        display: block;
        width: 100%;
        height: 240px;
        object-fit: cover;
    }

    .croppedImageMaximizeIcon {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 24px;
        height: 24px;
        color: #fff;
        text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.7);
    }

    .croppedImageImageMissing {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        height: 240px;
        background: #999;
        text-align: center;
    }

    .croppedImageCaption {
        padding: 6px 10px;
        background: #212121;
        font-size: 15px;
        color: #fafafa;
        white-space: pre-wrap;
    }

</style>