<template>
    <div class="sidebar">
        <LocationList
            :locations="locations"
            :location-being-hovered-over="locationBeingHoveredOver"
            :location-being-edited="locationBeingEdited"
            :loading="loading"
            @hover="onHover"
            @startPlacing="onStartPlacing"
            @reportFocusOnSearchField="reportFocusOnSearchField"
        />
        <LocationEditingPanel
            :location-being-edited="locationBeingEdited"
            :saving="saving"
            @save="save"
            @deleteItem="deleteItem"
            @close="closeEditingPanel"
        />
    </div>
</template>
<script>

    import LocationList from './LocationList.vue';
    import LocationEditingPanel from './LocationEditingPanel.vue';
    import { l } from "../../utils/LocalizationUtils.js";


    export default {
        components: {
            LocationList,
            LocationEditingPanel,
        },
        props: {
            locations: { type: Array, default: () => [] },
            locationBeingHoveredOver: { type: Object, default: null },
            locationBeingEdited: { type: Object, default: null },
            loading: { type: Boolean, default: false },
            saving: { type: Boolean, default: false },
        },
        methods: {

            onHover(location) {
                this.$emit("hover", location);
            },

            onStartPlacing(location) {
                this.$emit("startPlacing", location);
            },

            save(newProperties) {
                this.$emit("save", newProperties);
            },

            deleteItem() {
                this.$emit("deleteItem");
            },

            closeEditingPanel() {
                this.$emit("startPlacing", null);
            },

            reportFocusOnSearchField(focus) {
                this.$emit("reportFocusOnSearchField", focus);
            },

            l,

        },
    }

</script>
<style scoped>

    .sidebar {
        position: absolute;
        display: flex;
        flex-direction: column;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

</style>
