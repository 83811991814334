<template>
    <div :class="{ 'pa-3': label, 'my-4': label, dateInputBox: label, dateInputBoxContained: !label, required: label && field.required }">
        <v-subheader v-if="label && !field.error && !field.inSingleFieldArrayEntry" class="manualLabel label">
            {{ label }}
        </v-subheader>
        <v-subheader v-if="label && field.error && !field.inSingleFieldArrayEntry" style="color:red" class="manualLabel label">
            {{ label }}
        </v-subheader>
        <p v-if="field.errorMessages && field.errorMessages.length > 0" style="color:red; margin: 8px 0 0 0; font-size:smaller;">
            {{ field.errorMessages[0] }}
        </p>
        <div v-if="typeStringField">
            <InputField v-model="typeStringField" />
        </div>
        <div v-if="customLabelField && typeStringField && typeStringField.value === 'Custom'">
            <InputField v-model="customLabelField" />
        </div>
        <div v-if="dateField">
            <InputField v-model="dateField" />
        </div>
        <div v-if="locationField">
            <InputField v-model="locationField" />
        </div>
    </div>
</template>
<script>

    import { setupField } from "../../../utils/FormUtils.js";
    import { l } from "../../../utils/LocalizationUtils.js";


    export default {
        model: {
            prop: "propField",
            event: "input"
        },
        props: {
            propField: { type: Object, default: null }
        },
        data() {
            return {
                typeStringField: null,
                customLabelField: null,
                dateField: null,
                locationField: null
            }
        },
        computed: {
            field() {
                return this.propField;
            },
            label() {
                return this.field.label ? l(this.field.label) : null;
            }
        },
        watch: {
            typeStringField: {
                handler(form) {
                    this.setFieldValue();
                },
                deep: true
            },
            customLabelField: {
                handler(form) {
                    this.setFieldValue();
                },
                deep: true
            },
            dateField: {
                handler(form) {
                    this.setFieldValue();
                },
                deep: true
            },
            locationField: {
                handler(form) {
                    this.setFieldValue();
                },
                deep: true
            }
        },
        beforeCreate() { // Delayed InputField import, to prevent recursion of imports
            this.$options.components.InputField = require('./InputField.vue').default;
        },
        mounted() {

            const labelOptions = [
                ...(this.field.labelOptions ? this.field.labelOptions : []),
                "Custom"
            ];

            this.typeStringField = setupField({
                label: "Label",
                type: "Select",
                options: labelOptions
            }, this.field.value?.typeString || labelOptions[0])

            this.customLabelField = setupField({
                label: "Custom Label",
                type: "Text",
                localized: true
            }, this.field.value?.customLabel);

            this.dateField = setupField({
                label: "Date",
                type: "Date"
            }, this.field.value?.date);

            this.locationField = setupField({
                label: "Location",
                type: "Reference",
                referenceOf: "informationPage",
                pageTypes: [ "location" ],
                allowCustomLabel: true,
            }, this.field.value?.location);
        },
        methods: {
            setFieldValue() {
                this.field.value = {
                    typeString: this.typeStringField ? this.typeStringField.getValue() : "",
                    customLabel: (this.typeStringField?.value === "Custom" && this.customLabelField) ? this.customLabelField.getValue() : null,
                    date: this.dateField ? this.dateField.getValue() : null,
                    location: this.locationField ? this.locationField.getValue() : null
                };
            },
            l
        }
    }
</script>
<style scoped>

</style>