import axios from "axios";
import { triggerError } from "../utils/alerts.js";


const url = "/bash/";


class BashScriptService {

    static callBashScript(scriptName, root) {
        axios.get(`${url}${scriptName}`).then(response => {
            root.$emit("success", response.data);
        }).catch(error => {
            triggerError(error);
        });
    }

}

export default BashScriptService;