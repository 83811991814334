<template>
    <WindowedForm v-model="form" />
</template>
<script>

    import WindowedForm from "../../components/globalUiElements/WindowedForm.vue";
    import { createForm } from "../../utils/FormUtils.js";
    import { metrics } from "../utils/usageUtils.js";
    import { l } from "../../localization/localization.js";


    export default {
        components: {
            WindowedForm,
        },
        props: {
            limits: { type: Object, default: null },
        },
        data() {
            const fieldDefs = [];

            metrics.forEach(metric => {

                fieldDefs.push({
                    key: metric.name + "Toggle",
                    type: "Select",
                    options: [
                        { text: l.cms.usage.unlimited(), value: false },
                        { text: l.cms.usage.limited(), value: true },
                    ],
                    label: metric.label(),
                    value: (this.limits && !isNaN(this.limits[metric.name])) ? this.limits[metric.name] !== -1 : false,
                });

                fieldDefs.push({
                    key: metric.name + "Value",
                    type: "Number",
                    label: l.cms.usage.limitOfLimitTypeLabel(metric.label()) + (metric.unit() ? " (" + metric.unit() + ")" : ""),
                    localized: false,
                    required: true,
                    value: (this.limits && this.limits[metric.name] && this.limits[metric.name] >= 0) ? this.limits[metric.name] : 0,
                    passOtherParameters: [metric.name + "Toggle"],
                    visibility: field => field.otherParameters[metric.name + "Toggle"].value,
                });

                fieldDefs.push({
                    uiElement: "Spacer"
                });

            });

            return {
                form: createForm({

                    title: {
                        label: () => l.cms.usage.editLimitsFormTitle(),
                    },

                    fieldDefs,

                    submitLabel: () => l.cms.usage.editLimitsFormSubmitButtonLabel(),

                    action: (fieldValues, callback) => {
                        const limits = {};
                        metrics.forEach(metric => {
                            const toggle = fieldValues[metric.name + "Toggle"];
                            const value = parseFloat(fieldValues[metric.name + "Value"]);
                            limits[metric.name] = (toggle && !isNaN(value) && value >= 0) ? value : -1
                        });
                        callback(limits);
                    },

                    submitted: limits => {
                        this.$emit("save", limits);
                    },

                    cancelled: () => {
                        this.$emit("cancel");
                    }
                })
            }
        },
    }
</script>