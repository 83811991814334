var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.editor)?_c('div',{staticClass:"toolbar"},_vm._l((_vm.toolbar),function(tool,index){return _c('div',{key:index},[(tool.action)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ activeFormatButton: tool.isActive() },attrs:{"icon":"","tile":"","disabled":!tool.enabled()},on:{"click":function($event){return tool.action()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(tool.icon)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.l(tool.tooltip)))])]):_vm._e(),(tool.spacer)?_c('div',{staticClass:"toolbarSpacer"}):_vm._e()],1)}),0):_vm._e(),_c('v-expand-transition',[(_vm.showLinkEditor)?_c('div',{staticClass:"linkEditor px-3 py-2"},[_c('v-tabs',{staticClass:"mx-3",attrs:{"height":"32"},on:{"change":_vm.onLinkTypeChange},model:{value:(_vm.linkType),callback:function ($$v) {_vm.linkType=$$v},expression:"linkType"}},[_c('v-tab',{key:"app"},[_vm._v(" Link within app ")]),_c('v-tab',{key:"web"},[_vm._v(" External web link ")])],1),_c('v-tabs-items',{model:{value:(_vm.linkType),callback:function ($$v) {_vm.linkType=$$v},expression:"linkType"}},[_c('v-tab-item',{key:"app",staticClass:"linkEditorTabContent",attrs:{"transition":"false"}},[_c('div',{staticClass:"linkFieldBox"},[_c('ReferenceField',{on:{"input":_vm.insertAppLink},model:{value:(_vm.appLinkField),callback:function ($$v) {_vm.appLinkField=$$v},expression:"appLinkField"}})],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary","icon":"","disabled":!_vm.appLinkField.value},on:{"click":_vm.closeLinkEditor}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-check ")])],1)]}}],null,false,3695188873)},[_c('span',[_vm._v(_vm._s(_vm.saveLinkLabel))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary","icon":"","disabled":!_vm.canUnsetLink},on:{"click":_vm.unsetLink}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-link-off ")])],1)]}}],null,false,1176218576)},[_c('span',[_vm._v(_vm._s(_vm.removeLinkLabel))])])],1),_c('v-tab-item',{key:"web",staticClass:"linkEditorTabContent",attrs:{"transition":"false"}},[_c('v-text-field',{ref:"webLinkField",staticClass:"linkFieldInput",attrs:{"hide-details":"auto","outlined":"","required":""},on:{"change":_vm.validateWebLink},model:{value:(_vm.webLinkValue),callback:function ($$v) {_vm.webLinkValue=$$v},expression:"webLinkValue"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary","icon":"","disabled":_vm.webLinkValue === 'http://'},on:{"click":_vm.saveWebLink}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-check ")])],1)]}}],null,false,1969814700)},[_c('span',[_vm._v(_vm._s(_vm.saveLinkLabel))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary","icon":"","disabled":!_vm.canUnsetLink},on:{"click":_vm.unsetLink}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-link-off ")])],1)]}}],null,false,1176218576)},[_c('span',[_vm._v(_vm._s(_vm.removeLinkLabel))])])],1)],1)],1):_vm._e()]),(_vm.editor)?_c('div',{class:{ textareaContainer: true, withScrollbar: _vm.hasScrollbar }},[_c('div',{ref:"textarea",staticClass:"textarea subtitle-1",on:{"mouseup":_vm.openLinkEditorIfLinkSelected}},[_c('EditorContent',{attrs:{"editor":_vm.editor}})],1),_c('v-icon',{staticClass:"resize"},[_vm._v(" mdi-resize-bottom-right ")]),_c('div',{staticClass:"resizeButton",on:{"mousedown":_vm.startResize}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }