
import { l, tryGetTokenOverride, selectLanguage } from "./localization.js";


// Acquirer pages in encyclopedia
export const acquirers = {


    
    entry(number, locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "entry");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        switch(number) {
            case 1: return l.app.pages.acquirers.singular(locale);
            default: return l.app.pages.acquirers.plural(locale);
        }
            
    },
            

    
    singular(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "singular");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Acquirer";
            case "de": return "Erwerber";
            default: return `[singular: ${language} missing]`;
        }
    
    },
            

    
    plural(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "plural");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Acquirers";
            case "de": return "Erwerber";
            default: return `[plural: ${language} missing]`;
        }
    
    },
            
};
