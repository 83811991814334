export default {


    namespaced: true,


    state: {
        show: false,
        image: null,
    },


    mutations: {

        setVisibility(state, show) {
            state.show = show;
        },

        setImage(state, image) {
            state.image = image;
        },

    },


    actions: {

        openImage({ commit }, image) {
            commit("setImage", image);
            commit("setVisibility", true);
        },

        close({ commit }) {
            commit("setVisibility", false);
            commit("setImage", null);
        },

    },


    getters: {

        getVisibility(state) {
            return state.show;
        },

        getImage(state) {
            return state.image;
        },

    },

}