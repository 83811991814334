
import { l, tryGetTokenOverride, selectLanguage } from "./localization.js";


// Media in CMS
export const media = {


    
    acceptedImageFormats(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "acceptedImageFormats");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Accepted image formats: JPEG (.jpeg/.jpg) or PNG (.png)";
            case "de": return "Dateiformate für Bilder: JPEG (.jpeg/.jpg) or PNG (.png)";
            default: return `[acceptedImageFormats: ${language} missing]`;
        }
    
    },
            

    
    maxImageResolution(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "maxImageResolution");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Maximum image resolution: 5000px × 5000px";
            case "de": return "Maximale Bildauflösung: 5000px × 5000px";
            default: return `[maxImageResolution: ${language} missing]`;
        }
    
    },
            

    
    acceptedAudioFormats(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "acceptedAudioFormats");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Accepted audio format: MP3 (.mp3)";
            case "de": return "Dateiformat für Audio: MP3 (.mp3)";
            default: return `[acceptedAudioFormats: ${language} missing]`;
        }
    
    },
            

    
    acceptedVideoFormats(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "acceptedVideoFormats");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Accepted video format: MP4 (.mp4)";
            case "de": return "Dateiformat für Video: MP4 (.mp4)";
            default: return `[acceptedVideoFormats: ${language} missing]`;
        }
    
    },
            

    
    maxFileSize(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "maxFileSize");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Maximum file size: 100 MB";
            case "de": return "Maximale Dateigrösse: 100 MB";
            default: return `[maxFileSize: ${language} missing]`;
        }
    
    },
            
};
