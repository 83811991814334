import axios from "axios";
import { triggerError } from "../utils/alerts.js";


const url = "/api/appSettings/";


class AppSettingService {

    // Get
    static getSetting(id, callback) {
        axios.get(`${url}${id}`).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }

    // Get all
    static getSettings(callback) {
        axios.get(url).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }

    // Post
    static insertSetting(setting, callback) {
        axios.post(url, setting).then(response => {
            callback(response.data);
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.isJoi) {
                callback({ errors: error.response.data.details });
            } else {
                triggerError(error);
            }
        });
    }

    // Put
    static updateSetting(setting, callback) {
        axios.put(`${url}${setting._id}`, setting).then(response => {
            response.data._id = setting._id;
            callback(response.data);
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.isJoi) {
                callback({ errors: error.response.data.details });
            } else {
                triggerError(error);
            }
        });
    }

    // Delete
    static deleteSetting(settingId, callback) {
        axios.delete(`${url}${settingId}`).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }

}

export default AppSettingService;