<template>
    <WindowedForm v-model="form" />
</template>
<script>
    
    import FloorService from "../../services/FloorService.js";
    import WindowedForm from "../../components/globalUiElements/WindowedForm.vue";
    import { createForm } from "../../utils/FormUtils.js";
    import { l } from "../../utils/LocalizationUtils.js";


    export default {
        components: {
            WindowedForm
        },
        props: {
            exhibition: { type: Object, default: null }
        },
        data() {
            return {
                form: createForm({

                    title: "Add New Floor",

                    action: FloorService.insertFloor,

                    fieldDefs: [
                        {
                            key: "exhibitionId",
                            type: "Hidden",
                            value: this.exhibition?._id,
                        },
                        {
                            key: "name",
                            type: "Text",
                            label: "Name",
                            localized: true,
                            required: true,
                        },
                        {
                            key: "level",
                            type: "Number",
                            label: "Level",
                            required: true,
                        },
                        {
                            key: "floorPlanSvgId",
                            type: "Reference",
                            referenceOf: "media",
                            label: "SVG file of floorplan",
                        },
                        {
                            key: "floorPlanWidthInMeters",
                            type: "Hidden",
                            value: 1,
                        },
                    ],

                    submitLabel: "Add",

                    submitted: entry => {
                        this.$root.$emit("success", l("Floor added.", entry));
                        this.$emit("addedEntry");
                    },

                    cancelled: () => {
                        this.$emit("cancel");
                    }
                })
            }
        }
    }
</script>