import axios from "axios";
import { triggerError } from "../utils/alerts.js";


function processCall(call, url, entry, defaultResponse, onError) {
    return new Promise(async resolve => {
        try {
            if (typeof entry !== "undefined") {
                const response = await call(url, entry);
                resolve(response.data);
            } else {
                const response = await call(url);
                resolve(response.data);
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.isJoi) {
                resolve({ errors: error.response.data.details });
            } else if (onError) {
                onError(error); 
            } else {
                triggerError(error);
            }
            resolve(typeof defaultResponse !== "undefined" ? defaultResponse : null);
        }
    });
}


export function get({ url, defaultResponse, onError }) {
    return processCall(axios.get, url, undefined, defaultResponse, onError);
};


export function insert({ url, entry, defaultResponse, onError }) {
    return processCall(axios.post, url, entry, defaultResponse, onError);
};


export function update({ url, entry, defaultResponse, onError }) {
    return processCall(axios.put, url, entry, defaultResponse, onError);
};


export function remove({ url, defaultResponse, onError }) {
    return processCall(axios.delete, url, undefined, defaultResponse, onError);
};