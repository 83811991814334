<template>
    <div>
        <h2 class="title primary--text mt-10">
            {{ listTitle }}
        </h2>
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
            class="usageLimitsLoading"
        />
        <v-simple-table v-if="Array.isArray(list)" class="usageLimitsTable mb-3">
            <template #default>
                <thead>
                    <tr>
                        <th class="text-left">
                            {{ metricColumnLabel }}
                        </th>
                        <th class="text-right">
                            {{ usedColumnLabel }}
                        </th>
                        <th class="text-right">
                            {{ limitColumnLabel }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(limit, index) in list" :key="index">
                        <td>
                            {{ limit.label }}
                        </td>
                        <td class="text-right">
                            {{ limit.used }}
                        </td>
                        <td :class="{ 'text-right': true, 'font-weight-bold': !limit.unlimited, 'font-italic': limit.unlimited }">
                            {{ limit.value }}
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <ButtonWithIcon
            icon="mdi-tune"
            :label="editLimitsButtonLabel"
            @click="openEditForm"
        />
        <EditLimitsForm v-if="showEditForm" :limits="limits" @cancel="closeEditForm" @save="save" />
    </div>
</template>
<script>

    import ButtonWithIcon from "../../components/globalUiElements/ButtonWithIcon.vue";
    import EditLimitsForm from "./EditLimitsForm.vue";
    import { metrics } from "../utils/usageUtils.js";
    import { l } from "../../localization/localization.js";


    export default {
        components: {
            ButtonWithIcon,
            EditLimitsForm,
        },
        props: {
            limits: { type: Object, default: null },
            loading: { type: Boolean, default: false },
        },
        data() {
            return {
                showEditForm: false,
            };
        },
        computed: {
            listTitle: () => l.cms.usage.systemlimitsListTitle(),
            metricColumnLabel: () => l.cms.usage.metric(),
            usedColumnLabel: () => l.cms.usage.used(),
            limitColumnLabel: () => l.cms.usage.limitLabel(),
            editLimitsButtonLabel: () => l.cms.usage.editLimitsButtonLabel(),
            list() {
                const list = [];
                metrics.forEach(metric => {
                    const value = (this.limits && !isNaN(this.limits[metric.name])) ? this.limits[metric.name] : -1;
                    const used = metric.used();
                    const unit = metric.unit();
                    list.push({
                        label: metric.label(),
                        used: (!isNaN(used) ? +used.toFixed(3) : used) + (unit ? " " + unit : ""),
                        value: value >= 0 ? (value + (unit ? " " + unit : "")) : l.cms.usage.unlimited(),
                        unlimited: value == -1,
                    });
                });
                return list;
            },
        },
        methods: {
            openEditForm() {
                this.showEditForm = true;
            },
            closeEditForm() {
                this.showEditForm = false;
            },
            save(limits) {
                this.closeEditForm();
                this.$emit("saveLimits", limits);
            },
        },
    }
</script>
<style scoped>

    .usageLimitsLoading, 
    .usageLimitsTable {
        max-width: 600px;
    }

</style>
