<template>
    <WindowedForm v-model="form" />
</template>
<script>

    import * as tagService from "../services/tagService.js";
    import WindowedForm from "../../components/globalUiElements/WindowedForm.vue";
    import { createForm } from "../../utils/FormUtils.js";
    import { getLabel } from "../utils/tagUtils.js";
    import { l } from "../../localization/localization.js";


    export default {
        components: {
            WindowedForm
        },
        props: {
            entry : { type: Object, default: null }
        },
        data() {
            return {
                form: createForm({

                    title: {
                        label: entry => l.cms.tags.editFormTitle(getLabel(entry)),
                        entry: this.entry
                    },

                    action: tagService.update,

                    fieldDefs: [
                        {
                            key: "_id",
                            type: "Hidden",
                            value: this.entry._id,
                        },
                        {
                            key: "name",
                            type: "Text",
                            label: () => l.cms.tags.name(),
                            localized: true,
                            required: true,
                            value: this.entry.name,
                        },
                    ],

                    submitLabel: () => l.cms.tags.editFormSubmitButtonLabel(),

                    submitted: entry => {
                        this.$root.$emit("success", l.cms.tags.editedMessage(getLabel(entry)));
                        this.$emit("editedEntry");
                    },

                    cancelled: () => {
                        this.$emit("cancel");
                    }
                })
            }
        }
    }
</script>