<template>
    <div class="headerBox">
        <div v-if="image" class="headerImage">
            <img class="headerImage" :src="'/assets/' + image + '.jpg'">
        </div>
        <div class="headerInfoBox">
            <div v-if="name" class="headerTitle">
                {{ name }}
            </div>
            <div class="headerDataTable">
                <div v-for="({ label, value }, index) in dataTable" :key="index" class="dataTableEntry">
                    <div v-if="value" class="dataTableLabel">
                        {{ label }}
                    </div>
                    <!-- eslint-disable -->
                    <div v-if="value" v-html="value" class="dataTableValue" />
                    <!-- eslint-enable -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    export default {
        props: {
            image: { type: String, default: "" },
            name: { type: String, default: "" },
            dataTable: { type: Array, default: () => [] },
        },
    }

</script>
<style scoped>

    .headerBox {
        position: relative;
        min-height: 100px;
        background: #212121;
    }

    .headerImage {
        height: 400px;
        width: 100%;
        padding: 0;
    }

    .headerImage img {
        display: block;
        height: 400px;
        width: 100%;
        object-fit: contain;
    }

    .headerInfoBox {
        padding: 20px 20px 12px;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        background: #333;
    }

    .headerTitle {
        font-size: 30px;
        font-weight: 500;
        color: #fff;
    }

    .headerDataTable {
        padding-top: 12px;
    }

    .dataTableEntry {
        padding-left: 18px;
    }

    .dataTableLabel {
        padding-bottom: 4px;
        font-size: 15px;
        font-weight: 700;
        color: #fff;
    }

    .dataTableValue {
        margin: 2px 0 8px 4px;
        padding: 2px 0 0 13px;
        font-size: 18px;
        color: #fff;
        border-left: 1px solid #616161;
    }

</style>