<template>
    <div class="defaultContentView">
        <ViewTitle
            :title="l('Building')"
            icon="mdi-domain"
        />
        <ExhibitionSelection
            @change="setExhibition"
        />
        <div class="text-h5 mt-5">
            {{ l("Floors") }}
        </div>
        <div>
            <FloorList
                :exhibition="exhibition"
                :reload-trigger="listReloadTrigger"
                @add="openAddForm"
                @edit="openEditForm"
            />
            <AddFloor
                v-if="showAddForm"
                :exhibition="exhibition"
                @cancel="closeAddForm"
                @addedEntry="addedEntry"
            />
            <EditFloor
                v-if="showEditForm"
                :entry="editingEntry"
                @cancel="closeEditForm"
                @editedEntry="editedEntry"
            />
        </div>
    </div>
</template>
<script>

    import { mapActions } from "vuex";
    import ViewTitle from "../../components/globalUiElements/ViewTitle.vue";
    import ExhibitionSelection from "../../components/globalUiElements/ExhibitionSelection.vue";
    import FloorList from "../components/FloorList.vue";
    import AddFloor from "../components/AddFloor.vue";
    import EditFloor from "../components/EditFloor.vue";
    import { l } from "../../utils/LocalizationUtils.js";


    export default {
        components: {
            ViewTitle,
            ExhibitionSelection,
            FloorList,
            AddFloor,
            EditFloor,
        },
        data() {
            return {
                exhibition: null,
                listReloadTrigger: 0,
                showAddForm: false,
                showEditForm: false,
                editingEntry: null,
                supportedFileFormats: {},
            };
        },
        mounted() {
            this.updateSectionTitle(this.l("Building"));
        },
        methods: {
            
            setExhibition(exhibition) {
                this.exhibition = exhibition;
            },

            openAddForm() {
                this.showAddForm = true;
            },

            closeAddForm() {
                this.showAddForm = false;
            },

            addedEntry() {
                this.closeAddForm();
                this.listReloadTrigger++;
            },

            openEditForm(entry) {
                this.editingEntry = entry;
                this.showEditForm = true;
            },

            closeEditForm() {
                this.showEditForm = false;
                this.editingEntry = null;
            },

            editedEntry() {
                this.closeEditForm();
                this.listReloadTrigger++;
            },

            l,

            ...mapActions({
                updateSectionTitle: "platform/updateSectionTitle"
            }),
        }
    }
</script>
