<template>
    <div class="statusDisplay ml-2 mb-1">
        <span v-if="status === 0" class="status badStatus">
            <v-icon dark x-small class="statusIcon">mdi-exclamation-thick</v-icon>
        </span>
        <span v-if="status === 1" class="status goodStatus">
            <v-icon dark x-small class="statusIcon">mdi-check-bold</v-icon>
        </span>
        <span v-if="status === 2" class="status waitStatus">
            <v-icon dark x-small class="statusIcon">mdi-dots-horizontal</v-icon>
        </span>
        <span class="statusLabel caption grey--text text--darken-2">{{ label }}</span>
    </div>
</template>
<script>

    export default {
        props: {
            status: { type: Number, default: 0 },
            label: { type: String, default: "" },
        }
    };
</script>
<style scoped>

    .statusDisplay {
        position: relative;
        height: 16px;
        width: 160px;
    }

    .status {
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0;
        width: 14px;
        height: 14px;
        border-radius: 7px;
    }

    .badStatus {
        background: #d00;
    }

    .goodStatus {
        background: #090;
    }

    .waitStatus {
        background: #f90;
    }

    .statusIcon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .statusLabel {
        display: block;
        padding: 0 0 0 18px;
        margin: 0;
        height: 14px;
        line-height: 14px !important;
        white-space: nowrap;
    }

</style>