<template>
    <div class="previewContainer">
        <div ref="viewport" class="viewport">
            <div
                :class="{ 'worksheet': true, 'placing': locationBeingEdited && !locationBeingEdited.locked }"
                :style="worksheetStyle"
                @click="clickOnFloor"
                @mousemove="mouseMoveOnFloor"
                @mouseover="mouseOverFloor"
                @mouseleave="mouseOutOfFloor"
            >
                <div
                    v-if="horizontalGuideVisible"
                    :class="{ 'horizontalGuide': true, 'snapping': snappedToY, 'locked': lockedToHorizontal }"
                    :style="horizontalGuideStyle"
                />
                <div
                    v-if="verticalGuideVisible"
                    :class="{ 'verticalGuide': true, 'snapping': snappedToX, 'locked': lockedToVertical }"
                    :style="verticalGuideStyle"
                />
                <div ref="floor" class="floor" :style="floorStyle">
                    <img ref="svg" :src="svgFilepath" @load="extractSVGSize">
                    <div 
                        v-for="location in locations"
                        :key="location._id"
                        :class="{
                            'location': true,
                            'hovered': isLocationBeingHoveredOver(location),
                            'placing': isLocationBeingPlaced(location),
                            'locked': isLocationLocked(location),
                            'snapping': isLocationBeingSnappedTo(location),
                            'lockedTo': isLocationBeingLockedTo(location),
                        }"
                        :style="{
                            'left': (location.positionX * floorSize.width) + 'px',
                            'top': (location.positionY * floorSize.height) + 'px'
                        }"
                    >
                        <div
                            class="clickable"
                            @click="clickOnLocation(location, $event)"
                            @mousemove="mouseMoveOnFloor"
                            @mouseover="mouseOverLocation(location)"
                            @mouseout="mouseOutOfLocation(location)"
                        />
                        <div class="highlightMarker" />
                        <div class="marker" />
                        <v-progress-circular v-if="saving" class="savingIndicator" indeterminate color="primary" size="24" />
                    </div>
                    <div
                        v-if="tooltipVisible"
                        class="locationTooltip elevation-3"
                        :style="tooltipStyle"
                    >
                        <ReferencePreview
                            type="page"
                            :preview-data="locationBeingHoveredOver.previewData"
                            :append-icon="locationBeingHoveredOver.locked ? 'mdi-lock' : null"
                            max-width="346"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div v-if="loading || loadingSVG" class="loadingSVG">
            <v-progress-circular indeterminate color="primary" />
        </div>
    </div>
</template>
<script>

    import ReferencePreview from "../../components/globalUiElements/ReferencePreview.vue";
    import { pageExists } from "../../pages/utils/pageUtils.js";


    export default {
        components: {
            ReferencePreview,
        },
        props: {
            floor: { type: Object, default: null },
            viewportZoom: { type: Number, default: 1 },
            locations: { type: Array, default: () => [] },
            locationBeingHoveredOver: { type: Object, default: null },
            locationBeingEdited: { type: Object, default: null },
            snapping: { type: Boolean, default: false },
            showGuides: { type: Boolean, default: false },
            lockAxis: { type: Boolean, default: false },
            loading: { type: Boolean, default: false },
            saving: { type: Boolean, default: false },
        },
        data() {
            return {

                baseFillFactor: 0.8, // at 100% zoom, floor fills 80% of the viewport
                viewportPadding: 40, // there will always b3 40px padding between floor and viewport

                svgFilepath: "",
                loadingSVG: false,
                floorAspectRatio: 1,

                viewportSize: { width: 1, height: 1 },
                worksheetPosition: { x: 0.5, y: 0.5 },
                scrollEventsBlocked: false,

                mouseOnFloor: false,

                mousePositionOnScreen: null,
                mousePositionOnFloor: null,

                guidedMousePositionOnScreen: null,
                guidedMousePositionOnFloor: null,

                snappingLinesX: [],
                snappingLinesY: [],
                snappedToX: null,
                snappedToY: null,
                locationsBeingSnappedToX: [],
                locationsBeingSnappedToY: [],

                lockAxisToPoint: null,
                locationsBeingLockedToX: [],
                locationsBeingLockedToY: [],
            }
        },
        computed: {

            worksheetStyle() {
                return {
                    width: Math.max(this.viewportSize.width, this.floorSize.width + (2 * this.viewportPadding)) + "px",
                    height: Math.max(this.viewportSize.height, this.floorSize.height + (2 * this.viewportPadding)) + "px",
                };
            },

            baseFloorSize() {
                const viewportAspectRatio = this.viewportSize.width / this.viewportSize.height;
                if(this.floorAspectRatio > viewportAspectRatio) {
                    const baseFloorWidth = this.viewportSize.width * this.baseFillFactor;
                    return {
                        width: baseFloorWidth,
                        height: baseFloorWidth / this.floorAspectRatio,
                    };
                } else {
                    const baseFloorHeight = this.viewportSize.height * this.baseFillFactor;
                    return {
                        width: baseFloorHeight * this.floorAspectRatio,
                        height: baseFloorHeight,
                    };
                }
            },

            floorPosition() {
                return this.calculateFloorPosition(this.floorSize);
            },

            floorSize() {
                if(this.loadingSVG) {
                    return { width: 1000, height: 1000 }; // necessary so that img has space to expand, so that we can read its size
                } else {
                    return this.calculateFloorSize();
                }
            },

            floorStyle() {
                return {
                    left: this.floorPosition.x + "px",
                    top: this.floorPosition.y + "px",
                    width: this.floorSize.width + "px",
                    height: this.floorSize.height + "px",
                };
            },

            tooltipVisible() {
                return this.mouseOnFloor
                    && this.locationBeingHoveredOver
                    && this.mousePositionOnScreen
                    && pageExists(this.locationBeingHoveredOver.artPieceId);
            },

            tooltipStyle() {
                if(this.tooltipVisible) {
                    if(this.mousePositionOnScreen.x < 0.6 * this.floorSize.width) {
                        return {
                            left: (this.mousePositionOnScreen.x + 16) + "px",
                            top: (this.mousePositionOnScreen.y - 25) + "px",
                        };
                    } else {
                        return {
                            right: ((this.floorSize.width - this.mousePositionOnScreen.x) + 16) + "px",
                            top: (this.mousePositionOnScreen.y - 25) + "px",
                        };
                    }
                } 
                return {};
            },

            guidesVisible() {
                return this.locationBeingEdited
                    && !this.locationBeingEdited.locked
                    && this.mouseOnFloor
                    && this.guidedMousePositionOnScreen
                    && !this.locationBeingHoveredOver;
            },

            horizontalGuideVisible() {
                return this.guidesVisible && (this.showGuides || this.snappedToY || this.lockedToHorizontal);
            },

            horizontalGuideStyle() {
                if(this.horizontalGuideVisible) {
                    return { top: (this.floorPosition.y + this.guidedMousePositionOnScreen.y - 0.5) + "px" };
                }
                return {};
            },

            verticalGuideVisible() {
                return this.guidesVisible && (this.showGuides || this.snappedToX || this.lockedToVertical);
            },

            verticalGuideStyle() {
                if(this.verticalGuideVisible) {
                    return { left: (this.floorPosition.x + this.guidedMousePositionOnScreen.x - 0.5) + "px" };
                }
                return {};
            },

            lockedToHorizontal() {
                return this.guidedMousePositionOnFloor && this.lockAxisToPoint && this.guidedMousePositionOnFloor.y === this.lockAxisToPoint.y;
            },

            lockedToVertical() {
                return this.guidedMousePositionOnFloor && this.lockAxisToPoint && this.guidedMousePositionOnFloor.x === this.lockAxisToPoint.x;
            },

        },
        watch: {
            floor: {
                immediate: true,
                handler() {
                    this.resetViewport();
                    this.setSVGFilePath();
                },
            },
            viewportZoom: {
                handler() {
                    this.blockScrollEventsFor1Frame();
                    this.restoreWorksheetPosition();
                },
            },
            locations: {
                immediate: true,
                deep: true,
                handler() {
                    this.updateSnappingGrid();
                },
            },
            lockAxis() {
                if(this.lockAxis && this.guidedMousePositionOnFloor) {
                    this.lockAxisToPoint = { ...this.guidedMousePositionOnFloor };
                    this.locationsBeingLockedToX = [ ...this.locationsBeingSnappedToX ];
                    this.locationsBeingLockedToY = [ ...this.locationsBeingSnappedToY ];
                } else {
                    this.lockAxisToPoint = null;
                    this.locationsBeingLockedTo = [];
                }
                this.updateGuidedPosition();
            },
            snapping() {
                this.updateGuidedPosition();
            },
        },
        mounted() {
            window.addEventListener("resize", this.onWindowResize);
            this.$refs.viewport.addEventListener("scroll", this.onViewportScroll);
            this.$refs.viewport.addEventListener("wheel", this.onViewportMouseWheel);
            this.onWindowResize();
        },
        beforeDestroy() {
            window.removeEventListener("resize", this.onWindowResize);
            this.$refs.viewport.removeEventListener("scroll", this.onViewportScroll);
            this.$refs.viewport.removeEventListener("wheel", this.onViewportMouseWheel);
        },
        methods: {

            setSVGFilePath() {
                this.svgFilepath = "";
                this.loadingSVG = true;
                setTimeout(() => { // timeout so element with img has time to expand
                    this.svgFilepath = "/assets/" + this.floor.floorPlanSvgId + ".svg";
                }, 50);
            },

            extractSVGSize() {
                if(this.$refs && this.$refs.svg) {
                    const svgWidth = Math.max(this.$refs.svg.offsetWidth, 1);
                    const svgHeight = Math.max(this.$refs.svg.offsetHeight, 1);
                    this.floorAspectRatio = svgWidth / svgHeight;
                    this.loadingSVG = false;

                } else { // Image is loaded, but vue hasn't set up references yet
                    setTimeout(() => {
                        this.extractSVGSize();
                    }, 100);
                }
            },

            onWindowResize() {
                this.viewportSize = {
                    width: Math.max(this.$refs.viewport.getBoundingClientRect().width, 1),
                    height: Math.max(this.$refs.viewport.getBoundingClientRect().height, 1),
                };
                this.restoreWorksheetPosition();
            },

            resetViewport() {
                this.worksheetPosition = { x: 0.5, y: 0.5 };
                this.scrollEventsBlocked = false;
            },

            viewportToFloorspace(coords) {
                const floorSize = this.calculateFloorSize(); // cannot rely on this.floorsize, as it may not have updated itself yet
                const floorPosition = this.calculateFloorPosition(floorSize);
                return {
                    x: (coords.x + this.$refs.viewport.scrollLeft - floorPosition.x) / floorSize.width, 
                    y: (coords.y + this.$refs.viewport.scrollTop - floorPosition.y) / floorSize.height,
                }
            },

            getViewportCenterInFloorspace() {
                return this.viewportToFloorspace({
                    x: this.viewportSize.width * 0.5,
                    y: this.viewportSize.height * 0.5,
                });
            },

            onViewportScroll() {
                if(!this.scrollEventsBlocked) {
                    this.worksheetPosition = this.getViewportCenterInFloorspace();
                }
            },

            blockScrollEventsFor1Frame() {
                this.scrollEventsBlocked = true; // prevent zooming from triggering worksheet position updates
                setTimeout(() => {
                    this.scrollEventsBlocked = false;
                }, 40);
            },

            restoreWorksheetPosition() {
                const restore = () => {
                    const wrongWorksheetPosition = this.getViewportCenterInFloorspace();
                    const correctionX = (this.worksheetPosition.x - wrongWorksheetPosition.x) * this.floorSize.width;
                    const correctionY = (this.worksheetPosition.y - wrongWorksheetPosition.y) * this.floorSize.height

                    this.$refs.viewport.scrollLeft += correctionX;
                    this.$refs.viewport.scrollTop += correctionY;
                }
                restore();
                setTimeout(() => {
                    restore();
                    this.worksheetPosition = this.getViewportCenterInFloorspace();
                }, 1);
            },

            calculateFloorPosition(floorSize) {
                return {
                    x: Math.max((this.viewportSize.width * 0.5) - (floorSize.width * 0.5), this.viewportPadding),
                    y: Math.max((this.viewportSize.height * 0.5)  - (floorSize.height * 0.5), this.viewportPadding),
                };
            },

            calculateFloorSize() {
                return {
                    width: this.baseFloorSize.width * this.viewportZoom,
                    height: this.baseFloorSize.height * this.viewportZoom,
                };
            },

            onViewportMouseWheel(event) {
                if(event.ctrlKey) {
                    event.preventDefault();
                    this.$emit("changeZoom", this.viewportZoom * (event.deltaY < 0 ? 1.25 : 0.8));

                }
            },

            updateSnappingGrid() {
                this.snappingLinesX = [];
                this.snappingLinesY = [];

                this.locations.forEach(location => {
                    if(!isNaN(location.positionX)) {
                        this.addSnappingLine(this.snappingLinesX, location.positionX, location);
                    }
                    if(!isNaN(location.positionY)) {
                        this.addSnappingLine(this.snappingLinesY, location.positionY, location);
                    }
                });
            },

            addSnappingLine(snappingLines, position, anchor) {
                const existingSnappingLine = snappingLines.find(line => line.position === position);
                if(existingSnappingLine) {
                    existingSnappingLine.anchors.push(anchor);
                } else {
                    snappingLines.push({
                        position,
                        anchors: [ anchor ],
                    });
                }
            },

            mouseOverFloor() {
                this.mouseOnFloor = true;
            },

            mouseOutOfFloor() {
                this.mouseOnFloor = false;
            },

            mouseMoveOnFloor(event) {
                this.mousePositionOnScreen = this.getMousePosition(event);
                this.mousePositionOnFloor = this.screenToFloorspace(this.mousePositionOnScreen);
                this.updateGuidedPosition();
            },

            getMousePosition(event) {
                return {
                    x: (event.clientX - this.$refs.floor.getBoundingClientRect().left) + 0.5, // +0.5 so be at center of pixel
                    y: (event.clientY - this.$refs.floor.getBoundingClientRect().top) + 0.5,
                };
            },

            updateGuidedPosition() {
                if(this.mousePositionOnFloor) {

                    const guidedPosition = { ...this.mousePositionOnFloor };

                    // snapping
                    this.snappedToX = null;
                    this.snappedToY = null;
                    this.locationsBeingSnappedToX = [];
                    this.locationsBeingSnappedToY = [];

                    if(this.snapping) {

                        const snappingDistanceInPixel = 10;
                        let snapDistanceX = snappingDistanceInPixel / this.floorSize.width;
                        let snapDistanceY = snappingDistanceInPixel / this.floorSize.height;

                        this.snappedToX = this.getSnappedPosition(this.snappingLinesX, guidedPosition.x, snapDistanceX);
                        if(this.snappedToX) {
                            guidedPosition.x = this.snappedToX.position;
                            this.locationsBeingSnappedToX = this.snappedToX.anchors;
                        }

                        this.snappedToY = this.getSnappedPosition(this.snappingLinesY, guidedPosition.y, snapDistanceY);
                        if(this.snappedToY) {
                            guidedPosition.y = this.snappedToY.position;
                            this.locationsBeingSnappedToY = this.snappedToY.anchors;
                        }
                    }
                    
                    // axis lock
                    if(this.lockAxis && this.lockAxisToPoint) {
                        const distanceX = Math.abs(this.lockAxisToPoint.x - guidedPosition.x);
                        const distanceY = Math.abs(this.lockAxisToPoint.y - guidedPosition.y);
                        if(distanceX < distanceY) {
                            guidedPosition.x = this.lockAxisToPoint.x;

                        } else {
                            guidedPosition.y = this.lockAxisToPoint.y;
                        }
                    }

                    this.guidedMousePositionOnFloor = guidedPosition;
                    this.guidedMousePositionOnScreen = this.floorToScreenspace(this.guidedMousePositionOnFloor);
                }
            },

            getSnappedPosition(snappingLines, position, snapDistance) {
                let snapped = null;
                snappingLines.forEach(line => {
                    const distance = Math.abs(line.position - position);
                    if(distance < snapDistance) {
                        snapDistance = distance;
                        snapped = line;
                    }
                });
                return snapped;
            },

            screenToFloorspace(coords) {
                return {
                    x: coords.x / this.floorSize.width,
                    y: coords.y / this.floorSize.height,
                }
            },
            
            floorToScreenspace(coords) {
                return {
                    x: coords.x * this.floorSize.width,
                    y: coords.y * this.floorSize.height,
                }
            },

            mouseOverLocation(location) {
                this.$emit("hover", location);
            },

            mouseOutOfLocation(location) {
                if(this.locationBeingHoveredOver === location) {
                    this.$emit("hover", null);
                }
            },

            clickOnLocation(location, event) {
                setTimeout(() => {
                    this.$emit("startPlacing", location);
                }, 40);
                event.stopPropagation();
            },

            clickOnFloor(event) {
                if(this.locationBeingEdited && !this.locationBeingEdited.locked && !this.saving) {
                    this.updateGuidedPosition();

                    // this is so there are no inaccuracies caused by manually input values (which are rounded to 4 digits)
                    const roundedX = Math.round(10000 * this.guidedMousePositionOnFloor.x) / 10000; 
                    const roundedY = Math.round(10000 * this.guidedMousePositionOnFloor.y) / 10000;

                    this.$emit("place", {
                        positionX: Math.min(1, Math.max(roundedX, 0)),
                        positionY: Math.min(1, Math.max(roundedY, 0)),
                    });

                    this.locationsBeingSnappedTo = [];
                    this.locationsBeingLockedTo = [];
                }
                event.preventDefault();
            },

            isLocationBeingHoveredOver(location) {
                return this.locationBeingHoveredOver === location;
            },

            isLocationBeingPlaced(location) {
                return this.locationBeingEdited === location;
            },

            isLocationLocked(location) {
                return location.locked;
            },

            isLocationBeingSnappedTo(location) {
                return this.locationBeingEdited && !this.locationBeingEdited.locked && this.snapping
                    && (this.locationsBeingSnappedToX.includes(location) || this.locationsBeingSnappedToY.includes(location));
            },

            isLocationBeingLockedTo(location) {
                return this.locationBeingEdited && !this.locationBeingEdited.locked
                    && (this.lockedToVertical && this.locationsBeingLockedToX.includes(location))
                    || (this.lockedToHorizontal && this.locationsBeingLockedToY.includes(location));
            },
        },
    }

</script>
<style scoped>

    .previewContainer {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        overflow: hidden;
    }

    .viewport {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        overflow: auto;
    }

    .worksheet {
        position: relative;
        z-index: 3;
    }

    .worksheet.placing {
        cursor: crosshair !important;
    }

    .floor {
        position: absolute;
    }

    .location {
        position: absolute;
        width: 1px;
        height: 1px;
    }

    .location .clickable {
        position: absolute;
        left: -6px;
        top: -6px;
        width: 12px;
        height: 12px;
        border-radius: 6px;
        cursor: pointer;
        z-index: 4;
    }

    .location.placing .clickable {
        display: none;
    }

    .location.placing.locked .clickable {
        display: block !important;
        left: -12px !important;
        top: -12px !important;
        width: 24px !important;
        height: 24px !important;
        border-radius: 8px !important;
        cursor: pointer !important;
    }

    .location .marker {
        position: absolute;
        left: -6px;
        top: -6px;
        width: 12px;
        height: 12px;
        background: #fff;
        border-radius: 6px;
        border: 2px solid #666;
    }

    .location.hovered .marker {
        border-color: var(--v-primary-base) !important;
        background: var(--v-primary-lighten4);
    }

    .location.snapping .marker {
        background: var(--v-primary-base) !important;
    }

    .location.lockedTo .marker {
        background: #f00 !important;
    }

    .location.placing .marker {
        left: -8px !important;
        top: -8px !important;
        width: 16px !important;
        height: 16px !important;
        border-radius: 8px !important;
        border: 4px solid var(--v-primary-base) !important;
    }

    .location .highlightMarker,
    .location .savingIndicator {
        display: none;
    }

    .location.placing .highlightMarker {
        display: block;
        position: absolute;
        left: -12px;
        top: -12px;
        width: 24px;
        height: 24px;
        border-radius: 12px;
        background: rgba(255, 255, 255, 0.5);
    }

    .location.placing .savingIndicator {
        display: block;
        position: absolute;
        left: -12px;
        top: -12px;
    }

    .locationTooltip {
        position: absolute;
        background: #fff;
        border-radius: 4px;
        z-index: 7;
        overflow: hidden;
    }

    .horizontalGuide,
    .verticalGuide {
        position: absolute;
        background: rgba(127, 127, 127, 0.5);
        z-index: 4;
    }

    .horizontalGuide.snapping,
    .verticalGuide.snapping {
        background: var(--v-primary-base);
        opacity: 0.8;
    }

    .horizontalGuide.locked,
    .verticalGuide.locked {
        background: rgba(255, 0, 0, 0.8);
    }

    .horizontalGuide {
        height: 1px;
        left: 0;
        right: 0;
    }
    
    .verticalGuide {
        width: 1px;
        top: 0;
        bottom: 0;
    }

    .loadingSVG {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: #fff;
        z-index: 5;
    }

</style>
