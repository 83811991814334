<template>
    <div>
        <h2 class="mt-4 mb-2">
            {{ l("AR Target Interaction Exploration") }}
        </h2>
        <v-container v-if="interactionModuleLookUp" fluid class="pa-0 ma-0">
            <v-row align="center" no-gutters>
                <v-col cols="4">
                    <ReferenceField
                        v-model="arTargetSelectionField"
                    />
                </v-col>
                <v-spacer />
            </v-row>
        </v-container>
        <div v-if="!interactionModuleLookUp || loading">
            <v-progress-linear indeterminate />
        </div>
        <AnalyticsDataTable
            v-if="interactionModuleLookUp && arTargetId && !loading"
            :headers="tableHeaders"
            :data="tableData"
            :fold-out-labels="foldOutLabels"
            @highlight="highlight"
            @unhighlight="unhighlight"
            @select="select"
        />
    </div>
</template>
<script>

    import ReferenceField from "../globalUiElements/inputFields/ReferenceField.vue";
    import AnalyticsDataTable from "./AnalyticsDataTable.vue";
    import InteractionModulesService from "../../services/InteractionModulesService.js";
    import InteractionService from "../../services/InteractionService.js";
    import AnalyticsService from "../../services/AnalyticsService.js";
    import { createVisitTable, getShare } from "../../utils/analyticsUtils.js";
    import { shortDuration, getPercentage } from "../../utils/StringUtils.js";
    import { l } from "../../utils/LocalizationUtils.js";


    export default {
        components: {
            ReferenceField,
            AnalyticsDataTable,
        },
        props: {
            exhibitionAndDateRange: { type: Object, default: null },
        },
        data() {
            return {
                loading: false,
                interactionModuleLookUp: null,
                interactionLookUp: {},

                arTargetSelectionField: {
                    key: "arTarget",
                    type: "Reference",
                    referenceOf: "arTarget",
                    value: null,
                    label: "Select AR target:",
                },
                arTargetId: null,

                tableHeaders: [
                    { sortable: false },
                    { text: l("Interaction"), value: "label" },
                    { text: l("Total Usage"), value: "usage", align: "end" },
                    { text: l("Visits"), value: "visits", align: "end" },
                    { text: l("Time per visit"), value: "timePerVisit", align: "end" },
                    { text: l("Opened first"), value: "first", align: "end" },
                    { text: l("Opened last"), value: "last", align: "end" },
                    { text: l("Most common previous interaction"), sortable: false, align: "end" },
                    { text: l("Most common next interaction"), sortable: false, align: "end" },
                ],
                tableData: [],

                foldOutLabels: {
                    usage: l("Total time spent in this interaction:"),
                    usageShare: l("Share among all interactions of target:"),
                    visits: l("Times interaction was opened:"),
                    timePerVisit: l("Average time spent in interaction:"),
                    first: l("Was the first interaction opened:"),
                    last: l("Was the last interaction opened:"),
                },
            };
        },
        watch: {
            exhibitionAndDateRange: {
                handler() {
                    if(this.exhibitionAndDateRange && this.exhibitionAndDateRange.exhibitionId) {
                        this.loadInteractionModuleLookUp();
                    }
                },
                deep: true,
                immediate: true,
            },
            arTargetSelectionField: {
                handler() {
                    this.arTargetId = this.arTargetSelectionField.value;
                    if(this.interactionModuleLookUp && this.arTargetId) {
                        this.loadInteractionUsageData();
                    }
                },
                deep: true,
            },
        },
        methods: {

            async loadInteractionModuleLookUp() {
                const interactionModules = await InteractionModulesService.getInteractionModules();
                if(!Array.isArray(interactionModules)) {
                    return;
                }
                this.interactionModuleLookUp = {};

                interactionModules.forEach(interactionModule => {
                    interactionModule.versions.forEach(version => {
                        this.interactionModuleLookUp[interactionModule.id + ":" + version.versionNumber] = version.title;
                    });
                });
            },

            async loadInteractionUsageData() {

                this.loading = true;
                this.tableData = [];

                const interactions = await InteractionService.getInteractionsByARTarget(this.arTargetId);
                if(!Array.isArray(interactions)) {
                    return;
                }
                this.interactionLookUp = {};
                interactions.forEach(interaction => {
                    const versionLookupKey = interaction.interactionModuleId + ":" + interaction.interactionModuleVersion;
                    this.interactionLookUp[interaction._id] = {
                        name: interaction.name,
                        interactionModule: this.interactionModuleLookUp[versionLookupKey] ? this.interactionModuleLookUp[versionLookupKey] : "Unknown interaction type",
                    }
                });

                const query = {
                    ...this.exhibitionAndDateRange,
                    arTargetId: this.arTargetId,
                };
                
                const data = await AnalyticsService.getArInteractionEvents(query);

                let tableData = [];

                if(data?.interactions) {

                    let sumOfTotalDurations = 0;
                    let sumOfFirsts = 0;
                    let sumOfLasts = 0;
                    for(const { totalDuration, firstInteraction, lastInteraction } of Object.values(data.interactions)) {
                        sumOfTotalDurations += totalDuration;
                        sumOfFirsts += firstInteraction;
                        sumOfLasts += lastInteraction;
                    }

                    for(const [ interaction, interactionData ] of Object.entries(data.interactions)) {

                        const label = this.getInteractionName(interaction);
                        const usageShare = getShare(interactionData.totalDuration, sumOfTotalDurations);
                        const timePerVisit = interactionData.totalVisits > 0 ? (interactionData.totalDuration / interactionData.totalVisits) : 0;

                        tableData.push({
                            label,
                            usage: interactionData.totalDuration,
                            usageShare,
                            visits: interactionData.totalVisits,
                            timePerVisit,
                            first: interactionData.firstInteraction,
                            firstShare: getShare(interactionData.firstInteraction, sumOfFirsts),
                            last: interactionData.lastInteraction,
                            lastShare: getShare(interactionData.lastInteraction, sumOfLasts),
                            previous: createVisitTable(interactionData.previousInteractions, key => this.getInteractionName(key)),
                            next: createVisitTable(interactionData.nextInteractions, key => this.getInteractionName(key)),
                            foldOutTitle: label,
                            foldOutSubtitle: this.getInteractonModule(interaction),
                            showDetails: false,
                        });
                    }
                }
                this.tableData = tableData;

                this.loading = false;
            },

            getInteractionName(interactionId) {
                if(this.interactionLookUp[interactionId]) {
                    return this.interactionLookUp[interactionId].name;
                }
                return interactionId;
            },

            getInteractonModule(interactionId) {
                if(this.interactionLookUp[interactionId]) {
                    return l("Interaction type:") + " " + l(this.interactionLookUp[interactionId].interactionModule);
                }
                return l("Unknown interaction type");
            },

            highlight(row) {
                // TODO do something
            },

            unhighlight(row) {
                // TODO do something
            },

            select(row) {
                this.tableData.forEach(otherRow => {
                    if(otherRow !== row) {
                        otherRow.showDetails = false;
                    }
                });
                row.showDetails = !row.showDetails;
            },

            shortDuration,

            getPercentage,

            l,
        },
    }
</script>