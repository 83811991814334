<template>
    <div class="headerBox">
        <div class="headerImage">
            <img v-if="values.image" class="headerImage" :src="'/assets/' + values.image + '.jpg'">
            <div class="headerImageContent">
                <div class="headerGradient">
                    <div v-if="duration" class="headerDuration">
                        {{ duration }}
                    </div>
                </div>
                <div class="headerLine">
                    <div />
                </div>
                <div class="headerTitle">
                    {{ values.name }}
                </div>
            </div>
        </div>
        <div v-if="values.authorImage || values.author || values.authorBiography" class="authorSection">
            <img v-if="values.authorImage" class="authorImage" :src="'/assets/' + values.authorImage + '.jpg'">
            <div v-if="values.author" class="authorName">
                {{ by }} <span>{{ values.author }}</span>
            </div>
            <div v-if="values.authorBiography" class="authorBiography">
                <span>{{ values.authorBiography }}</span>
            </div>
        </div>
    </div>
</template>
<script>

    import { lInPreviewLocale } from "../../../utils/LocalizationUtils.js";


    export default {
        props: {
            values: { type: Object, default: () => { } }
        },
        computed: {
            duration() {
                const duration = this.values.duration;
                if (typeof duration === "undefined" || duration === null || duration === "" || duration <= 0) {
                    return "";
                } else if (duration == 1) { // do not ===
                    return lInPreviewLocale("1 minute");
                } else {
                    return lInPreviewLocale("${v} minutes", duration);
                }
            },
            by() {
                return lInPreviewLocale("By ${v}", "");
            }
        }
    }
</script>
<style scoped>

    .headerBox {
        position: relative;
        min-height: 100px;
        background: #212121;
    }

    .headerImage {
        position: relative;
        height: 440px;
        width: 100%;
        padding: 0;
    }

    .headerImage img {
        display: block;
        height: 440px;
        width: 100%;
        object-fit: cover;
    }

    .headerImageContent {
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
    }

    .headerGradient {
        position: relative;
        height: 50px;
        background-image: linear-gradient(to bottom, rgba(33, 33, 33, 0.0), rgba(33, 33, 33, 0.8));
    }

    .headerDuration {
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
        padding: 0 20px 8px;
        font-size: 15px;
        font-style: italic;
        color: #fff;
    }

    .headerLine {
        width: 100%;
        height: 1px;
        padding: 0 10px;
        background: rgba(33, 33, 33, 0.8);
    }

    .headerLine div {
        width: 100%;
        height: 1px;
        background: #fff;
    }

    .headerTitle {
        padding: 16px 20px 32px;
        font-size: 30px;
        font-weight: 500;
        color: #fff;
        background: rgba(33, 33, 33, 0.8);
    }

    .authorSection {
        padding: 16px 20px 16px;
    }

    .authorImage {
        display: block;
        height: 48px;
        width: 48px;
        margin: 0 auto;
        object-fit: cover;
        border-radius: 24px;
    }

    .authorName {
        padding: 4px 0;
        font-size: 15px;
        color: #fff;
        text-align: center;
    }

    .authorName span {
        font-weight: 500;
    }

    .authorBiography {
        padding-bottom: 24px;
        font-size: 15px;
        font-style: italic;
        color: #fff;
        white-space: pre-wrap;
    }

</style>