<template>
    <v-layout row justify-center>
        <v-dialog :value="true" persistent max-width="1080px" scrollable>
            <v-card>
                <v-card-title class="pb-4">
                    <span class="headline primary--text">
                        <v-icon large color="primary" class="title-icon">
                            mdi-information-outline
                        </v-icon>
                        {{ l("Uses of ${v.title}", stopType) + " (" + stopType.typeId + " v." + stopType.versionNumber + ")" }}
                    </span>
                </v-card-title>
                <v-divider />
                <v-card-text ref="scrollArea" class="pt-4">
                    <v-data-table hide-default-footer :headers="headers" :items="stopType.uses" :sort-by="sortBy" :items-per-page="-1" :no-data-text="l('No entries.')">
                        <template #item="row">
                            <tr>
                                <td class="clickable" @click="goTo(row.item)">
                                    <span class="id-box">
                                        {{ row.item.tourId }}
                                    </span>
                                </td>
                                <td class="clickable" @click="goTo(row.item)">
                                    {{ localize(inContentLocale(), row.item.tourTitle) }}
                                </td>
                                <td>
                                    {{ row.item.index }}
                                </td>
                                <td>
                                    {{ parameterPreview(row.item) }}
                                </td>
                                <td class="text-right nobreak" style="width:68px">
                                    <v-tooltip left>
                                        <template #activator="{ on }">
                                            <v-btn text icon color="primary" v-on="on" @click="goTo(row.item)">
                                                <v-icon>mdi-arrow-right-thick</v-icon>
                                            </v-btn>
                                        </template>
                                        <span class="body-2">{{ l("Go to Tour") }}</span>
                                    </v-tooltip>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" text min-width="80" @click="close">
                        {{ l("Close") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-layout>
</template>
<script>

    import { inContentLocale } from "../../locales/utils/localeUtils.js";
    import { l, localize } from "../../utils/LocalizationUtils.js";
    

    export default {
        props: {
            stopType: { type: Object, default: null },
        },
        data() {
            return {
                headers: [
                    { text: this.l("Tour ID"), value: "tourId" },
                    { text: this.l("Tour"), value: "tourTitle" },
                    { text: this.l("Index"), value: "index" },
                    { text: this.l("Parameters"), sortable: false },
                    { text: "", sortable: false }
                ],
                sortBy: "tourId",
            };
        },
        methods: {
            parameterPreview(use) {
                const paramString = JSON.stringify(use.parameters);
                return paramString.substring(0, 60) + (paramString.length > 60 ? "..." : "");
            },
            goTo(entry) {
                this.$router.push("/tours/" + entry.tourId + "/editStops");
            },
            close() {
                this.$emit("close");
            },
            localize,
            inContentLocale,
            l,
        }
    }
</script>
<style scoped>

    .clickable {
        cursor: pointer;
    }

    .title-icon {
        vertical-align: sub;
    }

    .id-box {
        background: #ddd;
        font-family: "Lucida Console", Monaco, monospace;
        padding: 5px;
        color: #111;
        font-size: 11px;
    }

</style>