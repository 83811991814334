<template>
    <div class="list">
        <v-text-field 
            v-if="locations.length > 0"
            v-model="search"
            append-icon="mdi-magnify"
            :label="l('Search')"
            single-line
            clearable
            hide-details
            class="searchField"
            @focus="reportFocusOnSearchField(true)"
            @click="reportFocusOnSearchField(true)"
            @blur="reportFocusOnSearchField(false)"
        />
        <div
            v-for="location in filteredLocations"
            :key="location._id"
            :class="{ 'listItem': true, 'hovered': locationBeingHoveredOver === location, 'placing': locationBeingEdited === location }"
            @click="clickOnLocation(location)"
            @mouseover="mouseOverLocation(location)"
            @mouseout="mouseOutOfLocation(location)"
        >
            <ReferencePreview
                type="page"
                :preview-data="location.previewData"
                tile
                hover-for-large-image
                :append-icon="location.locked ? 'mdi-lock' : null"
            />
        </div>
        <div
            v-if="locations.length === 0 && !loading"
            class="noItems text-body-2 text-center"
        >
            {{ l('This floor has no locations') }}
        </div>
    </div>
</template>
<script>

    import ReferencePreview from "../../components/globalUiElements/ReferencePreview.vue";
    import { l } from "../../utils/LocalizationUtils.js";


    export default {
        components: {
            ReferencePreview,
        },
        props: {
            locations: { type: Array, default: () => [] },
            locationBeingHoveredOver: { type: Object, default: null },
            locationBeingEdited: { type: Object, default: null },
            loading: { type: Boolean, default: false },
        },
        data() {
            return {
                search: "",
            };
        },
        computed: {
            filteredLocations() {
                const searchString = this.search ? this.search.trim().toLocaleLowerCase() : "";
                if(searchString === "") {
                    return this.locations;
                } else {
                    return this.locations.filter(location => {
                        return location.previewData
                            && location.previewData.searchableString
                            && location.previewData.searchableString.includes(searchString);
                    });
                }
            },
        },
        methods: {

            reportFocusOnSearchField(focus) {
                if(focus) {
                    this.releaseLocation();
                }
                this.$emit("reportFocusOnSearchField", focus);
            },

            releaseLocation() {
                this.$emit("startPlacing", null);
            },

            clickOnLocation(location) {
                if(location === this.locationBeingEdited) {
                    this.$emit("startPlacing", null);
                } else {
                    this.$emit("startPlacing", location);
                }
            },

            mouseOverLocation(location) {
                this.$emit("hover", location);
            },

            mouseOutOfLocation(location) {
                if(this.locationBeingHoveredOver === location) {
                    this.$emit("hover", null);
                }
            },

            l,

        },
    }

</script>
<style scoped>

    .list {
        overflow-y: auto;
        flex-shrink: 100;
        flex-grow: 100;
        padding-bottom: 8px;
    }

    .searchField {
        margin: 0px 18px 14px;
    }

    .listItem {
        margin: 4px 13px;
        cursor: pointer;
        border-radius: 8px;
        padding: 3px;
    }

    .listItem.hovered {
        padding: 1px;
        cursor: pointer;
        border: 2px solid var(--v-primary-base);
    }

    .listItem.placing {
        padding: 0;
        cursor: pointer;
        border: 3px solid var(--v-primary-base);
    }

    .noItems {
        margin: 50px 20px;
    }

</style>
