<template>
    <div>
        <h2 class="mt-4 mb-2">
            {{ l("Feature Usage") }}
        </h2>
        <div v-if="loading">
            <v-progress-linear indeterminate />
        </div>
        <GChart
            v-if="!loading"
            type="PieChart"
            :data="chartData"
            :options="chartOptions"
            :highlight="chartHighlight"
        />
        <AnalyticsDataTable
            v-if="tableData.length > 0"
            :headers="tableHeaders"
            :data="tableData"
            :fold-out-labels="foldOutLabels"
            @highlight="highlight"
            @unhighlight="unhighlight"
            @select="select"
        />
    </div>
</template>
<script>

    import { GChart } from "vue-google-charts";
    import AnalyticsDataTable from "./AnalyticsDataTable.vue";
    import AnalyticsService from "../../services/AnalyticsService.js";
    import { createVisitTable, getShare } from "../../utils/analyticsUtils.js";
    import { shortDuration, getPercentage } from "../../utils/StringUtils.js";
    import { l } from "../../utils/LocalizationUtils.js";


    export default {
        components: {
            GChart,
            AnalyticsDataTable,
        },
        props: {
            exhibitionAndDateRange: { type: Object, default: null },
        },
        data() {
            return {
                loading: true,

                chartData: [],
                chartOptions: {
                    height: 480,
                    pieSliceText: "label",
                    tooltip: {
                        isHtml: true,
                    },
                    legend: "none",
                    slices: [],
                },
                chartHighlight: null,

                tableHeaders: [
                    { sortable: false },
                    { text: l("Feature"), value: "label" },
                    { text: l("Total Usage"), value: "usage", align: "end" },
                    { text: l("Visits"), value: "visits", align: "end" },
                    { text: l("Time per visit"), value: "timePerVisit", align: "end" },
                    { text: l("Was first feature used"), value: "first", align: "end" },
                    { text: l("Was last feature used"), value: "last", align: "end" },
                    { text: l("Most common origin"), sortable: false, align: "end" },
                    { text: l("Most common next feature"), sortable: false, align: "end" },
                ],
                tableData: [],

                foldOutLabels: {
                    usage: l("Total time spent using this feature:"),
                    usageShare: l("Share of total app usage:"),
                    visits: l("Number of uses of this feature:"),
                    timePerVisit: l("Average duration of use:"),
                    first: l("Was the first feature the user used:"),
                    last: l("Was the last feature the user used:"),
                },
            };
        },
        watch: {
            exhibitionAndDateRange: {
                handler() {
                    if(this.exhibitionAndDateRange && this.exhibitionAndDateRange.exhibitionId) {
                        this.loadAppFeatureUsageData();
                    }
                },
                deep: true,
                immediate: true,
            },
        },
        methods: {

            async loadAppFeatureUsageData() {
                this.loading = true;

                const data = await AnalyticsService.getModeEvents(this.exhibitionAndDateRange);

                let chartData = [];
                let chartSlices = [];
                let tableData = [];

                if(data?.modes) {

                    chartData.push([
                        l("Feature"),
                        l("Use"),
                        { role: "tooltip", "p": { "html": true } },
                    ]);

                    let sumOfTotalDurations = 0;
                    let sumOfFirsts = 0;
                    let sumOfLasts = 0;
                    for(const { totalDuration, first, last } of Object.values(data.modes)) {
                        sumOfTotalDurations += totalDuration;
                        sumOfFirsts += first;
                        sumOfLasts += last;
                    }

                    for(const [ mode, modeData ] of Object.entries(data.modes)) {

                        const label = l(`AnalyticsFeature:${mode}`);
                        const usageShare = getShare(modeData.totalDuration, sumOfTotalDurations);
                        const timePerVisit = modeData.totalVisits > 0 ? (modeData.totalDuration / modeData.totalVisits) : 0;

                        let tooltip = `<p class="pa-2 ma-0 text-no-wrap"><span class="text-subtitle-2">${label}</span><br/>`;

                        const hoursOfUsageLabel = l("${v} of usage", shortDuration(modeData.totalDuration, false));
                        tooltip += `<span class="text-body-2">${hoursOfUsageLabel}</span><br />`;

                        const percentageOfTotalUsageLabel = l("${v} of total usage", getPercentage(usageShare, 1));
                        tooltip += `<span class="text-body-2">${percentageOfTotalUsageLabel}</span>`;

                        chartData.push([
                            label,
                            modeData.totalDuration,
                            tooltip,
                        ]);

                        const chartSlice = {
                            offset: 0.0,
                        };
                        chartSlices.push(chartSlice);

                        tableData.push({
                            label,
                            usage: modeData.totalDuration,
                            usageShare,
                            visits: modeData.totalVisits,
                            timePerVisit,
                            first: modeData.first,
                            firstShare: getShare(modeData.first, sumOfFirsts),
                            last: modeData.last,
                            lastShare: getShare(modeData.last, sumOfLasts),
                            previous: createVisitTable(modeData.previousModes, key => l(`AnalyticsFeature:${key}`)),
                            next: createVisitTable(modeData.nextModes, key => l(`AnalyticsFeature:${key}`)),
                            foldOutTitle: label,
                            showDetails: false,
                            chartSlice
                        });
                    }
                }
                this.chartData = chartData;
                this.chartOptions.slices = chartSlices;
                this.tableData = tableData;

                this.loading = false;
            },

            highlight(row) {
                this.chartHighlight = row;
                this.updateChartOffsets();
            },

            unhighlight(row) {
                if(this.chartHighlight === row) {
                    this.chartHighlight = null;
                    this.updateChartOffsets();
                }
            },

            select(row) {
                this.tableData.forEach(otherRow => {
                    if(otherRow !== row) {
                        otherRow.showDetails = false;
                    }
                });
                row.showDetails = !row.showDetails;
                this.updateChartOffsets();
            },

            updateChartOffsets() {
                this.tableData.forEach(row => {
                    if(row.showDetails || row === this.chartHighlight) {
                        row.chartSlice.offset = 0.08;
                    } else {
                        row.chartSlice.offset = 0.0;
                    }
                });
            },

            shortDuration,

            getPercentage,

            l,
        },
    }
</script>