<template>
    <div class="editableValueField" :style="widthStyle">
        <div
            v-if="!editing"
            :class="{ 'valueDisplay': true, 'text-body-2': true, 'active': !disabled }"
            @click="editValue"
        >
            {{ displayValue }}
        </div>
        <v-text-field
            v-if="editing"
            ref="editableValueInput"
            v-model="editableValue"
            class="editableValueInput"
            :style="widthStyle"
            single-line
            dense
            solo
            hide-details
            height="12"
            :prefix="prefix"
            :suffix="suffix"
            @keyup.enter="applyInput"
            @blur="applyInput"
        />
    </div>
</template>
<script>

    export default {
        props: {
            value: { type: undefined, default: "0" },
            disabled: { type: Boolean, default: false },
            prefix: { type: String, default: undefined },
            suffix: { type: String, default: undefined },
            width: { type: String, default: "64" },
        },
        data() {
            return {
                editing: false,
                editableValue: "",
            };
        },
        computed: {

            displayValue() {
                return (this.prefix ? this.prefix : "") + this.value + (this.suffix ? this.suffix : "");
            },

            widthStyle() {
                return { width: this.width + "px" };
            },

        },
        watch: {
            value: {
                immediate: true,
                handler() {
                    this.editableValue = this.value;
                    this.editing = false;
                },
            },
            disabled: {
                immediate: true,
                handler() {
                    if(this.disabled) {
                        this.editing = false;
                    }
                },
            },
        },
        methods: {

            editValue() {
                if(!this.disabled) {
                    this.editing = true;
                    setTimeout(() => {
                        this.$refs.editableValueInput.$el.querySelector("input").select();
                    }, 50);
                }
            },

            applyInput()  {
                this.editing = false;

                const parsedValue = parseFloat(this.editableValue);
                if(!isNaN(parsedValue)) {
                    this.$emit("change", parsedValue);
                } else {
                    this.editableValue = this.value;
                }
            },

        }
    }

</script>
<style scoped>

    .editableValueField {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-grow: 0;
    }

    .valueDisplay {
        padding: 3px 6px;
        border-radius: 5px;
        margin-right: 3px;
    }

    .valueDisplay.active:hover {
        cursor: pointer;
        background: #e0e0e0;
    }

    .editableValueInput {
        width: 64px;
        flex-grow: 0;
        padding: 0 !important;
    }

    ::v-deep .editableValueInput .v-input__slot {
        padding: 0 6px !important;
    }

    ::v-deep .editableValueInput input,
    ::v-deep .editableValueInput .v-text-field__suffix {
        font-size: 14px;
        text-align: right;
    }

</style>
