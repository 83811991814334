import * as usageService from "../services/usageService.js";


export default {


    namespaced: true,


    state: {
        usage: {
            visible: false,
            limits: {},
            report: [],
        },
        currentUse: {
            storageSize: 0,
            exhibitionCount: 0,
            arTargetCount: 0,
            pageCount: 0,
            tourCount: 0,
        },
    },


    mutations: {

        setUsage(state, usage) {
            state.usage = usage;
        },

        setCurrentUse(state, currentUse) {
            state.currentUse = currentUse;
        },

    },


    actions: {

        load({ commit }) {
            return new Promise(async (resolve, reject) => {
                try {
                    
                    const usage = await usageService.get();
                    if (usage) {
                        commit("setUsage", usage);
                    }

                    const currentUse = await usageService.getCurrentUse();
                    if (currentUse) {
                        commit("setCurrentUse", currentUse);
                    }

                    resolve();
                } catch (error) {
                    reject(`Could not update usage: ${error}`);
                }
            });
        },

    },


    getters: {

        getUsage(state) {
            return state.usage;
        },

        getCurrentUse(state) {
            return state.currentUse;
        },

    },

}