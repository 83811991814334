<template>
    <div :class="{ 'pa-3': label, 'my-4': label, dateInputBox: label, dateInputBoxContained: !label, required: label && field.required }">
        <v-subheader v-if="label && !field.error && !field.inSingleFieldArrayEntry" class="manualLabel label noBottomMargin">
            {{ label }}
        </v-subheader>
        <v-subheader v-if="label && field.error && !field.inSingleFieldArrayEntry" style="color:red" class="manualLabel label noBottomMargin">
            {{ label }}
        </v-subheader>
        <p v-if="field.errorMessages && field.errorMessages.length > 0" style="color:red; margin: 8px 0 0 0; font-size:smaller;">
            {{ field.errorMessages[0] }}
        </p>
        <div class="singleOrPeriodContainer">
            <div class="singleOrPeriodSelector">
                <v-tooltip right>
                    <template #activator="{ on }">
                        <v-btn
                            icon
                            tile
                            small
                            :class="{ selectedOption: !isPeriod }"
                            v-on="on"
                            @click="setSingle"
                        >
                            <v-icon dense>
                                mdi-numeric-1-box-outline
                            </v-icon>
                        </v-btn>
                    </template>
                    <span class="body-2">{{ l("Single date") }}</span>
                </v-tooltip>
                <v-tooltip right>
                    <template #activator="{ on }">
                        <v-btn
                            icon
                            tile
                            small
                            :class="{ selectedOption: isPeriod }"
                            v-on="on"
                            @click="setPeriod"
                        >
                            <v-icon dense>
                                mdi-arrow-expand-horizontal
                            </v-icon>
                        </v-btn>
                    </template>
                    <span class="body-2">{{ l("Time period") }}</span>
                </v-tooltip>
            </div>
            <div class="fieldColumn">
                <div class="fieldRow">
                    <div v-if="isPeriod" class="fromToLabel">
                        {{ l("From... Date") }}
                    </div>
                    <div v-if="fromTagField" class="tagField">
                        <InputField v-model="fromTagField" />
                    </div>
                    <div v-if="fromDayField" class="dateField">
                        <InputField v-model="fromDayField" />
                    </div>
                    <div v-if="fromMonthField" class="monthField">
                        <InputField v-model="fromMonthField" />
                    </div>
                    <div v-if="fromYearField" class="dateField">
                        <InputField v-model="fromYearField" />
                    </div>
                    <div v-if="fromEraField" class="eraField">
                        <InputField v-model="fromEraField" />
                    </div>
                </div>
                <div v-if="isPeriod" class="fieldRow">
                    <div class="fromToLabel">
                        {{ l("To... Date") }}
                    </div>
                    <div v-if="toTagField" class="tagField">
                        <InputField v-model="toTagField" />
                    </div>
                    <div v-if="toDayField" class="dateField">
                        <InputField v-model="toDayField" />
                    </div>
                    <div v-if="toMonthField" class="monthField">
                        <InputField v-model="toMonthField" />
                    </div>
                    <div v-if="toYearField" class="dateField">
                        <InputField v-model="toYearField" />
                    </div>
                    <div v-if="toEraField" class="eraField">
                        <InputField v-model="toEraField" />
                    </div>
                </div>
                <div class="labelBox">
                    <v-icon>
                        mdi-label-outline
                    </v-icon>
                    <div v-if="defaultLabelDisplayInContentLocale && !labelField" class="text-body-2 defaultLabelDisplay">
                        {{ defaultLabelDisplayInContentLocale }}
                    </div>
                    <div v-if="labelField" class="labelField">
                        <InputField v-model="labelField" />
                    </div>
                    <v-tooltip v-if="!labelField" right>
                        <template #activator="{ on }">
                            <v-btn icon v-on="on" @click="openLabelField">
                                <v-icon>
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                        </template>
                        <span class="body-2">{{ l("Edit label") }}</span>
                    </v-tooltip>
                    <v-tooltip v-if="labelField" right>
                        <template #activator="{ on }">
                            <v-btn icon class="useDefaultLabelButton" v-on="on" @click="closeLabelField">
                                <v-icon>
                                    mdi-pencil-off
                                </v-icon>
                            </v-btn>
                        </template>
                        <span class="body-2">{{ l("Use default label") }}</span>
                    </v-tooltip>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import { setupField } from "../../../utils/FormUtils.js";
    import { inContentLocale, getAppLocales } from "../../../locales/utils/localeUtils.js";
    import { dateString } from "../../../localization/localization.js";
    import { l, localize } from "../../../utils/LocalizationUtils.js";


    export default {
        model: {
            prop: "propField",
            event: "input"
        },
        props: {
            propField: { type: Object, default: null }
        },
        data() {
            return {
                isPeriod: false,
                fromTagField: null,
                fromDayField: null,
                fromMonthField: null,
                fromYearField: null,
                fromEraField: null,
                toTagField: null,
                toDayField: null,
                toMonthField: null,
                toYearField: null,
                toEraField: null,
                labelField: null
            }
        },
        computed: {
            field() {
                return this.propField;
            },
            label() {
                return this.field.label ? l(this.field.label) : null;
            },
            defaultLabelDisplay() {
                const localizations = [];
                getAppLocales().forEach(locale => {

                    if(this.isPeriod) {
                        localizations[locale.ietfLanguageTag] = dateString(
                            locale,
                            this.fromYearField?.getValue(),
                            this.fromMonthField?.getValue(),
                            this.fromDayField?.getValue(),
                            this.fromEraField?.getValue(),
                            this.fromTagField?.getValue(),
                        ) + " – " + dateString(
                            locale,
                            this.toYearField?.getValue(),
                            this.toMonthField?.getValue(),
                            this.toDayField?.getValue(),
                            this.toEraField?.getValue(),
                            this.toTagField?.getValue(),
                        );
                    } else {
                        localizations[locale.ietfLanguageTag] = dateString(
                            locale,
                            this.fromYearField?.getValue(),
                            this.fromMonthField?.getValue(),
                            this.fromDayField?.getValue(),
                            this.fromEraField?.getValue(),
                            this.fromTagField?.getValue(),
                        );
                    }
                });
                
                return localizations;
            },
            defaultLabelDisplayInContentLocale() {
                return localize(inContentLocale(), this.defaultLabelDisplay);
            },
        },
        watch: {
            fromTagField: {
                handler(form) {
                    this.setFieldValue();
                },
                deep: true
            },
            fromDayField: {
                handler(form) {
                    this.setFieldValue();
                },
                deep: true
            },
            fromMonthField: {
                handler(form) {
                    this.setFieldValue();
                },
                deep: true
            },
            fromYearField: {
                handler(form) {
                    this.setFieldValue();
                },
                deep: true
            },
            fromEraField: {
                handler(form) {
                    this.setFieldValue();
                },
                deep: true
            },
            toTagField: {
                handler(form) {
                    this.setFieldValue();
                },
                deep: true
            },
            toDayField: {
                handler(form) {
                    this.setFieldValue();
                },
                deep: true
            },
            toMonthField: {
                handler(form) {
                    this.setFieldValue();
                },
                deep: true
            },
            toYearField: {
                handler(form) {
                    this.setFieldValue();
                },
                deep: true
            },
            toEraField: {
                handler(form) {
                    this.setFieldValue();
                },
                deep: true
            },
            labelField: {
                handler(form) {
                    this.setFieldValue();
                },
                deep: true
            }
        },
        beforeCreate() { // Delayed InputField import, to prevent recursion of imports
            this.$options.components.InputField = require('./InputField.vue').default;
        },
        mounted() {
            const tagOptions = [
                { text: "", value: "" },
                { text: "Circa", value: "Circa" },
                { text: "Before", value: "Before" },
                { text: "After", value: "After" }
            ];
            const monthOptions = [
                { text: "", value: -1 },
                { text: "January", value: 1 },
                { text: "February", value: 2 },
                { text: "March", value: 3 },
                { text: "April", value: 4 },
                { text: "May", value: 5 },
                { text: "June", value: 6 },
                { text: "July", value: 7 },
                { text: "August", value: 8 },
                { text: "September", value: 9 },
                { text: "October", value: 10 },
                { text: "November", value: 11 },
                { text: "December", value: 12 }
            ];
            const eraOptions = [
                { text: "AD Date", value: "AD" },
                { text: "BC Date", value: "BC" }
            ];

            this.isPeriod = this.field.value?.typeString === "Period";

            // from fields
            this.fromTagField = setupField({
                type: "Select",
                options: tagOptions
            }, this.replaceUndefined(this.isPeriod ? this.field.value?.start?.tagString : this.field.value?.date?.tagString, ""));

            this.fromDayField = setupField({
                label: "Day",
                type: "Number",
            }, this.replaceUndefined(this.isPeriod ? this.field.value?.start?.day : this.field.value?.date?.day, ""));

            this.fromMonthField = setupField({
                label: "Month",
                type: "Select",
                options: monthOptions
            }, this.replaceUndefined(this.isPeriod ? this.field.value?.start?.month : this.field.value?.date?.month, -1));

            this.fromYearField = setupField({
                label: "Year",
                type: "Number",
                required: true
            }, this.replaceUndefined(this.isPeriod ? this.field.value?.start?.year : this.field.value?.date?.year, ""));

            this.fromEraField = setupField({
                type: "Select",
                options: eraOptions
            }, this.replaceUndefined(this.isPeriod ? this.field.value?.start?.eraString : this.field.value?.date?.eraString, "AD"));

            // to fields
            this.toTagField = setupField({
                type: "Select",
                options: tagOptions
            }, this.replaceUndefined(this.field.value?.end?.tagString, ""));

            this.toDayField = setupField({
                label: "Day",
                type: "Number",
            }, this.replaceUndefined(this.field.value?.end?.day, ""));

            this.toMonthField = setupField({
                label: "Month",
                type: "Select",
                options: monthOptions
            }, this.replaceUndefined(this.field.value?.end?.month, -1));

            this.toYearField = setupField({
                label: "Year",
                type: "Number",
                required: true
            }, this.replaceUndefined(this.field.value?.end?.year, ""));

            this.toEraField = setupField({
                type: "Select",
                options: eraOptions
            }, this.replaceUndefined(this.field.value?.end?.eraString, "AD"));

            if(this.field.value?.label) {
                this.setLabelField(this.field.value.label);
            }
        },
        methods: {
            setFieldValue() {
                if(this.isPeriod) {
                    this.field.value = {
                        typeString: "Period",
                        start: {
                            tagString: this.fromTagField ? this.fromTagField.getValue() : null,
                            eraString: this.fromEraField ? this.fromEraField.getValue() : null,
                            year: this.fromYearField ? this.makeValidYear(this.fromYearField.getValue()) : -1,
                            month: this.fromMonthField ? this.makeValidMonth(this.fromMonthField.getValue()) : -1,
                            day: this.fromDayField ? this.makeValidDay(this.fromDayField.getValue()) : -1
                        },
                        end: {
                            tagString: this.toTagField ? this.toTagField.getValue() : null,
                            eraString: this.toEraField ? this.toEraField.getValue() : null,
                            year: this.toYearField ? this.makeValidYear(this.toYearField.getValue()) : -1,
                            month: this.toMonthField ? this.makeValidMonth(this.toMonthField.getValue()) : -1,
                            day: this.toDayField ? this.makeValidDay(this.toDayField.getValue()) : -1
                        },
                        label: this.labelField ? this.labelField.getValue() : null
                    };
                } else {
                    this.field.value = {
                        typeString: "Single",
                        date: {
                            tagString: this.fromTagField ? this.fromTagField.getValue() : null,
                            eraString: this.fromEraField ? this.fromEraField.getValue() : null,
                            year: this.fromYearField ? this.makeValidYear(this.fromYearField.getValue()) : -1,
                            month: this.fromMonthField ? this.makeValidMonth(this.fromMonthField.getValue()) : -1,
                            day: this.fromDayField ? this.makeValidDay(this.fromDayField.getValue()) : -1
                        },
                        label: this.labelField ? this.labelField.getValue() : null
                    };
                }
            },
            makeValidYear(year) {
                if(typeof year !== "undefined" && year !== null && year !== "" && year <= 9999) {
                    return year;
                }
                return -1;
            },
            makeValidMonth(month) {
                if(month && month >= 1 && month <= 12) {
                    return month;
                }
                return -1;
            },
            makeValidDay(day) {
                if(day && day >= 1 && day <= 31) {
                    return day;
                }
                return -1;
            },
            replaceUndefined(value, defaultValue) {
                if(typeof value === "undefined" || value === null || value === false || value === -1 || value === "-1") {
                    return defaultValue;
                }
                return value;
            },
            setSingle() {
                this.isPeriod = false;
                this.setFieldValue();
            },
            setPeriod() {
                this.isPeriod = true;
                this.setFieldValue();
            },
            openLabelField() {
                this.setLabelField(this.defaultLabelDisplay);
            },
            setLabelField(value) {
                this.labelField = setupField({
                    type: "Text",
                    localized: true
                }, value);
            },
            closeLabelField() {
                this.labelField = null;
                this.setFieldValue();
            },
            l
        }
    }
</script>
<style scoped>

    .noBottomMargin {
        margin-bottom: 0 !important;
    }

    .singleOrPeriodContainer {
        display: flex;
        align-items: flex-start;
    }

    .singleOrPeriodSelector {
        display: flex;
        flex-direction: column;
        border: 1px solid rgba(0, 0, 0, 0.6);
        border-radius: 3px;
        margin: 18px 12px 0 0;
    }

    .selectedOption {
        background: rgba(0, 0, 0, 0.2);
    }

    .fieldRow {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        margin: 0 0 8px;
    }

    .fromToLabel {
        width: 8%;
        min-width: 50px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: clip;
        margin-right: 2%;
        text-align: right;
    }

    .tagField {
        width: 18%;
        min-width: 80px;
        margin-right: 2%;
    }

    .dateField {
        width: 12%;
        min-width: 60px;
        margin-right: 2%;
    }

    .monthField {
        width: 18%;
        min-width: 80px;
        margin-right: 2%;
    }

    .eraField {
        width: 15%;
        min-width: 70px;
    }

    .fieldRow ::v-deep .text-input {
        margin: 0 !important;
    }
 
    .dateInputBox {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
        background: #f3f3f3;
        padding: 12px;
    }

    .dateInputBoxContained {
        margin-top: 3px !important;
        margin-bottom: 3px !important;
    }

    .manualLabel {
        font-size: 16px;
        font-weight: 400;
        height: 18px;
        padding: 0;
        margin: 0 0 8px;
    }

    .labelBox {
        display: flex;
        align-items: center;
        justify-items: flex-start;
        flex-wrap: nowrap;
        margin: 8px 0 0;
    }

    .defaultLabelDisplay {
        padding-left: 8px;
        color: rgba(0, 0, 0, 0.6);
    }

    .labelField {
        padding-left: 8px;
        flex-grow: 1;
    }

    .useDefaultLabelButton {
        align-self: flex-end;
    }

</style>