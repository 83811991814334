<template>
    <div class="defaultContentView">
        <div v-if="informationPageType && informationPage && parentPage === 'topics'">
            <router-link to="/topics/" class="pt-2 pb-4 text-decoration-none" style="display:inline-block">
                <v-icon color="primary">
                    arrow_back
                </v-icon>
                {{ l("Topics") }}
            </router-link>
        </div>
        <div v-if="informationPageType && informationPage && parentPage === 'encyclopedia'">
            <router-link :to="'/encyclopedia/' + informationPageType.id" class="pt-2 pb-4 text-decoration-none" style="display:inline-block">
                <v-icon color="primary">
                    arrow_back
                </v-icon>
                {{ l(informationPageType.titlePlural) }}
            </router-link>
        </div>
        <h1 v-if="!informationPageNotFound" class="display-1 mb-5 primary--text">
            <v-icon v-if="informationPageTypeVersion" color="primary" class="titleIcon">
                {{ informationPageTypeVersion.mdiIcon }}
            </v-icon>
            {{ l(informationPageName) }}
        </h1>
        <h1 v-if="informationPageNotFound" class="display-1 mb-5 primary--text">
            <v-icon color="primary" class="titleIcon">
                mdi-file-question
            </v-icon>
            {{ l("Not found") }}
        </h1>
        <v-progress-linear v-if="loading" indeterminate class="loading mt-5" />
        <PreviewLanguageSelection v-if="!informationPageNotFound" />
        <div v-if="informationPageTypeVersion && informationPage" class="blockContainer">
            <div class="blockContainerBox elevation-3" />
            <InformationPageAttributes
                :information-page-type="informationPageType"
                :information-page-type-version="informationPageTypeVersion"
                :information-page="informationPage"
                @save="save"
            />
            <InformationPageBlock               
                v-for="block in informationPage.blocks"
                :key="block.uid"
                :block="block"
                :block-type-look-up="blockTypeLookUp"
                :available-block-types="availableBlockTypes"
                @save="save"
                @moveUpBlock="moveUpBlock(block, $event)"
                @moveDownBlock="moveDownBlock(block, $event)"
                @deleteBlock="deleteBlock(block, $event)"
                @addBlock="addBlock"
            />
            <div class="virtualClosingBlock">
                <AddBlockBeforeBox :index="informationPage.blocks.length" :available-block-types="availableBlockTypes" @addBlock="addBlock" />
            </div>
        </div>
    </div>
</template>
<script>

    import { mapActions } from "vuex";
    import PreviewLanguageSelection from "../../components/globalUiElements/PreviewLanguageSelection.vue";
    import InformationPageAttributes from "../../components/informationPages/InformationPageAttributes.vue";
    import InformationPageBlock from "../../components/informationPages/InformationPageBlock.vue";
    import AddBlockBeforeBox from "../../components/informationPages/AddBlockBeforeBox.vue";
    import InformationPageService from "../../services/InformationPageService.js";
    import InformationPageTypesService from "../../services/InformationPageTypesService.js";
    import InformationPageBlocksService from "../../services/InformationPageBlocksService.js";
    import { getDefaultValue } from "../../utils/FormUtils.js";
    import { getFieldTypeFromParameter } from "../../utils/ParameterUtils.js";
    import { giveUniqueId, giveUniqueIdsToAll, updateIndicesAndMovability } from "../../utils/ListUtils.js";
    import { assignMostRecentTitleAndIcon } from "../../pages/utils/pageUtils.js";
    import { deepClone, deepCloneAndReplace } from "../../utils/objectUtils.js";
    import { inContentLocale } from "../../locales/utils/localeUtils.js";
    import { l, getLocalizedParameterValue } from "../../utils/LocalizationUtils.js";
    import { updateLookups } from '../../utils/referenceUtils';

    export default {
        components: {
            PreviewLanguageSelection,
            InformationPageAttributes,
            InformationPageBlock,
            AddBlockBeforeBox
        },
        data() {
            return {
                parentPage: "",
                loading: false,
                informationPageType: null,
                informationPageTypeVersion: null,
                informationPage: null,
                informationPageNotFound: false,
                blockTypeLookUp: {},
                availableBlockTypes: []
            }
        },
        computed: {
            informationPageName() {
                let informationPageName = "";
                if (this.informationPageTypeVersion && this.informationPage) {
                    informationPageName = getLocalizedParameterValue(inContentLocale(), this.informationPage.attributes, "name");
                }
                this.updateSectionTitle(informationPageName);
                return informationPageName;
            }
        },
        async created() {

            this.loading = true;

            this.parentPage = this.$route.meta.informationPageEditorParent;

            await updateLookups(["pages"]);

            this.updateInformationBlockTypes(() => {

                const action = this.$route.meta.informationPageEditorAction;
                if (action === "new") {

                    const informationPageTypeId = this.parentPage === "topics" ? "topic" : this.$route.params.type;
                    const informationPageTypeVersion = this.$route.params.version;

                    this.loadInformationPageTypeVersion(informationPageTypeId, informationPageTypeVersion, () => {

                        this.informationPage = {
                            informationPageTypeId: this.informationPageType.id,
                            informationPageTypeVersion: informationPageTypeVersion,
                            attributes: [],
                            blocks: [],
                            hidden: false
                        };
                        this.setupAttributes();

                        this.loading = false;
                    });

                } else if (action === "edit") {

                    const informationPageId = this.$route.params.pageId;

                    InformationPageService.getInformationPage(informationPageId, page => {

                        this.loadInformationPageTypeVersion(page.informationPageTypeId, page.informationPageTypeVersion, () => {
                            this.informationPage = page;

                            giveUniqueIdsToAll(this.informationPage.blocks);
                            updateIndicesAndMovability(this.informationPage.blocks, this);

                            this.loading = false;
                        });
                    }, error => {
                        this.informationPageNotFound = true;
                        this.loading = false;
                    });
                }

            });
             
        },
        methods: {

            updateInformationBlockTypes(callback) {

                InformationPageBlocksService.getInformationPageBlocks(blocks => {

                    this.blockTypeLookUp = {};
                    this.availableBlockTypes = [];

                    blocks.forEach(block => {

                        this.blockTypeLookUp[block.id] = {};
                        const hasSeveralVisibleVersions = block.versions.filter(version => version.isVisibleToCurator).length > 1;

                        block.versions.forEach(version => {
                            version.id = block.id;
                            this.blockTypeLookUp[block.id][version.versionNumber] = version;

                            if (version.isVisibleToCurator) {
                                this.availableBlockTypes.push({
                                    blockTypeId: block.id,
                                    blockTypeVersion: version.versionNumber,
                                    title: this.l(version.title) + (hasSeveralVisibleVersions ? " (v" + version.versionNumber + ")" : ""),
                                    mdiIcon: version.mdiIcon,
                                    parameters: version.parameters
                                });
                            }
                        })
                    });

                    callback();
                });
            },            

            loadInformationPageTypeVersion(informationPageTypeId, informationPageTypeVersion, callback) {

                InformationPageTypesService.getInformationPageType(informationPageTypeId, type => {

                    if (type) {

                        this.informationPageType = assignMostRecentTitleAndIcon(type);

                        this.informationPageType.versions.forEach(version => {
                            if (version.versionNumber == informationPageTypeVersion) {
                                this.informationPageTypeVersion = version;
                            }
                        });

                        if (this.informationPageTypeVersion) {
                            callback();

                        } else {
                            const versionName = informationPageTypeId + " (v" + informationPageTypeVersion + ")";
                            this.$root.$emit("error", this.l("Could not find information page type version: ${v}", versionName));
                        }
                    } else {
                        this.$root.$emit("error", this.l("Could not find information page type: ${v}", informationPageTypeId));
                    }
                });
            },

            setupAttributes() {
                this.informationPageTypeVersion.attributes.forEach(attribute => {

                    const fieldType = getFieldTypeFromParameter(attribute.type)[0];
                    let defaultValue = getDefaultValue(fieldType);
                    if (attribute.defaultValue) {
                        defaultValue = attribute.defaultValue;
                    }
                    this.informationPage.attributes.push({
                        key: attribute.key,
                        value: defaultValue
                    });
                });
            },

            save({ editedInformationPage, originalBlock, editedBlock, caller, onSuccess }) {

                if (originalBlock && editedBlock) {
                    editedInformationPage = deepCloneAndReplace(this.informationPage, originalBlock, editedBlock);
                }

                if (this.informationPage._id) {
                    // existing information page
                    InformationPageService.updateInformationPage(editedInformationPage, response => {
                        if (response.errors && response.errors.length > 0) {
                            if (caller) {
                                caller.assignErrors(response.errors);
                            } else {
                                this.$root.$emit("error", "Validation error: " + response.errors[0].message);
                            }

                        } else {
                            if (onSuccess) {
                                onSuccess();
                            }
                            this.informationPage = editedInformationPage;
                            updateIndicesAndMovability(this.informationPage.blocks, this);
                            this.$root.$emit("success", this.l("Changes to information page \"${v}\" saved.", this.informationPageName));
                        }
                    });

                } else {
                    // new information page
                    InformationPageService.insertInformationPage(editedInformationPage, response => {
                        if (response.errors && response.errors.length > 0) {
                            if (caller) {
                                caller.assignErrors(response.errors);
                            } else {
                                this.$root.$emit("error", "Validation error: " + response.errors[0].message);
                            }

                        } else if (response._id) {
                            editedInformationPage._id = response._id;
                            if (onSuccess) {
                                onSuccess();
                            }
                            this.informationPage = editedInformationPage;
                            updateIndicesAndMovability(this.informationPage.blocks, this);
                            this.$root.$emit("success", this.l("Changes to information page \"${v}\" saved.", this.informationPageName));

                        } else {
                            this.$root.$emit("error", "Error: Did not generate entry ID");
                        }
                    });
                }
            },

            moveUpBlock(block, onSuccess) {
                if (block.index > 0) {
                    const editedInformationPage = deepClone(this.informationPage);
                    editedInformationPage.blocks.splice(block.index, 1);
                    editedInformationPage.blocks.splice(block.index - 1, 0, block);
                    this.save({ editedInformationPage, onSuccess });
                }
            },

            moveDownBlock(block, onSuccess) {
                if (block.index < this.informationPage.blocks.length - 1) {
                    const editedInformationPage = deepClone(this.informationPage);
                    editedInformationPage.blocks.splice(block.index, 1);
                    editedInformationPage.blocks.splice(block.index + 1, 0, block);
                    this.save({ editedInformationPage, onSuccess });
                }
            },

            deleteBlock(block, onSuccess) {
                this.$root.$emit(
                    "confirm",
                    this.l("Delete block"),
                    this.l("Do you really want to delete this block?"),
                    this.l("Delete"),
                    yes => {
                        if (yes) {
                            const editedInformationPage = deepClone(this.informationPage);
                            editedInformationPage.blocks.splice(block.index, 1);
                            this.save({ editedInformationPage, onSuccess });
                        } else {
                            onSuccess();
                        }
                    }
                );
            },

            addBlock({ blockType, index, onSuccess }) {
                const newBlock = {
                    blockTypeId: blockType.blockTypeId,
                    blockTypeVersion: blockType.blockTypeVersion,
                    parameters: [],
                };
                blockType.parameters.forEach(parameter => {
                    newBlock.parameters.push({
                        key: parameter.key,
                        value: parameter.defaultValue || null,
                    })
                });
                giveUniqueId(newBlock);

                const editedInformationPage = deepClone(this.informationPage);
                editedInformationPage.blocks.splice(index, 0, newBlock);
                this.save({ editedInformationPage, onSuccess });
            },

            l,

            ...mapActions({
                updateSectionTitle: "platform/updateSectionTitle"
            })
        }
    }
</script>
<style scoped>

    .loading {
        width: 480px;
    }

    .languageSelection {
        width: 480px;
    }

    .blockContainer {
        position: relative;
        margin-bottom: 400px;
    }

    .blockContainerBox {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 480px;
        border: 1px solid #666;
        border-radius: 2px;
        z-index: 2;
    }

    .virtualClosingBlock {
        position: relative;
        height: 0px;
    }

</style>
