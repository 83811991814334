<template>
    <v-dialog :value="isLoggedIn && curationLockEngaged && !bypassed" persistent overlay-color="#ccc" overlay-opacity="0.8" max-width="320px">
        <v-card>
            <v-toolbar dark dense flat color="primary" class="text-uppercase">
                <v-toolbar-title>
                    {{ title }}
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pt-8 pb-0 text-center">
                <v-icon size="100">
                    mdi-update
                </v-icon>
            </v-card-text>
            <v-card-text class="py-6 text-center">
                {{ description }}
            </v-card-text>
            <v-card-actions v-if="hasSystemAccessRights" class="pt-0 mt-0">
                <v-spacer />
                <v-btn color="primary" text type="submit" min-width="80" @click="bypass">
                    {{ bypassButtonLabel }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

    import { mapGetters } from "vuex";
    import ServerStatusService from "../../services/ServerStatusService.js";
    import { l } from "../../localization/localization.js";


    export default {
        data() {
            return {
                curationLockEngaged: false,
                bypassed: false
            };
        },
        computed: {
            title: () => l.cms.curationLock.popupTitle(),
            description: () => l.cms.curationLock.popupDescription(),
            bypassButtonLabel: () => l.cms.curationLock.bypassButtonLabel(),
            ...mapGetters({
                isLoggedIn: "isLoggedIn",
                hasSystemAccessRights: "user/hasSystemAccessRights"
            })
        },
        mounted() {
            this.$root.$on("curationLockOn", () => {
                this.curationLockEngaged = true;
                this.bypassed = false;
            });
            this.$root.$on("curationLockOff", () => {
                this.curationLockEngaged = false;
                this.bypassed = false;
            });
            setTimeout(this.update, 1000);
        },
        methods: {

            update() {
                ServerStatusService.getCurationLockStatus(isLocked => {
                    if (isLocked && !this.curationLockEngaged) {
                        this.$root.$emit("curationLockOn");
                    } else if (!isLocked && this.curationLockEngaged) {
                        this.$root.$emit("curationLockOff");
                    }
                    setTimeout(this.update, 1000);
                }, error => {
                    // TODO probably no internet or lost connection to server --> show message
                    setTimeout(this.update, 1000);
                });
            },

            bypass() {
                this.bypassed = true;
                this.$root.$emit("curationLockBypassed");
            },

        }
    };
</script>
<style scoped>
</style>