<template>
    <div class="defaultContentView">
        <h1 class="display-1 mb-4 primary--text">
            <v-icon color="primary" class="titleIcon">
                mdi-chart-bar
            </v-icon>
            {{ l("Analytics") }}
        </h1>
        <ExhibitionAndDateRangePicker
            v-model="exhibitionAndDateRange"
        />
        <ARTargetUsage
            v-if="isExhibitionSelected"
            :exhibition-and-date-range="exhibitionAndDateRange"
        />
        <InteractionUsage
            v-if="isExhibitionSelected"
            :exhibition-and-date-range="exhibitionAndDateRange"
        />
        <AppFeatureUsage
            v-if="isExhibitionSelected"
            :exhibition-and-date-range="exhibitionAndDateRange"
        />
        <!--<h2 v-if="hasIFrameURL" class="mt-4 mb-2">
            {{ l("App Statistics") }}
        </h2>
        <IFrameContainer
            v-if="hasIFrameURL"
            :i-frame-source="googleDataStudioURL"
        />-->
    </div>
</template>
<script>

    import ExhibitionAndDateRangePicker from "../components/analytics/ExhibitionAndDateRangePicker.vue";
    import ARTargetUsage from "../components/analytics/ARTargetUsage.vue";
    import InteractionUsage from "../components/analytics/InteractionUsage.vue";
    import AppFeatureUsage from "../components/analytics/AppFeatureUsage.vue";
    //import IFrameContainer from "../components/globalUiElements/IFrameContainer.vue"; // TODO
    import { mapActions } from "vuex";
    import { getDateAMonthAgoAt0000, getTodayAt2359 } from "../utils/analyticsUtils.js";
    import { l } from "../utils/LocalizationUtils.js";


    export default {
        components: {
            ExhibitionAndDateRangePicker,
            ARTargetUsage,
            InteractionUsage,
            AppFeatureUsage,
            //IFrameContainer, // TODO
        },
        data () {
            return {
                exhibitionAndDateRange: null,
                googleDataStudioURL: "", // TODO
            };
        },
        computed: {
            isExhibitionSelected() {
                return this.exhibitionAndDateRange && this.exhibitionAndDateRange.exhibitionId;
            },
            hasIFrameURL: function() {
                return this.googleDataStudioURL; // TODO
            },
        },
        created() {
            this.getGoogleDataStudioURL(); // TODO
        },
        mounted() {

            this.updateSectionTitle(this.l("Analytics"));

            this.exhibitionAndDateRange = {
                exhibitionId: "",
                startDate: getDateAMonthAgoAt0000(),
                endDate: getTodayAt2359(),
            };
        },
        methods: {

            getGoogleDataStudioURL() {
                /*AnalyticsService.getGoogleDataStudioURL((err, data) => { TODO
                    this.googleDataStudioURL = data;
                });*/
            },

            ...mapActions({
                updateSectionTitle: "platform/updateSectionTitle"
            }),

            l,
        }
    }
</script>

