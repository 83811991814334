
import { l, tryGetTokenOverride, selectLanguage } from "./localization.js";


// Usage Report in CMS
export const usage = {


    
    pageTitle(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "pageTitle");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Usage Report";
            case "de": return "Nutzungsübersicht";
            default: return `[pageTitle: ${language} missing]`;
        }
    
    },
            

    
    settingsPageTitle(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "settingsPageTitle");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Usage Settings";
            case "de": return "Nutzungseinstellungen";
            default: return `[settingsPageTitle: ${language} missing]`;
        }
    
    },
            

    
    visibilityToggleLabel(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "visibilityToggleLabel");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Show usage report";
            case "de": return "Nutzungsübersicht anzeigen";
            default: return `[visibilityToggleLabel: ${language} missing]`;
        }
    
    },
            

    
    systemlimitsListTitle(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "systemlimitsListTitle");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "System Limits";
            case "de": return "System-Limiten";
            default: return `[systemlimitsListTitle: ${language} missing]`;
        }
    
    },
            

    
    limited(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "limited");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Limited";
            case "de": return "Begrenzt";
            default: return `[limited: ${language} missing]`;
        }
    
    },
            

    
    unlimited(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "unlimited");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Unlimited";
            case "de": return "Unbegrenzt";
            default: return `[unlimited: ${language} missing]`;
        }
    
    },
            

    
    metric(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "metric");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Metric";
            case "de": return "Metrik";
            default: return `[metric: ${language} missing]`;
        }
    
    },
            

    
    used(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "used");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Used";
            case "de": return "Verwendet";
            default: return `[used: ${language} missing]`;
        }
    
    },
            

    
    limitLabel(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "limitLabel");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Limit";
            case "de": return "Begrenzung";
            default: return `[limitLabel: ${language} missing]`;
        }
    
    },
            

    
    editLimitsButtonLabel(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "editLimitsButtonLabel");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Edit limits";
            case "de": return "Begrenzungen bearbeiten";
            default: return `[editLimitsButtonLabel: ${language} missing]`;
        }
    
    },
            

    
    editLimitsFormTitle(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "editLimitsFormTitle");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Edit Limits";
            case "de": return "Begrenzungen bearbeiten";
            default: return `[editLimitsFormTitle: ${language} missing]`;
        }
    
    },
            

    
    limitOfLimitTypeLabel(metric, locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "limitOfLimitTypeLabel");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Limit of " + metric;
            case "de": return "Begrenzung von " + metric;
            default: return `[limitOfLimitTypeLabel: ${language} missing]`;
        }
    
    },
            

    
    editLimitsFormSubmitButtonLabel(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "editLimitsFormSubmitButtonLabel");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        return l.cms.general.save(locale);
    },
            

    
    structureOfUsageReportTitle(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "structureOfUsageReportTitle");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Structure of Usage Report";
            case "de": return "Aufbau der Nutzungsübersicht";
            default: return `[structureOfUsageReportTitle: ${language} missing]`;
        }
    
    },
            

    
    editElementButtonLabel(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "editElementButtonLabel");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Edit element";
            case "de": return "Element bearbeiten";
            default: return `[editElementButtonLabel: ${language} missing]`;
        }
    
    },
            

    
    editElementFormTitle(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "editElementFormTitle");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Edit Element";
            case "de": return "Element bearbeiten";
            default: return `[editElementFormTitle: ${language} missing]`;
        }
    
    },
            

    
    contentFieldLabel(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "contentFieldLabel");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Content";
            case "de": return "Inhalt";
            default: return `[contentFieldLabel: ${language} missing]`;
        }
    
    },
            

    
    usageBarMetricFieldLabel(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "usageBarMetricFieldLabel");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Metric";
            case "de": return "Metrik";
            default: return `[usageBarMetricFieldLabel: ${language} missing]`;
        }
    
    },
            

    
    usageBarLabelFieldLabel(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "usageBarLabelFieldLabel");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        return l.cms.general.label(locale);
    },
            

    
    usageBarCurrentValueFieldLabel(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "usageBarCurrentValueFieldLabel");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Current value";
            case "de": return "Aktueller Wert";
            default: return `[usageBarCurrentValueFieldLabel: ${language} missing]`;
        }
    
    },
            

    
    usageBarMaxValueFieldLabel(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "usageBarMaxValueFieldLabel");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Maximum value";
            case "de": return "Maximum-Wert";
            default: return `[usageBarMaxValueFieldLabel: ${language} missing]`;
        }
    
    },
            

    
    limitFieldExplanation(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "limitFieldExplanation");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "(-1 = unlimited)";
            case "de": return "(-1 = unbegrenzt)";
            default: return `[limitFieldExplanation: ${language} missing]`;
        }
    
    },
            

    
    usageBarUnitFieldLabel(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "usageBarUnitFieldLabel");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Unit";
            case "de": return "Einheit";
            default: return `[usageBarUnitFieldLabel: ${language} missing]`;
        }
    
    },
            

    
    customLimitType(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "customLimitType");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Custom";
            case "de": return "Benutzerdefiniert";
            default: return `[customLimitType: ${language} missing]`;
        }
    
    },
            

    
    editElementFormSubmitButtonLabel(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "editElementFormSubmitButtonLabel");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        return l.cms.general.save(locale);
    },
            

    
    moveUpElementButtonLabel(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "moveUpElementButtonLabel");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Move up element";
            case "de": return "Element nach oben verschieben";
            default: return `[moveUpElementButtonLabel: ${language} missing]`;
        }
    
    },
            

    
    moveDownElementButtonLabel(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "moveDownElementButtonLabel");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Move down element";
            case "de": return "Element nach unten verschieben";
            default: return `[moveDownElementButtonLabel: ${language} missing]`;
        }
    
    },
            

    
    deleteElementButtonLabel(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "deleteElementButtonLabel");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Remove element";
            case "de": return "Element entfernen";
            default: return `[deleteElementButtonLabel: ${language} missing]`;
        }
    
    },
            

    
    deleteElementPromptTitle(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "deleteElementPromptTitle");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Remove Element?";
            case "de": return "Element entfernen?";
            default: return `[deleteElementPromptTitle: ${language} missing]`;
        }
    
    },
            

    
    deleteElementPromptQuestion(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "deleteElementPromptQuestion");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Are you sure you want to remove this element?";
            case "de": return "Wollen Sie dieses Element wirklich entfernen?";
            default: return `[deleteElementPromptQuestion: ${language} missing]`;
        }
    
    },
            

    
    deleteElementPromptButtonLabel(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "deleteElementPromptButtonLabel");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Remove";
            case "de": return "Entfernen";
            default: return `[deleteElementPromptButtonLabel: ${language} missing]`;
        }
    
    },
            

    
    addTitleElementButtonLabel(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "addTitleElementButtonLabel");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Add title";
            case "de": return "Titel hinzufügen";
            default: return `[addTitleElementButtonLabel: ${language} missing]`;
        }
    
    },
            

    
    newTitleElementContent(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "newTitleElementContent");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "New title";
            case "de": return "Neuer Titel";
            default: return `[newTitleElementContent: ${language} missing]`;
        }
    
    },
            

    
    addTextElementButtonLabel(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "addTextElementButtonLabel");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Add text";
            case "de": return "Text hinzufügen";
            default: return `[addTextElementButtonLabel: ${language} missing]`;
        }
    
    },
            

    
    newTextElementContent(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "newTextElementContent");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "New text element";
            case "de": return "Neues Text-Element";
            default: return `[newTextElementContent: ${language} missing]`;
        }
    
    },
            

    
    addSpacerElementButtonLabel(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "addSpacerElementButtonLabel");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Add spacer";
            case "de": return "Abstand hinzufügen";
            default: return `[addSpacerElementButtonLabel: ${language} missing]`;
        }
    
    },
            

    
    addUsageBarElementButtonLabel(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "addUsageBarElementButtonLabel");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Add usage bar";
            case "de": return "Nutzungs-Balken hinzufügen";
            default: return `[addUsageBarElementButtonLabel: ${language} missing]`;
        }
    
    },
            
};
