import axios from "axios";
import { triggerError } from "../utils/alerts.js";


const url = "/api/tourStopTypes/";


class TourStopTypesService {

    // Get all
    static getTourStopTypes(callback) {
        axios.get(url).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }

    // Put
    static updateTourStopTypes(tourStopTypes, callback) {
        axios.put(url, tourStopTypes).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }

}

export default TourStopTypesService;
