import store from "../store/index.js";
import Localization from "../localization2/Localization.js";
import { isString } from "./StringUtils.js";
import { getParameterValue } from "./ParameterUtils.js";
import { inCMSLocale, inContentLocale, inPreviewLocale, inFallbackLocale, getAppLocales } from "../locales/utils/localeUtils.js";


export function l(token, values) {
    if (inCMSLocale()) {
        return Localization.l(inCMSLocale(), token, values);
    } else {
        return Localization.l(inFallbackLocale(), token, values);
    }
}


export function lInContentLocale(token, values) {
    return Localization.l(inContentLocale(), token, values);
}


export function lInPreviewLocale(token, values) {
    return Localization.l(inPreviewLocale(), token, values);
}


export function localize(locale, token) {
    if (token && typeof token === "object") {
        if (locale && locale.ietfLanguageTag && token.hasOwnProperty(locale.ietfLanguageTag)) {
            return token[locale.ietfLanguageTag];
        } else {
            return Object.values(token)[0];
        }
    }
    return token;
}


export function getLocalizedParameterValue(locale, parameters, parameterKey) {
    const value = getParameterValue(parameters, parameterKey);
    return localize(locale, value);
}


export function getLocalizedParameterList(locale, parameters, expectedParameters) {

    const parameterList = {};

    expectedParameters.forEach(expectedParameter => {

        const key = expectedParameter.key ? expectedParameter.key : expectedParameter.name;
        const value = getParameterValue(parameters, key);

        if (expectedParameter.localized && Array.isArray(value)) {
            const localizedArray = [];
            value.forEach(arrayValue => {
                localizedArray.push(localize(locale, arrayValue));
            });
            parameterList[key] = localizedArray;

        } else if (expectedParameter.localized) {
            parameterList[key] = localize(locale, value);

        } else {
            parameterList[key] = value;
        }
    });
    return parameterList;
}


export function setLocalizedValues(fields, valuesToLocalize) {
    if (fields && valuesToLocalize) {

        for (const [fieldKey, valueToLocalize] of Object.entries(valuesToLocalize)) {
            if (fields[fieldKey]) {
                const field = fields[fieldKey];

                getAppLocales().forEach(appLocale => {
                    if (!field.hasOwnProperty(appLocale.ietfLanguageTag)) {
                        field[appLocale.ietfLanguageTag] = Localization.l(appLocale.ietfLanguageTag, valueToLocalize);
                    }
                });
            }
        }
    } 
}


export function getObjectWithValueForAllAppLocales(value) {
    const output = {};
    getAppLocales().forEach(appLocale => {
        output[appLocale.ietfLanguageTag] = value;
    });
    return output;
}


export function hasAnyTranslation(value) {
    let hasAnyTranslation = false;
    if (value && typeof value === "object") {
        getAppLocales().forEach(appLocale => {
            const translation = value[appLocale.ietfLanguageTag];
            if (translation && isString(translation) && translation.trim() !== "") {
                hasAnyTranslation = true;
            }
        });
    }
    return hasAnyTranslation;
}


export function backwardCompatibleL(token, value) { // temporary solution while old l() is still being used
    if (typeof value !== "undefined") {
        if (typeof token === "function") {
            return token(value);
        } else {
            return l(token, value);
        }
    } else {
        if (typeof token === "function") {
            return token();
        } else {
            return l(token);
        }
    }
    
}
