import Vue from "vue";
import { mapMutations, mapActions } from "vuex";
import App from "./App.vue";
import vuetify from "./plugins/Vuetify.js";
import router from "./router/router.js";
import store from "./store/index.js";
import { setupAutoLogout } from "./router/authorization.js";
import { l } from "./utils/LocalizationUtils.js";


Vue.config.productionTip = false;

// global constants
const version = "3.1.02 [2022-07-05] 54ee5";


// initialize vue app
new Vue({
    vuetify,
    router,
    store,
    created() {

        // set frontend version from hard-coded string
        this.setFrontendVersion(version);

        // setup axios interceptor to automatically logout user when accessing a forbidden page
        setupAutoLogout();

        // load user and session from local storage
        this.loadDataFromLocalStorage();

        // load platform status from ping API
        this.loadPingStatus();

        // load languages the app supports
        this.loadAppLanguages();

        // load usage
        this.loadUsage();
        
    },
    mounted() {
        // Update HTML title tag
        this.updateDocumentTitle();
    },
    methods: {
        l, // TODO find and remove any places where this is used
        ...mapMutations({
            setFrontendVersion: "platform/setFrontendVersion"
        }),
        ...mapActions({
            loadPingStatus: "loadPingStatus",
            loadDataFromLocalStorage: "loadDataFromLocalStorage",
            loadAppLanguages: "locales/loadAppLanguages",
            loadUsage: "usage/load",
            updateDocumentTitle: "platform/updateDocumentTitle",
        })
    },
    render: h => h(App)
}).$mount('#app')
