export const versions = [
    {
        number: "3.1",
        date: {
            year: 2022,
            month: 5,
            day: 9,
        },
        newFeatures: [
            {
                "en-US": "Header previews of all pages are now fully displayed",
                "de-CH": "Die Vorschau der Einleitungselemente wird jetzt für alle Seiten vollständig angezeigt",
            },
            {
                "en-US": "Interface language and content language can now be set independantly",
                "de-CH": "Sprache für Benutzeroberfläche und Inhalten kann nun unabhängig eingestellt werden",
            },
            {
                "en-US": "Interactions can now have tags",
                "de-CH": "Interaktionen können nun Tags haben",
            },
            {
                "en-US": "Usage report added (when activated)",
                "de-CH": "Nutzungsübersicht hinzugefügt (falls aktiviert)",
            },
            {
                "en-US": "Accepted types are now listed when uploading media",
                "de-CH": "Akzeptierte Dateiformate werden beim Dateiupload aufgelistet",
            },
            {
                "en-US": "Release note now appear in this list",
                "de-CH": "Versionshinweise werden nun in dieser Liste angezeigt",
            },
        ],
        changes: [
        ],
    },
];