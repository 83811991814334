import UserService from "../../services/UserService.js";


export default {


    namespaced: true,


    state: {
        user: {}
    },


    mutations: {

        setUser(state, user) {
            state.user = user;
        }

    },


    actions: {

        setUser({ state, commit, dispatch }, user) {
            localStorage.user = JSON.stringify(user);
            commit("setUser", user);
            dispatch("locales/selectUserLocales", state.user, { root: true });
        },

        reloadUser({ state, dispatch }) {
            if (state.user && state.user._id) {
                UserService.getMe(state.user._id, user => {
                    dispatch("setUser", user);
                });
            }
        },

        loadUserFromLocalStorage({ dispatch }) {
            if (localStorage.user) {
                const user = JSON.parse(localStorage.user);
                if (user) {
                    dispatch("setUser", user);
                    dispatch("reloadUser");
                }
            }
        }

    },


    getters: {

        getUser(state) {
            return state.user;
        },

        getUserName(state) {
            if (!state.user || !state.user.name) {
                return "";
            }
            return state.user.name;
        },

        hasAccessRights(state) {
            return (zone) => {
                if (state.user && state.user.rights) {
                    if (state.user.rights.hasOwnProperty(zone) && state.user.rights[zone]) {
                        return true;
                    } else if (zone === "media") {
                        return state.user.rights.exhibitions || state.user.rights.arContent || state.user.rights.informationDatabase;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            }
        },

        hasExhibitionsAccessRights(state) {
            return state.user && state.user.rights && state.user.rights.exhibitions;
        },

        hasARContentAccessRights(state) {
            return state.user && state.user.rights && state.user.rights.arContent;
        },

        hasInformationDatabaseAccessRights(state) {
            return state.user && state.user.rights && state.user.rights.informationDatabase;
        },

        hasAnalyticsAccessRights(state) {
            return state.user && state.user.rights && state.user.rights.analytics;
        },

        hasUsersAccessRights(state) {
            return state.user && state.user.rights && state.user.rights.users;
        },

        hasSystemAccessRights(state) {
            return state.user && state.user.rights && state.user.rights.system;
        },

        hasToursAccessRights(state) {
            return state.user && state.user.rights && state.user.rights.informationDatabase;
        },

        hasTopicsAccessRights(state) {
            return state.user && state.user.rights && state.user.rights.informationDatabase;
        },

        hasHomeContentAccessRights(state) {
            return state.user && state.user.rights && state.user.rights.informationDatabase;
        },

        hasEncyclopediaAccessRights(state) {
            return state.user && state.user.rights && state.user.rights.informationDatabase;
        },

        hasMediaAccessRights(state) {
            return state.user
                && state.user.rights
                && (state.user.rights.exhibitions || state.user.rights.arContent || state.user.rights.informationDatabase);
        }

    }

}