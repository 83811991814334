<template>
    <div :class="'block' + (showEditBox ? ' activeBlock' : '')">
        <BlockPreview
            :information-page-type="null"
            :block-type="null"
            :tour="true"
            :values="valuesForPreview"
        />
        <a class="blockSelector" @click="openEditBox()" />
        <EditTourInfoPropertiesBox
            ref="editBox"
            :tour="tour"
            :show-edit-box="showEditBox"
            :saving="saving"
            @close="closeEditBox"
            @save="save"
        />
    </div>
</template>
<script>

    import BlockPreview from "../informationPages/blockPreviews/BlockPreview.vue";
    import EditTourInfoPropertiesBox from "./EditTourInfoPropertiesBox.vue";
    import { deepClone } from "../../utils/objectUtils.js";
    import { inPreviewLocale } from "../../locales/utils/localeUtils.js";
    import { l, localize } from "../../utils/LocalizationUtils.js";


    export default {
        components: {
            BlockPreview,
            EditTourInfoPropertiesBox
        },
        props: {
            tour: { type: Object, default: () => { } }
        },
        data() {
            return {
                showEditBox: false,
                saving: false
            }
        },
        computed: {
            valuesForPreview() {
                return {
                    author: localize(inPreviewLocale(), this.tour.author),
                    authorImage: this.tour.authorImage,
                    authorBiography: localize(inPreviewLocale(), this.tour.authorBiography),
                    name: localize(inPreviewLocale(), this.tour.name),
                    teaser: localize(inPreviewLocale(), this.tour.teaser),
                    image: this.tour.image,
                    duration: this.tour.duration,
                };
            }
        },
        mounted() {
            if (this.tourNotYetCreated()) {
                this.openEditBox();
            }
        },
        methods: {

            openEditBox() {
                this.showEditBox = true;
                this.saving = false;
            },

            closeEditBox() {
                this.showEditBox = false;
                this.saving = false;
            },

            assignErrors(errors) {
                this.$refs.editBox.assignErrors(errors);
                this.saving = false;
            },

            save(values) {

                const editedTour = deepClone(this.tour);

                editedTour.author = values.author;
                editedTour.authorImage = values.authorImage;
                editedTour.authorBiography = values.authorBiography
                editedTour.name = values.name;
                editedTour.teaser = values.teaser;
                editedTour.image = values.image;
                editedTour.duration = values.duration;

                this.saving = true;
                this.$emit("save", {
                    editedTour,
                    caller: this,
                    onSuccess: () => {
                        this.closeEditBox();
                    }
                });
            },

            tourNotYetCreated() {
                return !this.tour._id;
            },

            l
        }
    }
</script>
<style scoped>

    .block {
        position: relative;
    }

    .activeBlock .blockContent {
        z-index: 6;
    }

    .blockSelector {
        display: block;
        position: absolute;
        top: -1px;
        left: -1px;
        width: 482px;
        bottom: -1px;
        user-select: none;
        cursor: pointer;
        z-index: 2;
    }

    .blockSelector:hover {
        border: 2px solid;
        border-radius: 3px;
        background: rgba(204, 204, 204, 0.12);
    }

    .activeBlock .blockSelector {
        top: -2px !important;
        left: -2px !important;
        width: 484px !important;
        bottom: -2px !important;
        border: 3px solid !important;
        border-radius: 4px !important;
        z-index: 7 !important;
        background: none !important;
    }

</style>