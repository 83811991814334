<template>
    <div class="defaultContentView">
        <h1 class="display-1 mb-3 primary--text">
            <v-icon color="primary" class="titleIcon">
                mdi-home-variant-outline
            </v-icon>
            {{ l("Home Content") }}
        </h1>
        <HomeContentExhibitionList />
    </div>
</template>
<script>

    import { mapActions } from "vuex";
    import HomeContentExhibitionList from "../../components/homeContent/HomeContentExhibitionList.vue";
    import { l } from "../../utils/LocalizationUtils.js";

    export default {
        components: {
            HomeContentExhibitionList
        },
        created() {
            this.updateSectionTitle(this.l("Home Content"));
        },
        methods: {
            l,
            ...mapActions({
                updateSectionTitle: "platform/updateSectionTitle"
            })
        }
    }

</script>
