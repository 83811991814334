<template>
    <v-layout row justify-center>
        <v-dialog :value="true" persistent max-width="640px" scrollable>
            <v-card>
                <v-card-title class="pb-4">
                    <span class="headline primary--text">
                        <v-icon large color="primary" class="title-icon">
                            mdi-information-outline
                        </v-icon>
                        {{ l("Information") }}
                    </span>
                </v-card-title>
                <v-divider />
                <v-card-text ref="scrollArea" class="pt-4">
                    <v-container>
                        <v-row no-gutters class="py-3 body-2">
                            <v-col cols="4">
                                {{ l("ID") }}
                            </v-col>
                            <v-col>
                                {{ interactionModule.moduleId }}
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="py-3 body-2">
                            <v-col cols="4">
                                {{ l("Version Number") }}
                            </v-col>
                            <v-col>
                                {{ interactionModule.versionNumber }}
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="py-3 body-2">
                            <v-col cols="4">
                                {{ l("Title") }}
                            </v-col>
                            <v-col>
                                {{ interactionModule.title }}
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="py-3 body-2">
                            <v-col cols="4">
                                {{ l("Description") }}
                            </v-col>
                            <v-col>
                                {{ interactionModule.versionDescription }}
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="py-3 body-2">
                            <v-col cols="4">
                                {{ l("Parameters") }}
                            </v-col>
                            <v-col>
                                <ul>
                                    <li v-for="parameter in interactionModule.parameters" :key="parameter.key" class="pb-3">
                                        <span class="subtitle-2">
                                            {{ parameter.key }}
                                        </span>
                                        <ul>
                                            <li>{{ l("Type") + ": " + parameter.type }}</li>
                                            <li>{{ l("Label") + ": " + parameter.label }}</li>
                                            <li>{{ l("Required") + ": " + (parameter.required ? l("Yes") : l("No")) }}</li>
                                            <li>{{ l("Localized") + ": " + (parameter.localized ? l("Yes") : l("No")) }}</li>
                                        </ul>
                                    </li>
                                </ul>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" text min-width="80" @click="close">
                        {{ l("Close") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-layout>
</template>
<script>
    export default {
        props: {
            interactionModule: { type: Object, default: null },
        },
        methods: {
            close() {
                this.$emit("close");
            },
            l(token, values) {
                return this.$root.l(token, values);
            }
        }
    }
</script>
<style scoped>

    .title-icon {
        vertical-align: sub;
    }

    .id-box {
        background: #ddd;
        font-family: "Lucida Console", Monaco, monospace;
        padding: 5px;
        color: #111;
    }

</style>