<template>
    <div>
        <h2 class="title mb-3 primary--text">
            {{ l("Bash Scripts") }}
        </h2>
        <p v-for="(bashScript, index) in bashScripts" :key="index">
            {{ bashScript }}
            <v-btn color="primary" @click="callScript(bashScript)">
                {{ l("Call") }}
            </v-btn>
        </p>
        <p v-if="bashScripts.length == 0">
            <em>
                {{ l("No bash scripts configured.") }}
            </em>
        </p>
        <p>
            <strong>
                {{ l("Bash script list must be configured in these files:") }}
            </strong><br>
            &bull; &nbsp; &quot;frontend/src/components/bashScripts/BashScripts.vue&quot;<br>
            &bull; &nbsp; &quot;backend/src/routes/bash.js&quot;
        </p>
    </div>
</template>
<script>

    import { mapActions } from "vuex";
    import BashScriptService from "../../services/BashScriptService.js";
    export default {
        props: {
            reloadTrigger: { type: Number, default: 0 },
        },
        data() {
            return {
                bashScripts: [
                    //"mongorestore_before_vienna_cleanup",
                    //"mongorestore_after_vienna_cleanup"
                ]
            };
        },
        watch: {
            reloadTrigger() {
                this.update();
            }
        },
        methods: {
            update() {
                this.updateSectionTitle(this.l("Bash Scripts"));
            },
            callScript(scriptName) {
                BashScriptService.callBashScript(scriptName);
            },
            l(token, values) {
                return this.$root.l(token, values);
            },
            ...mapActions({
                updateSectionTitle: "platform/updateSectionTitle"
            })
        }
    }
</script>

