<template>
    <div>
        <v-container fluid class="pb-5 px-0 mx-0">
            <v-row align="center" no-gutters>
                <v-col cols="4">
                    <v-text-field v-model="search" append-icon="mdi-magnify" :label="l('Search')" single-line clearable hide-details />
                </v-col>
                <v-col>
                    <v-tooltip right>
                        <template #activator="{ on }">
                            <v-btn text icon color="primary" v-on="on" @click="toggleAdvancedSearch()">
                                <v-icon>mdi-tune</v-icon>
                            </v-btn>
                        </template>
                        <span class="body-2">{{ l("Advanced Search") }}</span>
                    </v-tooltip>
                </v-col>
                <v-spacer />
                <v-col class="text-right">
                    <AddButton
                        v-if="!usageLimitReached"
                        :label="l('Add Media')"
                        @click="add"
                    />
                    <v-alert v-if="usageLimitReached" type="error" dense>
                        {{ limitReachedWarning }}
                    </v-alert>
                </v-col>
            </v-row>
        </v-container>
        <div v-if="showAdvancedSearch">
            <v-container fluid class="pl-0 py-0">
                <v-row align="center" dense>
                    <v-col>
                        <v-select :items="getLocalizedOptions(searchFileTypeOptions)" :label="l('File type')" solo clearable dense @change="updateSearchFileType" />
                    </v-col>
                    <v-col>
                        <v-select :items="getLocalizedOptions(searchFileSizeOptions)" :label="l('File size')" solo clearable dense @change="updateSearchFileSize" />
                    </v-col>
                    <v-col>
                        <v-select :items="getLocalizedOptions(searchFileDateOptions)" :label="l('Date uploaded')" solo clearable dense @change="updateSearchFileDate" />
                    </v-col>
                    <v-spacer />
                </v-row>
            </v-container>
        </div>
        <v-data-table :headers="headers" :items="list" :sort-by="sortBy" :items-per-page="itemsPerPage" :footer-props="pagination" :loading="loading" :search="search" :no-data-text="l('No entries.')">
            <template #item="row">
                <tr>
                    <td class="clickable fixed-row-height text-center column-preview" @click="edit(row.item)">
                        <v-icon v-if="row.item.showPreview == 'icon'">
                            {{ row.item.previewIcon }}
                        </v-icon>
                        <img v-if="row.item.showPreview == 'image'" :src="row.item.previewImage" class="preview-image">
                    </td>
                    <td class="clickable fixed-row-height nobreak" @click="edit(row.item)">
                        {{ row.item.name }}
                    </td>
                    <td class="clickable fixed-row-height column-type nobreak" @click="edit(row.item)">
                        {{ row.item.fileType }}
                    </td>
                    <td class="clickable fixed-row-height column-size nobreak" @click="edit(row.item)">
                        {{ row.item.fancyFileSize }}
                    </td>
                    <td class="clickable fixed-row-height column-date nobreak" @click="edit(row.item)">
                        {{ row.item.uploadDate }}
                    </td>
                    <td class="clickable fixed-row-height column-license nobreak" @click="edit(row.item)">
                        <v-tooltip v-if="row.item.license" bottom>
                            <template #activator="{ on }">
                                <v-icon v-on="on">
                                    mdi-fountain-pen-tip
                                </v-icon>
                            </template>
                            <span class="body-2 preserveWhitespace">{{ row.item.license }}</span>
                        </v-tooltip>
                        <v-tooltip v-if="row.item.hasCopyright" bottom>
                            <template #activator="{ on }">
                                <v-icon v-on="on">
                                    mdi-copyright
                                </v-icon>
                            </template>
                            <span class="body-2">{{ row.item.copyrightLabel }}</span>
                        </v-tooltip>
                    </td>
                    <td class="text-right nobreak column-actions" style="width: 176px">
                        <v-tooltip v-if="isAllowedToDownload(row.item)" left>
                            <template #activator="{ on }">
                                <v-btn text icon color="primary" v-on="on" @click="downloadEntry(row.item)">
                                    <v-icon>save_alt</v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">{{ l("Download") }}</span>
                        </v-tooltip>
                        <v-tooltip left>
                            <template #activator="{ on }">
                                <v-btn text icon color="primary" v-on="on" @click="openInformation(row.item)">
                                    <v-icon>mdi-information-outline</v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">{{ l("Information") }}</span>
                        </v-tooltip>
                        <v-tooltip left>
                            <template #activator="{ on }">
                                <v-btn text icon color="primary" v-on="on" @click="editSettings(row.item)">
                                    <v-icon>settings</v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">{{ l("Edit Media Settings") }}</span>
                        </v-tooltip>
                        <v-tooltip v-if="isAllowedToDelete(row.item)" left>
                            <template #activator="{ on }">
                                <v-btn text icon color="primary" v-on="on" @click="deleteEntry(row.item)">
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">{{ l("Delete") }}</span>
                        </v-tooltip>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <MediaInformationBox v-if="showInformation" :media="showInformationOn" @close="closeInformation" />
        <v-container v-if="showUsageBar">
            <v-row align="center" justify="end" no-gutters>
                <v-spacer />
                <UsageBar metric-name="storage" mini />
            </v-row>
        </v-container>
    </div>
</template>
<script>

    import MediaService from "../../services/MediaService.js";
    import MediaInformationBox from "./MediaInformationBox.vue";
    import AddButton from "../globalUiElements/AddButton.vue";
    import UsageBar from "../../usage/components/UsageBar.vue";
    import ListUtils from "../../utils/ListUtils.js";
    import StringUtils, { isString } from "../../utils/StringUtils.js";
    import { hasLimit, isLimitReached } from "../../usage/utils/usageUtils.js";
    import { inContentLocale } from "../../locales/utils/localeUtils.js";
    import { l, localize, hasAnyTranslation } from "../../utils/LocalizationUtils.js";
    import { updateLookups } from "../../utils/referenceUtils.js";


    export default ListUtils.setupList({
        components: {
            AddButton,
            UsageBar,
            MediaInformationBox,
        },
        data() {
            return {
                completeList: [],
                showAdvancedSearch: false,
                searchFileType: null,
                searchFileTypeOptions: [
                    { text: "Image files", filter: item => item.fileType.substr(0, 5) === "image" },
                    { text: "Audio files", filter: item => item.fileType.substr(0, 5) === "audio" },
                    { text: "Video files", filter: item => item.fileType.substr(0, 5) === "video" },
                    { text: "Text files", filter: item => item.fileType.substr(0, 4) === "text" || item.fileType === "application/json" },
                    { text: "System files", filter: item => item.fileType.substr(0, 5) !== "image" && item.fileType.substr(0, 5) !== "audio" && item.fileType.substr(0, 5) !== "video" }
                ],
                searchFileSize: null,
                searchFileSizeOptions: [
                    { text: "< 100 KB", filter: item => item.fileSize < 102400 },
                    { text: "100 KB - 1 MB", filter: item => item.fileSize >= 102400 && item.fileSize < 1048576 },
                    { text: "1 MB - 10 MB", filter: item => item.fileSize >= 1048576 && item.fileSize < 10485760 },
                    { text: "> 10 MB", filter: item => item.fileSize >= 10485760 },
                ],
                searchFileDate: null,
                searchFileDateOptions: [
                    { text: "Today", filter: item => item.age < 1 },
                    { text: "Last 3 days", filter: item => item.age < 3 },
                    { text: "This week", filter: item => item.age < 7 },
                    { text: "This month", filter: item => item.age < 30 },
                    { text: "This year", filter: item => item.age < 365 }
                ],
                showInformation: false,
                showInformationOn: null
            };
        },
        headers: [
            { text: "", sortable: false },
            { text: "Name", value: "name" },
            { text: "File Type", value: "fileType" },
            { text: "File Size", value: "fileSize" },
            { text: "Upload Date", value: "uploadDate" },
            { text: "License", value: "license" },
            { text: "", sortable: false },
        ],
        sortBy: "name",
        async update() {
            this.showLoading();
            await updateLookups(["usage"]);
            MediaService.getMedias(list => {
                list.forEach(entry => {
                    entry.age = Math.abs((new Date()) - (new Date(entry.uploadDate))) / 86400000; // milliseconds per day
                    entry.fileOriginDate = StringUtils.niceDate(entry.fileOriginDate, this.l("Unknown"));
                    entry.uploadDate = StringUtils.niceDate(entry.uploadDate, this.l("Unknown"));
                    entry.fancyFileSize = StringUtils.niceFileSize(entry.fileSize);
                    entry.license = entry.license ? entry.license : "";
                    entry.hasCopyright = hasAnyTranslation(entry.copyright);
                    entry.copyrightLabel = this.getCopyrightLabel(entry.copyright);
                    entry.showPreview = "icon";

                    if (entry.fileTypeGroup === "image" || entry.fileTypeGroup === "svg") {
                        entry.showPreview = "image";
                        entry.previewImage = "/assets/" + entry._id + ".jpg?quality=veryLow&update=" + entry.fileId.substring(2, 8);    // '&update' is needed such that the thumbnail is updated when the referenced image changes

                    } else if (entry.fileTypeGroup === "audio") {
                        entry.previewIcon = "music_video";
                    } else if (entry.fileTypeGroup === "video") {
                        entry.previewIcon = "movie";
                    } else if (entry.fileTypeGroup === "json" || entry.fileTypeGroup === "xml") {
                        entry.previewIcon = "description";
                    } else {
                        entry.previewIcon = "insert_drive_file";
                    }
                    
                });
                this.completeList = list;
                this.list = [ ...list ];
                this.hideLoading();
            });
        },
        deleteSettings : {
            promptTitle: "Delete Media?",
            promptQuestion: "Are you sure you want to delete \"${v.name}\"?",
            action: MediaService.deleteMedia,
            success: "Media \"${v.name}\" deleted."
        },
        computed: {
            showUsageBar: () => hasLimit("storage"),
            usageLimitReached: () => isLimitReached("storage"),
            limitReachedWarning: () => l("Storage space limit reached"),
        },
        methods: {
            editSettings(entry) {
                this.$emit("editSettings", entry);
            },
            getLocalizedOptions(options) {
                const localizedOptions = [];
                options.forEach(option => {
                    localizedOptions.push({ text: this.l(option.text), value: option.text });
                })
                return localizedOptions;
            },
            updateSearchFileType(value) {
                this.searchFileType = value ? this.searchFileTypeOptions.find(item => item.text === value) : null;
                this.updateAdvancedSearch();
            },
            updateSearchFileSize(value) {
                this.searchFileSize = value ? this.searchFileSizeOptions.find(item => item.text === value) : null;
                this.updateAdvancedSearch();
            },
            updateSearchFileDate(value) {
                this.searchFileDate = value ? this.searchFileDateOptions.find(item => item.text === value) : null;
                this.updateAdvancedSearch();
            },
            updateAdvancedSearch() {
                let newList = [...this.completeList];
                if (this.searchFileType !== null) {
                    newList = newList.filter(this.searchFileType.filter);
                }
                if (this.searchFileSize !== null) {
                    newList = newList.filter(this.searchFileSize.filter);
                }
                if (this.searchFileDate !== null) {
                    newList = newList.filter(this.searchFileDate.filter);
                }
                this.list = newList;
            },
            toggleAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            isAllowedToDelete(file) {
                return true;    // TODO
            },
            isAllowedToDownload(file) {
                return true;    // TODO
            },
            openInformation(entry) {
                this.showInformation = true;
                this.showInformationOn = entry;
            },
            closeInformation() {
                this.showInformation = false;
                this.showInformationOn = null;
            },
            downloadEntry(file) {
                MediaService.downloadMedia(file._id);
            },
            getCopyrightLabel(copyright) {
                const localized = localize(inContentLocale(), copyright);
                if(isString(localized) && localized.trim() !== "") {
                    return localized;

                } else if(copyright && typeof copyright === "object") {
                    for(const translation of Object.values(copyright)) {
                        if(isString(translation) && translation.trim() !== "") {
                            return translation;
                        }
                    }
                }
                return "";
            },
            l,
        }
    });
</script>
<style scoped>

    .clickable {
        cursor: pointer;
    }

    .preview-image {
        display: block;
        max-width: 48px;
        max-height: 48px;
        margin: 0 auto;
    }

    .fixed-row-height {
        height: 60px !important;
    }

    .nobreak {
        white-space: nowrap;
    }

    .column-preview {
        width: 76px;
        padding-right: 0 !important;
    }

    .column-type {
        width: 12%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .column-size {
        width: 10%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .column-date {
        width: 12%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .column-license {
        width: 8%;
    }

    .column-actions {
        width: 20%;
    }

    .preserveWhitespace {
        white-space: pre-wrap;
    }

</style>