<template>
    <div>
        <div class="editBlockFormFields">
            <FormFields
                v-model="form.fields"
                :waiting-for-response="form.waitingForResponse"
                @scrollTo="scrollTo"
                @submit="submit"
            />
        </div>
        <div class="pt-3">
            <v-btn
                color="primary"
                text
                min-width="80"
                @click="cancel"
            >
                {{ l("Cancel") }}
            </v-btn>
            <v-btn
                type="submit"
                color="primary"
                text
                min-width="80"
                :disabled="!form.submitButtonEnabled || form.waitingForResponse"
                :loading="form.waitingForResponse"
                @click="submit"
            >
                {{ l("Save") }}
            </v-btn>
        </div>
    </div>
</template>
<script>

    import FormFields from "../globalUiElements/inputFields/FormFields.vue";
    import { l } from "../../utils/LocalizationUtils.js";


    export default {
        components: {
            FormFields
        },
        model: {
            prop: "propForm",
            event: "input"
        },
        props: {
            propForm: { type: Object, default: null },
        },
        computed: {
            form() {
                return this.propForm;
            }
        },
        watch: {
            form: {
                handler(form) {
                    form.onChange();
                },
                deep: true
            }
        },
        methods: {
            cancel() {
                this.form.cancel();
            },
            submit() {
                this.form.submit();
            },
            scrollTo(scrollTarget) {
                this.$vuetify.goTo(scrollTarget, {
                    duration: 300,
                    easing: "easeInOutCubic"
                });
            },
            l
        }
    }
</script>
<style scoped>

    .title-icon {
        vertical-align: sub;
    }

    .editBlockFormFields {
        padding: 0 15px;
    }

</style>