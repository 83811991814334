<template>
    <div class="referencePreviewContainer">
        <div
            :class="{ 'referencePreview': true, 'tile': tile, 'onlyIcon': onlyIcon, 'hoverForLargeImage': hoverForLargeImage }"
            :style="{ 'maxWidth': maxWidth + 'px'}"
        >
            <v-icon
                v-if="onlyIcon || !data.image"
                class="icon"
            >
                {{ data.icon }}
            </v-icon>
            <div
                v-if="!onlyIcon && data.image"
                class="image"
            >
                <div class="imageContainer">
                    <div class="imageTransform">
                        <img :src="data.image">
                    </div>
                </div>
            </div>
            <div class="details">
                <div class="subtitle-2">
                    {{ data.label }}
                </div>
                <div class="body-2">
                    <span v-if="data.typeLabel" class="font-italic">
                        {{ data.typeLabel }}
                    </span>
                    <span v-if="data.typeLabel && data.details">
                        –
                    </span>
                    <span v-if="data.details">
                        {{ data.details }}
                    </span>
                </div>
            </div>
            <v-icon
                v-if="appendIcon"
                class="appendedIcon"
            >
                {{ appendIcon }}
            </v-icon>
        </div>
    </div>
</template>
<script>

    import { getPreviewData, getPreviewDataFromId } from "../../utils/referencePreviewUtils.js";


    export default {
        props: {
            type: { type: String, default: "" },
            referencedId: { type: String, default: "" },
            referencedEntry: { type: Object, default: null },
            previewData: { type: Object, default: null },
            tile: { type: Boolean },
            onlyIcon: { type: Boolean },
            maxWidth: { type: String, default: "400" },
            hoverForLargeImage: { type: Boolean },
            appendIcon: { type: String, default: null },
        },
        computed: {
            data() {
                if(this.previewData) {
                    return this.previewData;
                } else if(this.referencedEntry) {
                    return getPreviewData(this.type, this.referencedEntry);
                } else if(this.referencedId !== "") {
                    return getPreviewDataFromId(this.type, this.referencedId);
                }
                return {};
            },
        },
    }
</script>
<style scoped>

    .referencePreviewContainer {
        position: relative;
    }

    .referencePreview {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 54px;
    }

    .referencePreview.tile {
        border-radius: 5px;
        background: #e0e0e0;
    }

    .referencePreview.onlyIcon {
        height: 48px;
    }

    .referencePreview .image,
    .referencePreview .icon {
        width: 54px;
        height: 54px;
        background: #d0d0d0;
        flex-grow: 0;
        flex-shrink: 0;
    }

    .referencePreview.onlyIcon .icon {
        width: 48px;
        height: 48px;
        border-radius: 5px;
    }

    .referencePreview.onlyIcon.tile .icon {
        border-radius: 0;
    }

    .referencePreview.tile .icon,
    .referencePreview.tile .image {
        background: #bbb;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .referencePreview .image {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .imageContainer {
        position: relative;
        width: 48px;
        height: 48px;
    }

    .imageTransform {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 7;
        top: 0;
        left: 0;
        width: 48px;
        height: 48px;
    }

    .referencePreview.hoverForLargeImage .imageTransform {
        transition: top 0.5s ease 0.2s, left 0.5s ease 0.2s, width 0.5s ease 0.2s, height 0.5s ease 0.2s;
    }

    .referencePreview.hoverForLargeImage .imageTransform:hover {
        z-index: 100;
        top: -16px;
        left: -16px;
        width: 80px;
        height: 80px;
    }

    .imageTransform img {
        max-width: 100%;
        max-height: 100%;
        box-shadow: rgba(0, 0, 0, 0.6) 0 1px 5px;
    }

    .referencePreview.hoverForLargeImage .imageTransform img {
        transition: box-shadow 0.5s ease 0.2s;
    }

    .referencePreview.hoverForLargeImage .imageTransform:hover img {
        box-shadow: rgba(0, 0, 0, 0.8) 0 1px 8px;
    }

    .referencePreview .details {
        flex-grow: 1;
        overflow: hidden;
        padding-left: 9px;
        padding-right: 8px;
    }

    .referencePreview .details div {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .referencePreview .appendedIcon {
        margin: 0 10px 0 4px;
    }
    
</style>