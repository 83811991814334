<template>
    <div class="defaultContentView">
        <h1 class="display-1 mb-3 primary--text">
            <v-icon color="primary" class="titleIcon">
                mdi-book-open-page-variant
            </v-icon>
            {{ l("Encyclopedia") }}
        </h1>
        <EncyclopediaCategoryList />
    </div>
</template>
<script>

    import { mapActions } from "vuex";
    import EncyclopediaCategoryList from "../../components/informationPages/EncyclopediaCategoryList.vue";
    import { l } from "../../utils/LocalizationUtils.js";

    export default {
        components: {
            EncyclopediaCategoryList
        },
        mounted() {
            this.updateSectionTitle(this.l("Encyclopedia"));
        },
        methods: {
            l,
            ...mapActions({
                updateSectionTitle: "platform/updateSectionTitle"
            })
        }
    }
</script>
