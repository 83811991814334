import store from "../store/index.js";
import { getParameterValue } from "./ParameterUtils.js";
import { deepClone } from "./objectUtils.js";
import { giveUniqueId } from "./ListUtils.js";
import { triggerError } from "./alerts.js";
import { isString } from "./StringUtils.js";
import { getAppLocales } from "../locales/utils/localeUtils.js";
import { l, backwardCompatibleL } from "./LocalizationUtils.js";


export function getDefaultValue(fieldType) {
    switch (fieldType) {
        case "Textarea": {
            return "";
        }
        case "Text": {
            return "";
        }
        case "Number": {
            return null;
        }
        case "Time": {
            return "";
        }
        case "Date": {
            return null;
        }
        case "Event": {
            return null;
        }
        case "Password": {
            return "";
        }
        case "Checkbox": {
            return false;
        }
        case "Switch": {
            return false;
        }
        case "Select": {
            return null;
        }
        case "FileUpload": {
            return null;
        }
        case "Reference": {
            return null;
        }
        case "LinkedContentSelector": {
            return null;
        }
        case "ImageAlignment": {
            return {};
        }
        case "ImageTransform": {
            return {};
        }
    }
    return null;
}


function parseVisibilityFunction(fieldDef) {

    if (fieldDef.visibility) {

        if (isString(fieldDef.visibility)) {
            return Function('"use strict"; return (' + fieldDef.visibility + ')')();

        } else if (typeof fieldDef.visibility === "function") {
            return fieldDef.visibility;

        }
    }
    return () => true;
}


function createArrayEntry(saveEntriesAsObjects, fieldDefs, values) {
    const entry = {
        saveEntriesAsObjects,
        fields: [],

        getValue() {
            if (this.fields.length === 1) {
                return this.fields[0].getValue();
            } else {
                if (this.saveEntriesAsObjects) {
                    const entry = {};
                    this.fields.forEach(field => {
                        if (field.key) {
                            entry[field.key] = field.getValue();
                        }
                    });
                    return entry;
                } else {
                    const entry = [];
                    this.fields.forEach(field => {
                        if (field.key) {
                            entry.push({
                                key: field.key,
                                value: field.getValue()
                            });
                        }
                    });
                    return entry;
                }
            }
        },

        assignError(error, depthInHierarchy, firstError) {
            if (error.path.length === depthInHierarchy && this.fields.length === 1) {
                return this.fields[0].assignError(error, depthInHierarchy, firstError);
            } else if (error.path.length > depthInHierarchy) {
                const field = this.fields.find(field => field.key === error.path[depthInHierarchy]);
                if (field) {
                    return field.assignError(error, depthInHierarchy + 1, firstError);
                }
            }
            return false;
        },

        hasError() {
            for (const field of this.fields) {
                if (field.key && field.hasError()) {
                    return true;
                }
            }
            return false;
        },

        clearErrors() {
            for (const field of this.fields) {
                if (field.key) {
                    field.clearErrors();
                }
            }
        },
    };

    fieldDefs.forEach(fieldDef => {

        if (fieldDef.type) {
            let value = null;
            if (fieldDefs.length === 1) {
                value = values;
            } else if (entry.saveEntriesAsObjects
                && typeof values === "object" && values !== null
                && values.hasOwnProperty(fieldDef.key)) {
                value = values[fieldDef.key];
            } else {
                value = getParameterValue(values, fieldDef.key);
            }
            const field = setupField(fieldDef, value, false);
            if (fieldDefs.length === 1) {
                field.inSingleFieldArrayEntry = true;
            } else {
                field.inMultipleFieldArrayEntry = true;
            }
            entry.fields.push(field);

        } else if (fieldDef.uiElement) {
            entry.fields.push(createUiElementFromFieldDef(fieldDef));
        }

    });

    giveUniqueId(entry);
    return entry;
}


function createFieldFromDef(fieldDef) {
    return {

        key: fieldDef.key,
        type: fieldDef.type,
        value: null,
        defaultValue: fieldDef.defaultValue ? fieldDef.defaultValue : false,
        richText: fieldDef.richText,

        isHidden: fieldDef.type === "Hidden",
        isArray: false,
        isLocalized: false,
        inSingleFieldArrayEntry: false,
        inMultipleFieldArrayEntry: false,

        label: fieldDef.label,
        required: fieldDef.required ? fieldDef.required : false,
        visibility: parseVisibilityFunction(fieldDef),

        passOtherParameters: fieldDef.passOtherParameters,

        error: false,
        errorMessages: [],
        scrollToMe: false,
        focusCallback: () => {},

        getDefaultValue() {
            const type = this.isLocalized ? this.languageFieldsType : this.type;
            return this.defaultValue ? this.defaultValue : getDefaultValue(type);
        },

        setValue(value) {
            this.value = (typeof value === "undefined") ? this.getDefaultValue() : value;
        },

        getValue() {
            return deepClone(this.value);
        },

        setError(error, firstError) {
            this.error = true;
            this.errorMessages = [l("Validation error: " + error.type)];
            this.scrollToMe = firstError || this.scrollToMe;
            return true;
        },

        assignError(error, depthInHierarchy, firstError) {
            if (error.path.length === depthInHierarchy) {
                return this.setError(error, firstError);
            }
            return false;
        },

        hasError() {
            return this.error;
        },

        clearErrors() {
            this.error = false;
            this.errorMessages = [];
            this.scrollToMe = false;
        },

        focus() {
            setTimeout(() => {
                this.focusCallback();
            }, 50);
        }
    };
}


function setupArrayField(field, fieldDef) {

    Object.assign(field, {

        isArray: true,
        saveEntriesAsObjects: fieldDef.saveEntriesAsObjects || false,

        entryLabel: fieldDef.entryLabel,
        entryFieldDefs: fieldDef.entryFieldDefs,
        entries: [],

        setValue(value) {
            if (!Array.isArray(value)) {
                if (typeof value !== "undefined" && value !== null) {
                    value = [value];
                } else {
                    value = [];
                }
            }
            value.forEach(entryValues => {
                this.entries.push(createArrayEntry(this.saveEntriesAsObjects, this.entryFieldDefs, entryValues));
            });
        },

        getValue() {
            const array = [];
            this.entries.forEach(entry => {
                array.push(entry.getValue());
            });
            return array;
        },

        assignError(error, depthInHierarchy, firstError) {
            if (error.path.length === depthInHierarchy) {
                return this.setError(error, firstError);
            } else if (error.path.length > depthInHierarchy) {
                let index = error.path[depthInHierarchy];
                if (index < this.entries.length) {
                    return this.entries[index].assignError(error, depthInHierarchy + 1, firstError);
                }
            }
            return false;
        },

        hasError() {
            if (this.error) {
                return true;
            }
            for (const entry of this.entries) {
                if (entry.hasError()) {
                    return true;
                }
            }
            return false;
        },

        clearErrors() {
            this.error = false;
            this.errorMessages = [];
            this.scrollToMe = false;
            for (const entry of this.entries) {
                entry.clearErrors();
            }
        },

        addEntry() {
            if (this.entryFieldDefs) {
                this.entries.push(createArrayEntry(this.saveEntriesAsObjects, this.entryFieldDefs, null));
            }
        },

        removeEntry(index) {
            this.entries.splice(index, 1);
        },

        moveUpEntry(index) {
            if (index !== 0) {
                const entry = this.entries[index];
                this.entries.splice(index, 1);
                this.entries.splice(index - 1, 0, entry);
            }
        },

        moveDownEntry(index) {
            if (index !== this.entries.length - 1) {
                const entry = this.entries[index];
                this.entries.splice(index, 1);
                this.entries.splice(index + 1, 0, entry);
            }
        }
    });
}


function setupLanguageField(field, fieldDef) {
    Object.assign(field, {
        label: null,
        visibility: () => true,
        isLanguageField: true
    });
}


function setupLocalizedField(field, fieldDef) {

    Object.assign(field, {

        type: "Localized",
        isLocalized: true,

        languageFieldsType: fieldDef.type,
        languages: [],

        setValue(value) {
            getAppLocales().forEach(language => {
                const languageValue = this.getLanguageValueFromLocalizedObject(value, language.ietfLanguageTag);
                field.languages.push({
                    language,
                    field: setupField(fieldDef, languageValue, true)
                });
            });
        },

        getValue() {
            const value = {};
            this.languages.forEach(entry => {
                value[entry.language.ietfLanguageTag] = entry.field.getValue();
            });
            return value;
        },

        getLanguageValueFromLocalizedObject(localizedObject, ietfLanguageTag) {
            if (typeof localizedObject !== "undefined") {
                if (localizedObject !== null && typeof localizedObject === "object") {
                    if (localizedObject.hasOwnProperty(ietfLanguageTag)) {
                        return localizedObject[ietfLanguageTag];
                    } else {
                        return getDefaultValue(this.languageFieldsType);
                    }
                } else {
                    return localizedObject;
                }
            }
            return getDefaultValue(this.languageFieldsType);
        },

        assignError(error, depthInHierarchy, firstError) {
            if (error.path.length === depthInHierarchy) {
                return this.setError(error, firstError);
            } else if (error.path.length > depthInHierarchy) {
                let ietfLanguageTag = error.path[depthInHierarchy];
                const language = getAppLocales().find(appLanguage => appLanguage.ietfLanguageTag === ietfLanguageTag);
                if (this.errorMessages.length === 0) {
                    this.error = true;
                    this.errorMessages = [l(
                        "Validation error for language ${v.language}: " + error.type,
                        {language: language ? language.name : ietfLanguageTag}
                    )];
                    this.scrollToMe = firstError || this.scrollToMe;
                }
                return true;
            }
            return false;
        },

        focus() {
            this.focusOnLanguage(0);
        },

        focusOnLanguage(index) {
            if (this.languages.length > index) {
                this.languages[index].field.focus();
            }
        }
    });
}


function setupFileUploadField(field, fieldDef) {
    Object.assign(field, {
        acceptedFileFormats: fieldDef.acceptedFileFormats ? fieldDef.acceptedFileFormats : {},
        existingFile: fieldDef.existingFile ? fieldDef.existingFile : null
    });
}


function setupReferenceField(field, fieldDef) {
    Object.assign(field, {
        referenceOf: fieldDef.referenceOf,
        referenceType: fieldDef.referenceType,
        pageTypes: fieldDef.pageTypes,
        allowCustomLabel: fieldDef.allowCustomLabel,
        allowCustomImage: fieldDef.allowCustomImage
    });
}


function setupSelectField(field, fieldDef) {
    const localizedOptions = [];
    if (Array.isArray(fieldDef.options)) {
        fieldDef.options.forEach(option => {
            if (option.text && option.value) {
                localizedOptions.push({ text: backwardCompatibleL(option.text), value: option.value });
            } else {
                localizedOptions.push(option);
            }
        });
    }
    Object.assign(field, {
        options: localizedOptions,
        showPoiIconHelp: fieldDef.showPoiIconHelp
    });
}


function setupEventField(field, fieldDef) {
    Object.assign(field, {
        labelOptions: fieldDef.labelOptions
    });
}


export function setupField(fieldDef, value, setupAsLanguageField) {

    const field = createFieldFromDef(fieldDef);

    if (fieldDef.localized && !field.isHidden) {
        if (setupAsLanguageField) {
            setupLanguageField(field, fieldDef);
        } else {
            setupLocalizedField(field, fieldDef);
        }
    }

    if (field.type === "Array") {
        setupArrayField(field, fieldDef);

    } else if (field.type === "FileUpload") {
        setupFileUploadField(field, fieldDef);

    } else if (field.type === "Reference") {
        setupReferenceField(field, fieldDef);

    } else if (field.type === "Select") {
        setupSelectField(field, fieldDef);

    } else if (field.type === "Event") {
        setupEventField(field, fieldDef);
    }

    field.setValue(value);

    return field;
}


function createUiElementFromFieldDef(fieldDef) {
    return {
        uiElement: fieldDef.uiElement,
        text: fieldDef.text,
        icon: fieldDef.icon,
        label: fieldDef.label,
        value: fieldDef.value,
        items: fieldDef.items,
    };
}


function passOtherParameters(fields) {

    fields.forEach(field => {
        if (Array.isArray(field.passOtherParameters)) {

            field.otherParameters = [];

            field.passOtherParameters.forEach(otherParameterKey => {
                const otherParameter = fields.find(otherParameter => otherParameter.key === otherParameterKey);
                if (otherParameter) {
                    field.otherParameters[otherParameterKey] = otherParameter;
                }
            });
        }
    });
}


function focusOnFirstInput(fields) {
    for (const field of fields) {
        if (field.key && !field.isHidden) {
            field.focus();
            return;
        }
    }
}


function setupFields(fieldDefs) {

    const fields = [];

    fieldDefs.forEach(fieldDef => {

        if (fieldDef.type) {
            const field = setupField(fieldDef, fieldDef.value, false);

            fields.push(field);

        } else if (fieldDef.uiElement) {
            fields.push(createUiElementFromFieldDef(fieldDef));
        }

    });

    passOtherParameters(fields);
    focusOnFirstInput(fields);

    return fields;
}


export function createForm(formDef) {
    return {
        title: formDef.title ? formDef.title : "",
        action: formDef.action,
        fields: setupFields(formDef.fieldDefs),

        hasChanges: false,

        submitButtonEnabled: false,
        submitLabel: formDef.submitLabel ? formDef.submitLabel : "Submit",
        submitPreprocessor: formDef.submitPreprocessor,
        submitted: formDef.submitted,
        onSubmit: formDef.onSubmit,
        waitingForResponse: false,

        cancelLabel: formDef.cancelLabel ? formDef.cancelLabel : "Cancel",
        cancelled: formDef.cancelled,

        onChange() {
            this.hasChanges = true;
            this.submitButtonEnabled = !this.hasErrors(this);
        },

        async submit() {

            if (!this.waitingForResponse) {

                if (this.submitButtonEnabled && this.submitPreprocessor) {
                    this.submitButtonEnabled = this.submitPreprocessor(this);
                }

                if (this.submitButtonEnabled) {
                    this.waitingForResponse = true;

                    this.clearErrors();

                    const newObject = {};
                    this.fields.forEach(field => {
                        if (field.key) {
                            newObject[field.key] = field.getValue();
                        }
                    });

                    if (this.onSubmit) {
                        this.onSubmit(newObject);
                    }

                    if (this.action) {

                        const responseHandler = response => {
                            this.waitingForResponse = false;

                            if (response.errors && response.errors.length > 0) {
                                this.assignErrors(response.errors)
                            } else if (this.submitted) {
                                this.submitted(response);
                            }
                        };

                        const action = this.action(newObject, responseHandler);

                        if (action && action.then) { // is Promise
                            const response = await action;
                            responseHandler(response);
                        }
                    }
                }
            }
        },

        cancel() {
            if (this.cancelled) {
                this.cancelled();
            }
        },

        assignErrors(errors) {

            let firstError = true;
            errors.forEach(error => {

                let couldAssignError = false;

                const field = this.fields.find(field => field.key === error.path[0]);
                if (field) {
                    couldAssignError = field.assignError(error, 1, firstError);
                }

                if (couldAssignError) {
                    firstError = false;
                } else {
                    triggerError(l("Validation error: " + error.message));
                }
            });
        },

        hasErrors() {
            for (const field of this.fields) {
                if (field.key && field.hasError()) {
                    return true;
                }
            }
            return false;
        },

        clearErrors() {
            for (const field of this.fields) {
                if (field.key) {
                    field.clearErrors();
                }
            }
        }
    }
}