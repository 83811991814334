<template>
    <div class="defaultContentView">
        <h1 class="display-1 mb-3 primary--text">
            {{ l("Error 403") }}
        </h1>
        <p>
            {{ l("You are not permitted to access this page.") }}
        </p>
    </div>
</template>
<script>

    import { mapActions } from "vuex";


    export default {
        mounted() {
            this.updateSectionTitle(this.l("Error 403"));
        },
        methods: {
            l(token, values) {
                return this.$root.l(token, values);
            },
            ...mapActions({
                updateSectionTitle: "platform/updateSectionTitle"
            })
        }
    }
</script>
