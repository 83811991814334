<template>
    <EncyclopediaHeader 
        :image="image"
        :name="name"
        :data-table="dataTable"
    />
</template>
<script>

    import EncyclopediaHeader from "./EncyclopediaHeader.vue";
    import { generateArtists, generateEvents, generateCategories, generateDataTableRowFromSinglePage } from "../../../utils/headerPreviewUtils.js";
    import { inPreviewLocale } from '../../../locales/utils/localeUtils';
    import { l } from "../../../localization/localization.js";


    export default {
        components: {
            EncyclopediaHeader
        },
        props: {
            values: { type: Object, default: () => { } }
        },
        computed: {
            image() {
                return this.values.image;
            },
            name() {
                return this.values.name;
            },
            dataTable() {
                return [
                    ...generateArtists(this.values.artists),
                    {
                        label: l.app.pages.artworks.title(inPreviewLocale()),
                        value: this.values.name
                    },
                    {
                        label: l.app.pages.artworks.date(),
                        value: this.values.year, // TODO: in the app this value is actually overriden by a "Creation" event, though this is also a sub-optimal solution, let's find a solution for the new design
                    },
                    {
                        label: l.app.pages.artworks.medium(inPreviewLocale()),
                        value: this.values.medium,
                    },
                    {
                        label: l.app.pages.artworks.dimensions(inPreviewLocale()),
                        value: this.values.dimensions,
                    },
                    {
                        label: l.app.pages.artworks.credits(inPreviewLocale()),
                        value: this.values.credits,
                    },
                    {
                        label: l.app.pages.artworks.catalogueNumberAbr(inPreviewLocale()),
                        value: this.values.number,
                    },
                    ...generateDataTableRowFromSinglePage(l.app.pages.artworks.acquirer(inPreviewLocale()), this.values.acquirer),
                    ...generateEvents(this.values.events),
                    ...generateCategories(this.values.categories),
                ];
            },
        },
    }
</script>