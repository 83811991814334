
import { l, tryGetTokenOverride, selectLanguage } from "./localization.js";


// Contains general translations
export const datetime = {


    
    january(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "january");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "de-AT", "fr"]);

        switch (language) {
            case "en": return "January";
            case "de": return "Januar";
            case "de-AT": return "Jänner";
            case "fr": return "janvier";
            default: return `[january: ${language} missing]`;
        }
    
    },
            

    
    february(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "february");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return "February";
            case "de": return "Februar";
            case "fr": return "février";
            default: return `[february: ${language} missing]`;
        }
    
    },
            

    
    march(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "march");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return "March";
            case "de": return "März";
            case "fr": return "mars";
            default: return `[march: ${language} missing]`;
        }
    
    },
            

    
    april(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "april");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return "April";
            case "de": return "April";
            case "fr": return "avril";
            default: return `[april: ${language} missing]`;
        }
    
    },
            

    
    may(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "may");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return "May";
            case "de": return "Mai";
            case "fr": return "mai";
            default: return `[may: ${language} missing]`;
        }
    
    },
            

    
    june(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "june");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return "June";
            case "de": return "Juni";
            case "fr": return "juin";
            default: return `[june: ${language} missing]`;
        }
    
    },
            

    
    july(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "july");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return "July";
            case "de": return "Juli";
            case "fr": return "juillet";
            default: return `[july: ${language} missing]`;
        }
    
    },
            

    
    august(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "august");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return "August";
            case "de": return "August";
            case "fr": return "août";
            default: return `[august: ${language} missing]`;
        }
    
    },
            

    
    september(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "september");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return "September";
            case "de": return "September";
            case "fr": return "septembre";
            default: return `[september: ${language} missing]`;
        }
    
    },
            

    
    october(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "october");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return "October";
            case "de": return "Oktober";
            case "fr": return "octobre";
            default: return `[october: ${language} missing]`;
        }
    
    },
            

    
    november(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "november");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return "November";
            case "de": return "November";
            case "fr": return "novembre";
            default: return `[november: ${language} missing]`;
        }
    
    },
            

    
    december(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "december");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return "December";
            case "de": return "Dezember";
            case "fr": return "décembre";
            default: return `[december: ${language} missing]`;
        }
    
    },
            

    // Get the month as string
    month(monthValue, locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "month");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        switch(monthValue) {
            case 1: return l.general.datetime.january(locale);
            case 2: return l.general.datetime.february(locale);
            case 3: return l.general.datetime.march(locale);
            case 4: return l.general.datetime.april(locale);
            case 5: return l.general.datetime.may(locale);
            case 6: return l.general.datetime.june(locale);
            case 7: return l.general.datetime.july(locale);
            case 8: return l.general.datetime.august(locale);
            case 9: return l.general.datetime.september(locale);
            case 10: return l.general.datetime.october(locale);
            case 11: return l.general.datetime.november(locale);
            case 12: return l.general.datetime.december(locale);
            default: return `[${monthValue} not supported]`;
        }
            
    },
            

    
    dateString(year, month, day, locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "dateString");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return month + " " + day + ", " + year;
            case "de": return day + ". " + month + " " + year;
            case "fr": return day + " " + month + " " + year;
            default: return `[dateString: ${language} missing]`;
        }
    
    },
            

    
    monthAndYear(year, month, locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "monthAndYear");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return month + " " + year;
            case "de": return month + " " + year;
            case "fr": return month + " " + year;
            default: return `[monthAndYear: ${language} missing]`;
        }
    
    },
            

    
    yearWithEra(year, era, locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "yearWithEra");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": 
        switch(era) {
            case "BC": return year + " BC";
            case "AD": return "AD " + year;
            default: return year;
        }
            
            case "de": 
        switch(era) {
            case "BC": return year + " v. Chr.";
            case "AD": return year + " n. Chr.";
            default: return year;
        }
            
            case "fr": 
        switch(era) {
            case "BC": return year + " av. J.-C.";
            case "AD": return year + " ap. J.-C.";
            default: return year;
        }
            
            default: return `[yearWithEra: ${language} missing]`;
        }
    
    },
            

    
    circaDate(date, locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "circaDate");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "c. " + date;
            case "de": return "ca. " + date;
            default: return `[circaDate: ${language} missing]`;
        }
    
    },
            

    
    beforeDate(date, locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "beforeDate");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "before " + date;
            case "de": return "vor " + date;
            default: return `[beforeDate: ${language} missing]`;
        }
    
    },
            

    
    afterDate(date, locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "afterDate");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "after " + date;
            case "de": return "nach " + date;
            default: return `[afterDate: ${language} missing]`;
        }
    
    },
            

    
    invalidDate(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "invalidDate");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Invalid date";
            case "de": return "Ungültiges Datum";
            default: return `[invalidDate: ${language} missing]`;
        }
    
    },
            
};
