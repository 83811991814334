<template>
    <div>
        <v-data-table hide-default-headers hide-default-footer :headers="headers" :items="list" :sort-by="sortBy" :items-per-page="itemsPerPage" :loading="loading" :no-data-text="l('No entries.')">
            <template #item="row">
                <tr>
                    <td class="clickable settingsKey" @click="edit(row.item)">
                        {{ row.item.key }}
                    </td>
                    <td class="clickable settingsValue" @click="edit(row.item)">
                        <span>{{ row.item.value }}</span>
                    </td>
                    <td class="text-right settingsDelete">
                        <v-tooltip left>
                            <template #activator="{ on }">
                                <v-btn text icon color="primary" v-on="on" @click="deleteEntry(row.item)">
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span class="body-2">{{ l("Delete") }}</span>
                        </v-tooltip>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <v-layout justify-center class="mt-5 mb-5">
            <v-btn color="primary" class="pl-3" @click="add">
                <v-icon class="mr-2">
                    add
                </v-icon>
                {{ l("Create New App Setting") }}
            </v-btn>
        </v-layout>
    </div>
</template>
<script>

    import { mapActions } from "vuex";
    import SettingService from "../../services/AppSettingService.js";
    import ListUtils from "../../utils/ListUtils.js";

    export default ListUtils.setupList({
        headers: [
            { text: "Key", value: "key" },
            { text: "Value", value: "value" },
            { text: "", sortable: false }
        ],
        sortBy: "key",
        update() {
            this.updateSectionTitle(this.l("App Settings"));
            this.showLoading();
            SettingService.getSettings(list => {
                this.list = list;
                this.hideLoading();
            });
        },
        deleteSettings : {
            promptTitle: "Delete App Setting?",
            promptQuestion: "Are you sure you want to delete \"${v.key}\"?",
            action: SettingService.deleteSetting,
            success: "App setting \"${v.key}\" deleted."
        },
        itemsPerPage: -1,
        methods: {
            ...mapActions({
                updateSectionTitle: "platform/updateSectionTitle"
            })
        }
    });
</script>
<style scoped>

    .clickable {
        cursor: pointer;
    }

    .settingsKey {
        min-width: 280px;
        font-family: "Lucida Console", Monaco, monospace;
        font-size: 13px !important;
        vertical-align: top;
        padding: 14px 0 14px 16px !important;
    }

    .settingsValue {
        padding: 8px 0 8px 16px !important;
        vertical-align: middle;
    }

    .settingsValue span {
        display: block;
        background: #e3e3e3;
        padding: 2px 5px;
        font-family: "Lucida Console", Monaco, monospace;
        font-size: 13px !important;
        white-space: pre-wrap;
        width: fit-content;
        max-height: 160px;
        overflow: auto;
    }

    .settingsDelete {
        vertical-align: top;
        padding: 6px 8px !important;
    }

</style>