import * as networkService from "../../services/networkService.js";


const url = "/api/usage/";


export const get = () => networkService.get({
    url,
});


export const getCurrentUse = () => networkService.get({
    url: `${url}currentUse`,
});


export const update = usage => networkService.update({
    url,
    entry: usage,
});