export default {


    namespaced: true,


    state: {
        alerts: []
    },


    mutations: {

        pushAlert(state, alert) {
            state.alerts.push(alert);
        },

        removeAlert(state, alert) {
            state.alerts.splice(state.alerts.findIndex(tryAlert => tryAlert === alert), 1);
        }

    },


    actions: {

        triggerError({ commit }, message) {
            commit("pushAlert", {
                type: "error",
                message: message,
                timer: 7.0,
                class: ""
            });
        },

        triggerWarning({ commit }, message) {
            commit("pushAlert", {
                type: "warning",
                message: message,
                timer: 7.0,
                class: ""
            });
        },

        triggerSuccess({ commit }, message) {
            commit("pushAlert", {
                type: "success",
                message: message,
                timer: 3.0,
                class: ""
            });
        },

        triggerInfo({ commit }, message) {
            commit("pushAlert", {
                type: "info",
                message: message,
                timer: 5.0,
                class: ""
            });
        },

        updateAlerts({ state, commit }) {
            
            const alerts = [...state.alerts];
            alerts.forEach(alert => {
                alert.timer -= 0.25;
                if (alert.timer <= 0.0) {
                    commit("removeAlert", alert);
                } else if (alert.timer <= 2.0) {
                    alert.class = "fade-out"
                }
            });           
        },

    },


    getters: {

        getAlerts(state) {
            return state.alerts;
        }

    }

}