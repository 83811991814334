<template>
    <v-navigation-drawer v-model="isOpen" app clipped>
        <v-list>
            <template v-for="item, index in menu">
                <v-list-item
                    v-if="!item.subMenu && item.visibility"
                    :key="'item' + index"
                    :to="item.link"
                    active-class="primary--text"
                    @click="item.onClick ? item.onClick() : () => {}"
                >
                    <v-list-item-action class="listItemAction">
                        <v-icon>
                            {{ item.icon }}
                        </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title class="subheading">
                            {{ item.title }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-group
                    v-if="item.subMenu && item.visibility"
                    :key="'item' + index"
                >
                    <template #activator>
                        <v-list-item-action class="listItemAction">
                            <v-icon>
                                {{ item.icon }}
                            </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title class="subheading">
                                {{ item.title }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <template v-for="subItem, subIndex in item.subMenu">
                        <v-list-item
                            v-if="subItem.visibility"
                            :key="'subItem' + subIndex"
                            :to="subItem.link"
                            active-class="primary--text"
                            class="subListItem"
                            @click="subItem.onClick ? subItem.onClick() : () => {}"
                        >
                            <v-list-item-action class="subListItemAction">
                                <v-icon>
                                    {{ subItem.icon }}
                                </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title class="subheading">
                                    {{ subItem.title }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list-group>
                <v-divider
                    v-if="item.appendDivider"
                    :key="index + 'divider'"
                />
            </template>
        </v-list>
    </v-navigation-drawer>
</template>
<script>

    import { mapGetters } from "vuex";
    import { l } from "../../localization/localization.js";
    import { backwardCompatibleL } from "../../utils/LocalizationUtils.js";
    import { isUsageVisible } from "../../usage/utils/usageUtils.js";


    export default {
        data() {
            return {
                isOpen: true,
            };
        },
        computed: {
            ...mapGetters({
                hasExhibitionsAccessRights: "user/hasExhibitionsAccessRights",
                hasARContentAccessRights: "user/hasARContentAccessRights",
                hasToursAccessRights: "user/hasToursAccessRights",
                hasTopicsAccessRights: "user/hasTopicsAccessRights",
                hasHomeContentAccessRights: "user/hasHomeContentAccessRights",
                hasEncyclopediaAccessRights: "user/hasEncyclopediaAccessRights",
                hasMediaAccessRights: "user/hasMediaAccessRights",
                hasAnalyticsAccessRights: "user/hasAnalyticsAccessRights",
                hasUsersAccessRights: "user/hasUsersAccessRights",
                hasSystemAccessRights: "user/hasSystemAccessRights",
            }),
            menu() {
                return [
                    {
                        title: backwardCompatibleL("Exhibitions"),
                        icon: "mdi-bank",
                        link: "/exhibitions/",
                        visibility: this.hasExhibitionsAccessRights,
                    },
                    {
                        title: backwardCompatibleL("Floorplan"),
                        icon: "mdi-floor-plan",
                        visibility: this.hasExhibitionsAccessRights,
                        subMenu: [
                            {
                                title: backwardCompatibleL("Building"),
                                icon: "mdi-domain",
                                link: "/floorplan/buildings/",
                                visibility: this.hasExhibitionsAccessRights,
                            },
                            {
                                title: backwardCompatibleL("Locations"),
                                icon: "mdi-map-marker-multiple",
                                link: "/floorplan/locations/",
                                visibility: this.hasExhibitionsAccessRights,
                            },
                            {
                                title: backwardCompatibleL("Navigation"),
                                icon: "mdi-view-list",
                                link: "/floorplan/navigation/",
                                visibility: false, //this.hasExhibitionsAccessRights,
                            },
                        ],
                    },
                    {
                        title: backwardCompatibleL("AR Content"),
                        icon: "mdi-image-multiple-outline",
                        link: "/arContent/",
                        onClick: this.arContentSideBarButtonClicked,
                        visibility: this.hasARContentAccessRights,
                    },
                    {
                        title: backwardCompatibleL("Tours"),
                        icon: "mdi-sign-direction",
                        link: "/tours/",
                        visibility: this.hasToursAccessRights,
                    },
                    {
                        title: backwardCompatibleL("Topics"),
                        icon: "mdi-bookshelf",
                        link: "/topics/",
                        visibility: this.hasTopicsAccessRights,
                    },
                    {
                        title: backwardCompatibleL("Home Content"),
                        icon: "mdi-home-variant-outline",
                        link: "/home-content/",
                        visibility: this.hasHomeContentAccessRights,
                    },
                    {
                        title: backwardCompatibleL("Encyclopedia"),
                        icon: "mdi-book-open-page-variant",
                        link: "/encyclopedia/",
                        visibility: this.hasEncyclopediaAccessRights,
                    },
                    {
                        title: backwardCompatibleL("Media"),
                        icon: "mdi-folder-multiple-image",
                        link: "/media/",
                        visibility: this.hasMediaAccessRights,
                        appendDivider: this.hasMediaAccessRights,
                    },
                    {
                        title: backwardCompatibleL("Analytics"),
                        icon: "mdi-chart-bar",
                        link: "/analytics/",
                        visibility: this.hasAnalyticsAccessRights,
                    },
                    {
                        title: l.cms.usage.pageTitle(),
                        icon: "mdi-gauge-full",
                        link: "/usage/",
                        visibility: isUsageVisible(),
                    },
                    {
                        title: backwardCompatibleL("Users"),
                        icon: "mdi-account-supervisor",
                        link: "/users/",
                        visibility: this.hasUsersAccessRights,
                    },
                    {
                        title: backwardCompatibleL("System"),
                        icon: "mdi-cog",
                        link: "/system/",
                        visibility: this.hasSystemAccessRights,
                        appendDivider: true,
                    },
                    {
                        title: backwardCompatibleL("Help"),
                        icon: "mdi-help-circle-outline",
                        link: "/help/",
                        visibility: true,
                    },
                ]
            }
        },
        mounted() {
            this.$root.$on("toggleSideBar", () => {
                this.isOpen = !this.isOpen;
            });
        },
        methods: {
            arContentSideBarButtonClicked() {
                this.$root.$emit("arContentSideBarButtonClicked");
            },
        }
    }
</script>
<style scoped>

    .subListItem {
        min-height: 40px !important;
        height: 40px !important;
    }

    .subListItem:last-of-type {
        margin-bottom: 16px;
    }

    .listItemAction, .subListItemAction {
        margin-right: 20px !important;
    }

    .subListItemAction {
        margin-left: 24px;
    }

</style>
