<template>
    <div :class="containerClass + (field.required ? ' required' : '')">
        <v-subheader v-if="label && !field.error && !field.inSingleFieldArrayEntry" class="manual-label label">
            {{ label }}
        </v-subheader>
        <v-subheader v-if="label && field.error && !field.inSingleFieldArrayEntry" style="color:red" class="manual-label label">
            {{ label }}
        </v-subheader>
        <p v-if="field.errorMessages && field.errorMessages.length > 0" style="color:red; margin: 8px 0 0 0; font-size:smaller;">
            {{ field.errorMessages[0] }}
        </p>
        <v-tabs v-model="currentLanguageIndex" color="primary" :background-color="tabsBackgroundColor" @change="focusOnInput">
            <template v-for="(entry, index) in field.languages">
                <v-tab v-if="entry.language.showInCMS" :key="index">
                    {{ entry.language.name }}
                </v-tab>
            </template>
            <template v-for="(entry, index) in field.languages">
                <v-tab-item v-if="entry.language.showInCMS" :key="index" transition="false" reverse-transition="false">
                    <InputField
                        v-model="field.languages[index].field"
                        :waiting-for-response="waitingForResponse"
                        @scrollTo="scrollTo"
                        @submitOnReturn="submitOnReturn"
                        @clearErrors="clearErrors"
                    />
                </v-tab-item>
            </template>
        </v-tabs>
    </div>
</template>
<script>

    import { backwardCompatibleL } from "../../../utils/LocalizationUtils.js";


    export default {
        model: {
            prop: "propField",
            event: "input"
        },
        props: {
            propField: { type: Object, default: null },
            waitingForResponse: { type: Boolean, default: false }
        },
        data() {
            return {
                currentLanguageIndex: 0
            };
        },
        computed: {
            field() {
                return this.propField;
            },
            label() {
                return this.field.label ? backwardCompatibleL(this.field.label) : null;
            },
            tabsBackgroundColor() {
                if (this.field.inSingleFieldArrayEntry) {
                    return "#ffffff";
                } else {
                    return "#f3f3f3";
                }
            },
            containerClass() {
                if (this.field.inSingleFieldArrayEntry) {
                    return "";
                } else if (!this.label) {
                    return "localized-input-for-custom-label";
                } else if (["Number", "Password", "Select"].includes(this.field.languageFieldsType)) {
                    return "localized-narrow-input pa-3 my-4";
                } else {
                    return "localized-input pa-3 my-4";
                }
            }
        },
        watch: {
            "field.error": function () {
                this.field.languages.forEach((entry, index) => {
                    if (this.field.errorMessages.length > 0 && this.field.errorMessages[0].includes(entry.language.name)) {
                        this.currentLanguageIndex = index;
                        this.focusOnInput(index);
                    }
                });
            }
        },
        beforeCreate() { // Delayed InputField import, to prevent recursion of imports
            this.$options.components.InputField = require('./InputField.vue').default;
        },
        methods: {
            focusOnInput(index) {
                this.field.focusOnLanguage(index);
            },
            submitOnReturn(event) {
                this.$emit("submitOnReturn", event);
            },
            scrollTo(scrollTarget) {
                this.$emit("scrollTo", scrollTarget);
            },
            clearErrors() {
                this.field.clearErrors();
            },
        }
    }
</script>
<style scoped>

    .localized-input {
        background: #f3f3f3;
    }

    .localized-narrow-input {
        background: #f3f3f3;
        max-width: 500px;
    }

    .manual-label {
        font-size: 16px;
        font-weight: 400;
        height: 18px;
        padding: 0;
        margin: 0;
    }

</style>