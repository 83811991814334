<template>
    <v-dialog :value="true" persistent max-width="900px" scrollable>
        <v-card>
            <v-card-title class="pb-4">
                <span class="headline primary--text">
                    <v-icon v-if="titleIcon" large color="primary" class="title-icon">
                        {{ titleIcon }}
                    </v-icon>
                    {{ title }}
                </span>
            </v-card-title>
            <v-divider />
            <v-card-text ref="scrollArea" class="pt-4">
                <FormFields
                    v-model="form.fields"
                    :waiting-for-response="form.waitingForResponse"
                    @scrollTo="scrollTo"
                    @submit="submit"
                />
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer />
                <v-btn
                    color="primary"
                    text
                    min-width="80"
                    @click="cancel"
                >
                    {{ cancelLabel }}
                </v-btn>
                <v-btn
                    type="submit"
                    color="primary"
                    text
                    min-width="80"
                    :disabled="!form.submitButtonEnabled || form.waitingForResponse"
                    :loading="form.waitingForResponse"
                    @click="submit"
                >
                    {{ submitLabel }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    
    import FormFields from "./inputFields/FormFields.vue";
    import { backwardCompatibleL } from "../../utils/LocalizationUtils.js";


    export default {
        components: {
            FormFields
        },
        model: {
            prop: "propForm",
            event: "input"
        },
        props: {
            propForm: { type: Object, default: null },
        },
        computed: {
            form() {
                return this.propForm;
            },
            title() {
                if (this.form.title) {
                    if (this.form.title.label) {
                        return backwardCompatibleL(this.form.title.label, this.form.title.entry);
                    } else {
                        return backwardCompatibleL(this.form.title);
                    }
                }
                return "";
            },
            titleIcon() {
                return (this.form.title && this.form.title.icon) ? this.form.title.icon : null;
            },
            submitLabel() {
                return this.form.submitLabel ? backwardCompatibleL(this.form.submitLabel) : "";
            },
            cancelLabel() {
                return this.form.cancelLabel ? backwardCompatibleL(this.form.cancelLabel) : "";
            }
        },
        watch: {
            form: {
                handler(form) {
                    form.onChange();
                    this.$emit("input", form);
                },
                deep: true
            }
        },
        methods: {
            cancel() {
                this.form.cancel();
            },
            submit() {
                this.form.submit();
            },
            scrollTo(scrollTarget) {
                if (scrollTarget) {
                    this.$vuetify.goTo(scrollTarget, {
                        duration: 300,
                        easing: "easeInOutCubic",
                        container: this.$refs.scrollArea //scrollable <div> within dialog element
                    });
                }
            }
        }
    }
</script>
<style scoped>

    .title-icon {
        vertical-align: sub;
    }

</style>