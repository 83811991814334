<template>
    <WindowedForm v-model="form" />
</template>
<script>
    
    import SettingService from "../../services/AppSettingService.js";
    import WindowedForm from "../globalUiElements/WindowedForm.vue";
    import { createForm } from "../../utils/FormUtils.js";
    import { l } from "../../utils/LocalizationUtils.js";


    export default {
        components: {
            WindowedForm
        },
        data() {
            return {
                form: createForm({

                    title: "Create New App Setting",

                    action: SettingService.insertSetting,

                    fieldDefs: [
                        {
                            key: "key",
                            type: "Text",
                            label: "Key",
                            required: true
                        },
                        {
                            key: "value",
                            type: "Textarea",
                            label: "Value"
                        }
                    ],

                    submitLabel: "Create",

                    submitted: entry => {
                        this.$root.$emit("success", l("App setting \"${v.key}\" created.", entry));
                        this.$emit("createdEntry");
                    },

                    cancelled: () => {
                        this.$emit("cancel");
                    }
                })
            }
        }
    }
</script>