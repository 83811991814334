import axios from "axios";
import { triggerError } from "../utils/alerts.js";


const url = "/api/floors/";
const urlByExhibition = "/api/floors/byExhibition/";


class FloorService {

    // Get
    static getFloor(id, callback) {
        axios.get(`${url}${id}`).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }

    // Get all
    static getFloors(exhibitionId, callback) {
        if (callback) {
            axios.get(`${urlByExhibition}${exhibitionId}`).then(response => {
                callback(response.data);
            }).catch(error => {
                triggerError(error);
            });
        } else {
            return new Promise(async resolve => {
                try {
                    const response = await axios.get(`${ urlByExhibition }${ exhibitionId }`);
                    resolve(response.data);
                } catch (error) {
                    triggerError(error);
                    resolve([]);
                }
            });
        }
    }

    // Post
    static insertFloor(floor, callback) {
        axios.post(url, floor).then(response => {
            callback(response.data);
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.isJoi) {
                callback({ errors: error.response.data.details });
            } else {
                triggerError(error);
            }
        });
    }

    // Put
    static updateFloor(floor, callback) {
        axios.put(`${url}${floor._id}`, floor).then(response => {
            response.data._id = floor._id;
            callback(response.data);
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.isJoi) {
                callback({ errors: error.response.data.details });
            } else {
                triggerError(error);
            }
        });
    }

    // Delete
    static deleteFloor(floorId, callback) {
        axios.delete(`${url}${floorId}`).then(response => {
            callback(response.data);
        }).catch(error => {
            triggerError(error);
        });
    }

}

export default FloorService;