<template>
    <div>
        <StatusIndicator :status="backendStatus" :label="backendLabel" />
        <StatusIndicator :status="databaseStatus" :label="databaseLabel" />
        <StatusIndicator :status="cloudStorageStatus" :label="cloudStorageLabel" />
        <StatusIndicator :status="initialUserStatus" :label="initialUserLabel" />
    </div>
</template>
<script>
    import { mapActions, mapGetters } from "vuex";
    import StatusIndicator from "./StatusIndicator.vue";

    export default {
        components: {
            StatusIndicator
        },
        computed: {
            ...mapGetters({
                backendStatus: "platform/getBackendStatus",
                backendLabel: "platform/getBackendLabel",
                databaseStatus: "platform/getDatabaseStatus",
                databaseLabel: "platform/getDatabaseLabel",
                cloudStorageStatus: "platform/getCloudStorageStatus",
                cloudStorageLabel: "platform/getCloudStorageLabel",
                initialUserStatus: "platform/getInitialUserStatus",
                initialUserLabel: "platform/getInitialUserLabel",
            })
        },
        methods: {
            ...mapActions({
                logout: "logout"
            })
        }
    };
</script>