
import { l, tryGetTokenOverride, selectLanguage } from "./localization.js";


// General CMS translations
export const general = {


    
    loggedInAs(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "loggedInAs");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return "Logged in as";
            case "de": return "Angemeldet als";
            case "fr": return "Connecté comme";
            default: return `[loggedInAs: ${language} missing]`;
        }
    
    },
            

    
    logout(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "logout");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return "Logout";
            case "de": return "Abmelden";
            case "fr": return "Déconnexion";
            default: return `[logout: ${language} missing]`;
        }
    
    },
            

    
    error403(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "error403");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return "Error 403: You don't have permission to access this content.";
            case "de": return "Fehler 403: Sie haben keine Zugriffsberechtigung auf diesen Inhalt.";
            case "fr": return "Erreur 403 : Vous n'avez pas la permission d'accéder à ce contenu.";
            default: return `[error403: ${language} missing]`;
        }
    
    },
            

    
    cancel(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "cancel");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return "Cancel";
            case "de": return "Abbrechen";
            case "fr": return "Annuler";
            default: return `[cancel: ${language} missing]`;
        }
    
    },
            

    
    create(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "create");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return "Create";
            case "de": return "Erstellen";
            case "fr": return "Créer";
            default: return `[create: ${language} missing]`;
        }
    
    },
            

    
    close(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "close");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "de-CH", "fr"]);

        switch (language) {
            case "en": return "Close";
            case "de": return "Schließen";
            case "de-CH": return "Schliessen";
            case "fr": return "Fermer";
            default: return `[close: ${language} missing]`;
        }
    
    },
            

    
    add(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "add");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return "Add";
            case "de": return "Hinzufügen";
            case "fr": return "Ajouter";
            default: return `[add: ${language} missing]`;
        }
    
    },
            

    
    save(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "save");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return "Save";
            case "de": return "Speichern";
            case "fr": return "Enregistrer";
            default: return `[save: ${language} missing]`;
        }
    
    },
            

    
    delete(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "delete");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return "Delete";
            case "de": return "Löschen";
            case "fr": return "Supprimer";
            default: return `[delete: ${language} missing]`;
        }
    
    },
            

    
    areYouSureYouWantToDelete(name, locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "areYouSureYouWantToDelete");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return "Are you sure you want to delete \"" + name + "\"?";
            case "de": return "Wollen Sie \"" + name + "\" wirklich löschen?";
            case "fr": return "Êtes-vous sûr de vouloir supprimer \"" + name + "\"?";
            default: return `[areYouSureYouWantToDelete: ${language} missing]`;
        }
    
    },
            

    
    label(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "label");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de"]);

        switch (language) {
            case "en": return "Label";
            case "de": return "Beschriftung";
            default: return `[label: ${language} missing]`;
        }
    
    },
            

    
    previewLocaleSelectionLabel(locale) {

        const overrideTranslation = tryGetTokenOverride(locale, "previewLocaleSelectionLabel");

        if (overrideTranslation) {
            return overrideTranslation;
        }
        
        const language = selectLanguage(locale, ["en", "de", "fr"]);

        switch (language) {
            case "en": return "Preview in language:";
            case "de": return "Vorschausprache:";
            case "fr": return "Langue d'aperçu :";
            default: return `[previewLocaleSelectionLabel: ${language} missing]`;
        }
    
    },
            
};
